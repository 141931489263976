import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { DamagesAndReturnsService, RecallService } from '@/api/receive';
import getCurrentDate from '@/utils/getCurrentDate';
import { AppRoutes } from '@/app/routers';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { useAppDispatch } from '@/app/store';
import { initRecallState } from '@/features/recall/recallSlice';
import { palette } from '@/theme';
import { isIOS } from '@/utils/iOS';

const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)`
  margin-right: 8px;
`;

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  margin-right: 16px;
  height: 36px;
  width: 36px;
`;

const StyledListItem = styled('li')`
  word-break: break-all;
  list-style: none;
  padding: 4px;

  span {
    font-size: 14px !important;
    font-family: 'Lucida Console', Courier, monospace !important;
  }
`;

const PageDownloadPDF: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { pathname, state } = useLocation<{ isRecallConfirmFailed: boolean }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmRecallError, setConfirmRecallError] = useState<string>();
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const { store } = useSelector(state => state.currentStore);
  const { confirmRecallOrderHasError } = useSelector(state => state.recall);
  const { pdfCode } = useSelector(state => {
    if (pathname === AppRoutes.PDF_DOWNLOAD_RECALL) {
      return state.recall;
    }

    if (pathname === AppRoutes.PDF_DOWNLOAD_STORE_TRANSFER) {
      return state.s2s;
    }

    return state.storeDamages;
  });

  useEffect(() => {
    /**
     * The redirect occurs only when a recall is successfully confirmed.
     * The pdf code is cleared in the homepage and the user uses the back button on the browser
     */
    if (!pdfCode && !state?.isRecallConfirmFailed) {
      history.push(AppRoutes.INTRO);
    }
  }, [history, pdfCode, state?.isRecallConfirmFailed]);

  useEffect(() => {
    if (confirmRecallOrderHasError) {
      setConfirmRecallError(
        confirmRecallOrderHasError?.body?.errorMessage ||
          confirmRecallOrderHasError?.body
      );
    }
  }, [confirmRecallOrderHasError]);

  useEffect(() => {
    dispatch(initRecallState());
  }, [dispatch]);

  const allowPDFDownload =
    pathname === AppRoutes.PDF_DOWNLOAD_RETURN_DAMAGE &&
    pdfCode === 'Confirmed';

  const downloadHandler = async (): Promise<void> => {
    const date = getCurrentDate();
    const storeCode = store?.storeCode;

    if (storeCode && date) {
      try {
        setLoading(true);

        const downloadPDf = async (
          file: string,
          fileName: string
        ): Promise<void> => {
          const linkSource = await fetch(`data:application/pdf;base64,${file}`);
          const blob = await linkSource.blob();
          const downloadLink = document.createElement('a');

          if (isIOS()) {
            window.open(window.URL.createObjectURL(blob), '_blank');
          } else {
            downloadLink.href = window.URL.createObjectURL(blob);
          }

          downloadLink.download = fileName;
          downloadLink.click();
        };

        const requestBody = {
          date,
          storeCode,
          documentNumber: pdfCode,
        };

        if (pathname === AppRoutes.PDF_DOWNLOAD_RECALL) {
          const { namePdfFile, pdfFile } =
            await RecallService.recallDownloadPdf(requestBody);

          downloadPDf(pdfFile, namePdfFile);
        } else {
          const { namePdfFile, pdfFile } =
            await DamagesAndReturnsService.damagesDownloadPdf(requestBody);

          downloadPDf(pdfFile, namePdfFile);
        }

        setLoading(false);
        history.push(AppRoutes.INTRO);
      } catch (err) {
        setLoading(false);
        setDownloadError(true);
      }
    }
  };

  return (
    <>
      <UIBox
        width="100%"
        height="100%"
        mx="auto"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {!state?.isRecallConfirmFailed ? (
          <>
            <UIBox alignItems="center" marginBottom={2} marginTop={20}>
              <StyledCheckCircleOutlineIcon htmlColor="#417505" />
              <Typography size="lg">{t('modal.datasaved')}</Typography>
            </UIBox>
            {allowPDFDownload ? null : (
              <>
                {pathname === AppRoutes.PDF_DOWNLOAD_STORE_TRANSFER ? (
                  <Typography size="lg" font="black">
                    {t('transferProceed')}
                  </Typography>
                ) : (
                  <Typography size="lg" font="black">
                    {t('finalizePDFProcess')}
                  </Typography>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <UIBox alignItems="center" marginBottom={2} marginTop={20}>
              <StyledErrorOutlineIcon htmlColor={palette.colors.yellow} />
              <Typography size="lg">{t('modal.processInterrupted')}</Typography>
            </UIBox>
            <Typography size="lg" font="black">
              {t('modal.datanotsaved')}
            </Typography>
          </>
        )}
        {confirmRecallError && (
          <>
            <UIBox
              maxHeight="300px"
              overflow="scroll"
              flexDirection="column"
              bgcolor="#ededed"
              mt={2}
            >
              {confirmRecallError.split('|').map(msg => (
                <StyledListItem>
                  <Typography>{msg}</Typography>
                </StyledListItem>
              ))}
            </UIBox>
          </>
        )}
      </UIBox>
      {!state?.isRecallConfirmFailed ? (
        <>
          {allowPDFDownload ? (
            <CTAContainer
              type="ONE_BUTTON"
              label={t('quit')}
              onClick={(): void => history.push(AppRoutes.INTRO)}
            />
          ) : (
            <>
              {pathname === AppRoutes.PDF_DOWNLOAD_STORE_TRANSFER ? (
                <CTAContainer
                  type="ONE_BUTTON"
                  label={t('quit')}
                  onClick={(): void => history.push(AppRoutes.INTRO)}
                />
              ) : (
                <CTAContainer
                  type="ONE_BUTTON"
                  loading={loading}
                  label={t('downloadPDF')}
                  onClick={downloadHandler}
                />
              )}
            </>
          )}
        </>
      ) : (
        <CTAContainer
          type="ONE_BUTTON"
          label={t('quit')}
          onClick={(): void => history.push(AppRoutes.RECALL)}
        />
      )}
      <ErrorSnackbar
        open={downloadError}
        setIsOpen={setDownloadError}
        errorMessage={t('error.downloadPDF')}
      />
    </>
  );
};

export default PageDownloadPDF;
