import React, { FC } from 'react';
import styled, {
  css,
  CSSProperties,
  FlattenSimpleInterpolation,
} from 'styled-components';
import Button from '@material-ui/core/Button';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '@/theme';
import { useTranslation } from 'react-i18next';

interface AlertUndoSnackbarProps extends SnackbarProps {
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  onUndoClick: () => void;
  margin?: CSSProperties['margin'];
}

const StyledSnackbar = styled(Snackbar)<{ margin?: CSSProperties['margin'] }>`
  ${({
    margin,
  }): CSSProperties['margin'] | FlattenSimpleInterpolation | undefined =>
    margin &&
    css`
      margin: ${margin};
    `};
`;

const StyledButton = styled(Button)`
  width: auto;
  padding: 0;
  margin: 0 16px;
  color: ${colors.white};

  &:hover {
    background: transparent;
  }
`;

const AlertUndoSnackbar: FC<AlertUndoSnackbarProps> = ({
  setIsOpen,
  open,
  message,
  onClose,
  onUndoClick,
  ...props
}) => {
  const { t } = useTranslation();

  const handleClose = (): void => {
    setIsOpen(false);
    onClose();
  };

  return (
    <StyledSnackbar
      {...props}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      ClickAwayListenerProps={{
        onClickAway: (): void => handleClose(),
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      action={
        <>
          <StyledButton
            disableRipple
            color="secondary"
            variant="text"
            size="small"
            onClick={onUndoClick}
          >
            {t('undo')}
          </StyledButton>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default AlertUndoSnackbar;
