import React from 'react';
import { useTranslation } from 'react-i18next';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { ReasonProps } from '../types';

const Reason: React.FC<ReasonProps> = ({ reason, removeReason, ml }) => {
  const { t } = useTranslation();

  return (
    <UIBox ml={4} alignItems="center">
      <Typography
        font="light"
        size="xs"
        as="p"
        style={{
          marginLeft: ml || 10,
          color: '#888a8c',
        }}
      >
        Reason: {reason}
      </Typography>
      <Typography
        font="light"
        size="xs"
        as="p"
        color="red"
        onClick={removeReason}
        style={{
          marginLeft: 16,
          cursor: 'pointer',
        }}
      >
        {t('removeReason')}
      </Typography>
    </UIBox>
  );
};

export default Reason;
