import { FC } from 'react';
import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { palette } from '@/theme';

interface ProductItemProps {
  $withAccordion?: boolean;
  className?: string;
}

interface ProductItemInfoProps {
  $found?: boolean;
  $notFound?: boolean;
  $unexpected?: boolean;
  $sale?: boolean;
  $sold?: boolean;
}

const handleBackground = ({
  $found,
  $notFound,
  $unexpected,
  $sale,
  $sold,
}: ProductItemInfoProps): string => {
  switch (true) {
    case $found:
      return palette.colors.green!;
    case $sold:
      return palette.colors.grey!;
    case $sale:
      return palette.colors.green!;
    case $notFound:
      return palette.colors.red!;
    case $unexpected:
      return palette.colors.yellow!;
    default:
      return palette.colors.grey!;
  }
};

export const StyledProductItemWrapper = styled(UIBox)`
  flex-direction: column;
  padding: 5px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${palette.colors.lightGrey};
  }
`;

const StyledProductItem = styled(UIBox)<ProductItemProps>`
  background: #fbfbfb;
  width: 100%;
  padding: ${({ $withAccordion }): string =>
    $withAccordion ? '24px 24px 0 24px' : '24px'};
  flex-direction: column;
`;

const StyledScannedProductInfo = styled(UIBox)<ProductItemInfoProps>`
  align-items: center;
  justify-content: space-between;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -25px;
    height: 100%;
    width: 8px;
    background: #ededed;
    border-radius: 50px;
    background: ${({ $found, $notFound, $unexpected, $sale, $sold }): string =>
      handleBackground({ $found, $notFound, $unexpected, $sale, $sold })};
  }
`;

export const ProductItem: FC<ProductItemProps & ProductItemInfoProps> = ({
  children,
  $withAccordion,
  ...props
}) => {
  return (
    <StyledProductItem
      className={props.className}
      $withAccordion={$withAccordion}
    >
      <StyledScannedProductInfo {...props}>{children}</StyledScannedProductInfo>
    </StyledProductItem>
  );
};

export default ProductItem;
