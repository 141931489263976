/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IdNotificationStoreList } from '../models/IdNotificationStoreList';
import type { NotificationDetailsList } from '../models/NotificationDetailsList';
import type { NotificationList } from '../models/NotificationList';
import { request as __request } from '../core/request';

export class NotificationService {
  /**
   * Service to show messages in the notification pop-up. Get user from jwt token
   * Service to show messages in the notification pop-up. Get user from jwt token. flag isEmea prenderà valore 1 per richiamo emea e 0 per richiamo US
   * @returns NotificationList OK
   * @throws ApiError
   */
  public static async notificationRetriveNotifications({
    storeCode,
    date,
  }: {
    /** filter for storeCode **/
    storeCode: any;
    /** filter for date **/
    date: any;
  }): Promise<NotificationList> {
    const result = await __request({
      method: 'GET',
      path: `/notification/retriveNotifications`,
      query: {
        storeCode: storeCode,
        date: date,
      },
      errors: {
        400: `bad input parameter, mandatory fiels is missing`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Retrive Notifications Details. Get user from jwt token
   * Retrive Notifications Details, you can view the detail of the notification. Get user from jwt token
   * @returns NotificationDetailsList OK
   * @throws ApiError
   */
  public static async notificationRetriveNotificationsDetails({
    storeCode,
    dateTo,
    dateFrom,
  }: {
    /** filter for storeCode **/
    storeCode: any;
    /** filter for dateTo **/
    dateTo: any;
    /** filter for dateFrom **/
    dateFrom: any;
  }): Promise<NotificationDetailsList> {
    const result = await __request({
      method: 'GET',
      path: `/notification/retriveNotificationsDetails`,
      query: {
        storeCode: storeCode,
        dateTo: dateTo,
        dateFrom: dateFrom,
      },
      errors: {
        400: `bad input parameter, mandatory fiels is missing`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * update Notification Viewed
   * update Notification Viewed
   * @returns any IdNotificationStore received
   * @throws ApiError
   */
  public static async notificationUpdateNotificationViewed({
    requestBody,
  }: {
    requestBody: IdNotificationStoreList;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/notification/updateNotificationViewed`,
      body: requestBody,
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
