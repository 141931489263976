import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AppStatusResponse = {
  dataEnd: string;
  dataStart: string;
  env: string;
  message: string;
  status: number;
};

export const initialState: AppStatusResponse = {
  dataEnd: '',
  dataStart: '',
  env: '',
  message: '',
  status: -1,
};

const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    updateMaintenanceState: (
      state,
      { payload }: PayloadAction<AppStatusResponse>
    ) => {
      state = payload;
      return state;
    },
  },
});

export const { updateMaintenanceState } = maintenanceSlice.actions;
export default maintenanceSlice.reducer;
