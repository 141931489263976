import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  ApiError,
  DeleteMassiveLoaderDateResponse,
  DeleteMassiveLoaderStoreResponse,
  LoadStoresRestService,
} from '@/api/receive';
import { PageLoader } from '@/components/ui/PageLoader';
import { UIBox } from '@/components/ui/Box';
import { UIButton, UIButtonWithIcon } from '@/components/ui/Button';
import { Typography } from '@/components/ui/Typography';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import RefreshIcon from '@material-ui/icons/Refresh';
import FileDetail from './FileDetail';

import * as S from './style';
import { useAsync } from 'react-use';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { AppRoutes } from '@/app/routers';
import { PaginationList } from '@/components/layout/PaginationList';
import { ModalDataSavedError as ModalError } from '@/components/layout/ModalDataSaved';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';

type Files = NonNullable<DeleteMassiveLoaderStoreResponse['dates']>;
type ErrorData = {
  loaderId: string;
  loaderType: string;
};

const PageDeleteEpcDetails: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ storeCode: string }>();
  const history = useHistory();

  const [files, setFiles] = React.useState<Files>([]);
  const [fetchFilesError, setFetchFilesError] = React.useState<boolean>(false);
  const [removeFileError, setRemoveFileError] = React.useState<boolean>(false);
  const [errorData, setErrorData] = React.useState<ErrorData>();
  const [fetchFilesLoading, setFetchFilesLoading] =
    React.useState<boolean>(false);
  const [acceptErrorsLoading, setAcceptErrorsLoading] =
    React.useState<boolean>(false);
  const [acceptErrorsError, setAcceptErrorsError] =
    React.useState<boolean>(false);
  const [fileChangedModalIsVisible, setFileChangedModalVisibility] =
    React.useState<boolean>(false);
  const [fileSuccessfullyDeleted, setFileSuccessfullyDeleted] =
    React.useState<boolean>(false);

  const fetchFiles = React.useCallback(async () => {
    try {
      setFetchFilesLoading(true);

      const response = await LoadStoresRestService.loadstoresRecapStoreUploaded(
        { storeCode: params.storeCode }
      );

      if (response && response.dates) {
        setFiles(response.dates);
      } else {
        setFiles([]);
      }

      setFetchFilesLoading(false);
    } catch {
      setFetchFilesError(true);
      setFetchFilesLoading(false);
    }
  }, [params.storeCode]);

  useAsync(async () => {
    await fetchFiles();
  }, []);

  React.useEffect(() => {
    if (fileSuccessfullyDeleted) {
      setTimeout(() => {
        setFileSuccessfullyDeleted(false);
      }, 2500);
    }
  }, [fileSuccessfullyDeleted]);

  const handleRemoveClick = async (
    loaderId: string,
    loaderType: string
  ): Promise<void> => {
    try {
      await LoadStoresRestService.loadstoresRemoveMassiveLoader({
        requestBody: {
          loaderId,
          loaderType,
        },
      });

      await fetchFiles();

      setFileSuccessfullyDeleted(true);
    } catch (e) {
      const error = e as ApiError;

      if (error.status === 406) {
        setErrorData({
          loaderId,
          loaderType,
        });

        setFileChangedModalVisibility(true);
      } else {
        setRemoveFileError(true);
      }
    }
  };

  const handleAcceptErrors = async (): Promise<void> => {
    try {
      setAcceptErrorsLoading(true);

      await LoadStoresRestService.loadstoresAcceptErrorDelete({
        requestBody: {
          loaderId: errorData?.loaderId,
          loaderType: errorData?.loaderType,
          storeCode: params.storeCode,
        },
      });

      setAcceptErrorsLoading(false);
    } catch {
      setAcceptErrorsLoading(false);
      setAcceptErrorsError(true);
    }

    setFileChangedModalVisibility(false);
    setErrorData(undefined);
  };

  return (
    <>
      {fetchFilesLoading ? (
        <PageLoader />
      ) : (
        <>
          <ModalError
            $minWidth="428px"
            $minHeight="295px"
            iconType="ERROR"
            title={t('payAttention')}
            open={fileChangedModalIsVisible}
            onClose={handleAcceptErrors}
            message={t('notAllowedToDeleteUpload')}
          >
            <UIBox
              mt={4}
              mb={-4}
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              <UIButton
                outlined
                label={t('ok_understand')}
                onClick={handleAcceptErrors}
                loading={acceptErrorsLoading}
                disabled={acceptErrorsLoading}
              />
            </UIBox>
          </ModalError>
          <UIBox flexDirection="column" width="100%" p={3}>
            <UIBox
              mt={2}
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography font="heavy">
                {t('uploadedFileDetailsForStoreCode', {
                  storeCode: params.storeCode,
                })}
              </Typography>
              <UIButtonWithIcon
                label={t('refresh')}
                startIcon={<RefreshIcon />}
                onClick={fetchFiles}
              />
            </UIBox>

            {files.length > 0 ? (
              <UIBox flexDirection="column" mt={5} mb="100px">
                <S.HeaderList>
                  <Typography font="heavy">{t('dates')}</Typography>
                </S.HeaderList>
                <S.List>
                  <PaginationList
                    data={files}
                    pageSize={25}
                    renderItem={(
                      file: DeleteMassiveLoaderDateResponse
                    ): JSX.Element => (
                      <FileDetail {...file} onRemoveClick={handleRemoveClick} />
                    )}
                  />
                </S.List>
              </UIBox>
            ) : (
              <UIBox mt="100px" alignItems="center" justifyContent="center">
                <Typography font="heavy" size="lg">
                  {t('anyLoaderItems')}
                </Typography>
              </UIBox>
            )}
          </UIBox>
          <CTAContainer
            type="BACK"
            onClick={(): void => history.push(AppRoutes.DELETE_EPC)}
          />
        </>
      )}
      <AlertSnackbar
        severity="success"
        open={fileSuccessfullyDeleted}
        setIsOpen={setFileSuccessfullyDeleted}
        message={t('success.itemDeleted')}
      />
      <ErrorSnackbar
        open={fetchFilesError}
        setIsOpen={setFetchFilesError}
        errorMessage={t('error.loadingFiles')}
      />
      <ErrorSnackbar
        open={removeFileError}
        setIsOpen={setRemoveFileError}
        errorMessage={t('error.removePosItem')}
      />
      <ErrorSnackbar
        open={acceptErrorsError}
        setIsOpen={setAcceptErrorsError}
        errorMessage={t('error.acceptErrors')}
      />
    </>
  );
};

export default PageDeleteEpcDetails;
