import { FC } from 'react';
import styled from 'styled-components';
import TextField from './TextField';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { Controller, UseControllerProps } from 'react-hook-form';
import { FormInputs } from '@/types/hookFormInput';
import { useTranslation } from 'react-i18next';

const StyledInputWrapper = styled(UIBox)<{ $uppercase?: boolean }>`
  padding: 24px;
  flex-direction: column;

  input {
    text-transform: ${({ $uppercase }): string =>
      $uppercase ? 'uppercase' : 'initial'};
  }
`;

const StyledLabel = styled(Typography)`
  margin-bottom: 8px;
`;

const StyledError = styled(Typography)`
  && {
    color: red;
    font-size: 0.75rem;
    margin-top: 3px;
  }
`;

const TextFieldController: FC<
  UseControllerProps<FormInputs> & {
    required?: boolean;
    isErrorVisible?: boolean;
    value?: string;
    uppercase?: boolean;
    className?: string;
    label?: string;
    placeholder?: string;
    errorType?: string;
    rules?: UseControllerProps<FormInputs>['rules'];
  }
> = ({
  name,
  control,
  value,
  required,
  uppercase,
  isErrorVisible,
  className,
  placeholder,
  rules,
  errorType,
  label,
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }): JSX.Element => (
        <StyledInputWrapper className={className} $uppercase={uppercase}>
          <StyledLabel font="medium">
            {t(`${name}`)} {required && '*'} <small>{label}</small>
          </StyledLabel>

          <TextField
            {...field}
            required={required}
            variant="outlined"
            value={value}
            id={name}
            placeholder={placeholder}
            type={name === 'email' ? 'email' : 'text'}
          />
          {isErrorVisible && (
            <StyledError>
              {errorType !== 'pattern' ? t('requiredField') : t('validValue')}
            </StyledError>
          )}
          {name === 'email' && isErrorVisible && (
            <StyledError>{t('validEmail')}</StyledError>
          )}
        </StyledInputWrapper>
      )}
    />
  );
};

export default TextFieldController;
