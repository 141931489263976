import { useEffect } from 'react';

import { addItem, reset } from '@/features/breadcrumbs/breadcrumbsSlice';

import { BreadcrumbsItem } from '@/types/breadcrumbs';
import { useAppDispatch } from '@/app/store';

export const useBreadcrumbs = (items: BreadcrumbsItem[]): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    items.forEach(item => dispatch(addItem(item)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useResetBreadcrumbs = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);
};
