import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';

import { changeAllReasonCodeStatus } from '@/features/profiling/reasonCodeSlice';

import { Typography } from '@/components/ui/Typography';
import { PaginationList } from '../PaginationList';
import { PageLoader } from '@/components/ui/PageLoader';
import { UISwitch } from '@/components/ui/Switch';
import ProfilingReasonCodeRow from './ProfilingReasonCodeRow';

import { StyledTablesHeader } from './styles';
import type { ReasonCodeDetails } from '@/api/receive';

interface ProfilingStoreListProps {
  paginationReasons?: ReasonCodeDetails[];
  listCurrentPage: number;
  enableAllReasonCode: boolean;
  setListCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setAllReasonCodeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfilingReasonCodeList: React.FC<ProfilingStoreListProps> = ({
  paginationReasons,
  listCurrentPage,
  enableAllReasonCode,
  setListCurrentPage,
  setAllReasonCodeEnabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { fetchReasonCodeByMovementTypeIsLoading } = useSelector(
    state => state.profiling.reasonCode
  );

  const switchChangeHandler = (): void => {
    setAllReasonCodeEnabled(prevState => !prevState);
    dispatch(changeAllReasonCodeStatus(!enableAllReasonCode));
  };

  return (
    <>
      <StyledTablesHeader>
        <Typography font="heavy">{t('movementTypeCode')}</Typography>
        <Typography font="heavy">{t('movementReasonCode')}</Typography>
        <Typography font="heavy">{t('movementReason')}</Typography>
        <UISwitch
          checkedLabel={t('enableAll')}
          unCheckedLabel={t('disableAll')}
          checked={enableAllReasonCode}
          onChange={(): void => switchChangeHandler()}
        />
      </StyledTablesHeader>
      {fetchReasonCodeByMovementTypeIsLoading ? (
        <PageLoader />
      ) : (
        paginationReasons && (
          <PaginationList
            setCurrPage={setListCurrentPage}
            currPage={listCurrentPage}
            data={paginationReasons}
            pageSize={25}
            renderItem={(reason: ReasonCodeDetails): JSX.Element => (
              <ProfilingReasonCodeRow
                key={reason.movementReasonCode}
                {...reason}
              />
            )}
          />
        )
      )}
    </>
  );
};

export default ProfilingReasonCodeList;
