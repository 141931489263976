import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { StoreAndRoles } from '@/api/receive';
import { PaginationList } from '../PaginationList';
import { Typography } from '@/components/ui/Typography';
import ProfilingStoreRow from './ProfilingStoreRolesRow';

import { StyledTablesHeader } from './styles';
import { AlertUndoSnackbar } from '@/components/ui/AlertSnackbar';
import { SelectStore } from '@/types/profiling';
import { useAppDispatch } from '@/app/store';
import {
  changeAllStoresStatus,
  undoRemovedStore,
} from '@/features/profiling/usersSlice';
import { UICheckbox } from '@/components/ui/Checkbox';
import { UILoader } from '@/components/ui/Loader';
import { UIBox } from '@/components/ui/Box';
import { SelectedStore } from '@/types/profiling';

interface ProfilingStoreListProps {
  paginationStores?: StoreAndRoles[];
  listCurrentPage: number;
  setListCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  selectedStores: SelectedStore[];
  setSaveEnable?: React.Dispatch<React.SetStateAction<boolean>>;
  storesChecked?: boolean;
  setAllStoresChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedStores: React.Dispatch<React.SetStateAction<SelectedStore[]>>;
}

export type StoresRemovedToInsert = {
  store: StoreAndRoles;
  index: number;
};

const StyledLoaderWrapper = styled(UIBox)`
  margin: 100px 0;
  align-items: center;
  justify-content: center;
`;

const ProfilingStoreList: React.FC<ProfilingStoreListProps> = ({
  paginationStores,
  listCurrentPage,
  setListCurrentPage,
  setSelectedStores,
  selectedStores,
  setSaveEnable,
  storesChecked,
  setAllStoresChecked,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [storesInPage, setStoresInPage] = useState<SelectStore[]>([]);

  const [storeRemovedToInsert, setStoreRemovedToInsert] =
    useState<StoresRemovedToInsert>();

  const [alertUndoSnackbarIsVisible, setAlertUndoSnackbarVisibility] =
    useState<boolean>(false);

  const {
    stores: { defaultStore },
    storesIsLoading,
  } = useSelector(state => state.profiling.user);

  const isDefaultStore = (stores: SelectStore[], index: number): boolean => {
    return (
      stores
        .map(({ storeCode }) => storeCode)
        .includes(defaultStore?.storeCode!) && index === 0
    );
  };

  const checkAllStores = (): void => {
    if (paginationStores) {
      const storeCodes = paginationStores.map(
        ({ storeCode }) => storeCode || ''
      );
      const storeCodesAndRoles = paginationStores.map(
        ({ storeCode, roles }) => ({
          storeCode: storeCode!,
          roles: roles!,
        })
      );

      if (storesChecked) {
        setSelectedStores([]);

        dispatch(
          changeAllStoresStatus({
            storeCodes,
            status: false,
          })
        );

        setAllStoresChecked?.(false);
      } else {
        setSelectedStores(storeCodesAndRoles);

        dispatch(
          changeAllStoresStatus({
            storeCodes,
            status: true,
          })
        );

        setAllStoresChecked?.(true);
      }
    }
  };

  const onUndoSnackbarClick = (): void => {
    if (storeRemovedToInsert) {
      dispatch(undoRemovedStore(storeRemovedToInsert));
      setStoreRemovedToInsert(undefined);
      setAlertUndoSnackbarVisibility(false);
    }
  };

  const onSnackbarClose = (): void => {
    setStoreRemovedToInsert(undefined);
  };

  if (storesIsLoading) {
    return (
      <StyledLoaderWrapper>
        <UILoader />
      </StyledLoaderWrapper>
    );
  }

  return (
    <>
      <StyledTablesHeader>
        <UICheckbox checked={storesChecked} onClick={checkAllStores} />
        <Typography font="heavy">{t('salesOrganization')}</Typography>
        <Typography font="heavy">{t('storeCode')}</Typography>
        <Typography font="heavy">{t('storeAddress')}</Typography>
        <Typography font="heavy">{t('roles')}</Typography>
        <Typography color="grey">
          {t('storeToInsert', { count: selectedStores.length })}
        </Typography>
      </StyledTablesHeader>
      {paginationStores && (
        <PaginationList
          setItemsInPage={setStoresInPage}
          setCurrPage={setListCurrentPage}
          currPage={listCurrentPage}
          data={paginationStores}
          pageSize={20}
          renderItem={(
            saleStore: StoreAndRoles,
            index: number
          ): JSX.Element => (
            <ProfilingStoreRow
              isDefaultStore={isDefaultStore(storesInPage, index)}
              storeRemovedToInsert={storeRemovedToInsert}
              setStoreRemovedToInsert={setStoreRemovedToInsert}
              setAlertUndoSnackbarVisibility={setAlertUndoSnackbarVisibility}
              setSelectedStores={setSelectedStores}
              selectedStores={selectedStores}
              setSaveEnable={setSaveEnable}
              {...saleStore}
              key={saleStore.storeCode || defaultStore?.storeCode}
            />
          )}
        />
      )}
      <AlertUndoSnackbar
        open={alertUndoSnackbarIsVisible}
        setIsOpen={setAlertUndoSnackbarVisibility}
        onClose={onSnackbarClose}
        onUndoClick={onUndoSnackbarClick}
        message={t('notification.itemRemoved')}
      />
    </>
  );
};

export default ProfilingStoreList;
