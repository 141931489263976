import React, { useEffect, useState } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useAsync } from 'react-use';

import getCurrentDate from '@/utils/getCurrentDate';
import {
  NotificationService,
  NotificationResponse as Notification,
  InventoryService,
} from '@/api/receive';
import { AppRoutes } from '@/app/routers';
import { useLocation } from 'react-router-dom';
import { useSocketContext } from './socketContext';

interface INotificationContext {
  notifications: Notification[];
  inventoryRefused: boolean;
  isNotificationBannerVisible: boolean;
  isNotificationAvailable: boolean;
  setNotificationBannerVisibility: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setNotificationAvailability: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationContext = React.createContext<INotificationContext>({
  notifications: [],
  inventoryRefused: false,
  isNotificationBannerVisible: false,
  isNotificationAvailable: false,
  setNotificationBannerVisibility: () => false,
  setNotificationAvailability: () => false,
});

export const NotificationContextProvider: React.FC = ({ children }) => {
  const { virtualBuddyNotificationIsVisible } = useSocketContext();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isNotificationAvailable, setNotificationAvailability] =
    useState<boolean>(false);
  const [isNotificationBannerVisible, setNotificationBannerVisibility] =
    useState<boolean>(false);
  const [inventoryRefused, setInventoryRefused] = useState<boolean>(false);
  const { pathname } = useLocation();

  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const shouldGetNotifications =
    pathname === AppRoutes.INTRO ||
    pathname === AppRoutes.SALE ||
    pathname === AppRoutes.CUSTOMER_RETURNS_INTRO;

  useEffect(() => {
    // It runs when the socket send the message for the notification
    if (virtualBuddyNotificationIsVisible) {
      setNotificationAvailability(true);
      setNotificationBannerVisibility(true);
    }
  }, [virtualBuddyNotificationIsVisible]);

  useAsync(async () => {
    if (shouldGetNotifications && storeCode) {
      const date = getCurrentDate();

      const response =
        await NotificationService.notificationRetriveNotifications({
          storeCode,
          date,
        });

      const inventory = await InventoryService.inventoryGetInventoryStatus({
        storeCode,
      });

      if (inventory && inventory.status === 'Refused') {
        setInventoryRefused(true);
      }

      if (response) {
        const { notificationDetails } = response;

        if (notificationDetails && notificationDetails.length > 0) {
          // Notifications are not visibile for these processes
          const filterNotification = notificationDetails.filter(
            ({ typeProcess }): boolean =>
              typeProcess !== 'SLEX' && typeProcess !== 'RTPS'
          );

          const notificationsAvailable = filterNotification.length > 0 || false;

          setNotifications(notificationDetails);
          setNotificationAvailability(notificationsAvailable);
          setNotificationBannerVisibility(notificationsAvailable);
        }
      } else {
        setNotifications([]);
        setNotificationAvailability(false);
        setNotificationBannerVisibility(false);
      }
    } else {
      setNotificationBannerVisibility(false);
    }
  }, [pathname]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        inventoryRefused,
        isNotificationBannerVisible,
        isNotificationAvailable,
        setNotificationBannerVisibility,
        setNotificationAvailability,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = (): INotificationContext =>
  React.useContext(NotificationContext);
