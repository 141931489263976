import { FC, useState, memo } from 'react';
import { IModalSetStoreType } from './types';
import { UIModal } from '@/components/ui/Modal';
import {
  CloseIcon,
  StyledBtn,
  StyledFooter,
  StyledMainWrapper,
  StyledModalWrapper,
  StyledTitleWrapper,
  StyledTopSpace,
} from './style';
import { Typography } from '../Typography';
import { useTranslation } from 'react-i18next';
import { UISelect } from '../Select';

export const ModalSetStoreType: FC<IModalSetStoreType> = memo(
  ({ open, setOpen, setCurStoreType, curStoreType }): JSX.Element => {
    const [selectedSt, setSelectedSt] = useState<string>(curStoreType);
    const { t } = useTranslation();

    const closeHandler = (): void => {
      setOpen(false);
    };

    const confirmClickHandler = (): void => {
      setCurStoreType?.({ value: selectedSt, label: selectedSt });
      setOpen(false);
    };

    return (
      <UIModal open={open} onClose={closeHandler} $minHeight="">
        <StyledModalWrapper>
          <StyledTitleWrapper>
            <Typography font="heavy">{t('modalSetStoreType.title')}</Typography>

            <CloseIcon color={'primary'} onClick={closeHandler} />
          </StyledTitleWrapper>

          <StyledMainWrapper>
            <StyledTopSpace>
              <UISelect
                title={t('modalSetStoreType.title')}
                aligned="center"
                variant="outlined"
                defaultValue={curStoreType}
                values={[
                  {
                    label: 'RETAIL',
                    value: 'RETAIL',
                  },
                  {
                    label: 'OUTLET EMEA',
                    value: 'OUTLET EMEA',
                  },
                  {
                    label: 'OUTLET US',
                    value: 'OUTLET US',
                  },
                  {
                    label: 'AIRPORT EMEA',
                    value: 'AIRPORT EMEA',
                  },
                  {
                    label: 'AIRPORT US',
                    value: 'AIRPORT US',
                  },
                  {
                    label: 'AIRPORT UK',
                    value: 'AIRPORT UK',
                  },
                ]}
                onChange={(e: React.ChangeEvent<{ value: unknown }>): void =>
                  setSelectedSt(e.target.value as string)
                }
              />
            </StyledTopSpace>
          </StyledMainWrapper>

          <StyledFooter>
            <StyledBtn
              label={t('modalSetTimezone.btn.cancel')}
              onClick={closeHandler}
              outlined
            />

            <StyledBtn
              label={t('modalSetTimezone.btn.ok')}
              disabled={curStoreType === '' && selectedSt === ''}
              onClick={confirmClickHandler}
            />
          </StyledFooter>
        </StyledModalWrapper>
      </UIModal>
    );
  }
);

ModalSetStoreType.displayName = 'ModalSetStoreType';

export default ModalSetStoreType;
