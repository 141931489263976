import { UIBox } from '@/components/ui/Box';
import { UISelect } from '@/components/ui/Select';
import styled from 'styled-components';

export const StyledSelect = styled(UISelect)`
  margin: -16px 0 0 0 !important;
`;

export const StyledText = styled(UIBox)`
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 16px;

  h1 {
    margin: 4px 0;
  }

  p {
    margin: 0;
    line-height: 14px !important;
  }
`;
