/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllCsvErrorFile } from '../models/AllCsvErrorFile';
import type { ConfirmReaderStoreUploadRequest } from '../models/ConfirmReaderStoreUploadRequest';
import type { CsvErrorFile } from '../models/CsvErrorFile';
import type { DeleteMassiveLoaderStoreResponse } from '../models/DeleteMassiveLoaderStoreResponse';
import type { DownloadCsvRequest } from '../models/DownloadCsvRequest';
import type { DownloadCsvResponse } from '../models/DownloadCsvResponse';
import type { LoadListProduction } from '../models/LoadListProduction';
import type { LoadStoresRequest } from '../models/LoadStoresRequest';
import type { MigrationListStatusPruduction } from '../models/MigrationListStatusPruduction';
import type { ReaderStoreUploadInfo } from '../models/ReaderStoreUploadInfo';
import type { ReaderStoreUploadRequest } from '../models/ReaderStoreUploadRequest';
import type { ReaderStoreUploadResponse } from '../models/ReaderStoreUploadResponse';
import type { RecapManualStoreUploadResponse } from '../models/RecapManualStoreUploadResponse';
import type { RecapReaderStoreUploadResponse } from '../models/RecapReaderStoreUploadResponse';
import type { ScanEpcReaderStoreUploadRequest } from '../models/ScanEpcReaderStoreUploadRequest';
import type { UploadFileManualStoreUploadRequest } from '../models/UploadFileManualStoreUploadRequest';
import { request as __request } from '../core/request';

export class LoadStoresRestService {
  /**
   * No Poc CSV loading service
   * load new store
   * @returns any File wrote
   * @throws ApiError
   */
  public static async loadstoresLoadNoPocStores({
    requestBody,
  }: {
    requestBody: LoadStoresRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/loadstores/loadNoPocStores`,
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * No Poc CSV loading service
   * load new store
   * @returns MigrationListStatusPruduction OK
   * @throws ApiError
   */
  public static async loadstoresRecapMigration(): Promise<MigrationListStatusPruduction> {
    const result = await __request({
      method: 'GET',
      path: `/loadstores/recapMigration`,
      errors: {
        400: `Bad request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * No Poc CSV loading service
   * load new store
   * @returns CsvErrorFile OK
   * @throws ApiError
   */
  public static async loadstoresExtractErrorDetails({
    storeCode,
  }: {
    /** filter for storeCode **/
    storeCode: any;
  }): Promise<CsvErrorFile> {
    const result = await __request({
      method: 'GET',
      path: `/loadstores/extractErrorDetails`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * No Poc CSV loading service
   * load new store
   * @returns AllCsvErrorFile OK
   * @throws ApiError
   */
  public static async loadstoresExtractAllErrors(): Promise<AllCsvErrorFile> {
    const result = await __request({
      method: 'GET',
      path: `/loadstores/extractAllErrors`,
      errors: {
        400: `Bad request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        406: `Not Accetable`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * No Poc CSV loading service
   * load new store
   * @returns any Accepted.
   * @throws ApiError
   */
  public static async loadstoresLoadProduction({
    requestBody,
  }: {
    requestBody: LoadListProduction;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/loadstores/loadProduction`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * @deprecated
   * start reader store upload (Massive loader in App)
   * start reader store upload
   * @returns ReaderStoreUploadResponse OK.
   * @throws ApiError
   */
  public static async loadstoresStartReaderStoreUpload({
    requestBody,
  }: {
    /** only storeCode is mandatory **/
    requestBody: ReaderStoreUploadRequest;
  }): Promise<ReaderStoreUploadResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/loadstores/startReaderStoreUpload`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * @deprecated
   * join reader store upload (Massive loader in App)
   * join reader store upload <br> call wss
   * @returns ReaderStoreUploadResponse OK.
   * @throws ApiError
   */
  public static async loadstoresJoinReaderStoreUpload({
    requestBody,
  }: {
    /** only storeCode is mandatory **/
    requestBody: ReaderStoreUploadRequest;
  }): Promise<ReaderStoreUploadResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/loadstores/joinReaderStoreUpload`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * @deprecated
   * refresh reader store upload (Massive loader in App)
   * refresh reader store upload
   * @returns ReaderStoreUploadInfo OK.
   * @throws ApiError
   */
  public static async loadstoresRefreshReaderStoreUpload({
    requestBody,
  }: {
    /** storeCode and readerId are mandatory **/
    requestBody: ReaderStoreUploadRequest;
  }): Promise<ReaderStoreUploadInfo> {
    const result = await __request({
      method: 'POST',
      path: `/loadstores/refreshReaderStoreUpload`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * @deprecated
   * finish and send reader store upload (Massive loader in App)
   * finish and send reader store upload <br> call wss
   * @returns any OK.
   * @throws ApiError
   */
  public static async loadstoresFinishAndSendReaderStoreUpload({
    requestBody,
  }: {
    /** storeCode and readerId are mandatory **/
    requestBody: ReaderStoreUploadRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/loadstores/finishAndSendReaderStoreUpload`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * download csv (Massive loader in App)
   * download csv service -> <br> For RFID flow it's possibile to use READ_TAGS(deprecated for signalR), UPLOADED_TAGS and ERROR_TAGS. <br> For MANUAL flow it's possibile to use only ERROR_TAGS.
   * @returns DownloadCsvResponse OK.
   * @throws ApiError
   */
  public static async loadstoresDownloadCsv({
    requestBody,
  }: {
    requestBody: DownloadCsvRequest;
  }): Promise<DownloadCsvResponse> {
    const result = await __request({
      method: 'POST',
      path: `/loadstores/downloadCsv`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirm reader store upload (Massive loader in App)
   * confirm reader store upload <br>
   * @returns any Accepted.
   * @throws ApiError
   */
  public static async loadstoresConfirmReaderStoreUpload({
    requestBody,
  }: {
    requestBody: ConfirmReaderStoreUploadRequest;
  }): Promise<{
    id?: string;
  }> {
    const result = await __request({
      method: 'POST',
      path: `/loadstores/confirmReaderStoreUpload`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * recap reader store upload (Massive loader in App)
   * start reader store upload
   * @returns RecapReaderStoreUploadResponse OK.
   * @throws ApiError
   */
  public static async loadstoresRecapReaderStoreUpload({
    requestBody,
  }: {
    /** storeCode and readerId are mandatory **/
    requestBody: ReaderStoreUploadRequest;
  }): Promise<RecapReaderStoreUploadResponse> {
    const result = await __request({
      method: 'POST',
      path: `/loadstores/recapReaderStoreUpload`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * @deprecated
   * scan epc reader store upload (Massive loader in App)
   * scan epc reader store upload
   * @returns RecapReaderStoreUploadResponse OK.
   * @throws ApiError
   */
  public static async loadstoresScanEpcReaderStoreUpload({
    requestBody,
  }: {
    requestBody: ScanEpcReaderStoreUploadRequest;
  }): Promise<RecapReaderStoreUploadResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/loadstores/scanEpcReaderStoreUpload`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * recap manual store upload (Massive loader in App)
   * recap manual store upload
   * @returns RecapManualStoreUploadResponse OK.
   * @throws ApiError
   */
  public static async loadstoresRecapManualStoreUpload({
    storeCode,
  }: {
    /** load csv in the following store **/
    storeCode: string;
  }): Promise<RecapManualStoreUploadResponse> {
    const result = await __request({
      method: 'GET',
      path: `/loadstores/recapManualStoreUpload`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * errors verified manual store upload (Massive loader in App)
   * errors verified manual store upload.
   * @returns any OK.
   * @throws ApiError
   */
  public static async loadstoresErrorsVerifiedManualStoreUpload({
    requestBody,
  }: {
    requestBody: {
      id?: string;
      loaderType?: string;
    };
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/loadstores/errorsVerifiedManualStoreUpload`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * upload file manual store upload (Massive loader in App)
   * upload file manual store upload
   * @returns any Accepted.
   * @throws ApiError
   */
  public static async loadstoresUploadFileManualStoreUpload({
    requestBody,
  }: {
    requestBody: UploadFileManualStoreUploadRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/loadstores/uploadFileManualStoreUpload`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * recap Store Uploaded for deleted (Massive loader in App)
   * recap Store Uploaded, calling the service without the storeCode query param, only the store list will be returned in response
   * @returns DeleteMassiveLoaderStoreResponse OK.
   * @throws ApiError
   */
  public static async loadstoresRecapStoreUploaded({
    storeCode,
  }: {
    /** service for delete page recap **/
    storeCode?: string;
  }): Promise<DeleteMassiveLoaderStoreResponse> {
    const result = await __request({
      method: 'GET',
      path: `/loadstores/recapStoreUploaded`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * removeMassiveLoader store upload (Massive loader in App)
   * removeMassiveLoader store upload
   * @returns any OK.
   * @throws ApiError
   */
  public static async loadstoresRemoveMassiveLoader({
    requestBody,
  }: {
    requestBody: {
      loaderId?: string;
      loaderType?: string;
    };
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/loadstores/removeMassiveLoader`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        406: `One or more items related to other tables`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * acceptErrorDelete for accept error (Massive loader in App)
   * acceptErrorDelete
   * @returns any OK, Status Changed.
   * @throws ApiError
   */
  public static async loadstoresAcceptErrorDelete({
    requestBody,
  }: {
    requestBody: {
      storeCode?: string;
      loaderId?: string;
      loaderType?: string;
    };
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/loadstores/acceptErrorDelete`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
