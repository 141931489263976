import React from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';
import { usePrintContext } from '@/context/Print';

import { UILoader } from '@/components/ui/Loader';
import { Typography } from '@/components/ui/Typography';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import {
  StyledBadge,
  StyledContent,
  StyledDeviceInfoWrapper,
  StyledDoneIcon,
  StyledErrorOutlineIcon,
  StyledMessageIconWrapper,
  StyledMessageWrapper,
  StyledPrintButton,
  StyledPrinterInfo,
  StyledRadio,
  StyledErrorWrapper,
  StyledUILoaderWrapper,
} from '../style';

import type { SelectPrinterProps } from './types';
import type { Printer } from '@/types/store';
import { UIBox } from '@/components/ui/Box';

const SelectPrinter: React.FC<SelectPrinterProps> = ({
  onPrintClick,
  printDisabled,
  ...props
}): JSX.Element => {
  const { print, setPrint } = usePrintContext();
  const { store } = useSelector(state => state.currentStore);
  const { isPrinting } = useSelector(state => state.print);
  const { t } = useTranslation();

  const printers = props.printers ? props.printers : store?.printers || [];

  const handlePrintChange = (printer: Printer): void => {
    setPrint(prevState => ({
      ...prevState,
      printer,
      error: {
        visible: false,
        message: undefined,
      },
    }));
  };

  return (
    <>
      <UIBox ml={1} mb={1}>
        <Typography size="md" font="heavy" as="h1">
          {t('selectPrinter')}
        </Typography>
      </UIBox>
      <StyledContent style={{ justifyContent: 'flex-start' }}>
        <UIBox flexDirection="column" mb={print.error.visible ? 3 : 5}>
          {printers.map((printer: Printer) => (
            <StyledDeviceInfoWrapper key={printer.printerCode}>
              <StyledRadio
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                checked={print.printer?.printerCode === printer.printerCode}
                onChange={(): void => handlePrintChange(printer)}
              />
              <StyledPrinterInfo>
                <UIBox alignItems="center" mb="4px">
                  <Typography font="heavy">{printer.printerName}</Typography>
                  <StyledBadge>
                    <Typography size="xs">
                      {printer.isRfid ? 'RFID' : 'NORFID'}
                    </Typography>
                  </StyledBadge>
                </UIBox>
                <Typography font="light">{printer.printerCode}</Typography>
              </StyledPrinterInfo>
            </StyledDeviceInfoWrapper>
          ))}
        </UIBox>
        {isPrinting && (
          <StyledUILoaderWrapper>
            <span>...{t('printwizard.loading')}...</span>
            <UILoader />
          </StyledUILoaderWrapper>
        )}
        {print.error.visible && (
          <StyledErrorWrapper>
            <StyledErrorOutlineIcon />
            {print.error.message && (
              <Typography size="lg" font="heavy" as="h3">
                {print.error.message}
              </Typography>
            )}
            <Typography size="sm" font="light" as="p">
              {t('printwizard.error.help')}
            </Typography>
          </StyledErrorWrapper>
        )}
        {print.data.printedEPC ? (
          <StyledMessageWrapper>
            <StyledMessageIconWrapper>
              <StyledDoneIcon />
            </StyledMessageIconWrapper>
            {t('tagPrinted')}
          </StyledMessageWrapper>
        ) : (
          <StyledPrintButton
            label={t('print')}
            onClick={(e): void => onPrintClick(e)}
            disabled={printDisabled || isPrinting}
          />
        )}
      </StyledContent>
    </>
  );
};

export default SelectPrinter;
