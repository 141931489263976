/**
 * ReactPlugin.ts
 * @copyright Microsoft 2019
 */
import { LogService } from '@/api/bff';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { useState, useRef, Dispatch, SetStateAction } from 'react';
import { useAsync } from 'react-use';
import { useSelector } from './useSelector';

export type AIReactCustomEvent<T> = Dispatch<SetStateAction<T>>;

export default function useCustomEvent<T>(
  reactPlugin: ReactPlugin,
  eventName: string,
  eventData: T,
  skipFirstRun = true
): AIReactCustomEvent<T> {
  const [data, setData] = useState(eventData);
  const firstRun = useRef(skipFirstRun);
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);

  const disableTelemetry = process.env.REACT_APP_DISABLE_INSIGHTS === 'true';
  const enableKPILog = process.env.REACT_APP_ENABLE_KPILOG === 'true';

  useAsync(async () => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    reactPlugin.trackEvent(
      { name: eventName },
      data as ICustomProperties | undefined
    );
    if (enableKPILog && !disableTelemetry) {
      try {
        LogService.postLogService({
          requestBody: {
            level: 'info',
            message: `KPI CALLED: ${storeCode} - ${eventName}`,
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [reactPlugin, data, eventName]);

  return setData as AIReactCustomEvent<T>;
}
