import styled, { css } from 'styled-components';
import { TablesHeader, TablesRow } from '@/components/layout/Tables';
import { theme } from '@/theme';

export const gridStyles = css`
  display: grid;
  gap: 24px;
  row-gap: 6px;
  grid-template-columns: 100px repeat(2, 100px) 215px 190px auto;

  ${theme.breakpoints.down('md')} {
    gap: 16px;
  }
`;

export const StyledTablesHeader = styled(TablesHeader)`
  ${gridStyles};

  & > * {
    &:last-child {
      text-align: right;
    }
  }
`;

export const StyledTablesRow = styled(TablesRow)`
  ${gridStyles};
  align-items: center;
`;
