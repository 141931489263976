import { FC, memo } from 'react';
import styled from 'styled-components';
import { Typography } from '@/components/ui/Typography';

import { UIBox } from '@/components/ui/Box';

const StyledMacroFlowMenu = styled(UIBox)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin-top: 30px;

  a {
    text-decoration: none;
  }

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
            margin-top: 32px;
        }`}
`;

const StyledMenu = styled(UIBox)`
  && {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-top: 24px;

    ${({ theme }): string =>
      `${theme.breakpoints.down('sm')} {
            grid-template-columns: 1fr;
        }`}
  }
`;

export const UIMacroFlowMenu: FC<{ title?: string }> = memo(
  ({ children, title }): JSX.Element => {
    return (
      <StyledMacroFlowMenu>
        <Typography size="lg" font="heavy" as="h1">
          {title}
        </Typography>
        <StyledMenu>{children}</StyledMenu>
      </StyledMacroFlowMenu>
    );
  }
);

UIMacroFlowMenu.displayName = 'UIMacroFlowMenu';

export default UIMacroFlowMenu;
