import { FC, memo } from 'react';
import { CircularProgress } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import styled from 'styled-components';

export interface UILoaderProps {
  size?: number | string;
  value?: number;
  linear?: boolean;
  width?: number | string;
}

const StyledLinear = styled(LinearProgress)<UILoaderProps>`
  width: ${({ width }): string | number => width || '100'}px;
`;

export const UILoader: FC<UILoaderProps> = memo(
  ({ size, value, linear = false, width }: UILoaderProps): JSX.Element =>
    !linear ? (
      <CircularProgress
        size={size}
        value={value}
        variant={value ? 'determinate' : 'indeterminate'}
      />
    ) : (
      <StyledLinear
        width={width}
        value={value}
        variant={value ? 'determinate' : 'indeterminate'}
      />
    )
);

UILoader.displayName = 'UILoader';

export default UILoader;
