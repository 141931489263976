import { useDebounce } from 'react-use';

export const useDebounceLimit = (
  f: (() => void) | [() => void, () => void],
  val: string,
  delay: number = 1250,
  min: number = 4
): void => {
  useDebounce(
    () => {
      if (Array.isArray(f)) {
        if (val.length >= min) {
          f[0]();
        } else {
          f[1]();
        }
      } else {
        f();
      }
    },
    delay,
    [val]
  );
};
