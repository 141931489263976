import React from 'react';
import { UIList, UIListSubHeader } from '@/components/ui/List';
import { Typography } from '@/components/ui/Typography';
import ProductListItem from '../ProductListItem';
import { Grid } from '../style';

import type { ReportBrand } from '@/api/receive';

import { calc } from '../utils/calc';

const ProductList: React.FC<
  ReportBrand & { report: string; margin?: number[] }
> = ({ upcs, margin, report }) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  return (
    <>
      {upcs && (
        <UIList
          subheader={
            <UIListSubHeader
              title={upcs[0].brandDescription || ''}
              actions={
                <Grid report={report} mt="0">
                  <span></span>
                  <Typography size="lg" margin="0 0 0 9px">
                    {report === 'APP'
                      ? calc(upcs, 'preQuantityApp')
                      : calc(upcs, 'preQuantitySap')}
                  </Typography>
                  <Typography size="lg" margin="0 0 0 9px" color="green">
                    {report === 'APP'
                      ? calc(upcs, 'postQuantityApp')
                      : calc(upcs, 'postQuantitySap')}
                  </Typography>
                  {report === 'SAP' && (
                    <Typography size="lg" margin="0 0 0 9px" color="yellow">
                      {calc(upcs, 'adjustment')}
                    </Typography>
                  )}
                </Grid>
              }
              accordion={{
                expanded,
                toggleAccordionState: setExpanded,
              }}
            />
          }
          padding={[0, 0]}
          margin={margin || [0, 0, 100]}
          bordered
          backgrounded
        >
          {expanded && (
            <>
              {upcs &&
                upcs.map(product => (
                  <ProductListItem {...product} report={report} />
                ))}
            </>
          )}
        </UIList>
      )}
    </>
  );
};

export default ProductList;
