import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/app/store';

import { changeStoreStatus } from '@/features/profiling/reasonCodeSlice';

import { Typography } from '@/components/ui/Typography';
import { UISwitch } from '@/components/ui/Switch';
import { StyledTablesRow } from './styles';

import type { ReasonCodeStoreDetails } from '@/api/receive';

const ProfilinReasonCodeStoresRow: React.FC<ReasonCodeStoreDetails> = ({
  ...store
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { storeCode, salesOrg, address, enabled } = store;

  const switchChangeHandler = (): void => {
    dispatch(changeStoreStatus(storeCode || ''));
  };

  return (
    <StyledTablesRow>
      <Typography>{salesOrg}</Typography>
      <Typography>{storeCode}</Typography>
      <Typography>{address}</Typography>
      <UISwitch
        checkedLabel={t('enabled')}
        unCheckedLabel={t('disabled')}
        checked={enabled || false}
        onChange={(): void => switchChangeHandler()}
      />
    </StyledTablesRow>
  );
};

export default ProfilinReasonCodeStoresRow;
