import styled from 'styled-components';

export const StyledDiv = styled('div')`
  margin-top: 40px;
`;
export const ContainerDiv = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto 110px;
  gap: 52px;
  max-width: 500px;
`;
export const SheetContainer = styled.div`
  padding-top: 28px;
`;
export const TextFieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;
