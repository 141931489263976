import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { ProductPrice } from '@/components/layout/ProductPrice';
import { ProductImage } from '@/components/layout/ProductImage';
import {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionItemContent,
  StyledContentInfo,
  StyledDescription,
  StyledEpcCode,
  StyledProductContent,
  StyledProductItem,
} from '../../listItemStyle';
import { Product } from '@/types/enum';

import type { CycleDetailsItems } from '@/api';

const MissingProductListItem: React.FC<CycleDetailsItems> = productItem => {
  const { t } = useTranslation();
  const [accordionExpended, setAccordionExpanded] = useState<boolean>(false);
  const accordionClickHandler = (): void => setAccordionExpanded(p => !p);

  return (
    <StyledProductItem>
      <StyledProductContent $type={Product.NOT_FOUND}>
        <StyledContentInfo>
          {productItem.epcCodes.length > 1 && (
            <StyledAccordion
              expanded={accordionExpended}
              onClick={accordionClickHandler}
            />
          )}
          <ProductImage
            imageUrl={productItem.imageUrl}
            colorCode={productItem.colorCode}
            modelCode={productItem.modelCode}
            brandCode={productItem.brandCode}
            onClick={(): void => {}}
          />
          <UIBox flexDirection="column">
            <StyledDescription>
              <Typography size="lg" font="heavy" as="span">
                {productItem.brandCode} - {productItem.brandDescription} -{' '}
                {productItem.styleName}
              </Typography>
              <Typography margin="8px 0 0 0" size="md" font="book" as="span">
                {productItem.upcCode} - {productItem.modelCode} -{' '}
                {productItem.commodity}
                {productItem.epcCodes.length === 1 && (
                  <StyledEpcCode>
                    {' '}
                    - {productItem.epcCodes?.[0].epcCode}
                  </StyledEpcCode>
                )}
              </Typography>
            </StyledDescription>
          </UIBox>
        </StyledContentInfo>
        <UIBox flexDirection="column">
          <ProductPrice
            currency={productItem.currency}
            price={productItem.price ? String(productItem.price) : undefined}
          />
          <UIBox alignSelf="end">
            <Typography>
              {t('itemQuantity', {
                itemQuantity: productItem.epcCodes.length,
              })}
            </Typography>
          </UIBox>
        </UIBox>
      </StyledProductContent>
      {productItem.epcCodes.length > 1 && accordionExpended && (
        <StyledAccordionContent ml="0px">
          {productItem.epcCodes?.map(({ epcCode }) => (
            <StyledAccordionItemContent key={epcCode}>
              <UIBox alignItems="center">
                <Typography margin="0 0 0 12px" color="grey">
                  {epcCode}
                </Typography>
              </UIBox>
            </StyledAccordionItemContent>
          ))}
        </StyledAccordionContent>
      )}
    </StyledProductItem>
  );
};

export default MissingProductListItem;
