import { FC, memo } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { UIBox } from '@/components/ui/Box';
import { StyledModalProps, UIModal } from '@/components/ui/Modal';
import { Typography } from '@/components/ui/Typography';

import { palette } from '@/theme';

export interface ModalDataSavedProps {
  open: boolean;
  message: string;
  onClose: () => void;
}

//#region - Styled Components

const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const StyledCloseIcon = styled(IconButton)`
  align-self: baseline;

  svg {
    fill: ${palette.colors.primary};
  }
`;

const StyledConfirmModalContent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledConfirmModalTextWrapper = styled(UIBox)`
  flex-direction: column;

  h1 {
    margin: 0 0 8px;
  }
`;

const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)`
  margin-right: 16px;
  height: 36px;
  width: 36px;
`;

//#endregion

export const ModalDataSaved: FC<
  ModalDataSavedProps & Partial<StyledModalProps>
> = memo(({ onClose, open, message, ...styledProps }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <UIModal
      open={open}
      $width="500px"
      $height="160px"
      onClose={onClose}
      {...styledProps}
    >
      <StyledWrapper>
        <StyledConfirmModalContent>
          <StyledCheckCircleOutlineIcon htmlColor="#417505" />
          <StyledConfirmModalTextWrapper>
            <Typography size="lg" font="heavy" as="h1">
              {t('modal.welldone')}
            </Typography>
            <Typography>{message}</Typography>
          </StyledConfirmModalTextWrapper>
        </StyledConfirmModalContent>
        <StyledCloseIcon onClick={onClose}>
          <HighlightOffIcon />
        </StyledCloseIcon>
      </StyledWrapper>
    </UIModal>
  );
});

ModalDataSaved.displayName = 'ModalDataSaved';

export default ModalDataSaved;
