import { FC } from 'react';
import { UIBox } from '@/components/ui/Box';
import styled from 'styled-components';

const StyledTable = styled(UIBox)`
  width: 100%;
  flex-direction: column;
  margin-bottom: 120px;

  & > * {
    padding: 0;
  }
`;

const Tables: FC = ({ children, ...props }) => {
  return <StyledTable {...props}>{children}</StyledTable>;
};

export default Tables;
