import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/app/store';
import { ProductImage } from '@/components/layout/ProductImage';
import { ProductPrice } from '@/components/layout/ProductPrice';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';

import {
  StyledAccordion,
  StyledCheckbox,
  StyledContentInfo,
  StyledAccordionContent,
  StyledAccordionItemContent,
  StyledDescription,
  StyledProductContent,
} from '@/pages/CycleCount/listItemStyle';

import { StyledProductItem } from './style';
import {
  checkManagedItem,
  unCheckManagedItem,
  checkNotManagedItem,
  unCheckNotManagedItem,
} from '@/features/pos/posSlice';
import { useSelector } from '@/hooks/useSelector';
import { SaleExceptionsItemProps } from './types';
import { CheckItemBody } from '@/types/pos';
import { ListType } from '../ProductList/types';
import { PaginationList } from '@/components/layout/PaginationList';

type ClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

/**
 *
 * @description
 * The Component changes its functionality thanks to a redux property
 * called "liteFunctionality" set to true by setLiteFunctionality action in PageSaleExceptionsScan page.
 *
 * Component changes if liteFunctionality property is true:
 * 1 - The upc code checkbox is checked only if all its epc codes are selected otherwise it's not
 * 2 - When handleUpcCheckboxClick function is fired, epc codes are passed as argument to change their checkbox statuses
 * 3 - The epc code checkboxes are not disabled
 *
 */
const SaleExceptionsItem: React.FC<SaleExceptionsItemProps> = ({
  type,
  product,
  actionsDisabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { liteFunctionality, checkedManagedItems, checkedNotManagedItems } =
    useSelector(state => state.pos);
  const [accordionExpended, setAccordionExpanded] =
    React.useState<boolean>(false);

  const checkedManagedEpcs = React.useMemo(
    () => checkedManagedItems[product.upcCode || ''] || [],
    [checkedManagedItems, product.upcCode]
  );

  const isUpcChecked = React.useMemo(() => {
    const { upcCode, epcCodes } = product;

    if (liteFunctionality)
      return (
        upcCode! in checkedManagedItems &&
        checkedManagedItems[upcCode!].length === epcCodes?.length!
      );

    if (type === ListType.MANAGED) {
      return upcCode! in checkedManagedItems && checkedManagedEpcs.length === 0;
    } else {
      return upcCode! in checkedNotManagedItems;
    }
  }, [
    checkedManagedEpcs.length,
    checkedManagedItems,
    checkedNotManagedItems,
    liteFunctionality,
    product,
    type,
  ]);

  const accordionClickHandler = (): void => setAccordionExpanded(p => !p);

  const updateCheckboxStatus = (
    body: CheckItemBody,
    isChecked: boolean
  ): void => {
    if (type === ListType.MANAGED) {
      if (!isChecked) {
        dispatch(unCheckManagedItem(body));
      } else {
        dispatch(checkManagedItem(body));
      }
    } else {
      if (!isChecked) {
        dispatch(unCheckNotManagedItem(body));
      } else {
        dispatch(checkNotManagedItem(body));
      }
    }
  };

  const handleUpcCheckboxClick = (e: ClickEvent): void => {
    const { upcCode } = product;
    const isChecked = (e.target as HTMLInputElement).checked;

    const reduxActionBody = {
      upcCode: upcCode || '',
      epcCodes: liteFunctionality ? product.epcCodes : undefined,
    };

    updateCheckboxStatus(reduxActionBody, isChecked);
  };

  const handleEpcCheckboxClick = (e: ClickEvent, epcCode: string): void => {
    const { upcCode } = product;
    const isChecked = (e.target as HTMLInputElement).checked;

    const reduxActionBody = {
      upcCode: upcCode || '',
      epcCode: epcCode,
    };

    updateCheckboxStatus(reduxActionBody, isChecked);
  };

  const disableEpcCodeCheckbox = (epcCode: string): boolean => {
    const uncheckedEpcCode = !checkedManagedEpcs.includes(epcCode);
    const maxQtySelected = checkedManagedEpcs.length === product.soldItems;

    if (liteFunctionality && typeof actionsDisabled === 'boolean') {
      return actionsDisabled || (uncheckedEpcCode && maxQtySelected);
    }

    return (
      (uncheckedEpcCode && maxQtySelected) || actionsDisabled === 'EPC_CODE'
    );
  };

  return (
    <>
      <StyledProductItem>
        <StyledProductContent>
          <StyledContentInfo>
            {product.epcCodes && product.epcCodes.length >= 1 && (
              <StyledAccordion
                expanded={accordionExpended}
                onClick={accordionClickHandler}
              />
            )}
            {!liteFunctionality && (
              <StyledCheckbox
                disabled={actionsDisabled === 'UPC_CODE'}
                onClick={handleUpcCheckboxClick}
                checked={isUpcChecked}
              />
            )}
            <ProductImage
              imageUrl={product.imageUrl}
              colorCode={product.colorCode}
              modelCode={product.modelCode}
              brandCode={product.brandCode}
            />
            <UIBox flexDirection="column">
              <StyledDescription>
                <UIBox alignItems="center">
                  <Typography size="lg" font="heavy" as="span">
                    {product.brandCode || 'ND'} -{' '}
                    {product.brandDescription || 'ND'} -{' '}
                    {product.styleName || 'ND'}
                  </Typography>
                </UIBox>
                <Typography margin="8px 0 0 0" size="md" font="book" as="span">
                  {product.upcCode || 'ND'} - {product.modelCode || 'ND'} -{' '}
                  {product.commodity || 'ND'}
                </Typography>
              </StyledDescription>
            </UIBox>
          </StyledContentInfo>
          <UIBox flexDirection="column">
            <ProductPrice
              currency={product.currency}
              price={product.price ? String(product.price) : undefined}
            />
            <UIBox alignSelf="end">
              <Typography>
                {t('soldItemsQty', {
                  items: product.soldItems || 0,
                })}
              </Typography>
            </UIBox>
          </UIBox>
        </StyledProductContent>
        {accordionExpended && (
          <StyledAccordionContent ml="-24px">
            <PaginationList
              data={product.epcCodes || []}
              pageSize={10}
              renderItem={(epcCode: string): JSX.Element => (
                <StyledAccordionItemContent key={epcCode}>
                  <UIBox alignItems="center">
                    <StyledCheckbox
                      checked={checkedManagedEpcs.includes(epcCode)}
                      disabled={disableEpcCodeCheckbox(epcCode)}
                      onClick={(e): void => handleEpcCheckboxClick(e, epcCode)}
                    />
                    <UIBox alignItems="center">
                      <Typography margin="0 0 0 12px" color="grey">
                        {epcCode}
                      </Typography>
                    </UIBox>
                  </UIBox>
                </StyledAccordionItemContent>
              )}
            />
          </StyledAccordionContent>
        )}
      </StyledProductItem>
    </>
  );
};

export default SaleExceptionsItem;
