export const SplitString = (
  value: string,
  separator: string = '-'
): string[] => {
  if (value) {
    if (value.indexOf(separator) === -1) {
      return [value];
    }
    return value.split(separator);
  }
  return [''];
};

export const stringDiff = (str: string, regexp: RegExp): string =>
  str.replace(regexp, '');

export const padStart = (
  str: string | number,
  lim: number = 2,
  padVal: string = '0'
): string => `${str}`.padStart(lim, padVal);

export const padEnd = (
  str: string | number,
  lim: number,
  padVal: string
): string => `${str}`.padEnd(lim, padVal);
