import styled from 'styled-components';

import FormGroup from '@material-ui/core/FormGroup';

import { Chip } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { UIBox } from '@/components/ui/Box';

import { SearchBar } from '@/components/layout/SearchBar';

import { TColorName } from '@/theme';
import { palette } from '@/theme';
import { Typography } from '@/components/ui/Typography';

export const StyledHasMarketing = styled('div')`
  padding-top: 5px;
  margin-right: 10px;
`;

export const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)`
  fill: ${({ theme }): TColorName => theme.palette.colors.green};
  margin-top: -6px;
  width: 18px;
  vertical-align: middle;
`;

export const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  fill: ${({ theme }): TColorName => theme.palette.colors.yellow};
  margin-right: 5px;
  margin-top: -6px;
  width: 14px;
  vertical-align: middle;
`;

export const StyledPageContainer = styled(UIBox)`
  width: 100%;
  flex-direction: column;

  & > * {
    padding: 0 24px;
  }
`;

export const StyledLoaderContainer = styled(UIBox)`
  align-self: center;
`;

export const StyledTitle = styled(Typography)`
  margin: 24px 0 24px;
  text-align: center;
`;

export const StyledSearchWrapper = styled(UIBox)`
  width: 100%;
  flex-direction: column;
  margin-top: 15px;
`;

export const StyledSearchBar = styled(SearchBar)`
  && {
    align-self: baseline;
    padding: 16px 0 0;
  }
`;

export const StyledCheckboxWrapper = styled(FormGroup)`
  display: flex;
  align-items: center;
  flex-direction: row;

  h1 {
    margin-right: 24px;
  }
`;

export const StyledListWrapper = styled(UIBox)`
  flex-direction: column;
  align-items: flex-start;
  margin-top: 32px;
`;

export const StyledCycleCountChip = styled(Chip)`
  && {
    background: #707173; // ? Add color in palette object
    color: ${palette.colors.white};
    min-width: 85px;
    font-size: 12px;
  }
`;
