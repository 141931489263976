import { FC, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useAsync } from 'react-use';
import { useAppDispatch } from '@/app/store';
import { useForm, SubmitHandler } from 'react-hook-form';

import { UISelect } from '@/components/ui/Select';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { Tabs } from '@/components/layout/Tabs';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { Form } from '@/components/layout/Form';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';
import { Tables } from '@/components/layout/Tables';
import { UIAutoCompleteStores } from '@/components/ui/AutoComplete';

import TextFieldController from '@/components/ui/TextField/TextFieldController';
import SelectController from '@/components/ui/Select/SelectController';
import { ProfilingStoreRolesList } from '@/components/layout/ProfilingStoreRolesList';
import { FormInputs } from '@/types/hookFormInput';

import {
  createUser,
  getStores,
  getUserDetails,
  initProfilingUserState,
  removeStoresBySale,
  setRoles,
  undoDeletedRoles,
  undoInsertedRoles,
  undoInsertedStores,
  undoInsertOrDeleteRoles,
} from '@/features/profiling/usersSlice';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useHistory } from 'react-router';
import { RoleFilter, StoreFilters } from '@/types/filters';

import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';
import { StoreWithPrinters } from '@/types/store';

import { CreateUser, ProfilingService, StoreAndRoles } from '@/api/receive';
import { ChipList } from '@/components/layout/ChipList';
import { UISwitch } from '@/components/ui/Switch';
import { ModalSelectStore } from '@/components/layout/ModalSelectStore';
import { UIButtonWithIcon } from '@/components/ui/Button';
import { ModalRole } from '@/components/layout/ModalRole';
import {
  AlertSnackbar,
  AlertUndoSnackbar,
} from '@/components/ui/AlertSnackbar';
import { AppRoutes } from '@/app/routers';
import useRolesOptions from '@/hooks/useRolesOptions';
import useChips, { Chip } from '@/hooks/useChips';
import { SelectedStore } from '@/types/profiling';
import { useRoleModalContext } from '@/context/roleModalContext';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { ModalAttention } from '@/components/layout/ModalAttention';

//#region - Styled Components
const StyledFilterWrapper = styled(UIBox)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, fit-content(200px)) repeat(3, 1fr);
  gap: 40px;
  padding: 0 24px 24px;
  margin-bottom: 16px;

  & > * {
    margin: 0;
    padding: 0;
    align-self: flex-end;

    & > div {
      width: 100% !important;
    }
  }
`;

const StyledButtonWithIcon = styled(UIButtonWithIcon)`
  width: fit-content;
  align-self: center;
  margin-top: 16px;
`;

const StyledAutocompleteWrapper = styled(UIBox)`
  flex-direction: column;
  padding: 24px;

  & > span {
    margin-bottom: 8px;
  }
`;

const StyledButton = styled(UIButtonWithIcon)`
  position: absolute;
  right: 40px;
  margin-top: 63px;
`;

const StyledSwitchWrapper = styled(UIBox)`
  width: 100%;
  margin: 12px 32px 0 0;

  & > * {
    margin-left: auto;
  }
`;

const StyledChipList = styled(ChipList)`
  margin-top: 24px;
`;
//#endregion

const PageControlUsersCreate: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const {
    chips,
    state: organizations,
    setChips: setOrganizations,
  } = useChips();
  const rolesOptions = useRolesOptions();
  const { roleModal, setRoleModalState } = useRoleModalContext();
  const tabs = [t('personalData'), t('storeAndPrivileges')];

  const {
    details,
    roles,
    createUserIsLoading,
    createUserHasError,
    stores: { defaultStore, storeBySalesOrganization },
  } = useSelector(state => state.profiling.user);

  const [listCurrentPage, setListCurrentPage] = useState<number>(1);

  const [filters, setFilters] = useState<StoreFilters>({
    sales: '',
    storeCode: '',
    role: 'all',
  });

  const [selectedStore, setSelectedStore] = useState<StoreWithPrinters>();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [alertSnackbarIsVisible, setAlertSnackbarVisibility] =
    useState<boolean>(false);

  const [undoStoresSnackbarIsVisible, setUndoStoresSnackbarVisibility] =
    useState<boolean>(false);

  const [insertedRolesSnackbarIsVisible, showInsertedRolesSnackbar] =
    useState<boolean>(false);

  const [deletedRolesSnackbarIsVisible, showDeletedRolesSnackbar] =
    useState<boolean>(false);

  const [undoRolesSnackbarIsVisible, showUndoRolesSnackbar] =
    useState<boolean>(false);

  const [selectStoreModalIsVisible, setSelectStoreModalVisibility] =
    useState<boolean>(false);

  const [activeUser, setActiveUser] = useState<boolean>(true);
  const [paginationStores, setPaginationStores] = useState<StoreAndRoles[]>([]);
  const [selectedStores, setSelectedStores] = useState<SelectedStore[]>([]);

  const [storeCodesToUndo, setStoreCodesToUndo] = useState<string[]>([]);

  const [sale, setSale] = useState<string | null>('');
  const [rolesToUndo, setRolesToUndo] = useState<SelectedStore[]>([]);
  const [storesChecked, setAllStoresChecked] = useState<boolean>(false);
  const [insertedRolesToUndo, setInsertedRolesToUndo] = useState<
    SelectedStore[]
  >([]);

  const [attentionModalIsVisible, setAttentionModalVisibility] =
    useState<boolean>(false);

  const [deletedRolesToUndo, setDeletedRolesToUndo] = useState<SelectedStore[]>(
    []
  );

  const { handleSubmit, control, watch, reset, getValues } =
    useForm<FormInputs>({
      mode: 'onChange',
      defaultValues: {
        userId: '',
        jobTitle: '',
        firstName: '',
        lastName: '',
        email: '',
        language: '',
      },
    });

  const isEmailFormatValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
    getValues('email')
  );

  const isFormValid =
    defaultStore?.storeCode &&
    getValues('userId') !== '' &&
    getValues('firstName') !== '' &&
    getValues('lastName') !== '' &&
    getValues('language') !== '' &&
    getValues('jobTitle') !== '';

  const confirmDisabled =
    !isFormValid || (getValues('email') !== '' && !isEmailFormatValid);

  const selectedStoresRoles = useMemo(
    () => [...new Set(selectedStores.flatMap(({ roles }) => roles))],
    [selectedStores]
  );

  useAsync(async () => {
    const { roles } = await ProfilingService.userFindAllRoles();

    if (roles) {
      dispatch(setRoles(roles));
    }
  }, []);

  useAsync(async () => {
    await dispatch(getUserDetails());
  }, []);

  useAsync(async () => {
    if (selectedStore?.storeCode) {
      await dispatch(
        getStores({
          defaultStoreCode: selectedStore?.storeCode,
          salesOrganization: organizations,
        })
      );

      setSelectedStores([]);
    }
  }, [selectedStore, organizations, dispatch]);

  useEffect(() => {
    if (selectedStores.length === paginationStores.length) {
      setAllStoresChecked(true);
    } else {
      setAllStoresChecked(false);
    }
  }, [paginationStores.length, selectedStores.length]);

  useEffect(() => {
    if (storeBySalesOrganization && defaultStore?.storeCode) {
      setPaginationStores([defaultStore, ...storeBySalesOrganization]);
    }
  }, [defaultStore, storeBySalesOrganization]);

  useEffect(() => {
    setSale(null);
  }, [organizations.length]);

  useEffect(() => {
    const { sales, storeCode, role } = filters;

    if (sales !== '' || storeCode !== '' || role !== 'all') {
      setPaginationStores(() => {
        if (storeBySalesOrganization && defaultStore) {
          const stores = [defaultStore, ...storeBySalesOrganization];

          if (stores) {
            return stores.filter(store => {
              if (role !== 'all') {
                return (
                  store.storeCode
                    ?.toLowerCase()
                    .includes(storeCode.toLowerCase()) &&
                  store.salesOrg?.toLowerCase().includes(sales.toLowerCase()) &&
                  store.roles?.includes(role!)
                );
              }

              return (
                store.storeCode
                  ?.toLowerCase()
                  .includes(storeCode.toLowerCase()) &&
                store.salesOrg?.toLowerCase().includes(sales.toLowerCase())
              );
            });
          }

          return [];
        }

        return [];
      });
    }

    if (sales === '' && storeCode === '' && role === 'all') {
      if (storeBySalesOrganization && defaultStore) {
        setPaginationStores([defaultStore, ...storeBySalesOrganization]);
      }
    }
  }, [defaultStore, filters, storeBySalesOrganization]);

  const onFormSubmit: SubmitHandler<FormInputs> = async (
    formData
  ): Promise<void> => {
    if (defaultStore?.storeCode) {
      const { userId, jobTitle, language, firstName, lastName, email } =
        formData;
      try {
        const { storeCode } = defaultStore;

        const createUserBody: CreateUser = {
          userId: userId.toUpperCase(),
          jobTitle,
          language,
          lastName,
          firstName,
          email,
          statusUser: activeUser ? 'Active' : 'Inactive',
          defaultStoreAndRoles: {
            storeCode,
            roles: defaultStore.roles?.map(role => role),
          },
          storesAndRoles: storeBySalesOrganization?.map(
            ({ storeCode, roles }) => ({
              storeCode,
              roles,
            })
          ),
        };

        await dispatch(createUser(createUserBody)).unwrap();
        await reset();
        await dispatch(initProfilingUserState());

        history.push(AppRoutes.MANAGE_USERS);
      } catch {
        setAlertSnackbarVisibility(true);
      }
    }
  };

  const onFilterChange = (
    key: keyof StoreFilters,
    value: string | 'all'
  ): void => {
    setFilters(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onChipDelete = (chip: Chip): void => {
    dispatch(removeStoresBySale(chip.label));
    setOrganizations?.(prevState =>
      prevState.filter(organization => organization !== chip.label)
    );
  };

  const onSalesAutoCompleteChange = (sale: string | null): void => {
    setOrganizations(prevState => [...new Set([...prevState, sale!])]);
  };

  const onSaveClick = async (): Promise<void> => {
    await handleSubmit(onFormSubmit)();
  };

  const onUndoStores = (): void => {
    dispatch(undoInsertedStores(storeCodesToUndo));
    setUndoStoresSnackbarVisibility(false);
    setStoreCodesToUndo([]);
  };

  const onUndoInsertRoles = (): void => {
    dispatch(undoInsertedRoles(insertedRolesToUndo));
    showInsertedRolesSnackbar(false);
    setInsertedRolesToUndo([]);
  };

  const onUndoDeleteRoles = (): void => {
    dispatch(undoDeletedRoles(deletedRolesToUndo));
    showDeletedRolesSnackbar(false);
    setDeletedRolesToUndo([]);
  };

  const onUndoRoles = (): void => {
    dispatch(undoInsertOrDeleteRoles(rolesToUndo));
    showUndoRolesSnackbar(false);
    setRolesToUndo([]);
  };

  const onConfirmBackModalClick = async (): Promise<void> => {
    await onSaveClick();
    setAttentionModalVisibility(false);
    history.push(AppRoutes.MANAGE_USERS);
  };

  const onUnConfirmModalClick = async (): Promise<void> => {
    setAttentionModalVisibility(false);
    history.push(AppRoutes.MANAGE_USERS);
  };

  const onCloseBackModalClick = (): void => {
    setAttentionModalVisibility(false);
  };

  const onUndoSnackbarClose = (): void => {
    setStoreCodesToUndo([]);
    setInsertedRolesToUndo([]);
    setDeletedRolesToUndo([]);
  };

  const onBackClick = (): void => {
    if (isFormValid) {
      return setAttentionModalVisibility(true);
    }

    history.push(AppRoutes.MANAGE_USERS);
  };

  return (
    <>
      <ModalAttention
        open={attentionModalIsVisible}
        onConfirmClick={onConfirmBackModalClick}
        onUnconfirmClick={onUnConfirmModalClick}
        onClose={onCloseBackModalClick}
        message={t('modal.attention.messageSave')}
        disableConfirm={confirmDisabled}
      />
      <Tabs
        mt="18px"
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        values={tabs}
      />
      {selectedTab === 0 ? (
        <>
          <StyledSwitchWrapper>
            <UISwitch
              checked={activeUser}
              checkedLabel={t('activeUser')}
              unCheckedLabel={t('inactiveUser')}
              onChange={(): void => setActiveUser(prevState => !prevState)}
            />
          </StyledSwitchWrapper>
          <Form>
            <TextFieldController
              required
              uppercase
              name="userId"
              value={watch('userId')}
              control={control}
              isErrorVisible={getValues('userId') === ''}
            />
            <SelectController
              name="jobTitle"
              value={watch('jobTitle')}
              control={control}
              options={details['jobTitles'] || []}
              required
              isErrorVisible={getValues('jobTitle') === ''}
            />
            <TextFieldController
              required
              name="firstName"
              value={watch('firstName')}
              control={control}
              isErrorVisible={getValues('firstName') === ''}
            />
            <TextFieldController
              required
              name="lastName"
              value={watch('lastName')}
              control={control}
              isErrorVisible={getValues('lastName') === ''}
            />
            <TextFieldController
              name="email"
              value={watch('email')}
              control={control}
              isErrorVisible={getValues('email') !== '' && !isEmailFormatValid}
            />
            <SelectController
              required
              name="language"
              control={control}
              options={(i18n.languages || []) as string[]}
              value={watch('language')}
              isErrorVisible={getValues('language') === ''}
            />
          </Form>
        </>
      ) : (
        <>
          <ModalSelectStore
            open={selectStoreModalIsVisible}
            setStoreCodesToUndo={setStoreCodesToUndo}
            setAlertUndoSnackbarVisibility={setUndoStoresSnackbarVisibility}
            onClose={(): void => setSelectStoreModalVisibility(false)}
          />
          <ModalRole
            type={roleModal.type}
            open={roleModal.open}
            roles={roleModal.roles}
            selectedStores={selectedStores}
            setSelectedStores={setSelectedStores}
            setRolesToUndo={setRolesToUndo}
            setInsertedRolesToUndo={setInsertedRolesToUndo}
            setDeletedRolesToUndo={setDeletedRolesToUndo}
            showInsertedRolesSnackbar={showInsertedRolesSnackbar}
            showDeletedRolesSnackbar={showDeletedRolesSnackbar}
            showUndoRolesSnackbar={showUndoRolesSnackbar}
            onClose={(): void => {
              setSelectedStores([]);
              setRoleModalState(prevState => ({
                ...prevState,
                rolesChecked: [],
                open: false,
              }));
            }}
          />
          <Form margin="0 0 12px">
            <StyledAutocompleteWrapper>
              <Typography font="medium">{t('defaultStore')} *</Typography>
              <UIAutoCompleteStores
                inputPlaceholder={t('changeStore')}
                selectedStore={selectedStore}
                setSelectedStore={setSelectedStore}
              />
            </StyledAutocompleteWrapper>
            <StyledAutocompleteWrapper>
              <Typography font="medium">{t('salesOrganization')}</Typography>
              <Autocomplete
                key="sales-organizations"
                value={sale}
                onChange={(_e, sale): void => {
                  onSalesAutoCompleteChange(sale);
                }}
                options={details['salesOrganization'] || []}
                getOptionLabel={(option): string => option || ''}
                getOptionSelected={(option, value): boolean => option === value}
                renderInput={(params): JSX.Element => (
                  <TextField {...params} variant="outlined" />
                )}
              />
              <StyledChipList
                chips={chips}
                onChipDelete={(chip): void => onChipDelete(chip)}
              />
            </StyledAutocompleteWrapper>
            <StyledButton
              label={t('add.store')}
              variant="text"
              endIcon={<AddCircleOutlineIcon />}
              onClick={(): void => setSelectStoreModalVisibility(true)}
              disabled={!defaultStore?.storeCode}
            />
          </Form>
          {defaultStore?.storeCode && (
            <>
              <StyledFilterWrapper>
                <StyledButtonWithIcon
                  label={t('addRoles')}
                  disabled={selectedStores.length <= 1}
                  endIcon={<AddCircleOutlineIcon />}
                  onClick={(): void =>
                    setRoleModalState({
                      open: true,
                      type: 'ADD',
                      roles,
                    })
                  }
                />
                <StyledButtonWithIcon
                  label={t('deleteRoles')}
                  disabled={selectedStores.length <= 1}
                  endIcon={<RemoveCircleOutlineIcon />}
                  onClick={(): void =>
                    setRoleModalState({
                      open: true,
                      type: 'DELETE',
                      roles: selectedStoresRoles,
                    })
                  }
                />
                <SearchBarLight
                  label=""
                  value={filters.sales}
                  placeholder={t('profiling.filterBySalesOrganizations')}
                  disabled={false}
                  onSearch={(): void => {}}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    onFilterChange('sales', e.target.value)
                  }
                  loading={false}
                  hideButton={true}
                />
                <SearchBarLight
                  label=""
                  value={filters.storeCode}
                  placeholder={t('profiling.filterByStoreCode')}
                  disabled={false}
                  onSearch={(): void => {}}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    onFilterChange('storeCode', e.target.value)
                  }
                  loading={false}
                  hideButton={true}
                />
                <UISelect
                  title=""
                  placeholder={t('profiling.filterByRole')}
                  variant="outlined"
                  onChange={(e: React.ChangeEvent<{ value: unknown }>): void =>
                    onFilterChange('role', e.target.value as RoleFilter)
                  }
                  value={filters.role}
                  defaultValue={'All'}
                  values={rolesOptions}
                />
              </StyledFilterWrapper>
              <Tables>
                <ProfilingStoreRolesList
                  listCurrentPage={listCurrentPage}
                  setListCurrentPage={setListCurrentPage}
                  selectedStores={selectedStores}
                  setSelectedStores={setSelectedStores}
                  paginationStores={paginationStores}
                  storesChecked={storesChecked}
                  setAllStoresChecked={setAllStoresChecked}
                />
              </Tables>
            </>
          )}
        </>
      )}
      <CTAContainer
        type="SAVE"
        disabledMainAction={confirmDisabled}
        disabled={createUserIsLoading}
        loading={createUserIsLoading}
        onClick={(): Promise<void> => onSaveClick()}
        onBackClick={onBackClick}
      />
      <AlertSnackbar
        open={alertSnackbarIsVisible}
        setIsOpen={setAlertSnackbarVisibility}
        message={createUserHasError?.body}
      />
      <AlertUndoSnackbar
        open={insertedRolesSnackbarIsVisible}
        setIsOpen={showInsertedRolesSnackbar}
        onUndoClick={onUndoInsertRoles}
        onClose={onUndoSnackbarClose}
        message={t('notification.itemAdded')}
        margin={'0 0 100px'}
      />
      <AlertUndoSnackbar
        open={deletedRolesSnackbarIsVisible}
        setIsOpen={showDeletedRolesSnackbar}
        onUndoClick={onUndoDeleteRoles}
        onClose={onUndoSnackbarClose}
        message={t('notification.itemRemoved')}
        margin={'0 0 100px'}
      />
      <AlertUndoSnackbar
        open={undoRolesSnackbarIsVisible}
        setIsOpen={showUndoRolesSnackbar}
        onUndoClick={onUndoRoles}
        onClose={onUndoSnackbarClose}
        message={t('notification.itemEdited')}
        margin={'0 0 100px'}
      />
      <AlertUndoSnackbar
        open={undoStoresSnackbarIsVisible}
        setIsOpen={setUndoStoresSnackbarVisibility}
        onUndoClick={onUndoStores}
        onClose={onUndoSnackbarClose}
        message={t('notification.itemAdded')}
        margin={'0 0 100px'}
      />
    </>
  );
};

export default PageControlUsersCreate;
