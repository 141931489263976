import { FC, useCallback } from 'react';
import { useSelector } from '@/hooks/useSelector';
import styled from 'styled-components';

import { palette } from '@/theme';

import { List } from '@material-ui/core';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { useTranslation } from 'react-i18next';

//#region - Styled Components

const StyledHeader = styled(UIBox)`
  width: 100%;
  margin-bottom: 4px;
  border-bottom: 1px solid black;
  display: grid;
  grid-template-columns: 20vw 1fr 1fr auto;

  && {
    padding: 14px 24px;
  }
`;

const StyledBrandsWrapper = styled(List)`
  flex-direction: column;

  && {
    padding: 0;
  }

  & > * {
    &:nth-child(odd) {
      background: ${palette.colors.midGrey};
    }
  }
`;

const StyledBrandItem = styled(UIBox)`
  display: grid;
  grid-template-columns: 20vw 1fr 1fr auto;
  width: 100%;
  padding: 12px 24px;
`;

const StyledCommodity = styled(Typography)`
  text-transform: capitalize;
`;

//#endregion

interface ICycleCountSummaryList {
  summary?: boolean;
}

export const CycleCountSummaryList: FC<ICycleCountSummaryList> = props => {
  const { summary } = props;
  const { t } = useTranslation();

  const {
    selectedBrands,
    selectedMarketingStories,
    selectedMarketingThemes,
    commodityState,
  } = useSelector(state => state.cycleCount);

  const totalNumberOfItemsToScan = useCallback((): number => {
    if (
      selectedMarketingThemes.length > 0 ||
      selectedMarketingStories.length > 0
    ) {
      const marketing = selectedMarketingThemes.concat(
        selectedMarketingStories
      );
      return marketing.reduce((previousValue, { itemQuantity }) => {
        return previousValue + itemQuantity!;
      }, 0);
    }

    return selectedBrands.reduce(
      (previousValue, { itemsToScan }) => previousValue + itemsToScan!,
      0
    );
  }, [selectedMarketingThemes, selectedMarketingStories, selectedBrands]);

  return (
    <>
      <StyledHeader>
        <Typography font="heavy" size="sm">
          {t('cycleCount.selectcomodity')}
        </Typography>
        <Typography font="heavy" size="sm">
          {t('cycleCount.brand')}
        </Typography>
        {summary &&
        ((selectedMarketingThemes && selectedMarketingThemes.length > 0) ||
          (selectedMarketingStories && selectedMarketingStories.length > 0)) ? (
          <Typography font="heavy" size="sm">
            {t('cycleCount.marketingtitle')}
          </Typography>
        ) : (
          <span />
        )}
        <Typography font="heavy" size="sm">
          {t('cycleCount.scanitems', {
            count: Number(totalNumberOfItemsToScan()),
          })}
        </Typography>
      </StyledHeader>
      <StyledBrandsWrapper>
        {summary &&
        ((selectedMarketingThemes && selectedMarketingThemes.length > 0) ||
          (selectedMarketingStories && selectedMarketingStories.length > 0)) ? (
          <>
            {selectedMarketingThemes.map(({ marketingThemeCode }) => (
              <>
                {selectedBrands.map(
                  ({ brandDescription, marketingTheme }, index) => (
                    <StyledBrandItem key={index}>
                      <StyledCommodity size="sm">
                        {Object.entries(commodityState)
                          .map(commodity => {
                            const [key, value] = commodity;

                            if (value) {
                              return key;
                            }

                            return null;
                          })
                          .filter(key => key)
                          .join(', ')}
                      </StyledCommodity>
                      <Typography size="sm">{brandDescription}</Typography>
                      <Typography size="sm">
                        {
                          marketingTheme?.find(
                            element =>
                              element.marketingThemeCode === marketingThemeCode
                          )?.marketingThemeDescription
                        }
                      </Typography>
                      <Typography size="sm">
                        {
                          marketingTheme?.find(
                            element =>
                              element.marketingThemeCode === marketingThemeCode
                          )?.itemQuantity
                        }
                      </Typography>
                    </StyledBrandItem>
                  )
                )}
              </>
            ))}
            {selectedMarketingStories.map(({ marketingStoryCode }) => (
              <>
                {selectedBrands.map(
                  ({ brandDescription, marketingStory }, index) => (
                    <StyledBrandItem key={index}>
                      <StyledCommodity size="sm">
                        {Object.entries(commodityState)
                          .map(commodity => {
                            const [key, value] = commodity;

                            if (value) {
                              return key;
                            }

                            return null;
                          })
                          .filter(key => key)
                          .join(', ')}
                      </StyledCommodity>
                      <Typography size="sm">{brandDescription}</Typography>
                      <Typography size="sm">
                        {
                          marketingStory?.find(
                            element =>
                              element.marketingStoryCode === marketingStoryCode
                          )?.marketingStoryDescription
                        }
                      </Typography>
                      <Typography size="sm">
                        {
                          marketingStory?.find(
                            element =>
                              element.marketingStoryCode === marketingStoryCode
                          )?.itemQuantity
                        }
                      </Typography>
                    </StyledBrandItem>
                  )
                )}
              </>
            ))}
          </>
        ) : (
          <>
            {selectedBrands.map(({ brandDescription, itemsToScan }, index) => (
              <StyledBrandItem key={index}>
                <StyledCommodity size="sm">
                  {Object.entries(commodityState)
                    .map(commodity => {
                      const [key, value] = commodity;

                      if (value) {
                        return key;
                      }

                      return null;
                    })
                    .filter(key => key)
                    .join(', ')}
                </StyledCommodity>
                <Typography size="sm">{brandDescription}</Typography>
                <span />
                <Typography size="sm">{itemsToScan}</Typography>
              </StyledBrandItem>
            ))}
          </>
        )}
      </StyledBrandsWrapper>
    </>
  );
};

CycleCountSummaryList.displayName = 'CycleCountSummaryList';

export default CycleCountSummaryList;
