/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProcessStatus } from '../models/ProcessStatus';
import { request as __request } from '../core/request';

export class ProcessService {
  /**
   * handle lock and unlock for single store. if you try to lock an already blocked process for a store it will return 403
   * handle lock and unlock for single store
   * @returns any OK
   * @throws ApiError
   */
  public static async processHandlingProcess({
    requestBody,
  }: {
    /** lockedBy must not be valuated, otherwise it will not be used **/
    requestBody: ProcessStatus;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/process/handlingProcessStatus`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * get process status filtered by storeCode or process or both or full
   * get process status filtered by storeCode or process or both or full
   * @returns any OK
   * @throws ApiError
   */
  public static async processReadProcessStatus({
    storeCode,
    process,
    filterByStoreCode,
  }: {
    /** filter for storeCode , this field becomes mandatory if filterByStoreCode is null **/
    storeCode?: string;
    /** filter for process (i.e. INVT) **/
    process?: string;
    /** this field becomes mandatory if storeCode is null, and true value allow you filter by all storeCode ,while false filter by store code is null **/
    filterByStoreCode?: boolean;
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/process/readProcessStatus`,
      query: {
        storeCode: storeCode,
        process: process,
        filterByStoreCode: filterByStoreCode,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * create process status
   * create process status
   * @returns any Created.
   * @throws ApiError
   */
  public static async processCreateProcessStatus({
    requestBody,
  }: {
    requestBody: ProcessStatus;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/process/createProcessStatus`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * update process status
   * update process status
   * @returns any Created
   * @throws ApiError
   */
  public static async processUpdateProcessStatus({
    requestBody,
  }: {
    requestBody: ProcessStatus;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/process/updateProcessStatus`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * delete process status
   * delete process status
   * @returns any Deleted
   * @throws ApiError
   */
  public static async processDeleteProcessStatus({
    process,
    storeCode,
  }: {
    /** filter for process (i.e. INVT) **/
    process: string;
    /** filter for storeCode **/
    storeCode?: string;
  }): Promise<any> {
    const result = await __request({
      method: 'DELETE',
      path: `/process/deleteProcessStatus`,
      query: {
        process: process,
        storeCode: storeCode,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
