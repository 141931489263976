import { Typography } from '@/components/ui/Typography';
import React, { useRef, useState } from 'react';
import { SearchButton, StyledAutoComplete, StyledWrapper } from './style';
import { IGenericFilter } from './types';
import { Search as SearchIcon } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { useDebounceLimit } from '@/utils/hooks';

const GenericFilter: React.FC<IGenericFilter> = ({
  onAutoCompleteOptionSelect,
  debounceFn,
  list = [],
  setChangeElement,
  filterTitle,
  filterPlaceholder,
}: IGenericFilter) => {
  const autocompleteInputRef = useRef<HTMLInputElement>(null);

  const [searchedEpc, setSearchedEpc] = useState<string>('');

  const { t } = useTranslation();

  const searchClick = (): void => autocompleteInputRef.current?.focus();

  useDebounceLimit(() => debounceFn?.(), searchedEpc);

  const changeEpcHandler = (value: string): void => {
    setSearchedEpc(value);
    setChangeElement?.(value);
  };

  return (
    <StyledWrapper>
      <Typography margin="auto auto auto 0"> {filterTitle} </Typography>

      <StyledAutoComplete
        disableClearable
        rounded
        width={350}
        inputRef={autocompleteInputRef}
        minSearchTermLength={4}
        label={filterPlaceholder}
        searchTerm=""
        options={list}
        getOptions={async (): Promise<void> => {}}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          changeEpcHandler(e.target.value)
        }
        loadingText={t('searching')}
        noOptionsText={t('error.no_results_available')}
        onOptionSelect={(_e, epc): void => {
          onAutoCompleteOptionSelect?.(epc || '');
        }}
        inputProps={{ maxLength: 24, minLength: 24 }}
      />

      <SearchButton
        onClick={searchClick}
        label={t('search')}
        startIcon={<SearchIcon />}
      />
    </StyledWrapper>
  );
};

export default GenericFilter;
