import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useHistory, useParams } from 'react-router-dom';
import {
  StyledTable,
  StyledEditRemoveCell,
  StyledLink,
  StyledBodyTableRow,
  StyledBodyTableCell,
  StyledHeadTableCell,
  StyledAddDeviceWrapper,
  StyledTableWrapper,
  StyledLoaderWrapper,
} from './style';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { InfoContainerProps, UpdateUrlCreator } from './types';
import AddLink from '@/components/ui/Links/AddLink';
import { AppRoutes } from '@/app/routers';
import { makeUrl } from '@/utils/links';
import { TableDevice } from '@/types/tableDevice';
import { UILoader } from '../Loader';
import { UrlUpdateStore } from '@/pages/ControlPanel/PageStoreDetails/types';

const TableDevices: FC<InfoContainerProps<TableDevice>> = ({
  data,
  isLoading,
  onRemoveElement,
}): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const { store } = useParams<UrlUpdateStore>();

  useEffect(() => {
    if (!store) history.push(AppRoutes.MANAGE_STORES);
  }, [store, history]);

  const editHandler = (id: string): void => {
    history.push(
      makeUrl<UrlUpdateStore | UpdateUrlCreator>(AppRoutes.UPDATE_DEVICE, {
        store: store,
        device: `${id}`,
      })
    );
  };

  const removeHandler = (id: string, type: string): void => {
    onRemoveElement(id, type);
  };

  const addDeviceHandler = (): void => {
    history.push(
      makeUrl<UrlUpdateStore>(AppRoutes.ADD_DEVICE, {
        store: store,
      })
    );
  };

  if (isLoading) {
    return (
      <StyledLoaderWrapper>
        <UILoader />
      </StyledLoaderWrapper>
    );
  }

  return (
    <StyledTableWrapper>
      <StyledAddDeviceWrapper>
        <AddLink label="Add a device" action={(): void => addDeviceHandler()} />
      </StyledAddDeviceWrapper>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledHeadTableCell>{t('Type')}</StyledHeadTableCell>
            <StyledHeadTableCell>{t('Name')}</StyledHeadTableCell>
            <StyledHeadTableCell>{t('Serial number')}</StyledHeadTableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <StyledBodyTableRow key={`row-${index}`}>
              <StyledBodyTableCell>{row.type}</StyledBodyTableCell>
              <StyledBodyTableCell>{row.name}</StyledBodyTableCell>
              <StyledBodyTableCell>{row.id}</StyledBodyTableCell>
              <StyledEditRemoveCell>
                <StyledLink
                  onClick={(): void => removeHandler(row.id, row.type)}
                >
                  {t('Remove')} <DeleteOutlineIcon />
                </StyledLink>
                <StyledLink onClick={(): void => editHandler(row.id)}>
                  {t('Edit')} <EditOutlinedIcon />
                </StyledLink>
              </StyledEditRemoveCell>
            </StyledBodyTableRow>
          ))}
        </TableBody>
      </StyledTable>
    </StyledTableWrapper>
  );
};

TableDevices.displayName = 'TableDevices';

export default TableDevices;
