import styled from 'styled-components';

import { Row } from '@/components/layout/Row';
import { UIBox } from '@/components/ui/Box';
import { Radio, IconButton } from '@material-ui/core';

export const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: auto;

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                  overflow-y: auto;
              }`}
`;

export const StyledContainer = styled(UIBox)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                    display: grid !important;
                    grid-template-columns: 1fr;
                }`}
`;

export const StyledColumn = styled(UIBox)`
  width: 100%;
  height: 100%;
  flex-direction: column;

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                    &:last-child {
                      display: block;
                    }
                }`}
`;

export const StyledContent = styled(UIBox)`
  height: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: center;

  button {
    width: auto !important;
  }

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                  justify-content: flex-start;
                }`}
`;

export const StyledRadio = styled(Radio)`
  margin-right: 16px;
`;

export const StyledRow = styled(Row)`
  border: 1px solid #dedede;
  border-radius: 10px;
  padding: 7px;
`;

export const StyledText = styled(UIBox)`
  flex-direction: column;

  p,
  span {
    margin: 0;
  }
`;

export const StyledCloseIcon = styled(IconButton)`
  align-self: flex-end;
  position: absolute !important;

  svg {
    fill: #005192;
  }
`;

export const StyledLoaderWrapper = styled(UIBox)`
  margin-top: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    margin-bottom: 16px;
  }
`;
