/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FindLastPrintResponse } from '../models/FindLastPrintResponse';
import type { PrintConfirm } from '../models/PrintConfirm';
import type { ProductItemForPrint } from '../models/ProductItemForPrint';
import type { ProductItemToReprint } from '../models/ProductItemToReprint';
import type { UpdateEpcRequest } from '../models/UpdateEpcRequest';
import { request as __request } from '../core/request';

export class PrintService {
  /**
   * SA wants to see the print of the latest epc
   * Print ProductItems
   * @returns FindLastPrintResponse OK
   * @throws ApiError
   */
  public static async receiveshipFindLastPrint({
    storeCode,
    days,
  }: {
    /** filter for storeCode **/
    storeCode: string;
    /** retrieve the epcs they have PRINT_DATE >= NOW TIMEZONE - days **/
    days?: number;
  }): Promise<FindLastPrintResponse> {
    const result = await __request({
      method: 'GET',
      path: `/receiveship/findLastPrint`,
      query: {
        storeCode: storeCode,
        days: days,
      },
      errors: {
        400: `bad input parameter, itemCode is mandatory`,
      },
    });
    return result.body;
  }

  /**
   * SA
   * Print ProductItem
   * @returns ProductItemForPrint OK
   * @throws ApiError
   */
  public static async receiveshipFindProductItemPrintDetails({
    upcCode,
    storeCode,
    date,
  }: {
    /** filter for upcCode **/
    upcCode: any;
    /** filter for upcCode **/
    storeCode: any;
    /** filter for date (yyyymmdd) **/
    date: string;
  }): Promise<ProductItemForPrint> {
    const result = await __request({
      method: 'GET',
      path: `/receiveship/findProductItemPrintDetails`,
      query: {
        upcCode: upcCode,
        storeCode: storeCode,
        date: date,
      },
      errors: {
        404: `Not Found`,
      },
    });
    return result.body;
  }

  /**
   * SA
   * Confirm
   * @returns any Print OK
   * @throws ApiError
   */
  public static async receiveshipConfirmPrintDetails({
    requestBody,
  }: {
    requestBody: PrintConfirm;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/receiveship/confirmPrintDetails`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
      },
    });
    return result.body;
  }

  /**
   * SA
   * EPC information retrieval
   * @returns ProductItemToReprint OK
   * @returns any No content
   * @throws ApiError
   */
  public static async receiveshipFindEpcToReprint({
    storeCode,
    epcCode,
    date,
  }: {
    /** filter for  storeCode **/
    storeCode: any;
    /** filter for epcCode **/
    epcCode: any;
    /** filter for date (yyyymmdd) **/
    date: string;
  }): Promise<ProductItemToReprint | any> {
    const result = await __request({
      method: 'GET',
      path: `/receiveship/findEpcToReprint`,
      query: {
        storeCode: storeCode,
        epcCode: epcCode,
        date: date,
      },
      errors: {
        400: `Bad request`,
        404: `Not Found`,
        500: `Internal server error`,
      },
    });
    return result.body;
  }

  /**
   * update epc (this service is only for stock summary)
   * update epc
   * @returns any Epcs updated
   * @throws ApiError
   */
  public static async receiveshipUpdateEpc({
    requestBody,
  }: {
    requestBody: UpdateEpcRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/receiveship/updateEpc`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Internal server error.`,
      },
    });
    return result.body;
  }
}
