import { FC, memo, useEffect } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  getProductDetails,
  initProductDetails,
} from '@/features/products/productDetailsSlice';
import { selectProductDetails } from '@/features/productSelectors';

import { HighlightOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import { UILoader } from '@/components/ui/Loader';
import { UIModal } from '@/components/ui/Modal';
import { ProductImage } from '@/components/ui/ProductImage';

import { UIBox } from '@/components/ui/Box';
import { useAppDispatch } from '@/app/store';

import type { ProductItem } from '@/api';
import { UIButton } from '@/components/ui/Button';

export interface ModalProductDetailsProps {
  open: boolean;
  onClose: () => void;
  productCode: string;
  productEPCCode?: string;
  $bis?: boolean;
  onConfirm?: () => Promise<void>;
}

//#region Styled Components
const SyledButtonContainer = styled(UIBox)`
  align-items: center;
  gap: 43px;
  width: 60%;
  align-self: flex-end;
  margin-top: 1em;
  position: absolute;
  bottom: 3rem;
`;
const StyledLoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const StyledSubContainer = styled.div`
  padding: 3rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  position: relative;
`;
const Row = styled.div`
  display: flex;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledProductDetails = styled(UIBox)`
  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                  flex-direction: column;

                  & > *:not(:last-child) {
                    margin-bottom: 16px;
                  }
              }`}
`;

const TextQuestion = styled.div`
  font-size: 18px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 2px;
`;

const TextSubtitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
const TextLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-right: 0.4rem;
  margin-bottom: 0.2rem;
`;
const TextValue = styled.div`
  font-size: 16px;
`;
const StyledClose = styled(IconButton)`
  height: 1rem;
  width: 1rem;
  align-self: flex-end;
  margin-top: 1rem;
  margin-right: 1rem;
`;
//#endregion

export const ModalProductDetails: FC<ModalProductDetailsProps> = memo(
  ({
    open,
    onClose,
    productCode,
    productEPCCode,
    $bis = false,
    onConfirm,
    ...rest
  }): JSX.Element => {
    const product: ProductItem | undefined = useSelector(selectProductDetails);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
      if (open) {
        dispatch(getProductDetails(productCode));
      }
    }, [dispatch, open, productCode]);

    const handleOnClose = (): void => {
      dispatch(initProductDetails());
      onClose();
    };

    const handleOnConfirm = async (): Promise<void> => {
      await onConfirm?.();
      dispatch(initProductDetails());
      onClose();
    };

    return (
      <UIModal
        open={open}
        onClose={handleOnClose}
        $width={'85%'}
        $height={'85%'}
        {...rest}
      >
        {product?.upcCode === '' ? (
          <StyledLoaderContainer>
            <UILoader />
          </StyledLoaderContainer>
        ) : (
          <StyledContainer>
            <StyledClose onClick={handleOnClose}>
              <HighlightOff color={'primary'} />
            </StyledClose>
            <StyledSubContainer>
              <ProductImage
                imageUrl={product?.imageUrl}
                colorCode={product?.colorCode}
                modelCode={product?.modelCode}
                brandCode={product?.brandDescription}
                width={400}
                fluid={false}
              />
              {$bis && productEPCCode && (
                <TextQuestion>
                  {t('page.backinstock.moveitems')}
                  <br />
                  {t('checkProceed')}
                </TextQuestion>
              )}
              <TextSubtitle>{product?.brandDescription}</TextSubtitle>
              <StyledProductDetails>
                <Col>
                  {$bis && productEPCCode && (
                    <Row>
                      <TextLabel>{t('epcCode')}:</TextLabel>
                      <TextValue>{productEPCCode}</TextValue>
                    </Row>
                  )}
                  <Row>
                    <TextLabel>{t('product.upcCode')}:</TextLabel>
                    <TextValue>{product?.upcCode}</TextValue>
                  </Row>
                  <Row>
                    <TextLabel>{t('product.modelCode')}:</TextLabel>
                    <TextValue>{product?.modelCode}</TextValue>
                  </Row>
                  <Row>
                    <TextLabel>{t('product.colorCode')}:</TextLabel>
                    <TextValue>{product?.colorCode}</TextValue>
                  </Row>
                  <Row>
                    <TextLabel>{t('product.sizeCode')}:</TextLabel>
                    <TextValue>{product?.sizeCode}</TextValue>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <TextLabel>{t('product.commodity')}:</TextLabel>
                    <TextValue>{product?.commodity}</TextValue>
                  </Row>
                  <Row>
                    <TextLabel>{t('product.styleName')}:</TextLabel>
                    <TextValue>{product?.styleName}</TextValue>
                  </Row>
                  <Row>
                    <TextLabel>{t('product.marketingTheme')}:</TextLabel>
                    <TextValue>{product?.marketingTheme}</TextValue>
                  </Row>
                  <Row>
                    <TextLabel>{t('product.marketingStory')}:</TextLabel>
                    <TextValue>{product?.marketingStory}</TextValue>
                  </Row>
                </Col>
              </StyledProductDetails>
              {$bis && productEPCCode && (
                <SyledButtonContainer>
                  <UIButton label={t('no')} outlined onClick={handleOnClose} />
                  <UIButton
                    label={t('yes')}
                    outlined
                    onClick={handleOnConfirm}
                  />
                </SyledButtonContainer>
              )}
            </StyledSubContainer>
          </StyledContainer>
        )}
      </UIModal>
    );
  }
);

ModalProductDetails.displayName = 'ModalProductDetails';

export default ModalProductDetails;
