import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { useDebounce } from 'react-use';
import { Trans, useTranslation } from 'react-i18next';
import Fuse from 'fuse.js';

import { Typography } from '@/components/ui/Typography';
import { UIList } from '@/components/ui/List';
import { UIListItem } from '@/components/ui/ListItem';
import { UIListHeader } from '@/components/ui/ListHeader';
import { CycleCountSummaryList } from '@/components/layout/CycleCountSummaryList';

import {
  getBrandsMarketing,
  selectMarketingTheme,
  deselectMarketingTheme,
  selectMarketingStory,
  deselectMarketingStory,
} from '@/features/cycleCount/cycleCountSlice';
import { AppRoutes } from '@/app/routers';
import { CustomMarketingTheme, CustomMarketingStory } from '@/types/cycleCount';
import { CTAContainer } from '@/components/layout/CTAContainer';
import TabCycleCount from '@/components/CycleCount/TabCycleCount/TabCycleCount';
import {
  StyledCycleCountChip,
  StyledListWrapper,
  StyledPageContainer,
  StyledSearchBar,
  StyledSearchWrapper,
  StyledTitle,
  TitleListWrapper,
} from './style';
import { Link } from '@material-ui/core';
import {
  manageSelectAllMarketingStories,
  manageSelectAllMarketingThemes,
} from './utils';

const PageCycleCountFilterMarketing: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const isSohProcess = useSelector(state => state.cycleCount.sohLocked);

  const [searchedMarketingTheme, setSearchedMarketingTheme] =
    useState<string>('');
  const [marketingThemeToFilter, setMarketingThemeToFilter] = useState<
    CustomMarketingTheme[]
  >([]);
  const [marketingStoriesToFilter, setMarketingStoriesToFilter] = useState<
    CustomMarketingStory[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);

  const { store } = useSelector(state => state.currentStore);
  const {
    brandsMarketingThemes,
    brandsMarketingStories,
    brandsMarketingThemesIsLoading,
  } = useSelector(state => state.cycleCount);

  const searchOptions = {
    keys: ['marketingThemeDescription', 'marketingStoryDescription'],
    minMatchCharLength: 1,
    threshold: 0.4,
    distance: 40,
  };

  const fuse_theme = new Fuse(brandsMarketingThemes!, searchOptions);
  const fuse_story = new Fuse(brandsMarketingStories!, searchOptions);

  useEffect(() => {
    setIsLoading(true);
    if (brandsMarketingThemesIsLoading === undefined) {
      dispatch(getBrandsMarketing());
    }
    setIsLoading(false);
  }, [dispatch, brandsMarketingThemesIsLoading]);

  useEffect(() => {
    if (
      brandsMarketingThemes &&
      brandsMarketingThemes?.length === 0 &&
      brandsMarketingStories &&
      brandsMarketingStories?.length === 0 &&
      isLoading === false
    ) {
      history.replace(AppRoutes.CYCLE_COUNT_FILTER_SUMMARY);
    }
  }, [
    dispatch,
    history,
    brandsMarketingThemes,
    brandsMarketingStories,
    isLoading,
  ]);

  useEffect(() => {
    if (marketingThemeToFilter?.length === 0) {
      setMarketingThemeToFilter(brandsMarketingThemes);
    }

    if (marketingStoriesToFilter?.length === 0) {
      setMarketingStoriesToFilter(brandsMarketingStories);
    }
  }, [
    brandsMarketingThemes,
    marketingThemeToFilter?.length,
    brandsMarketingStories,
    marketingStoriesToFilter?.length,
  ]);

  useDebounce(
    () => {
      if (store?.storeCode) {
        if (searchedMarketingTheme.length >= 3) {
          const filteredMarketingThemes = fuse_theme
            .search(searchedMarketingTheme)
            .map(({ item }) => item);
          setMarketingThemeToFilter(filteredMarketingThemes);

          const filteredMarketingStories = fuse_story
            .search(searchedMarketingTheme)
            .map(({ item }) => item);
          setMarketingStoriesToFilter(filteredMarketingStories);
        }

        if (searchedMarketingTheme.length === 0) {
          setMarketingThemeToFilter(brandsMarketingThemes);
          setMarketingStoriesToFilter(brandsMarketingStories);
        }
      }
    },
    1500,
    [searchedMarketingTheme]
  );

  const isSelectedAllMarketingThemes = marketingThemeToFilter.every(
    item => item.selected
  );
  const isSelectedAllMarketingStories = marketingStoriesToFilter.every(
    item => item.selected
  );

  const isSelectedOneMarketingThemes = marketingThemeToFilter.some(
    item => item.selected
  );
  const isSelectedOneMarketingStories = marketingStoriesToFilter.some(
    item => item.selected
  );

  const searchInputHandler: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    setSearchedMarketingTheme(value);
  };

  const handleSelectTheme = (selectedMarketingTheme: string): void => {
    let newFilteredMarketingThemes = [...marketingThemeToFilter];

    const marketingThemeItem = marketingThemeToFilter.find(
      ({ marketingThemeCode }) => marketingThemeCode === selectedMarketingTheme
    );

    const marketingThemeItemIndex = marketingThemeToFilter.findIndex(
      ({ marketingThemeCode }) => marketingThemeCode === selectedMarketingTheme
    );

    if (marketingThemeItem) {
      const marketingThemeIsSelected = marketingThemeItem.selected;

      if (!marketingThemeIsSelected) {
        dispatch(selectMarketingTheme(marketingThemeItem));

        newFilteredMarketingThemes[marketingThemeItemIndex] = {
          ...marketingThemeItem,
          selected: true,
        };
      } else {
        dispatch(deselectMarketingTheme(marketingThemeItem));

        newFilteredMarketingThemes[marketingThemeItemIndex] = {
          ...marketingThemeItem,
          selected: false,
        };
      }
    }

    setMarketingThemeToFilter(newFilteredMarketingThemes);
  };

  const handleSelectStory = (selectedMarketingStory: string): void => {
    let newFilteredMarketingStories = [...marketingStoriesToFilter];

    const marketingStoryItem = marketingStoriesToFilter.find(
      ({ marketingStoryCode }) => marketingStoryCode === selectedMarketingStory
    );

    const marketingStoryItemIndex = marketingStoriesToFilter.findIndex(
      ({ marketingStoryCode }) => marketingStoryCode === selectedMarketingStory
    );

    if (marketingStoryItem) {
      const marketingStoryIsSelected = marketingStoryItem.selected;

      if (!marketingStoryIsSelected) {
        dispatch(selectMarketingStory(marketingStoryItem));
        newFilteredMarketingStories[marketingStoryItemIndex] = {
          ...marketingStoryItem,
          selected: true,
        };
      } else {
        dispatch(deselectMarketingStory(marketingStoryItem));
        newFilteredMarketingStories[marketingStoryItemIndex] = {
          ...marketingStoryItem,
          selected: false,
        };
      }
    }

    setMarketingStoriesToFilter(newFilteredMarketingStories);
  };

  const handleSelectAllClick = (
    filteredType: 'marketingTheme' | 'marketingStore'
  ): void => {
    let list =
      filteredType === 'marketingTheme'
        ? marketingThemeToFilter
        : marketingStoriesToFilter;

    switch (filteredType) {
      case 'marketingTheme':
        list = marketingThemeToFilter.map(li => ({
          ...li,
          selected: !isSelectedAllMarketingThemes,
        }));
        dispatch(
          manageSelectAllMarketingThemes(list, !isSelectedAllMarketingThemes)
        );
        setMarketingThemeToFilter(list);
        break;

      case 'marketingStore':
        list = marketingStoriesToFilter.map(li => ({
          ...li,
          selected: !isSelectedAllMarketingStories,
        }));
        dispatch(
          manageSelectAllMarketingStories(list, !isSelectedAllMarketingStories)
        );
        setMarketingStoriesToFilter(list);
        break;
    }
  };

  const hasThemesOrStories =
    (marketingThemeToFilter && marketingThemeToFilter.length) ||
    (marketingStoriesToFilter && marketingStoriesToFilter.length);

  const applyClickHandler = (): void =>
    isSohProcess
      ? history.replace(AppRoutes.SOH_ALIGNMENT_FILTER_SUMMARY)
      : history.replace(AppRoutes.CYCLE_COUNT_FILTER_SUMMARY);

  const backClickHandler = (): void => {
    isSohProcess
      ? history.replace(AppRoutes.SOH_ALIGNMENT_FILTER_BRAND)
      : history.replace(AppRoutes.CYCLE_COUNT_FILTER_BRAND);
  };

  return (
    <StyledPageContainer>
      <StyledTitle font="heavy" size="lg">
        {isSohProcess ? t('filterYourSoh') : t('cycleCount.filtertitle')}
      </StyledTitle>
      <TabCycleCount index={0} />
      {hasThemesOrStories ? (
        <>
          <CycleCountSummaryList />

          <StyledSearchWrapper>
            <Typography font="heavy" as="h1" size="lg">
              {' '}
              {t('cycleCount.filterdescription')}{' '}
            </Typography>

            <StyledSearchBar
              placeholder={t('cycleCount.marketingplaceholder')}
              value={searchedMarketingTheme}
              onChange={searchInputHandler}
              disabled
              loading={false}
              hideButton
              label=""
              onSearch={(): void => {}}
            />
          </StyledSearchWrapper>

          <StyledListWrapper>
            <UIListHeader
              text={t('cycleCount.marketingtitle')}
              extraText={t('quantity')}
            />

            <UIList margin={[0, 0, 120]} padding={[0]}>
              {marketingStoriesToFilter?.length > 0 ? (
                <TitleListWrapper>
                  <Typography font="heavy" as="p" size="lg">
                    {t('cycleCount.marketingstory')}
                  </Typography>

                  <Link
                    component="button"
                    variant="body2"
                    underline="always"
                    onClick={(): void => handleSelectAllClick('marketingStore')}
                    color="primary"
                    style={
                      isSelectedOneMarketingThemes
                        ? { color: '#999' }
                        : { cursor: 'pointer' }
                    }
                    disabled={isSelectedOneMarketingThemes}
                  >
                    {!isSelectedAllMarketingStories
                      ? t('selectAll')
                      : t('deselectAll')}
                  </Link>
                </TitleListWrapper>
              ) : (
                <span />
              )}

              {marketingStoriesToFilter?.map(
                (
                  {
                    marketingStoryCode,
                    marketingStoryDescription,
                    itemQuantity,
                    selected,
                  },
                  index
                ) => (
                  <UIListItem
                    key={`${marketingStoryCode}-${index}-marketing`}
                    text={marketingStoryDescription}
                    checked={selected}
                    onClick={(): void => handleSelectStory(marketingStoryCode!)}
                    disabled={isSelectedOneMarketingThemes}
                  >
                    <StyledCycleCountChip
                      size="small"
                      label={
                        <Trans
                          i18nKey="itemQuantity"
                          values={{ itemQuantity }}
                        />
                      }
                    />
                  </UIListItem>
                )
              )}

              {marketingThemeToFilter?.length > 0 ? (
                <TitleListWrapper>
                  <Typography font="heavy" as="p" size="lg">
                    {' '}
                    {t('cycleCount.marketingtheme')}{' '}
                  </Typography>

                  <Link
                    component="button"
                    variant="body2"
                    underline="always"
                    onClick={(): void => handleSelectAllClick('marketingTheme')}
                    color={'primary'}
                    style={
                      isSelectedOneMarketingStories
                        ? { color: '#999' }
                        : { cursor: 'pointer' }
                    }
                    disabled={isSelectedOneMarketingStories}
                  >
                    {!isSelectedAllMarketingThemes
                      ? t('selectAll')
                      : t('deselectAll')}
                  </Link>
                </TitleListWrapper>
              ) : (
                <span />
              )}

              {marketingThemeToFilter?.map(
                (
                  {
                    marketingThemeCode,
                    marketingThemeDescription,
                    itemQuantity,
                    selected,
                  },
                  index
                ) => (
                  <UIListItem
                    key={`${marketingThemeCode}-${index}-marketing`}
                    text={marketingThemeDescription}
                    checked={selected}
                    onClick={(): void => handleSelectTheme(marketingThemeCode!)}
                    disabled={isSelectedOneMarketingStories}
                  >
                    <StyledCycleCountChip
                      size="small"
                      label={
                        <Trans
                          i18nKey="itemQuantity"
                          values={{ itemQuantity }}
                        />
                      }
                    />
                  </UIListItem>
                )
              )}
            </UIList>
          </StyledListWrapper>
        </>
      ) : (
        <div />
      )}
      <CTAContainer
        type="APPLY"
        onClick={applyClickHandler}
        onBackClick={backClickHandler}
      />
    </StyledPageContainer>
  );
};

PageCycleCountFilterMarketing.displayName = 'PageCycleCountFilterMarketing';

export default PageCycleCountFilterMarketing;
