import React, { FC, useState, useEffect } from 'react';
import { FormControlLabel, IconButton, ModalProps } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import styled from 'styled-components';

import { UIModal } from '@/components/ui/Modal';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { useTranslation } from 'react-i18next';
import { UIAutoCompleteStores } from '@/components/ui/AutoComplete';
import { StoreWithPrinters } from '@/types/store';
import { UIButton } from '@/components/ui/Button';
import { SelectStore } from '@/types/profiling';
import { useAppDispatch } from '@/app/store';
import { insertStores } from '@/features/profiling/usersSlice';
import { useSelector } from '@/hooks/useSelector';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { UIAction } from '@/components/ui/Action';

export interface ModalSelectStoreProps
  extends Omit<ModalProps, 'onClose' | 'children'> {
  onClose: () => void;
  setSaveEnable?: React.Dispatch<React.SetStateAction<boolean>>;
  setStoreCodesToUndo?: React.Dispatch<React.SetStateAction<string[]>>;
  setAlertUndoSnackbarVisibility?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

//#region - Styled Components
export const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 22px;
  position: relative;
`;

export const StyledCloseIcon = styled(IconButton)`
  align-self: flex-end;
  right: 12px;
  top: 12px;
  position: absolute !important;
  z-index: 1;

  svg {
    fill: #005192;
  }
`;

export const StyledControlLabel = styled(FormControlLabel)`
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }

  svg {
    fill: #005192;
  }
`;

const StyledContent = styled(UIBox)`
  width: 90%;
  flex-direction: column;
  margin-top: 12px;

  & > button {
    width: 100%;
  }
`;

const SyledLabel = styled(Typography)`
  margin-bottom: 32px;
`;

const StyledList = styled(UIBox)`
  flex-direction: column;
  height: 240px;
  margin-bottom: 18px;
  overflow-y: auto;
`;

const StyledListHeader = styled(UIBox)`
  margin-top: 24px;
  padding: 4px 8px;
  border-bottom: 1px solid #c4c4c4;
`;

const StyledListItem = styled(UIBox)`
  padding: 12px 0;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(UIButton)`
  margin-top: auto;
`;
//#endregion

const ModalSelectStore: FC<ModalSelectStoreProps> = ({
  open,
  onClose,
  setSaveEnable,
  setStoreCodesToUndo,
  setAlertUndoSnackbarVisibility,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { storeBySalesOrganization, defaultStore } = useSelector(
    state => state.profiling.user.stores
  );

  const [storesToInsert, setStoresToInsert] = useState<SelectStore[]>([]);

  const [selectedStore, setSelectedStore] = useState<StoreWithPrinters>();
  const [alertSnackbarIsVisible, setAlertSnackbarVisibility] =
    useState<boolean>(false);

  useEffect(() => {
    if (!open) {
      setSelectedStore(undefined);
      setAlertSnackbarVisibility(false);
      setStoresToInsert([]);
    }
  }, [open, setAlertUndoSnackbarVisibility]);

  useEffect(() => {
    if (selectedStore) {
      setStoresToInsert(prevState => {
        const store = prevState.find(
          ({ storeCode }) => selectedStore.storeCode === storeCode
        );

        const storeInRedux = [defaultStore, ...storeBySalesOrganization!].find(
          store => selectedStore.storeCode === store?.storeCode
        );

        if (store || storeInRedux) {
          setAlertSnackbarVisibility(true);
          return prevState;
        }

        return [...prevState, selectedStore];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore]);

  useEffect(() => {
    if (selectedStore) {
      setStoreCodesToUndo?.(prevState => [
        ...prevState,
        selectedStore.storeCode!,
      ]);
    }
  }, [selectedStore, setStoreCodesToUndo]);

  const onRemoveStoreClick = (storeCode: string): void => {
    setStoresToInsert(prevState =>
      prevState.filter(store => store.storeCode !== storeCode)
    );
  };

  const onApplyClick = (): void => {
    setAlertSnackbarVisibility(false);
    setAlertUndoSnackbarVisibility?.(true);
    dispatch(insertStores(storesToInsert));
    setSaveEnable?.(true);
    onClose();
  };

  return (
    <>
      <UIModal
        $minWidth="480px"
        $minHeight="420px"
        open={open}
        onClose={onClose}
      >
        <StyledWrapper>
          <StyledCloseIcon onClick={onClose}>
            <HighlightOffIcon />
          </StyledCloseIcon>
          <StyledContent>
            <SyledLabel font="heavy">{t('searchStoreToAdd')}</SyledLabel>
            <UIAutoCompleteStores
              inputPlaceholder={t('addStore')}
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStore}
            />
            {storesToInsert.length > 0 && (
              <StyledList>
                <StyledListHeader>
                  <Typography size="xs">
                    {t('storeToInsert', {
                      count: storesToInsert.length,
                    })}
                  </Typography>
                </StyledListHeader>
                {storesToInsert.map(
                  //@ts-ignore
                  ({ storeCode, storeAddress }) => (
                    <StyledListItem>
                      <Typography>
                        {storeCode} -{' '}
                        {storeAddress.length > 20
                          ? storeAddress.substr(0, 20)
                          : storeAddress}
                      </Typography>
                      <UIAction
                        onClick={(): void => onRemoveStoreClick(storeCode!)}
                        label={t('remove')}
                        icon="delete"
                      />
                    </StyledListItem>
                  )
                )}
              </StyledList>
            )}
            <StyledButton
              onClick={onApplyClick}
              disabled={!defaultStore?.storeCode}
              label={t('apply')}
            />
          </StyledContent>
        </StyledWrapper>
      </UIModal>
      <AlertSnackbar
        open={alertSnackbarIsVisible && !!selectedStore}
        setIsOpen={setAlertSnackbarVisibility}
        message={`The store code: ${selectedStore?.storeCode} already exist in the list`}
      />
    </>
  );
};

export default ModalSelectStore;
