/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PosSyntacticExceptionFieldEnum {
  STORE_CODE = 'STORE_CODE',
  UPC_CODE = 'UPC_CODE',
  TAG_CODE = 'TAG_CODE',
  TRANSACTION_ID = 'TRANSACTION_ID',
  TRANSACTION_DATE = 'TRANSACTION_DATE',
  REASON_CODE = 'REASON_CODE',
}
