import React from 'react';
import { useAsync } from 'react-use';
import {
  HubConnectionBuilder,
  HubConnectionState,
  HubConnection,
  LogLevel,
} from '@microsoft/signalr';
import { useSelector } from '@/hooks/useSelector';
import { Process } from '@/hooks/useHandleProcess';

const baseURL = process.env.REACT_APP_SIGNALR_URL;

const encode = <T,>(b: T): string => {
  return btoa(JSON.stringify(b));
};

interface ISignalRContext {
  connected: boolean;
  connect: (process: CustomProcess) => Promise<void>;
  disconnect: () => Promise<void>;
  subscribeStore: () => Promise<void>;
  unsubscribeStore: () => Promise<void>;
  retrieveStatus: (process: CustomProcess) => Promise<unknown>;
  send: <T>(body: T) => Promise<void>;
  error: boolean;
  errorMessage: string;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  connection?: HubConnection;
}

type CustomProcess = Process | 'DEFAULT';

export interface SignalRError {
  visible: boolean;
  message: string;
}
export interface DefaultSignalRData {
  userId: string;
  storeCode: string;
  process: CustomProcess;
}

export interface SubscribeGroupBody extends DefaultSignalRData {
  connectionId: string;
}

export interface MessageBody<T> extends DefaultSignalRData {
  data: T;
}

const SignalRContext = React.createContext<ISignalRContext>({
  connected: false,

  connect: async () => {},
  disconnect: async () => {},

  send: async () => {},
  subscribeStore: async () => {},
  unsubscribeStore: async () => {},
  retrieveStatus: async () => {},

  error: false,
  errorMessage: '',
  setError: () => {},
});

export const SignalRContextProvider: React.FC = ({ children }) => {
  const [connection, setConnection] = React.useState<HubConnection>();
  const [process, setProcess] = React.useState<CustomProcess>();
  const [connectionStatus, setConnectionStatus] =
    React.useState<HubConnectionState>(HubConnectionState.Disconnected);
  const [error, setError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const userId = useSelector(state => state.user.username || '');
  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );

  useAsync(async () => {
    if (!connection && storeCode && userId) {
      const info = await fetch(
        `${baseURL}/negotiate?storeCode=${storeCode}&userId=${userId}&hubname=srosignalr`,
        {
          method: 'POST',
          headers: {
            'x-ms-signalr-storeCode': storeCode,
            'x-ms-signalr-userId': userId,
          },
        }
      );

      const { url, accessToken } = await info.json();

      const hubConnection = new HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => accessToken,
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      setConnection(hubConnection);
    }
  }, [storeCode, userId, connection]);

  const showError = (message: string): void => {
    setError(true);
    setErrorMessage(message);
  };

  const connected = React.useMemo(
    () => connectionStatus === HubConnectionState.Connected,
    [connectionStatus]
  );

  const subscribeStore = React.useCallback(async () => {
    if (connected) {
      if (connection!.connectionId) {
        const body: SubscribeGroupBody = {
          userId,
          storeCode,
          process: process || 'DEFAULT',
          connectionId: connection!.connectionId,
        };

        try {
          await fetch(`${baseURL}/subscribe`, {
            method: 'POST',
            body: JSON.stringify({
              encoded: encode(body),
            }),
          });

          console.group(`Signalr - Subscribe`);
          console.log(`User ${userId} subscribed to store: ${storeCode}`);
          console.groupEnd();
        } catch {
          showError('An error occured while subscribing store');
        }
      }
    } else {
      showError('You must be connected before subscribe to the store');
    }
  }, [connected, connection, process, storeCode, userId]);

  const unsubscribeStore = React.useCallback(async () => {
    if (connected) {
      if (connection!.connectionId) {
        const body: SubscribeGroupBody = {
          userId,
          storeCode,
          process: process || 'DEFAULT',
          connectionId: connection!.connectionId,
        };

        try {
          await fetch(`${baseURL}/unsubscribe`, {
            method: 'POST',
            body: JSON.stringify({
              encoded: encode(body),
            }),
          });

          console.group(`Signalr - Unubscribe`);
          console.log(`User ${userId} unubscribed from store: ${storeCode}`);
          console.groupEnd();
        } catch {
          showError('An error occured while subscribing store');
        }
      }
    } else {
      showError('You must be connected before unsubscribe from the store');
    }
  }, [connected, connection, process, storeCode, userId]);

  const send = React.useCallback(
    async <T,>(body: T) => {
      if (connected) {
        const message: MessageBody<T> = {
          userId,
          storeCode,
          process: process || 'DEFAULT',
          data: body,
        };

        try {
          await fetch(`${baseURL}/messages`, {
            method: 'POST',
            body: JSON.stringify({
              encoded: encode(message),
            }),
          });

          console.group(`Signalr - Message`);
          console.log(`Message sent to users subscribed at store ${storeCode}`);
          console.log(`Data sent`, message);
          console.groupEnd();
        } catch {
          showError('An error occured while sending data');
        }
      } else {
        showError('You must be connected before send data');
      }
    },
    [connected, process, storeCode, userId]
  );

  const connect = React.useCallback(
    async (process: CustomProcess) => {
      if (connection) {
        try {
          setProcess(process);
          await connection.start();
          setConnectionStatus(connection.state);

          console.group(`Signalr - Connect`);
          console.log(
            `SignalR connection state: ${connection.state}. Process: ${process}`
          );
          console.groupEnd();
        } catch (e) {
          console.log('Connection failed: ', e);
        }
      }
    },
    [connection]
  );

  const disconnect = React.useCallback(async () => {
    if (connection) {
      try {
        await connection.stop();

        setConnectionStatus(connection.state);
        setConnection(undefined);
        setProcess(undefined);

        console.group(`Signalr - Disconnect`);
        console.log(`SignalR connection state: ${connection.state}.`);
        console.groupEnd();
      } catch (e) {
        console.log('Connection failed: ', e);
      }
    }
  }, [connection]);

  const retrieveStatus = React.useCallback(
    async (process: CustomProcess) => {
      if (connection) {
        try {
          const response = await fetch(
            `${baseURL}/status?store=${storeCode}&process=${process}`,
            {
              method: 'GET',
            }
          );

          return await response.json();
        } catch {
          showError('An error occured while retrieving loader status');
        }
      }
    },
    [connection, storeCode]
  );

  return (
    <SignalRContext.Provider
      value={{
        connection,
        connected,
        connect,
        disconnect,
        send,
        subscribeStore,
        unsubscribeStore,
        retrieveStatus,
        error,
        errorMessage,
        setError,
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export const useSignalRContext = (): ISignalRContext =>
  React.useContext(SignalRContext);
