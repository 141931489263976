import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useSelector } from '@/hooks/useSelector';
import useSROTrackEvent from '@/hooks/useSROTrackEvent';
import { InvalidProductDetail } from '@/api/receive';

type TrackInvalidUPCReceiving = {
  User: string;
  StoreCode: string;
  UPC: string;
  EPC: string;
  LinkedEvent: string;
};

type ReturnHookType = (invalidUpcs: InvalidProductDetail[]) => Promise<void>;

const useTrackInvalidUpc = (): ReturnHookType => {
  const appInsights = useAppInsightsContext();
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const { username } = useSelector(state => state.user);
  const { selectedDeliveries } = useSelector(state => state.deliveries);

  const data: TrackInvalidUPCReceiving = {
    User: username || '',
    StoreCode: storeCode || '',
    UPC: '',
    EPC: '',
    LinkedEvent: selectedDeliveries
      .map(({ codeDelivery }) => codeDelivery)
      .join(', '),
  };

  const trackEvent = useSROTrackEvent<TrackInvalidUPCReceiving>(
    appInsights,
    'InvalidUPCReceiving',
    data
  );

  const trackInvalidUpc = async (
    invalidUpcs: InvalidProductDetail[]
  ): Promise<void> => {
    const track = async (upcCode: string, epcCode: string): Promise<void> => {
      await trackEvent({
        ...data,
        UPC: upcCode,
        EPC: epcCode,
      });
    };
    for await (const invalidUpc of invalidUpcs) {
      await track(invalidUpc.upcCode!, invalidUpc.epcCode!);
    }
  };

  return trackInvalidUpc;
};

export default useTrackInvalidUpc;
