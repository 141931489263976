/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackInStockRequest } from '../models/BackInStockRequest';
import type { BackInStockSearchResponse } from '../models/BackInStockSearchResponse';
import { request as __request } from '../core/request';

export class BackInStockService {
  /**
   * retrieve back in stock data
   * retrieve back in stock data
   * @returns BackInStockSearchResponse OK
   * @throws ApiError
   */
  public static async backinstockSearch({
    requestBody,
  }: {
    requestBody: BackInStockRequest;
  }): Promise<Array<BackInStockSearchResponse>> {
    const result = await __request({
      method: 'POST',
      path: `/backinstock/search`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * add back in stock data
   * add back in stock data
   * @returns any OK
   * @throws ApiError
   */
  public static async backinstockAdd({
    requestBody,
  }: {
    requestBody: BackInStockRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/backinstock/add`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
