import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector } from '@/hooks/useSelector';

import type { CustomRecallBrand, CustomRecallUpc } from '@/types/recallSlice';
import type { RecallStatus as RcStatus } from '@/api/receive';
import { useAppDispatch } from '@/app/store';
import { removeTags, setRecall } from '@/features/recall/recallSlice';
import { useParams } from 'react-router';

export type RecallItems = {
  found: CustomRecallUpc[];
  error: CustomRecallUpc[];
  missing: CustomRecallUpc[];
};

export type RecallStatus = RcStatus['status'];
interface IRecallContext {
  backup: RecallItems;
  setBackup: React.Dispatch<React.SetStateAction<RecallItems>>;
  recallItems: RecallItems;
  recallStatus: RecallStatus;
  setRecallItems: React.Dispatch<React.SetStateAction<RecallItems>>;
  setRecallStatus: React.Dispatch<React.SetStateAction<RecallStatus>>;
  setProductBrands: React.Dispatch<React.SetStateAction<CustomRecallBrand[]>>;
  recallConfirmed: boolean;
  setRecallConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  findItemsThroughTags: () => void;
}

const RecallContext = React.createContext<IRecallContext>({
  recallItems: {
    found: [],
    missing: [],
    error: [],
  },
  backup: {
    found: [],
    missing: [],
    error: [],
  },
  recallStatus: 'New',
  setRecallItems: () => {},
  setRecallStatus: () => {},
  setProductBrands: () => {},
  setBackup: () => {},
  recallConfirmed: false,
  setRecallConfirmed: () => {},
  findItemsThroughTags: () => {},
});

export const RecallContextProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const { idRecall } = useParams<{ idRecall: string }>();
  const [productBrands, setProductBrands] = useState<CustomRecallBrand[]>([]);
  const [recallConfirmed, setRecallConfirmed] = useState<boolean>(false);
  const [recallStatus, setRecallStatus] = useState<RecallStatus>('New');

  const [backup, setBackup] = useState<RecallItems>({
    found: [],
    error: [],
    missing: [],
  });

  const [recallItems, setRecallItems] = useState<RecallItems>({
    found: [],
    error: [],
    missing: [],
  });

  const { brands } = useSelector(state => state.recall);
  const { tags } = useSelector(state => state.devices);

  useEffect(() => {
    if (brands.length > 0) {
      setProductBrands(brands);
    }
  }, [brands]);

  useEffect(() => {
    if (idRecall) {
      dispatch(
        setRecall({
          idRecall,
          recallStatus,
        })
      );
    }
  }, [dispatch, idRecall, recallStatus]);

  const products = useMemo(
    () => productBrands.flatMap(({ upcs }) => upcs),
    [productBrands]
  );

  const findItemsThroughTags = useCallback(() => {
    if (tags.length > 0) {
      const removeEpcs = (epcs: string[]): string[] => {
        return epcs.filter(epcCode => !tags.includes(epcCode));
      };

      const addEpcs = (epcs: string[]): string[] => {
        return epcs.filter(epcCode => tags.includes(epcCode));
      };

      const removeItem = (
        items: CustomRecallUpc[],
        itemToRemove: CustomRecallUpc
      ): CustomRecallUpc[] => {
        return items.filter(({ upcCode }) => upcCode !== itemToRemove.upcCode);
      };

      const recallItems = products.reduce(
        (prev, curr) => {
          if (curr.found && curr.missing) {
            const { missing, found } = curr;

            const item: CustomRecallUpc = {
              ...curr,
              missing: removeEpcs(missing),
              found: [...new Set([...found, ...addEpcs(missing)])],
            };

            if (item.missing && item.found) {
              // Not Found condition
              if (item.found.length < item.recallQuantity) {
                return {
                  ...prev,
                  missing: [...prev.missing, item],
                };
              }

              // Error condition
              if (item.found.length > item.recallQuantity) {
                return {
                  ...prev,
                  missing: removeItem(prev.missing, item),
                  found: removeItem(prev.found, item),
                  error: [...prev.error, item],
                };
              }

              // Found condition
              if (item.found.length === item.recallQuantity) {
                return {
                  ...prev,
                  missing: removeItem(prev.missing, item),
                  found: [...new Set([...prev.found, item])],
                };
              }
            }
          }

          return prev;
        },
        {
          found: [],
          missing: [],
          error: [],
        } as RecallItems
      );

      setRecallItems(prevState => ({
        ...prevState,
        ...recallItems,
      }));

      dispatch(removeTags());
    }
  }, [dispatch, products, tags]);

  return (
    <RecallContext.Provider
      value={{
        recallItems,
        backup,
        recallStatus,
        setRecallStatus,
        setRecallItems,
        setProductBrands,
        setBackup,
        setRecallConfirmed,
        recallConfirmed,
        findItemsThroughTags,
      }}
    >
      {children}
    </RecallContext.Provider>
  );
};

export const useRecallContext = (): IRecallContext =>
  React.useContext(RecallContext);
