import { FC, memo, useEffect, useState } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

export interface UIErrorProps {
  code: number;
  message: string;
  source: string;
  env: 'backend' | 'frontend';
}

const ErrorFallback = ({ error }: FallbackProps): JSX.Element => {
  const [err, setError] = useState<UIErrorProps>();

  useEffect(() => {
    setError({
      code: 0,
      message: error.message,
      source: '',
      env: 'backend',
    });
  }, [error]);

  console.log(err);

  return <div></div>;
};

const UIError: FC = memo(
  ({ children }): JSX.Element => (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  )
);

UIError.displayName = 'UIError';

export default UIError;
