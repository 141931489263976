import { SearchReturnItem } from '@/api/receive';
import { CustomReturnPosItem } from '@/types/returnPos';

export const formatData = (
  products: SearchReturnItem[]
): CustomReturnPosItem[] => {
  const items: CustomReturnPosItem[] = [];

  products.forEach(({ reasons, ...p }) => {
    reasons?.forEach(reason => {
      reason.dates?.forEach((_, index) => {
        items.push({
          ...p,
          ...reason.dates?.[index],
          posReasonCode: reason.posReasonCode,
          isInGoodCondition: reason.isInGoodCondition,
        });
      });
    });
  });

  return items;
};
