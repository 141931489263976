import { FC } from 'react';
import styled from 'styled-components';

import List, { ListProps as MUIListProps } from '@material-ui/core/List';

import { UIBox } from '../Box';
import { Typography } from '@/components/ui/Typography';

export interface UIListProps extends ListProps {
  title?: React.ReactNode;
  itemsCount?: React.ReactNode;
  itemsWarning?: React.ReactNode;
  rightHeader?: React.ReactNode;
  className?: string;
}

export interface ListProps extends Omit<MUIListProps, 'title'> {
  bordered?: boolean;
  rounded?: boolean;
  backgrounded?: boolean;
  shadowed?: boolean;
  margin?: number[];
  padding?: number[];
}

const StyledListContainer = styled(UIBox)<{
  margin?: number[];
  padding?: number[];
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }): string =>
    padding ? padding?.map((p: number) => `${p}px`).join(' ') : '24px'};
  margin: ${({ margin }): string | undefined =>
    margin?.map((m: number) => `${m}px`).join(' ')};

  .items {
    align-self: baseline;
    margin: 0 0 8px 0;
  }
`;

const StyledListHeader = styled(UIBox)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 16px;

  && > h1 {
    align-self: initial;
    margin: 0;
  }
`;

const StyledListHeaderWarning = styled(UIBox)`
  width: auto;
`;

const StyledListHeaderRight = styled.div`
  ${({ theme }): string => `
		display: flex;
		justify-content: space-around;
		margin-right: 24px;
		margin-left: auto;
		width: 350px;
		${theme.breakpoints.down('sm')} {
			width: 200px;
		}
	`}
`;

const StyledListWrapper = styled('div')<ListProps>`
  width: 100%;
  box-shadow: ${({ shadowed }): string =>
    shadowed ? ' 0px 0px 10px rgba(0, 0, 0, 0.1)' : 'none'};
  background: ${({ backgrounded }): string =>
    backgrounded ? '#FBFBFB' : 'none'};
  border-radius: ${({ rounded }): string => (rounded ? '8px' : '0')};
  border: ${({ bordered }): string =>
    bordered ? '1px solid #EDEDED' : 'none'};
`;

const StyledList = styled(List)`
  margin-top: 0;
  padding: 0;
`;

export const UIList: FC<UIListProps> = ({
  title,
  children,
  itemsCount,
  itemsWarning,
  rightHeader,
  backgrounded,
  bordered,
  rounded,
  shadowed,
  margin,
  padding,
  className,
  ...props
}) => (
  <StyledListContainer className={className} margin={margin} padding={padding}>
    {title && (
      <Typography size="lg" font="heavy" as="h1">
        {title}
      </Typography>
    )}
    {itemsCount && (
      <StyledListHeader>
        <Typography className="items" size="lg" font="heavy" as="h1">
          {itemsCount}
        </Typography>
        {itemsWarning && (
          <StyledListHeaderWarning>{itemsWarning}</StyledListHeaderWarning>
        )}
      </StyledListHeader>
    )}
    <StyledListHeaderRight>{rightHeader}</StyledListHeaderRight>
    <StyledListWrapper
      backgrounded={backgrounded}
      rounded={rounded}
      bordered={bordered}
      shadowed={shadowed}
    >
      <StyledList {...props}>{children}</StyledList>
    </StyledListWrapper>
  </StyledListContainer>
);

UIList.displayName = 'UIList';

export default UIList;
