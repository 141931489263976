/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfimCreateDamages } from '../models/ConfimCreateDamages';
import type { ConfimCreateDamagesPosRequest } from '../models/ConfimCreateDamagesPosRequest';
import type { ConfimCreateDamagesResponse } from '../models/ConfimCreateDamagesResponse';
import type { ConfirmDamagesAndReturnsEncoded } from '../models/ConfirmDamagesAndReturnsEncoded';
import type { PdfFile } from '../models/PdfFile';
import type { ReasonList } from '../models/ReasonList';
import type { SearchItemsForCreateDamages } from '../models/SearchItemsForCreateDamages';
import type { SearchItemsForCreateDamagesList } from '../models/SearchItemsForCreateDamagesList';
import { request as __request } from '../core/request';

export class DamagesAndReturnsService {
  /**
   * DamagesAndReturnService service for getCreateDamagesReasonCode
   * getCreateDamagesReasonCode
   * @returns ReasonList OK
   * @throws ApiError
   */
  public static async damagesGetCreateDamagesReasonCode({
    storeCode,
  }: {
    /** filter for storeCode **/
    storeCode: any;
  }): Promise<ReasonList> {
    const result = await __request({
      method: 'GET',
      path: `/damages/getCreateDamagesReasonCode`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * searchItemsForCreateDamages
   * searchItemsForCreateDamages
   * @returns SearchItemsForCreateDamagesList OK
   * @throws ApiError
   */
  public static async damagesSearchItemsForCreateDamages({
    requestBody,
  }: {
    requestBody: SearchItemsForCreateDamages;
  }): Promise<SearchItemsForCreateDamagesList> {
    const result = await __request({
      method: 'POST',
      path: `/damages/searchItemsForCreateDamages`,
      body: requestBody,
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirmCreateDamages
   * confirmCreateDamages
   * @returns ConfimCreateDamagesResponse Create Damages.
   * @throws ApiError
   */
  public static async damagesConfirmCreateDamages({
    requestBody,
  }: {
    requestBody: ConfimCreateDamages;
  }): Promise<ConfimCreateDamagesResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/damages/confirmCreateDamages`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirmCreateDamagesEncoded
   * confirmCreateDamagesEncoded
   * @returns ConfimCreateDamagesResponse Create Damages.
   * @throws ApiError
   */
  public static async damagesConfirmCreateDamagesEncoded({
    requestBody,
  }: {
    requestBody: ConfirmDamagesAndReturnsEncoded;
  }): Promise<ConfimCreateDamagesResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/damages/confirmCreateDamagesEncoded`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * findItemsForValidateDamages
   * findItemsForValidateDamages
   * @returns SearchItemsForCreateDamagesList OK
   * @throws ApiError
   */
  public static async damagesFindItemsForValidateDamages({
    storeCode,
    date,
  }: {
    /** filter for storeCode **/
    storeCode: any;
    /** filter for date **/
    date: any;
  }): Promise<SearchItemsForCreateDamagesList> {
    const result = await __request({
      method: 'GET',
      path: `/damages/findItemsForValidateDamages`,
      query: {
        storeCode: storeCode,
        date: date,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirmValidateDamages
   * confirmValidateDamages
   * @returns ConfimCreateDamagesResponse Create Damages.
   * @throws ApiError
   */
  public static async damagesConfirmValidateDamages({
    requestBody,
  }: {
    requestBody: ConfimCreateDamages;
  }): Promise<ConfimCreateDamagesResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/damages/confirmValidateDamages`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirmValidateDamagesEncoded
   * confirmValidateDamagesEncoded
   * @returns ConfimCreateDamagesResponse Create Damages.
   * @throws ApiError
   */
  public static async damagesConfirmValidateDamagesEncoded({
    requestBody,
  }: {
    requestBody: ConfirmDamagesAndReturnsEncoded;
  }): Promise<ConfimCreateDamagesResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/damages/confirmValidateDamagesEncoded`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirmBackToStock
   * confirmBackToStock
   * @returns ConfimCreateDamagesResponse Create Back To Stock.
   * @throws ApiError
   */
  public static async damagesConfirmBackToStock({
    requestBody,
  }: {
    requestBody: ConfimCreateDamages;
  }): Promise<ConfimCreateDamagesResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/damages/confirmBackToStock`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirmBackToStockEncoded
   * confirmBackToStockEncoded
   * @returns ConfimCreateDamagesResponse Create Back To Stock.
   * @throws ApiError
   */
  public static async damagesConfirmBackToStockEncoded({
    requestBody,
  }: {
    requestBody: ConfirmDamagesAndReturnsEncoded;
  }): Promise<ConfimCreateDamagesResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/damages/confirmBackToStockEncoded`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirmGoodBackReturn
   * confirmGoodBackReturn
   * @returns ConfimCreateDamagesResponse Create Damages.
   * @throws ApiError
   */
  public static async damagesConfirmGoodBackReturn({
    requestBody,
  }: {
    requestBody: ConfimCreateDamagesPosRequest;
  }): Promise<ConfimCreateDamagesResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/damages/confirmGoodBackReturn`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirmGoodBackReturnEncoded
   * confirmGoodBackReturnEncoded
   * @returns ConfimCreateDamagesResponse Create Damages.
   * @throws ApiError
   */
  public static async damagesConfirmGoodBackReturnEncoded({
    requestBody,
  }: {
    requestBody: ConfirmDamagesAndReturnsEncoded;
  }): Promise<ConfimCreateDamagesResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/damages/confirmGoodBackReturnEncoded`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirmBadCondition
   * confirmBadCondition
   * @returns ConfimCreateDamagesResponse Create Damages.
   * @throws ApiError
   */
  public static async damagesConfirmBadCondition({
    requestBody,
  }: {
    requestBody: ConfimCreateDamagesPosRequest;
  }): Promise<ConfimCreateDamagesResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/damages/confirmBadCondition`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirmBadConditionEncoded
   * confirmBadConditionEncoded
   * @returns ConfimCreateDamagesResponse Create Damages.
   * @throws ApiError
   */
  public static async damagesConfirmBadConditionEncoded({
    requestBody,
  }: {
    requestBody: ConfirmDamagesAndReturnsEncoded;
  }): Promise<ConfimCreateDamagesResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/damages/confirmBadConditionEncoded`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * DamagesAndReturnService service for download pdf
   * download pdf
   * @returns PdfFile OK
   * @throws ApiError
   */
  public static async damagesDownloadPdf({
    storeCode,
    documentNumber,
    date,
  }: {
    /** filter for storeCode **/
    storeCode: any;
    /** filter for documentNumber **/
    documentNumber: any;
    /** filter for date **/
    date: any;
  }): Promise<PdfFile> {
    const result = await __request({
      method: 'GET',
      path: `/damages/downloadPdf`,
      query: {
        storeCode: storeCode,
        documentNumber: documentNumber,
        date: date,
      },
      errors: {
        400: `Bad request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
