import React, { useCallback, useState } from 'react';

type CalendarDate = {
  currentDay: number;
  currentMonth: number;
  currentYear: number;
  setCurrentDay: React.Dispatch<React.SetStateAction<number>>;
  setCurrentMonth: React.Dispatch<React.SetStateAction<number>>;
  setCurrentYear: React.Dispatch<React.SetStateAction<number>>;
};

type UseCalendarReturnType = {
  date: CalendarDate;
  findEventPerMonth: (month: number, year: number) => boolean;
  findEventPerDay: (day: number, month: number, year: number) => boolean;
};

const useCalendar = (): UseCalendarReturnType => {
  const [currentDay, setCurrentDay] = useState<number>(new Date().getDate());
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  );

  const findEventPerMonth = useCallback(
    (month: number, year: number) =>
      month === currentMonth && year === currentYear,
    [currentMonth, currentYear]
  );

  const findEventPerDay = useCallback(
    (day: number, month: number, year: number) =>
      day === currentDay && month === currentMonth && year === currentYear,
    [currentDay, currentMonth, currentYear]
  );

  return {
    date: {
      currentDay,
      currentMonth,
      currentYear,
      setCurrentDay,
      setCurrentMonth,
      setCurrentYear,
    },
    findEventPerDay,
    findEventPerMonth,
  };
};

export default useCalendar;
