import {
  createAsyncThunk,
  createSlice,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit';
import { StoreInfoSlice, StoreWithPrinters } from '@/types/store';
import type { ReceiveApiError as ApiError } from '@/api';
import { ReceiveShipmentService } from '@/api/receive';

const initialState: StoreInfoSlice = {
  isLoading: false,
  hasError: false,
  store: sessionStorage.getItem('store')
    ? JSON.parse(sessionStorage.getItem('store')!)
    : undefined,
};

export const receiveGetStore = createAsyncThunk<
  StoreWithPrinters,
  string,
  { rejectValue: ApiError; dispatch: Dispatch }
>(
  'receiveship/readStoreByStoreCode',
  async (storeCode, { rejectWithValue, dispatch }) => {
    try {
      const res = await ReceiveShipmentService.receiveshipReadStoreByStoreCode({
        storeCode,
      });
      const storeWithPrinters = { ...res, printers: res.printers || null };
      await dispatch(currentStoreSlice.actions.setStore(storeWithPrinters));

      return storeWithPrinters;
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  }
);

const currentStoreSlice = createSlice({
  name: 'currentStore',
  initialState,
  reducers: {
    setStore: (state, { payload }: PayloadAction<StoreWithPrinters>) => {
      sessionStorage.setItem('store', JSON.stringify(payload));

      state.store = payload;
    },
    removeStore: state => {
      sessionStorage.removeItem('store');

      state.store = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(receiveGetStore.pending, state => {
        state.isLoading = true;
      })
      .addCase(receiveGetStore.fulfilled, state => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(receiveGetStore.rejected, (state, { error }) => {
        state.isLoading = false;
        state.hasError = true;
        console.log(error);
      });
  },
});

export const { setStore, removeStore } = currentStoreSlice.actions;
export default currentStoreSlice.reducer;
