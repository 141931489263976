/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StockItemDetailList } from '../models/StockItemDetailList';
import type { StockItemList } from '../models/StockItemList';
import type { StockOnHandTagsResponse } from '../models/StockOnHandTagsResponse';
import { request as __request } from '../core/request';

export class StockSummaryService {
  /**
   * retrieve stock items
   * retrieve stock items
   * @returns StockItemList OK
   * @throws ApiError
   */
  public static async stockFindStockItems({
    storeId,
  }: {
    /** filter for storeId **/
    storeId: any;
  }): Promise<StockItemList> {
    const result = await __request({
      method: 'GET',
      path: `/stock/findStockItems`,
      query: {
        storeId: storeId,
      },
      errors: {
        400: `bad request`,
      },
    });
    return result.body;
  }

  /**
   * retrieve stock item detail
   * retrieve stock items detail. It must to pass storeCode param AND at least one between brand or upcCode.
   * @returns StockItemDetailList OK
   * @throws ApiError
   */
  public static async stockFindStockItemDetails({
    storeId,
    brand,
    upcCode,
    modelCode,
    epcCode,
  }: {
    /** filter for storeId **/
    storeId: any;
    /** filter for brand. For long brand names with space and special char it needs to be encoded. For example brand 'DOLCE & GABBANA' will be 'DOLCE%20%26%20GABBANA' **/
    brand?: string;
    /** filter for upcCode **/
    upcCode?: string;
    /** filter for model **/
    modelCode?: string;
    /** filter for epc **/
    epcCode?: string;
  }): Promise<StockItemDetailList> {
    const result = await __request({
      method: 'GET',
      path: `/stock/findStockItemDetails`,
      query: {
        storeId: storeId,
        brand: brand,
        upcCode: upcCode,
        modelCode: modelCode,
        epcCode: epcCode,
      },
      errors: {
        400: `bad request`,
        500: `internal server error`,
      },
    });
    return result.body;
  }

  /**
   * returns the on-hand stock of a specific store
   * returns the on-hand stock of a specific store
   * @returns StockOnHandTagsResponse OK
   * @throws ApiError
   */
  public static async stockStockOnHand({
    storeCode,
  }: {
    /** filter for storeId **/
    storeCode: any;
  }): Promise<StockOnHandTagsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/stock/stockOnHand`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad request.`,
      },
    });
    return result.body;
  }

  /**
   * returns the on-hand stock of a specific country and date
   * returns the on-hand stock of a specific country and date
   * @returns StockOnHandTagsResponse OK
   * @throws ApiError
   */
  public static async stockStockOnHandByCountryAndDate({
    country,
    date,
  }: {
    /** filter for country **/
    country: any;
    /** filter for country **/
    date: any;
  }): Promise<StockOnHandTagsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/stock/stockOnHandByCountryAndDate`,
      query: {
        country: country,
        date: date,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
