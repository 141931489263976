import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { List, ListItem } from '@material-ui/core';
import { colors } from '@/theme';

export const StyledTitle = styled(UIBox)`
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`;

export const StyledList = styled(List)`
  width: 100%;
  margin-bottom: 100px;
`;

export const StyledListItem = styled(ListItem)<{ disabled?: boolean }>`
  width: 100%;
  background: #fbfbfb;
  border-radius: 4px;
  border: 1px solid #ededed;
  box-shadow: 0px 0px 10px 0px #00000040;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  &&& {
    pointer-events: ${({ disabled }): string =>
      disabled ? 'none' : 'auto'} !important;
  }
`;

export const StyledChip = styled(UIBox)<{ background?: string }>`
  border-radius: 100px;
  padding: 0px 8px;
  background: ${({ background }): string => (background ? background : 'grey')};
  color: ${colors.white};

  & > * {
    text-transform: uppercase;
  }
`;

export const StyledIconWrapper = styled(UIBox)<{ background?: string }>`
  margin-left: 8px;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: ${({ background }): string =>
    background ? background : 'transparent'};

  svg {
    width: 100%;
    height: 100%;
  }
`;
