import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { UIButtonWithIcon } from '@/components/ui/Button';

export const StyledFilterWrapper = styled(UIBox)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  padding: 0 24px 24px;
  margin-bottom: 16px;

  & > * {
    margin: 0;
    padding: 0 !important;
    align-self: flex-end;
  }
`;

export const StyledUpdateUserWrapper = styled.div`
  color: #005192;
  cursor: pointer;
  width: fit-content !important;
  align-self: center;
  align-items: center;
  display: flex;
`;

export const StyledButtons = styled(UIBox)`
  width: fit-content !important;
  gap: 16px;

  button {
    margin-top: 0;
  }
`;

export const StyledButtonWithIcon = styled(UIButtonWithIcon)`
  width: fit-content;
  align-self: center;
  margin-top: 16px;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  grid-column: 3 / -1;
  align-self: center;
  justify-self: end;
`;
