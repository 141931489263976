import { FC, useMemo } from 'react';
import styled from 'styled-components';
import UISelect from './Select';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { Controller, UseControllerProps } from 'react-hook-form';
import { FormInputs } from '@/types/hookFormInput';
import { useTranslation } from 'react-i18next';

const StyledInputWrapper = styled(UIBox)`
  padding: 24px;
  flex-direction: column;
`;

const StyledLabel = styled(Typography)`
  margin-bottom: 8px;
`;

const StyledError = styled(Typography)`
  && {
    color: red;
    font-size: 0.75rem;
    margin-top: 3px;
  }
`;

const SelectController: FC<
  UseControllerProps<FormInputs> & {
    value?: string; // | string[] | (() => void) | undefined;
    options: string[];
    required?: boolean;
    onSelectChange?: () => void;
    isErrorVisible?: boolean;
    disabled?: boolean;
  }
> = ({
  name,
  control,
  options,
  value,
  onSelectChange,
  required,
  isErrorVisible,
  disabled,
}) => {
  const { t, i18n } = useTranslation();

  const values = useMemo(
    () =>
      options.map(option => ({
        label: t(`jobtitle.${option.toLowerCase().replaceAll(' ', '-')}`),
        value: option,
      })),
    [options, t]
  );

  const languages = useMemo(() => {
    const userLanguages = i18n.languages.map(isoCode => ({
      label: t(`languages.${isoCode}`),
      value: isoCode,
    }));

    return userLanguages;
  }, [i18n.languages, t]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }): JSX.Element => (
        <StyledInputWrapper>
          <StyledLabel font="medium">
            {t(`${name}`)} {required && '*'}
          </StyledLabel>
          <UISelect
            {...field}
            onChange={(e): void => {
              field.onChange(e);
              onSelectChange?.();
            }}
            marg="0"
            selectwidth="100%"
            values={name === 'language' ? languages : values}
            value={value}
            variant="outlined"
            disabled={disabled}
          />
          {required && isErrorVisible && (
            <StyledError>{t('requiredField')}</StyledError>
          )}
        </StyledInputWrapper>
      )}
    />
  );
};

export default SelectController;
