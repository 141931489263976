import styled from 'styled-components';
import { Typography } from '../Typography';

export const StyledInfoContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 36px 0 0;
  &.second-row {
    margin: 10px 0 36px 36px;
  }
`;

export const StyledInfoRow = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StyledInfoColumn = styled('div')``;

export const StyledInfoLabel = styled.label`
  &:not(:last-of-type):after {
    margin-right: 4px;
    margin-left: 4px;
    content: '|';
  }
  ${({ theme }): string =>
    theme === 'bold'
      ? `
        font-weight: bold;

    `
      : ''}
`;

export const StyledActiveStore = styled.label`
  padding: 12px 0;
`;

export const ButtonText = styled(Typography)`
  cursor: pointer;
  text-transform: uppercase;
`;
