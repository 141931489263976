import { FC, memo, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import { SvgIcon } from '@material-ui/core';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { CalendarTodayRounded } from '@material-ui/icons';
import { ReactComponent as DeliveryIcon } from '@/assets/icons/delivery.svg';

import { UIWarning } from '@/components/ui/Warning';
import { useSelector } from '@/hooks/useSelector';
import {
  getDeliveryList,
  initDeliveryList,
  unlockSpecificDeliveries,
} from '@/features/deliveries/deliveriesSlice';
import { useAppDispatch } from '@/app/store';
import { DeliveryItems } from '@/api';
import { useTranslation } from 'react-i18next';

export interface UIListItemProps {
  index: number;
  handleClick?: (
    e: React.MouseEvent,
    codeDelivery: string,
    index: number
  ) => void;
  checked?: number[];
}

//#region - Styled Components
interface StyledListItemStatusProps {
  inUse?: boolean;
}

const StyledListItem = styled(ListItem)`
  width: 100%;
  flex-wrap: wrap;
  padding: 20px 24px 14px 24px;
  background: #fbfbfb;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.05);

  &&& {
    pointer-events: auto !important;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: initial;
`;

const StyledListItemCheckbox = styled(Checkbox)<{ disabled?: boolean }>`
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'auto')};

  svg {
    fill: #005192;
  }
`;

const StyledListItemInfo = styled(UIBox)`
  margin-left: 42px;
  display: flex;
  flex-direction: column;
  width: 12%;
`;

const StyledListItemDate = styled(UIBox)`
  display: flex;
  align-items: center;

  span {
    color: #929496;
  }

  svg {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    fill: #929496;
  }
`;

const StyledListItemText = styled(UIBox)`
  display: flex;
  align-items: center;
  margin-top: 5px;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    fill: #929496;
  }
`;

const StyleListItemBadge = styled(UIBox)`
  display: block;
  max-width: 80px;
  border-radius: 50px;
  text-align: center;
  justify-content: center;
  background: #707173;
  margin-left: 24px;
  padding: 0 12px;

  span {
    line-height: 19px;
  }

  span {
    color: #fff;
  }
`;

const StyledListItemStatus = styled(UIBox)`
  margin-left: 96px;
  display: flex;
  align-items: center;
  flex-grow: 1;

  ${({ theme }): string =>
    `${theme.breakpoints.down('xs')} {
            margin-left: 0px;
            margin-top: 24px;
    }`}
`;

export const StyledListItemStatusBadge = styled.div<StyledListItemStatusProps>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-right: 14px;
  background: ${({ inUse }): string =>
    inUse ? '#F6BB3B' : '#417505'}; // TODO: use color variables
`;

export const StyledTinyListItemStatusBadge = styled(StyledListItemStatusBadge)`
  width: 12px;
  height: 12px;
  margin-right: 8px;
`;

const StyledRealItemMessage = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
//#endregion

export const DeliveryListItem: FC<DeliveryItems & UIListItemProps> = memo(
  ({
    codeDelivery,
    dateShipment,
    nameUserDelivery,
    numberItems,
    status,
    checked,
    index,
    handleClick,
    soldItem,
  }) => {
    const dispatch = useAppDispatch();
    const inUse = status !== 'New';
    const date = DateTime.fromISO(dateShipment).toFormat('yyyy LLLL dd'); // TODO Create a Date component that format date using Intl.DateTimeFormat
    const { username } = useSelector(state => state.user);

    const { t } = useTranslation();

    const deliveryRef = useRef<HTMLDivElement>(null);
    const countRef = useRef<number>(0);
    const timerRef = useRef<number>();

    const resetDelivery = async (deliveryCodes: string[]): Promise<void> => {
      dispatch(initDeliveryList());
      await dispatch(
        unlockSpecificDeliveries({
          deliveryCodes,
        })
      );
      await dispatch(getDeliveryList());
    };

    const handleTripleClick = (): void => {
      countRef.current = countRef.current + 1;

      const timerIsPresent = timerRef.current;

      if (
        timerIsPresent &&
        countRef.current === 3 &&
        status === 'In Use' &&
        nameUserDelivery === username
      ) {
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
        countRef.current = 0;

        if (
          window.confirm(
            `Reset delivery ${codeDelivery} used by ${nameUserDelivery}?`
          )
        ) {
          resetDelivery([`${codeDelivery}`]);
        }
      }

      if (!timerIsPresent) {
        const timer = setTimeout(() => {
          clearTimeout(timerRef.current);
          timerRef.current = undefined;
          countRef.current = 0;
        }, 1000);

        //@ts-ignore
        timerRef.current = timer;
      }
    };

    useEffect(() => {
      if (deliveryRef.current) {
        deliveryRef.current.addEventListener('click', handleTripleClick);
      }
    });

    return (
      <StyledListItem
        ref={deliveryRef}
        button
        onClick={(e): void => {
          if (inUse) {
            return;
          }

          handleClick?.(e, codeDelivery, index);
        }}
        disabled={inUse}
        alignItems="center"
      >
        <StyledListItemIcon>
          <StyledListItemCheckbox
            edge="start"
            checked={checked && checked.indexOf(index) !== -1}
            tabIndex={-1}
            disabled={inUse}
            disableRipple
          />
        </StyledListItemIcon>
        <StyledListItemInfo>
          <StyledListItemDate>
            <SvgIcon component={CalendarTodayRounded}></SvgIcon>
            <Typography size="xs" font="book">
              {date}
            </Typography>
          </StyledListItemDate>
          <StyledListItemText>
            <DeliveryIcon />
            <Typography size="lg" font="heavy">
              {codeDelivery}
            </Typography>
          </StyledListItemText>
        </StyledListItemInfo>
        <StyledListItemStatus>
          <StyledListItemStatusBadge inUse={inUse} />
          <Typography size="lg" font="medium">
            {inUse ? `In use by ${nameUserDelivery}` : 'New'}
          </Typography>
          <UIBox ml="auto" alignItems="center">
            {soldItem && (
              <StyledRealItemMessage>
                <UIWarning label={t('receiving.soldItems')} />
              </StyledRealItemMessage>
            )}
            <StyleListItemBadge>
              <Typography size="xs" color="white">
                {numberItems} items
              </Typography>
            </StyleListItemBadge>
          </UIBox>
        </StyledListItemStatus>
      </StyledListItem>
    );
  }
);

DeliveryListItem.displayName = 'DeliveryListItem';

export default DeliveryListItem;
