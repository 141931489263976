import { ThemeOptions } from '@material-ui/core';

export const overrides: ThemeOptions['overrides'] = {
  MuiAppBar: {
    root: {
      '@media print': {
        display: 'none',
      },
    },
  },
  MuiButton: {
    root: {
      height: '44px',
      width: '305px',
      borderRadius: '30px',
      '&$disabled': {
        backgroundColor: 'rgba(146, 148, 150, 1)',
        border: '2px solid rgba(146, 148, 150, 1)',
        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.2)',
        color: 'white',
      },
    },
    outlinedPrimary: {
      border: '2px solid rgba(0, 81, 146, 0.5)',
    },
  },
};
