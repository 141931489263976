import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAsync, usePrevious } from 'react-use';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { useDebounce } from 'react-use';
import { useReasonCodeContext } from '@/context/reasonCodeContext';

import useChips, { Chip } from '@/hooks/useChips';

import {
  addSale,
  checkGroupTitleAvailability,
  fetchReasonCodeByMovementType,
  fetchSalesAndMovementTypeByProcess,
  fetchStoresBySales,
  findGroup,
  initReasonCodeGroupStoresAndReasons,
  removeReasonByMovement,
  removeStoreBySale,
  updateGroup,
} from '@/features/profiling/reasonCodeSlice';

import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';
import { UIBox } from '@/components/ui/Box';
import { UILoader } from '@/components/ui/Loader';
import { UISelect } from '@/components/ui/Select';
import { TextField } from '@/components/ui/TextField';
import { ChipList } from '@/components/layout/ChipList';
import { Autocomplete } from '@material-ui/lab';
import { Typography } from '@/components/ui/Typography';
import { PageLoader } from '@/components/ui/PageLoader';
import { Tabs } from '@/components/layout/Tabs';
import { CTAContainer } from '@/components/layout/CTAContainer';
import ProfilingReasonCodeList from '@/components/layout/ProfilingReasonCodeList/ProfilingReasonCodeList';
import ProfilingReasonCodeStoresList from '@/components/layout/ProfilingReasonCodeStoresList/ProfilingReasonCodeStoresList';

import { AppRoutes } from '@/app/routers';
import type {
  ReasonCodeDetails,
  ReasonCodeStoreDetails,
  UpdateGroupRequest,
} from '@/api/receive';
import type { SelectOption } from '@/types/selectOption';
import type { MovementTypeFilters, StoreFilters } from '@/types/filters';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';

const StyledFieldWrapper = styled(UIBox)`
  flex-direction: column;
`;

const StyledChipList = styled(ChipList)`
  margin-top: 24px;
`;

const StyledField = styled(TextField)`
  min-width: 300px;
`;

const StyledSelect = styled(UISelect)`
  min-width: 300px;
`;

const StyledFieldTitle = styled(Typography)`
  && {
    margin-bottom: 8px;
  }
`;

const StyledFieldLoading = styled(UIBox)`
  align-items: center;
  margin-top: 8px;

  & > * {
    &:first-child {
      margin-right: 8px;
    }
  }
`;

const StyledFieldMessage = styled(Typography)<{ color?: string }>`
  && {
    margin-top: 8px;
    ${({ color }): FlattenSimpleInterpolation | undefined =>
      color &&
      css`
        color: ${color};
      `};
  }
`;

const StyledFilterWrapper = styled(UIBox)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-bottom: 16px;

  & > * {
    margin: 0;
    padding: 0;
    align-self: flex-start;

    & > div {
      width: 100% !important;
    }
  }
`;

const PageControlReasonCodesEdit: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const onMount = useRef<boolean>(true);
  const query = useParams<{ groupTitle: string }>();
  const tabs = [t('generalInfo'), t('reasonCodes'), t('stores')];

  const [groupTitle, setGroupTitle] = useState<string>('');
  const [process, setProcess] = useState<string>('');
  const [sale, setSale] = useState<string | null>(null);
  const [movementType, setMovementType] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [enableAllReasonCode, setAllReasonCodeEnabled] =
    useState<boolean>(true);
  const [reasonCodeCurrentPage, setReasonCodeListCurrentPage] =
    useState<number>(1);

  const [enableAllStores, setAllStoresEnabled] = useState<boolean>(true);
  const [storesCurrentPage, setStoresListCurrentPage] = useState<number>(1);
  const [errorSnackbar, setErrorSnackbarVisibility] = useState<boolean>(false);

  const [paginationReason, setPaginationReason] = useState<ReasonCodeDetails[]>(
    []
  );

  const [paginationStores, setPaginationStores] = useState<
    ReasonCodeStoreDetails[]
  >([]);

  const [movementTypeFilter, setMovementTypeFilter] =
    useState<MovementTypeFilters>({
      typeCode: '',
      reasonCode: '',
    });

  const [storesFilter, setStoresFilter] = useState<StoreFilters>({
    sales: '',
    storeCode: '',
  });

  const { processes } = useReasonCodeContext();

  const { chips: organizationsChips, setChips: setOrganizationsChips } =
    useChips();
  const { chips: movementTypesChips, setChips: setMovementTypesChips } =
    useChips();

  const prevOrgsChips = usePrevious(organizationsChips.length);
  const prevMovementChips = usePrevious(movementTypesChips.length);

  const group = useSelector(state => state.profiling.reasonCode.group);
  const {
    salesOrgByProcess,
    movementTypes,
    groupTitleExist,
    fetchGroupIsLoading,
    updateGroupIsLoading,
    updateGroupHasError,
    checkingGroupTitleSuccess,
    checkingGroupTitleAvailability,
  } = useSelector(state => state.profiling.reasonCode);

  useAsync(async () => {
    if (query) {
      await dispatch(findGroup(query.groupTitle as string));
    }
  }, [dispatch]);

  useAsync(async () => {
    if (process) {
      await dispatch(fetchSalesAndMovementTypeByProcess(process));
    }
  }, [dispatch, process]);

  useAsync(async () => {
    if (
      group.salesOrg !== undefined &&
      prevOrgsChips! < organizationsChips.length
    ) {
      await dispatch(
        fetchStoresBySales({
          salesOrg: organizationsChips.map(({ label }) => label),
        })
      );
    }
  }, [dispatch, organizationsChips.length]);

  useAsync(async () => {
    if (
      group.movementTypeCodes !== undefined &&
      prevMovementChips! < movementTypesChips.length
    ) {
      await dispatch(
        fetchReasonCodeByMovementType({
          movementTypeCodes: movementTypesChips.map(({ label }) => label),
        })
      );
    }
  }, [dispatch, movementTypesChips.length]);

  useEffect(() => {
    if (group && onMount.current) {
      setGroupTitle(group.groupTitle || '');
      setProcess(group.process || '');
      setOrganizationsChips(group.salesOrg || []);
      setMovementTypesChips(group.movementTypeCodes || []);
    }
  }, [group, setMovementTypesChips, setOrganizationsChips]);

  useEffect(() => {
    setPaginationStores(group.stores || []);
  }, [group.stores]);

  useEffect(() => {
    setPaginationReason(group.reasonCodes || []);
  }, [group.reasonCodes]);

  useEffect(() => {
    if (updateGroupHasError) {
      setErrorSnackbarVisibility(true);
    }
  }, [updateGroupHasError]);

  useEffect(() => {
    const { typeCode, reasonCode } = movementTypeFilter;

    if (group.reasonCodes) {
      if (typeCode !== '' || reasonCode !== '') {
        setPaginationReason(() => {
          return (
            group.reasonCodes?.filter(
              ({ movementTypeCode, movementReasonCode }) => {
                return (
                  movementTypeCode
                    ?.toLowerCase()
                    .includes(typeCode.toLowerCase()) &&
                  movementReasonCode
                    ?.toLowerCase()
                    .includes(reasonCode.toLowerCase())
                );
              }
            ) || []
          );
        });
      }

      if (typeCode === '' && reasonCode === '') {
        setPaginationReason(group.reasonCodes);
      }
    }
  }, [movementTypeFilter, group.reasonCodes]);

  useEffect(() => {
    const { sales, storeCode } = storesFilter;

    if (group && group.stores) {
      if (sales !== '' || storeCode !== '') {
        setPaginationStores(() => {
          return (
            group.stores?.filter(({ salesOrg, storeCode: storeCodeGroup }) => {
              return (
                salesOrg?.toLowerCase().includes(sales.toLowerCase()) &&
                storeCodeGroup?.toLowerCase().includes(storeCode.toLowerCase())
              );
            }) || []
          );
        });
      }

      if (sales === '' && storeCode === '') {
        setPaginationStores(group.stores || []);
      }
    }
  }, [group, storesFilter]);

  useDebounce(
    async () => {
      if (groupTitle && groupTitle !== group.groupTitle) {
        await dispatch(checkGroupTitleAvailability(groupTitle));
      }
    },
    1250,
    [groupTitle]
  );

  const processOptions: SelectOption[] = useMemo(() => {
    const options = processes.map(process => ({
      label: t(`${process}`),
      value: process,
    }));

    return options;
  }, [processes, t]);

  const titleChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setGroupTitle(e.target.value);
  };

  const processChangeHandler = (
    e: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setProcess(e.target.value as string);
    setOrganizationsChips([]);
    setMovementTypesChips([]);
    setSale(null);
    setMovementType(null);
    dispatch(initReasonCodeGroupStoresAndReasons());
    onMount.current = false;
  };

  const salesChangeHandler = (sale: string | null): void => {
    if (sale) {
      setSale(sale);
      setOrganizationsChips(prevState => [...new Set([...prevState, sale])]);
      setSale(null);
      onMount.current = false;
    }
  };

  const movementTypeChangeHandler = (movementType: string | null): void => {
    if (movementType) {
      setMovementType(movementType);
      setMovementTypesChips(prevState => [
        ...new Set([...prevState, movementType]),
      ]);
      setMovementType(null);
      onMount.current = false;
    }
  };

  const onOrganizationChipDelete = (chip: Chip): void => {
    setOrganizationsChips(prevState =>
      prevState.filter(organization => organization !== chip.label)
    );
    dispatch(addSale(chip.label));
    dispatch(removeStoreBySale(chip.label));
    onMount.current = false;
  };

  const onMovementTypeChipDelete = (chip: Chip): void => {
    setMovementTypesChips(prevState =>
      prevState.filter(typeCode => typeCode !== chip.label)
    );
    dispatch(removeReasonByMovement(chip.label));
    onMount.current = false;
  };

  const confirmClickHandler = async (): Promise<void> => {
    const reasonCodesEnabled = group.reasonCodes?.filter(
      ({ enabled }) => enabled
    );
    const storesEnabled = group.stores?.filter(({ enabled }) => enabled);

    try {
      const updateGroupBody: UpdateGroupRequest = {
        groupTitle,
        oldGroupTitle: group.groupTitle,
        process,
        reasonCodes: reasonCodesEnabled,
        stores: storesEnabled?.map(({ storeCode, enabled }) => ({
          storeCode,
          enabled,
        })),
      };

      await dispatch(updateGroup(updateGroupBody)).unwrap();
      history.push(AppRoutes.MANAGE_REASON_CODES);
    } catch {
      setErrorSnackbarVisibility(true);
    }
  };

  const movementTypeFilterHandler = (
    key: keyof MovementTypeFilters,
    value: string
  ): void => {
    setMovementTypeFilter(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const storesFilterHandler = (
    key: keyof StoreFilters,
    value: string
  ): void => {
    setStoresFilter(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  if (fetchGroupIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <Tabs
        mt="18px"
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        values={tabs}
      />
      {/*********** TAB GENERAL INFO ***********/}
      {selectedTab === 0 && (
        <UIBox
          padding={3}
          marginBottom={12}
          flexDirection="column"
          alignSelf="start"
        >
          <UIBox marginBottom={3}>
            <StyledFieldWrapper>
              <StyledFieldTitle>{t('groupTitle')} *</StyledFieldTitle>
              <StyledField
                value={groupTitle}
                onChange={(e): void => titleChangeHandler(e)}
                variant="outlined"
              />
              {checkingGroupTitleAvailability && (
                <StyledFieldLoading>
                  <UILoader size={16} />
                  <Typography size="sm">
                    {t('checkingGroupTitle')}...
                  </Typography>
                </StyledFieldLoading>
              )}
              {checkingGroupTitleSuccess && (
                <>
                  {groupTitleExist && groupTitle !== group.groupTitle && (
                    <StyledFieldMessage size="sm" color="red">
                      {t('groupTitleExist')}
                    </StyledFieldMessage>
                  )}
                  {!groupTitleExist && (
                    <StyledFieldMessage size="sm" color="green">
                      {t('groupTitleAvailable')}
                    </StyledFieldMessage>
                  )}
                </>
              )}
            </StyledFieldWrapper>
            <StyledFieldWrapper marginLeft={3}>
              <StyledFieldTitle>{t('process')} *</StyledFieldTitle>
              <StyledSelect
                title=""
                marg="0px"
                variant="outlined"
                onChange={processChangeHandler}
                defaultValue={process}
                value={process}
                values={process ? processOptions : []}
              />
            </StyledFieldWrapper>
          </UIBox>
          <UIBox marginBottom={3} marginTop={3}>
            <StyledFieldWrapper maxWidth={300}>
              <StyledFieldTitle>{t('salesOrganization')} *</StyledFieldTitle>
              <Autocomplete
                key="sales-organizations"
                value={sale}
                onChange={(_e, sale): void => {
                  salesChangeHandler(sale);
                }}
                options={salesOrgByProcess || []}
                getOptionLabel={(option): string => option || ''}
                getOptionSelected={(option, value): boolean => option === value}
                renderInput={(params): JSX.Element => (
                  <StyledField {...params} variant="outlined" />
                )}
              />
              <StyledChipList
                chips={organizationsChips}
                onChipDelete={(chip): void => onOrganizationChipDelete(chip)}
              />
            </StyledFieldWrapper>
          </UIBox>
          <UIBox>
            <StyledFieldWrapper maxWidth={300}>
              <StyledFieldTitle font="medium" size="lg">
                {t('movementTypeCode')} *
              </StyledFieldTitle>
              <StyledFieldTitle>{t('selectGroupCodes')}</StyledFieldTitle>
              <Autocomplete
                key="movement-types"
                value={movementType}
                onChange={(_e, movementType): void => {
                  movementTypeChangeHandler(movementType);
                }}
                options={movementTypes || []}
                getOptionLabel={(option): string => option || ''}
                getOptionSelected={(option, value): boolean => option === value}
                renderInput={(params): JSX.Element => (
                  <StyledField {...params} variant="outlined" />
                )}
              />
              <StyledChipList
                chips={movementTypesChips}
                onChipDelete={(chip): void => onMovementTypeChipDelete(chip)}
              />
            </StyledFieldWrapper>
          </UIBox>
        </UIBox>
      )}
      {/*********** TAB REASON CODES ***********/}
      {selectedTab === 1 && (
        <UIBox
          width="100%"
          padding={3}
          marginBottom={12}
          flexDirection="column"
          alignSelf="start"
        >
          <StyledFilterWrapper>
            <SearchBarLight
              label=""
              value={movementTypeFilter.typeCode}
              placeholder={t('profiling.filterByMovementType')}
              disabled={false}
              onSearch={(): void => {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                movementTypeFilterHandler('typeCode', e.target.value)
              }
              loading={false}
              hideButton={true}
            />
            <SearchBarLight
              label=""
              value={movementTypeFilter.reasonCode}
              placeholder={t('profiling.filterByReasonCode')}
              disabled={false}
              onSearch={(): void => {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                movementTypeFilterHandler(
                  'reasonCode',
                  e.target.value as string
                )
              }
              loading={false}
              hideButton={true}
            />
          </StyledFilterWrapper>
          <ProfilingReasonCodeList
            paginationReasons={paginationReason}
            listCurrentPage={reasonCodeCurrentPage}
            enableAllReasonCode={enableAllReasonCode}
            setAllReasonCodeEnabled={setAllReasonCodeEnabled}
            setListCurrentPage={setReasonCodeListCurrentPage}
          />
        </UIBox>
      )}
      {/*********** TAB STORES ***********/}
      {selectedTab === 2 && (
        <UIBox
          width="100%"
          padding={3}
          marginBottom={12}
          flexDirection="column"
          alignSelf="start"
        >
          <StyledFilterWrapper>
            <SearchBarLight
              label=""
              value={storesFilter.sales}
              placeholder={t('profiling.filterBySalesOrganizations')}
              disabled={false}
              onSearch={(): void => {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                storesFilterHandler('sales', e.target.value)
              }
              loading={false}
              hideButton={true}
            />
            <SearchBarLight
              label=""
              value={storesFilter.storeCode}
              placeholder={t('profiling.filterByStoreCode')}
              disabled={false}
              onSearch={(): void => {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                storesFilterHandler('storeCode', e.target.value as string)
              }
              loading={false}
              hideButton={true}
            />
          </StyledFilterWrapper>
          <ProfilingReasonCodeStoresList
            paginationStores={paginationStores}
            listCurrentPage={storesCurrentPage}
            enableAllStores={enableAllStores}
            setAllStoresEnabled={setAllStoresEnabled}
            setListCurrentPage={setStoresListCurrentPage}
          />
        </UIBox>
      )}
      <CTAContainer
        type="TWO_BUTTONS"
        onClick={confirmClickHandler}
        onBackClick={(): void => history.push(AppRoutes.MANAGE_REASON_CODES)}
        loading={updateGroupIsLoading}
        disabled={updateGroupIsLoading}
        disabledMainAction={
          groupTitle === '' ||
          process === '' ||
          organizationsChips.length === 0 ||
          movementTypesChips.length === 0 ||
          group.stores?.every(store => !store.enabled) ||
          group.reasonCodes?.every(reason => !reason.enabled) ||
          (groupTitleExist && groupTitle !== group.groupTitle)
        }
        mainButtonLabel={t('confirm')}
        backButtonLabel={t('back')}
      />
      <ErrorSnackbar
        open={errorSnackbar && !!updateGroupHasError}
        setIsOpen={setErrorSnackbarVisibility}
        errorMessage={updateGroupHasError?.body}
      />
    </>
  );
};

export default PageControlReasonCodesEdit;
