import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ProductItemWrapper } from '@/components/layout/ProductItemWrapper';
import { ProductImage } from '@/components/layout/ProductImage';
import { ProductDescription } from '@/components/layout/ProductDescription';
import { Typography } from '@/components/ui/Typography';
import { UIBox } from '@/components/ui/Box';

import { colors } from '@/theme';
import type { RecallListItemInfoProps, ItemType } from './type';

const color = (type: ItemType): string => {
  switch (type) {
    case 'NOT FOUND':
      return colors.yellow;

    case 'ERROR':
      return colors.red;

    case 'FOUND':
      return colors.green;

    default:
      return colors.grey;
  }
};

const StyledProductItem = styled(ProductItemWrapper)<{ type: ItemType }>`
  &::before {
    background: ${({ type }): string => color(type)};
  }
`;

const StyledContentWrapper = styled(UIBox)`
  width: 100%;
  align-items: center;
  justify-content: space-between;

  && > * {
    line-height: 19px;
  }
`;

const RecallListItemInfo: React.FC<RecallListItemInfoProps> = ({
  type,
  brandDescription,
  styleName,
  upcCode,
  modelCode,
  imageUrl,
  commodity,
  scannedQuantity,
  recallQuantity,
  quantityInStock,
  accordion,
  checkbox,
}) => {
  const { t } = useTranslation();

  return (
    <StyledProductItem type={type}>
      <StyledContentWrapper>
        <ProductImage
          $v2={accordion?.isVisible}
          imageUrl={imageUrl}
          checkboxIsVisible={checkbox?.isVisible}
          checkboxIsDisabled={checkbox?.isDisabled}
          checked={checkbox?.checked}
          onCheckboxClick={checkbox?.onClick}
          onClickAccordion={accordion?.toggle}
          $isAccordionExpanded={accordion?.isExpanded}
        />
        <ProductDescription>
          <Typography size="lg" font="heavy" as="span">
            {brandDescription} - {styleName}
          </Typography>
          <Typography size="md" font="book" as="p">
            {upcCode} - {modelCode} - {commodity}
          </Typography>
        </ProductDescription>
        <UIBox flexDirection="column" alignItems="center" marginRight={4}>
          <Typography size="sm" color="grey">
            {t('scanned')} / {t('recallQuantity')}
          </Typography>
          <Typography margin="8px 0 0" font="heavy" size="xl">
            {scannedQuantity}/{recallQuantity}
          </Typography>
        </UIBox>
        <UIBox flexDirection="column" alignItems="center">
          <Typography size="sm" color="grey">
            {t('inStock')}
          </Typography>
          <Typography margin="8px 0 0" font="heavy" size="xl">
            {quantityInStock}
          </Typography>
        </UIBox>
      </StyledContentWrapper>
    </StyledProductItem>
  );
};

export default RecallListItemInfo;
