export const breakpointList = {
  xs: 0,
  sm: 600,
  md: 1024,
  lg: 1440,
  xl: 1920,
};

export type TBreakpointName = keyof typeof breakpointList;
export type TBreakpointValues = typeof breakpointList[TBreakpointName];

export const breakpoints = {
  values: {
    ...breakpointList,
  },
};
