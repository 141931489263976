import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';

import { UICheckbox } from '@/components/ui/Checkbox';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import WarningIcon from '@material-ui/icons/Warning';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { checkEpc } from '@/features/deliveriesManage/deliveriesManageSlice';
import { StyledTablesRow } from './style';
import type { DeliveryDetailsRow } from '@/api/receive';
import { EpcStatusValues, EPC_STATUS } from '@/types/enum';

const DeliveryListItem: React.FC<DeliveryDetailsRow> = ({
  upcCode,
  epcCode,
  attributes,
  lastEpcStatus,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { checkedEpcs } = useSelector(state => state.manageDeliveries);
  const epcs = useSelector(
    state => state.manageDeliveries.deliveryDetails?.epcsInTransit
  );

  const checkboxIsVisible: boolean = useMemo(() => {
    if (epcs) {
      const epcsInTransit = epcs.map(({ epcCode }) => epcCode || '');
      return epcsInTransit.includes(epcCode || '');
    }

    return false;
  }, [epcCode, epcs]);

  const handleCheckboxClick = (): void => {
    if (epcCode) {
      dispatch(checkEpc(epcCode));
    }
  };

  const handleStatus = (status: EpcStatusValues): JSX.Element => {
    switch (status) {
      case EPC_STATUS.IN_STOCK:
        return (
          <UIBox alignItems="center">
            <Typography margin="0 8px 0 0">{t('inStock')}</Typography>
            <CheckCircleOutlineIcon htmlColor="#417505" />
          </UIBox>
        );

      case EPC_STATUS.STOLEN:
        return (
          <UIBox alignItems="center">
            <Typography margin="0 8px 0 0">{t('stolen')}</Typography>
            <WarningIcon htmlColor="#E20404" />
          </UIBox>
        );

      case EPC_STATUS.SOLD:
        return (
          <UIBox alignItems="center">
            <Typography margin="0 8px 0 0">{t('sold')}</Typography>
            <NotInterestedIcon htmlColor="#707173" />
          </UIBox>
        );

      case EPC_STATUS.MISSING:
        return (
          <UIBox alignItems="center">
            <Typography margin="0 8px 0 0">{t('missing')}</Typography>
            <HighlightOffIcon htmlColor="#707173" />
          </UIBox>
        );

      case EPC_STATUS.IN_TRANSIT:
        return (
          <UIBox alignItems="center">
            <Typography margin="0 8px 0 0">{t('inTransit')}</Typography>
            <UIBox
              alignItems="center"
              justifyContent="center"
              p={1.5}
              width="16px"
              height="16px"
              border="2px solid #005192"
              borderRadius="100px"
            >
              <MoreHorizIcon htmlColor="#005192" />
            </UIBox>
          </UIBox>
        );

      default:
        return (
          <UIBox alignItems="center">
            <Typography margin="0 8px 0 0">{t(status || '')}</Typography>
          </UIBox>
        );
    }
  };

  return (
    <StyledTablesRow>
      {checkboxIsVisible ? (
        <UICheckbox
          onClick={handleCheckboxClick}
          checked={checkedEpcs.includes(epcCode || '')}
        />
      ) : (
        <div></div>
      )}
      <Typography>{upcCode}</Typography>
      <Typography color="grey">{epcCode}</Typography>
      <Typography>{attributes?.join(', ')}</Typography>
      {handleStatus(lastEpcStatus as EpcStatusValues)}
    </StyledTablesRow>
  );
};

export default DeliveryListItem;
