import type { PaletteOptions } from '@material-ui/core/styles/createPalette';

export const colors = {
  disabled: '#929496',
  green: '#417505',
  primary: '#005192',
  red: '#E20404',
  secondary: '#00000',
  grey: '#929496',
  midGrey: '#fbfbfb',
  lightGrey: '#EDEDED',
  text: '#4B4B4B',
  white: '#ffffff',
  black: '#000',
  yellow: '#F6BB3B',
};

export type TColorName = keyof typeof colors;
export type TColorValues = React.CSSProperties['color'];

export const palette: PaletteOptions = {
  background: {
    default: colors.white,
  },
  primary: {
    main: colors.primary,
  },
  secondary: {
    main: colors.secondary,
  },
  text: {
    disabled: colors.white,
  },
  action: {
    disabled: colors.disabled,
  },
  colors: {
    green: colors.green,
    grey: colors.grey,
    midGrey: colors.midGrey,
    lightGrey: colors.lightGrey,
    red: colors.red,
    text: colors.text,
    white: colors.white,
    black: colors.black,
    yellow: colors.yellow,
  },
};
