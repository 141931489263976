import React, { FC, memo } from 'react';
import styled from 'styled-components';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import { SnackbarContent } from '@material-ui/core';
import { theme } from '../../../theme';

import { TransitionProps } from '@material-ui/core/transitions';
import Grow from '@material-ui/core/Grow';

export interface NotificationBarProps extends SnackbarProps {
  cta: JSX.Element;
  vertical: 'bottom' | 'top';
  horizontal: 'center' | 'left' | 'right';
  TransitionComponent?: React.ComponentType<
    TransitionProps & {
      children?: React.ReactElement;
    }
  >;
}

const StyledSnackbar = styled(Snackbar)`
  min-width: 80vw;
  margin-bottom: 100px;

  .MuiSnackbarContent-root {
    padding: 24px;
    width: 100%;
    background: ${theme.palette.text.primary};

    ${({ theme }): string =>
      `${theme.breakpoints.down('xs')} {
                flex-direction: column;
                align-items: center;
        }`}
  }
`;

const StyledSnackbarContent = styled(SnackbarContent)`
  .MuiSnackbarContent-message {
    font-size: 16px;

    ${({ theme }): string =>
      `${theme.breakpoints.down('xs')} {
                padding: 16px 0;
                text-align: center;
        }`}
  }

  .MuiSnackbarContent-action {
    button {
      font-size: 18px;
      width: auto;
      color: ${theme.palette.primary.contrastText};
      border-color: ${theme.palette.primary.contrastText};
    }

    ${({ theme }): string =>
      `${theme.breakpoints.down('xs')} {
                margin-left: 0;

                button {
                    width: 305px;
                }
        }`}
  }
`;

export const NotificationBar: FC<NotificationBarProps> = memo(
  ({
    message,
    cta,
    open,
    vertical,
    horizontal,
    autoHideDuration,
    TransitionComponent,
    ...rest
  }): JSX.Element => {
    return (
      <StyledSnackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        TransitionComponent={TransitionComponent || Grow}
        autoHideDuration={autoHideDuration}
        {...rest}
      >
        <StyledSnackbarContent message={message} action={cta} />
      </StyledSnackbar>
    );
  }
);

NotificationBar.displayName = 'NotificationBar';

export default NotificationBar;
