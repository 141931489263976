/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum LoaderStatus {
  ONGOING = 'ONGOING',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  COMPLETED = 'COMPLETED',
  COMPLETED_WITH_ERRORS = 'COMPLETED WITH ERRORS',
  ERROR_VERIFIED = 'ERROR VERIFIED',
}
