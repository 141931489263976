import styled from 'styled-components';

import { css } from 'styled-components';
import { theme } from '@/theme';
import { Typography } from '@/components/ui/Typography';

import { UIBox } from '@/components/ui/Box';

export const GridStyles = css<{ $showPartial?: boolean }>`
  display: grid;
  align-items: center;
  gap: 16px;

  grid-template-columns: ${({ $showPartial }): string =>
    $showPartial ? 'repeat(6, 15%) auto' : '15% 15% 15% 38% 7% 7%'};

  ${theme.breakpoints.down('md')} {
    grid-template-columns: ${({ $showPartial }): string =>
      $showPartial ? 'repeat(6, 10%) auto' : 'repeat(4, 20%) auto auto'};
  }
`;

export const StyledGrid = styled(UIBox)`
  width: 100%;
  flex-direction: column;
  margin-bottom: 120px;

  & > * {
    padding: 0;
  }
`;

export const StyledGridHeader = styled(UIBox)<{ $showPartial?: boolean }>`
  width: 100%;
  padding: 8px 24px;
  border-bottom: 1px solid #4b4b4b;
  ${GridStyles}
`;

export const StyledRowWrapper = styled(UIBox)`
  flex-direction: column;

  &:not(:nth-of-type(odd)) {
    background: #fbfbfb;
  }
`;

export const StyledGridRow = styled(UIBox)`
  width: 100%;
  padding: 11px 24px;
`;

export const StyledGridRowContent = styled(UIBox)<{ $showPartial?: boolean }>`
  width: 100%;
  padding: 8px 0;
  ${GridStyles}

  & > * {
    &:last-child {
      width: 100%;
      justify-self: flex-end;
      justify-content: ${({ $showPartial }): string =>
        $showPartial ? 'end' : 'start'};
    }
  }
`;

export const StyledSpan = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledUserId = styled(UIBox)`
  gap: 8px;
  align-items: center;
`;

export const StyledRole = styled('span')`
  color: grey;
`;

export const StyledStatusWrapper = styled(UIBox)`
  align-items: center;
`;

export const StyledStatus = styled('div')<{ status?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 6px;
  background: ${({ status }): string =>
    status?.toLowerCase() === 'active' ? 'green' : 'red'};
`;
