/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthResponse } from '../models/AuthResponse';
import { request as __request } from '../core/request';

export class AuthService {
  /**
   * IDM Login
   * create jwt token for Login IDM
   * @returns AuthResponse OK
   * @throws ApiError
   */
  public static async authLogin({
    code,
    redirectUrl,
  }: {
    code: any;
    redirectUrl: any;
  }): Promise<AuthResponse> {
    const result = await __request({
      method: 'GET',
      path: `/auth/login`,
      query: {
        code: code,
        redirectUrl: redirectUrl,
      },
      errors: {
        400: `Bad request.`,
        401: `Unauthorized.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * IDM Logout
   * Logout IDM
   * @returns any Logout Successfull.
   * @throws ApiError
   */
  public static async authLogout(): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/auth/logout`,
      errors: {
        400: `Malformed jwt token`,
        401: `Unauthorized.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * IDM Refresh Token after Login
   * Refresh IDM
   * @returns AuthResponse OK
   * @throws ApiError
   */
  public static async authRefreshToken(): Promise<AuthResponse> {
    const result = await __request({
      method: 'GET',
      path: `/auth/refreshToken`,
      errors: {
        400: `Bad request.`,
        401: `Unauthorized.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
