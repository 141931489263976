/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RfidPrintOut } from '../models/RfidPrintOut';
import { request as __request } from '../core/request';

export class PrintService {
  /**
   * @returns RfidPrintOut Default Response
   * @throws ApiError
   */
  public static async print({
    requestBody,
  }: {
    requestBody?: {
      storeCode: string;
      reasonCode?: 'Printed' | 'Damaged' | 'Missing';
      serialNumberPrinter: string;
      layout:
        | 'SRO EMEA'
        | 'SRO EMEA CREATE-TAG'
        | 'SRO EMEA RE-TAG'
        | 'SRO EMEA RE-PRICING'
        | 'SRO NA'
        | 'SRO NA CREATE-TAG'
        | 'SRO NA RE-TAG'
        | 'SRO NA RE-PRICING';
      upcCode: string;
      confirmPrint?: boolean;
      epc?: string;
      van?: string;
      brandDescription?: string;
      commodityCode?: string;
      commodityDescription?: string;
      styleNameCode?: string;
      styleNameDescription?: string;
      marketingThemeCode?: string;
      marketingThemeDescription?: string;
      marketingStoryCode?: string;
      marketingStoryDescription?: string;
      frameColor?: string;
      lensColor?: string;
      modelCode?: string;
      colorCode?: string;
      sizeCode?: string;
      polarCode?: string;
      polarDescription?: string;
      retailPrice?: string;
      airportPrice?: string;
      discountPrice?: string;
      currency?: string;
      price?: string;
      second_price?: string;
      second_price_type?: string;
      discount_percent?: string;
      copy_quantity?: number;
      salesText?: string;
      releaseCode?: string;
      sale?: 'SALE';
    };
  }): Promise<RfidPrintOut> {
    const result = await __request({
      method: 'POST',
      path: `/api/v1/print`,
      body: requestBody,
    });
    return result.body;
  }
}
