import React from 'react';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    return <MuiTextField ref={ref} {...props} />;
  }
);

TextField.displayName = 'TextField';

export default TextField;
