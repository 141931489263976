import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';

export const StyledProductsWrapper = styled(UIBox)`
  margin-bottom: 100px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  .searchUPC {
    padding: 0;
    > div {
      padding: 0;
      width: 350px;
      > .MuiInputBase-root {
        height: 28px;
      }
      > .MuiOutlinedInput-root {
        border-radius: 13px;
      }
    }
  }

  .MuiToggleButtonGroup-root {
    border-radius: 13px;
    background-color: #005192;
    height: 28px;
    .MuiToggleButton-root {
      border-radius: 13px;
      margin: 3px;
      padding: 2px 5px;
      color: #fff;
      border-color: transparent;
      &:not(:last-child) {
        border-top-right-radius: 13px;
        border-bottom-right-radius: 13px;
      }
      &:last-child {
        border-top-left-radius: 13px;
        border-bottom-left-radius: 13px;
      }
      &.Mui-selected {
        background-color: #fff;
        color: #005192;
      }
    }
  }
`;
