import styled from 'styled-components';

import DoneIcon from '@material-ui/icons/Done';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { Radio } from '@material-ui/core';

export const StyledColumn = styled(UIBox)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0 -1px 21px rgba(0, 0, 0, 0.1);

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                    &:last-child {
                      display: block;
                    }
                }`}
`;

export const StyledColumnDevice = styled(StyledColumn)`
  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                    min-height: 250px;
                }`}
`;

export const StyledTitle = styled(UIBox)`
  align-items: center;
  justify-content: center;
  background: #fbfbfb;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: 1px solid #ededed;
`;

export const StyledContent = styled(UIBox)`
  height: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  button {
    width: auto !important;
  }

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                  justify-content: flex-start;
                }`}
`;

export const StyledContentDevice = styled(StyledContent)`
  overflow-y: auto;
`;

export const StyledText = styled(UIBox)`
  flex-direction: column;
  margin-bottom: 16px;

  h1 {
    margin: 4px 0;
  }

  p {
    margin: 0;
  }
`;

export const StyledDeviceInfoWrapper = styled(UIBox)`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const StyledUILoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLoaderWrapper = styled(UIBox)`
  margin-top: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    margin-bottom: 16px;
  }
`;

export const StyledDoneIcon = styled(DoneIcon)`
  fill: #417505;
  margin: 24px auto;
  width: 26px;
  height: 26px;
`;

export const StyledTypography = styled(Typography)`
  text-align: center;
  line-height: 19px;
`;

export const StyledRadio = styled(Radio)`
  margin-right: 16px;
`;

export const StyledReleaseButtonWrapper = styled(UIBox)`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;
