import { AuthenticationService, ReceiveOpenAPI } from '../api';
export interface User {
  token: string;
}

export class UserError extends Error {
  code: string | undefined;
  description: string | undefined;
  constructor(message: string, code?: string, description?: string) {
    super(message);
    this.code = code;
    this.description = description;
  }
}

export default class UserServices {
  static async getJwtToken(code: string): Promise<string> {
    const redirectUri = process.env.REACT_APP_IDM_REDIRECT_URI;

    if (!redirectUri) {
      throw new UserError(
        'REACT_APP_IDM_REDIRECT_URI is not defined in env variables'
      );
    }

    try {
      const { token } = await AuthenticationService.authLogin({
        code,
        redirectUrl: redirectUri,
      });

      ReceiveOpenAPI.TOKEN = sessionStorage.getItem('userToken') || undefined;

      return token!;
    } catch ({ body }) {
      throw new UserError(
        (body as string) || 'UserServices: Failed during login'
      );
    }
  }

  static async userLogout(): Promise<string | void> {
    const idmURL = process.env.REACT_APP_IDM_URL;

    sessionStorage.clear();

    await AuthenticationService.authLogout();

    window.location.assign(`${idmURL}/app/logout`);
  }
}
