import React from 'react';
import {
  StyledGridRow,
  StyledGridRowContent,
  StyledRowWrapper,
  StyledSpan,
  StyledUserId,
} from './style';
import { ScannedProduct } from '@/types/product';

const ProductTableRow: React.FC<ScannedProduct> = ({
  brandDescription,
  modelCode,
  sizeCode,
  commodity,
  colorCode,
  styleName,
  upcCode,
  expectedQty,
  foundQty,
}) => {
  return (
    <StyledRowWrapper bgcolor="#E9EDF4">
      <StyledGridRow>
        <StyledGridRowContent>
          <StyledUserId>
            <StyledSpan font="medium">{brandDescription}</StyledSpan>
          </StyledUserId>
          <StyledSpan font="medium">{modelCode}</StyledSpan>
          <StyledSpan font="medium">{upcCode}</StyledSpan>
          <StyledSpan font="medium">
            {sizeCode} {commodity} {colorCode} {styleName}
          </StyledSpan>
          <StyledSpan font="medium">{expectedQty || 0}</StyledSpan>
          <StyledSpan font="medium">{foundQty || 0}</StyledSpan>
        </StyledGridRowContent>
      </StyledGridRow>
    </StyledRowWrapper>
  );
};

export default ProductTableRow;
