import React from 'react';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { useTranslation } from 'react-i18next';
import { UIButtonWithIcon } from '@/components/ui/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as S from './style';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { useHistory } from 'react-router';
import { AppRoutes } from '@/app/routers';
import { useAsync } from 'react-use';
import { StoreToStoreService } from '@/api';
import { useSelector } from '@/hooks/useSelector';
import { LastStoreTransferResponse } from '@/api/receive';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { PageLoader } from '@/components/ui/PageLoader';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const downloadFile = (file: string, name: string): void => {
  const linkSource = `data:application/csv;base64,${file}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = name;
  downloadLink.click();
};

const PageStoreToStoreHistory: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [fetchError, setFetchError] = React.useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = React.useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = React.useState<boolean>(false);
  const [downloadError, setDownloadError] = React.useState<boolean>(false);
  const [lastStoreTransfers, setLastStoreTransfers] = React.useState<
    LastStoreTransferResponse[]
  >([]);

  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );

  const fetchStoreTransfer = React.useCallback(async () => {
    try {
      setFetchLoading(true);
      const response = await StoreToStoreService.storeFindLastStoreTransfer({
        storeCode,
      });

      if (response && response.lastStoreTransfer) {
        setLastStoreTransfers(response.lastStoreTransfer);
      }
      setFetchLoading(false);
    } catch {
      setFetchLoading(false);
      setFetchError(true);
    }
  }, [storeCode]);

  useAsync(async () => {
    await fetchStoreTransfer();
  }, []);

  const downloadHandler = async (orderId: string): Promise<void> => {
    setDownloadLoading(true);
    try {
      const { namePdfFile, pdfFile } =
        await StoreToStoreService.storeCreateStoreToStoreReport({
          orderId,
        });

      downloadFile(pdfFile, namePdfFile);
    } catch {
      setDownloadError(true);
    }
    setDownloadLoading(false);
  };

  return (
    <>
      {fetchLoading ? (
        <PageLoader />
      ) : (
        <UIBox p={3} width="100%" flexDirection="column">
          <UIBox alignItems="center" justifyContent="center">
            <Typography font="heavy">
              {t('pastStoreTransfersAvailable')}
            </Typography>
          </UIBox>
          <UIBox mt={2} alignSelf="end">
            <UIButtonWithIcon
              label={t('refresh')}
              startIcon={<RefreshIcon />}
              onClick={fetchStoreTransfer}
            />
          </UIBox>
          {lastStoreTransfers.length > 0 && (
            <>
              <S.Grid mt="32px" pl="20px" pr="20px">
                <Typography font="heavy">{t('transferredToStore')}</Typography>
                <Typography font="heavy">
                  {t('transferOrderCreationDate')}
                </Typography>
                <Typography font="heavy">{t('orderId')}</Typography>
                <span></span>
              </S.Grid>
              <S.List>
                {lastStoreTransfers.map(
                  ({
                    storeCodeToTransfer,
                    transferOrderCreationDate,
                    orderId,
                    orderStatus,
                  }) => (
                    //@ts-ignore
                    <S.ListItem button={false}>
                      <S.Grid alignItems="start">
                        <Typography>{storeCodeToTransfer}</Typography>
                        <Typography>{transferOrderCreationDate}</Typography>
                        <Typography>{orderId}</Typography>
                        <UIBox flexDirection="column" alignItems="flex-end">
                          <UIButtonWithIcon
                            label={t('downloadTransferDocument')}
                            startIcon={<GetAppIcon />}
                            disabled={orderStatus === 'Ready to transfer'}
                            onClick={(): Promise<void> =>
                              downloadHandler(orderId || '')
                            }
                            loading={downloadLoading}
                          />
                          {orderStatus === 'Ready to transfer' && (
                            <UIBox mt={2} alignItems="center">
                              <ErrorOutlineIcon
                                htmlColor="#4B4B4B"
                                fontSize="small"
                              />
                              <Typography margin="0 0 0 4px" size="sm">
                                {t('downloadDocumentInfo')}
                              </Typography>
                            </UIBox>
                          )}
                        </UIBox>
                      </S.Grid>
                    </S.ListItem>
                  )
                )}
              </S.List>
            </>
          )}
          <CTAContainer
            type="TWO_BUTTONS"
            onBackClick={(): void => history.push(AppRoutes.INTRO)}
            onClick={(): void => history.push(AppRoutes.STORE_TRANSFER)}
            mainButtonLabel={t('makeNewTransfer')}
          />
          <ErrorSnackbar
            open={fetchError}
            setIsOpen={setFetchError}
            errorMessage={t('error.fetchStoreTransfer')}
          />
          <ErrorSnackbar
            open={downloadError}
            setIsOpen={setDownloadError}
            errorMessage={t('error.downloadPDF')}
          />
        </UIBox>
      )}
    </>
  );
};

export default PageStoreToStoreHistory;
