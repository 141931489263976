import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/app/rootReducer';
import { Inventory, InventoryInitialState } from '@/types/inventory';
import {
  ApiError,
  InventoryInfo,
  InventoryOwnerInfo,
  InventoryRequest,
  InventoryService,
} from '@/api/receive';
import { InventoryStatus } from '@/types/enum';

const initialState: InventoryInitialState = {
  tags: [],
  inventoryList: {
    scheduled: [],
    ongoing: [],
    pending: [],
    passed: [],
  },
  virtualBuddyStores: [],
  inventoryDetails: {},
  inventoryReport: {
    brands: [],
    users: [],
  },
};

export const fetchInventoryList = createAsyncThunk<
  Inventory & { storeCodes?: string[] },
  { storeCode: string; isFiltered: boolean } | undefined,
  { state: RootState; rejectValue: ApiError }
>(
  'inventory/fetchInventoryList',
  async (body, { rejectWithValue, getState }) => {
    const storeCode = getState().currentStore.store?.storeCode;

    if (storeCode) {
      try {
        const response = await InventoryService.inventoryFindLastInventories({
          storeCode: body?.storeCode ? body.storeCode : storeCode,
          isFiltered: body?.isFiltered,
        });

        if (response && response.inventoryDetails) {
          const inventories = response.inventoryDetails.reduce(
            (prev, curr) => {
              const { statusName } = curr;

              const inventoryHandler = (
                status: keyof Inventory
              ): Inventory => ({
                ...prev,
                [status]: [...prev[status], curr],
              });

              switch (statusName) {
                case InventoryStatus.SCHEDULED:
                case InventoryStatus.REFUSED:
                  return inventoryHandler('scheduled');

                case InventoryStatus.ON_GOING:
                case InventoryStatus.WAITING_APPLICATION:
                  return inventoryHandler('ongoing');

                case InventoryStatus.VALIDATED:
                  return inventoryHandler('passed');

                case InventoryStatus.WAITING_VALIDATION:
                  return inventoryHandler('pending');

                default:
                  return prev;
              }
            },
            {
              scheduled: [],
              ongoing: [],
              passed: [],
              pending: [],
            } as Inventory
          );

          if (response.storeCodes) {
            return {
              ...inventories,
              storeCodes: response.storeCodes,
            };
          }

          return inventories;
        }

        return {
          scheduled: [],
          ongoing: [],
          pending: [],
          passed: [],
          storeCodes: [],
        };
      } catch (err) {
        return rejectWithValue(err as ApiError);
      }
    }

    return {
      scheduled: [],
      ongoing: [],
      pending: [],
      passed: [],
    };
  }
);

export const fetchInventoryDetails = createAsyncThunk<
  InventoryInfo,
  InventoryRequest & { isVirtualBuddy?: boolean },
  { state: RootState; rejectValue: ApiError }
>(
  'inventory/fetchInventoryDetails',
  async ({ inventoryId, storeCode }, { rejectWithValue }) => {
    if (storeCode) {
      try {
        const response = await InventoryService.inventoryRefreshInventory({
          requestBody: {
            storeCode,
            inventoryId,
          },
        });

        if (response) {
          return response;
        }

        return {};
      } catch (err) {
        return rejectWithValue(err as ApiError);
      }
    }

    return {};
  }
);

export const fetchInventoryReport = createAsyncThunk<
  InventoryOwnerInfo,
  string,
  { state: RootState; rejectValue: ApiError }
>(
  'inventory/fetchInventoryReport',
  async (inventoryId, { getState, rejectWithValue }) => {
    const storeCode = getState().currentStore.store?.storeCode;

    if (storeCode) {
      try {
        const response =
          await InventoryService.inventoryWaitingForValidationSeeResults({
            requestBody: {
              storeCode,
              inventoryId,
            },
          });

        if (response && response.brands && response.users) {
          return response;
        }

        return {
          brands: [],
          users: [],
        };
      } catch (err) {
        return rejectWithValue(err as ApiError);
      }
    }

    return {
      brands: [],
      users: [],
    };
  }
);

export const addInventoryEpc = createAsyncThunk<
  InventoryOwnerInfo,
  { inventoryId: string; epcs: string[]; isInventoryOwner?: boolean },
  { state: RootState; rejectValue: ApiError }
>(
  'inventory/addEpc',
  async ({ inventoryId, epcs, isInventoryOwner }, { rejectWithValue }) => {
    try {
      const response = await InventoryService.inventoryScanEpc({
        requestBody: {
          isInventoryOwner,
          inventoryId,
          epcs,
        },
      });

      // Brands and users return from the response only if isInventoryOwner === true
      if (response && response.brands && response.users) {
        return response;
      }
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

export const closeInventory = createAsyncThunk<
  void,
  InventoryRequest,
  { state: RootState; rejectValue: ApiError }
>(
  'inventory/closeInventory',
  async ({ inventoryId, storeCode }, { rejectWithValue }) => {
    try {
      await InventoryService.inventoryCloseInventory({
        requestBody: {
          inventoryId,
          storeCode,
        },
      });
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

export const rejectInventory = createAsyncThunk<
  void,
  InventoryRequest,
  { state: RootState; rejectValue: ApiError }
>(
  'inventory/rejectInventory',
  async ({ inventoryId, storeCode }, { rejectWithValue }) => {
    try {
      await InventoryService.inventoryRejectedInventory({
        requestBody: {
          inventoryId,
          storeCode,
        },
      });
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    addInventoryTags: (
      state,
      { payload }: PayloadAction<{ epc: string }[]>
    ) => {
      const flatTags = payload.map(({ epc }): string => epc.toUpperCase());
      const concatTags = state.tags.concat(flatTags);

      state.tags = [...new Set(concatTags)];
    },
    updateInventoryDetails: (state, { payload }: PayloadAction<string>) => {
      const details = JSON.parse(payload) as InventoryInfo;

      if (details) {
        if (
          Number(details.inventoryProgress) >
          Number(state.inventoryDetails.inventoryProgress)
        ) {
          state.inventoryDetails = details;
        }
      }
    },
    setInventoryOwner: state => {
      const inventoryOwner = state.inventoryDetails.users?.find(
        ({ role }) => role === 'Inventory Owner'
      );
      state.inventoryOwner = inventoryOwner?.userId;
    },
    initInventoryTags: state => {
      state.tags = [];
    },
    initInventoryDetails: state => {
      state.inventoryDetails = initialState.inventoryDetails;
      state.inventoryOwner = undefined;
      state.fetchInventoryIsLoading = false;
      state.fetchInventoryHasError = undefined;
    },
    initInventoryList: state => {
      state.inventoryList = initialState.inventoryList;
      state.fetchInventoryListIsLoading = false;
      state.fetchInventoryListHasError = undefined;
    },
    initInventoryState: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchInventoryList.pending, state => {
        state.fetchInventoryListIsLoading = true;
        state.fetchInventoryListHasError = undefined;
      })
      .addCase(fetchInventoryList.fulfilled, (state, { payload }) => {
        state.inventoryList = payload;

        if (payload.storeCodes) {
          state.virtualBuddyStores = payload.storeCodes;
        }

        state.fetchInventoryListIsLoading = false;
      })
      .addCase(fetchInventoryList.rejected, (state, { payload }) => {
        state.fetchInventoryListIsLoading = false;
        state.fetchInventoryListHasError = payload;
      })
      .addCase(fetchInventoryDetails.pending, state => {
        state.fetchInventoryIsLoading = true;
        state.fetchInventoryHasError = undefined;
      })
      .addCase(
        fetchInventoryDetails.fulfilled,
        (state, { payload, meta: { arg } }) => {
          state.fetchInventoryIsLoading = false;

          const data: InventoryInfo = {
            ...payload,
            brands: arg.isVirtualBuddy
              ? payload.brands
              : payload.brands?.filter(({ found }) => found! > 0),
          };

          if (!state.inventoryDetails.storeCode) {
            state.inventoryDetails = data;
          } else if (
            Number(data.inventoryProgress) >
            Number(state.inventoryDetails.inventoryProgress)
          ) {
            state.inventoryDetails = data;
          }
        }
      )
      .addCase(fetchInventoryDetails.rejected, (state, { payload }) => {
        state.fetchInventoryIsLoading = false;
        state.fetchInventoryHasError = payload;
      })
      .addCase(fetchInventoryReport.pending, state => {
        state.fetchInventoryReportIsloading = true;
        state.fetchInventoryReportHasError = undefined;
      })
      .addCase(fetchInventoryReport.fulfilled, (state, { payload }) => {
        state.fetchInventoryReportIsloading = false;
        state.inventoryReport = payload;
      })
      .addCase(fetchInventoryReport.rejected, (state, { payload }) => {
        state.fetchInventoryReportIsloading = false;
        state.fetchInventoryReportHasError = payload;
      })
      .addCase(
        addInventoryEpc.fulfilled,
        (state, { payload, meta: { arg } }) => {
          const { isInventoryOwner } = arg;

          if (isInventoryOwner) {
            state.inventoryReport = payload;
          }
        }
      )
      .addCase(addInventoryEpc.rejected, (state, { payload }) => {
        state.addInventoryEpcHasError = payload;
      })
      .addCase(closeInventory.rejected, (state, { payload }) => {
        state.closeInventoryHasError = payload;
      })
      .addCase(rejectInventory.rejected, (state, { payload }) => {
        state.refuseInventoryHasError = payload;
      });
  },
});

export const {
  addInventoryTags,
  setInventoryOwner,
  initInventoryTags,
  initInventoryState,
  initInventoryList,
  initInventoryDetails,
  updateInventoryDetails,
} = inventorySlice.actions;
export default inventorySlice.reducer;
