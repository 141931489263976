import { FC, memo } from 'react';
import { StyledAntSwitch } from './style';
import { IAntSwitch } from './type';

const AntSwitch: FC<IAntSwitch> = memo(
  ({
    checked,
    color = 'primary',
    inputProps,
    disabled,
    onClick,
  }): JSX.Element => (
    <StyledAntSwitch
      checked={checked}
      color={color}
      disabled={disabled}
      inputProps={inputProps}
      onClick={onClick}
    />
  )
);
AntSwitch.displayName = 'AntSwitch';

export default AntSwitch;
