/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfimRecallResponse } from '../models/ConfimRecallResponse';
import type { PdfFile } from '../models/PdfFile';
import type { RecallConfirmEncoded } from '../models/RecallConfirmEncoded';
import type { RecallConfirmList } from '../models/RecallConfirmList';
import type { RecallDetailsList } from '../models/RecallDetailsList';
import type { RecallList } from '../models/RecallList';
import type { RecallStatus } from '../models/RecallStatus';
import { request as __request } from '../core/request';

export class RecallService {
  /**
   * service to retrieve the list of recall to do
   * service to retrieve the list of recall to do.
   * @returns RecallList OK
   * @throws ApiError
   */
  public static async recallFindRecallOrders({
    storeCode,
    date,
  }: {
    /** filter for storeCode **/
    storeCode: any;
    /** filter for date **/
    date: any;
  }): Promise<RecallList> {
    const result = await __request({
      method: 'GET',
      path: `/recall/findRecallOrders`,
      query: {
        storeCode: storeCode,
        date: date,
      },
      errors: {
        400: `bad input parameter, mandatory fiels is missing`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * service to retrieve the details of recall to do
   * service to retrieve the details of recall to do
   * @returns RecallDetailsList OK
   * @throws ApiError
   */
  public static async recallDetailsRecallOrders({
    storeCode,
    idRecall,
  }: {
    /** filter for storeCode **/
    storeCode: any;
    /** filter for idRecall **/
    idRecall: any;
  }): Promise<RecallDetailsList> {
    const result = await __request({
      method: 'GET',
      path: `/recall/detailsRecallOrders`,
      query: {
        storeCode: storeCode,
        idRecall: idRecall,
      },
      errors: {
        400: `bad input parameter, mandatory fiels is missing`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * change recall status
   * change recall status
   * @returns any Status Changed
   * @throws ApiError
   */
  public static async recallChgangeRecallStatus({
    requestBody,
  }: {
    requestBody: RecallStatus;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/recall/changeRecallStatus`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirmRecall
   * confirmRecall
   * @returns ConfimRecallResponse Confirm Recall.
   * @throws ApiError
   */
  public static async recallConfirmRecall({
    requestBody,
  }: {
    requestBody: RecallConfirmList;
  }): Promise<ConfimRecallResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/recall/confirmRecall`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirmRecall
   * confirmRecall
   * @returns ConfimRecallResponse Confirm Recall.
   * @throws ApiError
   */
  public static async recallConfirmRecallEncoded({
    requestBody,
  }: {
    requestBody: RecallConfirmEncoded;
  }): Promise<ConfimRecallResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/recall/confirmRecallEncoded`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * RecallService service for download pdf
   * download pdf
   * @returns PdfFile OK
   * @throws ApiError
   */
  public static async recallDownloadPdf({
    storeCode,
    documentNumber,
    date,
  }: {
    /** filter for storeCode **/
    storeCode: any;
    /** filter for documentNumber **/
    documentNumber: any;
    /** filter for date **/
    date: any;
  }): Promise<PdfFile> {
    const result = await __request({
      method: 'GET',
      path: `/recall/downloadPdf`,
      query: {
        storeCode: storeCode,
        documentNumber: documentNumber,
        date: date,
      },
      errors: {
        400: `Bad request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
