import { UIBox } from '@/components/ui/Box';
import styled from 'styled-components';

export const Grid = styled(UIBox)<{ report: string }>`
  width: 100%;
  align-items: center;
  margin-top: ${({ mt }): string => mt || '24px'};
  display: grid;
  grid-template-columns: ${({ report }): string =>
    report === 'APP' ? 'auto 160px 160px' : 'auto 160px 160px 160px'};
  gap: 24px;
`;
