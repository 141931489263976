import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReasonCodeInitialState } from '@/types/profiling';

import { RootState } from '@/app/rootReducer';
import {
  ApiError,
  CheckGroupTitleBooleanObject,
  DetailedGroup,
  GroupList,
  MovementTypeCodesRequest,
  Processes,
  ReasonCodeService,
  ReasonCodesListResponse,
  AvailableSalesOrgAndMovementTypeCodesByProcessResponse,
  StoreListResponse,
  UpdateGroupRequest,
  SalesOrganizationListRequest,
  CreateGroupRequest,
  ReasonCodeStoreDetails,
  ReasonCodeDetails,
} from '@/api/receive';

const initialState: ReasonCodeInitialState = {
  group: {
    reasonCodes: [],
    stores: [],
    enabledStores: [],
    enabledReasonCodes: [],
  },
  groupList: {
    groups: [],
    lastUploadDate: '',
  },
  processes: [],
  movementTypes: [],
  salesOrgByProcess: [],
  groupTitleExist: false,
};

export const fetchGroupList = createAsyncThunk<
  GroupList,
  string | undefined,
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/getGroupList', async (storeCode, { rejectWithValue }) => {
  try {
    const response = await ReasonCodeService.reasoncodesLoadAllGroups({
      storeCode,
    });

    if (response) {
      return response;
    }

    return {
      groups: [],
      lastUploadDate: '',
    };
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const fetchProcesses = createAsyncThunk<
  Processes,
  void,
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/fetchProcesses', async (_, { rejectWithValue }) => {
  try {
    const response = await ReasonCodeService.reasoncodesGetAllProcesses();

    if (response) {
      return response;
    }

    return {
      processes: [],
    };
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const findGroup = createAsyncThunk<
  DetailedGroup,
  string,
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/findGroup', async (groupTitle, { rejectWithValue }) => {
  try {
    const response = await ReasonCodeService.reasoncodesFindGroupByGroupTitle({
      groupTitle,
    });

    if (response) {
      return response;
    }

    return {
      groupTitle: '',
      process: '',
      stores: [],
      salesOrg: [],
      reasonCodes: [],
      movementTypeCodes: [],
    };
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const checkGroupTitleAvailability = createAsyncThunk<
  CheckGroupTitleBooleanObject,
  string,
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/checkGroupTitle', async (groupTitle, { rejectWithValue }) => {
  try {
    return await ReasonCodeService.reasoncodesCheckGroupTitleUniqueName({
      groupTitle,
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const deleteGroup = createAsyncThunk<
  void,
  Parameters<typeof ReasonCodeService.reasoncodesDeleteGroup>[number],
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/deleteGroup', async ({ groupTitle }, { rejectWithValue }) => {
  try {
    return await ReasonCodeService.reasoncodesDeleteGroup({ groupTitle });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const fetchSalesAndMovementTypeByProcess = createAsyncThunk<
  AvailableSalesOrgAndMovementTypeCodesByProcessResponse,
  string,
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>(
  'profiling/fetchSalesAndMovementTypeByProcess',
  async (processName, { rejectWithValue }) => {
    try {
      const response =
        await ReasonCodeService.reasoncodesGetAvailableSalesOrgAndMovementTypeCodesByProcess(
          {
            processName,
          }
        );

      if (response) {
        return response;
      }

      return {
        salesOrg: [],
      };
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

export const fetchReasonCodeByMovementType = createAsyncThunk<
  ReasonCodesListResponse,
  MovementTypeCodesRequest,
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>(
  'profiling/fetchReasonCodeByMovementType',
  async (requestBody, { rejectWithValue }) => {
    try {
      const response =
        await ReasonCodeService.reasoncodesSearchReasonCodesByMovementTypeCodes(
          {
            requestBody,
          }
        );

      if (response) {
        return response;
      }

      return {
        reasonCodes: [],
      };
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

export const fetchStoresBySales = createAsyncThunk<
  StoreListResponse,
  SalesOrganizationListRequest,
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/fetchStoresBySales', async (requestBody, { rejectWithValue }) => {
  try {
    if (requestBody.salesOrg) {
      const { salesOrg } = requestBody;
      const lastSaleAdded = salesOrg[salesOrg.length - 1];

      const response =
        await ReasonCodeService.reasoncodesSearchStoresBySalesOrganizations({
          requestBody: {
            salesOrg: lastSaleAdded ? [lastSaleAdded] : [],
          },
        });

      if (response) {
        return response;
      }

      return {
        stores: [],
      };
    }

    return {
      stores: [],
    };
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const createGroup = createAsyncThunk<
  void,
  CreateGroupRequest,
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/createGroup', async (body, { rejectWithValue }) => {
  try {
    return await ReasonCodeService.reasoncodesCreateGroupEncoded({
      requestBody: {
        createGroupRequest: btoa(JSON.stringify(body)),
      },
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const updateGroup = createAsyncThunk<
  void,
  UpdateGroupRequest,
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/updateGroup', async (body, { rejectWithValue }) => {
  try {
    return await ReasonCodeService.reasoncodesUpdateGroupEncoded({
      requestBody: {
        updateGroupRequest: btoa(JSON.stringify(body)),
      },
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

const reasonCodeProfilingSlice = createSlice({
  name: 'reasonCodeprofiling',
  initialState,
  reducers: {
    deleteGroupFromState: (state, { payload }: PayloadAction<string>) => {
      const groups = state.groupList.groups?.filter(
        ({ groupTitle }) => groupTitle?.toLowerCase() !== payload.toLowerCase()
      );
      state.groupList.groups = groups;
    },
    changeStoreStatus: (state, { payload }: PayloadAction<string>) => {
      if (state.group.stores) {
        const store = state.group.stores.find(
          ({ storeCode }) => storeCode === payload
        );

        if (store) {
          store.enabled = !store.enabled;
          if (store.enabled) {
            state.group.enabledStores.push(store);
          } else {
            state.group.enabledStores = state.group.enabledStores.filter(
              ({ storeCode }) => storeCode !== payload
            );
          }
        }
      }
    },
    changeAllStoreStatus: (state, { payload }: PayloadAction<boolean>) => {
      if (state.group.stores) {
        state.group.stores = state.group.stores.map(store => ({
          ...store,
          enabled: payload,
        }));
      }
      if (payload) {
        state.group.enabledStores = state.group.stores || [];
      } else {
        state.group.enabledStores = [];
      }
    },
    changeReasonCodeStatus: (state, { payload }: PayloadAction<string>) => {
      if (state.group.reasonCodes) {
        const reasonCode = state.group.reasonCodes.find(
          ({ movementReasonCode }) => movementReasonCode === payload
        );

        if (reasonCode) {
          reasonCode.enabled = !reasonCode.enabled;
          if (reasonCode.enabled) {
            state.group.enabledReasonCodes.push(reasonCode);
          } else {
            state.group.enabledReasonCodes =
              state.group.enabledReasonCodes.filter(
                ({ movementReasonCode }) => movementReasonCode !== payload
              );
          }
        }
      }
    },
    changeAllReasonCodeStatus: (state, { payload }: PayloadAction<boolean>) => {
      if (state.group.reasonCodes) {
        state.group.reasonCodes = state.group.reasonCodes.map(reason => ({
          ...reason,
          enabled: payload,
        }));
      }
      if (payload) {
        state.group.enabledReasonCodes = state.group.reasonCodes || [];
      } else {
        state.group.enabledReasonCodes = [];
      }
    },
    removeStoreBySale: (state, { payload }: PayloadAction<string>) => {
      if (state.group.stores) {
        state.group.stores = state.group.stores.filter(
          ({ salesOrg }) => !salesOrg?.includes(payload)
        );
      }
    },
    removeReasonByMovement: (state, { payload }: PayloadAction<string>) => {
      if (state.group.reasonCodes) {
        state.group.reasonCodes = state.group.reasonCodes.filter(
          ({ movementTypeCode }) => movementTypeCode !== payload
        );
      }
    },
    addSale: (state, { payload }: PayloadAction<string>) => {
      state.salesOrgByProcess = [
        ...new Set([...state.salesOrgByProcess, payload]),
      ];
    },
    initProfilingReasonCodeGroup: state => {
      state.group = initialState.group;
      state.groupTitleExist = initialState.groupTitleExist;
      state.checkingGroupTitleSuccess = initialState.checkingGroupTitleSuccess;
      state.checkingGroupTitleAvailability =
        initialState.checkingGroupTitleAvailability;
    },
    initReasonCodeGroupStoresAndReasons: state => {
      state.group.stores = initialState.group.stores;
      state.group.reasonCodes = initialState.group.reasonCodes;
    },
    initProfilingReasonCode: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGroupList.pending, state => {
        state.fetchGroupListIsLoading = true;
        state.fetchGroupListHasError = undefined;
      })
      .addCase(fetchGroupList.fulfilled, (state, { payload }) => {
        state.fetchGroupListIsLoading = false;
        state.groupList.groups = payload.groups || [];
        state.groupList.lastUploadDate = payload.lastUploadDate || '';
      })
      .addCase(fetchGroupList.rejected, (state, { payload }) => {
        state.fetchGroupListIsLoading = false;
        state.fetchGroupListHasError = payload as ApiError;
      })
      .addCase(findGroup.pending, state => {
        state.fetchGroupIsLoading = true;
        state.fetchGroupHasError = undefined;
      })
      .addCase(findGroup.fulfilled, (state, { payload }) => {
        state.fetchGroupIsLoading = false;

        if (payload) {
          state.group = {
            ...payload,
            enabledStores: payload.stores || [],
            enabledReasonCodes: payload.reasonCodes || [],
          };
        }
      })
      .addCase(findGroup.rejected, (state, { payload }) => {
        state.fetchGroupIsLoading = false;
        state.fetchGroupHasError = payload as ApiError;
      })
      .addCase(checkGroupTitleAvailability.pending, state => {
        state.checkingGroupTitleAvailability = true;
        state.checkingGroupTitleSuccess = false;
        state.checkingGroupTitleHasError = undefined;
      })
      .addCase(checkGroupTitleAvailability.fulfilled, (state, { payload }) => {
        state.checkingGroupTitleAvailability = false;
        state.checkingGroupTitleHasError = undefined;

        if (payload.groupTitleExists) {
          state.groupTitleExist = payload.groupTitleExists;
          state.checkingGroupTitleSuccess = true;
        } else {
          state.checkingGroupTitleSuccess = false;
          state.groupTitleExist = false;
        }
      })
      .addCase(checkGroupTitleAvailability.rejected, (state, { payload }) => {
        state.checkingGroupTitleAvailability = false;
        state.checkingGroupTitleSuccess = false;
        state.checkingGroupTitleHasError = payload as ApiError;
      })
      .addCase(fetchProcesses.pending, state => {
        state.fetchProcessIsLoading = true;
        state.fetchProcessHasError = undefined;
      })
      .addCase(fetchProcesses.fulfilled, (state, { payload }) => {
        state.fetchProcessIsLoading = false;
        state.fetchProcessHasError = undefined;
        state.processes = payload.processes || [];
      })
      .addCase(fetchProcesses.rejected, (state, { payload }) => {
        state.fetchProcessIsLoading = false;
        state.fetchProcessHasError = payload as ApiError;
      })
      .addCase(fetchSalesAndMovementTypeByProcess.pending, state => {
        state.fetchSalesAndMovementTypesByProcessIsLoading = true;
        state.fetchSalesAndMovementTypesByProcessHasError = undefined;
      })
      .addCase(
        fetchSalesAndMovementTypeByProcess.fulfilled,
        (state, { payload }) => {
          state.fetchSalesAndMovementTypesByProcessHasError = undefined;
          state.fetchSalesAndMovementTypesByProcessIsLoading = false;
          state.salesOrgByProcess = payload.salesOrg || [];
          state.movementTypes = payload.movementTypeCodes || [];
        }
      )
      .addCase(
        fetchSalesAndMovementTypeByProcess.rejected,
        (state, { payload }) => {
          state.fetchSalesAndMovementTypesByProcessIsLoading = false;
          state.fetchSalesAndMovementTypesByProcessHasError =
            payload as ApiError;
        }
      )
      .addCase(fetchReasonCodeByMovementType.pending, state => {
        state.fetchReasonCodeByMovementTypeIsLoading = true;
        state.fetchReasonCodeByMovementTypeHasError = undefined;
      })
      .addCase(
        fetchReasonCodeByMovementType.fulfilled,
        (state, { payload }) => {
          state.fetchReasonCodeByMovementTypeIsLoading = false;
          state.fetchReasonCodeByMovementTypeHasError = undefined;

          if (payload.reasonCodes && state.group.reasonCodes) {
            const reasons = [
              ...state.group.reasonCodes,
              ...payload.reasonCodes,
            ];
            const resonAdded = [
              ...new Set(
                state.group.enabledReasonCodes.map(
                  reason => reason.movementTypeCode
                )
              ),
            ];
            state.group.enabledReasonCodes = [
              ...state.group.enabledReasonCodes,
              ...payload.reasonCodes.filter(
                ({ movementTypeCode }) =>
                  !resonAdded.includes(movementTypeCode!)
              ),
            ];
            state.group.reasonCodes = Array.from(
              reasons
                .reduce((m, o) => {
                  if (!m.has(o.movementReasonCode!))
                    m.set(o.movementReasonCode!, o);
                  return m;
                }, new Map<string, ReasonCodeDetails>())
                .values()
            );
            state.group.reasonCodes = state.group.reasonCodes.map(reason => ({
              ...reason,
              enabled:
                state.group.enabledReasonCodes.findIndex(
                  reasonA =>
                    reasonA.movementReasonCode === reason.movementReasonCode
                ) > -1,
            }));
          }
        }
      )
      .addCase(fetchReasonCodeByMovementType.rejected, (state, { payload }) => {
        state.fetchReasonCodeByMovementTypeIsLoading = false;
        state.fetchReasonCodeByMovementTypeHasError = payload as ApiError;
      })
      .addCase(fetchStoresBySales.pending, state => {
        state.fetchStoreBySalesIsLoading = true;
        state.fetchGroupListHasError = undefined;
      })
      .addCase(fetchStoresBySales.fulfilled, (state, { payload }) => {
        state.fetchStoreBySalesIsLoading = false;

        if (payload.stores && state.group.stores) {
          const stores = [...state.group.stores, ...payload.stores];
          const salesAdded = [
            ...new Set(state.group.enabledStores.map(store => store.salesOrg)),
          ];
          state.group.enabledStores = [
            ...state.group.enabledStores,
            ...payload.stores.filter(
              ({ salesOrg }) => !salesAdded.includes(salesOrg!)
            ),
          ];
          state.group.stores = Array.from(
            stores
              .reduce((m, o) => {
                if (!m.has(o.storeCode!)) m.set(o.storeCode!, o);
                return m;
              }, new Map<string, ReasonCodeStoreDetails>())
              .values()
          );
          state.group.stores = state.group.stores.map(store => ({
            ...store,
            enabled:
              state.group.enabledStores.findIndex(
                storeA => storeA.storeCode === store.storeCode
              ) > -1,
          }));
        }
      })
      .addCase(fetchStoresBySales.rejected, (state, { payload }) => {
        state.fetchStoreBySalesIsLoading = false;
        state.fetchGroupListHasError = payload as ApiError;
      })
      .addCase(createGroup.pending, state => {
        state.createGroupIsLoading = true;
        state.createGroupHasError = undefined;
      })
      .addCase(createGroup.fulfilled, state => {
        state.createGroupIsLoading = false;
      })
      .addCase(createGroup.rejected, (state, { payload }) => {
        state.createGroupIsLoading = false;
        state.createGroupHasError = payload as ApiError;
      })
      .addCase(updateGroup.pending, state => {
        state.updateGroupIsLoading = true;
        state.updateGroupHasError = undefined;
      })
      .addCase(updateGroup.fulfilled, state => {
        state.updateGroupIsLoading = false;
      })
      .addCase(updateGroup.rejected, (state, { payload }) => {
        state.updateGroupIsLoading = false;
        state.updateGroupHasError = payload as ApiError;
      });
  },
});

export const {
  removeStoreBySale,
  removeReasonByMovement,
  deleteGroupFromState,
  changeStoreStatus,
  changeAllStoreStatus,
  changeReasonCodeStatus,
  changeAllReasonCodeStatus,
  addSale,
  initProfilingReasonCode,
  initProfilingReasonCodeGroup,
  initReasonCodeGroupStoresAndReasons,
} = reasonCodeProfilingSlice.actions;
export default reasonCodeProfilingSlice.reducer;
