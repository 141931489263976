import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { usePrintContext } from '@/context/Print';
import { useLocation } from 'react-router';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';

import { isEmpty } from '@/utils/validation';

import type { Reason } from '@/api/receive';
import { AppRoutes } from '@/app/routers';
import { StyledSelect, StyledText } from './style';
import { StyledRadio } from '../style';

import { Typography } from '@/components/ui/Typography';
import { UIBox } from '@/components/ui/Box';

type ReceivingReason = Reason & {
  description: string;
};

const SelectReason: React.FC<{ reasonDescription?: JSX.Element }> = ({
  reasonDescription,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { setPrint } = usePrintContext();
  const [reasonCode, setReasonCode] = useState<string>('');
  const { reasons } = useSelector(state => state.storeDamages);

  const receivingReasons: ReceivingReason[] = useMemo(
    () => [
      {
        movementReason: t('printwizard.damaged'),
        movementReasonCode: 'Damaged',
        description: t('printwizard.damaged_desc'),
      },
      {
        movementReason: t('printwizard.missing'),
        movementReasonCode: 'Missing',
        description: t('printwizard.missing_desc'),
      },
    ],
    [t]
  );

  const isCycleCountOrReceivingProcess = useMemo(
    () =>
      pathname === AppRoutes.SCAN ||
      pathname === AppRoutes.CYCLE_COUNT_FILTER_PRODUCTS,
    [pathname]
  );

  const handleReasons: Reason[] | ReceivingReason[] =
    isCycleCountOrReceivingProcess ? receivingReasons : reasons;

  useEffect(() => {
    if (!isEmpty(reasonCode)) {
      const reasonDetails: Reason | undefined = handleReasons.find(
        ({ movementReasonCode }) => movementReasonCode === reasonCode
      );

      if (reasonDetails) {
        setPrint(prevState => ({
          ...prevState,
          reason: {
            movementReason: reasonDetails.movementReason,
            movementReasonCode: reasonDetails.movementReasonCode,
            movementTypeCode: reasonDetails.movementTypeCode,
          },
        }));
      }
    }
  }, [handleReasons, reasonCode, setPrint]);

  return (
    <>
      <UIBox ml={1} mb={1}>
        <Typography size="md" font="heavy" as="h1">
          {t('selectReasonCode')}
        </Typography>
      </UIBox>
      {reasonDescription && reasonDescription}
      {handleReasons.length > 2 ? (
        <StyledSelect
          selectwidth="100%"
          aligned="center"
          variant="outlined"
          exportValue={setReasonCode}
          values={
            handleReasons.map(({ movementReason, movementReasonCode }) => ({
              label: `${movementReasonCode} - ${movementReason}`,
              value: movementReasonCode,
            })) || []
          }
        />
      ) : (
        <UIBox flexDirection="column" maxWidth="285px">
          {(handleReasons as ReceivingReason[]).map(
            ({ movementReason, movementReasonCode, description }) => (
              <UIBox alignItems="center">
                <StyledRadio
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleChecked />}
                  checked={reasonCode === movementReasonCode}
                  disabled={false}
                  onChange={(): void => setReasonCode(movementReasonCode)}
                />
                <StyledText>
                  <Typography size="sm" font="heavy" as="h1">
                    {`${movementReasonCode} - ${movementReason}`}
                  </Typography>
                  <Typography size="xs" as="p">
                    {description}
                  </Typography>
                </StyledText>
              </UIBox>
            )
          )}
        </UIBox>
      )}
    </>
  );
};

export default SelectReason;
