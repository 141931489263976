import {
  ApiError,
  PosService,
  SearchReturnItem,
  SearchReturnItemsRequest,
} from '@/api/receive';
import { RootState } from '@/app/rootReducer';
import { InitialReturnPosState } from '@/types/returnPos';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatData } from './utils';

const initialState: InitialReturnPosState = {
  processLocked: false,
  returnItems: [],
  voidSales: [],
};

export const getReturnPosItems = createAsyncThunk<
  {
    returnItems: SearchReturnItem[];
    voidSales: SearchReturnItem[];
  },
  SearchReturnItemsRequest['posFlowType'],
  { rejectValue: ApiError; state: RootState }
>('getReturnPosItems', async (posFlowType, { getState, rejectWithValue }) => {
  try {
    const storeCode = getState().currentStore.store?.storeCode || '';

    const response = await PosService.posSearchReturnItems({
      requestBody: {
        storeCode,
        posFlowType,
      },
    });

    if (response) {
      return {
        returnItems: response.returnItems?.upcs || [],
        voidSales: response.voidSales?.upcs || [],
      };
    }

    return {
      returnItems: [],
      voidSales: [],
    };
  } catch (error) {
    return rejectWithValue(error as ApiError);
  }
});

export const returnPosSlice = createSlice({
  name: 'returnPosSlice',
  initialState,
  reducers: {
    setProcessLocked: (state, { payload }: PayloadAction<boolean>) => {
      state.processLocked = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getReturnPosItems.pending, state => {
        state.getReturnPosItemsIsLoading = true;
        state.getReturnPosItemsHasError = undefined;
      })
      .addCase(getReturnPosItems.fulfilled, (state, { payload }) => {
        state.getReturnPosItemsIsLoading = false;
        state.returnItems = formatData(payload.returnItems);
        state.voidSales = formatData(payload.voidSales);
      })
      .addCase(getReturnPosItems.rejected, (state, { payload }) => {
        state.getReturnPosItemsIsLoading = false;
        state.getReturnPosItemsHasError = payload as ApiError;
      });
  },
});

export const { setProcessLocked } = returnPosSlice.actions;
export default returnPosSlice.reducer;
