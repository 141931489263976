/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InventoryEpcResultEnum {
  FOUND = 'Found',
  NOT_FOUND = 'Not Found',
  UNEXPECTED = 'Unexpected',
  NO_RESULT = 'No Result',
}
