import { FC } from 'react';
import styled from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { Checkbox } from '@material-ui/core';

interface TablesCheckboxProps {
  name?: string;
  checked: boolean | undefined;
}

const StyledWrapper = styled(UIBox)`
  flex-direction: column;
`;

const StyledCheckboxWrapper = styled(UIBox)`
  align-items: center;

  & > span {
    padding: 9px 9px 9px 0;
  }

  p {
    color: #707173;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  svg {
    fill: #005192;
  }
`;

const TablesCheckbox: FC<TablesCheckboxProps> = ({ checked, name }) => {
  return (
    <StyledWrapper>
      <StyledCheckboxWrapper>
        <StyledCheckbox name={name} defaultChecked={checked} />
      </StyledCheckboxWrapper>
    </StyledWrapper>
  );
};

export default TablesCheckbox;
