import styled, { css } from 'styled-components';
import { TablesHeader, TablesRow } from '@/components/layout/Tables';
import { theme } from '@/theme';

export const GridStyles = css<{ reasonCode?: boolean }>`
  display: grid;
  gap: 16px;
  grid-template-columns: ${({ reasonCode }): string =>
    reasonCode
      ? '32px 32px 100px 150px 260px 150px 200px 200px auto'
      : '32px 32px 100px 150px 260px 150px 300px auto'};

  ${theme.breakpoints.down('md')} {
    gap: 8px;
  }
`;

export const TableHeader = styled(TablesHeader)<{ reasonCode?: boolean }>`
  ${GridStyles};
`;

export const TableRow = styled(TablesRow)<{ reasonCode?: boolean }>`
  ${GridStyles};
  align-items: center;

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      justify-self: end;
    }
  }
`;
