import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCycleCountContext } from '@/context/cycleCount';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { CheckedEpcs, CheckedUpcs, ProductListItemProps } from '../types';
import { ProductPrice } from '@/components/layout/ProductPrice';
import { ProductImage } from '@/components/layout/ProductImage';
import Reason from './Reason';
import Actions from './Actions';

import {
  StyledAccordion,
  StyledCheckbox,
  StyledProductItem,
  StyledContentInfo,
  StyledProductContent,
  StyledEpcCode,
  StyledAccordionContent,
  StyledAccordionItemContent,
  StyledDescription,
} from '../../listItemStyle';

import { removeNotFoundReason } from '@/features/cycleCount/cycleCountSlice';

import type { CycleDetailsItems as CCProduct } from '@/api';
import { EPC_STATUS, Product } from '@/types/enum';
import { StyledChip } from './style';
import useAddUnexpectedEpcs from '../../hooks/useAddUnexpectedEpcs';

//#region - TYPES
type Generic = { [key: string]: CCProduct | string[] };
type ClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
type SetStateUpcs = React.Dispatch<React.SetStateAction<CheckedUpcs>>;
type SetStateEpcs = React.Dispatch<React.SetStateAction<CheckedEpcs>>;
type SetStateFn = (setUpcs: SetStateUpcs, setEpcs: SetStateEpcs) => void;
//#endregion

export const ProductListItem: React.FC<ProductListItemProps & CCProduct> = ({
  type,
  disableActions,
  ...product
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    checkedNotFoundUpcs,
    checkedNotFoundEpcs,
    checkedUnexpectedUpcs,
    checkedUnexpectedEpcs,
    setCheckedNotFoundUpcs,
    setCheckedNotFoundEpcs,
    setCheckedUnexpectedUpcs,
    setCheckedUnexpectedEpcs,
    notFoundEpcsWithReason,
    setNotFoundEpcsWithReason,
    setNotFoundModalReasonVisibility,
    setUnexpectedModalReasonVisibility,
    setOldEpc,
    setProductToPrint,
    setPrintModalVisibility,
    setProductDetailsByUpc,
    setProductModalVisibility,
  } = useCycleCountContext();

  const hasSingleEpc = product.epcCodes && product.epcCodes.length === 1;
  const singleEpc = product.epcCodes[0].epcCode;
  const selectedNotFoundCount = Object.values(checkedNotFoundEpcs).flatMap(
    e => e
  ).length;

  const selectedUnexpectedCount = Object.values(checkedUnexpectedEpcs).flatMap(
    e => e
  ).length;

  const [accordionExpended, setAccordionExpanded] = useState<boolean>(false);

  const accordionClickHandler = (): void => setAccordionExpanded(p => !p);

  const {
    products: { found },
    isMissingItemsCycleCount,
  } = useSelector(state => state.cycleCount);

  const isSohProcess = useSelector(state => state.cycleCount.sohLocked);
  const printers = useSelector(state =>
    state.currentStore.store ? state.currentStore.store.printers : []
  );

  const isCheckboxVisible = isSohProcess
    ? type === Product.UNEXPECTED
    : (type === Product.NOT_FOUND && !isMissingItemsCycleCount) ||
      type === Product.UNEXPECTED;

  //#region - COMMON FUNCTIONS
  const handleProductImageClick = (): void => {
    setProductDetailsByUpc(product.upcCode);
    setProductModalVisibility(true);
  };

  const getUpcCheckedStatus = useCallback(
    (checkedUpcs: CheckedUpcs, checkedEpcs: CheckedEpcs): boolean => {
      const { upcCode, epcCodes } = product;

      return (
        upcCode in checkedUpcs &&
        epcCodes.length === checkedEpcs[upcCode].length
      );
    },
    [product]
  );

  const getEpcCheckedStatus = useCallback(
    (checkedEpcs: CheckedEpcs, epcCode: string): boolean => {
      const { upcCode } = product;
      const epcCodes = upcCode in checkedEpcs ? checkedEpcs[upcCode] : [];
      return epcCodes.includes(epcCode);
    },
    [product]
  );

  const isUpcChecked = useMemo((): boolean => {
    if (type === Product.NOT_FOUND) {
      return getUpcCheckedStatus(checkedNotFoundUpcs, checkedNotFoundEpcs);
    } else {
      return getUpcCheckedStatus(checkedUnexpectedUpcs, checkedUnexpectedEpcs);
    }
  }, [
    checkedNotFoundEpcs,
    checkedNotFoundUpcs,
    checkedUnexpectedEpcs,
    checkedUnexpectedUpcs,
    getUpcCheckedStatus,
    type,
  ]);

  const isEpcChecked = useCallback(
    (epcCode: string): boolean => {
      if (type === Product.NOT_FOUND) {
        return getEpcCheckedStatus(checkedNotFoundEpcs, epcCode);
      } else {
        return getEpcCheckedStatus(checkedUnexpectedEpcs, epcCode);
      }
    },
    [checkedNotFoundEpcs, checkedUnexpectedEpcs, getEpcCheckedStatus, type]
  );

  const setStateBasedOnType = (setState: SetStateFn): void => {
    switch (type) {
      case Product.NOT_FOUND:
        return setState(setCheckedNotFoundUpcs, setCheckedNotFoundEpcs);

      case Product.UNEXPECTED:
        return setState(setCheckedUnexpectedUpcs, setCheckedUnexpectedEpcs);

      default:
        return;
    }
  };

  const handleChecked = (e: ClickEvent): void => {
    const { upcCode } = product;
    const isChecked = (e.target as HTMLInputElement).checked;
    const epcs = product.epcCodes.map(({ epcCode }) => epcCode);

    const deleteKey = <T extends Generic>(state: T): T => {
      const copy = { ...state };
      delete copy[upcCode];
      return copy;
    };

    const updateKey = <T extends Generic, V>(state: T, value: V): T => {
      const copy = { ...state, [upcCode]: value };
      return copy;
    };

    const setState = (setUpcs: SetStateUpcs, setEpcs: SetStateEpcs): void => {
      if (!isChecked) {
        setUpcs(state => deleteKey<CheckedUpcs>(state));
        setEpcs(state => deleteKey<CheckedEpcs>(state));
      } else {
        setUpcs(state => updateKey<CheckedUpcs, CCProduct>(state, product));
        setEpcs(state => updateKey<CheckedEpcs, string[]>(state, epcs));
      }
    };

    setStateBasedOnType(setState);
  };

  const handleEpcCheckboxClick = (e: ClickEvent, epcCode: string): void => {
    const { upcCode } = product;
    const isChecked = (e.target as HTMLInputElement).checked;

    const deleteEpc = (state: CheckedEpcs): CheckedEpcs => {
      const copy = { ...state };

      return {
        ...copy,
        [upcCode]: state[upcCode].filter(epc => epc !== epcCode),
      };
    };

    const updateState = <T extends Generic, V>(state: T, value: V): T => {
      const copy = { ...state };

      return {
        ...copy,
        [upcCode]: value,
      };
    };

    const setState = (setUpcs: SetStateUpcs, setEpcs: SetStateEpcs): void => {
      if (!isChecked) {
        setEpcs(state => deleteEpc(state));
      } else {
        setUpcs(state => updateState<CheckedUpcs, CCProduct>(state, product));
        setEpcs(state =>
          updateState<CheckedEpcs, string[]>(
            state,
            upcCode in state ? [...state[upcCode], epcCode] : [epcCode]
          )
        );
      }
    };

    setStateBasedOnType(setState);
  };

  const handleUpcCheckboxClick = (e: ClickEvent): void => {
    handleChecked(e);
  };
  //#endregion

  //#region - NOT FOUND
  const disableReason = disableActions || selectedNotFoundCount > 1;
  const disablePrint = (epcCode: string): boolean =>
    disableActions || notFoundEpcsWithReason.includes(epcCode);

  const epcsReasoned = useSelector(state =>
    state.cycleCount.notFoundWithReason.flatMap(
      ({ epcNotFound }) => epcNotFound
    )
  );

  const isEpcReasoned = (epcCode: string): boolean =>
    epcsReasoned
      .flatMap(({ epcNotFoundCode }) => epcNotFoundCode)
      .includes(epcCode.toUpperCase());

  const getEpcReason = (epcCode: string): string =>
    epcsReasoned.find(({ epcNotFoundCode }) => epcNotFoundCode === epcCode)
      ?.reasonEpcNotFound || '';

  const handleReasonClick = (epcCode: string): void => {
    setCheckedNotFoundUpcs(() => ({
      [product.upcCode]: product,
    }));

    setCheckedNotFoundEpcs(() => ({
      [product.upcCode]: [epcCode],
    }));

    setNotFoundModalReasonVisibility(true);
  };

  const handleRemoveReason = (epcCode: string): void => {
    const removedReason = notFoundEpcsWithReason.filter(epc => epc !== epcCode);
    setNotFoundEpcsWithReason(removedReason || []);
    dispatch(removeNotFoundReason({ upc: product.upcCode, epc: epcCode }));
  };

  const handlePrintClick = (epcCode: string): void => {
    if (printers && printers.length > 0) {
      setProductToPrint({
        upcCode: product.upcCode!,
        currency: product.currency,
      });

      setOldEpc(epcCode);

      setPrintModalVisibility(true);
    }
  };
  //#endregion

  //#region - UNEXPECTED
  const disableAdd = disableActions || selectedUnexpectedCount > 1;
  const addUnexpectedEpcsToFound = useAddUnexpectedEpcs();
  const [addUnexpectedEpcCode, setAddUnexpectedEpcCode] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (isSohProcess && addUnexpectedEpcCode) {
      addUnexpectedEpcsToFound();
      setAddUnexpectedEpcCode(false);
    }
  }, [addUnexpectedEpcCode, addUnexpectedEpcsToFound, isSohProcess]);

  const isEpcPreviouslyUnexpected = useCallback(
    (epcCode: string): boolean => {
      const foundEpcCodes = found.flatMap(({ epcCodes }) => epcCodes);

      const index = foundEpcCodes.findIndex(
        p => p.epcCode === epcCode && p.epcStatus === EPC_STATUS.MISSING
      );

      return index !== -1;
    },
    [found]
  );

  const handleAddClick = (epcCode: string): void => {
    setCheckedUnexpectedUpcs(() => ({
      [product.upcCode]: product,
    }));

    setCheckedUnexpectedEpcs(() => ({
      [product.upcCode]: [epcCode],
    }));

    if (isSohProcess) {
      setAddUnexpectedEpcCode(true);
    } else {
      setUnexpectedModalReasonVisibility(true);
    }
  };
  //#endregion

  return (
    <>
      <StyledProductItem>
        <StyledProductContent $type={type}>
          <StyledContentInfo>
            {product.epcCodes.length > 1 && (
              <StyledAccordion
                expanded={accordionExpended}
                onClick={accordionClickHandler}
              />
            )}
            {isCheckboxVisible && (
              <StyledCheckbox
                checked={isUpcChecked}
                disabled={disableActions}
                onClick={(e): void => handleUpcCheckboxClick(e)}
              />
            )}
            <ProductImage
              imageUrl={product.imageUrl}
              colorCode={product.colorCode}
              modelCode={product.modelCode}
              brandCode={product.brandCode}
              onClick={handleProductImageClick}
            />
            <UIBox flexDirection="column">
              <StyledDescription>
                <UIBox alignItems="center">
                  <Typography size="lg" font="heavy" as="span">
                    {product.brandCode} - {product.brandDescription} -{' '}
                    {product.styleName}
                  </Typography>
                  {hasSingleEpc && isEpcPreviouslyUnexpected(singleEpc) && (
                    <StyledChip label={t('new')} />
                  )}
                </UIBox>
                <Typography margin="8px 0 0 0" size="md" font="book" as="span">
                  {product.upcCode} - {product.modelCode} - {product.commodity}
                  {hasSingleEpc && (
                    <StyledEpcCode> - {singleEpc}</StyledEpcCode>
                  )}
                </Typography>
              </StyledDescription>
            </UIBox>
          </StyledContentInfo>
          <UIBox flexDirection="column">
            <ProductPrice
              currency={product.currency}
              price={product.price ? String(product.price) : undefined}
            />
            <UIBox alignSelf="end">
              <Typography>
                {t('itemQuantity', { itemQuantity: product.epcCodes.length })}
              </Typography>
            </UIBox>
            {hasSingleEpc && (
              <UIBox
                flexDirection="column"
                alignItems="end"
                mt={1}
                alignSelf="end"
              >
                <Actions
                  type={type}
                  addClick={(): void => handleAddClick(singleEpc)}
                  reasonClick={(): void => handleReasonClick(singleEpc)}
                  printClick={(): void => handlePrintClick(singleEpc)}
                  disableAdd={disableAdd}
                  disableReason={disableReason}
                  disablePrint={disablePrint(singleEpc)}
                />
                {isEpcReasoned(singleEpc) && (
                  <UIBox flexDirection="column">
                    <Reason
                      ml={0}
                      removeReason={(): void => handleRemoveReason(singleEpc)}
                      reason={getEpcReason(singleEpc)}
                    />
                  </UIBox>
                )}
                {isEpcPreviouslyUnexpected(singleEpc) && (
                  <Typography size="xs" color="grey">
                    * {t('unexpectedBeforeScan')}
                  </Typography>
                )}
              </UIBox>
            )}
          </UIBox>
        </StyledProductContent>
        {!hasSingleEpc && accordionExpended && (
          <StyledAccordionContent ml="0px">
            {product.epcCodes?.map(({ epcCode }) => (
              <StyledAccordionItemContent key={epcCode}>
                <UIBox alignItems="center">
                  {isCheckboxVisible && (
                    <StyledCheckbox
                      checked={isEpcChecked(epcCode)}
                      disabled={disableActions}
                      onClick={(e): void => handleEpcCheckboxClick(e, epcCode)}
                    />
                  )}
                  <UIBox alignItems="center">
                    <Typography margin="0 0 0 12px" color="grey">
                      {epcCode}
                    </Typography>
                    {isEpcPreviouslyUnexpected(epcCode) && (
                      <UIBox alignItems="center">
                        <StyledChip label={t('new')} />
                        <Typography size="xs" color="grey" margin="0 0 0 12px">
                          * {t('unexpectedBeforeScan')}
                        </Typography>
                      </UIBox>
                    )}
                  </UIBox>
                  {type === Product.NOT_FOUND && isEpcReasoned(epcCode) && (
                    <Reason
                      reason={getEpcReason(epcCode)}
                      removeReason={(): void => handleRemoveReason(epcCode)}
                    />
                  )}
                </UIBox>
                <Actions
                  type={type}
                  addClick={(): void => handleAddClick(epcCode)}
                  reasonClick={(): void => handleReasonClick(epcCode)}
                  printClick={(): void => handlePrintClick(epcCode)}
                  disableAdd={disableAdd}
                  disableReason={disableReason}
                  disablePrint={disablePrint(epcCode)}
                />
              </StyledAccordionItemContent>
            ))}
          </StyledAccordionContent>
        )}
      </StyledProductItem>
    </>
  );
};

export default ProductListItem;
