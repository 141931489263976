import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StoresState } from '@/types/profiling';

import { RootState } from '@/app/rootReducer';
import { ApiError, ReceiveShipmentService } from '@/api/receive';
import { StoreList } from '@/api/receive';

const initialState: StoresState = {
  stores: [],
};

export const fetchStores = createAsyncThunk<
  StoreList,
  void,
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('receiveship/getStoreList', async (_, { rejectWithValue }) => {
  try {
    return await ReceiveShipmentService.receiveshipGetStoreList();
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

const storesProfilingSlice = createSlice({
  name: 'storesProfiling',
  initialState,
  reducers: {
    initProfilingStoresState: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStores.pending, state => {
        state.fetchStoresIsLoading = true;
        state.fetchStoresHasError = undefined;
      })
      .addCase(fetchStores.fulfilled, (state, { payload }) => {
        if (payload.stores) {
          state.fetchStoresIsLoading = false;
          state.stores = payload.stores;
        }
      })
      .addCase(fetchStores.rejected, (state, { payload }) => {
        state.fetchStoresIsLoading = false;
        state.fetchStoresHasError = payload as ApiError;
      });
  },
});

export const { initProfilingStoresState } = storesProfilingSlice.actions;
export default storesProfilingSlice.reducer;
