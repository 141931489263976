import React, { useState } from 'react';
import type { TPrintContext, Print } from './types';

export const initialPrintState: Print = {
  data: {},
  reason: {
    movementReason: '',
    movementReasonCode: '',
  },
  error: {
    visible: false,
  },
};

const PrintContext = React.createContext<TPrintContext>({
  print: initialPrintState,
  setPrint: () => {},
  writeTagsError: false,
  setWriteTagsError: () => {},
});

export const PrintContextProvider: React.FC = ({ children }) => {
  const [writeTagsError, setWriteTagsError] = useState<boolean>(false);
  const [print, setPrint] = useState<Print>(initialPrintState);

  return (
    <PrintContext.Provider
      value={{
        print: {
          ...print,
        },
        setPrint,
        writeTagsError,
        setWriteTagsError,
      }}
    >
      {children}
    </PrintContext.Provider>
  );
};

export const usePrintContext = (): TPrintContext =>
  React.useContext(PrintContext);
