import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import RefreshIcon from '@material-ui/icons/Refresh';
import { Timer } from '@/components/layout/Timer';
import { UIButtonWithIcon } from '@/components/ui/Button';
import { useSelector } from '@/hooks/useSelector';

type ActionGroupProps = {
  inPause: boolean;
  onUpdateClick: () => void;
  setPause: React.Dispatch<React.SetStateAction<boolean>>;
};

const ActionGroup = ({
  inPause,
  setPause,
  onUpdateClick,
}: ActionGroupProps): JSX.Element => {
  const isIPad = useMediaQuery('(max-width:1110px)');
  const { deviceInUse } = useSelector(state => state.devices);

  return (
    <>
      <UIButtonWithIcon
        margin={isIPad ? '0 8px 0 0' : '0 8px 0 32px'}
        label=""
        startIcon={<RefreshIcon fontSize="large" />}
        onClick={onUpdateClick}
        disabled={!deviceInUse}
      />
      <Timer
        $v2
        inPause={inPause}
        setPause={setPause}
        minutes="0"
        seconds="0"
      />
    </>
  );
};

export default ActionGroup;
