import { FC, memo, useState, useEffect } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { userLogout } from '../features/user/userSlice';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppContainer } from '@/components/layout/AppContainer';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { Typography } from '@/components/ui/Typography';
import { useAppDispatch } from '@/app/store';

const PageLogout: FC = memo((): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isLoadingLogin = useSelector(state => state.user.isLoadingLogin);
  const hasError = useSelector(state => state.user.loginError);
  const sroat = sessionStorage.getItem('userToken');
  const { t } = useTranslation();

  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);

  useEffect(() => {
    dispatch(userLogout());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingLogin && !hasError && !sroat) {
      history.push('/');
    }
  }, [history, isLoadingLogin, sroat, hasError]);

  useEffect(() => {
    setIsErrorVisible(hasError);
  }, [hasError]);

  return (
    <AppContainer>
      <Typography as="h1">{t('logout')}</Typography>

      <ErrorSnackbar
        open={isErrorVisible}
        setIsOpen={setIsErrorVisible}
        errorMessage={t('error.logout')}
      />
    </AppContainer>
  );
});

PageLogout.displayName = 'Logout';

export default PageLogout;
