import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { usePrintContext } from '@/context/Print';

import Devices from '@/components/layout/ModalScanDetailsV2/ColumnDevice';
import { Typography } from '@/components/ui/Typography';

import type { SelectDeviceProps } from './type';

import {
  StyledDoneIcon,
  StyledErrorIcon,
  StyledMessageIconWrapper,
  StyledMessageWrapper,
  StyledPrintedEpc,
  StyledStartUseReaderButton,
} from '../style';
import { useAppDispatch } from '@/app/store';
import { resetWriteTagsState } from '@/features/devices/devicesSlice';

const SelectDevice: React.FC<SelectDeviceProps> = ({
  device,
  setDevice,
  setActiveStep,
  onStartClick,
  isWriteTagsReady,
}) => {
  const { print } = usePrintContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { writeTagsIsLoading, writeTagsHasError } = useSelector(
    state => state.devices
  );

  const handleTryAgainClick = (): void => {
    dispatch(resetWriteTagsState());
  };

  return (
    <Devices
      setDevice={setDevice}
      setActiveStep={setActiveStep}
      selectedDevice={device}
      title={t('selectDevice')}
    >
      {print.data.printedEPC && (
        <StyledPrintedEpc>
          {t('device.writetags.encodeready')}:{' '}
          {print.data.printedEPC.toUpperCase()}
        </StyledPrintedEpc>
      )}
      {isWriteTagsReady ? (
        <StyledMessageWrapper>
          <StyledMessageIconWrapper>
            <StyledDoneIcon />
          </StyledMessageIconWrapper>
          {t('device.writetags.isready')}
        </StyledMessageWrapper>
      ) : (
        <>
          {writeTagsHasError ? (
            <>
              <StyledMessageWrapper>
                <StyledMessageIconWrapper color="#f6bb3b">
                  <StyledErrorIcon />
                </StyledMessageIconWrapper>
                <Typography font="heavy" as="h1">
                  {t('error.something_went_wrong')}
                </Typography>
                <Typography font="book">
                  {t('device.writetags.encodeagain')}
                </Typography>
              </StyledMessageWrapper>
              <StyledStartUseReaderButton
                label={t('tryAgain')}
                onClick={handleTryAgainClick}
              />
            </>
          ) : (
            <StyledStartUseReaderButton
              label={t('startUseReader')}
              loading={writeTagsIsLoading}
              disabled={writeTagsIsLoading}
              onClick={onStartClick}
            />
          )}
        </>
      )}
    </Devices>
  );
};

export default SelectDevice;
