// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUniqueListBy = (arr: any[], key: string): any[] => {
  return [...new Map(arr.map(item => [item[key], item])).values()];
};

export function sortByKey<TVals>(
  vals: TVals[],
  key: keyof TVals,
  keys: string[],
  secondary: (a: TVals, b: TVals) => number = (): number => 0
): TVals[] {
  return vals.sort((a: TVals, b: TVals) => {
    if (a?.[key] !== b?.[key]) {
      return keys.indexOf(`${a?.[key]}`) - keys.indexOf(`${b?.[key]}`);
    }
    return secondary(a, b);
  });
}

export const joinArray = (arr: Array<string>, joiner = ', '): string =>
  arr?.join(joiner) || '';

export const sum = (
  arr: (number | undefined)[] | undefined
): number | undefined =>
  arr?.reduce(
    (previousValue, currentValue) => (previousValue ?? 0) + (currentValue ?? 0),
    0
  );
