import { FC } from 'react';
import { ListItemIcon, ListItemText } from '@material-ui/core';

import { StyledListItem, StyledListItemCheckbox } from './style';
import { UIListItemProps } from './types';

export const UIListItem: FC<UIListItemProps> = ({
  children,
  checked,
  text,
  onClick,
  disabled,
}) => {
  return (
    <StyledListItem button disabled={disabled} onClick={(): void => onClick()}>
      <ListItemIcon>
        <StyledListItemCheckbox
          edge="start"
          tabIndex={-1}
          disableRipple
          checked={checked}
          disabled={disabled}
        />
      </ListItemIcon>

      <ListItemText>{text}</ListItemText>

      {children}
    </StyledListItem>
  );
};

UIListItem.displayName = 'UIListItem';

export default UIListItem;
