import React from 'react';

import { UIList } from '@/components/ui/List';
import { RecallNotFoundListItem } from '@/components/layout/RecallList/RecallNotFoundList';

import type { RecallBrand } from '@/api/receive';

const RecallNotFoundList: React.FC<Omit<RecallBrand, 'brandName'>> = ({
  upcs,
}) => {
  return (
    <UIList bordered shadowed backgrounded>
      {upcs.map(data => (
        <RecallNotFoundListItem key={data.upcCode} {...data} />
      ))}
    </UIList>
  );
};

export default RecallNotFoundList;
