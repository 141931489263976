import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { UIAction } from '@/components/ui/Action';
import { PaginationList } from '@/components/layout/PaginationList';
import { Typography } from '@/components/ui/Typography';

import { theme } from '@/theme';
import type { Group } from '@/api/receive';

interface ProfilingGroupListProps {
  groupList: Group[];
  isSingleStore?: boolean;
  onEditClick?: (title?: string) => void;
  onRemoveClick?: (title?: string) => void;
}

//#region - Styled Components
export const gridStyles = css<{ $isSingleStore?: boolean }>`
  display: grid;
  gap: 24px;
  grid-template-columns: ${({ $isSingleStore }): string =>
    $isSingleStore ? '150px 300px auto' : 'repeat(5, 15.5%) auto'};

  ${theme.breakpoints.down('md')} {
    gap: 16px;
  }
`;

const StyledWrapper = styled.div<{ $isSingleStore?: boolean }>`
  width: 100%;
  padding: ${({ $isSingleStore }): string => ($isSingleStore ? '0' : '0 24px')};
  margin-bottom: 120px;
  .kPaginationListItem > div {
    padding: 11px 0;
  }
`;

const StyledGrid = styled(UIBox)`
  width: 100%;
  flex-direction: column;
  margin-bottom: 120px;

  & > * {
    padding: 0;
  }
`;

const StyledGridHeader = styled(UIBox)<{ $isSingleStore?: boolean }>`
  width: 100%;
  padding: 8px 24px;
  border-bottom: 1px solid #4b4b4b;
  ${gridStyles}
`;

const StyledRowWrapper = styled(UIBox)`
  flex-direction: column;

  &:not(:nth-of-type(odd)) {
    background: #fbfbfb;
  }
`;

const StyledGridRow = styled(UIBox)`
  width: 100%;
  padding: 11px 24px;
`;

const StyledGridRowContent = styled(UIBox)<{ $isSingleStore?: boolean }>`
  width: 100%;
  padding: 8px 0;
  ${gridStyles}

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      width: 100%;
      justify-self: flex-end;
    }
  }
`;

const StyledActionsWrapper = styled(UIBox)`
  align-items: center;

  & > * {
    &:not(:last-child) {
      margin-left: auto;
      margin-right: 12px;
    }
  }
  ${theme.breakpoints.down(1150)} {
    flex-direction: column;
    align-items: end;
    div:first-child {
      margin: 0;
      margin-bottom: 15px;
    }
  }
  ${theme.breakpoints.down(860)} {
    div p {
      display: none;
    }
  }
`;

//#endregion

const ProfilingGroupList: React.FC<ProfilingGroupListProps> = ({
  groupList,
  onEditClick,
  onRemoveClick,
  isSingleStore = false,
}) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper $isSingleStore={isSingleStore}>
      <StyledGrid>
        <StyledGridHeader $isSingleStore={isSingleStore}>
          {isSingleStore ? (
            <>
              <Typography font="heavy">{t('groupTitle')}</Typography>
              <Typography font="heavy">{t('process')}</Typography>
              <Typography font="heavy">{t('reasonCodesFromSap')}</Typography>
            </>
          ) : (
            <>
              <Typography font="heavy">{t('createdBy')}</Typography>
              <Typography font="heavy">{t('groupTitle')}</Typography>
              <Typography font="heavy">{t('process')}</Typography>
              <Typography font="heavy">{t('salesOrganization')}</Typography>
              <Typography font="heavy">{t('movementTypeCode')}</Typography>
            </>
          )}
        </StyledGridHeader>

        {groupList && groupList.length > 0 ? (
          <PaginationList
            data={groupList}
            pageSize={20}
            renderItem={(group: Group): JSX.Element => (
              <StyledRowWrapper key={group.groupTitle}>
                <StyledGridRow>
                  <StyledGridRowContent $isSingleStore={isSingleStore}>
                    {isSingleStore ? (
                      <>
                        <Typography font="medium">
                          {group.groupTitle}
                        </Typography>
                        <Typography font="medium">{group.process}</Typography>
                        <Typography font="medium">
                          {group.movementTypeCodes?.join(', ')}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography font="medium">{group.createdBy}</Typography>
                        <Typography font="medium">
                          {group.groupTitle}
                        </Typography>
                        <Typography font="medium">{group.process}</Typography>
                        <Typography font="medium">
                          {group.salesOrg?.join(', ')}
                        </Typography>
                        <Typography font="medium">
                          {group.movementTypeCodes?.join(', ')}
                        </Typography>
                        <StyledActionsWrapper>
                          <UIAction
                            icon="delete"
                            label={t('remove')}
                            onClick={(): void =>
                              onRemoveClick?.(group.groupTitle)
                            }
                          />
                          <UIAction
                            icon="edit"
                            label={t('edit')}
                            onClick={(): void =>
                              onEditClick?.(group.groupTitle)
                            }
                          />
                        </StyledActionsWrapper>
                      </>
                    )}
                  </StyledGridRowContent>
                </StyledGridRow>
              </StyledRowWrapper>
            )}
          />
        ) : (
          <UIBox
            marginTop={8}
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Typography>{t('noGroupsCreatedYet')}</Typography>
          </UIBox>
        )}
      </StyledGrid>
    </StyledWrapper>
  );
};

export default ProfilingGroupList;
