import React from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';

import { UIBox } from '@/components/ui/Box';
import { UIAction } from '@/components/ui/Action';
import type { ProductActionsProps } from '../types';
import { Product } from '@/types/enum';

const Actions: React.FC<ProductActionsProps> = ({
  type,
  addClick,
  printClick,
  reasonClick,
  disableAdd,
  disablePrint,
  disableReason,
}) => {
  const { t } = useTranslation();
  const { isMissingItemsCycleCount } = useSelector(state => state.cycleCount);
  const isSohProcess = useSelector(state => state.cycleCount.sohLocked);
  const { store } = useSelector(state => state.currentStore);

  return (
    <UIBox>
      {type === Product.NOT_FOUND &&
        !isMissingItemsCycleCount &&
        !isSohProcess && (
          <UIAction
            label={t('reason')}
            icon={'edit'}
            disabled={disableReason}
            onClick={reasonClick}
          />
        )}
      {type === Product.NOT_FOUND && (
        <UIBox ml={1}>
          <UIAction
            label={t('print')}
            icon="print"
            disabled={
              store?.printers === null ||
              store?.printers?.length === 0 ||
              disablePrint
            }
            onClick={printClick}
          />
        </UIBox>
      )}
      {type === Product.UNEXPECTED && (
        <UIAction
          label={t('add')}
          icon={'add'}
          disabled={disableAdd}
          onClick={addClick}
        />
      )}
    </UIBox>
  );
};

export default Actions;
