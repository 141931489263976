import { FC } from 'react';
import { UIBox } from '@/components/ui/Box';
import styled from 'styled-components';

const StyledTablesRow = styled(UIBox)`
  width: 100%;
  padding: 11px 24px;

  &:not(:nth-child(odd)) {
    background: #fbfbfb;
  }
`;

const StyledTablesRowContent = styled(UIBox)`
  width: 100%;
  padding: 8px 0;

  /* & > * {
    &:last-child {
      width: 100%;
      justify-self: flex-end;
    }
  } */
`;

const TableRow: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <StyledTablesRow>
      <StyledTablesRowContent className={className}>
        {children}
      </StyledTablesRowContent>
    </StyledTablesRow>
  );
};

export default TableRow;
