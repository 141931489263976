import styled from 'styled-components';
import { UIButton } from '@/components/ui/Button';
import { UIBox } from '@/components/ui/Box';
import { DatePicker } from '@material-ui/pickers';

export const StyledFiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
`;

export const StyledCalendarWrapper = styled(UIBox)`
  flex: 1;
  align-items: center;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 280px;

  .MuiFormLabel-root.Mui-disabled {
    color: #ccc !important;
  }

  .MuiOutlinedInput-root.Mui-disabled {
    input {
      border-radius: 4px;
      background: #e8e7e7 !important;

      &::placeholder {
        color: #4b4b4b;
      }
    }

    fieldset {
      border-color: #e8e7e7 !important;
      cursor: arrow;
    }
  }
`;

export const SearchContainer = styled(UIBox)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  padding: 34px 0 0;
`;

export const SearchButton = styled(UIButton)`
  && {
    width: 150px;
    padding: 26px 0;
  }
`;
