import { ProductImage } from '@/components/layout/ProductImage';
import { Typography } from '@/components/ui/Typography';
import React, { useEffect, useState } from 'react';

import { IUpcLI } from './types';

import {
  StyledContentInfo,
  StyledDescription,
  StyledProductContent,
  StyledProductItem,
  StyledCheckbox,
} from '@/pages/CycleCount/listItemStyle';
import { UIBox } from '@/components/ui/Box';
import { ProductPrice } from '@/components/layout/ProductPrice';
import { IListCCSelect } from '../ListCCSelect/types';
import { useTranslation } from 'react-i18next';
import { EPC_STATUS } from '@/types/enum';

type ClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

const UpcLI: React.FC<IUpcLI> = ({ el, keyName, setChecked, forceChecked }) => {
  const { t } = useTranslation();

  const {
    imageUrl,
    brandCode,
    commodity,
    currency,
    price,
    brandDescription,
    modelCode,
    colorCode,
    upcCode,
    epcCodes,
  } = el;

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(!!forceChecked);
  }, [forceChecked]);

  const handleCheckboxClick = (e: ClickEvent): void => {
    const isChecked = (e.target as HTMLInputElement).checked;
    const key = keyName.split('-')[0] as IListCCSelect['keyName'];

    setChecked(p => ({
      ...p,
      [key === 'upcCode' ? upcCode : `${upcCode}|${modelCode}`]: isChecked,
    }));
  };

  return (
    <StyledProductItem>
      <StyledProductContent $type={'IN_TRANSIT'}>
        <StyledContentInfo>
          <StyledCheckbox
            key={1}
            checked={isChecked}
            onClick={handleCheckboxClick}
          />
          <ProductImage
            imageUrl={imageUrl}
            colorCode={colorCode}
            modelCode={modelCode}
            brandCode={brandCode}
            onClick={(): void => {}}
          />
          <UIBox flexDirection="column">
            <StyledDescription>
              <Typography size="lg" font="heavy" as="span">
                {brandCode} - {brandDescription}
              </Typography>
              <Typography margin="8px 0 0 0" size="md" font="book" as="span">
                {upcCode} - {modelCode} - {commodity}
              </Typography>
            </StyledDescription>
          </UIBox>
        </StyledContentInfo>
        <UIBox flexDirection="column">
          <ProductPrice
            currency={currency}
            price={price ? String(price) : undefined}
          />
          <UIBox alignSelf="end">
            <Typography>
              {t('itemQuantity', {
                itemQuantity: epcCodes.filter(
                  ({ epcStatus }) => epcStatus === EPC_STATUS.IN_STOCK
                ).length,
              })}
            </Typography>
          </UIBox>
        </UIBox>
      </StyledProductContent>
    </StyledProductItem>
  );
};

export default UpcLI;
