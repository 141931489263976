import { useTranslation } from 'react-i18next';
import React from 'react';

import { ProductImage } from '@/components/layout/ProductImage';
import { ProductPrice } from '@/components/layout/ProductPrice';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';

import {
  StyledContentInfo,
  StyledDescription,
  StyledProductContent,
  StyledAccordionItemContent,
} from '@/pages/CycleCount/listItemStyle';

import { StyledProductItem, CustomStyledAccordionContent } from './style';

import { UIAction } from '@/components/ui/Action';
import { ProductListItemProps } from './types';
import { Type } from '../ProductList/types';
import { DateTime } from 'luxon';
import { CustomReturnPosItem } from '@/types/returnPos';

const ProductListItem: React.FC<CustomReturnPosItem & ProductListItemProps> = ({
  onPrintClick,
  onRemoveClick,
  type,
  ...item
}) => {
  const { t, i18n } = useTranslation();

  const createDate = (date: string): string => {
    return DateTime.fromFormat(date, 'yyyyMMdd').toFormat('yyyy LLLL dd', {
      locale: i18n.language,
    });
  };

  return (
    <>
      <StyledProductItem>
        <StyledProductContent>
          <StyledContentInfo>
            <ProductImage
              imageUrl={item.imageUrl}
              colorCode={item.colorCode}
              modelCode={item.modelCode}
              brandCode={item.brandCode}
            />
            <UIBox flexDirection="column">
              <StyledDescription>
                <UIBox alignItems="center">
                  <Typography size="lg" font="heavy" as="span">
                    {item.brandCode || 'ND'} - {item.brandDescription || 'ND'} -{' '}
                    {item.styleName || 'ND'}
                  </Typography>
                </UIBox>
                <Typography margin="8px 0 0 0" size="md" font="book" as="span">
                  {item.upcCode || 'ND'} - {item.modelCode || 'ND'} -{' '}
                  {item.commodity || 'ND'}
                </Typography>
              </StyledDescription>
            </UIBox>
          </StyledContentInfo>
          <UIBox flexDirection="column">
            <ProductPrice
              currency={item.currency}
              price={item.price ? String(item.price) : undefined}
            />
            <UIBox alignSelf="end">
              <Typography>
                {t('itemQuantity', {
                  itemQuantity: item.items || 0,
                })}
              </Typography>
            </UIBox>
          </UIBox>
        </StyledProductContent>
        <CustomStyledAccordionContent ml="0px">
          <StyledAccordionItemContent>
            {item.date && (
              <UIBox>
                <Typography color="grey" margin="0 8px">
                  {t('returnDate')}:{' '}
                </Typography>
                <Typography font="heavy">{createDate(item.date)}</Typography>
              </UIBox>
            )}
            {item.posReasonCode && (
              <UIBox>
                <Typography color="grey" margin="0 8px">
                  {t('returnPos')}:{' '}
                </Typography>
                <Typography font="heavy">{item.posReasonCode}</Typography>
              </UIBox>
            )}
            {type === Type.EXCEPTIONS && (
              <UIBox>
                <UIAction
                  label={t('remove')}
                  icon="delete"
                  onClick={(): Promise<void> | void => onRemoveClick?.(item)}
                />
              </UIBox>
            )}
            {type !== Type.EXCEPTIONS && (
              <UIBox>
                <UIAction
                  label={t('print')}
                  icon="print"
                  onClick={(): Promise<void> | void =>
                    onPrintClick?.(type, item)
                  }
                />
              </UIBox>
            )}
          </StyledAccordionItemContent>
        </CustomStyledAccordionContent>
      </StyledProductItem>
    </>
  );
};

export default ProductListItem;
