import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ElasticHandlerRestService,
  StockSummaryService,
} from '../../../api/receive';
import { AppRoutes } from '../../../app/routers';
import UIAutocomplete from '../../../components/ui/AutoComplete/AutoComplete';
import { UIButtonWithIcon } from '../../../components/ui/Button';
import { useSelector } from '../../../hooks/useSelector';
import {
  StyledFilterWrapper,
  StyledDownloadWrapper,
} from '../../PageStoreMigrator/style';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import {
  isValidUpc,
  isValidEpc,
  isValidModel,
} from '../../../utils/validation';

interface Props {
  onChangeCommodityFilter: (commodity: string) => void;
  onChangeBrandFilter: (brand: string) => void;
}

export const FilterBarStockSummary: FC<Props> = ({
  onChangeCommodityFilter,
  onChangeBrandFilter,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const { store } = useSelector(state => state.currentStore);
  const [upcs, setUpcs] = useState<string[]>([]);
  const [brandFilter, setBrandFilter] = useState<string>('');
  const [upcFilter, setUpcFilter] = useState<string>('');
  const [epcFilter, setEpcFilter] = useState<string>('');
  const [modelFilter, setModelFilter] = useState<string>('');
  const [upcFilterIsValid, setUpcFilterIsValid] = useState<boolean>(false);
  const [epcFilterIsValid, setEpcFilterIsValid] = useState<boolean>(false);
  const [modelFilterIsValid, setModelFilterIsValid] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [listEpcs, setListEpcs] = useState<Array<string>>([]);
  const [listModels, setListModels] = useState<Array<string>>([]);
  const { stockItemDetail } = useSelector(
    state => state.stockSummary.stockBrandDetail
  );
  const { stockItems } = useSelector(state => state.stockSummary.brands);

  const query: {
    brand?: string;
    upcCode?: string;
    commodity?: string;
    epcCode?: string;
    modelCode?: string;
  } = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );

  const [commodityFilter, setCommodityFilter] = useState<string>(
    !!query.commodity ? query.commodity : t('stock.all')
  );

  const commodityValues = (): {
    value: string;
    label: string;
  }[] => {
    let array = query.brand
      ? stockItemDetail?.map(({ commodity }) => commodity || '')
      : stockItems?.map(({ commodity }) => commodity || '');
    return [...new Set(array)]
      .filter(cm => cm !== '')
      .sort()
      .map(item => ({ value: item, label: item }));
  };
  const brandValues = [...new Set(stockItems?.map(({ brand }) => brand!))]
    .sort()
    .map(item => ({ value: item, label: item }));

  const onUpcChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUpcFilter(e.currentTarget.value);
  };
  const onEpcChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEpcFilter(e.currentTarget.value);
  };
  const onModelChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setModelFilter(e.currentTarget.value);
  };

  const onDownloadClick = async (): Promise<void> => {
    if (store?.storeCode) {
      setDownloadLoading(true);
      const { csvFile, nameFile } = await StockSummaryService.stockStockOnHand({
        storeCode: store.storeCode,
      });

      const linkSource = `data:application/csv;base64,${csvFile}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = nameFile;
      downloadLink.click();
      setDownloadLoading(false);
    }
  };
  useEffect(() => {
    if (upcFilter.length > 0) {
      setUpcFilterIsValid(isValidUpc(upcFilter));
    } else {
      setUpcFilterIsValid(true);
    }
  }, [upcFilter]);

  useEffect(() => {
    if (epcFilter.length > 0) {
      setEpcFilterIsValid(isValidEpc(epcFilter));
    } else {
      setEpcFilterIsValid(true);
    }
  }, [epcFilter]);
  useEffect(() => {
    if (modelFilter.length > 0) {
      setModelFilterIsValid(isValidModel(modelFilter));
    } else {
      setModelFilterIsValid(true);
    }
  }, [modelFilter]);

  useEffect(() => {
    if (query) {
      setUpcFilter('');
      setEpcFilter('');
      setModelFilter('');
    }
  }, [query]);

  // useUpdateEffect(() => {
  //   const { brand, upcCode, epcCode, modelCode } = query;

  //   if (!brand || !upcCode || !epcCode || !modelCode) {
  //     setAlertSnackbarVisibility(false);
  //   }
  // }, [query]);

  // useUpdateEffect(() => {
  //   if (stockItemDetail?.length === 0 && !stockItemsIsLoading) {
  //     setAlertSnackbarVisibility(true);
  //   }
  // }, [stockItemDetail, stockItemsIsLoading]);

  const debounceFn = async (): Promise<void> => {
    if (upcFilter.length >= 4) {
      try {
        const { filterValueList: upcList } =
          await ElasticHandlerRestService.elastichandlerSearchFromUpcByFilterAutocomplete(
            { filterValue: upcFilter, filterType: 'upcCode' }
          );

        if (upcList) {
          if (upcList?.length > 0) {
            const list = upcList.map(({ filterValue }) => filterValue);

            return setUpcs(list);
          }
        }

        return setUpcs([]);
      } catch (e) {
        console.log('error', e);
      }
    }
  };

  const debounceFnEpc = async (): Promise<void> => {
    try {
      const { epcList } =
        await ElasticHandlerRestService.elastichandlerSearchEpcAutocomplete({
          epcCode: epcFilter,
        });

      setListEpcs(epcList?.map(({ epcCode }) => epcCode || '') || []);
    } catch (e) {
      console.error(e);
    }
  };

  const debounceFnModel = async (): Promise<void> => {
    try {
      const { filterValueList: modelList } =
        await ElasticHandlerRestService.elastichandlerSearchFromUpcByFilterAutocomplete(
          {
            filterType: 'modelCode',
            filterValue: modelFilter,
          }
        );

      setListModels(
        modelList?.map(({ filterValue }) => filterValue || '') || []
      );
    } catch (e) {
      console.error(e);
    }
  };

  const onAutoCompleteOptionSelectEpc = (epcCode: string | null): void => {
    if (query.brand) {
      history.push(
        `${AppRoutes.STOCK_SUMMARY_DETAILS}?brand=${encodeURIComponent(
          query.brand
        )}&epcCode=${epcCode}`
      );
    } else {
      history.push(`${AppRoutes.STOCK_SUMMARY_DETAILS}?epcCode=${epcCode}`);
    }
  };
  const onAutoCompleteOptionSelectModel = (modelCode: string | null): void => {
    if (query.brand) {
      history.push(
        `${AppRoutes.STOCK_SUMMARY_DETAILS}?brand=${encodeURIComponent(
          query.brand
        )}&modelCode=${modelCode}`
      );
    } else {
      history.push(`${AppRoutes.STOCK_SUMMARY_DETAILS}?modelCode=${modelCode}`);
    }
  };

  const onAutoCompleteOptionSelect = (upcCode: string | null): void => {
    if (query.brand) {
      history.push(
        `${AppRoutes.STOCK_SUMMARY_DETAILS}?brand=${encodeURIComponent(
          query.brand
        )}&upcCode=${upcCode}`
      );
    } else {
      history.push(`${AppRoutes.STOCK_SUMMARY_DETAILS}?upcCode=${upcCode}`);
    }
  };

  const onAutoCompleteFocus = (): void => {
    if (upcFilter.length === 0) {
      setUpcs([]);
    }
  };
  const onAutoCompleteFocusEpc = (): void => {
    if (epcFilter.length === 0) {
      setListEpcs([]);
    }
  };
  const onAutoCompleteFocusModel = (): void => {
    if (modelFilter.length === 0) {
      setListModels([]);
    }
  };

  useEffect(() => {
    if (query) {
      setUpcFilter('');
      setEpcFilter('');
      setModelFilter('');
    }
  }, [query]);

  useEffect(() => {
    if (brandFilter) {
      history.push(
        `${AppRoutes.STOCK_SUMMARY_DETAILS}?brand=${encodeURIComponent(
          brandFilter
        )}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandFilter]);

  useEffect(() => {
    onChangeBrandFilter(brandFilter ?? query.brand);
    onChangeCommodityFilter(commodityFilter ?? query.commodity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandFilter, commodityFilter]);

  return (
    <StyledFilterWrapper>
      <Autocomplete
        key="brand"
        onChange={(_, selected): void => {
          setBrandFilter(selected?.value || '');
        }}
        options={brandValues}
        getOptionLabel={(option): string => option.label || ''}
        getOptionSelected={(option): boolean =>
          option.label === (query.brand ?? brandFilter)
        }
        value={{
          label: query.brand!,
          value: query.brand!,
        }}
        renderInput={(params): JSX.Element => (
          <TextField
            {...params}
            label={t('stock.select_brand')}
            variant="outlined"
          />
        )}
      />
      <Autocomplete
        key="commodity"
        value={{
          label: commodityFilter ?? query.commodity,
          value: commodityFilter ?? query.commodity,
        }}
        onChange={(_, selected): void => {
          setCommodityFilter(
            selected?.value || query.commodity || t('stock.all')
          );
        }}
        options={[
          ...commodityValues(),
          { value: t('stock.all'), label: t('stock.all') },
        ]}
        getOptionLabel={(option): string => option.label}
        getOptionSelected={(option): boolean =>
          option.label === commodityFilter
        }
        renderInput={(params): JSX.Element => (
          <TextField
            {...params}
            label={t('stock.select_commodity')}
            variant="outlined"
          />
        )}
      />

      <UIAutocomplete
        disableClearable
        width={350}
        minSearchTermLength={4}
        label={t('stock.find_upc')}
        searchTerm={upcFilter}
        options={upcs}
        getOptions={debounceFn}
        onChange={onUpcChange}
        onFocus={onAutoCompleteFocus}
        onOptionSelect={(_e, upcCode): void =>
          onAutoCompleteOptionSelect(upcCode)
        }
        error={!upcFilterIsValid}
        loadingText={t('searching')}
        noOptionsText={t('error.no_results_available')}
        helperText={!upcFilterIsValid && t('error.invalidUpc')}
        prevValue={query.upcCode ? query.upcCode : ''}
        inputProps={{
          maxLength: 13,
          minLength: 5,
        }}
      />
      <UIAutocomplete
        disableClearable
        width={350}
        minSearchTermLength={4}
        label={t('searchByEpc')}
        searchTerm={epcFilter}
        options={listEpcs}
        getOptions={debounceFnEpc}
        onChange={onEpcChange}
        onFocus={onAutoCompleteFocusEpc}
        onOptionSelect={(_e, epcCode): void =>
          onAutoCompleteOptionSelectEpc(epcCode)
        }
        error={!epcFilterIsValid}
        loadingText={t('searching')}
        noOptionsText={t('error.no_results_available')}
        prevValue={query.epcCode ? query.epcCode : ''}
        helperText={!epcFilterIsValid && t('error.invalidEpc')}
        inputProps={{
          maxLength: 13,
          minLength: 5,
        }}
      />
      <UIAutocomplete
        disableClearable
        width={350}
        minSearchTermLength={4}
        label={t('cycleCount.filter.model')}
        searchTerm={modelFilter}
        options={listModels}
        getOptions={debounceFnModel}
        onChange={onModelChange}
        onFocus={onAutoCompleteFocusModel}
        onOptionSelect={(_e, modelCode): void =>
          onAutoCompleteOptionSelectModel(modelCode)
        }
        error={!modelFilterIsValid}
        loadingText={t('searching')}
        noOptionsText={t('error.no_results_available')}
        helperText={!modelFilterIsValid && t('error.invalidModel')}
        prevValue={query.modelCode ? query.modelCode : ''}
        inputProps={{
          maxLength: 13,
          minLength: 5,
        }}
      />
      {!query.brand && !query.upcCode && (
        <StyledDownloadWrapper>
          <UIButtonWithIcon
            label={t('soh.download.file')}
            startIcon={<GetAppIcon />}
            onClick={onDownloadClick}
            loading={downloadLoading}
          />
        </StyledDownloadWrapper>
      )}
    </StyledFilterWrapper>
  );
};
