export enum Roles {
	ALL = 'all',
	ADMIN = 'admin',
	OPERATOR = 'operator',
}

export type RoleFilter = string | 'all';
export type ProcessFilter = string | 'all';

export type UsersFilters = {
	userId: string;
	lastName: string;
	storeCode: string;
	role: RoleFilter;
	salesOrg?: string;
	jobTitle?: string;
};

export type StoreFilters = {
	sales: string;
	storeCode: string;
	role?: RoleFilter;
};

export type ReasonCodeFilters = {
	groupTitle: string;
	process: ProcessFilter;
	sales: string;
};

export type MovementTypeFilters = {
	typeCode: string;
	reasonCode: string;
};

export type ScannedProductFilters = {
	upcCode: string;
};
