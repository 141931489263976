import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { UIListHeader } from '@/components/ui/ListHeader';

import { Product } from '@/types/enum';
import type {
  CheckedEpcs,
  CheckedUpcs,
  ProductListHeaderProps,
  ProductListType,
} from '../types';
import { UIAction } from '@/components/ui/Action';
import { useCycleCountContext } from '@/context/cycleCount';
import { CycleDetailsItems } from '@/api';
import { StyledListHeader } from './style';
import useAddUnexpectedEpcs from '../../hooks/useAddUnexpectedEpcs';

const ProductListHeader: React.FC<ProductListHeaderProps & ProductListType> = ({
  type,
  disableActions,
}) => {
  const { t } = useTranslation();
  const {
    checkedNotFoundEpcs,
    checkedUnexpectedEpcs,
    setCheckedNotFoundUpcs,
    setCheckedNotFoundEpcs,
    setCheckedUnexpectedUpcs,
    setCheckedUnexpectedEpcs,
    setNotFoundModalReasonVisibility,
    setUnexpectedModalReasonVisibility,
  } = useCycleCountContext();

  const isSohProcess = useSelector(state => state.cycleCount.sohLocked);
  const addUnexpectedEpcsToFound = useAddUnexpectedEpcs();

  const { unexpected, notFound } = useSelector(
    state => state.cycleCount.products
  );

  const unexpectedCount = unexpected.reduce(
    (acc: number, b) => acc + Number(b.epcCodes.length),
    0
  );

  const notFoundCount = notFound.reduce(
    (acc: number, b) => acc + Number(b.epcCodes.length),
    0
  );

  const selectedNotFoundCount = Object.values(checkedNotFoundEpcs).flatMap(
    e => e
  ).length;

  const selectedUnexpectedCount = Object.values(checkedUnexpectedEpcs).flatMap(
    e => e
  ).length;

  const checkAll = (
    products: CycleDetailsItems[]
  ): {
    upcs: CheckedUpcs;
    epcs: CheckedEpcs;
  } => {
    let upcs: CheckedUpcs = {};
    let epcs: CheckedEpcs = {};

    products.forEach(product => {
      upcs = {
        ...upcs,
        [product.upcCode]: product,
      };

      epcs = {
        ...epcs,
        [product.upcCode]: product.epcCodes.map(({ epcCode }) => epcCode),
      };
    });

    return {
      upcs,
      epcs,
    };
  };

  const uncheckAllNotFound = (): void => {
    setCheckedNotFoundUpcs({});
    setCheckedNotFoundEpcs({});
  };

  const uncheckAllUnexpected = (): void => {
    setCheckedUnexpectedUpcs({});
    setCheckedUnexpectedEpcs({});
  };

  const checkAllNotFound = (): void => {
    setCheckedNotFoundUpcs(checkAll(notFound).upcs);
    setCheckedNotFoundEpcs(checkAll(notFound).epcs);
  };

  const checkAllUnexpected = (): void => {
    setCheckedUnexpectedUpcs(checkAll(unexpected).upcs);
    setCheckedUnexpectedEpcs(checkAll(unexpected).epcs);
  };

  const handleSelect = (): void => {
    switch (type) {
      case Product.NOT_FOUND:
        if (selectedNotFoundCount === notFoundCount) {
          uncheckAllNotFound();
        } else {
          checkAllNotFound();
        }
        break;

      case Product.UNEXPECTED:
        if (selectedUnexpectedCount === unexpectedCount) {
          uncheckAllUnexpected();
        } else {
          checkAllUnexpected();
        }
        break;

      default:
        return;
    }
  };

  // const createLinkText = (): string | undefined => {
  //   if (type === Product.UNEXPECTED) {
  //     return selectedUnexpectedCount === unexpectedCount
  //       ? t('deselectAll')
  //       : t('selectAll');
  //   }

  //   if (type === Product.NOT_FOUND && !isSohProcess) {
  //     return selectedNotFoundCount === notFoundCount
  //       ? t('deselectAll')
  //       : t('selectAll');
  //   }
  // };

  return (
    <StyledListHeader className={type}>
      <UIListHeader
        linkText={
          type === Product.UNEXPECTED
            ? selectedUnexpectedCount === unexpectedCount
              ? t('deselectAll')
              : t('selectAll')
            : type === Product.NOT_FOUND && !isSohProcess
            ? selectedNotFoundCount === notFoundCount
              ? t('deselectAll')
              : t('selectAll')
            : undefined
        }
        onLinkClick={handleSelect}
        disableLink={disableActions}
        text={
          type === Product.NOT_FOUND && isSohProcess
            ? undefined
            : t('item_selected', {
                selected:
                  type === Product.UNEXPECTED
                    ? selectedUnexpectedCount
                    : selectedNotFoundCount,
              })
        }
        rightComponent={
          type === Product.UNEXPECTED ? (
            <UIAction
              label={t('add')}
              icon={'add'}
              disabled={disableActions || selectedUnexpectedCount <= 1}
              onClick={(): void => {
                if (isSohProcess) {
                  addUnexpectedEpcsToFound();
                } else {
                  setUnexpectedModalReasonVisibility?.(true);
                }
              }}
            />
          ) : (
            <>
              {!isSohProcess && (
                <UIAction
                  label={t('reason')}
                  icon={'edit'}
                  disabled={disableActions || selectedNotFoundCount <= 1}
                  onClick={(): void => setNotFoundModalReasonVisibility?.(true)}
                />
              )}
            </>
          )
        }
      />
    </StyledListHeader>
  );
};

export default ProductListHeader;
