import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { Link } from 'react-router-dom';

import { UIMacroFlowMenu } from '@/components/layout/MacroFlowMenu';
import { UIButton } from '@/components/ui/Button';
import { isStoreDamagesCreate, isStoreDamagesValidate } from '@/utils/user';
import { AppRoutes } from '@/app/routers';
import { initDamagesReturnsState } from '@/features/storeDamages/storeDamagesSlice';
import { useInitApplication } from '@/hooks/useInitApplication';

const StyledLink = styled(Link)<{ disabled?: boolean }>`
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'auto')};
`;

const PageStoreDamagesIntro: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useSelector(state => state.user);

  useInitApplication();

  useLayoutEffect(() => {
    dispatch(initDamagesReturnsState());
  }, [dispatch]);

  return (
    <UIMacroFlowMenu title={t('menu.title')}>
      {isStoreDamagesCreate(user) && (
        <StyledLink to={AppRoutes.STORE_DAMAGES_CREATE}>
          <UIButton label={t('menu.createDamage')} outlined={false} />
        </StyledLink>
      )}
      {isStoreDamagesValidate(user) && (
        <StyledLink to={AppRoutes.STORE_DAMAGES_VALIDATE}>
          <UIButton label={t('menu.validateDamage')} outlined={false} />
        </StyledLink>
      )}
    </UIMacroFlowMenu>
  );
};

export default PageStoreDamagesIntro;
