/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Routes {
  _CACHE = '/cache',
  _CHECK_TAGS = '/check-tags',
  _CHECKOUT = '/checkout',
  _CONFIRM_STORE_TO_STORE = '/confirmStoreToStore',
  _CONFIRM_STORE_TO_STORE_ENCODED = '/confirmStoreToStoreEncoded',
  _CYCLE_COUNT = '/cycle-count',
  _FIND_STOCK_ITEMS = '/findStockItems',
  _FIND_STOCK_ITEMS_DETAIL = '/findStockItemsDetail',
  _FIND_STORE_TO_TRANSFER = '/findStoreToTransfer',
  _LOGIN = '/login',
  _LOGOUT = '/logout',
  _MIGRATION_STOCK = '/migrationStock',
  _POC_MIGRATION = '/poc-migration',
  _PRINT = '/print',
  _RECEIVE_SHIPMENT = '/receive-shipment',
  _SAP = '/sap',
  _SCAN_DEVICES = '/scan-devices',
  _SEARCH_ITEM_TO_TRANSFER = '/searchItemToTransfer',
  _SEARCH_STORE_BY_FILTER = '/searchStoreByFilter',
  _SETTINGS = '/settings',
  _STORE = '/store',
  _TRACE_LOG = '/trace-log',
}
