import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';
import { palette } from '@/theme';

export const StyledChip = styled(Chip)<{
  $activeBackground?: 'grey' | 'primary';
}>`
  margin-bottom: 12px;
  background: #005192;
  color: ${palette.colors.white};

  ${({ $activeBackground }): string =>
    `${
      $activeBackground === 'grey' &&
      `
            background: #888;

            &:focus {
            	background: rgba(136,136,136, 0.5)
            }
        `
    }
        ${
          $activeBackground === 'primary' &&
          `
            background: #005192;

            &:focus {
              background: rgba(0, 81, 146, 0.5);
            }
        `
        }
    `}
`;
