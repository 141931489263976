import styled, { css } from 'styled-components';
import { TablesHeader, TablesRow } from '@/components/layout/Tables';
import { theme } from '@/theme';

export const gridStyles = css`
  display: grid;
  gap: 24px;
  grid-template-columns: 200px 150px 250px 100px auto;

  ${theme.breakpoints.down('md')} {
    gap: 16px;
  }
`;

export const StyledTablesHeader = styled(TablesHeader)`
  ${gridStyles};
`;

export const StyledTablesRow = styled(TablesRow)`
  ${gridStyles};
  align-items: center;
`;
