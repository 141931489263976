import { FC, memo } from 'react';
import { Snackbar, SnackbarProps } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

export interface ErrorSnackbarProps extends SnackbarProps {
  setIsOpen: (open: boolean) => void;
  errorMessage: React.ReactNode;
}

export const ErrorSnackbar: FC<ErrorSnackbarProps> = memo(
  ({ setIsOpen, errorMessage, ...rest }: ErrorSnackbarProps): JSX.Element => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={10000}
        onClose={(): void => setIsOpen(false)}
        ClickAwayListenerProps={{
          onClickAway: (): void => {},
        }}
        {...rest}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    );
  }
);

ErrorSnackbar.displayName = 'ErrorSnackbar';

export default ErrorSnackbar;
