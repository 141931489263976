import { FC, memo } from 'react';
import { UILoader } from '@/components/ui/Loader';
import styled from 'styled-components';

export interface UILoaderProps {}

const PageOverlay = styled('div')`
  height: calc(100vh - 136px);
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageLoader: FC<UILoaderProps> = memo((): JSX.Element => {
  return (
    <PageOverlay className="page-overlay">
      <UILoader />
    </PageOverlay>
  );
});

PageLoader.displayName = 'PageLoader';

export default PageLoader;
