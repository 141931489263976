import styled from 'styled-components';

export const ListContainer = styled.div`
  margin-bottom: 120px;
`;

export const LIWrapper = styled.div`
  margin-top: 12px;
  background: #fbfbfb;
  border: 1px solid #ededed;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;
