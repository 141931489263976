import styled from 'styled-components';

export const StyledManageRolesContainer = styled.div`
  padding: 132px 20px;
  width: 100%;
`;
export const StyledListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-top: 16px;
`;

export const StyledLoaderWrapper = styled.div`
  margin: 28px 0;
`;
