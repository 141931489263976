import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ReceiveApiError as ApiError,
  CheckStatusMigration,
  MigrationService,
} from '@/api';
import { RootState } from '@/app/rootReducer';
import { PocSlice } from '@/types/pocSlice';

const initialState: PocSlice = {
  tags: [],
  migrationIsEnabled: false,
  migrationStatusIsLoading: false,
  migrationStatusHasError: undefined,
};

export const getMigrationStatus = createAsyncThunk<
  CheckStatusMigration | undefined,
  void,
  { rejectValue: ApiError; state: RootState }
>('getMigrationStatus', async (_, { rejectWithValue, getState }) => {
  const state = getState();
  const { store } = state.currentStore;

  if (store?.storeCode) {
    try {
      return await MigrationService.migrationCheckStatusMigration({
        storeCode: store.storeCode,
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
});

const pocSlice = createSlice({
  name: 'pocSlice',
  initialState,
  reducers: {
    addTags: (state, { payload }: PayloadAction<{ epc: string }[]>) => {
      const flatTags = payload.map(({ epc }): string => epc.toUpperCase());
      const concatTags = state.tags.concat(flatTags);

      state.tags = [...new Set(concatTags)];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getMigrationStatus.pending, state => {
        state.migrationStatusIsLoading = true;
      })
      .addCase(getMigrationStatus.fulfilled, (state, { payload }) => {
        state.migrationStatusIsLoading = false;

        if (payload) {
          state.migrationIsEnabled = payload.isEnabledMigration;
        }
      })
      .addCase(getMigrationStatus.rejected, (state, { payload }) => {
        state.migrationStatusIsLoading = false;
        state.migrationStatusHasError = payload as ApiError;
      });
  },
});

export const { addTags } = pocSlice.actions;
export default pocSlice.reducer;
