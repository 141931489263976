import React from 'react';
import { IColorDatas } from './types';
import {
  StyledColorBall,
  StyledColorDatasWrapper,
  StyledColorDataWrapper,
} from './style';

const ColorDatas: React.FC<IColorDatas> = ({ datas }) => (
  <StyledColorDatasWrapper>
    {datas.map(({ name, value, color }) => (
      <StyledColorDataWrapper key={name}>
        {name}: {value} <StyledColorBall $color={color} />
      </StyledColorDataWrapper>
    ))}
  </StyledColorDatasWrapper>
);

ColorDatas.displayName = 'ColorDatas';

export default ColorDatas;
