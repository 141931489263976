import React, { useState, useEffect } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useAsync } from 'react-use';
import { useAppDispatch } from '@/app/store';
import { fetchProcesses } from '@/features/profiling/reasonCodeSlice';

interface IReasonCodeContext {
  processes: string[];
}

const ReasonCodeContext = React.createContext<IReasonCodeContext>({
  processes: [],
});

export const ReasonCodeContextProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const { processes: stateProcesses } = useSelector(
    state => state.profiling.reasonCode
  );

  const [processes, setProcesses] = useState<string[]>([]);

  useAsync(async () => {
    await dispatch(fetchProcesses());
  }, [dispatch]);

  useEffect(() => {
    if (stateProcesses) {
      setProcesses(stateProcesses);
    }
  }, [stateProcesses]);

  return (
    <ReasonCodeContext.Provider
      value={{
        processes,
      }}
    >
      {children}
    </ReasonCodeContext.Provider>
  );
};

export const useReasonCodeContext = (): IReasonCodeContext =>
  React.useContext(ReasonCodeContext);
