import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@/components/ui/Typography';
import { UIAction } from '@/components/ui/Action';
import { ExceptionsListItemProps } from './types';
import * as S from '../style';
import { UICheckbox } from '@/components/ui/Checkbox';
import { UIAccordion } from '@/components/ui/Accordion';
import { UIBox } from '@/components/ui/Box';

const ExceptionsListItem: React.FC<ExceptionsListItemProps> = ({
  exception,
  withReason,
  selectedIds,
  onCheckboxClick,
  onEditClick,
}) => {
  const { t } = useTranslation();
  const hasError = exception.errorList && exception.errorList.length > 0;
  const [accordionExpanded, setAccordionExpanded] =
    React.useState<boolean>(false);

  const data = React.useMemo(
    () => ({
      storeCode: exception.storeCode || 'ND',
      upcCode: exception.upcCode || 'ND',
      tagCode: exception.tagCode || 'ND',
      transactionID: exception.transactionId || 'ND',
      dateOfTransaction: exception.dateOfTransaction || 'ND',
    }),
    [
      exception.storeCode,
      exception.upcCode,
      exception.tagCode,
      exception.transactionId,
      exception.dateOfTransaction,
    ]
  );

  const translations = {
    STORE_CODE: t('storeCode'),
    UPC_CODE: t('upcCode'),
    TAG_CODE: t('tagCode'),
    TRANSACTION_ID: t('transactionId'),
    TRANSACTION_DATE: t('dateOfTransaction'),
    REASON_CODE: t('reasonCode'),
  };

  return (
    <>
      <S.TableRow reasonCode={withReason}>
        <UICheckbox
          checked={selectedIds.includes(exception.id || '')}
          onClick={(): void => onCheckboxClick(exception.id || '')}
        />
        {hasError ? (
          <UIAccordion
            expanded={accordionExpanded}
            onClick={(): void => setAccordionExpanded(expanded => !expanded)}
          />
        ) : (
          <span></span>
        )}
        {Object.values(data).map(value => (
          <Typography>{value}</Typography>
        ))}
        {withReason && <Typography>{exception.reasonCode}</Typography>}
        <UIAction
          label={t('edit')}
          icon="edit"
          onClick={(): void => onEditClick(exception)}
        />
      </S.TableRow>
      {hasError && accordionExpanded && (
        <UIBox flexDirection="column" ml={2} p={2}>
          {exception.errorList.map(
            (error: keyof typeof translations, index: number) => (
              <React.Fragment key={`${error}-${index}`}>
                {error === 'REASON_CODE' ? (
                  <Typography margin="0 2px 0">
                    {t('reasonCodeNotAvailableInApp')}.{' '}
                  </Typography>
                ) : (
                  <UIBox mb={2} alignItems="center">
                    <Typography margin="0 2px 0">
                      {t('problemWith')}{' '}
                    </Typography>
                    <Typography margin="0 2px 0">
                      {translations[error]}.{' '}
                    </Typography>
                    <Typography>{t('pleaseVerifyData')}. </Typography>
                  </UIBox>
                )}
              </React.Fragment>
            )
          )}
        </UIBox>
      )}
    </>
  );
};

export default ExceptionsListItem;
