import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { UIBox } from '@/components/ui/Box';
import { UILoader } from '@/components/ui/Loader';
import { Typography } from '@/components/ui/Typography';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { ModalDataSavedError as Modal } from '@/components/layout/ModalDataSaved';
import { UIButton } from '@/components/ui/Button';
import { AppRoutes } from '@/app/routers';

import * as S from './style';
import { useAsync } from 'react-use';
import { StockOnHandAlignmentService } from '@/api/receive';

const PageSohAlignmentVerify: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [startSOH, setStartSOH] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataUnavailable, setDataUnavailable] = React.useState<boolean>(false);

  useAsync(async () => {
    try {
      setLoading(true);

      const response =
        await StockOnHandAlignmentService.stockonhandVerifyProductMasterData();

      setDataUnavailable(
        Array.isArray(response.pmdList) && response.pmdList.length > 0
      );

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const handleProceed = (): void => {
    sessionStorage.setItem('soha-status', 'NEW');
    history.push(AppRoutes.SOH_ALIGNMENT_FILTER_BRAND);
  };

  const handleBack = (): void => {
    if (dataUnavailable) {
      StockOnHandAlignmentService.stockonhandSendEmail();
    }

    history.push(AppRoutes.SOH_ALIGNMENT);
  };

  const createMessage = (
    title: string,
    message: string,
    icon: JSX.Element
  ): JSX.Element => (
    <>
      <UIBox width="48px" height="48px" mr={2}>
        {icon}
      </UIBox>
      <UIBox flexDirection="column">
        <Typography font="heavy">{title}</Typography>
        <Typography margin="12px 0">{message}</Typography>
      </UIBox>
    </>
  );

  return (
    <>
      <Modal
        $minWidth="550px"
        $minHeight="250px"
        open={startSOH}
        title=""
        message={t('sohActivitiesDisabled')}
        onClose={(): void => setStartSOH(false)}
      >
        <S.ButtonContainer>
          <UIButton
            outlined
            label={t('cancel')}
            onClick={(): void => setStartSOH(false)}
          />
          <UIButton outlined label={t('ok')} onClick={handleProceed} />
        </S.ButtonContainer>
      </Modal>
      <UIBox width="100%" mt={10} alignItems="center">
        <UIBox maxWidth="640px" ml="auto" mr="auto" alignItems="center">
          {loading ? (
            <>
              {createMessage(
                t('verifying'),
                t('verifyingUPCsMasterData'),
                <UILoader />
              )}
            </>
          ) : (
            <>
              {dataUnavailable
                ? createMessage(
                    t('payAttention'),
                    t('upcsUnavailableMasterData'),
                    <S.ErrorIcon />
                  )
                : createMessage(
                    t('verified'),
                    t('upcsAvailableMasterData'),
                    <S.CheckIcon />
                  )}
            </>
          )}
        </UIBox>
      </UIBox>

      <CTAContainer
        type="TWO_BUTTONS"
        mainButtonLabel={t('proceed')}
        disabledMainAction={dataUnavailable || loading}
        onBackClick={handleBack}
        onClick={(): void => setStartSOH(true)}
      />
    </>
  );
};

export default PageSohAlignmentVerify;
