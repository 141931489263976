import styled from 'styled-components';

export const StyledDiv = styled('div')`
  display: flex;
  margin: 0 0 100px;
  width: 100%;
  flex-direction: column;
  padding: 0 24px;
`;
export const SheetContainer = styled.div`
  padding-top: 28px;
`;
