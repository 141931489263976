import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { UIModal } from '@/components/ui/Modal';
import { Typography } from '@/components/ui/Typography';

import {
  StyledWrapper,
  StyledCloseIcon,
  StyledConfirmModalContent,
  StyledConfirmModalTextWrapper,
  StyledTitleContainer,
  SyledButtonContainer,
} from './style';

import { ModalDataSavedProps } from './types';
import { UIButton } from '@/components/ui/Button';
import { ProfilingService } from '@/api/receive';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';

export const ModalDeleteRole: FC<ModalDataSavedProps> = memo(
  ({ onClose, open, role, exportResult }): JSX.Element => {
    const { t } = useTranslation();
    const [errorDelete, setErrorDelete] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

    const closeAction = async (deleted: boolean): Promise<void> => {
      try {
        if (deleted) {
          setDeleteLoading(true);
          await ProfilingService.userDeleteRole({ roleDescription: role });
          setDeleteLoading(false);
        }
        exportResult?.({ role: role!, deleted });
        onClose();
      } catch {
        setDeleteLoading(false);
        setErrorDelete(true);
      }
    };

    const deleteAction = async (): Promise<void> => {
      closeAction(true);
    };

    return (
      <>
        <UIModal
          open={open}
          onClose={(): Promise<void> => closeAction(false)}
          $minHeight="unset"
        >
          <StyledWrapper>
            <StyledTitleContainer>
              <Typography size="lg" font="heavy" as="h1">
                {t('roleList.removeModal.title')}
              </Typography>

              <StyledCloseIcon
                onClick={(): Promise<void> => closeAction(false)}
              >
                <HighlightOffIcon />
              </StyledCloseIcon>
            </StyledTitleContainer>

            <StyledConfirmModalContent>
              <StyledConfirmModalTextWrapper>
                <Typography>
                  {t('roleList.removeModal.description.0')}
                </Typography>

                <Typography>
                  {t('roleList.removeModal.description.1')}
                </Typography>
              </StyledConfirmModalTextWrapper>
            </StyledConfirmModalContent>
            <SyledButtonContainer>
              <UIButton
                label={t('no')}
                outlined
                onClick={(): Promise<void> => closeAction(false)}
              />
              <UIButton
                label={t('yes')}
                outlined
                loading={deleteLoading}
                disabled={deleteLoading}
                onClick={deleteAction}
              />
            </SyledButtonContainer>
          </StyledWrapper>
        </UIModal>
        <ErrorSnackbar
          open={errorDelete}
          setIsOpen={setErrorDelete}
          errorMessage={t('error.deleterole')}
        />
      </>
    );
  }
);

ModalDeleteRole.displayName = 'ModalDeleteRole';

export default ModalDeleteRole;
