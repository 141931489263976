import React from 'react';
import { ProductImage } from '@/components/layout/ProductImage';
import { StyledContentInfo, StyledDescription } from '../../listItemStyle';
import { Typography } from '@/components/ui/Typography';
import { UIBox } from '@/components/ui/Box';
import { Grid } from '../style';
import { Wrapper } from './style';

import { ReportUpcs } from '@/api/receive';

const ProductListItem: React.FC<
  ReportUpcs & { report: string; nullValues?: boolean }
> = ({ report, nullValues = false, ...product }) => {
  return (
    <Wrapper>
      <Grid report={report} mt="0">
        <StyledContentInfo>
          {product.upcCode && (
            <ProductImage
              imageUrl={product.imageUrl}
              colorCode={product.colorCode}
              modelCode={product.modelCode}
              brandCode={product.brandCode}
              onClick={(): void => {}}
            />
          )}
          <UIBox flexDirection="column">
            <StyledDescription>
              {product.upcCode ? (
                <>
                  <Typography size="lg" font="heavy" as="span">
                    {product.brandCode} - {product.brandDescription} -{' '}
                    {product.styleDescription || 'ND'}
                  </Typography>
                  <Typography
                    margin="8px 0 0 0"
                    size="md"
                    font="book"
                    as="span"
                  >
                    {product.upcCode} - {product.modelCode || 'ND'} -{' '}
                    {product.commodityDescription || 'ND'}
                  </Typography>
                </>
              ) : (
                '-'
              )}
            </StyledDescription>
          </UIBox>
        </StyledContentInfo>
        <Typography margin="0 0 0 25px" size="lg">
          {report === 'APP'
            ? product.preQuantityApp || 0
            : nullValues
            ? '-'
            : product.preQuantitySap || 0}
        </Typography>
        <Typography margin="0 0 0 25px" size="lg" color="green">
          {report === 'APP'
            ? product.postQuantityApp || 0
            : nullValues
            ? '-'
            : product.postQuantitySap || 0}
        </Typography>
        {report === 'SAP' && (
          <Typography margin="0 0 0 25px" size="lg" color="yellow">
            {nullValues ? '-' : product.adjustment || 0}
          </Typography>
        )}
      </Grid>
    </Wrapper>
  );
};

export default ProductListItem;
