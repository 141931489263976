import { useSelector } from '@/hooks/useSelector';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { DateTime, Interval } from 'luxon';
import useSROTrackEvent from '@/hooks/useSROTrackEvent';

type TrackConfirmReceiving = {
  User: string;
  StoreCode: string;
  TimeNeeded: string;
  DeliveryNumber: string;
  ExpectedItems: number;
  FoundItems: number;
  MissingItems: number;
  Sold: number;
  ExcessItems: number;
  ScanNeeded: number;
  InvalidEpcItems: number;
};

type ReturnHookType = (
  startTimestamp: DateTime,
  confirmTimeStamp: DateTime
) => Promise<void>;

const useTrackConfirm = (scanAgainCounter: number): ReturnHookType => {
  const appInsights = useAppInsightsContext();
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const { username } = useSelector(state => state.user);
  const { selectedDeliveries } = useSelector(state => state.deliveries);
  const selectedDeliveriesEpcQuantity = selectedDeliveries.reduce(
    (prev, curr) => prev + Number(curr.numberItems),
    0
  );

  const {
    unexpectedAdded,
    products: {
      missing: { qty: missingQty },
      found: { qty: foundQty },
      sold: { qty: soldQty },
      invalidProduct,
    },
  } = useSelector(state => state.scannedProducts);

  const data: TrackConfirmReceiving = {
    User: username || '',
    StoreCode: storeCode || '',
    TimeNeeded: '',

    DeliveryNumber: selectedDeliveries
      .map(({ codeDelivery }) => codeDelivery)
      .join(', '),
    ExpectedItems: selectedDeliveriesEpcQuantity,
    FoundItems: foundQty || 0,
    MissingItems: missingQty || 0,
    Sold: soldQty || 0,
    ExcessItems: unexpectedAdded,
    ScanNeeded: scanAgainCounter,
    InvalidEpcItems: invalidProduct.length,
  };

  const trackEvent = useSROTrackEvent<TrackConfirmReceiving>(
    appInsights,
    'Confirm Delivery',
    data
  );

  const trackConfirm = async (
    startTimestamp: DateTime,
    confirmTimestamp: DateTime
  ): Promise<void> => {
    if (startTimestamp && confirmTimestamp) {
      const interval = Interval.fromDateTimes(startTimestamp, confirmTimestamp)
        .toDuration(['minutes', 'seconds'])
        .toObject();

      const seconds =
        interval.seconds! <= 9
          ? `0${interval.seconds?.toFixed()}`
          : interval.seconds?.toFixed();

      await trackEvent({
        ...data,
        TimeNeeded: `${interval.minutes?.toFixed()}:${seconds}`,
      });
    }
  };

  return trackConfirm;
};

export default useTrackConfirm;
