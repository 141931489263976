import { FC } from 'react';
import styled from 'styled-components';
import { UIModal } from '@/components/ui/Modal';
import { UIBox } from '@/components/ui/Box';
import { UILoader } from '@/components/ui/Loader';
import { Typography } from '@/components/ui/Typography';
import { useTranslation } from 'react-i18next';

export interface ModalPrintDetailsProps {
  open: boolean;
}

const StyledModal = styled(UIModal)`
  &&& {
    width: 400px;
    height: 160px;
    margin: auto;
    border-radius: 10px;
  }
`;

const StyledContent = styled(UIBox)`
  margin-left: 22px;
  flex-direction: column;

  h1 {
    text-transform: capitalize;
  }
`;

const ModalLoading: FC<ModalPrintDetailsProps> = ({ open }) => {
  const { t } = useTranslation();

  return (
    <StyledModal open={open} $minWidth="400px" $minHeight="160px">
      <UIBox margin="auto" alignItems="center">
        <UILoader />
        <StyledContent>
          <Typography as="h1" font="heavy" size="lg" margin="4px 0">
            {t('printing')}...
          </Typography>
          <Typography>{t('tagIsPrinting')}</Typography>
        </StyledContent>
      </UIBox>
    </StyledModal>
  );
};

export default ModalLoading;
