import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ReceiveShipmentsService } from '@/api';

import type { RootState } from '@/app/rootReducer';
import type {
  ReceiveApiError as ApiError,
  DeliveryItems,
  DeliveryList,
} from '@/api';

export interface DeliveryListSlice {
  deliveryItems: DeliveryItems[];
  selectedDeliveries: DeliveryItems[];
  isLoading: boolean;
  error?: ApiError;
}

const initialState: DeliveryListSlice = {
  deliveryItems: [],
  selectedDeliveries: [],
  isLoading: false,
  error: undefined,
};

export const getDeliveryList = createAsyncThunk<
  DeliveryList | undefined,
  void,
  { state: RootState; rejectValue: ApiError }
>('deliveries/getDeliveryList', async (_, { getState, rejectWithValue }) => {
  const { currentStore } = getState();

  if (currentStore.store?.storeCode) {
    const { storeCode } = currentStore.store;

    if (storeCode) {
      try {
        return await ReceiveShipmentsService.receiveshipGetDeliveryList({
          storeId: storeCode,
        });
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  }
});

export const unlockDeliveries = createAsyncThunk<
  void,
  void,
  { rejectValue: ApiError; state: RootState }
>('deliveries/unlockDeliveries', async (_, { getState, rejectWithValue }) => {
  const {
    deliveries: { selectedDeliveries },
  } = getState();

  let selectedDeliveriesCodes = selectedDeliveries.map(
    ({ codeDelivery }) => codeDelivery
  );

  const deliveriesInStorage = sessionStorage.getItem('selectedDeliveries');

  if (deliveriesInStorage) {
    selectedDeliveriesCodes = (
      JSON.parse(deliveriesInStorage) as DeliveryItems[]
    ).map(({ codeDelivery }) => codeDelivery);
  }

  if (selectedDeliveriesCodes.length > 0) {
    try {
      return await ReceiveShipmentsService.receiveshipChangeDeliveryStatus({
        requestBody: {
          deliveryNumbers: selectedDeliveriesCodes,
          status: 'New',
        },
      });
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  }
});

export const unlockSpecificDeliveries = createAsyncThunk<
  void,
  {
    deliveryCodes: string[];
  },
  { rejectValue: ApiError; state: RootState }
>(
  'deliveries/unlockSpecificDeliveries',
  async ({ deliveryCodes }, { rejectWithValue }) => {
    try {
      return await ReceiveShipmentsService.receiveshipChangeDeliveryStatus({
        requestBody: {
          deliveryNumbers: deliveryCodes,
          status: 'New',
        },
      });
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  }
);

const deliveriesSlice = createSlice({
  name: 'deliveries',
  initialState,
  reducers: {
    selectDelivery: (
      state: DeliveryListSlice,
      { payload }: PayloadAction<DeliveryItems>
    ) => {
      sessionStorage.setItem(
        'selectedDeliveries',
        JSON.stringify([...state.selectedDeliveries, payload])
      );

      state.selectedDeliveries.push(payload);
    },
    deselectDelivery: (
      state: DeliveryListSlice,
      action: PayloadAction<DeliveryItems>
    ) => {
      const index = state.selectedDeliveries.findIndex(
        delivery => delivery.codeDelivery === action.payload.codeDelivery
      );

      if (index !== -1) {
        state.selectedDeliveries.splice(index || 0, 1);

        sessionStorage.setItem(
          'selectedDeliveries',
          JSON.stringify(state.selectedDeliveries)
        );
      }
    },
    initDeliveryList: () => {
      sessionStorage.removeItem('selectedDeliveries');
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getDeliveryList.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getDeliveryList.fulfilled, (state, { payload }) => {
        if (payload?.deliveryItems) {
          state.deliveryItems = payload.deliveryItems;
        }

        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(getDeliveryList.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
});

export const { selectDelivery, deselectDelivery, initDeliveryList } =
  deliveriesSlice.actions;
export default deliveriesSlice.reducer;
