import { FC, memo, useState } from 'react';
import { IModalSetTimezone } from './types';
import { UIModal } from '@/components/ui/Modal';
import { useDebounceLimit } from '@/utils/hooks';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';
import {
  CloseIcon,
  StyledBtn,
  StyledFooter,
  StyledMainWrapper,
  StyledModalWrapper,
  StyledRow,
  StyledTitleWrapper,
  StyledTopSpace,
  StyledTzContainer,
} from './style';
import { Typography } from '../Typography';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { ElasticHandlerRestService } from '@/api/receive';

export const ModalSetTimezone: FC<IModalSetTimezone> = memo(
  ({ open, setOpen, setCurTimezone, curTimezone }): JSX.Element => {
    const [searchedTz, setSearchedTz] = useState<string>('');
    const [foundTz, setFoundTz] = useState<string[]>([]);
    const [selectedTz, setSelectedTz] = useState<string>(curTimezone);

    const { t } = useTranslation();

    const debounceFn = async (): Promise<void> => {
      try {
        const { filterValueList } =
          await ElasticHandlerRestService.elastichandlerSearchDictionaryAutoComplete(
            { filterScope: 'timezone', filterValue: searchedTz }
          );

        if (filterValueList) {
          setFoundTz(filterValueList.map(({ filterValue }) => filterValue));
        }
      } catch (e) {
        console.error(e);
      }
    };

    const resetFoundTz = (): void => setFoundTz([]);

    useDebounceLimit([debounceFn, resetFoundTz], searchedTz, undefined, 3);

    const closeHandler = (): void => {
      resetFoundTz();
      setSelectedTz('');
      setOpen(false);
    };

    const confirmClickHandler = (): void => {
      setCurTimezone?.({ value: selectedTz, label: selectedTz });
      resetFoundTz();
      setOpen(false);
    };

    const deleteSelected = (): void => {
      if (selectedTz !== curTimezone) {
        setSelectedTz(curTimezone);
      }
    };

    return (
      <UIModal open={open} onClose={closeHandler} $minHeight="">
        <StyledModalWrapper>
          <StyledTitleWrapper>
            <Typography font="heavy">{t('modalSetTimezone.title')}</Typography>

            <CloseIcon color={'primary'} onClick={closeHandler} />
          </StyledTitleWrapper>

          <StyledMainWrapper>
            <StyledTopSpace>
              <SearchBarLight
                label=""
                placeholder={t('modalSetTimezone.searchTimezone')}
                disabled={false}
                onSearch={(): void => {}}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  setSearchedTz(e.target.value)
                }
                loading={false}
                hideButton={true}
                fullWidth
              />
            </StyledTopSpace>
            <StyledTzContainer>
              <div>
                {selectedTz && (
                  <StyledRow $selected onClick={deleteSelected}>
                    <Typography>{selectedTz}</Typography>
                    {selectedTz !== curTimezone && <DeleteIcon color="error" />}
                  </StyledRow>
                )}

                {foundTz.map(tz => {
                  const isSelected = tz === selectedTz;

                  const selectedMap = (): void => {
                    if (!isSelected) {
                      setSelectedTz(tz);
                    }
                  };

                  if (isSelected) {
                    return null;
                  }

                  return (
                    <StyledRow
                      onClick={(): void => selectedMap()}
                      $selected={isSelected}
                    >
                      <Typography> {tz} </Typography>
                      <CheckIcon color="primary" />
                    </StyledRow>
                  );
                })}
              </div>
            </StyledTzContainer>
          </StyledMainWrapper>

          <StyledFooter>
            <StyledBtn
              label={t('modalSetTimezone.btn.cancel')}
              onClick={closeHandler}
              outlined
            />

            <StyledBtn
              label={t('modalSetTimezone.btn.ok')}
              disabled={curTimezone === '' && selectedTz === ''}
              onClick={confirmClickHandler}
            />
          </StyledFooter>
        </StyledModalWrapper>
      </UIModal>
    );
  }
);

ModalSetTimezone.displayName = 'ModalSetTimezone';

export default ModalSetTimezone;
