import {
  AppStatusResponse,
  initialState as initialMaintenanceState,
} from '@/features/maintenance/maintenanceSlice';

/**
 * If it need to be tested in local environment add in
 * "packages/sro-ui/package.json"
 *
 * the followed line:
 * "proxy": "https://courtesypage-fapp.azurewebsites.net/api/"
 *
 * and change the URL variable in:
 * "StatusApp?code=McTO3RGRNkQMB1Hip7CiB4l7uUJf53ZqCyAjcPGFhC-YAzFuCIsWxQ=="
 */
export const getAppStatus = async (): Promise<AppStatusResponse> => {
  try {
    const pollingDisabled =
      process.env.REACT_APP_DISABLE_MAINTENANCE_POLLING === 'true';

    if (!pollingDisabled) {
      const URL = process.env.REACT_APP_MAINTENANCE_URL;

      if (URL) {
        const maintenanceEnv = process.env.REACT_APP_MAINTENANCE_ENV;

        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        const result = await response.json();

        if (result.env === maintenanceEnv) {
          return result;
        }

        return initialMaintenanceState;
      }
    }
  } catch (e) {
    console.error(e);
    return initialMaintenanceState;
  }

  return initialMaintenanceState;
};
