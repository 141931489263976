import {
  addNotFoundPrinted,
  addNotFoundPrintedToFound,
  removeEpcPrintedFromNotFound,
} from '@/features/cycleCount/cycleCountSlice';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { PrintReasonCodeStatus } from '@/types/enum';
import { RootState } from '@/app/rootReducer';

type Dispatch = ThunkDispatch<RootState, unknown, AnyAction>;

type CycleCountActionsBody = {
  upcCode: string;
  printedEPC: string;
  oldEpc: string;
  reasonCode: PrintReasonCodeStatus;
};

export namespace CycleCountRedux {
  export const update = (
    dispatch: Dispatch,
    { upcCode, printedEPC, oldEpc, reasonCode }: CycleCountActionsBody
  ): void => {
    dispatch(
      addNotFoundPrintedToFound({
        upc: upcCode,
        oldEpc,
        newEpc: printedEPC,
      })
    );

    dispatch(
      removeEpcPrintedFromNotFound({
        oldEpc,
        upc: upcCode,
      })
    );

    if (reasonCode !== 'Printed' && oldEpc) {
      dispatch(
        addNotFoundPrinted({
          notFoundProduct: {
            epcFound: [
              {
                epcFoundCodeNew: printedEPC.toUpperCase(),
                epcFoundCodeOld: oldEpc,
                reasonEpcFound:
                  reasonCode === 'Damaged' ? 'Tag Damaged' : reasonCode,
              },
            ],
            upcCodeFound: upcCode,
            epcFoundQuantity: 1,
          },
          epc: oldEpc,
          upc: upcCode,
        })
      );
    }
  };
}
