import React, { useEffect, useState } from 'react';
import { MQTTContext } from '@/context/MQTT';

import { Epc, SubscribeEPC } from './types';
import { useLocation } from 'react-router';
import { useAppDispatch } from '@/app/store';
import { tagsMQTTDevice } from '@/features/devices/devicesSlice';

export const parseEpcs = (payload: string): { epc: Epc }[] => {
  const epcs = payload.split(';');
  return epcs.map(epc => ({ epc: epc }));
};

export const useSubscribeEpcs = (deviceId: string = ''): SubscribeEPC => {
  const dispatch = useAppDispatch();
  const { client, newMessage } = React.useContext(MQTTContext);
  const { pathname } = useLocation();
  const [topic, _setTopic] = useState<string>('');

  useEffect(() => {
    if (deviceId) {
      console.log('set topic on', deviceId);
      _setTopic(`L/${deviceId}/T/EPC`);
    }
  }, [deviceId]);

  useEffect(() => {
    if (client && topic) {
      console.log(topic);
      client.subscribe(topic, { qos: 1, rap: true }, error => {
        if (error) {
          // dispatch(dataActions.addError(error.message))
          console.log(error);
        }
      });
    }
  }, [client, topic]);

  useEffect(() => {
    if (newMessage?.topic === topic) {
      const tags = parseEpcs(newMessage.payload);
      dispatch(tagsMQTTDevice(tags));
    }
  }, [topic, newMessage, pathname, dispatch]);

  const setTopic = (_deviceId: string): void => {
    if (deviceId) {
      _setTopic(`L/${deviceId}/T/EPC`);
    }
  };

  const publish = (message: string): void => {
    client && client.publish(topic, message, { retain: true });
  };

  const unsubscribe = (): void => {
    client && client.unsubscribe(topic);
  };

  const subscribe = (): void => {
    if (client && topic) {
      client.subscribe(topic, { qos: 1, rap: true }, error => {
        if (error) {
          // dispatch(dataActions.addError(error.message))
          console.log(error);
        }
      });
    }
  };

  return {
    publish: publish,
    unsubscribe: unsubscribe,
    subscribe: subscribe,
    setTopic: setTopic,
  };
};
