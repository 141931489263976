import React, { FC } from 'react';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  ModalProps,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import styled from 'styled-components';

import { UIModal } from '@/components/ui/Modal';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { useSelector } from '@/hooks/useSelector';
import { UIButton } from '@/components/ui/Button';
import { useTranslation } from 'react-i18next';

export interface ModalSelectPrintersProps
  extends Omit<ModalProps, 'onClose' | 'children'> {
  onClose: () => void;
  selectedPrinter?: string;
  setSelectedPrinter: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPrintStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

//#region - Styled Components
export const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const StyledCloseIcon = styled(IconButton)`
  align-self: flex-end;
  position: absolute !important;
  z-index: 1;

  svg {
    fill: #005192;
  }
`;

export const StyledFormControl = styled(FormControl)`
  height: 100%;
  padding: 32px 8px;
  margin-top: 8px;
`;

export const StyledControlLabel = styled(FormControlLabel)`
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }

  svg {
    fill: #005192;
  }
`;
//#endregion

const ModalSelectPrinters: FC<ModalSelectPrintersProps> = ({
  open,
  onClose,
  setPrintStatus,
  selectedPrinter,
  setSelectedPrinter,
}) => {
  const { t } = useTranslation();
  const { store } = useSelector(state => state.currentStore);

  const onPrintClick = (): void => {
    setPrintStatus(true);
    onClose();
  };

  return (
    <UIModal $minWidth="450px" $minHeight="auto" open={open} onClose={onClose}>
      <StyledWrapper>
        <StyledCloseIcon onClick={onClose}>
          <HighlightOffIcon />
        </StyledCloseIcon>
        <StyledFormControl>
          <Typography size="lg" font="heavy" as="h1">
            {t('selectprint')}
          </Typography>
          <RadioGroup>
            {store &&
              store.printers?.map(({ printerName, printerCode }, index) => (
                <React.Fragment key={`${printerCode}-${index}`}>
                  <StyledControlLabel
                    control={<Radio />}
                    onChange={(): void => setSelectedPrinter(printerCode)}
                    value={printerCode}
                    label={printerName}
                  />
                </React.Fragment>
              ))}
          </RadioGroup>
          <UIBox alignItems="center" justifyContent="center" marginTop="32px">
            <UIButton
              label={t('print')}
              onClick={onPrintClick}
              disabled={!selectedPrinter}
            />
          </UIBox>
        </StyledFormControl>
      </StyledWrapper>
    </UIModal>
  );
};

export default ModalSelectPrinters;
