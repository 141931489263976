import React from 'react';
import { Typography } from '@/components/ui/Typography';
import { useTranslation } from 'react-i18next';

import * as S from '../style';
import { UIBox } from '@/components/ui/Box';
import { ExceptionsListProps } from './types';
import ExceptionsListItem from '../ExceptionsListItem';

const ExceptionsList: React.FC<ExceptionsListProps> = ({
  exceptions,
  selectedIds,
  withReason,
  onCheckboxClick,
  onEditClick,
}) => {
  const { t } = useTranslation();

  return (
    <UIBox width="100%" flexDirection="column" mt={3} mb="150px">
      <S.TableHeader reasonCode={withReason}>
        <span></span>
        <span></span>
        <Typography font="heavy">{t('storeCode')}</Typography>
        <Typography font="heavy">{t('upcCode')}</Typography>
        <Typography font="heavy">{t('tagCode')}</Typography>
        <Typography font="heavy">{t('transactionID')}</Typography>
        <Typography font="heavy">{t('dateOfTransaction')}</Typography>
        {withReason ? (
          <Typography font="heavy">{t('reasonCode')}</Typography>
        ) : (
          <span></span>
        )}
      </S.TableHeader>
      {exceptions.map(exception => (
        <ExceptionsListItem
          key={exception.id}
          withReason={withReason}
          selectedIds={selectedIds}
          exception={exception}
          onCheckboxClick={onCheckboxClick}
          onEditClick={onEditClick}
        />
      ))}
    </UIBox>
  );
};

export default ExceptionsList;
