import { FC, memo } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { useTranslation } from 'react-i18next';

export interface CounterProps {
  sticky: boolean;
  foundItemsCount: number;
  notFoundItemsCount: number;
  unexpectedItemsCount: number;
}

export interface CounterItemProps {
  $found?: boolean;
  $notFound?: boolean;
  $unexpected?: boolean;
  $sticky?: boolean;
}

const StyledCounter = styled(UIBox)<{ $sticky?: boolean }>`
  ${({ $sticky }): false | FlattenSimpleInterpolation | undefined =>
    $sticky &&
    css`
      && {
        padding: 0;

        h1,
        span {
          color: #fff;
        }
      }
    `}
`;

const StyledCounterItem = styled(UIBox)<CounterItemProps>`
  flex-direction: column;
  align-items: center;
  padding: 14px 24px;

  ${({ $sticky }): false | FlattenSimpleInterpolation | undefined =>
    $sticky &&
    css`
      flex-direction: row;
      height: 100%;

      && {
        padding: 12px 13px;

        h1 {
          margin: 0 4px 0 8px;
        }

        &::after {
          width: 12px;
          height: 12px;
          border: 1px solid #fff;
        }
      }
    `}

  &:not(:last-of-type) {
    border-right: 1px solid #ededed;
  }

  h1 {
    margin: 8px 0;
  }

  &::after {
    content: '';
    display: block;
    width: 32px;
    height: 8px;
    border-radius: 24px;
    ${({ $found }): false | FlattenSimpleInterpolation | undefined =>
      $found &&
      css`
        background: #417505;
      `};
    ${({ $notFound }): false | FlattenSimpleInterpolation | undefined =>
      $notFound &&
      css`
        background: #e20404;
      `};
    ${({ $unexpected }): false | FlattenSimpleInterpolation | undefined =>
      $unexpected &&
      css`
        background: #f6bb3b;
      `};
  }
`;

export const Counter: FC<Partial<CounterProps>> = memo(
  ({
    sticky,
    foundItemsCount,
    notFoundItemsCount,
    unexpectedItemsCount,
  }): JSX.Element => {
    const { t } = useTranslation();

    return (
      <StyledCounter>
        <StyledCounterItem $found $sticky={sticky}>
          <Typography
            size={sticky ? `xs` : 'sm'}
            font="heavy"
            color={sticky ? `white` : 'grey'}
          >
            {t('found')}
          </Typography>
          <Typography
            size="xl"
            font="heavy"
            as="h1"
            color={sticky ? `white` : 'text'}
          >
            {foundItemsCount}
          </Typography>
        </StyledCounterItem>
        <StyledCounterItem $notFound $sticky={sticky}>
          <Typography
            size={sticky ? `xs` : 'sm'}
            font="heavy"
            color={sticky ? `white` : 'grey'}
          >
            {t('notFound')}
          </Typography>
          <Typography
            size="xl"
            font="heavy"
            as="h1"
            color={sticky ? `white` : 'text'}
          >
            {notFoundItemsCount}
          </Typography>
        </StyledCounterItem>
        <StyledCounterItem $unexpected $sticky={sticky}>
          <Typography
            size={sticky ? `xs` : 'sm'}
            font="heavy"
            color={sticky ? `white` : 'grey'}
          >
            {t('unexpected')}
          </Typography>
          <Typography
            size="xl"
            font="heavy"
            as="h1"
            color={sticky ? `white` : 'text'}
          >
            {unexpectedItemsCount}
          </Typography>
        </StyledCounterItem>
      </StyledCounter>
    );
  }
);

Counter.displayName = 'Counter';

export default Counter;
