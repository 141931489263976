import styled from 'styled-components';

import { IconButton } from '@material-ui/core';

import { UIBox } from '@/components/ui/Box';

import { palette } from '@/theme';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px;
  flex-direction: column;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledCloseIcon = styled(IconButton)`
  align-self: baseline;

  svg {
    fill: ${palette.colors.primary};
  }
`;

export const StyledConfirmModalContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledConfirmModalTextWrapper = styled(UIBox)`
  flex-direction: column;
`;

export const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  margin-right: 16px;
  height: 36px;
  width: 36px;
`;

export const SyledButtonContainer = styled(UIBox)`
  align-items: center;
  gap: 43px;
  width: 60%;
  align-self: flex-end;
  margin-top: 24px;
`;
