import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MaintenanceIcon } from '@/assets/icons/maintenance.svg';

import { StyledCard } from './style';
import { Typography } from '@/components/ui/Typography';
import { useSelector } from '@/hooks/useSelector';

const MaintenanceCard: React.FC = () => {
  const { t } = useTranslation();
  const { dataStart, dataEnd } = useSelector(state => state.maintenance);

  return (
    <StyledCard>
      <MaintenanceIcon />
      <Typography font="heavy" size="xl" as="h3">
        {t('underMaintenance')}
      </Typography>
      {dataStart && dataEnd && (
        <Typography as="p">
          {t('applicationUnavailable', {
            dataStart,
            dataEnd,
          })}
        </Typography>
      )}
    </StyledCard>
  );
};

export default MaintenanceCard;
