import React from 'react';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import styled from 'styled-components';

interface UICheckboxProps extends CheckboxProps {
  className?: string;
}

const StyledCheckbox = styled(Checkbox)<{ disabled?: boolean }>`
  width: fit-content;

  svg {
    fill: ${({ disabled }): string => (disabled ? 'grey' : '#005192')};
  }
`;

const UICheckbox: React.FC<UICheckboxProps> = ({ className, ...props }) => {
  return <StyledCheckbox {...props} className={className} />;
};

export default UICheckbox;
