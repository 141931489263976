/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EpcAutoCompleteList } from '../models/EpcAutoCompleteList';
import type { EpcElasticList } from '../models/EpcElasticList';
import type { FilterValueElasticList } from '../models/FilterValueElasticList';
import type { SendUpcToElasticByUpcCodesRequest } from '../models/SendUpcToElasticByUpcCodesRequest';
import type { UpcElasticList } from '../models/UpcElasticList';
import { request as __request } from '../core/request';

export class ElasticHandlerRestService {
  /**
   * search timezone
   * search from dictionary index by filterValue and filterScope
   * @returns FilterValueElasticList OK
   * @throws ApiError
   */
  public static async elastichandlerSearchDictionaryAutoComplete({
    filterValue,
    filterScope,
  }: {
    /** filter value **/
    filterValue: string;
    /** filter scope **/
    filterScope: 'timezone';
  }): Promise<FilterValueElasticList> {
    const result = await __request({
      method: 'GET',
      path: `/elastichandler/searchDictionaryAutoComplete`,
      query: {
        filterValue: filterValue,
        filterScope: filterScope,
      },
      errors: {
        400: `Bad request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * search EPC full value
   * By passing a epcCode, you can view the detail of the EPC
   * @returns EpcElasticList OK
   * @throws ApiError
   */
  public static async elastichandlerSearchEpcFull({
    epcCode,
  }: {
    /** filter for epcCode **/
    epcCode: any;
  }): Promise<EpcElasticList> {
    const result = await __request({
      method: 'GET',
      path: `/elastichandler/searchEpcFull`,
      query: {
        epcCode: epcCode,
      },
      errors: {
        400: `bad input parameter, epcCode is mandatory`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * search EPC autocomplete
   * use for autocomplete
   * @returns EpcAutoCompleteList OK
   * @throws ApiError
   */
  public static async elastichandlerSearchEpcAutocomplete({
    epcCode,
  }: {
    /** filter for epcCode **/
    epcCode: any;
  }): Promise<EpcAutoCompleteList> {
    const result = await __request({
      method: 'GET',
      path: `/elastichandler/searchEpcAutocomplete`,
      query: {
        epcCode: epcCode,
      },
      errors: {
        400: `bad input parameter, epcCode is mandatory`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * search UPC full value
   * By passing filter value and filter type , you can view the detail
   * @returns UpcElasticList OK
   * @throws ApiError
   */
  public static async elastichandlerSearchFromUpcByFilterFull({
    filterType,
    filterValue,
  }: {
    /** filter type **/
    filterType: 'upcCode' | 'modelCode';
    filterValue: any;
  }): Promise<UpcElasticList> {
    const result = await __request({
      method: 'GET',
      path: `/elastichandler/searchFromUpcByFilterFull`,
      query: {
        filterType: filterType,
        filterValue: filterValue,
      },
      errors: {
        400: `bad input parameter, upcCode is mandatory`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * search from UPC autocomplete
   * use for autocomplete
   * @returns FilterValueElasticList OK
   * @throws ApiError
   */
  public static async elastichandlerSearchFromUpcByFilterAutocomplete({
    filterType,
    filterValue,
  }: {
    /** filter type **/
    filterType: 'upcCode' | 'modelCode';
    filterValue: any;
  }): Promise<FilterValueElasticList> {
    const result = await __request({
      method: 'GET',
      path: `/elastichandler/searchFromUpcByFilterAutocomplete`,
      query: {
        filterType: filterType,
        filterValue: filterValue,
      },
      errors: {
        400: `bad input parameter, upcCode is mandatory`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * This REST push PMD to elastic
   * This REST push PMD to elastic
   * @returns any Accepted.
   * @throws ApiError
   */
  public static async elastichandlerSendUpcToElastic(): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/elastichandler/sendUpcToElastic`,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * This REST push PMD to elastic by upc codes
   * This REST push PMD to elastic by upc codes
   * @returns any Accepted.
   * @throws ApiError
   */
  public static async elastichandlerSendUpcToElasticByUpcCodes({
    requestBody,
  }: {
    requestBody: SendUpcToElasticByUpcCodesRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/elastichandler/sendUpcToElasticByUpcCodes`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * send EPC to elastic
   * Send EPC to elastic by filterByDateSendToElastic
   * @returns any Accepted.
   * @throws ApiError
   */
  public static async elastichandlerSendEpcToElastic({
    filterByDateSendToElastic,
  }: {
    /** The query param filterByDateSendToElastic must be true or false. Apply filter by DATE_SEND_TO_ELASTIC if true or send full epc if false. **/
    filterByDateSendToElastic: any;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/elastichandler/sendEpcToElastic`,
      query: {
        filterByDateSendToElastic: filterByDateSendToElastic,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * This REST push EPCS filtered by store code to elastic
   * This REST push EPCS filtered by store code to elastic by store code
   * @returns any Accepted.
   * @throws ApiError
   */
  public static async elastichandlerSendEpcByStoreToElastic({
    storeCode,
  }: {
    /** push EPCS filtered by store code to elastic **/
    storeCode: any;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/elastichandler/sendEpcByStoreToElastic`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * This REST push EPCS filtered by epc creation date to elastic
   * This REST push EPCS filtered by epc creation date to elastic
   * @returns any Accepted.
   * @throws ApiError
   */
  public static async elastichandlerSendEpcByCreationDateToElastic({
    creationDate,
  }: {
    /** This REST push EPCS filtered by epc creation date must be dd/mm/yyyy **/
    creationDate: any;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/elastichandler/sendEpcByCreationDateToElastic`,
      query: {
        creationDate: creationDate,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * delete EPC to elastic filter by DateSendToElastic
   * This REST delete EPCS full.
   * @returns any Accepted.
   * @throws ApiError
   */
  public static async elastichandlerDeleteEpcFromElastic({
    filterByDateSendToElastic,
  }: {
    /** The query param filterByDateSendToElastic must be true or false. Apply filter by DATE_SEND_TO_ELASTIC if true; delete full epc if false **/
    filterByDateSendToElastic: any;
  }): Promise<any> {
    const result = await __request({
      method: 'DELETE',
      path: `/elastichandler/deleteEpcFromElastic`,
      query: {
        filterByDateSendToElastic: filterByDateSendToElastic,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * delete EPC to elastic by epc creation date
   * This REST delete EPCS filtered by epc creation date from elastic
   * @returns any Accepted.
   * @throws ApiError
   */
  public static async elastichandlerDeleteEpcByCreationDateFromElastic({
    creationDate,
  }: {
    /** This REST delete EPCS filtered by epc creation date, must be dd/mm/yyyy **/
    creationDate: any;
  }): Promise<any> {
    const result = await __request({
      method: 'DELETE',
      path: `/elastichandler/deleteEpcByCreationDateFromElastic`,
      query: {
        creationDate: creationDate,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
