import React from 'react';
import { useAsync } from 'react-use';
import { useAppDispatch } from '@/app/store';
import { useTranslation } from 'react-i18next';

import { Tabs } from '@/components/layout/Tabs';
import {
  ConfimCreateDamagesPosRequest,
  PosFlowType,
  PosService,
  ProductItemForPrint,
} from '@/api/receive';
import {
  getReturnPosItems,
  setProcessLocked,
} from '@/features/returnPos/returnPosSlice';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import ProductList from './ProductList';
import { useSelector } from '@/hooks/useSelector';
import { UIBox } from '@/components/ui/Box';
import { UIButtonWithIcon } from '@/components/ui/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import { PageLoader } from '@/components/ui/PageLoader';
import { Typography } from '@/components/ui/Typography';
import { Type } from './ProductList/types';
import ModalPrintTemp from '@/components/layout/ModalPrintTemp';
import { usePrintContext } from '@/context/Print';
import {
  findUpc as findProductToPrint,
  printLabel,
} from '@/features/print/printSlice';
import { handlePrintLayout } from '@/utils/handlePrintLayout';
import { ModalPrintReturnsBackup } from '@/components/layout/ModalPrintReturnsBackup';
import {
  confirmBadCondtionItems,
  confirmGoodCondtionItems,
  fetchDamageReasons,
} from '@/features/storeDamages/storeDamagesSlice';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { AppRoutes } from '@/app/routers';
import { useHistory } from 'react-router';
import { CustomReturnPosItem } from '@/types/returnPos';
import { DeleteOutline } from '@material-ui/icons';
import { isSaleExceptionsManager } from '@/utils/user';
import { encodeRequest } from '@/utils';
import { ModalAttention } from '@/components/layout/ModalAttention';

const PageReturnPos: React.FC = () => {
  const { t } = useTranslation();
  const { print, setPrint } = usePrintContext();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const tabs = [t('returnItems'), t('voidItems'), t('exceptionsItems')];

  const [getPosExceptionsError, setGetPosExceptionsError] =
    React.useState<boolean>(false);
  const [printModalIsVisible, setPrintModalVisibility] =
    React.useState<boolean>(false);
  const [conditionModalIsVisible, setConditionModalVisibility] =
    React.useState<boolean>(false);
  const [confirmDeleteExceptions, setConfirmDeleteExceptions] =
    React.useState<boolean>(false);
  const [loadingDeleteExceptions, setLoadingDeleteExceptions] =
    React.useState<boolean>(false);

  const [productToPrint, setProductToPrint] =
    React.useState<ProductItemForPrint>();
  const [productPosDate, setProductPosDate] = React.useState<string>();
  const [confirmError, setConfirmError] = React.useState<boolean>(false);
  const [removePosItemError, setRemovePosItemError] =
    React.useState<boolean>(false);
  const [productInGoodCondition, setProductInGoodCondition] =
    React.useState<boolean>(false);
  const [searchProductToPrintError, setSearchProductToPrintError] =
    React.useState<boolean>(false);
  const [productReasonCode, setProductReasonCode] = React.useState<string>();

  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [listType, setListType] = React.useState<Type>(Type.RETURN);

  const pos = useSelector(state => state.returnPos);
  const user = useSelector(state => state.user);

  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );

  const { confirmBadConditionHasError, confirmGoodConditionHasError } =
    useSelector(state => state.storeDamages);

  React.useEffect(() => {
    dispatch(setProcessLocked(true));
  }, [dispatch]);

  const getPosExceptions = React.useCallback(async () => {
    switch (selectedTab) {
      case 0:
        return dispatch(getReturnPosItems([PosFlowType.POS_RETURN]));

      case 1:
        return dispatch(getReturnPosItems([PosFlowType.POS_VOID_SALE]));

      default:
        return dispatch(
          getReturnPosItems([
            PosFlowType.POS_RETURN_EXCEPTION,
            PosFlowType.POS_VOID_SALE_EXCEPTION,
          ])
        );
    }
  }, [dispatch, selectedTab]);

  const removeReturnPosExceptions = React.useCallback(async () => {
    setLoadingDeleteExceptions(true);
    try {
      switch (selectedTab) {
        case 0:
          await PosService.posRemovePosItemEncoded({
            requestBody: {
              removePosRequest: encodeRequest(
                pos.returnItems.map(
                  ({ upcCode, items, date, posReasonCode }) => ({
                    posFlowType: PosFlowType.POS_RETURN,
                    upcCode: upcCode || '',
                    storeCode,
                    rowsToDelete: items || 1,
                    date: date,
                    reasonCode: posReasonCode,
                  })
                )
              ),
            },
          });
          dispatch(getReturnPosItems([PosFlowType.POS_RETURN]));
          break;
        case 1:
          await PosService.posRemovePosItemEncoded({
            requestBody: {
              removePosRequest: encodeRequest(
                pos.voidSales.map(({ upcCode, items, date }) => ({
                  posFlowType: PosFlowType.POS_VOID_SALE,
                  upcCode: upcCode || '',
                  storeCode,
                  rowsToDelete: items || 1,
                  date: date,
                }))
              ),
            },
          });
          dispatch(getReturnPosItems([PosFlowType.POS_VOID_SALE]));
          break;
      }
    } catch {
      setRemovePosItemError(true);
    }
    setLoadingDeleteExceptions(false);
    setConfirmDeleteExceptions(false);
  }, [dispatch, pos.returnItems, pos.voidSales, selectedTab, storeCode]);

  useAsync(async () => {
    await dispatch(fetchDamageReasons());
  }, []);

  useAsync(async () => {
    await getPosExceptions();
  }, [getPosExceptions]);

  React.useEffect(() => {
    if (pos.getReturnPosItemsHasError) {
      setGetPosExceptionsError(true);
    }
  }, [pos.getReturnPosItemsHasError]);

  const searchProductToPrint = async (
    product: CustomReturnPosItem
  ): Promise<void> => {
    try {
      const isInGoodCondition = product.isInGoodCondition || false;
      const reasonCode = product.posReasonCode;
      const posDate = product.date;

      const productToPrint = await dispatch(
        findProductToPrint({
          storeCode,
          upcCode: product.upcCode || '',
        })
      ).unwrap();

      setProductInGoodCondition(isInGoodCondition);
      setProductReasonCode(reasonCode);
      setProductPosDate(posDate);
      setConditionModalVisibility(true);
      return setProductToPrint(productToPrint);
    } catch {
      setSearchProductToPrintError(true);
    }
  };

  const onListPrintActionClick = async (
    listType: Type,
    product: CustomReturnPosItem
  ): Promise<void> => {
    switch (listType) {
      case Type.RETURN:
        setListType(Type.RETURN);
        return searchProductToPrint(product);

      case Type.VOID:
        setListType(Type.VOID);
        setProductInGoodCondition(true);
        setProductPosDate(product.date);
        setProductToPrint({
          upcCode: product.upcCode,
          currency: product.currency,
        });

        return setPrintModalVisibility(true);

      default:
        return;
    }
  };

  const onListRemoveActionClick = async (
    product: CustomReturnPosItem,
    isVoid?: boolean
  ): Promise<void> => {
    try {
      await PosService.posRemovePosItem({
        requestBody: [
          {
            storeCode,
            upcCode: product.upcCode || '',
            rowsToDelete: product.items || 1,
            date: product.date,
            reasonCode: isVoid ? undefined : product.posReasonCode,
            posFlowType: isVoid
              ? PosFlowType.POS_VOID_SALE_EXCEPTION
              : PosFlowType.POS_RETURN_EXCEPTION,
          },
        ],
      });

      await getPosExceptions();
    } catch {
      setRemovePosItemError(true);
    }
  };

  const handleConfirm = async (epcCode: string): Promise<void> => {
    const damagesDetails = print.reason;

    let requestBody: ConfimCreateDamagesPosRequest = {
      storeCode,
      upcCodes: [
        {
          upcCode: productToPrint?.upcCode || '',
          posFlowType:
            listType === Type.RETURN
              ? PosFlowType.POS_RETURN
              : PosFlowType.POS_VOID_SALE,
          posReasonCode: productReasonCode
            ? String(productReasonCode)
            : undefined,
          returnDate: productPosDate,
          epcs: [
            {
              epcCode,
              movementReasonCode:
                damagesDetails?.movementReasonCode || undefined,
              movementTypeCode: damagesDetails?.movementTypeCode || undefined,
            },
          ],
        },
      ],
    };

    try {
      !productInGoodCondition
        ? await dispatch(confirmBadCondtionItems(requestBody)).unwrap()
        : await dispatch(confirmGoodCondtionItems(requestBody)).unwrap();
      history.push(AppRoutes.PDF_DOWNLOAD_RETURN_DAMAGE);
    } catch {
      return setConfirmError(true);
    }
  };

  const showPrintError = (visible: boolean, message?: string): void => {
    setPrint(prevState => ({
      ...prevState,
      error: {
        visible,
        message,
      },
    }));
  };

  const printProduct = async (): Promise<void> => {
    if (print.error.message) {
      showPrintError(false);
    }

    if (storeCode && productToPrint) {
      try {
        const { upcCode, currency } = productToPrint;

        const response = await dispatch(
          printLabel({
            storeCode: storeCode,
            layout: handlePrintLayout(
              currency || '',
              print.printer?.isRfid || false
            ),
            serialNumberPrinter: print.printer?.printerCode || '',
            upcCode: upcCode!,
          })
        ).unwrap();

        if (print.printer?.isRfid) {
          await handleConfirm(response.epc || '');
          await getPosExceptions();
          setPrintModalVisibility(false);
          setProductInGoodCondition(false);
          setProductToPrint(undefined);
          setProductReasonCode(undefined);
        } else {
          setPrint(prevState => ({
            ...prevState,
            data: {
              printedEPC: response.epc,
            },
          }));
        }
      } catch {
        showPrintError(true, t('error.something_went_wrong'));
      }
    }
  };

  const startWriteTags = async (): Promise<void> => {
    if (print.printer && !print.printer.isRfid) {
      await handleConfirm(print.data.printedEPC || '');
      await getPosExceptions();
      setPrintModalVisibility(false);
      setProductInGoodCondition(false);
      setProductToPrint(undefined);
      setProductReasonCode(undefined);
    }
  };

  const handlePrintModalClose = (): void => {
    setPrintModalVisibility(false);
    setProductToPrint(undefined);
  };

  const handleModalReturnPrintClick = (): void => {
    setPrintModalVisibility(true);
    setConditionModalVisibility(false);
  };

  const handleModalReturnClose = (): void => {
    setConditionModalVisibility(false);
    setProductToPrint(undefined);
  };

  return (
    <>
      {pos.getReturnPosItemsIsLoading ? (
        <PageLoader />
      ) : (
        <>
          <ModalAttention
            open={confirmDeleteExceptions}
            onConfirmClick={removeReturnPosExceptions}
            onClose={(): void => setConfirmDeleteExceptions(false)}
            messageMaxWidth="95%"
            modalMaxWidth="50%"
            message={t('confirmDeleteExceptions')}
            loadingConfirm={loadingDeleteExceptions}
            disableConfirm={loadingDeleteExceptions}
          />
          <ModalPrintReturnsBackup
            open={conditionModalIsVisible}
            exportIsDamaged={setProductInGoodCondition}
            onClose={handleModalReturnClose}
            onCloseHandler={handleModalReturnClose}
            onPrintClick={handleModalReturnPrintClick}
            defaultRadioValue={productInGoodCondition ? 'Y' : 'N'}
            product={{
              ...productToPrint,
              storeCode,
              upcCode: productToPrint?.upcCode || '',
              layout: 'SRO EMEA',
              serialNumberPrinter: '',
            }}
          >
            <UIBox mt={2} mb={3} alignItems="center">
              <Typography margin="0 4px" color="grey">
                {t('returnPos')}:
              </Typography>
              <Typography font="heavy">{productReasonCode}</Typography>
            </UIBox>
          </ModalPrintReturnsBackup>
          <ModalPrintTemp
            hideReasons={productInGoodCondition || listType === Type.VOID}
            open={printModalIsVisible}
            onClose={handlePrintModalClose}
            productToPrint={productToPrint}
            onPrintClick={printProduct}
            onStartClick={startWriteTags}
            reasonDescription={
              <UIBox ml={1} mb={1.5}>
                <Typography size="sm" margin="0 4px 0 0" color="grey">
                  {t('reasonPos')}:
                </Typography>
                <Typography size="sm" font="heavy">
                  {productReasonCode}
                </Typography>
              </UIBox>
            }
          />
          <UIBox width="100%" mt={3}>
            <Tabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              values={tabs}
            />
          </UIBox>
          <UIBox width="100%" p={3} justifyContent="flex-end" gridGap="10px">
            <UIButtonWithIcon
              label={t('refresh')}
              startIcon={<RefreshIcon />}
              onClick={getPosExceptions}
            />
            {isSaleExceptionsManager(user) && [0, 1].includes(selectedTab) && (
              <UIButtonWithIcon
                label={t('remove')}
                startIcon={<DeleteOutline />}
                onClick={(): void => setConfirmDeleteExceptions(true)}
                disabled={
                  selectedTab === 0
                    ? pos.returnItems.length === 0
                    : pos.voidSales.length === 0
                }
              />
            )}
          </UIBox>
          {selectedTab === 0 && (
            <>
              {pos.returnItems.length > 0 ? (
                <ProductList
                  type={Type.RETURN}
                  products={pos.returnItems}
                  onPrintClick={onListPrintActionClick}
                />
              ) : (
                <UIBox mt={8}>
                  <Typography margin="0 auto" font="heavy" size="lg">
                    {t('anyReturunItems')}
                  </Typography>
                </UIBox>
              )}
            </>
          )}
          {selectedTab === 1 && (
            <>
              {pos.voidSales.length > 0 ? (
                <ProductList
                  type={Type.VOID}
                  products={pos.voidSales || []}
                  onPrintClick={onListPrintActionClick}
                />
              ) : (
                <UIBox mt={8}>
                  <Typography margin="0 auto" font="heavy" size="lg">
                    {t('anyVoidSalesItems')}
                  </Typography>
                </UIBox>
              )}
            </>
          )}
          {selectedTab === 2 && (
            <>
              {pos.returnItems.length === 0 && pos.voidSales.length === 0 ? (
                <UIBox mt={8}>
                  <Typography margin="0 auto" font="heavy" size="lg">
                    {t('anyExceptionsItems')}
                  </Typography>
                </UIBox>
              ) : (
                <>
                  {pos.returnItems.length > 0 && (
                    <>
                      <UIBox ml={3} alignSelf="start">
                        <Typography size="lg" font="heavy">
                          {t('returnItems')}
                        </Typography>
                      </UIBox>
                      <ProductList
                        type={Type.EXCEPTIONS}
                        products={pos.returnItems || []}
                        onRemoveClick={(product): Promise<void> =>
                          onListRemoveActionClick(product)
                        }
                      />
                    </>
                  )}
                  {pos.voidSales.length > 0 && (
                    <UIBox
                      width="100%"
                      flexDirection="column"
                      mt={pos.returnItems.length > 0 ? -6 : 0}
                    >
                      <UIBox ml={3} alignSelf="start">
                        <Typography size="lg" font="heavy">
                          {t('voidItems')}
                        </Typography>
                      </UIBox>
                      <ProductList
                        type={Type.EXCEPTIONS}
                        products={pos.voidSales || []}
                        onRemoveClick={(product): Promise<void> =>
                          onListRemoveActionClick(product, true)
                        }
                      />
                    </UIBox>
                  )}
                </>
              )}
            </>
          )}
          <CTAContainer
            type="BACK"
            onClick={(): void => history.push(AppRoutes.CUSTOMER_RETURNS_INTRO)}
          />
        </>
      )}
      <ErrorSnackbar
        open={getPosExceptionsError}
        setIsOpen={setGetPosExceptionsError}
        errorMessage={t('error.getReturnPosExceptions')}
      />
      <ErrorSnackbar
        open={searchProductToPrintError}
        setIsOpen={setSearchProductToPrintError}
        errorMessage={t('error.searchProductToPrint')}
      />
      <ErrorSnackbar
        open={removePosItemError}
        setIsOpen={setRemovePosItemError}
        errorMessage={t('error.removePosItem')}
      />
      <ErrorSnackbar
        open={confirmError && !!confirmBadConditionHasError}
        setIsOpen={setConfirmError}
        errorMessage={
          confirmBadConditionHasError?.body?.errorMessage ||
          confirmBadConditionHasError?.body ||
          t('error.something_went_wrong')
        }
      />
      <ErrorSnackbar
        open={confirmError && !!confirmGoodConditionHasError}
        setIsOpen={setConfirmError}
        errorMessage={
          confirmGoodConditionHasError?.body?.errorMessage ||
          confirmGoodConditionHasError?.body ||
          t('error.something_went_wrong')
        }
      />
    </>
  );
};

export default PageReturnPos;
