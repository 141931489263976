import { UIBox } from '@/components/ui/Box';
import { Product } from '@/types/enum';
import styled from 'styled-components';

export const StyledListHeader = styled(UIBox)`
  position: sticky;
  top: 230px;
  background: #fff;
  z-index: 3;
  flex-direction: column;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;

  &.${Product.NOT_FOUND}, &.${Product.UNEXPECTED} {
    margin: 0;
  }
`;
