import React from 'react';
import { PaginationList } from '@/components/layout/PaginationList';

import { Typography } from '@/components/ui/Typography';

import { useTranslation } from 'react-i18next';
import ProductTableRow from './ProductTableRow';
import { StyledGrid, StyledGridHeader } from './style';
import { ProductTableProps } from './types';
import { ScannedProduct } from '@/types/product';
import { UIBox } from '@/components/ui/Box';

const ProductTable: React.FC<ProductTableProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledGrid padding="0 24px">
        <StyledGridHeader>
          <Typography font="heavy">{t('product.brandDescription')}</Typography>
          <Typography font="heavy">{t('modelCode')}</Typography>
          <Typography font="heavy">{t('product.upcCode')}</Typography>
          <Typography font="heavy">{t('receiving.upcdescription')}</Typography>
          <Typography font="heavy">{t('receiving.shipqty')}</Typography>
          <Typography font="heavy">{t('receiving.countqty')}</Typography>
        </StyledGridHeader>

        <PaginationList
          data={data}
          pageSize={20}
          noItems={
            <UIBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop="10%"
            >
              <Typography font="heavy" color="grey">
                {t('noItems')}
              </Typography>
            </UIBox>
          }
          renderItem={(product: ScannedProduct): JSX.Element =>
            product && <ProductTableRow {...product} key={product.upcCode} />
          }
        />
      </StyledGrid>
    </>
  );
};

export default ProductTable;
