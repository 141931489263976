import {
  deselectAllMarketingStory,
  deselectAllMarketingTheme,
  selectAllMarketingStory,
  selectAllMarketingTheme,
} from '@/features/cycleCount/cycleCountSlice';
import { CustomMarketingStory, CustomMarketingTheme } from '@/types/cycleCount';

interface MarketingTheme {
  payload: CustomMarketingTheme[];
  type: string;
}

interface MarketingStory {
  payload: CustomMarketingStory[];
  type: string;
}

export const manageSelectAllMarketingThemes = (
  data: CustomMarketingTheme[],
  selectAll: boolean
): MarketingTheme => {
  if (selectAll) {
    return selectAllMarketingTheme(data);
  } else {
    return deselectAllMarketingTheme(data);
  }
};

export const manageSelectAllMarketingStories = (
  data: CustomMarketingStory[],
  selectAll: boolean
): MarketingStory => {
  if (selectAll) {
    return selectAllMarketingStory(data);
  } else {
    return deselectAllMarketingStory(data);
  }
};
