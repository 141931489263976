import { FC } from 'react';
import styled from 'styled-components';
import {
  createStyles,
  makeStyles,
  Switch,
  Theme,
  SwitchProps as MuiSwtichProps,
} from '@material-ui/core';
import { colors } from '@/theme';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';

interface SwitchProps {
  checked: boolean;
  checkedLabel?: string;
  unCheckedLabel?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: colors.white,

        '& + $track': {
          backgroundColor: colors.primary,
          opacity: 1,
          border: 'none',
        },
      },

      '&$focusVisible $thumb': {
        border: '6px solid #fff',
        backgroundColor: colors.primary,
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid gray`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
);

const StyledWrapper = styled(UIBox)`
  align-items: center;
`;

const UISwitch: FC<SwitchProps & MuiSwtichProps> = ({
  checked,
  checkedLabel,
  unCheckedLabel,
  ...props
}) => {
  const classes = useStyles();

  return (
    <StyledWrapper>
      <Typography font="heavy">
        {checked ? checkedLabel : unCheckedLabel}
      </Typography>
      <Switch
        {...props}
        checked={checked}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
      />
    </StyledWrapper>
  );
};

export default UISwitch;
