import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ReceiveShipmentsService } from '@/api';

import type { ProductItem, ReceiveApiError as ApiError } from '@/api';

export interface ProductDetailsSlice {
  details?: ProductItem;
  isLoading: boolean;
  error?: ApiError;
}

const initialState: ProductDetailsSlice = {
  isLoading: false,
  error: undefined,
  details: undefined,
};

export const getProductDetails = createAsyncThunk<
  ProductItem,
  string,
  { rejectValue: ApiError }
>('productDetails/getProductDetails', async (itemCode, { rejectWithValue }) => {
  try {
    return await ReceiveShipmentsService.receiveshipGetProductItemDetails({
      itemCode,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const productDetailsSlice = createSlice({
  name: 'productDetails',
  initialState,
  reducers: {
    initProductDetails() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProductDetails.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getProductDetails.fulfilled, (state, { payload }) => {
        state.details = payload;
        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(getProductDetails.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
});

export const { initProductDetails } = productDetailsSlice.actions;
export default productDetailsSlice.reducer;
