export const fontFamilies = {
  medium: 'AvenirLTPro-Medium',
  light: 'AvenirLTPro-Light',
  heavy: 'AvenirLTPro-Heavy',
  roman: 'AvenirLTPro-Roman',
  book: 'AvenirLTPro-Book',
  black: 'AvenirLTPro-Black',
  default: 'AvenirLTPro-Roman',
};

export type TFontFamily = keyof typeof fontFamilies;
export type TFontFamilyValues = typeof fontFamilies[TFontFamily];

export const fontSizes = {
  xxs: '10px',
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '18px',
  xl: '24px',
};

export type TFontSize = keyof typeof fontSizes;
export type TFontSizeValues = typeof fontSizes[TFontSize];

export const typography = {
  body1: {
    fontFamily: fontFamilies.medium,
  },
  body2: {
    fontFamily: fontFamilies.medium,
  },
  button: {
    fontFamily: fontFamilies.medium,
  },
  caption: {
    fontFamily: fontFamilies.medium,
  },
  fontFamily: fontFamilies.medium,
  h1: {
    fontFamily: fontFamilies.heavy,
  },
  h2: {
    fontFamily: fontFamilies.medium,
  },
  h3: {
    fontFamily: fontFamilies.medium,
  },
  h4: {
    fontFamily: fontFamilies.medium,
  },
  h5: {
    fontFamily: fontFamilies.medium,
  },
  h6: {
    fontFamily: fontFamilies.medium,
  },
  overline: {
    fontFamily: fontFamilies.medium,
  },
  subtitle1: {
    fontFamily: fontFamilies.medium,
  },
  subtitle2: {
    fontFamily: fontFamilies.medium,
  },
};
