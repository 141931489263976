import { FC } from 'react';
import styled from 'styled-components';
import { IconButton, List, ListSubheader } from '@material-ui/core';
import { UIModal } from '@/components/ui/Modal';
import { UIBox } from '@/components/ui/Box';
import { palette, theme } from '@/theme';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Typography } from '@/components/ui/Typography';
import { RepricingListItem } from '../RepricingListItem';
import { RepriceUpc } from '@/api/reprice';
import { useTranslation } from 'react-i18next';

//#region - Interfaces
export interface ModalPrintDetailsProps {
  open: boolean;
  brandName: string;
  onClose: () => void;
  repriceUpcs: RepriceUpc[];
}
//#endregion

//#region - Styled Components
export const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: auto;

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                  overflow-y: auto;
              }`}
`;

export const StyledCloseIcon = styled(IconButton)`
  align-self: flex-end;
  position: absolute !important;

  svg {
    fill: #005192;
  }

  ${theme.breakpoints.down('md')} {
    top: 0;
    right: 0.5rem;
  }
`;

export const StyledContent = styled(UIBox)`
  height: 100%;
  width: 100%;
  padding: 54px;

  & > * {
    width: 100%;
  }
`;

const StyledList = styled(List)`
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid ${palette.colors.lightGrey};
  background: ${palette.colors.midGrey};
  margin-bottom: 60px;
`;

const StyledSubHeader = styled(ListSubheader)`
  background: ${palette.colors.lightGrey};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  padding: 0 24px;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
`;

//#endregion

const ModalRepriceItems: FC<ModalPrintDetailsProps> = ({
  open,
  onClose,
  brandName,
  repriceUpcs,
}) => {
  const { t } = useTranslation();

  return (
    <UIModal $minWidth="70vw" open={open} onClose={onClose}>
      <StyledWrapper>
        <StyledCloseIcon onClick={onClose}>
          <HighlightOffIcon />
        </StyledCloseIcon>
        <Typography size="lg" font="heavy" as="h1">
          {t('itemsWithPriceZero')}
        </Typography>
        <StyledContent>
          <StyledList
            subheader={
              <StyledSubHeader>
                <Typography font="heavy" as="h1">
                  {brandName}
                </Typography>
                <Typography font="heavy" as="h1">
                  {t('reprice.items', {
                    count: repriceUpcs.length,
                  })}
                </Typography>
              </StyledSubHeader>
            }
          >
            {repriceUpcs.map(repriceItem => (
              <RepricingListItem
                repriceItem={repriceItem}
                brandName={repriceItem.brandDescription}
                setUpcPrintQuantity={(): void => {}}
                checkboxIsVisible={false}
                printStatusIsVisible={false}
                printCounterIsVisible={false}
                itemsInStockAreVisible={false}
              />
            ))}
          </StyledList>
        </StyledContent>
      </StyledWrapper>
    </UIModal>
  );
};

export default ModalRepriceItems;
