import { FC, useEffect } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { useSelector } from '@/hooks/useSelector';
import styled from 'styled-components';

import { theme } from '@/theme';
import { StockSummaryService } from '@/api';

// import { PageLoader } from '@/components/ui/PageLoader';
import { useTranslation } from 'react-i18next';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { UIButton } from '@/components/ui/Button';

const SyledContainer = styled(UIBox)`
  max-width: ${theme.breakpoints.values.lg}px;
  margin: auto auto 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    &:first-of-type {
      margin: 4vh 0 3vh;
    }

    /* &:last-of-type {
      font-size: 36px;
    } */
  }
`;

const PageSOH: FC = () => {
  const { t } = useTranslation();
  const { store } = useSelector(state => state.currentStore);
  const { search } = useLocation();
  const query = qs.parse(search);

  const onDownloadClick = async (): Promise<void> => {
    if (store?.storeCode) {
      const { csvFile, nameFile } = await StockSummaryService.stockStockOnHand({
        storeCode: store.storeCode,
      });

      const linkSource = `data:application/csv;base64,${csvFile}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = nameFile;
      downloadLink.click();
    }
  };

  useEffect(() => {
    if (query.download === '1') {
      onDownloadClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useBreadcrumbs([
    {
      title: t('page.soh'),
    },
  ]);

  return (
    <SyledContainer>
      <Typography size="lg" as="h1">
        {t('page.soh')}
      </Typography>
      <Typography size="lg" font="heavy" as="h1">
        {t('soh.download.file')}
      </Typography>
      <UIButton label={t('download')} onClick={onDownloadClick} />
    </SyledContainer>
  );
};

export default PageSOH;
