import { UIBox } from '@/components/ui/Box';
import styled from 'styled-components';

export const StyledProductItem = styled(UIBox)`
  flex-direction: column;
  padding: 20px;
  background: #fbfbfb;
  align-content: start;

  &:not(:last-of-type) {
    border-bottom: 1px solid #ededed;
  }
`;

export const StyledProductContent = styled(UIBox)`
  position: relative;
  align-items: center;
  display: grid;
  grid-template-columns: 3fr 1fr auto;
  justify-content: space-between;

  &::before {
    content: '';
    border-radius: 100px;
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 45px;
    z-index: 9999;
  }
`;
