import React from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { Typography } from '@/components/ui/Typography';
import { UIBox } from '@/components/ui/Box';
import { UIAction } from '@/components/ui/Action';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { isInventoryHider, isVirtualBuddy } from '@/utils/user';

import {
  StyledActionWrapper,
  StyledCalendarIcon,
  StyledIconWrapper,
  StyledInventoryItem,
  StyledStoreInfoWrapper,
  StyledErrorOutlineIcon,
} from './style';
import { InventoryStatus, InventoyStatusValues } from '@/types/enum';
import { InventoryResponse as InvetoryDetails } from '@/api/receive';
import getCurrentDate from '@/utils/getCurrentDate';
import { palette } from '@/theme';
import { useHistory } from 'react-router';
import { AppRoutes } from '@/app/routers';

interface InventoryItemProps extends InvetoryDetails {
  inventoryStatus: InventoyStatusValues;
  inventoryClickHandler?: (
    id: string,
    inventoryStatus?: InventoyStatusValues,
    isRetry?: boolean,
    inventoryStoreCode?: string,
    inventoryOwner?: string
  ) => Promise<void> | void;
  disabled?: boolean;
  hideClick?: (inventoryId: string, isRetry: boolean) => void;
}

const InventoryItem: React.FC<InventoryItemProps> = ({
  inventoryDate,
  inventoryId,
  inventoryStatus,
  storeDescription,
  storeAddress,
  inventoryClickHandler,
  disabled = false,
  hideClick,
  isRetry,
  inventoryOwner,
  storeCode: inventoryStoreCode,
}) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const user = useSelector(state => state.user);

  const detailsClickHandler = (): void => {
    if (isVirtualBuddy(user)) {
      return history.push(`${AppRoutes.INVENTORY}-details/${inventoryId}`, {
        inventoryStoreCode,
      });
    }

    return history.push(`${AppRoutes.INVENTORY}-details/${inventoryId}`);
  };

  const dateHandler = (date: string): string => {
    return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('yyyy LLLL dd', {
      locale: i18n.language,
    });
  };

  const hideClickHandler = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    e.stopPropagation();
    hideClick?.(inventoryId, isRetry || false);
  };

  return (
    <StyledInventoryItem
      onClick={(): Promise<void> | void =>
        inventoryClickHandler?.(
          inventoryId,
          inventoryStatus,
          isRetry,
          inventoryStoreCode,
          inventoryOwner
        )
      }
      disabled={
        disabled ||
        (inventoryStatus === InventoryStatus.SCHEDULED &&
          inventoryDate.replaceAll('-', '') !== getCurrentDate())
      }
      //@ts-ignore
      button={inventoryStatus !== InventoryStatus.VALIDATED}
    >
      <UIBox alignItems="center">
        <StyledCalendarIcon $inventoryStatus={inventoryStatus} />
        <Typography size="md">{dateHandler(inventoryDate)}</Typography>
      </UIBox>
      {isVirtualBuddy(user) && (
        <StyledStoreInfoWrapper>
          <Typography size="md">{storeDescription}</Typography>
          <Typography size="md">{storeAddress}</Typography>
        </StyledStoreInfoWrapper>
      )}
      {isInventoryHider(user) && inventoryStatus === InventoryStatus.SCHEDULED && (
        <UIBox ml="auto" onClick={hideClickHandler}>
          <Typography font="heavy" color="primary" size="md">
            {t('hide')}
          </Typography>
        </UIBox>
      )}
      {inventoryStatus === InventoryStatus.VALIDATED && (
        <StyledActionWrapper>
          <UIAction
            label={t('details')}
            icon="search"
            onClick={detailsClickHandler}
          />
        </StyledActionWrapper>
      )}
      {inventoryStatus === InventoryStatus.REFUSED && (
        <UIBox alignItems="center">
          <Typography>{t('refusedByVirtualBuddy')}</Typography>
          <StyledErrorOutlineIcon htmlColor={palette.colors.yellow} />
        </UIBox>
      )}
      {inventoryStatus === InventoryStatus.ON_GOING && (
        <UIBox alignItems="center">
          <Typography>{t('ongoing')}</Typography>
          <StyledIconWrapper>
            <MoreHorizIcon htmlColor="#fff" />
          </StyledIconWrapper>
        </UIBox>
      )}
    </StyledInventoryItem>
  );
};

export default InventoryItem;
