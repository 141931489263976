import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import TextFieldController from '@/components/ui/TextField/TextFieldController';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

export const CloseIcon = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const SubContainer = styled.div`
  padding: 2rem 3rem;
`;

export const Title = styled.div`
  margin-bottom: 24px;
`;

export const FieldsGrid = styled.form`
  display: grid;
  margin-top: 24px;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
`;

export const Field = styled(TextFieldController)`
  margin: 0;
  padding: 0;
  width: 100%;
`;
