import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { useLocation } from 'react-router';

import { UIModal } from '@/components/ui/Modal';
import { HighlightOff } from '@material-ui/icons';
import { Step, StepLabel } from '@material-ui/core';
import { Typography } from '@/components/ui/Typography';
import SelectReason from './SelectReason';
import SelectPrinter from './SelectPrinter';
import SelectDevice from './SelectDevice';

import {
  resetWriteTagsState,
  writeTags,
} from '@/features/devices/devicesSlice';

import {
  StyledClose,
  StyledColumn,
  StyledContainer,
  StyledStepContainer,
  StyledStepper,
  StyledSubContainer,
} from './style';

import type { ModalPrintProps } from './types';
import type { ScanDevice } from '@/types/device';
import { initialPrintState, usePrintContext } from '@/context/Print';
import { PrintReasonCodeStatus } from '@/types/enum';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { UIBox } from '@/components/ui/Box';
import { AppRoutes } from '@/app/routers';

const ModalPrintTemp: React.FC<ModalPrintProps> = ({
  open,
  onPrintClick,
  onStartClick,
  onClose,
  productToPrint,
  encodeTag = true,
  hideReasons = false,
  printers,
  reasonDescription,
  ...rest
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { print, setPrint } = usePrintContext();
  const { deviceInUse, writeTagsHasError: writeTagsReduxError } = useSelector(
    state => state.devices
  );
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedPrinter, setSelectedPrinter] = useState<string>();
  const [selectedDevice, setSelectedDevice] = useState<ScanDevice>();
  const [isWriteTagsReady, setWriteTagsIsReady] = useState<boolean>(false);
  const [writeTagsError, setWriteTagsError] = useState<boolean>(false);
  const [writeTagsErrorMessage, setWriteTagsErrorMessage] = useState<string>();

  const handleClose = useCallback((): void => {
    onClose?.({}, 'backdropClick');
  }, [onClose]);

  useEffect(() => {
    if (!open) {
      setActiveStep(0);
      setPrint(initialPrintState);

      setWriteTagsIsReady(false);
      setWriteTagsError(false);
      dispatch(resetWriteTagsState());
    }
  }, [dispatch, open, setPrint]);

  useEffect(() => {
    if (deviceInUse) {
      setSelectedDevice(deviceInUse);
    }
  }, [deviceInUse]);

  useEffect(() => {
    if (writeTagsReduxError) {
      setWriteTagsError(true);
      setWriteTagsErrorMessage(
        writeTagsReduxError?.body?.message || t('error.something_went_wrong')
      );
    }
  }, [t, writeTagsReduxError]);

  useEffect(() => {
    setActiveStep(0);

    if (print.data.printedEPC) {
      if (encodeTag) {
        if (!print.printer?.isRfid) {
          setActiveStep(1);
        } else {
          handleClose();
        }
      } else {
        setActiveStep(0);
        handleClose();
      }
    }
  }, [
    encodeTag,
    handleClose,
    print.data.printedEPC,
    print.printer?.isRfid,
    setActiveStep,
  ]);

  const handleStartClick = async (): Promise<void> => {
    if (writeTagsError) {
      setWriteTagsError(false);
      setWriteTagsErrorMessage(undefined);
      dispatch(resetWriteTagsState());
    }

    if (selectedDevice && print.data.printedEPC) {
      const { deviceId } = selectedDevice;
      const { printedEPC } = print.data;
      const reason = print.reason.movementReasonCode as PrintReasonCodeStatus;

      try {
        const response = await dispatch(
          writeTags({
            dev_id: deviceId,
            epc: printedEPC,
            storeCode: storeCode!,
            upc: productToPrint?.upcCode,
            confirmPrint: pathname === `${AppRoutes.PRINT}/new`,
            reason,
          })
        ).unwrap();

        if (response && response.code !== 'OK') {
          setWriteTagsError(true);
          setWriteTagsErrorMessage(response.msg);
          setActiveStep(1);
        } else {
          setWriteTagsIsReady(true);
          onStartClick();
          handleClose();
        }
      } catch {}
    }
  };

  const steps = [
    { title: t('printNewTag') },
    {
      title: t('encodeNewTag'),
      description: t('encodeTagDescription'),
    },
  ];

  return (
    <>
      <UIModal open={open} onClose={onClose} {...rest}>
        <StyledContainer>
          <StyledClose onClick={(): void => handleClose()}>
            <HighlightOff color={'primary'} />
          </StyledClose>
          <StyledSubContainer>
            <StyledStepper activeStep={activeStep} alternativeLabel>
              {steps.map(({ title, description }) => (
                <Step key={title}>
                  <StepLabel>{title}</StepLabel>
                  {description && (
                    <Typography font="light" size="xs">
                      {description}
                    </Typography>
                  )}
                </Step>
              ))}
            </StyledStepper>
            <StyledStepContainer isActive={activeStep === 0}>
              <StyledColumn>
                {!hideReasons && (
                  <SelectReason reasonDescription={reasonDescription} />
                )}
                <UIBox display="block" mt={!hideReasons ? 2 : 0}>
                  <SelectPrinter
                    printers={printers}
                    selectedPrinter={selectedPrinter}
                    setPrinter={setSelectedPrinter}
                    onPrintClick={onPrintClick}
                    printDisabled={
                      hideReasons
                        ? !print.printer?.printerCode
                        : !print.printer?.printerCode ||
                          !print.reason.movementReason
                    }
                  />
                </UIBox>
              </StyledColumn>
            </StyledStepContainer>
            <StyledStepContainer isActive={activeStep === 1}>
              <SelectDevice
                device={selectedDevice}
                setDevice={setSelectedDevice}
                setActiveStep={setActiveStep}
                isWriteTagsReady={isWriteTagsReady}
                onStartClick={handleStartClick}
              />
            </StyledStepContainer>
          </StyledSubContainer>
        </StyledContainer>
      </UIModal>
      <ErrorSnackbar
        open={writeTagsError}
        setIsOpen={setWriteTagsError}
        errorMessage={writeTagsErrorMessage}
      />
    </>
  );
};

export default ModalPrintTemp;
