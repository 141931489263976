/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TypeOfStore {
  RETAIL = 'RETAIL',
  OUTLET_EMEA = 'OUTLET EMEA',
  OUTLET_US = 'OUTLET US',
  AIRPORT_EMEA = 'AIRPORT EMEA',
  AIRPORT_US = 'AIRPORT US',
  AIRPORT_UK = 'AIRPORT UK',
}
