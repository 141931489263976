export const makeUrl = <Type>(link: string, refine: Type): string => {
  if (
    link === '' ||
    refine === {} ||
    typeof refine === 'undefined' ||
    refine === null
  ) {
    return '';
  }
  return Object.entries(refine).reduce(
    (l, v) => l.replace(`:${v[0]}`, v[1]),
    link
  );
};
