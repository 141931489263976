import React, { FC, useState, useEffect } from 'react';
import { Autocomplete, UseAutocompleteProps } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { TextField, CircularProgress } from '@material-ui/core';
import { StoreList, StoreToStoreService } from '@/api';
import { StoreWithPrinters } from '@/types/store';

interface UIAutoCompleteProps {
  inputPlaceholder?: string;
  selectedStore?: StoreWithPrinters;
  filterBySales?: boolean;
  setSaveEnable?: React.Dispatch<React.SetStateAction<boolean>>;
  setOrganizations?: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedStore?: React.Dispatch<
    React.SetStateAction<StoreWithPrinters | undefined>
  >;
  selectStoreCodeFilter?: (store: StoreWithPrinters) => void;
  clearOnBlur?: boolean;
  blurOnSelect?: boolean;
  showHelpText?: boolean;
  popupIcon?: JSX.Element;
}

const UIAutoCompleteStores: FC<UIAutoCompleteProps> = ({
  selectedStore,
  setSaveEnable,
  setSelectedStore,
  setOrganizations,
  inputPlaceholder,
  selectStoreCodeFilter,
  popupIcon,
  filterBySales = false,
  clearOnBlur = false,
  blurOnSelect = false,
  showHelpText = true,
}) => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useDebounce(
    async () => {
      if (searchTerm.length >= 3) {
        if (
          searchTerm !==
          getStoreLabel({
            storeCode: selectedStore?.storeCode,
            storeName: selectedStore?.storeName,
            storeDescription: selectedStore?.storeDescription,
            storeAddress: selectedStore?.storeAddress,
          })
        ) {
          setLoading(true);
          setOptions(
            await StoreToStoreService.storeSearchStoreListByFilter({
              storeFilter: searchTerm,
              filtered: filterBySales,
            })
          );
          setLoading(false);
        }
      }
    },
    1250,
    [searchTerm]
  );

  const [open, setOpen] = useState<boolean>(false);

  const [options, setOptions] = useState<StoreList>({
    stores: [],
  });

  useEffect(() => {
    if (searchTerm.length > 0) {
      if (selectedStore) {
        if (options.stores && options.stores.length > 0) {
          if (
            searchTerm !==
            getStoreLabel({
              storeCode: selectedStore.storeCode,
              storeName: selectedStore.storeName,
              storeDescription: selectedStore.storeDescription,
              storeAddress: selectedStore.storeAddress,
            })
          ) {
            setOpen(true);
          }
        }
      }
    } else {
      setOpen(false);
      setLoading(false);
    }
  }, [options, searchTerm, selectedStore]);

  const autocompleteOpenHandler: UseAutocompleteProps<
    StoreWithPrinters,
    false,
    false,
    false
  >['onOpen'] = () => {
    if (options.stores && options.stores.length > 0) {
      setOpen(true);
    }
  };

  const autocompleteCloseHandler: UseAutocompleteProps<
    StoreWithPrinters,
    false,
    false,
    false
  >['onClose'] = () => {
    setOpen(false);
  };

  const storeChangeHandler: UseAutocompleteProps<
    StoreWithPrinters,
    false,
    false,
    false
  >['onChange'] = (_e, store) => {
    if (store) {
      setSelectedStore?.(store);
      setOrganizations?.([]);
      selectStoreCodeFilter?.(store);
      setOpen(false);
      setLoading(false);

      if (selectedStore?.storeCode !== '') {
        setSaveEnable?.(true);
      } else {
        setSaveEnable?.(false);
      }
    }
  };

  const autocompleteInputHandler: UseAutocompleteProps<
    StoreWithPrinters,
    false,
    false,
    false
  >['onInputChange'] = (_e, value) => {
    setLoading(true);
    setSearchTerm(value);
  };

  const getStoreLabel = ({
    storeCode,
    storeName,
    storeDescription,
    storeAddress,
  }: {
    storeCode: StoreWithPrinters['storeCode'];
    storeName: StoreWithPrinters['storeName'];
    storeAddress: StoreWithPrinters['storeAddress'];
    storeDescription: StoreWithPrinters['storeDescription'];
  }): string =>
    `${storeCode ? `${storeCode} - ` : ''}` +
    [storeName, storeDescription, storeAddress].filter(el => el).join(' ,');

  return (
    <Autocomplete
      id="change-store"
      open={open}
      onOpen={autocompleteOpenHandler}
      onClose={autocompleteCloseHandler}
      onFocus={(): void => setOpen(true)}
      clearText={t('clear')}
      openText={t('open')}
      noOptionsText={t('settings.noStoreFound')}
      closeText={t('close')}
      loadingText={t('searchingStore')}
      value={selectedStore || null}
      getOptionSelected={(
        option: StoreWithPrinters,
        value: StoreWithPrinters
      ): boolean =>
        getStoreLabel({
          storeCode: option.storeCode,
          storeName: option.storeName,
          storeDescription: option.storeDescription,
          storeAddress: option.storeAddress,
        }) ===
        getStoreLabel({
          storeCode: value.storeCode,
          storeName: value.storeName,
          storeDescription: value.storeDescription,
          storeAddress: value.storeAddress,
        })
      }
      getOptionLabel={({
        storeCode,
        storeName,
        storeDescription,
        storeAddress,
      }): string =>
        getStoreLabel({
          storeCode,
          storeName,
          storeDescription,
          storeAddress,
        })
      }
      fullWidth
      options={(options.stores as StoreWithPrinters[]) || []}
      onChange={storeChangeHandler}
      loading={loading}
      popupIcon={popupIcon}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          placeholder={inputPlaceholder}
          variant="outlined"
          helperText={showHelpText ? t('searchByStore') : undefined}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onInputChange={autocompleteInputHandler}
      clearOnBlur={clearOnBlur}
      blurOnSelect={blurOnSelect}
    />
  );
};

export default UIAutoCompleteStores;
