import styled from 'styled-components';
import { ListSubheader } from '@material-ui/core';
import { colors } from '@/theme';
import { UIListHeader } from '@/components/ui/ListHeader';
import { UIList } from '@/components/ui/List';

export const StyledUIList = styled(UIList)``;

export const StyledListSubHeader = styled(ListSubheader)`
  background: ${colors.lightGrey};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  padding: 4px 24px;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledUnexpectedListHeader = styled(UIListHeader)`
  width: 100%;
  padding: 0 24px;
  margin: 12px 0 0;
`;
