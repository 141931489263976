import React from 'react';
import { useAsync } from 'react-use';
import { useAppDispatch } from '@/app/store';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { setProcessLocked } from '@/features/loader/loaderSlice';
import { UIBox } from '@/components/ui/Box';
import { UIButtonWithIcon } from '@/components/ui/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import FileItem from './FileItem';
import { Typography } from '@/components/ui/Typography';
import { PageLoader } from '@/components/ui/PageLoader';
import {
  LoadStoresRestService,
  RecapManualStoreUploadResponse as RecapStoreUpload,
} from '@/api/receive';

import * as S from './style';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { AppRoutes } from '@/app/routers';
import { useSelector } from '@/hooks/useSelector';
import { ModalDataSavedError } from '@/components/layout/ModalDataSaved';
import * as XLSX from 'xlsx';

const fileFormat = {
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const downloadFile = (file: string, name: string): void => {
  const linkSource = `data:application/csv;base64,${file}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = name;
  downloadLink.click();
};

const PageManualUpload: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );

  const [loading, setLoading] = React.useState<boolean>(false);
  const [recap, setRecap] = React.useState<RecapStoreUpload>();
  const [downloadError, setDownloadError] = React.useState<boolean>(false);
  const [fetchRecapError, setFetchRecapError] = React.useState<boolean>(false);
  const [wrongFileUploaded, setWrongFileUploaded] =
    React.useState<boolean>(false);
  const [downloadDetailsError, setDownloadDetailsError] =
    React.useState<boolean>(false);
  const [downloadDetailsErrorMessage, setDownloadDetailsErrorMessage] =
    React.useState<string>();
  const [uploadErrorSnackbar, setUploadErrorSnackbar] =
    React.useState<boolean>(false);

  const fetchRecap = React.useCallback(async () => {
    try {
      setLoading(true);
      const response =
        await LoadStoresRestService.loadstoresRecapManualStoreUpload({
          storeCode,
        });

      setRecap(response);

      setLoading(false);
    } catch {
      setLoading(false);
      setFetchRecapError(true);
    }
  }, [storeCode]);

  useAsync(async () => {
    dispatch(
      setProcessLocked({
        process: 'manualProcessLocked',
        locked: true,
      })
    );
  }, []);

  useAsync(async () => {
    await fetchRecap();
  }, []);

  const handleDownloadTemplate = async (): Promise<void> => {
    const rows: unknown[] = [];

    const name = `${storeCode}_load_store`;
    const format = `${name}.xlsx`;

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, name);
    XLSX.utils.sheet_add_aoa(worksheet, [['EPC', 'UPC']]);

    worksheet['!cols'] = [
      { wch: 30 },
      { wch: 30 },
      { hidden: true },
      { hidden: true },
      { hidden: true },
    ];

    XLSX.writeFile(workbook, format);
  };

  const handleDownloadErrors = async (): Promise<void> => {
    if (recap && recap.id) {
      try {
        await LoadStoresRestService.loadstoresErrorsVerifiedManualStoreUpload({
          requestBody: {
            id: recap.id,
            loaderType: 'MSUP',
          },
        });

        await fetchRecap();
      } catch {
        setDownloadError(true);
      }
    }
  };

  const handleDownloadErrorDetails = async (id: string): Promise<void> => {
    try {
      const response = await LoadStoresRestService.loadstoresDownloadCsv({
        requestBody: {
          id,
          type: 'ERROR_TAGS',
        },
      });

      if (response) {
        downloadFile(response.base64, response.fileName);
      }
    } catch {
      setDownloadDetailsError(true);
      setDownloadDetailsErrorMessage(t('error.downloadDetails', { storeCode }));
    }
  };

  const openUploadFileDialog = (): void => {
    if (inputRef.current) inputRef.current.click();
  };

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target;

    if (files && files.length > 0) {
      const file = files[0];
      const xlsFileName = `${storeCode}_load_store.xls`;
      const xlsxFileName = `${storeCode}_load_store.xlsx`;

      if (file.name !== xlsFileName && file.name !== xlsxFileName) {
        return setWrongFileUploaded(true);
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async (): Promise<void> => {
        const result = reader.result;

        if (result && typeof result === 'string') {
          try {
            await LoadStoresRestService.loadstoresUploadFileManualStoreUpload({
              requestBody: {
                storeCode,
                base64: result.split(',')[1],
                fileName:
                  file.name === xlsFileName ? xlsFileName : xlsxFileName,
              },
            });

            await fetchRecap();
          } catch {
            setUploadErrorSnackbar(true);
          }
        }
      };

      reader.onerror = (): void => {
        setUploadErrorSnackbar(true);
      };
    }
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <ModalDataSavedError
            $minWidth="440px"
            $minHeight="260px"
            iconType="ERROR"
            open={wrongFileUploaded}
            onClose={(): void => setWrongFileUploaded(false)}
            title={`${t('payAttention')} ${t('storeCodeNotCorrect')}!`}
            message={t('verifyFileNameOrStoreCode', {
              storeCode,
              fileFormat: 'csv',
            })}
          />
          <UIBox flexDirection="column" width="100%" p={3}>
            <UIBox width="100%" justifyContent="space-between">
              <UIBox alignItems="start" flexDirection="column">
                <UIButtonWithIcon
                  startIcon={<GetAppIcon />}
                  label={t('downloadTemplate')}
                  onClick={handleDownloadTemplate}
                />
                <Typography margin="4px 0" color="grey">
                  {t('downloadTemplateBeforeProceeding')}
                </Typography>
              </UIBox>
              <UIButtonWithIcon
                label={t('refresh')}
                startIcon={<RefreshIcon />}
                onClick={fetchRecap}
              />
            </UIBox>
            <UIBox mt={8} flexDirection="column" width="100%">
              <S.HeaderList>
                <Typography font="heavy">{t('nameFile')}</Typography>
                <Typography font="heavy">{t('status')}</Typography>
              </S.HeaderList>
              <S.List>
                {recap && (
                  <FileItem
                    {...recap}
                    onDownloadErrorClick={handleDownloadErrorDetails}
                  />
                )}
              </S.List>
            </UIBox>
          </UIBox>
          <S.InputWrapper>
            <input
              id="upload-csv-file"
              ref={inputRef}
              type="file"
              accept={`${fileFormat.xls}, ${fileFormat.xlsx}`}
              onChange={uploadFile}
            />
          </S.InputWrapper>
          <CTAContainer
            type="THREE_BUTTONS"
            secondaryButtonOutlined
            backButtonLabel={t('quit')}
            secondaryButtonLabel={t('errorsVerified')}
            mainButtonLabel={t('uploadFile')}
            disableSecondaryButton={!recap?.id}
            disabledConfirm={!!recap?.id}
            onQuitClick={(): void => history.push(AppRoutes.STORE_MIGRATOR)}
            onScanClick={handleDownloadErrors}
            onConfirmClick={openUploadFileDialog}
          />
        </>
      )}
      <ErrorSnackbar
        open={fetchRecapError}
        setIsOpen={setFetchRecapError}
        errorMessage={t('error.fetchManualStoreRecap')}
      />
      <ErrorSnackbar
        open={downloadError}
        setIsOpen={setDownloadError}
        errorMessage={t('error.downloadErrors')}
      />
      <ErrorSnackbar
        open={downloadDetailsError}
        setIsOpen={setDownloadDetailsError}
        errorMessage={downloadDetailsErrorMessage}
      />
      <ErrorSnackbar
        open={uploadErrorSnackbar}
        setIsOpen={setUploadErrorSnackbar}
        errorMessage={t('error.file_upload')}
      />
    </>
  );
};

export default PageManualUpload;
