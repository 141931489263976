import React from 'react';
import { StyledChip } from './style';
import { ChipProps as MuiChipProps } from '@material-ui/core/Chip';
interface ChipProps extends MuiChipProps {
  $activeBackground?: 'grey' | 'primary';
}

const UIChip: React.FC<ChipProps> = props => {
  return <StyledChip {...props} disabled={props.disabled} />;
};

UIChip.displayName = 'UIChip';

export default UIChip;
