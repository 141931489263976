import React, { useEffect, useState } from 'react';
import { getSettings, getToken } from '@/features/user/userSlice';
import { useAsync } from 'react-use';
import { useHistory, useLocation } from 'react-router-dom';

import { AppRoutes } from '../app/routers';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { ReceiveOpenAPI } from '@/api';
import { RepriceOpenAPI } from '@/api';
import { BFFOpenAPI } from '@/api';
import { UIBox } from '@/components/ui/Box';
import { UIButton } from '@/components/ui/Button';
import queryString from 'query-string';
import styled from 'styled-components';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';

const {
  REACT_APP_IDM_URL,
  REACT_APP_LUXOTTICA_CLIENT_ID,
  REACT_APP_IDM_REDIRECT_URI,
  REACT_APP_IDM_SCOPE,
  REACT_APP_IDM_ACR_VALUES,
} = process.env;

const LoginWrapper = styled(UIBox)`
  margin-top: 100px;
`;

const PageLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { search } = useLocation();

  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const isLoadingLogin = useSelector(state => state.user.isLoadingLogin);
  const { settingsError } = useSelector(state => state.user);
  const { loginError, errorCode, username, errorMessage } = useSelector(
    state => state.user
  );
  const { store } = useSelector(state => state.currentStore);

  const handleLogin = (): void => {
    if (
      REACT_APP_IDM_URL &&
      REACT_APP_LUXOTTICA_CLIENT_ID &&
      REACT_APP_IDM_REDIRECT_URI
    ) {
      const loginPage = queryString.stringifyUrl({
        url: `${REACT_APP_IDM_URL}/oauth/nam/authz`,
        query: {
          response_type: 'code',
          client_id: REACT_APP_LUXOTTICA_CLIENT_ID,
          redirect_uri: REACT_APP_IDM_REDIRECT_URI,
          scope: REACT_APP_IDM_SCOPE,
          acr_values:
            REACT_APP_IDM_ACR_VALUES ||
            'urn%3Aoasis%3Anames%3Atc%3ASAML%3A2.0%3Aac%3Aclasses%3ARSD',
        },
      });
      window.open(loginPage, '_self');
    }
  };

  const handleLogout = (): void => {
    if (REACT_APP_IDM_URL) {
      window.open(`${REACT_APP_IDM_URL}/app/logout`, '_self');
    }
  };

  useEffect(() => {
    if (search) {
      const parsed = queryString.parse(search);

      if (parsed.code) {
        dispatch(getToken(parsed.code as string));
      }
    }
  }, [dispatch, search]);

  useAsync(async () => {
    if (username && sessionStorage.getItem('userToken')) {
      ReceiveOpenAPI.TOKEN = sessionStorage.getItem('userToken') || undefined;
      RepriceOpenAPI.TOKEN = sessionStorage.getItem('userToken') || undefined;
      BFFOpenAPI.TOKEN = sessionStorage.getItem('userToken') || undefined;

      await dispatch(getSettings());
    }
  }, [history, username, dispatch]);

  useEffect(() => {
    if (store && username && !settingsError) {
      history.replace(AppRoutes.INTRO);
    }

    if (settingsError && !store) {
      history.replace(AppRoutes.SETTINGS);
    }
  }, [settingsError, history, username, store]);

  useEffect(() => {
    setIsErrorVisible(loginError);
  }, [loginError]);

  return (
    <LoginWrapper flexDirection="column" alignItems="center">
      {!loginError ? (
        <UIButton
          onClick={handleLogin}
          disabled={isLoadingLogin}
          label={t('login')}
          loading={isLoadingLogin}
        />
      ) : (
        <UIButton onClick={handleLogout} label={t('logout')} />
      )}

      <ErrorSnackbar
        open={isErrorVisible}
        setIsOpen={setIsErrorVisible}
        errorMessage={
          errorCode
            ? t(`error.code_${errorCode}`)
            : errorMessage || t('error.login')
        }
      />
    </LoginWrapper>
  );
};

export default PageLogin;
