import { Layout, Polar, RfidPrintIn } from '@/api';
import { Printer } from '@/types/store';
import { ProductItemForPrint, TypeOfStore } from '@/api/receive';
import { isEmpty } from './validation';

type CustomPrintBody = Omit<RfidPrintIn, 'layout' | 'serialNumberPrinter'>;

export const findPrint = (
  printers: Printer[],
  serialNumberPrinter: string
): Printer | undefined => {
  const printer = printers.find(
    ({ printerCode }) => printerCode === serialNumberPrinter
  );

  return printer;
};

export const handlePrintLayout = (
  currency: string,
  isRFID: boolean
): `${Layout}` => {
  if (isRFID) {
    return currency === 'USD' ? 'SRO NA' : 'SRO EMEA';
  }

  return currency === 'USD' ? 'SRO NA CREATE-TAG' : 'SRO EMEA CREATE-TAG';
};

export const handlePrintBody = (
  typeOfStore: TypeOfStore,
  product: ProductItemForPrint,
  additionalBody: RfidPrintIn
): RfidPrintIn => {
  const { retailPrice, outletPrice, airportPrice, description, percentage } =
    product;

  let printRequestBody: CustomPrintBody = {
    currency: product.currency,
    van: product.van,
    brandDescription: product.brandDescription,
    styleNameDescription: product.styleNameDescription,
    marketingThemeDescription: product.marketingThemeDescription,
    marketingStoryDescription: product.marketingStoryDescription,
    frameColor: product.frameColor,
    lensColor: product.lensColor,
    upcCode: product.upcCode!,
    //@ts-ignore
    polarCode: product.polarCode as Polar, // ??
  };

  switch (typeOfStore) {
    case TypeOfStore.OUTLET_EMEA:
      printRequestBody = {
        ...printRequestBody,
        price: retailPrice,
        second_price: outletPrice, // discount price
        second_price_type: 'OUTLET', // description
        discount_percent: !isEmpty(percentage)
          ? `${description} ${percentage}`
          : undefined, // percentage (add '%' symbol)
      };
      break;

    case TypeOfStore.AIRPORT_EMEA:
      printRequestBody = {
        ...printRequestBody,
        price: retailPrice,
        second_price: airportPrice, // airport_price
        second_price_type: 'TAX FREE',
      };
      break;

    case TypeOfStore.AIRPORT_UK:
      printRequestBody = {
        ...printRequestBody,
        price: retailPrice, // price
        second_price: airportPrice, // airport_price
        second_price_type: 'AIRPORT PRICE', // description
      };
      break;

    case TypeOfStore.OUTLET_US:
      printRequestBody = {
        ...printRequestBody,
        price: retailPrice, // retail price
        sale: description ? 'SALE' : undefined,
        // salesText: description, // description?
      };
      break;

    // TypeOfStore.AIRPORT_US || TypeOfStore.RETAIL:
    default:
      printRequestBody = {
        ...printRequestBody,
        price: retailPrice, // retail price
      };
      break;
  }

  return {
    ...printRequestBody,
    ...additionalBody,
  };
};
