import React, { useState } from 'react';
import { useNotificationContext } from '@/context/notificationContext';
import { useSocketContext } from '@/context/socketContext';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import styled from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { UIAccordion } from '@/components/ui/Accordion';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography } from '@/components/ui/Typography';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CalendarToday from '@material-ui/icons/CalendarToday';

import { colors } from '@/theme';
import { DateTime } from 'luxon';
import { AppRoutes } from '@/app/routers';
import { isVirtualBuddy } from '@/utils/user';
import { NotificationService } from '@/api/receive';
import type { Process } from '@/types/notification';
import { NOTIFICATION } from '@/types/enum';
import { useAppDispatch } from '@/app/store';
import { fetchInventoryList } from '@/features/inventory/inventorySlice';

//#region - Styled Components
const StyledNotification = styled(UIBox)`
  width: 100%;
  background: #fbfbfb;
  border: 1px solid #ededed;
  box-shadow: 0px 0px 10px 0px #0000001a;
  border-radius: 4px;
  padding: 24px 24px 0px;
  margin-top: 24px;
`;

const StyledInnerNotification = styled(UIBox)`
  width: 100%;
  padding: 24px;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
`;

const StyledNotificationIcon = styled(NotificationsIcon)`
  width: 32px;
  height: 32px;
  margin: 0 16px;
`;

const StyledCalendarIcon = styled(CalendarToday)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const StyledText = styled(Typography)`
  &&& {
    color: ${colors.primary};
    text-transform: uppercase;
    margin-right: 24px;
    cursor: pointer;
  }
`;

const StyledDate = styled(Typography)`
  &&& {
    text-transform: capitalize;
    margin-right: 10px;
  }
`;
//#endregion

const NotificationBanner: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useSelector(state => state.user);
  const { pathname } = useLocation();
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState<string>('');
  const [errorSnackbarIsVisible, setErrorSnackbarVisibility] =
    useState<boolean>(false);
  const [isAccordionExpanded, setAccordionIsExpanded] =
    useState<boolean>(false);

  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const { setVirtualBuddyNotificationVisibility } = useSocketContext();
  const {
    inventoryRefused,
    notifications: notificationDetails,
    setNotificationBannerVisibility,
  } = useNotificationContext();

  const notifications = React.useMemo(
    () =>
      notificationDetails.filter(
        ({ typeProcess }) => typeProcess !== 'SLEX' && typeProcess !== 'RTPS'
      ),
    [notificationDetails]
  );

  const seeMoreClickHandler = async (): Promise<void> => {
    if (isVirtualBuddy(user)) {
      setNotificationBannerVisibility(false);
      setVirtualBuddyNotificationVisibility(false);

      if (pathname === AppRoutes.INVENTORY_VIRTUAL_BUDDY) {
        await dispatch(
          fetchInventoryList({
            storeCode: storeCode || '',
            isFiltered: false,
          })
        );

        return;
      }

      return history.push(AppRoutes.INVENTORY_VIRTUAL_BUDDY);
    }

    try {
      const idNotificationStores = notifications.flatMap(
        ({ idNotificationStore }) => idNotificationStore!
      );

      await NotificationService.notificationUpdateNotificationViewed({
        requestBody: {
          idNotificationStores,
        },
      });

      history.push(AppRoutes.NOTIFICATION);
    } catch (err) {
      setErrorSnackbarMessage(err as string);
      setErrorSnackbarVisibility(true);
    }
  };

  const hideClickHandler = (): void => {
    setNotificationBannerVisibility(false);

    if (isVirtualBuddy(user)) {
      setVirtualBuddyNotificationVisibility(false);
    }
  };

  const renderMessage = (
    processType: Process,
    isEmea: number,
    days: number
  ): JSX.Element => {
    const process = t(processType);

    switch (processType) {
      case NOTIFICATION.INVENTORY:
      case NOTIFICATION.REPRICING:
        return (
          <Typography font="heavy">
            {days > 1 && t('notification', { process, days })}
            {days === 1 && t('nextDaysNotification', { process })}
            {days === 0 && t('todayNotification', { process })}
          </Typography>
        );

      case NOTIFICATION.RECALL:
        if (isEmea === 0) {
          return (
            <Typography font="heavy">
              {days > 1 && t('beginsNotification.days', { process, days })}
              {days === 1 && t('beginsNotification.day', { process })}
              {days === 0 && t('beginsNotification.today', { process })}
            </Typography>
          );
        }

        return (
          <Typography font="heavy">
            {days > 1 && t('completedNotification.days', { process, days })}
            {days === 1 && t('completedNotification.day', { process })}
            {days === 0 && t('completedNotification.today', { process })}
          </Typography>
        );

      default:
        return (
          <Typography font="heavy">
            {t('notificationPrint', { process })}
          </Typography>
        );
    }
  };

  const renderActions =
    notifications.length === 1 || isAccordionExpanded || isVirtualBuddy(user);

  const renderDate = (dateNotification: string): string => {
    const dateFormat = dateNotification?.replaceAll('/', '-');

    return DateTime.fromISO(dateFormat).toFormat('yyyy LLLL dd', {
      locale: i18n.language,
    });
  };

  return (
    <>
      <StyledNotification flexDirection="column">
        <UIBox
          paddingBottom={3}
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <UIBox alignItems="center">
            {notifications.length > 1 && (
              <UIAccordion
                expanded={isAccordionExpanded}
                onClick={(): void => setAccordionIsExpanded(prev => !prev)}
              />
            )}
            <StyledNotificationIcon htmlColor="#F6BB3B" />
            <UIBox flexDirection="column">
              {notifications.length > 1 ? (
                <Typography font="heavy">{t('taskAdded')}</Typography>
              ) : (
                <>
                  {inventoryRefused && (
                    <Typography font="heavy" margin="0 0 8px">
                      {t('inventoryRefusedNotification')}
                    </Typography>
                  )}
                  {notifications[0] ? (
                    <>
                      <Typography font="heavy" margin="0 0 8px">
                        {renderMessage(
                          notifications[0].typeProcess as Process,
                          Number(notifications[0].isEmea),
                          Number(
                            notifications[0].remainingDaysToPerformActivity
                          )
                        )}
                      </Typography>
                      <Typography>
                        {renderDate(notifications[0].dateNotification)}
                      </Typography>
                    </>
                  ) : (
                    <>
                      {isVirtualBuddy(user) && (
                        <Typography font="heavy">
                          {t('inventoriesAvailable')}
                        </Typography>
                      )}
                    </>
                  )}
                </>
              )}
            </UIBox>
          </UIBox>
          {renderActions && (
            <UIBox marginLeft={12}>
              <StyledText onClick={hideClickHandler}>{t('hide')}</StyledText>
              <StyledText onClick={seeMoreClickHandler}>
                {t('seeMore')}
              </StyledText>
            </UIBox>
          )}
        </UIBox>
        {notifications.length > 1 && (
          <>
            {isAccordionExpanded && (
              <>
                {notifications.map(
                  (
                    {
                      dateNotification,
                      typeProcess,
                      isEmea,
                      remainingDaysToPerformActivity,
                    },
                    index
                  ) => (
                    <UIBox
                      key={`${dateNotification}-${typeProcess}-${index}`}
                      flexDirection="column"
                    >
                      <StyledInnerNotification>
                        <UIBox alignItems="center" marginLeft={8}>
                          <UIBox alignItems="center">
                            <StyledCalendarIcon htmlColor="grey" />
                            <StyledDate size="sm" color="grey">
                              {renderDate(dateNotification)}
                            </StyledDate>
                            {renderMessage(
                              typeProcess as Process,
                              Number(isEmea),
                              Number(remainingDaysToPerformActivity)
                            )}
                          </UIBox>
                        </UIBox>
                      </StyledInnerNotification>
                    </UIBox>
                  )
                )}
              </>
            )}
          </>
        )}
      </StyledNotification>
      <ErrorSnackbar
        open={errorSnackbarIsVisible}
        setIsOpen={setErrorSnackbarVisibility}
        errorMessage={errorSnackbarMessage}
      />
    </>
  );
};

export default NotificationBanner;
