import React from 'react';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ProductAccordionItem } from '@/components/layout/ProdocutAccordionItem';
import { Typography } from '@/components/ui/Typography';
import { UIBox } from '@/components/ui/Box';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import RecallListItemInfo from '../RecallListItem/RecallListItemInfo';
import { colors } from '@/theme';

import {
  StyledProductAccordion,
  StyledProductItemWrapper,
  StyledAccortionItemContent,
  StyledMessageWrapper,
  StyledMessage,
} from '../style';
import type { CustomRecallUpc } from '@/types/recallSlice';

const StyledFoundIcon = styled(CheckCircleIcon)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const StyledMissingIcon = styled(ErrorOutlineIcon)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const StyledProductAccordionItem = styled(ProductAccordionItem)`
  margin-bottom: 12px;
`;

const RecallNotFoundListItem: React.FC<CustomRecallUpc> = ({
  ...productInfo
}) => {
  const { t } = useTranslation();
  const { epcs, found, missing } = productInfo;
  const [isAccordionExpanded, toggleAccordion] = useToggle(false);

  return (
    <StyledProductItemWrapper>
      <RecallListItemInfo
        {...productInfo}
        type="NOT FOUND"
        scannedQuantity={found?.length || 0}
        accordion={{
          isVisible: epcs?.length! > 0,
          isExpanded: isAccordionExpanded,
          toggle: toggleAccordion,
        }}
      />
      {isAccordionExpanded && (
        <>
          <StyledMessageWrapper>
            {missing && missing.length > 0 && (
              <StyledMessage size="sm">
                {t('scanMissingItem', { missingItems: missing.length })}
              </StyledMessage>
            )}
          </StyledMessageWrapper>
          <StyledProductAccordion
            $v2
            items=""
            padding="0"
            expanded={isAccordionExpanded}
            toggleAccordion={toggleAccordion}
          >
            <>
              {missing && missing.length > 0 && (
                <UIBox flexDirection="column">
                  <UIBox mb={1} alignItems="center">
                    <StyledMissingIcon htmlColor="#F6BB3B" />
                    <Typography size="sm" color="grey">
                      {t('missing')}
                    </Typography>
                  </UIBox>
                  {missing?.map(epcCode => (
                    <StyledAccortionItemContent key={epcCode}>
                      <UIBox alignItems="center">
                        <StyledProductAccordionItem epcCode={epcCode} />
                      </UIBox>
                    </StyledAccortionItemContent>
                  ))}
                </UIBox>
              )}
              {found && found.length > 0 && (
                <UIBox flexDirection="column">
                  <UIBox mb={1} alignItems="center">
                    <StyledFoundIcon htmlColor={colors.green} />
                    <Typography size="sm" color="grey">
                      {t('found')}
                    </Typography>
                  </UIBox>
                  {found?.map(epcCode => (
                    <StyledAccortionItemContent key={epcCode}>
                      <UIBox alignItems="center">
                        <StyledProductAccordionItem epcCode={epcCode} />
                      </UIBox>
                    </StyledAccortionItemContent>
                  ))}
                </UIBox>
              )}
            </>
          </StyledProductAccordion>
        </>
      )}
    </StyledProductItemWrapper>
  );
};

export default RecallNotFoundListItem;
