import { FC, memo } from 'react';
import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';

import { Typography } from '@/components/ui/Typography';

import { TColorName, TFontSize } from '@/theme';

const StyledLink = styled(Link)<UILinkProps>`
  && {
    text-decoration: none;

    & > *:hover {
      color: ${({ theme }): string => theme.palette.text.disabled};
    }
  }
`;

export interface UILinkProps extends LinkProps {
  size?: TFontSize;
  color?: TColorName;
}

const UILink: FC<UILinkProps> = memo(
  ({ children, to, size, color = 'text', ...rest }) => (
    <StyledLink to={to} {...rest}>
      <Typography size={size} color={color}>
        {children}
      </Typography>
    </StyledLink>
  )
);

UILink.displayName = 'UILink';

export default UILink;
