import { FC, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import { useAsync, usePrevious, useUnmount, useUpdateEffect } from 'react-use';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';

import { ScanDevicesService } from '@/api';

import {
  getSalesProducts,
  removeSaleProduct,
  removeSaleProductEpc,
  readdDeletedProduct,
  readdDeletedProductEpc,
  confirmSaleProductsCheckout,
} from '@/features/sales/salesProductsSlice';
import {
  addDeviceInUse,
  deleteTags,
  removeTag,
  tagsMQTTDevice,
} from '@/features/devices/devicesSlice';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { UIList } from '@/components/ui/List';

import { CTAContainer } from '@/components/layout/CTAContainer';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import {
  ModalDataSaved,
  ModalDataSavedError,
} from '@/components/layout/ModalDataSaved';
import { ModalProductDetails } from '@/components/layout/ModalProductDetails';

import { Search as SearchIcon } from '@material-ui/icons';

import { EnumMode } from '@/types/enum';
import { useAppDispatch } from '@/app/store';
import { ScanDevice } from '@/types/device';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { PaginationList } from '@/components/layout/PaginationList';
import {
  ApiError,
  ElasticHandlerRestService,
  CheckoutItem,
} from '@/api/receive';
import { AppRoutes } from '@/app/routers';
import useSROTrackEvent from '@/hooks/useSROTrackEvent';

import {
  StyledProductsWrapper,
  StyledAutoCompleteWrapper,
  StyledTitle,
  StyledAutoComplete,
  StyledNotificationButton,
  SearchButton,
  StyledExceptionUpc,
  StyledExceptionUpcList,
} from './style';
import { sum } from '@/utils';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { UIButton, UIButtonWithIcon } from '@/components/ui/Button';
import useHandleProcess from '@/hooks/useHandleProcess';
import { ScannedProductItemSell } from '@/components/layout/ScannedProductItemSell';
import RefreshIcon from '@material-ui/icons/Refresh';

export const PageSaleItems: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const appInsights = useAppInsightsContext();
  const handleProcess = useHandleProcess();
  const autocompleteInputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { store } = useSelector(state => state.currentStore);
  const { storeCode: StoreCode } = store || {};
  const { username } = useSelector(({ user }) => user);
  const { deviceInUse, tags, deleteTagsHasError } = useSelector(
    ({ devices }) => devices
  );

  const { products, error, isLoading } = useSelector(
    state => state.saleProducts
  );
  const [isConfirmModalVisible, setConfirmModalVisibility] =
    useState<boolean>(false);
  const [openScanModal, setScanModalVisibility] = useState(true);
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const [isScanning, setIsScanning] = useState(false);
  const [openProductModal, setProdcutModalVisibility] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [epcCode, setEpcCode] = useState<string>('');
  const [epcsList, setEpcList] = useState<string[]>([]);
  const [isScanningAgain, setIsScanningAgain] = useState<boolean>(false);
  const [snackbarKeys, setSnackbarKeys] = useState<(string | number)[]>([]);
  const [deviceError, setDeviceError] = useState<boolean>(false);
  const [deviceErrorMsg, setDeviceErrorMsg] = useState<string>();
  const [finishButtonClicked, setFinishButtonClicked] =
    useState<boolean>(false);
  const [modalExceptionsIsVisible, setModalExceptionsVisibility] =
    useState<boolean>(false);
  const [confirmButtonClicked, setConfirmButtonClicked] =
    useState<boolean>(false);
  const [confirmErrorStatus, setConfirmErrorStatus] = useState<number>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [addEpc, setAddEpc] = useState<boolean>(false);
  const [exceptionsUpcs, setExceptionsUpcs] = useState<string[]>([]);

  const productsCount = sum(products?.map(({ epcs }) => epcs?.length));

  const oldProdCount = usePrevious(productsCount);

  const IdCheckout = `${username}-${sessionStorage.getItem('idCheckout')}`;

  const User = username;

  const trackSaleProducts = useSROTrackEvent(appInsights, 'Checkout', {
    User,
    StoreCode,
    IdCheckout: '',
    FoundItems: productsCount,
  });

  const trackRemoveUpc = useSROTrackEvent(appInsights, 'Checkout Remove UPC', {
    User,
    StoreCode,
    EPC: '',
    UPC: '',
    LinkedEvent: IdCheckout,
  });

  const trackRemoveEpc = useSROTrackEvent(appInsights, 'Checkout Remove EPC', {
    User,
    StoreCode,
    UPC: '',
    EPC: '',
    LinkedEvent: IdCheckout,
  });

  const trackConfirmCheckout = useSROTrackEvent(
    appInsights,
    'Confirm Checkout',
    {
      User,
      StoreCode,
      UPC: '',
      EPC: '',
      FoundItems: 0,
      IdCheckout,
    }
  );

  useAsync(async () => {
    if (tags && tags.length > 0) {
      sessionStorage.setItem('saleTags', JSON.stringify(tags));
      await dispatch(getSalesProducts({ tags }));

      if (openScanModal) {
        setScanModalVisibility(false);
      }
    }
  }, [dispatch, tags.length]);

  useEffect(() => {
    if (deleteTagsHasError) {
      setDeviceError(true);
      setDeviceErrorMsg(deleteTagsHasError.body.message);
    }
  }, [deleteTagsHasError]);

  useUnmount(() =>
    snackbarKeys.forEach(snackbarKey => closeSnackbar(snackbarKey))
  );

  useEffect(() => sessionStorage.setItem('idCheckout', uuidv4()), []);

  useEffect(() => {
    if (error) {
      setIsErrorVisible(true);
    }
  }, [error]);

  useEffect(() => {
    let timeoutHandle: number;

    if (isConfirmModalVisible) {
      timeoutHandle = window.setTimeout(
        () => history.push(AppRoutes.INTRO),
        5000
      );
    }

    return (): void => clearTimeout(timeoutHandle);
  }, [isConfirmModalVisible, history]);

  useEffect(() => {
    if (products.length > 0) {
      trackSaleProducts({
        User,
        StoreCode,
        IdCheckout,
        FoundItems: productsCount,
      });
    }
    // ? Disabled for possibile unwanted side effects 😦
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.length]);

  useEffect(() => {
    if (epcCode.length === 0) {
      setEpcList([]);
    }
  }, [epcCode]);

  const getEpcs = async (): Promise<void> => {
    if (epcCode.length >= 4) {
      try {
        const { epcList } =
          await ElasticHandlerRestService.elastichandlerSearchEpcAutocomplete({
            epcCode,
          });

        if (epcList) {
          if (epcList?.length > 0) {
            const list = epcList.map(({ epcCode }) => (epcCode ? epcCode : ''));

            return setEpcList(list);
          }
        }

        return setEpcList([]);
      } catch (e) {
        console.log('error', e);
      }
    }
  };

  const onAutoCompleteOptionSelect = (epc: string | null): void => {
    setAddEpc(true);
    if (epc) {
      dispatch(tagsMQTTDevice([{ epc }]));
    }
  };

  useUpdateEffect(() => {
    if (addEpc && productsCount === oldProdCount) {
      setOpenSnackbar(true);
    }
  }, [products]);

  const searchInputHandler: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => setEpcCode(value);

  const onCloseModalClick = (): void => {
    setConfirmModalVisibility(false);
    history.push(AppRoutes.INTRO);
  };

  const onConfirmClick = async (): Promise<void> => {
    setConfirmButtonClicked(true);

    const upcCodes = products.map(({ upcCode }) => upcCode);
    const epcCodes = products
      .map(({ epcs }) => epcs?.flatMap(({ epcCode }) => epcCode!) || [])
      .flat();
    const storage = sessionStorage.getItem('deviceInUse');

    if (storage) {
      const deviceInStorage = JSON.parse(storage) as Required<ScanDevice>;
      await dispatch(addDeviceInUse(deviceInStorage));
      await dispatch(deleteTags());
    }

    try {
      const response = await dispatch(
        confirmSaleProductsCheckout(epcCodes)
      ).unwrap();

      if (response.upcs && response.upcs.length > 0) {
        setModalExceptionsVisibility(true);
        setExceptionsUpcs(response.upcs);
      } else {
        setConfirmModalVisibility(true);
      }

      trackConfirmCheckout({
        User,
        StoreCode,
        UPC: upcCodes?.join(', '),
        EPC: epcCodes?.join(', '),
        FoundItems: epcCodes?.length || 0,
        IdCheckout,
      });
    } catch (err) {
      const error = err as ApiError;

      if (error.status === 406) {
        setExceptionsUpcs(error.body?.upcs || []);
        setConfirmErrorStatus(406);
        setModalExceptionsVisibility(true);
      }

      setConfirmButtonClicked(false);
    }
  };

  const onFinishScanClick = async (): Promise<void> => {
    try {
      const storage = sessionStorage.getItem('deviceInUse');

      if (!storage) return;

      const deviceInStorage = JSON.parse(storage) as Required<ScanDevice>;
      const devId = deviceInUse?.deviceId! || deviceInStorage.deviceId;

      setFinishButtonClicked(true);

      const { tags, mode } = await ScanDevicesService.getTags({ devId });

      if (mode === 'no mode') {
        setFinishButtonClicked(false);
        setDeviceErrorMsg(t('error.deviceCommunication'));
        return setDeviceError(true);
      }

      if (tags && tags.length > 0) {
        dispatch(tagsMQTTDevice(tags));
        sessionStorage.setItem(
          'saleTags',
          JSON.stringify(tags.map(({ epc }) => epc))
        );
      }

      setTimeout(async () => {
        const { code, message } = await ScanDevicesService.activateScanning({
          requestBody: { dev_id: devId, enable: 'False' },
        });

        if (code !== 'OK') {
          setDeviceErrorMsg(message);
          setFinishButtonClicked(false);
          return setDeviceError(true);
        }
      }, 1000);

      setTimeout(async () => {
        await dispatch(deleteTags());
        setIsScanning(false);
        setFinishButtonClicked(false);
      }, 2000);
    } catch (err) {
      setFinishButtonClicked(false);
      setDeviceError(true);
    }
  };

  const onScanClick = (): void => {
    setIsScanningAgain(true);
    setScanModalVisibility(true);
  };

  const onQuitClick = async (): Promise<void> => {
    history.push(AppRoutes.INTRO);
  };

  const searchClick = (): void => {
    autocompleteInputRef.current?.focus();
  };

  const onProductClick = (e: React.MouseEvent, upcCode: string): void => {
    e.preventDefault();

    const target = e.target as HTMLElement;

    if (
      typeof target.className === 'object' ||
      target.nextElementSibling instanceof SVGElement ||
      target.className.includes('Accordion')
    )
      return;

    setSelectedProduct(upcCode);
    setProdcutModalVisibility(true);
  };

  const handleRemoveSaleProduct = (upcCode: string, epcCode: string): void => {
    const snackbarKey = enqueueSnackbar(t('sale.removeArticle'), {
      action: (
        <StyledNotificationButton
          label={t('cancel')}
          outlined
          onClick={(): void => {
            setAddEpc(false);
            dispatch(readdDeletedProduct(upcCode));
            dispatch(tagsMQTTDevice([{ epc: epcCode }]));
            closeSnackbar(snackbarKey);
          }}
        />
      ),
    });

    setSnackbarKeys(prevState => [...prevState, snackbarKey]);
    dispatch(removeSaleProduct(upcCode));
    dispatch(removeTag(epcCode));

    trackRemoveUpc({
      User,
      StoreCode,
      EPC: epcCode,
      UPC: upcCode,
      LinkedEvent: IdCheckout,
    });
  };

  const handleRemoveSaleEpc = (upcCode: string, epcCode: string): void => {
    setAddEpc(false);

    const snackbarKey = enqueueSnackbar(t('sale.removeArticle'), {
      action: (
        <StyledNotificationButton
          label={t('cancel')}
          outlined
          onClick={(): void => {
            dispatch(readdDeletedProductEpc({ upcCode, epcCode }));
            dispatch(tagsMQTTDevice([{ epc: epcCode }]));
            closeSnackbar(snackbarKey);
          }}
        />
      ),
    });

    setSnackbarKeys(prevState => [...prevState, snackbarKey]);
    dispatch(removeSaleProductEpc({ upcCode, epcCode }));
    dispatch(removeTag(epcCode));
    trackRemoveEpc({
      User,
      StoreCode,
      UPC: upcCode,
      EPC: epcCode,
      LinkedEvent: IdCheckout,
    });
  };

  const goToSaleExceptions = async (): Promise<void> => {
    await handleProcess({ process: 'SLEX', isLocked: true });
    history.push(AppRoutes.SALE_EXCEPTIONS);
  };

  return (
    <>
      <ModalScanDetailsV2
        open={openScanModal}
        onClose={(): void => setScanModalVisibility(false)}
        defaultMode={EnumMode.INVENTORY}
        disabledModes={[EnumMode.FIND]}
        setIsScanCompleted={setIsScanning}
        isScanningAgain={isScanningAgain}
      />

      <ModalProductDetails
        open={openProductModal}
        onClose={(): void => setProdcutModalVisibility(false)}
        productCode={selectedProduct}
      />
      <ModalDataSavedError
        $minWidth="605px"
        $minHeight="420px"
        open={modalExceptionsIsVisible}
        iconType="ERROR"
        title={t('payAttention')}
        message={
          confirmErrorStatus === 406
            ? t('allUpcsAreExpcetions')
            : t('exceptionsOccuredForSelectedUpc')
        }
        onClose={(): void => history.push(AppRoutes.INTRO)}
      >
        <StyledExceptionUpcList>
          {exceptionsUpcs.map(upc => (
            <StyledExceptionUpc key={upc}>{upc}</StyledExceptionUpc>
          ))}
        </StyledExceptionUpcList>
        <UIButton
          outlined
          label={t('goToSaleExceptions')}
          onClick={goToSaleExceptions}
        />
      </ModalDataSavedError>

      <StyledAutoCompleteWrapper>
        <StyledTitle>{t('searchEpcManually')}</StyledTitle>

        <StyledAutoComplete
          disableClearable
          rounded
          width={350}
          inputRef={autocompleteInputRef}
          minSearchTermLength={4}
          label={t('searchByEpc')}
          searchTerm={epcCode}
          options={epcsList}
          getOptions={getEpcs}
          onChange={searchInputHandler}
          loadingText={t('searching')}
          noOptionsText={t('error.no_results_available')}
          onOptionSelect={(_e, epc): void => onAutoCompleteOptionSelect(epc)}
          inputProps={{ maxLength: 24, minLength: 24 }}
        />

        <SearchButton
          onClick={searchClick}
          label={t('search')}
          startIcon={<SearchIcon />}
        />
      </StyledAutoCompleteWrapper>

      <StyledProductsWrapper>
        <UIList
          title=""
          itemsCount={
            <Trans
              i18nKey="itemsFound"
              values={{ itemsFound: productsCount }}
            />
          }
          itemsWarning={
            <UIButtonWithIcon
              label={t('refresh')}
              loading={isLoading}
              startIcon={<RefreshIcon />}
              onClick={async (): Promise<void> => {
                await dispatch(getSalesProducts({ tags }));
              }}
            />
          }
          rounded
          backgrounded
          shadowed
        >
          <PaginationList
            data={products}
            pageSize={10}
            renderItem={(
              props: NonNullable<CheckoutItem>,
              index: number
            ): JSX.Element => (
              <ScannedProductItemSell
                $sale
                productItem={props}
                handleRemoveSaleEpc={handleRemoveSaleEpc}
                handleRemoveSaleProduct={handleRemoveSaleProduct}
                key={`${props.brandCode}-${index}-productItem`}
                onClick={(e: React.MouseEvent): void =>
                  onProductClick(e, props.upcCode!)
                }
                disableActions={isScanning}
              />
            )}
          />
        </UIList>
      </StyledProductsWrapper>

      <ErrorSnackbar
        open={isErrorVisible}
        setIsOpen={setIsErrorVisible}
        errorMessage={
          error && error.body && typeof error.body === 'string'
            ? error.body
            : t('error.sale_products')
        }
      />

      <ErrorSnackbar
        open={deviceError}
        setIsOpen={setDeviceError}
        errorMessage={deviceErrorMsg}
      />

      <ModalDataSaved
        open={isConfirmModalVisible}
        onClose={onCloseModalClick}
        message={t('modal.datasaved')}
      />

      {isScanning ? (
        <CTAContainer
          type="FINISH SCAN"
          disabled={false}
          onClick={onFinishScanClick}
          loading={finishButtonClicked}
        />
      ) : (
        <CTAContainer
          type="DEFAULT"
          disabled={confirmButtonClicked}
          disabledConfirm={confirmButtonClicked || products.length === 0}
          onQuitClick={onQuitClick}
          onConfirmClick={onConfirmClick}
          onScanClick={onScanClick}
          loadingConfirm={confirmButtonClicked}
        />
      )}

      <AlertSnackbar
        open={openSnackbar}
        message={t('cycleCount.otherStore.message', { type: 'EPC' })}
        setIsOpen={setOpenSnackbar}
      />
    </>
  );
};

export default PageSaleItems;
