import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

import { ProductImage } from '@/components/ui/ProductImage';
import { UIButton } from '@/components/ui/Button';
import { TColorName } from '@/theme';

export const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

export const StyledSubContainer = styled.div`
  padding: 3rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const StyledClose = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const StyledProductImage = styled(ProductImage)`
  border: 1px solid ${({ theme }): TColorName => theme.palette.colors.lightGrey};
  border-radius: 5px;
`;

export const StyledPrintButton = styled(UIButton)`
  margin-top: 1rem;
`;
