import styled from 'styled-components';
import { UIBox } from '../Box';

export const SelectAll = styled(UIBox)<{ disabled?: boolean }>`
  cursor: ${({ disabled }): string => (disabled ? 'auto' : 'pointer')};
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'auto')};

  span {
    text-decoration: underline;
    color: ${({ disabled }): string =>
      disabled ? 'grey' : '#005192'} !important;
  }
`;

export const Span = styled(UIBox)`
  && {
    display: block;
    color: gray;
    margin-left: 24px;
  }
`;
