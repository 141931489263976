/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableSalesOrgAndMovementTypeCodesByProcessResponse } from '../models/AvailableSalesOrgAndMovementTypeCodesByProcessResponse';
import type { CheckGroupTitleBooleanObject } from '../models/CheckGroupTitleBooleanObject';
import type { CreateGroupRequest } from '../models/CreateGroupRequest';
import type { CreateGroupRequestEncoded } from '../models/CreateGroupRequestEncoded';
import type { DetailedGroup } from '../models/DetailedGroup';
import type { GroupList } from '../models/GroupList';
import type { MovementTypeCodesRequest } from '../models/MovementTypeCodesRequest';
import type { Processes } from '../models/Processes';
import type { ReasonCodesListResponse } from '../models/ReasonCodesListResponse';
import type { SalesOrganizationListRequest } from '../models/SalesOrganizationListRequest';
import type { StoreListResponse } from '../models/StoreListResponse';
import type { UpdateGroupRequest } from '../models/UpdateGroupRequest';
import type { UpdateGroupRequestEncoded } from '../models/UpdateGroupRequestEncoded';
import type { UploadReasonCodespRequest } from '../models/UploadReasonCodespRequest';
import { request as __request } from '../core/request';

export class ReasonCodeService {
  /**
   * Loads all available processes
   * Loads all available processes
   * @returns Processes OK
   * @throws ApiError
   */
  public static async reasoncodesGetAllProcesses(): Promise<Processes> {
    const result = await __request({
      method: 'GET',
      path: `/reasoncodes/getAllProcesses`,
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Loads all groups
   * Load all groups
   * @returns GroupList OK
   * @throws ApiError
   */
  public static async reasoncodesLoadAllGroups({
    storeCode,
  }: {
    /** use this field only when calling this service for store details from the control panel **/
    storeCode?: any;
  }): Promise<GroupList> {
    const result = await __request({
      method: 'GET',
      path: `/reasoncodes/findAllGroups`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * finds a single group data by job title
   * finds a single group data by job title
   * @returns DetailedGroup OK
   * @throws ApiError
   */
  public static async reasoncodesFindGroupByGroupTitle({
    groupTitle,
  }: {
    /** filter for groupTitle **/
    groupTitle: any;
  }): Promise<DetailedGroup> {
    const result = await __request({
      method: 'GET',
      path: `/reasoncodes/findGroupByGroupTitle`,
      query: {
        groupTitle: groupTitle,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * checks if a group title already exists
   * Checks if a group title already exists. If exists True is returned, False otherwise
   * @returns CheckGroupTitleBooleanObject true if group title is already used, false otherwise
   * @throws ApiError
   */
  public static async reasoncodesCheckGroupTitleUniqueName({
    groupTitle,
  }: {
    /** filter for groupTitle **/
    groupTitle: any;
  }): Promise<CheckGroupTitleBooleanObject> {
    const result = await __request({
      method: 'GET',
      path: `/reasoncodes/checkGroupTitleUniqueName`,
      query: {
        groupTitle: groupTitle,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * finds all reasonCodes with specific MovementTypeCodes
   * finds all reasonCodes with specific MovementTypeCodes
   * @returns ReasonCodesListResponse OK
   * @throws ApiError
   */
  public static async reasoncodesSearchReasonCodesByMovementTypeCodes({
    requestBody,
  }: {
    requestBody: MovementTypeCodesRequest;
  }): Promise<ReasonCodesListResponse> {
    const result = await __request({
      method: 'POST',
      path: `/reasoncodes/searchReasonCodesByMovementTypeCodes`,
      body: requestBody,
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Finds all available sales organizations and movement type codes for a specific process
   * Finds all sales organizations and movement type codes for a specific process. Available organizations are not already in use for the same process in a different group. Available movement type codes are hardcoded on database
   * @returns AvailableSalesOrgAndMovementTypeCodesByProcessResponse OK
   * @throws ApiError
   */
  public static async reasoncodesGetAvailableSalesOrgAndMovementTypeCodesByProcess({
    processName,
  }: {
    /** input process name **/
    processName: any;
  }): Promise<AvailableSalesOrgAndMovementTypeCodesByProcessResponse> {
    const result = await __request({
      method: 'GET',
      path: `/reasoncodes/getAvailableSalesOrgAndMovementTypeCodesByProcess`,
      query: {
        processName: processName,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * from a list of SalesOrganizations returns all stores that belong in that sales
   * from a list of SalesOrganizations returns all stores that belong in that sales
   * @returns StoreListResponse OK
   * @throws ApiError
   */
  public static async reasoncodesSearchStoresBySalesOrganizations({
    requestBody,
  }: {
    requestBody: SalesOrganizationListRequest;
  }): Promise<StoreListResponse> {
    const result = await __request({
      method: 'POST',
      path: `/reasoncodes/searchStoresBySalesOrganizations`,
      body: requestBody,
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * create new group
   * create new group
   * @returns any Group created
   * @throws ApiError
   */
  public static async reasoncodesCreateGroup({
    requestBody,
  }: {
    requestBody: CreateGroupRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/reasoncodes/createGroup`,
      body: requestBody,
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * create new group
   * create new group
   * @returns any Group created
   * @throws ApiError
   */
  public static async reasoncodesCreateGroupEncoded({
    requestBody,
  }: {
    requestBody: CreateGroupRequestEncoded;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/reasoncodes/createGroupEncoded`,
      body: requestBody,
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * update existing group
   * update existing group
   * @returns any Group updated
   * @throws ApiError
   */
  public static async reasoncodesUpdateGroup({
    requestBody,
  }: {
    requestBody: UpdateGroupRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/reasoncodes/updateGroup`,
      body: requestBody,
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * update existing group
   * update existing group
   * @returns any Group updated
   * @throws ApiError
   */
  public static async reasoncodesUpdateGroupEncoded({
    requestBody,
  }: {
    requestBody: UpdateGroupRequestEncoded;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/reasoncodes/updateGroupEncoded`,
      body: requestBody,
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * delete a group
   * This REST deletes a group.
   * @returns any Group successfully deleted.
   * @throws ApiError
   */
  public static async reasoncodesDeleteGroup({
    groupTitle,
  }: {
    /** group title **/
    groupTitle: any;
  }): Promise<any> {
    const result = await __request({
      method: 'DELETE',
      path: `/reasoncodes/deleteGroup`,
      query: {
        groupTitle: groupTitle,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * upload file with reason codes
   * Upload file containing Movement Type Codes, Movement Reason Code Type and Reason Codes
   * @returns any File uploaded successfully
   * @throws ApiError
   */
  public static async reasoncodesLoadReasonCodesFile({
    requestBody,
  }: {
    requestBody: UploadReasonCodespRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/reasoncodes/loadReasonCodesFile`,
      body: requestBody,
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
