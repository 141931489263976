import styled from 'styled-components';
import { useNotificationContext } from '@/context/notificationContext';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import { NotificationResponse } from '@/api/receive';

type ReturnType = {
  exceptionsOccurred: boolean;
  ExceptionsIcon: React.FunctionComponent;
};

const StyledButtonIcon = styled('div')`
  position: relative;

  svg {
    display: flex;
    margin-top: -2px;
    margin-bottom: auto;
  }

  &::after {
    content: '';
    position: absolute;
    right: 2px;
    top: 0;
    width: 8px;
    height: 8px;
    background: red;
    border-radius: 100px;
  }
`;

const useExpectionsNotification = (
  process: NotificationResponse['typeProcess']
): ReturnType => {
  const { notifications } = useNotificationContext();

  const exceptionsOccurred =
    notifications.findIndex(({ typeProcess }) => typeProcess === process) > -1;

  const ExceptionsIcon = (): JSX.Element => (
    <StyledButtonIcon>
      <NotificationsNoneOutlinedIcon />
    </StyledButtonIcon>
  );

  return {
    exceptionsOccurred,
    ExceptionsIcon,
  };
};

export default useExpectionsNotification;
