import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';
import { getMigrationStatus } from '@/features/poc/pocSlice';
import styled from 'styled-components';

import { PageLoader } from '@/components/ui/PageLoader';
import { Typography } from '@/components/ui/Typography';
import { UIButton } from '@/components/ui/Button';
import { UIBox } from '@/components/ui/Box';
import { theme } from '@/theme';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { ModalScanDetails } from '@/components/layout/ModalScanDetails';
import { AppRoutes } from '@/app/routers';
import { EnumMode } from '@/types/enum';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { useTranslation } from 'react-i18next';

const SyledContainer = styled(UIBox)`
  max-width: ${theme.breakpoints.values.lg}px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 52px 0 67px;
  }
`;

const PagePoc: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [openModalScan, setOpenModalScanVisbility] = useState<boolean>(false);
  const [alertSnackbarIsVisible, setAlertSnackbarVisiblity] =
    useState<boolean>(false);
  const {
    migrationStatusIsLoading,
    migrationIsEnabled,
    migrationStatusHasError,
  } = useSelector(state => state.poc);
  const { store } = useSelector(state => state.currentStore);

  useBreadcrumbs([
    {
      title: t('page.poc'),
    },
  ]);

  useEffect(() => {
    if (store?.storeCode) {
      dispatch(getMigrationStatus());
    }
  }, [dispatch, store?.storeCode]);

  useEffect(() => {
    if (migrationStatusHasError) {
      setAlertSnackbarVisiblity(true);
    }
  }, [migrationStatusHasError]);

  if (migrationStatusIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalScanDetails
        open={openModalScan}
        hideModes={[EnumMode.FIND]}
        onClose={(): void => setOpenModalScanVisbility(false)}
        nextPageURL={AppRoutes.POC_DATA}
      />
      <SyledContainer>
        <Typography align="center" as="h1">
          {t('menu.title')}
        </Typography>
        <UIButton
          label={t('poc.data.migration')}
          disabled={!migrationIsEnabled}
          onClick={(): void => setOpenModalScanVisbility(true)}
        />
      </SyledContainer>
      {migrationStatusHasError && (
        <AlertSnackbar
          open={alertSnackbarIsVisible}
          setIsOpen={(): void => setAlertSnackbarVisiblity(false)}
          message={
            migrationStatusHasError.status === 400
              ? migrationStatusHasError.body
              : migrationStatusHasError.statusText
          }
        />
      )}
    </>
  );
};

export default PagePoc;
