import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import PaginationList from '@/components/layout/PaginationList/PaginationList';
import { useDebounce } from 'react-use';

import {
  findStoreToTransfer,
  selectStore,
} from '@/features/s2s/storeToStoreSlice';

import { palette } from '@/theme';
import { StoreList } from '@/api';
import { PageLoader } from '@/components/ui/PageLoader';
import { SearchBar } from '@/components/layout/SearchBar';
import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { useUpdateEffect } from 'react-use';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import { EnumMode } from '@/types/enum';
import { AppRoutes } from '@/app/routers';
import { UIButtonWithIcon } from '@/components/ui/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      height: 44,
    },
  })
);

//#region - Styled Components
const StyledHeader = styled(UIBox)`
  width: 100%;
  padding: 35px 24px 5px;
  justify-content: space-between;
`;

const StyledStoreItem = styled(UIBox)`
  width: 100%;
  padding: 24px 66px;
  margin: 18px 0;
  border-radius: 4px;
  background: ${palette.colors.midGrey};
  cursor: pointer;
  border: 1px solid #dedede; // ? Add color in palette object

  &:first-child {
    margin-top: 24px;
  }
`;

const StyledContentWrapper = styled(UIBox)`
  display: grid;
  grid-template-columns: 400px 1fr;
`;

const StyledStoreDescription = styled(UIBox)`
  flex-direction: column;

  & > * {
    margin: 0;

    h3 {
      margin: 8px 0;
    }
  }
`;

const RefreshBtnContainer = styled('div')`
  width: 100%;
  padding-left: 35px;
`;

//#endregion

const PageStoreToStore: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [storeFilter, setStoreFilter] = useState<string>('');
  const [openScanModal, setScanModalVisibility] = useState<boolean>(false);
  const [alertSnackbarIsVisible, setAlertSnackbarVisibility] =
    useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);

  const { stores, storesIsLoading } = useSelector(state => state.s2s);
  const [storeList, setStoreList] = useState<NonNullable<StoreList['stores']>>(
    []
  );

  useEffect(() => {
    dispatch(findStoreToTransfer());
  }, [dispatch, refresh]);

  useEffect(() => {
    if (stores.length > 0) setStoreList(stores);
  }, [stores]);

  useUpdateEffect(() => {
    if (storeList.length === 0) {
      setAlertSnackbarVisibility(true);
    }
  }, [storeList.length]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setStoreFilter(e.currentTarget.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      handleSearch();
      e.preventDefault();
    }
  };

  const handleSearch = (): void => {
    if (storeFilter !== '') {
      setStoreList(
        stores.filter(({ storeCode, storeDescription, storeAddress }) => {
          if (
            storeCode?.toLowerCase().includes(storeFilter.toLowerCase().trim())
          )
            return true;
          if (
            storeDescription
              ?.toLowerCase()
              .includes(storeFilter.toLowerCase().trim())
          )
            return true;
          if (
            storeAddress
              ?.toLowerCase()
              .includes(storeFilter.toLowerCase().trim())
          )
            return true;
          return false;
        })
      );
    } else {
      setStoreList(stores);
    }
  };

  useDebounce(
    () => {
      if (storeFilter.length >= 3) {
        handleSearch();
      }

      if (storeFilter.length === 0) {
        setStoreList(stores);
      }
    },
    1000,
    [storeFilter]
  );

  const onStoreClick = ({
    storeCode,
    storeAddress,
  }: {
    storeCode?: string;
    storeAddress?: string;
  }): void => {
    setScanModalVisibility(true);
    if (storeCode && storeAddress) {
      dispatch(selectStore({ code: storeCode, address: storeAddress }));
    }
  };

  if (storesIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalScanDetailsV2
        resetDeviceInUse
        open={openScanModal}
        hideModes={[EnumMode.FIND]}
        onClose={(): void => setScanModalVisibility(false)}
        nextPageURL={AppRoutes.STORE_TRANSFER_SALE}
      />
      <StyledHeader>
        <Typography font="heavy" size="lg">
          {t('page.store_to_store_transfer')}
        </Typography>
        <SearchBar
          label={t('search')}
          value={storeFilter}
          onSearch={handleSearch}
          onKeyPress={handleKeyPress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleSearchChange(e)
          }
          placeholder={t('searchByStore')}
          InputProps={{
            className: classes.input,
            endAdornment: storesIsLoading && (
              <CircularProgress color="inherit" size={20} />
            ),
          }}
          disabled={storeFilter.length >= 1 && storeFilter.length < 3}
          loading={false}
          required
        />
      </StyledHeader>
      <RefreshBtnContainer>
        <UIButtonWithIcon
          label={t('refresh')}
          startIcon={<RefreshIcon />}
          onClick={(): void => setRefresh(refresh + 1)}
        />
      </RefreshBtnContainer>
      <UIBox width="100%" flexDirection="column" marginBottom="110px">
        <PaginationList
          data={storeList.slice().sort((a, b) => {
            if (a.storeCode && b.storeCode) {
              if (a.storeCode > b.storeCode) {
                return 1;
              }
              if (a.storeCode < b.storeCode) {
                return -1;
              }
            }
            return 0;
          })}
          pageSize={100}
          renderItem={({
            storeCode,
            storeDescription,
            storeAddress,
          }: NonNullable<StoreList['stores']>[number]): JSX.Element => (
            <StyledStoreItem
              key={`${storeCode}-store`}
              onClick={(): void =>
                onStoreClick({
                  storeCode,
                  storeAddress,
                })
              }
            >
              <StyledContentWrapper>
                <StyledStoreDescription>
                  <Typography size="lg" as="h1" font="heavy">
                    {storeDescription}
                    <Typography size="lg" as="h3" font="roman">
                      {storeCode}
                    </Typography>
                  </Typography>
                </StyledStoreDescription>
                <UIBox>
                  <Typography font="book">{storeAddress}</Typography>
                </UIBox>
              </StyledContentWrapper>
            </StyledStoreItem>
          )}
        ></PaginationList>
      </UIBox>
      <CTAContainer
        type="ONE_BUTTON"
        label={t('quit')}
        onClick={(): void => history.push(AppRoutes.INTRO)}
      />

      <AlertSnackbar
        open={alertSnackbarIsVisible}
        setIsOpen={setAlertSnackbarVisibility}
        message={t('error.no_stores_available')}
      />
    </>
  );
};

export default PageStoreToStore;
