import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
.MuiButton-contained.Mui-disabled {
	background-color: rgba(146, 148, 150, 1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
	color: white;
}
`;

export default GlobalStyle;
