import { ApiError, PermissionObject } from '@/api/receive';
import { RootState } from '@/app/rootReducer';
import { useSelector } from '@/hooks/useSelector';

type PermissionType = {
  key: number;
  label: string;
  action: string;
  active: boolean;
};

export const ProfilingSelector = (): RootState['profiling'] =>
  useSelector(state => state.profiling);

export const RolesSelector = (): Array<string> =>
  useSelector(state => state.profiling?.rolesManagement?.roles || []);

export const RolesAreLoading = (): boolean =>
  useSelector(
    state => state.profiling?.rolesManagement?.rolesAreLoading || false
  );

export const RolesError = (): ApiError | undefined =>
  useSelector(
    state => state.profiling?.rolesManagement?.rolesError || undefined
  );

export const PermissionsSelector = (): PermissionObject[] =>
  useSelector(state => state.profiling?.rolesManagement?.permissions || []);

export const SplittedPermissionsSelector = (): {
  mainPermissions: PermissionType[];
  additionalPermissions: PermissionType[];
  mainPermissionAllSelected: boolean;
} => {
  const permissions = PermissionsSelector();

  const mainPermissions = permissions
    .filter(permission => permission.mainPermission === true)
    .map((permission, index) => ({
      key: index,
      label: permission.permissionName!,
      action: permission.action!,
      active: permission.enabled!,
    }));

  const additionalPermissions = permissions
    .filter(permission => permission.mainPermission === false)
    .map((permission, index) => ({
      key: index,
      label: permission.permissionName!,
      action: permission.action!,
      active: permission.enabled!,
    }));

  return {
    mainPermissions,
    additionalPermissions,
    mainPermissionAllSelected: mainPermissions.every(({ active }) => active),
  };
};

export const PermissionsError = (): ApiError | undefined =>
  useSelector(
    state => state.profiling?.rolesManagement?.permissionsError || undefined
  );

export const PermissionsAreLoading = (): boolean =>
  useSelector(
    state => state.profiling?.rolesManagement?.permissionsAreLoading || false
  );

export const CurrentRoleSelector = (): string =>
  useSelector(state => state.profiling?.rolesManagement?.currentRole || '');

export const UpdatingRoleSelector = (): {
  isUpdatingRole: boolean;
  isUpdatedRole: boolean;
  updateRoleError: ApiError | undefined;
} => {
  const { isUpdatingRole, isUpdatedRole, updateRoleError } = useSelector(
    state =>
      state.profiling?.rolesManagement || {
        isUpdatingRole: false,
        isUpdatedRole: false,
        updateRoleError: undefined,
      }
  );
  return { isUpdatingRole, isUpdatedRole, updateRoleError };
};
