import styled, { css, CSSProperties } from 'styled-components';
import { TablesHeader, TablesRow } from '@/components/layout/Tables';
import { TextField } from '@material-ui/core';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { theme } from '@/theme';
import { UIBox } from '@/components/ui/Box';

export const StyledField = styled(TextField)`
  min-width: 300px;
`;

export const StyledSearchBarLight = styled(SearchBarLight)`
  &&& {
    margin-left: 32px;
    padding: 0;
  }
`;

export const StyledStartAdornament = styled('div')`
  background: #f6f0f0;
  margin: 0 12px 0px -14px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 100%;
`;

export const gridStyles = css`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 110px) 150px repeat(3, 110px) auto;

  ${theme.breakpoints.down('md')} {
    gap: 16px;
  }
`;

export const StyledTableWrapper = styled(UIBox)`
  margin-top: 16px;
  margin-bottom: 100px;
  flex-direction: column;
`;

export const StyledTablesHeader = styled(TablesHeader)`
  ${gridStyles};
  padding: 8px 24px;
`;

export const StyledTablesRow = styled(TablesRow)`
  ${gridStyles};
  padding: 0 !important;
  align-items: center;

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      justify-self: end;
    }
  }
`;

export const StyledStatus = styled('div')<{
  color: NonNullable<CSSProperties['backgroundColor']>;
}>`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  margin-right: 8px;
  background-color: ${({ color }): string => color};
`;

export const StyledDateWrapper = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)<{
  $rotated: boolean;
}>`
  margin-left: 8px;
  transform: ${({ $rotated }): string =>
    $rotated ? 'rotate(180deg)' : 'rotate(0)'};
`;
