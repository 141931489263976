import { UIAccordion } from '@/components/ui/Accordion';
import { UIBox } from '@/components/ui/Box';
import styled from 'styled-components';

export const Grid = styled(UIBox)`
  display: grid;
  grid-template-columns: 250px 200px;
`;

export const Accordion = styled(UIAccordion)`
  height: 32px;
`;
