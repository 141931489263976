import { FC, useState } from 'react';
import { UIAccordion } from '@/components/ui/Accordion';
import { UICheckbox } from '@/components/ui/Checkbox';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {
  StyledAccordionItem,
  StyledDownloadWrapper,
  StyledFilterWrapper,
  StyledListGrid,
  StyledListGridItem,
  StyledMoreHorizDot,
} from './style';
import { Typography } from '@/components/ui/Typography';
import { MigrationStatusPruduction } from '@/api/receive';
import { useTranslation } from 'react-i18next';
import { UIButtonWithIcon } from '@/components/ui/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DateTime } from 'luxon';
import { STOREMIGRATORSTATUS } from '@/types/enum';

interface PageStoreMigratorRowProps {
  item: MigrationStatusPruduction;
  onCheckboxClick: (fileName: string) => void;
  onDownloadClick: (storeCode: string) => void;
  checked: boolean;
}

const PageStoreMigratorRow: FC<PageStoreMigratorRowProps> = ({
  item,
  onCheckboxClick,
  onDownloadClick,
  checked,
}) => {
  const { lastUpload, storeCode, status, fileName } = item;
  const [accordionExpanded, toggleAccordion] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const onClick = (fileName: string): void => {
    onCheckboxClick(fileName);
  };

  const dateFormat = DateTime.fromISO(lastUpload!).toFormat('yyyy LLLL dd', {
    locale: i18n.language,
  });

  return (
    <>
      <StyledListGrid $isAccordionExpanded={accordionExpanded}>
        <StyledListGridItem>
          <Typography font="medium">
            {status === STOREMIGRATORSTATUS.ERROR && (
              <UIAccordion
                expanded={accordionExpanded}
                onClick={(): void => toggleAccordion(prevState => !prevState)}
              />
            )}
            {status === STOREMIGRATORSTATUS.WAITING && (
              <UICheckbox
                checked={checked}
                onClick={(): void => onClick(fileName)}
              />
            )}
          </Typography>
        </StyledListGridItem>
        <StyledListGridItem>
          <Typography font="medium">{dateFormat}</Typography>
        </StyledListGridItem>
        <StyledListGridItem>
          <Typography font="medium">{storeCode}</Typography>
        </StyledListGridItem>
        <StyledListGridItem
          style={{ justifySelf: 'right', alignItems: 'center' }}
        >
          <Typography font="medium">{status}</Typography>&nbsp;
          {status === STOREMIGRATORSTATUS.ERROR && (
            <WarningIcon fontSize="medium" htmlColor="#E20404" />
          )}
          {status === STOREMIGRATORSTATUS.WAITING && (
            <StyledMoreHorizDot fontSize="medium" />
          )}
          {status === STOREMIGRATORSTATUS.LOADING && (
            <AutorenewIcon fontSize="medium" htmlColor="#005192" />
          )}
          {status === STOREMIGRATORSTATUS.COMPLETED && (
            <CheckCircleIcon fontSize="medium" htmlColor="#417505" />
          )}
        </StyledListGridItem>
      </StyledListGrid>
      {accordionExpanded && (
        <StyledAccordionItem>
          <StyledFilterWrapper>
            <Typography font="medium">
              {t('page.store_migrator.download_error_msg')}
            </Typography>
            <StyledDownloadWrapper>
              <UIButtonWithIcon
                label={t('page.store_migrator.download_error')}
                startIcon={<GetAppIcon />}
                onClick={(): void => onDownloadClick(storeCode!)}
              />
            </StyledDownloadWrapper>
          </StyledFilterWrapper>
        </StyledAccordionItem>
      )}
    </>
  );
};

export default PageStoreMigratorRow;
