import { FC, memo } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { RadioButtons } from './types';
import { isEmpty } from '@/utils/validation';

export const RadioButtonsGroup: FC<RadioButtons> = memo(
  ({
    title,
    radios,
    ariaLabel,
    name,
    defaultValue,
    formControlComponent = 'fieldset',
    formLabelComponent = 'legend',
    disabled,
    alignButtons,
    setValueDetails,
  }): JSX.Element => {
    return (
      <FormControl component={formControlComponent}>
        <FormLabel component={formLabelComponent}>{title}</FormLabel>
        {disabled &&
          radios?.find(radio => radio?.value === defaultValue)?.label}
        {!disabled && (
          <RadioGroup
            aria-label={ariaLabel}
            value={defaultValue}
            name={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const value = (event.target as HTMLInputElement).value;
              setValueDetails?.({
                name,
                currentVal: value,
                isEmpty: isEmpty(value),
              });
            }}
            style={
              alignButtons === 'row'
                ? {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }
                : {}
            }
          >
            {radios.map(({ value, label }, index) => (
              <FormControlLabel
                key={`radio-${index}`}
                value={value}
                control={<Radio />}
                label={label}
              />
            ))}
          </RadioGroup>
        )}
      </FormControl>
    );
  }
);

RadioButtonsGroup.displayName = 'RadioButtonsGroup';

export default RadioButtonsGroup;
