import { FC } from 'react';
import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { colors } from '@/theme';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface ReprintCounterProps {
  counter: number;
  onIncrementClick: () => void;
  onDecrementClick: () => void;
}

interface StyledReprintCounterProps {
  margin?: CSSProperties['margin'];
}

//#region - Styled Components
const StyledWrapper = styled(UIBox)<StyledReprintCounterProps>`
  align-items: center;
`;

const StyledAddIcon = styled(AddCircleOutline)<{ disabled?: boolean }>`
  cursor: ${({ disabled }): string => (disabled ? `auto` : 'pointer')};
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'auto')};
  fill: ${({ disabled }): string =>
    disabled ? `${colors.disabled}` : '#005192'};
`;

const StyledRemoveIcon = styled(RemoveCircleOutline)<{
  disabled?: boolean;
}>`
  cursor: ${({ disabled }): string => (disabled ? `auto` : 'pointer')};
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'auto')};
  fill: ${({ disabled }): string =>
    disabled ? `${colors.disabled}` : '#005192'};
`;

const StyledCounterContent = styled('input')`
  width: 60px;
  height: 42px;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  margin: 0 24px;
  text-align: center;
`;

//#endregion

const ReprintCounter: FC<ReprintCounterProps & StyledReprintCounterProps> = ({
  counter,
  onIncrementClick,
  onDecrementClick,
  ...styledProps
}) => {
  return (
    <StyledWrapper {...styledProps}>
      <StyledRemoveIcon onClick={onDecrementClick} disabled={counter === 1} />
      <StyledCounterContent value={counter} disabled={true} />
      <StyledAddIcon onClick={onIncrementClick} disabled={false} />
    </StyledWrapper>
  );
};

export default ReprintCounter;
