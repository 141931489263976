import React from 'react';
import { useTranslation } from 'react-i18next';
import { UIBox } from '@/components/ui/Box';
import { UIButtonWithIcon } from '@/components/ui/Button';
import { Typography } from '@/components/ui/Typography';
import {
  LoaderStatus,
  RecapManualStoreUploadResponse as RecapStoreUpload,
} from '@/api/receive';
import { FileItemProps } from './types';
import GetAppIcon from '@material-ui/icons/GetApp';

import * as S from './style';

const FileItem: React.FC<RecapStoreUpload & FileItemProps> = props => {
  const { t } = useTranslation();
  const accordionIsVisible = props.status === LoaderStatus.ERROR;
  const [expanded, setExpanded] = React.useState<boolean>(false);

  return (
    <UIBox width="100%" flexDirection="column">
      <S.Row>
        {accordionIsVisible && (
          <S.Accordion
            expanded={expanded}
            onClick={(): void => setExpanded(e => !e)}
          />
        )}
        <Typography
          margin={accordionIsVisible ? '0' : '0 0 0 45px'}
          className="file-name"
        >
          {props.fileName}
        </Typography>
        <Typography>{props.status}</Typography>
      </S.Row>
      {expanded && (
        <UIBox
          width="100%"
          justifyContent="space-between"
          padding="8px 16px 16px 58px"
        >
          <Typography>{t('migrationErrorDetected')}</Typography>
          <UIButtonWithIcon
            label={t('downloadErrors')}
            startIcon={<GetAppIcon />}
            onClick={(): Promise<void> | void =>
              props.onDownloadErrorClick?.(props.id || '')
            }
          />
        </UIBox>
      )}
    </UIBox>
  );
};

export default FileItem;
