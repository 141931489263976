import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { StoreAndRoles } from '@/api/receive';

import { Typography } from '@/components/ui/Typography';
import { UICheckbox } from '@/components/ui/Checkbox';
import { UIAction } from '@/components/ui/Action';
import { useAppDispatch } from '@/app/store';
import {
  changeDefaultStoreStatus,
  changeStoreStatus,
  removeStore,
} from '@/features/profiling/usersSlice';

import { useSelector } from '@/hooks/useSelector';

import { StoresRemovedToInsert } from './ProfilingStoreRolesList';
import { UIBox } from '@/components/ui/Box';
import { UIAccordion } from '@/components/ui/Accordion';
import { gridStyles, StyledTablesRow } from './styles';
import { SelectedStore } from '@/types/profiling';
import { useRoleModalContext } from '@/context/roleModalContext';

interface ProfilingStoreRowProps {
  isDefaultStore?: boolean;
  isEditPage?: boolean;
  selectedStores: SelectedStore[];
  setSelectedStores: React.Dispatch<React.SetStateAction<SelectedStore[]>>;
  setAlertUndoSnackbarVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  storeRemovedToInsert?: StoresRemovedToInsert;
  setSaveEnable?: React.Dispatch<React.SetStateAction<boolean>>;
  setStoreRemovedToInsert: React.Dispatch<
    React.SetStateAction<StoresRemovedToInsert | undefined>
  >;
}

const StyledActions = styled(UIBox)`
  margin-left: auto;

  & > div {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const StyledRole = styled('span')`
  color: grey;
`;

const StyledAccordionItem = styled(UIBox)`
  flex-direction: column;
  ${gridStyles};
  row-gap: 0;

  & > span {
    grid-column-start: 5;
    display: block;
    width: max-content;
    text-transform: capitalize;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

const WrappedColumn = styled(Typography)`
  word-break: break-word;
`;

const ProfilingStoreRow: React.FC<StoreAndRoles & ProfilingStoreRowProps> = ({
  isDefaultStore,
  storeRemovedToInsert,
  setStoreRemovedToInsert,
  setAlertUndoSnackbarVisibility,
  selectedStores,
  setSelectedStores,
  setSaveEnable,
  ...store
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setRoleModalState } = useRoleModalContext();
  const { storeCode, salesOrg, streetAndHouseNumber, roles } = store;
  const {
    roles: userRoles,
    stores: { storeBySalesOrganization },
  } = useSelector(state => state.profiling.user);

  const [isAccordionExpanded, toggleAccordion] = useState<boolean>(false);

  const actionDisabled = (): boolean => {
    const storeCodes = selectedStores.map(({ storeCode }) => storeCode);
    return storeCodes.includes(storeCode!) && storeCodes.length > 1;
  };

  const onCheckboxClick = (storeCode: string, roles: string[]): void => {
    setSelectedStores(prevState => {
      const storeCodes = prevState.map(({ storeCode }) => storeCode);

      if (storeCodes.includes(storeCode)) {
        return prevState.filter(store => store.storeCode !== storeCode);
      }

      return [...prevState, { storeCode, roles }];
    });

    if (isDefaultStore) {
      dispatch(changeDefaultStoreStatus());
    } else {
      dispatch(changeStoreStatus(storeCode));
    }
  };

  const onEditClick = (storeCode: string, roles: string[]): void => {
    setSelectedStores([{ storeCode, roles }]);

    setRoleModalState({
      roles: userRoles,
      rolesChecked: roles,
      open: true,
      type: 'HYBRID',
    });
  };

  const onRemoveClick = (storeCode: string): void => {
    const indexStoreInPage = storeBySalesOrganization?.findIndex(
      store => store.storeCode === storeCode
    );

    dispatch(removeStore(storeCode));
    setAlertUndoSnackbarVisibility(true);
    setSaveEnable?.(true);
    setStoreRemovedToInsert({
      store,
      index: indexStoreInPage!,
    });
  };

  return (
    <>
      {roles && (
        <>
          <StyledTablesRow>
            <UIBox alignItems="center">
              {roles && roles.length > 1 && (
                <UIAccordion
                  expanded={isAccordionExpanded}
                  onClick={(): void => toggleAccordion(prevState => !prevState)}
                />
              )}
              <UICheckbox
                checked={selectedStores
                  .map(({ storeCode }) => storeCode)
                  .includes(storeCode!)}
                onClick={(): void => onCheckboxClick(storeCode!, roles)}
              />
            </UIBox>
            <WrappedColumn style={{ wordBreak: 'break-word' }}>
              {salesOrg}
            </WrappedColumn>
            <WrappedColumn style={{ wordBreak: 'break-word' }}>
              {storeCode}
            </WrappedColumn>
            <WrappedColumn style={{ wordBreak: 'break-word' }}>
              {streetAndHouseNumber}
            </WrappedColumn>
            <WrappedColumn style={{ wordBreak: 'break-word' }}>
              {roles?.[0]}{' '}
              {roles && roles.length > 1 && (
                <StyledRole>+ ({roles.length - 1})</StyledRole>
              )}
            </WrappedColumn>
            <StyledActions>
              <UIAction
                label={t('edit')}
                icon="edit"
                onClick={(): void => onEditClick(storeCode!, roles)}
                disabled={actionDisabled()}
              />
              {!isDefaultStore && (
                <UIAction
                  label={t('remove')}
                  icon="delete"
                  onClick={(): void => onRemoveClick(storeCode!)}
                  disabled={actionDisabled()}
                />
              )}
            </StyledActions>
            {isAccordionExpanded && (
              <StyledAccordionItem>
                {roles?.slice(1).map(role => (
                  <Typography key={role} font="medium">
                    {t(`${role.toLowerCase()}`)}
                  </Typography>
                ))}
              </StyledAccordionItem>
            )}
          </StyledTablesRow>
        </>
      )}
    </>
  );
};

export default ProfilingStoreRow;
