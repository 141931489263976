import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { useHistory, useLocation, useParams } from 'react-router';
import { useAppDispatch } from '@/app/store';
import { useSocketContext } from '@/context/socketContext';
import { useAsync } from 'react-use';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { Counter } from '@/components/layout/Counter';
import { PageLoader } from '@/components/ui/PageLoader';
import { UICheckbox } from '@/components/ui/Checkbox';
import { InventoryItemScan } from '@/components/layout/InventoryItem';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { ModalAttention } from '@/components/layout/ModalAttention';
import {
  ModalDataSaved,
  ModalDataSavedError as Modal,
} from '@/components/layout/ModalDataSaved';
import { CTAContainer } from '@/components/layout/CTAContainer';
import GetAppIcon from '@material-ui/icons/GetApp';

import { BoxProps } from '@material-ui/core';
import { colors, TFontSize } from '@/theme';
import {
  closeInventory,
  fetchInventoryDetails,
  rejectInventory,
} from '@/features/inventory/inventorySlice';
import {
  InventoryBrand,
  InventoryEpcResultEnum,
  InventoryService,
  ViewUpcDetail,
} from '@/api/receive';
import { ItemStatusValues } from '@/types/enum';
import { AppRoutes } from '@/app/routers';
import { Duration } from 'luxon';
import { UIButton, UIButtonWithIcon } from '@/components/ui/Button';

const StyledHeader = styled(UIBox)`
  width: 100%;

  & > * {
    border: 1px solid ${colors.primary};
    border-radius: 4px;
    background: #fbfbfb;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`;

interface HeaderItemProps extends BoxProps {
  label: string;
  labelSize?: TFontSize;
  value: string;
  valueSize?: TFontSize;
  valueMargin?: CSSProperties['margin'];
  itemsDirection?: CSSProperties['flexDirection'];
}

type Params = { id: string };
type ErrorModalDataType = 'WARNING' | 'ERROR';
type Filters = {
  notFound: boolean;
  unexpected: boolean;
};
type ErrorModalData = {
  title: string;
  message: string;
  type: ErrorModalDataType;
};

const StyledModal = styled(Modal)`
  .wrapper {
    overflow-y: scroll;
  }

  .content {
    width: 100%;
    height: 100%;
    align-items: start;
    margin-top: 64px;
  }
`;

const StyledUpcDetail = styled(UIBox)`
  width: 100%;
  padding: 18px;
  border-radius: 12px;
  background: rgba(251, 251, 251, 1);
  border: 1px solid rgba(237, 237, 237, 1);
`;

const HeaderItem: React.FC<HeaderItemProps> = ({
  label,
  value,
  labelSize = 'sm',
  valueSize = 'sm',
  valueMargin = '0 0 0 4px',
  itemsDirection,
  ...props
}) => {
  return (
    <UIBox {...props}>
      <UIBox
        alignItems="center"
        flexWrap="wrap"
        flexDirection={itemsDirection}
        mt={0.5}
        mb={1}
      >
        <Typography size={labelSize} font="heavy" color="grey">
          {label}
        </Typography>
        <Typography size={valueSize} font="heavy" margin={valueMargin}>
          {value}
        </Typography>
      </UIBox>
    </UIBox>
  );
};

const PageInventoryVirtualBuddyScan: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<Params>();
  const location = useLocation<{ inventoryStoreCode: string }>();
  const [inventoryBrands, setInventoryBrands] = useState<InventoryBrand[]>([]);
  const [filters, setFilters] = useState<Filters>({
    notFound: false,
    unexpected: false,
  });
  const [inventoryError, setInventoryError] = useState<boolean>(false);
  const [upcDetailsError, setUpcDetailsError] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [upcBrandName, setUpcBrandName] = useState<string>('');
  const [notFoundUpc, setNotFoundUpc] = useState<ViewUpcDetail[]>([]);
  const [unexpectedUpc, setUnexpectedUpc] = useState<ViewUpcDetail[]>([]);
  const [errorDownloadCSV, setErrorDownloadCSV] = useState<boolean>(false);
  const [errorModalData, setErrorModalData] = useState<
    ErrorModalData | undefined
  >();
  const [dataSavedModalIsVisible, setDataSavedModalVisibility] =
    useState<boolean>(false);
  const [confirmCloseModalIsVisible, setConfirmCloseModalVisibility] =
    useState<boolean>(false);
  const [rejectModalIsVisible, setRejectModalVisibility] =
    useState<boolean>(false);
  const [returnToStoreModalIsVisible, setReturnToStoreModalVisibility] =
    useState<boolean>(false);
  const [returnToStoreIsLoading, setReturnToStoreIsLoading] =
    useState<boolean>(false);
  const [returnToStoreError, setReturnToStoreError] = useState<boolean>(false);
  const [closeInventoryError, setCloseInventoryError] =
    useState<boolean>(false);
  const [closeInventoryLoading, setCloseInventoryLoading] =
    useState<boolean>(false);
  const [refuseInventoryError, setRefuseInventoryError] =
    useState<boolean>(false);
  const [rejectInventoryLoading, setRejectInventoryLoading] =
    useState<boolean>(false);
  const [upcDetailsModalIsVisible, setModalUpcDetailsVisibility] =
    useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const { connect } = useSocketContext();

  const {
    fetchInventoryIsLoading,
    fetchInventoryHasError,
    closeInventoryHasError,
    refuseInventoryHasError,
  } = useSelector(state => state.inventory);

  const {
    brands,
    storeCode,
    users,
    inventoryProgress,
    inventoryStartTime,
    stopInventory,
    virtualBuddyButtons,
  } = useSelector(state => state.inventory.inventoryDetails);

  useAsync(async () => {
    const storeInStorage = sessionStorage.getItem('inventoryStoreCode');

    await dispatch(
      fetchInventoryDetails({
        storeCode: storeInStorage || location.state?.inventoryStoreCode,
        inventoryId: id,
        isVirtualBuddy: true,
      })
    );

    await connect();
  }, []);

  useEffect(() => {
    if (stopInventory) {
      history.push(AppRoutes.INVENTORY_VIRTUAL_BUDDY);
    }
  }, [history, stopInventory]);

  useEffect(() => {
    if (brands) {
      if (filters.notFound && filters.unexpected) {
        setInventoryBrands(() =>
          brands.filter(inv => inv.notFound! > 0 && inv.unexpected! > 0)
        );
      } else if (filters.notFound) {
        setInventoryBrands(() => brands.filter(inv => inv.notFound! > 0));
      } else if (filters.unexpected) {
        setInventoryBrands(() => brands.filter(inv => inv.unexpected! > 0));
      } else {
        setInventoryBrands(brands);
      }
    }
  }, [brands, filters.notFound, filters.unexpected]);

  // ERRORS -------------------------------------
  useEffect(() => {
    if (fetchInventoryHasError) {
      setInventoryError(true);
    }
  }, [fetchInventoryHasError]);

  useEffect(() => {
    if (closeInventoryHasError && closeInventoryHasError.status === 500) {
      const { body } = closeInventoryHasError;

      if (body) {
        if (body.inventoryStatus === 'Waiting for Validation') {
          setErrorModalData({
            title: t('waitingForApplication'),
            message: t('waitingForApplicationErrorMessage'),
            type: 'WARNING',
          });
        } else {
          setErrorModalData({
            title: t('inventoryRejected'),
            message: t('inventoryRejectedErrorMessage'),
            type: 'ERROR',
          });
        }
      }

      setConfirmCloseModalVisibility(false);
      setErrorModal(true);
    }
  }, [closeInventoryHasError, t]);
  // --------------------------------------------

  const filtersHandler = (value: keyof Filters): void => {
    setFilters(prevState => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };

  // MODAL ACTIONS ------------------------------
  const closeInventoryHandler = async (): Promise<void> => {
    if (storeCode) {
      try {
        setCloseInventoryLoading(true);

        await dispatch(
          closeInventory({
            inventoryId: id,
            storeCode,
          })
        ).unwrap();

        setCloseInventoryLoading(false);
        setConfirmCloseModalVisibility(false);
        setDataSavedModalVisibility(true);
      } catch {
        setCloseInventoryLoading(false);
        setCloseInventoryError(true);
      }
    }
  };

  const rejectInventoryHandler = async (): Promise<void> => {
    if (storeCode) {
      try {
        setRejectInventoryLoading(true);

        await dispatch(
          rejectInventory({
            inventoryId: id,
            storeCode,
          })
        ).unwrap();

        setRejectInventoryLoading(false);
        setRejectModalVisibility(false);
        setDataSavedModalVisibility(true);
      } catch {
        setRejectInventoryLoading(false);
        setRefuseInventoryError(true);
      }
    }
  };

  const returnToStoreHandler = async (): Promise<void> => {
    if (storeCode) {
      try {
        setReturnToStoreIsLoading(true);

        await InventoryService.inventoryChangeInventoryStatus({
          requestBody: {
            storeCode,
            idInventory: id,
            isRetry: true,
            status: 'Refused',
          },
        });

        setReturnToStoreIsLoading(false);
        setReturnToStoreModalVisibility(false);

        history.push(AppRoutes.INVENTORY_VIRTUAL_BUDDY);
      } catch {
        setReturnToStoreIsLoading(false);
        setReturnToStoreError(true);
      }
    }
  };

  const handleBrandClick = async (brandName: string): Promise<void> => {
    try {
      const response = await InventoryService.inventoryViewUpcDetails({
        requestBody: {
          inventoryId: id,
          brands: [brandName],
          filters: [
            InventoryEpcResultEnum.NOT_FOUND,
            InventoryEpcResultEnum.UNEXPECTED,
          ],
        },
      });

      if (response) {
        const brandName = response[0]?.brandName || 'Gucci';
        const upcs = response[0]?.upcs || [];

        const filterUpc = (value: InventoryEpcResultEnum): ViewUpcDetail[] =>
          upcs.filter(({ filterValue }) => filterValue === value) || [];

        setUpcBrandName(brandName);
        setNotFoundUpc(filterUpc(InventoryEpcResultEnum.NOT_FOUND));
        setUnexpectedUpc(filterUpc(InventoryEpcResultEnum.UNEXPECTED));

        setModalUpcDetailsVisibility(true);
      }
    } catch {
      setUpcDetailsError(true);
    }
  };

  const downloadCsvHandler = async (): Promise<void> => {
    setDownloadLoading(true);
    try {
      const { base64, fileName } =
        await InventoryService.inventoryDownloadScannedEpcs({
          inventoryId: id,
        });

      const linkSource = `data:application/csv;base64,${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch {
      setErrorDownloadCSV(true);
    }
    setDownloadLoading(false);
  };

  const closeModalDataSavedHandler = (): void => {
    setDataSavedModalVisibility(false);
    history.push(AppRoutes.INVENTORY_VIRTUAL_BUDDY);
  };

  const closeModalDataSavedErrorHandler = (): void => {
    setErrorModal(false);
    setErrorModalData(undefined);
  };
  // --------------------------------------------

  const calcItems = useCallback(
    (value: ItemStatusValues) =>
      brands && brands.reduce((a, b) => a + b[value]!, 0),
    [brands]
  );

  const found = useMemo(() => calcItems('found'), [calcItems]);
  const inStock = useMemo(() => calcItems('expected'), [calcItems]);
  const notFound = useMemo(() => calcItems('notFound'), [calcItems]);
  const unexpected = useMemo(() => calcItems('unexpected'), [calcItems]);

  const scanAccuracy = useMemo(
    () => (found! / inStock!) * 100 || 0,
    [found, inStock]
  );

  const operators = useMemo(
    () =>
      users &&
      users
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
        .join(', '),
    [users]
  );

  const scanTime = useMemo(() => {
    if (inventoryProgress && inventoryStartTime) {
      const seconds = Number(inventoryProgress) - Number(inventoryStartTime);

      return Duration.fromObject({
        seconds,
      }).toFormat('hh:mm:ss');
    }

    return '00:00:00';
  }, [inventoryProgress, inventoryStartTime]);

  if (fetchInventoryIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <StyledModal
        open={upcDetailsModalIsVisible}
        title={`Brand: ${upcBrandName}`}
        message=""
        onClose={(): void => {
          setModalUpcDetailsVisibility(false);
          setUpcBrandName('');
        }}
      >
        <>
          {unexpectedUpc.length > 0 && (
            <UIBox mb={3} flexDirection="column" width="100%">
              <UIBox mt={1} mb={2}>
                <Typography>
                  {t('receiving.unexpecteditems', {
                    count: unexpectedUpc.length,
                  })}
                </Typography>
              </UIBox>
              {unexpectedUpc.map(({ upcCode }) => (
                <StyledUpcDetail>
                  <Typography>{upcCode}</Typography>
                </StyledUpcDetail>
              ))}
            </UIBox>
          )}
          {notFoundUpc.length > 0 && (
            <UIBox flexDirection="column" width="100%">
              <UIBox mt={1} mb={2}>
                <Typography>
                  {t('receiving.notfounditems', { count: notFoundUpc.length })}
                </Typography>
              </UIBox>
              {notFoundUpc.map(({ upcCode }) => (
                <StyledUpcDetail>
                  <Typography>{upcCode}</Typography>
                </StyledUpcDetail>
              ))}
            </UIBox>
          )}
        </>
      </StyledModal>
      <Modal
        open={errorModal}
        onClose={closeModalDataSavedErrorHandler}
        title={errorModalData?.title || ''}
        message={errorModalData?.message || ''}
        iconType={errorModalData?.type}
      />
      <ModalDataSaved
        $minWidth="400px"
        $minHeight="160px"
        open={dataSavedModalIsVisible}
        onClose={closeModalDataSavedHandler}
        message={t('modal.datasaved')}
      />
      <ModalAttention
        open={confirmCloseModalIsVisible}
        loadingConfirm={closeInventoryLoading}
        disableConfirm={closeInventoryLoading}
        onClose={(): void => setConfirmCloseModalVisibility(false)}
        message={t('confirmCloseInventoryMessage')}
        messageMaxWidth="100%"
        onConfirmClick={closeInventoryHandler}
      />
      <ModalAttention
        open={returnToStoreModalIsVisible}
        loadingConfirm={returnToStoreIsLoading}
        disableConfirm={returnToStoreIsLoading}
        onClose={(): void => setReturnToStoreModalVisibility(false)}
        message={t('returnToStoreMessage')}
        messageMaxWidth="75%"
        onConfirmClick={returnToStoreHandler}
      />
      <Modal
        $minHeight="250px"
        open={rejectModalIsVisible}
        onClose={(): void => setRejectModalVisibility(false)}
        title={t('payAttention')}
        message={t('rejectInventoryMessage')}
        iconType="WARNING"
      >
        <UIBox mt={4} alignItems="center" width="60%" alignSelf="end">
          <UIBox width="50%" mr={2}>
            <UIButton
              outlined
              label={t('no')}
              disabled={false}
              onClick={(): void => setRejectModalVisibility(false)}
            />
          </UIBox>
          <UIButton
            outlined
            label={t('yes')}
            loading={rejectInventoryLoading}
            disabled={rejectInventoryLoading}
            onClick={rejectInventoryHandler}
          />
        </UIBox>
      </Modal>
      <UIBox width="100%" flexDirection="column" p={3}>
        <StyledHeader>
          <UIBox p="14px" minWidth="300px" flexDirection="column">
            <HeaderItem
              label={t('storeCode')}
              value={storeCode || ''}
              flexDirection="column"
            />
            <HeaderItem label={t('operator')} value={operators || ''} />
          </UIBox>
          <UIBox alignItems="center" ml="auto" minWidth="250px">
            <UIBox
              flex="2"
              p="14px"
              height="100%"
              flexDirection="column"
              borderRight="1px solid #ededed"
            >
              <HeaderItem
                p={0}
                label={t('scanTime')}
                value={scanTime}
                flexDirection="column"
              />
              <HeaderItem
                p={0}
                label={t('scanAccuracy')}
                value={scanAccuracy.toFixed() + '%'}
                flexDirection="column"
              />
            </UIBox>
            <UIBox alignItems="center" pr="14px" ml={2}>
              <UIBox flexDirection="column">
                <Typography
                  size="xs"
                  font="heavy"
                  color="grey"
                  margin="0 0 4px 0"
                >
                  {t('preInventory')}
                </Typography>
                <Typography size="xs" font="heavy" color="grey">
                  ({t('inStock')}, {t('backToStock')})
                </Typography>
              </UIBox>
              <Typography size="xl" font="heavy" margin="0 0 0 8px">
                {typeof inStock !== 'undefined' ? String(inStock) : ''}
              </Typography>
            </UIBox>
          </UIBox>
          <Counter
            foundItemsCount={found}
            notFoundItemsCount={notFound}
            unexpectedItemsCount={unexpected}
          />
        </StyledHeader>
        <UIBox width="100%" mt={2}>
          <UIBox>
            <UIButtonWithIcon
              disabled={!virtualBuddyButtons}
              label={t('downloadCsv')}
              startIcon={<GetAppIcon />}
              onClick={downloadCsvHandler}
              loading={downloadLoading}
            />
          </UIBox>
          <UIBox alignItems="center" ml="auto">
            <UIBox>
              <Typography color="grey">{t('filterBy')}:</Typography>
            </UIBox>
            <UIBox alignItems="center" ml={2}>
              <UICheckbox
                checked={filters.notFound}
                onClick={(): void => filtersHandler('notFound')}
              />
              <Typography color="grey">{t('notFound')}</Typography>
            </UIBox>
            <UIBox alignItems="center" ml={2}>
              <UICheckbox
                checked={filters.unexpected}
                onClick={(): void => filtersHandler('unexpected')}
              />
              <Typography color="grey">{t('unexpected')}</Typography>
            </UIBox>
          </UIBox>
        </UIBox>
        <UIBox mt={3} pl={2} pr={2} justifyContent="space-between">
          <Typography size="lg" font="heavy">
            {t('brands')}
          </Typography>
          <Typography size="lg" font="heavy">
            {t('scannedTags')} / {t('inStock')}
          </Typography>
        </UIBox>
        <UIBox width="100%" mb="100px" flexDirection="column">
          {inventoryBrands.map((brand, index) => (
            <InventoryItemScan
              {...brand}
              onClick={handleBrandClick}
              key={`${brand.brandName}-${index}`}
            />
          ))}
        </UIBox>
        <CTAContainer
          type="FOUR_BUTTONS"
          fourthButtonLabel={t('quit')}
          thirdButtonLabel={t('reject')}
          secondButtonLabel={t('returnToStore')}
          firstButtonLabel={t('closeInventory')}
          disabledThirdButton={!virtualBuddyButtons}
          disabledSecondButton={!virtualBuddyButtons}
          disabledFirstButton={!virtualBuddyButtons}
          onFourthClick={(): void =>
            history.push(`${AppRoutes.INVENTORY_VIRTUAL_BUDDY}`)
          }
          onThirdClick={(): void => setRejectModalVisibility(true)}
          onSecondClick={(): void => setReturnToStoreModalVisibility(true)}
          onFirstClick={(): void => setConfirmCloseModalVisibility(true)}
        />
      </UIBox>
      <ErrorSnackbar
        open={inventoryError}
        setIsOpen={setInventoryError}
        errorMessage={t('error.inventory_details_loading')}
      />
      <ErrorSnackbar
        open={returnToStoreError}
        setIsOpen={setReturnToStoreError}
        errorMessage={t('error.returnToStore')}
      />
      <ErrorSnackbar
        open={upcDetailsError}
        setIsOpen={setUpcDetailsError}
        errorMessage={t('error.upcDetailsError')}
      />
      <ErrorSnackbar
        open={errorDownloadCSV}
        setIsOpen={setErrorDownloadCSV}
        errorMessage={t('error.downloadFile')}
      />
      <ErrorSnackbar
        open={closeInventoryHasError?.status !== 500 && closeInventoryError}
        setIsOpen={setCloseInventoryError}
        errorMessage={closeInventoryHasError?.body}
      />
      <ErrorSnackbar
        open={refuseInventoryError}
        setIsOpen={setRefuseInventoryError}
        errorMessage={
          refuseInventoryHasError?.body ||
          refuseInventoryHasError?.body?.errorMessage
        }
      />
    </>
  );
};

export default PageInventoryVirtualBuddyScan;
