import React from 'react';
import { Typography } from '@/components/ui/Typography';
import { useTranslation } from 'react-i18next';
import { UIBox } from '../Box';
import { SelectAllProps } from './types';

import * as S from './style';

const SelectAll: React.FC<SelectAllProps> = ({
  selectedAll,
  actions,
  selected,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <UIBox>
      <S.SelectAll
        disabled={disabled}
        onClick={selectedAll ? actions.deselectAll : actions.selectAll}
      >
        {selectedAll ? (
          <Typography>{t('deselectAll')}</Typography>
        ) : (
          <Typography>{t('selectAll')}</Typography>
        )}
      </S.SelectAll>
      <S.Span>
        {t('item_selected', {
          selected,
        })}
      </S.Span>
    </UIBox>
  );
};

export default SelectAll;
