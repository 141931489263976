import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReceiveApiError as ApiError } from '@/api';
import { RootState } from '@/app/rootReducer';
import { CheckedPosItems, CheckItemBody, PosInitialState } from '@/types/pos';
import {
  ConfirmSoldItem,
  ConfirmSoldItemsRequest,
  PosFlowType,
  PosService,
  SearchSaleExceptionResponse,
} from '@/api/receive';
import { Utils } from './utils';
import { encodeRequest } from '@/utils';

const initialState: PosInitialState = {
  liteFunctionality: false,
  dataSaved: false,
  processLocked: false,
  checkedManagedItems: {},
  checkedNotManagedItems: {},
  exceptionsManaged: [],
  exceptionsNotManaged: [],
};

export const getSaleExceptionsItems = createAsyncThunk<
  SearchSaleExceptionResponse,
  string[],
  { rejectValue: ApiError; state: RootState }
>('getSaleExceptionsItems', async (upcs, { getState, rejectWithValue }) => {
  try {
    const storeCode = getState().currentStore.store?.storeCode || '';

    const response = await PosService.posSearchSaleException({
      requestBody: {
        storeCode,
        upcs,
      },
    });

    if (response && response.productDetailsItems) {
      return {
        productDetailsItems: response.productDetailsItems,
      };
    }
  } catch (error) {
    return rejectWithValue(error as ApiError);
  }

  return {
    productDetailsItems: [],
  };
});

export const confirmSoldItems = createAsyncThunk<
  void,
  void,
  { rejectValue: ApiError; state: RootState }
>('confirmSoldItems', async (_, { getState, rejectWithValue }) => {
  try {
    const checkedManagedItems = getState().pos.checkedManagedItems;
    const storeCode = getState().currentStore.store?.storeCode || '';
    let upcs: ConfirmSoldItem[] = [];

    for (const [upcCode, epcs] of Object.entries(checkedManagedItems)) {
      upcs = [
        ...upcs,
        {
          upcCode,
          epcs,
        },
      ];
    }

    let requestBody: ConfirmSoldItemsRequest = {
      storeCode,
      upcs,
    };

    await PosService.posConfirmSoldItems({ requestBody });
  } catch (error) {
    return rejectWithValue(error as ApiError);
  }
});

export const removeManagedPosItems = createAsyncThunk<
  void,
  boolean | undefined,
  { rejectValue: ApiError; state: RootState }
>('removeManagedPosItems', async (all, { getState, rejectWithValue }) => {
  try {
    const storeCode = getState().currentStore.store?.storeCode || '';

    if (all) {
      const items = getState().pos.exceptionsManaged;
      await PosService.posRemovePosItem({
        requestBody: items.map(({ upcCode, soldItems }) => ({
          posFlowType: PosFlowType.POS_SALE_EXCEPTION,
          upcCode: upcCode || '',
          storeCode,
          rowsToDelete: soldItems || 1,
        })),
      });
    } else {
      const upcCodes = Object.keys(getState().pos.checkedManagedItems);
      await PosService.posRemovePosItem({
        requestBody: upcCodes.map(upcCode => ({
          posFlowType: PosFlowType.POS_SALE_EXCEPTION,
          upcCode: upcCode,
          storeCode,
          rowsToDelete: 1,
        })),
      });
    }
  } catch (error) {
    return rejectWithValue(error as ApiError);
  }
});

export const removeNotManagedPosItems = createAsyncThunk<
  void,
  boolean | undefined,
  { rejectValue: ApiError; state: RootState }
>('removeNotManagedPosItems', async (all, { getState, rejectWithValue }) => {
  try {
    const storeCode = getState().currentStore.store?.storeCode || '';

    if (all) {
      const items = getState().pos.exceptionsNotManaged;
      await PosService.posRemovePosItemEncoded({
        requestBody: {
          removePosRequest: encodeRequest(
            items.map(({ upcCode, soldItems }) => ({
              posFlowType: PosFlowType.POS_SALE_EXCEPTION,
              upcCode: upcCode || '',
              storeCode,
              rowsToDelete: soldItems || 1,
            }))
          ),
        },
      });
    } else {
      const upcCodes = Object.keys(getState().pos.checkedNotManagedItems);
      await PosService.posRemovePosItemEncoded({
        requestBody: {
          removePosRequest: encodeRequest(
            upcCodes.map(upcCode => ({
              posFlowType: PosFlowType.POS_SALE_EXCEPTION,
              upcCode: upcCode,
              storeCode,
              rowsToDelete: 1,
            }))
          ),
        },
      });
    }
  } catch (error) {
    return rejectWithValue(error as ApiError);
  }
});

const posSlice = createSlice({
  name: 'posSlice',
  initialState,
  reducers: {
    setLiteFunctionality: (state, { payload }: PayloadAction<boolean>) => {
      state.liteFunctionality = payload;
    },
    searchItems: (state, { payload }: PayloadAction<string[]>) => {
      const items = state.exceptionsManaged.map(item => ({
        ...item,
        epcCodes: item.epcCodes?.filter(epc => !payload.includes(epc)),
      }));

      state.exceptionsManaged = items;
    },
    checkManagedItem: (state, { payload }: PayloadAction<CheckItemBody>) => {
      const { upcCode, epcCode, epcCodes } = payload;

      const items = Utils.checkItem(
        state.checkedManagedItems,
        upcCode,
        epcCode,
        epcCodes
      );

      state.checkedManagedItems = items;
    },
    unCheckManagedItem: (state, { payload }: PayloadAction<CheckItemBody>) => {
      const { upcCode, epcCode } = payload;

      const items = Utils.unCheckItem(
        state.checkedManagedItems,
        upcCode,
        epcCode
      );

      state.checkedManagedItems = items;
    },
    checkNotManagedItem: (state, { payload }: PayloadAction<CheckItemBody>) => {
      const { upcCode, epcCode } = payload;

      const items = Utils.checkItem(
        state.checkedNotManagedItems,
        upcCode,
        epcCode
      );

      state.checkedNotManagedItems = items;
    },
    unCheckNotManagedItem: (
      state,
      { payload }: PayloadAction<CheckItemBody>
    ) => {
      const { upcCode, epcCode } = payload;

      const items = Utils.unCheckItem(
        state.checkedNotManagedItems,
        upcCode,
        epcCode
      );

      state.checkedNotManagedItems = items;
    },
    checkAllManagedItems: (
      state,
      { payload }: PayloadAction<CheckedPosItems>
    ) => {
      state.checkedManagedItems = payload;
    },
    resetManagedCheckedItems: state => {
      state.checkedManagedItems = {};
    },
    checkAllNotManagedItems: (
      state,
      { payload }: PayloadAction<CheckedPosItems>
    ) => {
      state.checkedNotManagedItems = payload;
    },
    resetNotManagedCheckedItems: state => {
      state.checkedNotManagedItems = {};
    },
    setDataSaved: (state, { payload }: PayloadAction<boolean>) => {
      state.dataSaved = payload;
    },
    setProcessLocked: (state, { payload }: PayloadAction<boolean>) => {
      state.processLocked = payload;
    },
    initPosState: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getSaleExceptionsItems.pending, state => {
        state.getSaleExceptionsIsLoading = true;
      })
      .addCase(getSaleExceptionsItems.fulfilled, (state, { payload }) => {
        state.getSaleExceptionsIsLoading = false;

        if (payload.productDetailsItems) {
          state.exceptionsManaged = payload.productDetailsItems.filter(
            ({ epcCodes }) => epcCodes && epcCodes.length > 0
          );
          state.exceptionsNotManaged = payload.productDetailsItems.filter(
            ({ epcCodes }) => !epcCodes || epcCodes.length === 0
          );
        }
      })
      .addCase(getSaleExceptionsItems.rejected, (state, { payload }) => {
        state.getSaleExceptionsIsLoading = false;
        state.getSaleExceptionsHasError = payload as ApiError;
      })
      .addCase(confirmSoldItems.pending, state => {
        state.confirmIsLoading = true;
      })
      .addCase(confirmSoldItems.fulfilled, state => {
        state.confirmIsLoading = false;
        state.confirmHasError = undefined;
      })
      .addCase(confirmSoldItems.rejected, (state, { payload }) => {
        state.confirmIsLoading = false;
        state.confirmHasError = payload as ApiError;
      })
      .addCase(removeNotManagedPosItems.pending, state => {
        state.removePosItemsIsLoading = true;
      })
      .addCase(removeNotManagedPosItems.fulfilled, state => {
        state.removePosItemsIsLoading = false;
        state.removePosItemsHasError = undefined;
      })
      .addCase(removeNotManagedPosItems.rejected, (state, { payload }) => {
        state.removePosItemsIsLoading = false;
        state.removePosItemsHasError = payload as ApiError;
      })
      .addCase(removeManagedPosItems.pending, state => {
        state.removePosItemsIsLoading = true;
      })
      .addCase(removeManagedPosItems.fulfilled, state => {
        state.removePosItemsIsLoading = false;
        state.removePosItemsHasError = undefined;
      })
      .addCase(removeManagedPosItems.rejected, (state, { payload }) => {
        state.removePosItemsIsLoading = false;
        state.removePosItemsHasError = payload as ApiError;
      });
  },
});

export const {
  initPosState,
  setDataSaved,
  searchItems,
  setLiteFunctionality,
  setProcessLocked,
  checkAllManagedItems,
  checkAllNotManagedItems,
  resetManagedCheckedItems,
  resetNotManagedCheckedItems,
  unCheckManagedItem,
  checkManagedItem,
  checkNotManagedItem,
  unCheckNotManagedItem,
} = posSlice.actions;
export default posSlice.reducer;
