import { FC, memo } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  StyledMultiProgressBar,
  StyledMultiProgressBarInfo,
  StyledMultiProgressBarInnerWrapper,
  StyledScannedStickyBar,
  StyledNotFoundBar,
  SyledFoundBar,
  StyledUnexpectedBar,
} from './styles';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { AppRoutes } from '@/app/routers';

export interface MultiProgressBarProps {
  foundItemsCount: number;
  notFoundItemsCount: number;
  unexpectedItemsCount: number;
  expectedItemsCount?: number;
  sticky?: boolean;
}

export const MultiProgressBarCycleCount: FC<MultiProgressBarProps> = memo(
  ({
    sticky,
    foundItemsCount,
    notFoundItemsCount,
    unexpectedItemsCount,
    expectedItemsCount,
  }): JSX.Element => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const totalItems = foundItemsCount + unexpectedItemsCount;

    return (
      <StyledMultiProgressBar>
        {!sticky && (
          <StyledMultiProgressBarInfo>
            <Typography size="sm" font="heavy">
              {t('scanned-tags')}
            </Typography>
            <UIBox flexDirection="column" textAlign="right">
              <Typography size="sm" font="heavy">
                {totalItems}/{expectedItemsCount || notFoundItemsCount}{' '}
                {t('items')}
              </Typography>
              {pathname.indexOf(`${AppRoutes.INVENTORY}-report/`) > -1 && (
                <Typography size="sm" color="grey">
                  {t('scanned')} / {t('preInventory')} ({t('inStock')},{' '}
                  {t('backInStock')})
                </Typography>
              )}
            </UIBox>
          </StyledMultiProgressBarInfo>
        )}
        {sticky ? (
          <StyledScannedStickyBar>
            <Typography size="xs" font="heavy">
              {t('scanned-tags')}
            </Typography>
            <StyledMultiProgressBarInnerWrapper $sticky={sticky}>
              <SyledFoundBar
                found={foundItemsCount}
                notFound={notFoundItemsCount}
                unexpected={unexpectedItemsCount}
              />
              <StyledNotFoundBar />
              <StyledUnexpectedBar
                found={foundItemsCount}
                notFound={notFoundItemsCount}
                unexpected={unexpectedItemsCount}
              />
            </StyledMultiProgressBarInnerWrapper>
            <Typography size="xs" font="heavy">
              {totalItems}/{notFoundItemsCount} {t('items')}
            </Typography>
          </StyledScannedStickyBar>
        ) : (
          <StyledMultiProgressBarInnerWrapper>
            <SyledFoundBar
              found={foundItemsCount}
              notFound={notFoundItemsCount}
              unexpected={unexpectedItemsCount}
            />
            <StyledNotFoundBar />
            <StyledUnexpectedBar
              found={foundItemsCount}
              notFound={notFoundItemsCount}
              unexpected={unexpectedItemsCount}
            />
          </StyledMultiProgressBarInnerWrapper>
        )}
      </StyledMultiProgressBar>
    );
  }
);

MultiProgressBarCycleCount.displayName = 'MultiProgressBarCycleCount';

export default MultiProgressBarCycleCount;
