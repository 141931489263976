import { FC, useState, useEffect } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';
import { DeviceInfoCheckbox } from '@/components/layout/DeviceinfoCheckbox';
import { ScanDevice } from '@/types/device';

import { UILoader } from '@/components/ui/Loader';
import { Typography } from '@/components/ui/Typography';
import { AppRoutes } from '@/app/routers';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { removeDeviceInUse } from '@/features/devices/devicesSlice';

import {
  StyledTitle,
  StyledContentDevice,
  StyledDeviceInfoWrapper,
  StyledUILoaderWrapper,
  StyledColumnDevice,
  StyledReleaseButtonWrapper,
} from './styles';
import { useLocation } from 'react-router';
import { useAppDispatch } from '@/app/store';
import { useSubscribeEpcs } from '@/hooks/mqtt/useSubscribeEpcs';
import { useSubscribeDeviceStatus } from '@/hooks/mqtt/useSubscribeDeviceStatus';
import { UIButton } from '@/components/ui/Button';

export interface SelectDeviceProps {
  selectedDevice: ScanDevice;
  setDevice: (arg: ScanDevice) => void;
  scanContinue?: boolean;
  title?: string;
  $v2?: boolean;
  hiddenDevices?: boolean;
}

const ColumnDevice: FC<SelectDeviceProps> = ({
  children,
  selectedDevice,
  setDevice,
  scanContinue,
  title,
  hiddenDevices,
  $v2 = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [isSelected, setIsSelected] = useState(false);
  const [forceUnlock, setForceUnlock] = useState(false);

  const { deviceList, isLoadingDeviceStatus } = useSelector(
    state => state.devices
  );

  const { deviceInUse } = useSelector(state => state.devices);

  const subscribeDevice = useSubscribeDeviceStatus();
  const subscribeEPCS = useSubscribeEpcs(deviceInUse?.deviceId!);

  const dispatch = useAppDispatch();

  const removeDeviceClickHandler = (): void => {
    setForceUnlock(true);
    subscribeDevice.publish(deviceInUse?.deviceId!, undefined);
    subscribeEPCS.unsubscribe();
    dispatch(removeDeviceInUse());
  };

  const onDeviceChange = (dev: ScanDevice): void => {
    setDevice(deviceInUse || dev);
  };

  useEffect(() => {
    if (deviceList && !isSelected) {
      const clickableRadio: HTMLElement | null = document.querySelector(
        '*[data-status="online"] input'
      );

      if (clickableRadio) {
        clickableRadio?.click();
        setIsSelected(true);
      }
    }
  }, [deviceInUse, deviceList, isSelected]);

  const disableDevices = (status: string, deviceId: string): boolean => {
    if (status === 'online' && (forceUnlock || !deviceInUse)) {
      return false;
    }
    if (pathname === AppRoutes?.SALE_ITEMS) {
      if (status === 'online') {
        if (!scanContinue) {
          return false;
        } else if (!deviceInUse && scanContinue) {
          return false;
        }
      }

      return true;
    } else {
      return (
        (scanContinue &&
          selectedDevice?.deviceId !== '' &&
          selectedDevice?.deviceId !== deviceId) ||
        status !== 'online'
      );
    }
  };

  return (
    <StyledColumnDevice>
      {!$v2 && (
        <StyledTitle>
          <Typography size="lg" font="heavy" as="h1">
            {title || t('scanwizard.1.device')}
          </Typography>
        </StyledTitle>
      )}
      <StyledContentDevice>
        {scanContinue && (
          <StyledReleaseButtonWrapper>
            <UIButton
              color="primary"
              onClick={removeDeviceClickHandler}
              label={t('device.release')}
            />
          </StyledReleaseButtonWrapper>
        )}
        {isLoadingDeviceStatus ? (
          <StyledUILoaderWrapper>
            <UILoader />
          </StyledUILoaderWrapper>
        ) : (
          !hiddenDevices &&
          deviceList.map(
            (
              {
                status,
                battery,
                deviceId,
                userName,
                nameDevice,
                mode,
                num_tag,
                storeId,
              },
              i
            ) => (
              <StyledDeviceInfoWrapper key={`${deviceId}-${i}`}>
                <DeviceInfoCheckbox
                  status={status}
                  battery={battery}
                  deviceName={nameDevice || deviceId}
                  userName={userName}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleChecked />}
                  checked={
                    status === 'offline'
                      ? false
                      : deviceInUse
                      ? deviceInUse.deviceId === deviceId
                      : selectedDevice?.deviceId === deviceId
                  }
                  disabled={disableDevices(status, deviceId)}
                  onChange={(): void =>
                    onDeviceChange({
                      status,
                      battery,
                      deviceId,
                      userName,
                      nameDevice,
                      mode,
                      num_tag,
                      storeId,
                    })
                  }
                  data-status={status}
                  $v2={$v2}
                />
              </StyledDeviceInfoWrapper>
            )
          )
        )}
        {children}
      </StyledContentDevice>
    </StyledColumnDevice>
  );
};

export default ColumnDevice;
