import { UIBox } from '@/components/ui/Box';
import styled from 'styled-components';

export const PageWrapper = styled(UIBox)`
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(UIBox)`
  margin-top: 40px;
  margin-bottom: 120px;
`;

export const Tags = styled(UIBox)`
  flex-direction: column;
  align-items: center;

  .tags-read {
    font-size: 36px;
  }
`;

export const DownloadWrapper = styled(UIBox)`
  margin-top: 64px;
`;
