import { FC } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';
import { EnumMode } from '@/types/enum';

import { Row } from '@/components/layout/Row';
import { Typography } from '@/components/ui/Typography';
import { UILoader } from '@/components/ui/Loader';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import {
  StyledColumn,
  StyledContent,
  StyledText,
  StyledLoaderWrapper,
  StyledDoneIcon,
  StyledTypography,
  StyledButton,
  StyledRadio,
} from './styles';
import { ScanDevice } from '@/types/device';

export interface ModeProps {
  mode: EnumMode;
  device?: ScanDevice;
  handleMode: () => void;
  disabledModes?: EnumMode[];
  hideModes?: EnumMode[];
  isScanAgain?: boolean;
  onStartReaderClick?: () => void;
}

export const ColumnMode: FC<ModeProps> = ({
  mode,
  device,
  disabledModes = [],
  hideModes = [],
  handleMode,
  isScanAgain,
  onStartReaderClick,
}): JSX.Element => {
  const { t } = useTranslation();

  const { startReaderIsLoading, deviceInUse, isLoadingDeviceStatus } =
    useSelector(state => state.devices);

  const isStartButtonDisabled =
    isLoadingDeviceStatus || startReaderIsLoading || device?.deviceId === '';

  return (
    <StyledColumn>
      <StyledContent>
        {!hideModes.includes(EnumMode.INVENTORY) && (
          <Row mt="0">
            <StyledRadio
              icon={<CircleUnchecked />}
              checkedIcon={<CircleChecked />}
              checked={mode === EnumMode.INVENTORY}
              disabled={
                mode === EnumMode.INVENTORY ||
                disabledModes?.includes(EnumMode.INVENTORY)
              }
              onChange={handleMode}
            />
            <StyledText>
              <Typography size="sm" font="heavy" as="h1">
                {t('scanwizard.standard')}
              </Typography>
              <Typography size="xs" as="p">
                {t('scanwizard.standard_desc')}
              </Typography>
            </StyledText>
          </Row>
        )}
        {!hideModes.includes(EnumMode.FIND) && (
          <Row mt="56">
            <StyledRadio
              icon={<CircleUnchecked />}
              checkedIcon={<CircleChecked />}
              checked={mode === EnumMode.FIND}
              disabled={
                mode === EnumMode.FIND || disabledModes.includes(EnumMode.FIND)
              }
              onChange={handleMode}
            />
            <StyledText>
              <Typography size="sm" font="heavy" as="h1">
                {t('scanwizard.find')}
              </Typography>
              <Typography size="xs" as="p">
                {t('scanwizard.find_desc')}
              </Typography>
            </StyledText>
          </Row>
        )}
        {(isScanAgain || !deviceInUse) && (
          <StyledButton
            onClick={onStartReaderClick}
            label={t('scanwizard.start')}
            outlined={false}
            disabled={isStartButtonDisabled}
          />
        )}
        {startReaderIsLoading && (
          <StyledLoaderWrapper>
            <span>{t('scanwizard.loading')}</span>
            <UILoader />
          </StyledLoaderWrapper>
        )}
        {!isScanAgain && deviceInUse && (
          <>
            <StyledDoneIcon />
            <StyledTypography size="sm" font="medium">
              {t('scanwizard.ready')}
            </StyledTypography>
          </>
        )}
      </StyledContent>
    </StyledColumn>
  );
};

export default ColumnMode;
