import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import { Link } from '@material-ui/core';
import { colors } from '@/theme/_colors';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const StyledTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledAddDeviceWrapper = styled('div')`
  margin-bottom: 16px;
  align-self: end;
`;

export const StyledTable = styled(Table)``;

export const StyledEditRemoveCell = styled(TableCell)`
  display: flex;
  justify-content: end;
  gap: 8px;
  border-bottom: none;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledBodyTableRow = styled(TableRow)`
  &:nth-child(2n + 1) {
    background: ${colors.midGrey};
  }
  &:hover {
    background: ${colors.lightGrey};
  }
`;

export const StyledBodyTableCell = styled(TableCell)`
  border-bottom: none;
`;

export const StyledHeadTableCell = styled(TableCell)`
  font-weight: bold;
`;
export const StyledLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 64px;
`;
