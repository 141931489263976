import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce, useMount } from 'react-use';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';

import { Trans, useTranslation } from 'react-i18next';
import Fuse from 'fuse.js';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Typography } from '@/components/ui/Typography';
import { UIList } from '@/components/ui/List';
import {
  deselectAllBrand,
  getBrandsByStore,
  initCycleCountState,
  resetBrandsSettings,
  setFilterSelected,
} from '@/features/cycleCount/cycleCountSlice';
import { CTAContainer } from '@/components/layout/CTAContainer';
import {
  selectAllBrand,
  changeCommodityState,
} from '@/features/cycleCount/cycleCountSlice';

import { CustomBrand, CycleCountSlice } from '@/types/cycleCount';
import { UILoader } from '@/components/ui/Loader';
import { AppRoutes } from '@/app/routers';
import { UIListItem } from '@/components/ui/ListItem';
import { UIListHeader } from '@/components/ui/ListHeader';
import TabCycleCount from '@/components/CycleCount/TabCycleCount/TabCycleCount';

import {
  StyledHasMarketing,
  StyledCheckCircleOutlineIcon,
  StyledErrorOutlineIcon,
  StyledPageContainer,
  StyledLoaderContainer,
  StyledTitle,
  StyledSearchWrapper,
  StyledSearchBar,
  StyledCheckboxWrapper,
  StyledListWrapper,
  StyledCycleCountChip,
} from './style';

import { searchOptions } from './constants';
import { manageSelectBrand } from './utils';

const PageCycleCountFilterBrand: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [brand, setBrand] = useState('');
  const isSohProcess = useSelector(state => state.cycleCount.sohLocked);

  const { store } = useSelector(state => state.currentStore);
  const { brandIsLoading, brandList, selectedBrands, commodityState } =
    useSelector(state => state.cycleCount);
  const [brandsToFilter, setBrandsToFilter] = useState<CustomBrand[]>([]);

  const fuse = new Fuse<CustomBrand>(brandList?.brand!, searchOptions);
  const [commodity, setCommodity] = useState<CycleCountSlice['commodityState']>(
    isSohProcess
      ? {
          sunglasses: false,
          frames: false,
          all: true,
        }
      : commodityState
  );

  useMount(() => dispatch(resetBrandsSettings()));

  useEffect(() => {
    if (brandIsLoading === undefined && selectedBrands.length === 0) {
      dispatch(getBrandsByStore());
    }
  }, [dispatch, brandIsLoading, selectedBrands.length]);

  useEffect(() => {
    if (brandsToFilter.length === 0 && brandList?.brand) {
      if (brand === '') {
        setBrandsToFilter(brandList.brand);
      }
    }
  }, [brandList?.brand, brandsToFilter, brand]);

  useEffect(() => {
    if (isSohProcess) {
      dispatch(changeCommodityState('all'));
      dispatch(changeCommodityState('sunglasses'));
      dispatch(changeCommodityState('frames'));
    }
  }, [dispatch, isSohProcess]);

  const filterNr = 0;

  useMount(() => {
    dispatch(setFilterSelected(filterNr));
  });

  const handleCommodityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (isSohProcess) {
      if (e.target.name !== 'all') {
        setCommodity(p => ({
          ...p,
          [e.target.name]: e.target.checked,
          all: false,
        }));

        if (commodityState['all']) {
          dispatch(changeCommodityState('all'));
        }

        dispatch(
          changeCommodityState(
            e.target.name as keyof CycleCountSlice['commodityState']
          )
        );
      } else {
        setCommodity({
          sunglasses: false,
          frames: false,
          all: true,
        });

        if (!commodityState['all']) {
          dispatch(changeCommodityState('all'));
        }

        if (commodityState['sunglasses']) {
          dispatch(changeCommodityState('sunglasses'));
        }

        if (commodityState['frames']) {
          dispatch(changeCommodityState('frames'));
        }
      }
    } else {
      setCommodity({ ...commodity, [e.target.name]: e.target.checked });
      dispatch(
        changeCommodityState(
          e.target.name as keyof CycleCountSlice['commodityState']
        )
      );
    }
  };

  const handleSelect = (selectedBrandCode: string): void => {
    let newFilteredBrands = [...brandsToFilter];
    const brandItem = brandsToFilter?.find(
      ({ brandCode }) => brandCode === selectedBrandCode
    );
    const brandItemIndex = brandsToFilter?.findIndex(
      ({ brandCode }) => brandCode === selectedBrandCode
    );

    if (brandItem) {
      dispatch(manageSelectBrand(brandItem, !brandItem.selected));
      newFilteredBrands[brandItemIndex] = {
        ...brandItem,
        selected: !brandItem.selected,
      };
    }

    setBrandsToFilter(newFilteredBrands);

    fuse.setCollection(newFilteredBrands);
  };

  const handleSelectAllClick = (): void => {
    let newFilteredBrands = [...brandsToFilter];

    if (brandsToFilter.filter(item => item.selected).length < 1) {
      newFilteredBrands = brandsToFilter.map(brandItem => ({
        ...brandItem,
        selected: true,
      }));

      dispatch(selectAllBrand(newFilteredBrands));
    } else {
      newFilteredBrands = brandsToFilter.map(brandItem => ({
        ...brandItem,
        selected: false,
      }));

      dispatch(deselectAllBrand(newFilteredBrands));
    }

    setBrandsToFilter(newFilteredBrands);
  };

  useDebounce(
    () => {
      if (store?.storeCode) {
        if (brand.length >= 3) {
          const filteredBrands = fuse.search(brand).map(({ item }) => item);
          setBrandsToFilter(filteredBrands);
        }

        if (brand.length === 0 && brandList?.brand) {
          fuse.setCollection(brandList.brand);
          setBrandsToFilter(brandList.brand);
        }
      }
    },
    1500,
    [brand]
  );

  const searchInputHandler: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    setBrand(value);
  };

  return (
    <>
      <StyledPageContainer>
        <StyledTitle font="heavy" size="lg">
          {isSohProcess ? t('filterYourSoh') : t('cycleCount.filtertitle')}
        </StyledTitle>
        <TabCycleCount index={0} />
        <StyledCheckboxWrapper>
          <Typography as="h1" font="heavy" size="lg">
            {t('cycleCount.selectcomodity')}
          </Typography>
          {brandList?.commodityList?.map(
            ({ commodityDescription, commodityCode }, index) =>
              commodityDescription && (
                <FormControlLabel
                  label={
                    <Trans
                      i18nKey={
                        commodityDescription === 'all'
                          ? 'all'
                          : isSohProcess
                          ? 'soh.commodity'
                          : 'cycleCount.commodity'
                      }
                      values={{
                        commodity:
                          commodityDescription.toLowerCase() === 'frames'
                            ? 'eyewear'
                            : commodityDescription,
                      }}
                    />
                  }
                  key={`${commodityCode}-${index}-commodity`}
                  control={
                    <Checkbox
                      checked={
                        commodity &&
                        commodity[
                          commodityDescription.toLowerCase() as keyof typeof commodity
                        ]
                      }
                      onChange={handleCommodityChange}
                      name={commodityDescription.toLowerCase()}
                      color="primary"
                    />
                  }
                />
              )
          )}
        </StyledCheckboxWrapper>
        <StyledSearchWrapper>
          <Typography font="heavy" as="h1" size="lg">
            {t('cycleCount.selectbrands')}
          </Typography>
          {!isSohProcess && (
            <Typography as="span" size="xs">
              <StyledErrorOutlineIcon width="18" fill="orange" />{' '}
              {t('cycleCount.selectdescription')}
            </Typography>
          )}
          <StyledSearchBar
            placeholder={t('cycleCount.serchbrand')}
            value={brand}
            onChange={searchInputHandler}
            disabled={true}
            loading={false}
            hideButton={true}
            label=""
            onSearch={(): void => {}}
          />
        </StyledSearchWrapper>
        <StyledListWrapper>
          <UIListHeader
            text={`${t('cycleCount.brand')}`}
            linkText={
              brandsToFilter.filter(item => item.selected).length < 1
                ? `${t('cycleCount.selectall')}`
                : `${t('cycleCount.deselectall')}`
            }
            extraText={`${t('cycleCount.qtycomodity')}`}
            onLinkClick={handleSelectAllClick}
          />
          {brandIsLoading ? (
            <StyledLoaderContainer>
              <UILoader />
            </StyledLoaderContainer>
          ) : (
            <UIList margin={[0, 0, 120]} padding={[0]}>
              {brandsToFilter?.map(
                (
                  {
                    brandCode,
                    brandDescription,
                    itemsToScan,
                    selected,
                    marketingTheme,
                    marketingStory,
                  },
                  index
                ) => (
                  <UIListItem
                    key={`${brandDescription}-${index}-items`}
                    onClick={(): void => handleSelect(brandCode!)}
                    text={brandDescription}
                    checked={selected}
                  >
                    {(marketingTheme && marketingTheme.length) ||
                    (marketingStory && marketingStory.length) ? (
                      <>
                        {!isSohProcess && (
                          <StyledHasMarketing>
                            <Typography as="p" size="xs">
                              <StyledCheckCircleOutlineIcon
                                width="14"
                                fill="green"
                              />{' '}
                              {t('cycleCount.hasmarketing')}
                            </Typography>
                          </StyledHasMarketing>
                        )}
                      </>
                    ) : (
                      <span />
                    )}
                    <StyledCycleCountChip
                      size="small"
                      label={
                        <Trans i18nKey="itemsToScan" values={{ itemsToScan }} />
                      }
                    />
                  </UIListItem>
                )
              )}
            </UIList>
          )}
        </StyledListWrapper>
        <CTAContainer
          type="APPLY"
          disabledMainAction={
            isSohProcess
              ? selectedBrands.length === 0 ||
                (!commodity?.sunglasses && !commodity?.frames && !commodity.all)
              : selectedBrands.length === 0 ||
                (!commodity?.sunglasses && !commodity?.frames)
          }
          onClick={(): void =>
            isSohProcess
              ? history.push(AppRoutes.SOH_ALIGNMENT_FILTER_SUMMARY)
              : history.push(AppRoutes.CYCLE_COUNT_FILTER_MARKETING)
          }
          onBackClick={(): void => {
            if (isSohProcess) {
              history.push(AppRoutes.SOH_ALIGNMENT);
            } else {
              dispatch(initCycleCountState());
              history.push(AppRoutes.CYCLE_COUNT);
            }
          }}
        />
      </StyledPageContainer>
    </>
  );
};

PageCycleCountFilterBrand.displayName = 'PageCycleCountFilterBrand';

export default PageCycleCountFilterBrand;
