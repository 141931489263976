import { FC, memo } from 'react';
import { useSelector } from '@/hooks/useSelector';
import styled from 'styled-components';

import { AppBar } from '@material-ui/core';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';

import { ReactComponent as LogoLux } from '@/assets/images/logo_luxottica.svg';
import { TColorName } from '@/theme';
import { useLocation } from 'react-router';
import { AppRoutes } from '@/app/routers';

export interface HeaderLiteProps {
  color: 'default' | 'inherit' | 'primary' | 'secondary' | 'transparent';
  position: 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky';
}

const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }): TColorName => theme.palette.colors.white};
`;

const StyledLogo = styled(LogoLux)`
  margin-right: 24px;
`;

const StyledTopContainerBox = styled(UIBox)`
  padding: 24px;
  height: 88px;
`;

const StyledTextContainerBox = styled(UIBox)`
  height: 43px;
  justify-content: space-between;
`;

export const HeaderLite: FC<HeaderLiteProps> = memo(
  (props: HeaderLiteProps): JSX.Element => {
    const { store } = useSelector(state => state.currentStore);
    const { pathname } = useLocation();

    return (
      <StyledAppBar {...props} elevation={0}>
        <StyledTopContainerBox justifyContent="space-between">
          <UIBox alignItems="flex-start">
            <StyledLogo />
            <StyledTextContainerBox flexDirection="column">
              <Typography size="xl">RFeyeD</Typography>
              {store && pathname !== AppRoutes.MAINTENANCE ? (
                <UIBox>
                  <Typography size="sm" font="heavy">
                    {store?.storeDescription}
                  </Typography>
                  <Typography size="sm">
                    &nbsp;|&nbsp;{store?.storeAddress}
                  </Typography>
                </UIBox>
              ) : null}
            </StyledTextContainerBox>
          </UIBox>
        </StyledTopContainerBox>
      </StyledAppBar>
    );
  }
);

HeaderLite.displayName = 'HeaderLite';

export default HeaderLite;
