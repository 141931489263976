import { FC, memo } from 'react';
import styled from 'styled-components';
import { SvgIcon, Theme, Typography } from '@material-ui/core';
import {
  Edit,
  AddCircleOutline,
  DeleteOutline,
  Print,
  CheckCircle,
} from '@material-ui/icons';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import SearchIcon from '@material-ui/icons/Search';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useTheme } from '@material-ui/core/styles';

const Icons = {
  add: AddCircleOutline,
  delete: DeleteOutline,
  print: Print,
  edit: Edit,
  check: CheckCircle,
  confirm: ThumbUpOutlinedIcon,
  backupRestore: SettingsBackupRestoreIcon,
  search: SearchIcon,
  unlock: LockOpenIcon,
};
interface RowActionProps {
  isVisible?: boolean;
  disabled?: boolean;
  theme: Theme;
}
export interface UIActionProps extends React.ComponentPropsWithRef<'div'> {
  label: string;
  disabled?: boolean;
  icon:
    | 'add'
    | 'delete'
    | 'print'
    | 'edit'
    | 'check'
    | 'confirm'
    | 'backupRestore'
    | 'search'
    | 'unlock';
  onClick?: () => void;
  isVisible?: boolean;
  fill?: string;
}

const Row = styled.div<RowActionProps>`
  flex-direction: row;
  align-items: center;
  display: ${({ isVisible }): string => (isVisible ? 'flex' : 'none')};
  cursor: ${({ disabled }): string => (disabled ? 'auto' : 'pointer')};

  & > * {
    color: ${(props: RowActionProps): string =>
      props.disabled
        ? props.theme.palette.action.disabled
        : props.theme.palette.primary.main};
  }
`;

const StyledSvgIcon = styled(({ ...props }) => <SvgIcon {...props} />)`
  margin-left: 5px;
`;

export const UIAction: FC<UIActionProps> = memo(
  ({
    label,
    icon,
    disabled,
    onClick,
    isVisible = true,
    ...props
  }): JSX.Element => {
    const theme = useTheme();

    return (
      <Row
        onClick={(): void => {
          !disabled && onClick?.();
        }}
        disabled={disabled}
        isVisible={isVisible}
        theme={theme}
        {...props}
      >
        <Typography>{label}</Typography>
        <StyledSvgIcon
          component={Icons[icon]}
          color={disabled ? 'disabled' : 'primary'}
          fill={props.fill}
        />
      </Row>
    );
  }
);

UIAction.displayName = 'UIAction';

export default UIAction;
