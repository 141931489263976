import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const StyledAddRoleContainer = styled.div`
  padding: 132px 20px;
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  margin-top: 28px;
`;

export const StyledLoaderWrapper = styled.div`
  margin: 28px 0;
`;
