import { CycleDetailsItems } from '@/api/receive';
import { PaginationList } from '@/components/layout/PaginationList';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ListContainer, LIWrapper } from './style';
import { IListCCSelect } from './types';

export default function ListCCSelect({
  data,
  component,
  keyName,
  checkedElements,
  exportCheckedElements,
}: IListCCSelect): JSX.Element {
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const { t } = useTranslation();

  const upcCodes = useMemo(() => data.map(({ upcCode }) => upcCode), [data]);

  const modelCodes = useMemo(
    () => data.map(({ upcCode, modelCode }) => `${upcCode}|${modelCode}`),
    [data]
  );

  const epcCodes = useMemo(
    () =>
      data.flatMap(({ epcCodes }) => epcCodes).map(({ epcCode }) => epcCode),
    [data]
  );

  const checkedUpcCodes = useMemo(() => {
    return upcCodes.filter(upcCode => checkedElements[upcCode]);
  }, [checkedElements, upcCodes]);

  const checkedModelCodes = useMemo(() => {
    return modelCodes.filter(modelCode => checkedElements[modelCode]);
  }, [checkedElements, modelCodes]);

  const checkedEpcCodes = useMemo(() => {
    return epcCodes.filter(epcCode => checkedElements[epcCode]);
  }, [checkedElements, epcCodes]);

  useEffect(() => {
    if (
      checkedEpcCodes.length === epcCodes.length ||
      checkedUpcCodes.length === upcCodes.length ||
      checkedModelCodes.length === modelCodes.length
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [
    checkedEpcCodes.length,
    checkedModelCodes.length,
    checkedUpcCodes.length,
    epcCodes.length,
    modelCodes.length,
    upcCodes.length,
  ]);

  const selectAllBasedOnType = (): void => {
    const codes = {
      upcCodes,
      epcCodes,
      modelCodes,
    };

    const checkElements = (key: keyof typeof codes): void => {
      exportCheckedElements?.(
        codes[key].reduce(
          (acc, el) => ({ ...acc, ...{ [el]: !selectAll } }),
          {}
        )
      );
    };

    switch (keyName) {
      case 'upcCode':
        return checkElements('upcCodes');

      case 'epcCode':
        return checkElements('epcCodes');

      default:
        return checkElements('modelCodes');
    }
  };

  const selectAllHandler = (): void => {
    setSelectAll(p => !p);
    selectAllBasedOnType();
  };

  const checkedKeyType = (product: CycleDetailsItems): string => {
    switch (keyName) {
      case 'upcCode':
        return product.upcCode;

      case 'epcCode':
        return product.epcCodes[0].epcCode;

      default:
        return `${product.upcCode}|${product.modelCode}`;
    }
  };

  const selectAllOnType = (): { count: number; selectedAll: boolean } => {
    switch (keyName) {
      case 'upcCode':
        return {
          count: checkedUpcCodes.length,
          selectedAll: checkedUpcCodes.length === upcCodes.length,
        };

      case 'epcCode':
        return {
          count: checkedEpcCodes.length,
          selectedAll: checkedEpcCodes.length === epcCodes.length,
        };

      default:
        return {
          count: checkedModelCodes.length,
          selectedAll: checkedModelCodes.length === modelCodes.length,
        };
    }
  };

  return (
    <ListContainer>
      {data.length > 0 && (
        <React.Fragment>
          <div>
            <Link to="#" onClick={selectAllHandler}>
              {!selectAllOnType().selectedAll
                ? t('cycleCount.selectList.selectAll')
                : t('cycleCount.selectList.deselectAll')}
            </Link>{' '}
            {t('cycleCount.selectList.nrSelected', {
              numberSelected: selectAllOnType().count,
            })}
          </div>

          <LIWrapper>
            <PaginationList
              data={data || []}
              pageSize={25}
              renderItem={(
                el: NonNullable<CycleDetailsItems>,
                index: number
              ): JSX.Element => (
                <React.Fragment key={index}>
                  {component(
                    el as CycleDetailsItems,
                    `${keyName}-${index}`,
                    checkedElements[checkedKeyType(el)]
                  )}
                </React.Fragment>
              )}
            />
          </LIWrapper>
        </React.Fragment>
      )}
    </ListContainer>
  );
}
