import React, { FC, memo } from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

import type { BoxProps } from '@material-ui/core';

export interface UIBoxProps extends BoxProps {
  children: React.ReactNode;
  flexDirection?: string;
  flexWrap?: string;
  justifyContent?: string;
  alignItems?: string;
  alignContent?: string;
  order?: string;
  flex?: string;
  flexGrow?: string;
  flexShrink?: string;
  alignSelf?: string;
}

const StyledBox = styled(Box)``;

export const UIBox: FC<UIBoxProps> = memo(
  ({ children, ...rest }: UIBoxProps): JSX.Element => {
    return (
      <StyledBox display="flex" {...rest}>
        {children}
      </StyledBox>
    );
  }
);

UIBox.displayName = 'Box';

export default UIBox;
