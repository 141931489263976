import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getCurrentDate from '@/utils/getCurrentDate';

import {
  ApiError,
  ConfimCreateDamages,
  ConfimCreateDamagesPosRequest,
  ConfimCreateDamagesResponse,
  DamagesAndReturnsService,
  EpcDamagesAndReturns,
  ReasonList,
  SearchItemsForCreateDamages,
  SearchItemsForCreateDamagesList,
} from '@/api/receive';
import type {
  MovementReason,
  StoreDamageInitialState,
} from '@/types/storeDamages';
import type { RootState } from '@/app/rootReducer';

const initialState: StoreDamageInitialState = {
  damageItems: [],
  backup: [],
  reasons: [],
  selectedUpcs: [],
  selectedEpcs: [],
  pdfCode: '',
};

export const fetchDamageReasons = createAsyncThunk<
  ReasonList,
  void,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'storeDamages/fetchDamageReasons',
  async (_, { rejectWithValue, getState }) => {
    const storeCode = getState().currentStore.store?.storeCode;

    try {
      if (storeCode) {
        const response =
          await DamagesAndReturnsService.damagesGetCreateDamagesReasonCode({
            storeCode,
          });

        if (response) {
          return response;
        }

        return {
          reason: [],
          process: '',
        };
      }

      return {
        reasons: [],
        process: '',
      };
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

export const fetchDamageItems = createAsyncThunk<
  SearchItemsForCreateDamagesList,
  Omit<Omit<SearchItemsForCreateDamages, 'storeCode'>, 'date'>,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'storeDamages/fetchDamageItems',
  async (requestBody, { rejectWithValue, getState }) => {
    const date = getCurrentDate();
    const { store } = getState().currentStore;

    if (store?.storeCode) {
      try {
        const response =
          await DamagesAndReturnsService.damagesSearchItemsForCreateDamages({
            requestBody: {
              ...requestBody,
              storeCode: store.storeCode,
              date,
            },
          });

        if (response) {
          return response;
        }

        return {
          productDetailsItems: [],
        };
      } catch (err) {
        return rejectWithValue(err as ApiError);
      }
    }

    return {
      productDetailsItems: [],
    };
  }
);

export const fetchDamageItemsToValidate = createAsyncThunk<
  SearchItemsForCreateDamagesList,
  void,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'storeDamages/fetchDamageItemsToValidate',
  async (_, { rejectWithValue, getState }) => {
    const date = getCurrentDate();
    const { store } = getState().currentStore;

    if (store?.storeCode) {
      try {
        const response =
          await DamagesAndReturnsService.damagesFindItemsForValidateDamages({
            storeCode: store.storeCode,
            date,
          });

        if (response) {
          return response;
        }

        return {
          productDetailsItems: [],
        };
      } catch (err) {
        return rejectWithValue(err as ApiError);
      }
    }

    return {
      productDetailsItems: [],
    };
  }
);

export const confirmDamagedItems = createAsyncThunk<
  ConfimCreateDamagesResponse,
  ConfimCreateDamages,
  {
    rejectValue: ApiError;
  }
>(
  'storeDamages/confirmDamagedItems',
  async (requestBody, { rejectWithValue }) => {
    try {
      const response =
        await DamagesAndReturnsService.damagesConfirmCreateDamagesEncoded({
          requestBody: {
            confirmDamagesAndReturnsEncoded: btoa(JSON.stringify(requestBody)),
          },
        });

      if (response) {
        return response;
      }

      return {
        documentNumber: '',
      };
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

export const confirmValidateDamages = createAsyncThunk<
  ConfimCreateDamagesResponse,
  ConfimCreateDamages,
  {
    rejectValue: ApiError;
  }
>(
  'storeDamages/confirmValidateDamages',
  async (requestBody, { rejectWithValue }) => {
    try {
      const response =
        await DamagesAndReturnsService.damagesConfirmValidateDamagesEncoded({
          requestBody: {
            confirmDamagesAndReturnsEncoded: btoa(JSON.stringify(requestBody)),
          },
        });

      if (response) {
        return response;
      }

      return {
        documentNumber: '',
      };
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

export const backToStockDamagedItems = createAsyncThunk<
  ConfimCreateDamagesResponse,
  ConfimCreateDamages,
  {
    rejectValue: ApiError;
  }
>(
  'storeDamages/backToStockDamagedItems',
  async (requestBody, { rejectWithValue }) => {
    try {
      const response =
        await DamagesAndReturnsService.damagesConfirmBackToStockEncoded({
          requestBody: {
            confirmDamagesAndReturnsEncoded: btoa(JSON.stringify(requestBody)),
          },
        });

      if (response) {
        return response;
      }

      return {
        documentNumber: '',
      };
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

export const confirmGoodCondtionItems = createAsyncThunk<
  ConfimCreateDamagesResponse,
  ConfimCreateDamages | ConfimCreateDamagesPosRequest,
  {
    rejectValue: ApiError;
  }
>(
  'storeDamages/confirmGoodCondtionItems',
  async (requestBody, { rejectWithValue }) => {
    try {
      const response =
        await DamagesAndReturnsService.damagesConfirmGoodBackReturnEncoded({
          requestBody: {
            confirmDamagesAndReturnsEncoded: btoa(JSON.stringify(requestBody)),
          },
        });

      if (response) {
        return response;
      }

      return {
        documentNumber: '',
      };
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

export const confirmBadCondtionItems = createAsyncThunk<
  ConfimCreateDamagesResponse,
  ConfimCreateDamages | ConfimCreateDamagesPosRequest,
  {
    rejectValue: ApiError;
  }
>(
  'storeDamages/confirmBadCondtionItems',
  async (requestBody, { rejectWithValue }) => {
    try {
      const response =
        await DamagesAndReturnsService.damagesConfirmBadConditionEncoded({
          requestBody: {
            confirmDamagesAndReturnsEncoded: btoa(JSON.stringify(requestBody)),
          },
        });

      if (response) {
        return response;
      }

      return {
        documentNumber: '',
      };
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

const storeDamagesSlice = createSlice({
  name: 'storeDamages',
  initialState,
  reducers: {
    initSelectedItems: state => {
      state.selectedUpcs = [];
      state.selectedEpcs = [];
    },
    selectUpc: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedUpcs = [...new Set([...state.selectedUpcs, ...payload])];
    },
    deselectUpc: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedUpcs = state.selectedUpcs.filter(
        upcCode => !payload.includes(upcCode)
      );
    },
    selectAllEpcByUpc: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedEpcs = [...new Set([...state.selectedEpcs, ...payload])];
    },
    deselectAllEpcByUpc: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedEpcs = state.selectedEpcs.filter(
        epcCode => !payload.includes(epcCode)
      );
    },
    selectEpc: (state, { payload }: PayloadAction<string>) => {
      state.selectedEpcs = [...new Set([...state.selectedEpcs, payload])];
    },
    removeEpcs: state => {
      const selectedEpcs = state.selectedEpcs;

      state.backup = state.damageItems;
      state.damageItems = state.damageItems
        ?.map(item => ({
          ...item,
          epcCodes: item.epcCodes.filter(
            ({ epcCode }) => !selectedEpcs.includes(epcCode)
          ),
        }))
        .filter(({ epcCodes }) => epcCodes.length > 0);
      state.selectedEpcs = [];
    },
    undoRemovedEpcs: state => {
      state.damageItems = state.backup;
    },
    deselectEpc: (state, { payload }: PayloadAction<string>) => {
      const epcSelected = state.selectedEpcs.includes(payload);

      if (epcSelected) {
        state.selectedEpcs = state.selectedEpcs.filter(
          epcCode => epcCode !== payload
        );
      }
    },
    changeEpcReasons: (state, { payload }: PayloadAction<MovementReason>) => {
      const selectedEpcs = state.selectedEpcs;

      if (selectedEpcs.length > 0 && state.damageItems) {
        for (const epcCode of selectedEpcs) {
          const damagedEpcs = state.damageItems.flatMap(
            ({ epcCodes }) => epcCodes
          );
          const ddamageEpcIndex = damagedEpcs.findIndex(
            item => item.epcCode === epcCode
          );

          if (ddamageEpcIndex !== -1) {
            const damageEpc = damagedEpcs[ddamageEpcIndex];

            damageEpc.movementReason = payload.movementReason;
            damageEpc.movementTypeCode = payload.movementTypeCode;
            damageEpc.movementReasonCode = payload.movementReasonCode;
          }
        }
      }
    },
    cleanBackup: state => {
      state.backup = [];
    },
    initDamagesReturnsState: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDamageReasons.pending, state => {
        state.fetchReasonsIsLoading = true;
        state.fetchReasonsHasError = undefined;
      })
      .addCase(fetchDamageReasons.fulfilled, (state, { payload }) => {
        state.fetchReasonsIsLoading = false;
        state.reasons = payload.reasons || [];
      })
      .addCase(fetchDamageReasons.rejected, (state, { payload }) => {
        state.fetchReasonsIsLoading = false;
        state.fetchReasonsHasError = payload as ApiError;
      })
      .addCase(fetchDamageItems.pending, state => {
        state.fetchDamageItemsIsLoading = true;
        state.fetchDamageItemsHasError = undefined;
      })
      .addCase(fetchDamageItems.fulfilled, (state, { payload }) => {
        state.fetchDamageItemsIsLoading = false;
        state.fetchDamageItemsHasError = undefined;

        if (payload.productDetailsItems && state.damageItems) {
          for (const item of payload.productDetailsItems) {
            const upc = state.damageItems.findIndex(
              ({ upcCode }) => upcCode === item.upcCode
            );

            if (upc > -1) {
              state.damageItems[upc].epcCodes = state.damageItems[
                upc
              ].epcCodes.concat(item.epcCodes);
              state.damageItems[upc].epcCodes = Array.from(
                state.damageItems[upc].epcCodes
                  .reduce((m, o) => {
                    if (!m.has(o.epcCode!)) m.set(o.epcCode!, o);
                    return m;
                  }, new Map<string, EpcDamagesAndReturns>())
                  .values()
              );
            } else {
              state.damageItems = [...state.damageItems, item];
            }
          }
        }
      })
      .addCase(fetchDamageItems.rejected, (state, { payload }) => {
        state.fetchDamageItemsIsLoading = false;
        state.fetchDamageItemsHasError = payload as ApiError;
      })
      .addCase(fetchDamageItemsToValidate.pending, state => {
        state.fetchDamageItemsToValidateIsLoading = true;
        state.fetchDamageItemsToValidateHasError = undefined;
      })
      .addCase(fetchDamageItemsToValidate.fulfilled, (state, { payload }) => {
        state.fetchDamageItemsToValidateIsLoading = false;
        state.fetchDamageItemsToValidateHasError = undefined;
        state.damageItems = payload.productDetailsItems || [];
      })
      .addCase(fetchDamageItemsToValidate.rejected, (state, { payload }) => {
        state.fetchDamageItemsToValidateIsLoading = false;
        state.fetchDamageItemsToValidateHasError = payload as ApiError;
      })
      .addCase(confirmDamagedItems.pending, state => {
        state.confirmDamagedItemsIsLoading = true;
        state.confirmDamagedItemsHasError = undefined;
      })
      .addCase(confirmDamagedItems.fulfilled, (state, { payload }) => {
        state.confirmDamagedItemsIsLoading = false;
        state.confirmDamagedItemsHasError = undefined;
        state.pdfCode = payload.documentNumber;
      })
      .addCase(confirmDamagedItems.rejected, (state, { payload }) => {
        state.confirmDamagedItemsIsLoading = false;
        state.confirmDamagedItemsHasError = payload as ApiError;
      })
      .addCase(confirmValidateDamages.pending, state => {
        state.confirmValidateDamagesIsLoading = true;
        state.confirmValidateDamagesHasError = undefined;
      })
      .addCase(confirmValidateDamages.fulfilled, (state, { payload }) => {
        state.confirmValidateDamagesIsLoading = false;
        state.confirmValidateDamagesHasError = undefined;
        state.pdfCode = payload.documentNumber;
      })
      .addCase(confirmValidateDamages.rejected, (state, { payload }) => {
        state.confirmValidateDamagesIsLoading = false;
        state.confirmValidateDamagesHasError = payload as ApiError;
      })
      .addCase(backToStockDamagedItems.pending, state => {
        state.backToStockDamagedItemsIsLoading = true;
        state.backToStockDamagedItemsHasError = undefined;
      })
      .addCase(backToStockDamagedItems.fulfilled, (state, { payload }) => {
        state.backToStockDamagedItemsIsLoading = false;
        state.backToStockDamagedItemsHasError = undefined;
        state.pdfCode = payload.documentNumber;
      })
      .addCase(backToStockDamagedItems.rejected, (state, { payload }) => {
        state.backToStockDamagedItemsIsLoading = false;
        state.backToStockDamagedItemsHasError = payload as ApiError;
      })
      .addCase(confirmBadCondtionItems.pending, state => {
        state.confirmBadConditionIsLoading = true;
        state.confirmBadConditionHasError = undefined;
      })
      .addCase(confirmBadCondtionItems.fulfilled, (state, { payload }) => {
        state.confirmBadConditionIsLoading = false;
        state.confirmBadConditionHasError = undefined;
        state.pdfCode = payload.documentNumber;
      })
      .addCase(confirmBadCondtionItems.rejected, (state, { payload }) => {
        state.confirmBadConditionIsLoading = false;
        state.confirmBadConditionHasError = payload as ApiError;
      })
      .addCase(confirmGoodCondtionItems.pending, state => {
        state.confirmGoodConditionIsLoading = true;
        state.confirmGoodConditionHasError = undefined;
      })
      .addCase(confirmGoodCondtionItems.fulfilled, (state, { payload }) => {
        state.confirmGoodConditionIsLoading = false;
        state.confirmGoodConditionHasError = undefined;
        state.pdfCode = payload.documentNumber;
      })
      .addCase(confirmGoodCondtionItems.rejected, (state, { payload }) => {
        state.confirmGoodConditionIsLoading = false;
        state.confirmGoodConditionHasError = payload as ApiError;
      });
  },
});

export const {
  initSelectedItems,
  removeEpcs,
  undoRemovedEpcs,
  selectUpc,
  deselectUpc,
  cleanBackup,
  selectAllEpcByUpc,
  deselectAllEpcByUpc,
  selectEpc,
  deselectEpc,
  changeEpcReasons,
  initDamagesReturnsState,
} = storeDamagesSlice.actions;
export default storeDamagesSlice.reducer;
