/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfirmSoldItemsRequest } from '../models/ConfirmSoldItemsRequest';
import type { DownloadCsvPosExceptionResponse } from '../models/DownloadCsvPosExceptionResponse';
import type { GenerateTokenRequest } from '../models/GenerateTokenRequest';
import type { GenerateTokenResponse } from '../models/GenerateTokenResponse';
import type { PosRequest } from '../models/PosRequest';
import type { RemovePosRequest } from '../models/RemovePosRequest';
import type { RemovePosRequestEncoded } from '../models/RemovePosRequestEncoded';
import type { RemovePosSyntacticExceptionRequest } from '../models/RemovePosSyntacticExceptionRequest';
import type { RetrieveExceptionOnFileRequest } from '../models/RetrieveExceptionOnFileRequest';
import type { SavePosExceptionRequest } from '../models/SavePosExceptionRequest';
import type { SearchPosExceptionResponse } from '../models/SearchPosExceptionResponse';
import type { SearchReturnItemsRequest } from '../models/SearchReturnItemsRequest';
import type { SearchReturnItemsResponse } from '../models/SearchReturnItemsResponse';
import type { SearchSaleExceptionRequest } from '../models/SearchSaleExceptionRequest';
import type { SearchSaleExceptionResponse } from '../models/SearchSaleExceptionResponse';
import { request as __request } from '../core/request';

export class PosService {
  /**
   * The following service generates bearer token usedto access APIs (SRO-EVENT-COLLECTOR)
   * generate token
   * @returns GenerateTokenResponse Bearer token
   * @throws ApiError
   */
  public static async authGenerateToken({
    requestBody,
  }: {
    requestBody: GenerateTokenRequest;
  }): Promise<GenerateTokenResponse> {
    const result = await __request({
      method: 'POST',
      path: `/collector/auth/generateToken`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Internal server error.`,
      },
    });
    return result.body;
  }

  /**
   * SA wants to CONFIRM a check out process with PoS (SRO-EVENT-COLLECTOR)
   * Confirm confirmPosCheckout after scan QR
   * @returns any Checkout Received
   * @throws ApiError
   */
  public static async posConfirmPosCheckout({
    requestBody,
  }: {
    requestBody: Array<PosRequest>;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/collector/pos/confirmPosCheckout`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * retrive pos exception to admin exception page (admin view)
   * retrive pos exception (admin view)
   * @returns SearchPosExceptionResponse OK
   * @throws ApiError
   */
  public static async posSearchPosException(): Promise<SearchPosExceptionResponse> {
    const result = await __request({
      method: 'GET',
      path: `/pos/searchPosException`,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * retrive sales pos exception to sale exception page
   * retrive sales pos exception to sale exception page
   * @returns SearchSaleExceptionResponse OK
   * @throws ApiError
   */
  public static async posSearchSaleException({
    requestBody,
  }: {
    requestBody: SearchSaleExceptionRequest;
  }): Promise<SearchSaleExceptionResponse> {
    const result = await __request({
      method: 'POST',
      path: `/pos/searchSaleException`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * retrive list of return items (flow types accepted POS RETURN EXCEPTION, POS_VOID_SALE_EXCEPTION, POS_RETURN, POS VOID SALE)
   * retrive list of return items
   * @returns SearchReturnItemsResponse OK
   * @throws ApiError
   */
  public static async posSearchReturnItems({
    requestBody,
  }: {
    requestBody: SearchReturnItemsRequest;
  }): Promise<SearchReturnItemsResponse> {
    const result = await __request({
      method: 'POST',
      path: `/pos/searchReturnItems`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * save PoS exception (admin view)
   * save PoS exception (admin view)
   * @returns any OK
   * @throws ApiError
   */
  public static async posSavePosException({
    requestBody,
  }: {
    requestBody: Array<SavePosExceptionRequest>;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/pos/savePosException`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        406: `Attention! You are trying to manage an exception that another user has already managed  / Attention! Exception already managed by another user.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Remove pos syntactic exception (admin view)
   * Remove pos syntatic exception (admin view)
   * @returns any OK
   * @throws ApiError
   */
  public static async posRemovePosSyntacticException({
    requestBody,
  }: {
    requestBody: RemovePosSyntacticExceptionRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/pos/removePosSyntacticException`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        406: `Attention! You are trying to manage an exception that another user has already managed  / Attention! Exception already managed by another user.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Remove pos item
   * Remove pos item
   * @returns any OK
   * @throws ApiError
   */
  public static async posRemovePosItemEncoded({
    requestBody,
  }: {
    requestBody: RemovePosRequestEncoded;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/pos/removePosItemEncoded`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Remove pos item
   * Remove pos item
   * @returns any OK
   * @throws ApiError
   */
  public static async posRemovePosItem({
    requestBody,
  }: {
    requestBody: Array<RemovePosRequest>;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/pos/removePosItem`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * confirm sold items
   * confirm sold items
   * @returns any OK
   * @throws ApiError
   */
  public static async posConfirmSoldItems({
    requestBody,
  }: {
    requestBody: ConfirmSoldItemsRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/pos/confirmSoldItems`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * clear raw pos data by stores and concurrency
   * This methods get data from pos raw table filtered by storeCodes and sends them to sro-event-collect only if there aren't pos and inventory processes active. If the data is accepted by the server then it is deleted from table
   * @returns any OK
   * @throws ApiError
   */
  public static async posClearRawPosDataByStoresAndConcurrency({
    requestBody,
  }: {
    requestBody: Array<string>;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/pos/clearRawPosDataByStoresAndConcurrency`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * clear raw pos data by active stores and concurrency
   * This methods get data from pos raw table filtered by active store, pos process off and inventory off and sends them to sro-event-collect. If the data is accepted by the server then it is deleted from table
   * @returns any OK
   * @throws ApiError
   */
  public static async posClearRawPosDataByActiveStoresAndConcurrency(): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/pos/clearRawPosDataByActiveStoresAndConcurrency`,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * send pos adj by store
   * send pos adj by store
   * @returns any OK
   * @throws ApiError
   */
  public static async posSendPosSapAdj({
    requestBody,
  }: {
    requestBody: {
      storeCode?: string;
    };
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/pos/sendPosSapAdj`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * download csv (Pos Sales Exception CSV)
   * download csv (Pos Sales Exception CSV)
   * @returns DownloadCsvPosExceptionResponse OK.
   * @throws ApiError
   */
  public static async posRetrieveExceptionOnFile({
    requestBody,
  }: {
    requestBody: RetrieveExceptionOnFileRequest;
  }): Promise<DownloadCsvPosExceptionResponse> {
    const result = await __request({
      method: 'POST',
      path: `/pos/retrieveExceptionOnFile`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
