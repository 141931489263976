import { AppRoutes } from '@/app/routers';
import { Tabs } from '@/components/layout/Tabs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useUpdateEffect } from 'react-use';
import { ITabCycleCount } from './types';
import { useSelector } from '@/hooks/useSelector';

const TabCycleCount: React.FC<ITabCycleCount> = ({ index }: ITabCycleCount) => {
  const [selectedTab, setSelectedTab] = useState<number>(index);
  const isSohProcess = useSelector(state => state.cycleCount.sohLocked);

  const { t } = useTranslation();
  const history = useHistory();

  const CYCLE_COUNT_FILTER_TITLES = [
    t('cycleCount.filter.brand'),
    t('cycleCount.filter.UPC'),
    t('cycleCount.filter.EPC'),
    t('cycleCount.filter.model'),
  ];

  const SOH_ALIGNMENT_FILTER_TITLES = [
    t('cycleCount.filter.brand'),
    t('cycleCount.filter.UPC'),
  ];

  const PATH_CYCLE_COUNT = [
    AppRoutes.CYCLE_COUNT_FILTER_BRAND,
    AppRoutes.CYCLE_COUNT_FILTER_UPC,
    AppRoutes.CYCLE_COUNT_FILTER_EPC,
    AppRoutes.CYCLE_COUNT_FILTER_MODEL,
  ];

  const PATH_SOH_ALIGNMENT = [
    AppRoutes.SOH_ALIGNMENT_FILTER_BRAND,
    AppRoutes.SOH_ALIGNMENT_FILTER_UPC,
  ];

  useUpdateEffect(() => {
    history?.push(
      isSohProcess
        ? PATH_SOH_ALIGNMENT[selectedTab]
        : PATH_CYCLE_COUNT?.[selectedTab]
    );
  }, [selectedTab, history]);

  return (
    <Tabs
      values={
        isSohProcess ? SOH_ALIGNMENT_FILTER_TITLES : CYCLE_COUNT_FILTER_TITLES
      }
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
    />
  );
};

export default TabCycleCount;
