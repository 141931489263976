import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BreadcrumbsItem } from '@/types/breadcrumbs';

export interface BreadcrumbsState {
  items: BreadcrumbsItem[];
}

const initialState: BreadcrumbsState = {
  items: [],
};

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    update: (
      state,
      action: PayloadAction<Omit<BreadcrumbsItem, 'onClick'>[]>
    ) => {
      state.items = action.payload;
    },
    addItem: (
      state,
      { payload }: PayloadAction<Omit<BreadcrumbsItem, 'onClick'>>
    ) => {
      if (!state.items.some(({ title }) => title === payload.title)) {
        state.items.push(payload);
      }
    },
    reset: () => initialState,
  },
});

export const { addItem, update, reset } = breadcrumbsSlice.actions;
export default breadcrumbsSlice.reducer;
