import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({});
const reactPlugin = new ReactPlugin();

const disableTelemetry = process.env.REACT_APP_DISABLE_INSIGHTS === 'true';
const connectionString =
  process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING;

const appInsights = new ApplicationInsights({
  config: {
    connectionString,
    disableTelemetry,
    extensions: [reactPlugin],
    maxBatchInterval: 1000,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

try {
  // XXX: load appinsight only when enabled
  if (!disableTelemetry) appInsights.loadAppInsights();
} catch (e) {
  console.error(e);
  console.warn(e);
}

export { reactPlugin, appInsights };
