import React, { FC } from 'react';
import { RoleListItemProps, UpdateRoleCreator } from './types';
import {
  RoleListItemStyled,
  LinkEditStyled,
  ActionContainerStyled,
} from './style';
import { useHistory } from 'react-router';
import { makeUrl } from '@/utils/links';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { AppRoutes } from '@/app/routers';
import { useTranslation } from 'react-i18next';
import { ROLES_HIDE_DELETE, ROLES_HIDE_EDIT } from './constants';

export const RoleListItem: FC<RoleListItemProps> = ({ role, deleteFn }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const editHandler = (event: React.SyntheticEvent, role: string): void => {
    event.preventDefault();
    history.push(makeUrl<UpdateRoleCreator>(AppRoutes.UPDATE_ROLE, { role }));
  };

  return (
    <RoleListItemStyled>
      <div>{role}</div>

      <ActionContainerStyled>
        {!ROLES_HIDE_DELETE.includes(role.toLowerCase()) && (
          <LinkEditStyled href="#" onClick={(): void => deleteFn(role)}>
            {t('roleListItem.button.1')} <DeleteIcon fontSize="small" />
          </LinkEditStyled>
        )}

        {!ROLES_HIDE_EDIT.includes(role.toLowerCase()) && (
          <LinkEditStyled
            href="#"
            onClick={(event: React.SyntheticEvent): void =>
              editHandler(event, role)
            }
          >
            {t('roleListItem.button.0')} <EditIcon fontSize="small" />
          </LinkEditStyled>
        )}
      </ActionContainerStyled>
    </RoleListItemStyled>
  );
};

RoleListItem.displayName = 'RoleListItem';

export default RoleListItem;
