import { CTAContainer } from '@/components/layout/CTAContainer';
import { FC, useEffect, useState } from 'react';
import { useAsync, usePrevious } from 'react-use';
import { StyledDiv, ContainerDiv, TextFieldContainer } from './style';
import RadioButtonsGroup from '@/components/ui/RadioButtons/RadioButtons';
import GraficTextField from '@/components/ui/GraficTextField/GraficTextField';
import { useParams } from 'react-router-dom';
import { useSelector } from '@/hooks/useSelector';
import { UrlUpdateDevice } from './types';
import { mergeReadersAndPrinters } from '@/utils/devices';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '@/app/routers';
import { useTranslation } from 'react-i18next';
import { FieldDataValuesValues } from '@/types/fieldDataValues';
import { VALIDATION_SERIAL_NUMBER } from '@/configs/validation';
import { TableDevice } from '@/types/tableDevice';
import { useAppDispatch } from '@/app/store';
import {
  createDevice,
  resetCRUDDevice,
  updateDevice,
} from '@/features/devices/devicesSlice';
import { isEmpty } from '@/utils/validation';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { UrlUpdateStore } from '../PageStoreDetails/types';
import { makeUrl } from '@/utils/links';
import { ReceiveShipmentsService, StoreWithPrinters } from '@/api';
import { PrintDeviceService, ScanDeviceService } from '@/api/receive';
import { ApiError } from '@/api/bff';
import { setStore } from '@/features/stores/currentStoreSlice';

export const PageAddDevice: FC = (): JSX.Element => {
  const { device: id } = useParams<UrlUpdateDevice>() || { device: '' };
  const { store } = useParams<UrlUpdateStore>();

  const [currentStore, setCurrentStore] = useState<
    StoreWithPrinters | undefined
  >(undefined);

  const history = useHistory();
  const { t } = useTranslation();
  const { username } = useSelector(state => state.user);
  const {
    errorCreatingDevice,
    errorUpdatingDevice,
    isCreatedDevice,
    isUpdatedDevice,
  } = useSelector(state => state.devices);

  const dispatch = useAppDispatch();

  const [serialNumberDetails, setSerialNumberDetails] =
    useState<FieldDataValuesValues>({});
  const [nameDetails, setNameDetails] = useState<FieldDataValuesValues>({});
  const [deviceDetails, setDeviceDetails] = useState<FieldDataValuesValues>({});
  const [isRfidDetails, setIsRfidDetails] = useState<FieldDataValuesValues>({});
  const [isOpenedSnackbar, setIsOpenedSnackbar] = useState<boolean>(false);
  const [errorPrinterDevice, setErrorPrinterDevice] = useState<
    ApiError | undefined
  >();
  const [errorDevice, setErrorDevice] = useState<ApiError | undefined>();

  const [currentDevice, setCurrentDevice] = useState<TableDevice | undefined>(
    undefined
  );
  const [mergedDeviceList, setMergedDeviceList] = useState<TableDevice[]>([]);
  const prevRfidValue = usePrevious(isRfidDetails.currentVal);

  const isUpdateValue = !isEmpty(id);
  const existingDevices = mergedDeviceList
    .filter(
      device => device.id.toLowerCase() !== currentDevice?.id.toLowerCase()
    )
    .map(device => device.id);

  const radioButtonsDeviceTypeLabel = [
    { label: t('pageStoreAddDevice.deviceType.label.0'), value: 'reader' },
    { label: t('pageStoreAddDevice.deviceType.label.1'), value: 'printer' },
  ];
  const radioButtonIsRfidLabel = [
    { label: t('pageStoreAddDevice.isRfid.label.0'), value: 'y' },
    { label: t('pageStoreAddDevice.isRfid.label.1'), value: 'n' },
  ];

  const isPrinter =
    currentDevice?.type === 'printer' || deviceDetails.currentVal === 'printer';

  const validationOk =
    (nameDetails.isTouched &&
      !serialNumberDetails.isEmpty &&
      serialNumberDetails.isValid &&
      !nameDetails.isEmpty &&
      !deviceDetails.isEmpty &&
      !serialNumberDetails.notValidValue &&
      !(isPrinter && isRfidDetails.isEmpty)) ||
    // isUpdateValue is true in edit mode
    (isUpdateValue && isRfidDetails.currentVal !== prevRfidValue);

  useEffect(() => {
    if (isUpdateValue) {
      setCurrentDevice(mergedDeviceList.find(device => device.id === id));
    }
  }, [id, isUpdateValue, mergedDeviceList]);

  useEffect(() => {
    if (isUpdateValue && isPrinter && currentDevice) {
      setNameDetails(prev => ({
        ...prev,
        currentVal: currentDevice.name,
      }));
    }
  }, [currentDevice, isPrinter, isUpdateValue]);

  useEffect(() => {
    if (isCreatedDevice || isUpdatedDevice) {
      history.push(
        makeUrl<UrlUpdateStore>(AppRoutes.STORE_DETAILS, {
          store: store,
        })
      );
    }
  }, [isCreatedDevice, history, isUpdatedDevice, store]);

  useAsync(async () => {
    if (!store) history.push(AppRoutes.MANAGE_STORES);

    const current =
      await ReceiveShipmentsService.receiveshipReadStoreByStoreCode({
        storeCode: store,
      });
    if (store && current) {
      setCurrentStore(current);
      const deviceList =
        await ScanDeviceService.scandeviceSearchListScanDevices({
          storeId: store,
        });
      const devices = deviceList?.deviceList || [];
      setMergedDeviceList(
        mergeReadersAndPrinters(devices, current?.printers || [])
      );
    }
  }, [store, history]);

  useEffect(() => {
    if (typeof errorDevice !== 'undefined') {
      setIsOpenedSnackbar(true);
    } else {
      setIsOpenedSnackbar(false);
    }
  }, [errorDevice]);

  useEffect(() => {
    if (typeof errorPrinterDevice !== 'undefined') {
      setIsOpenedSnackbar(true);
    } else {
      setIsOpenedSnackbar(false);
    }
  }, [errorPrinterDevice]);

  useEffect(() => {
    if (
      isUpdateValue
        ? typeof errorUpdatingDevice !== 'undefined'
        : typeof errorCreatingDevice !== 'undefined'
    ) {
      dispatch(resetCRUDDevice());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialNumberDetails, dispatch]);

  const confirmClickHandler = async (): Promise<void> => {
    if (isUpdateValue) {
      if (currentDevice?.type === 'reader') {
        try {
          await dispatch(
            updateDevice({
              deviceId: serialNumberDetails.currentVal || '',
              statusDevice: 'offline',
              statusMode: 'inventory',
              storeId: currentStore?.storeCode || '',
              nameDevice: nameDetails.currentVal || '',
              userName: username,
            })
          ).unwrap();

          history.goBack();
        } catch (err) {
          setErrorDevice(err as ApiError);
        }
      } else {
        try {
          await PrintDeviceService.printUpdatePrintDevice({
            requestBody: {
              storeCode: store || '',
              prints: [
                {
                  printerName: nameDetails.currentVal || '',
                  printerCode: serialNumberDetails.currentVal || '',
                  isRfid: isRfidDetails.currentVal === 'y' ? true : false,
                },
              ],
            },
          });

          if (currentStore && currentStore.printers) {
            const printerIndex = currentStore.printers.findIndex(
              ({ printerCode }) =>
                printerCode === serialNumberDetails.currentVal
            );

            if (printerIndex > -1) {
              const updatedPrinters = [...currentStore.printers];

              updatedPrinters[printerIndex] = {
                printerName: nameDetails.currentVal || '',
                printerCode: serialNumberDetails.currentVal || '',
                isRfid: isRfidDetails.currentVal === 'y',
              };

              dispatch(
                setStore({
                  ...currentStore,
                  printers: updatedPrinters,
                })
              );
            }
          }

          history.goBack();
        } catch (err) {
          setErrorPrinterDevice(err as ApiError);
        }
      }
    } else {
      if (deviceDetails.currentVal === 'reader') {
        try {
          await dispatch(
            createDevice({
              deviceId: serialNumberDetails.currentVal || '',
              statusDevice: 'offline',
              statusMode: 'inventory',
              storeId: currentStore?.storeCode || '',
              nameDevice: nameDetails.currentVal || '',
              userName: username,
            })
          ).unwrap();

          history.goBack();
        } catch (err) {
          setErrorDevice(err as ApiError);
        }
      } else {
        try {
          await PrintDeviceService.printCreatePrintDevice({
            requestBody: {
              storeCode: store || '',
              prints: [
                {
                  printerName: nameDetails.currentVal || '',
                  printerCode: serialNumberDetails.currentVal || '',
                  isRfid: isRfidDetails.currentVal === 'y' ? true : false,
                },
              ],
            },
          });

          history.goBack();
        } catch (err) {
          setErrorPrinterDevice(err as ApiError);
        }
      }
    }
  };

  const backClickHandler = (): void => {
    history.push(
      makeUrl<UrlUpdateStore>(AppRoutes.STORE_DETAILS, {
        store: store,
      })
    );
  };

  return (
    <StyledDiv>
      <ErrorSnackbar
        setIsOpen={setIsOpenedSnackbar}
        open={isOpenedSnackbar}
        errorMessage={errorDevice?.body || errorPrinterDevice?.body}
      />
      <ContainerDiv>
        <RadioButtonsGroup
          title={t('pageStoreAddDevice.deviceType.title')}
          radios={radioButtonsDeviceTypeLabel}
          name="deviceType"
          ariaLabel="deviceType"
          defaultValue={deviceDetails?.currentVal || currentDevice?.type || ''}
          disabled={currentDevice !== null && currentDevice !== undefined}
          // setCurrentValue={setCurrentDeviceType}
          setValueDetails={setDeviceDetails}
        />
        {deviceDetails.currentVal !== '' && (
          <TextFieldContainer>
            <GraficTextField
              title={t('pageStoreAddDevice.name.title')}
              placeholder={t('pageStoreAddDevice.name.placeholder')}
              name="name"
              defaultValue={currentDevice?.name || ''}
              required
              updateMode="change"
              exportValue={setNameDetails}
            />
            <GraficTextField
              title={t('pageStoreAddDevice.serialNumber.title')}
              placeholder={t('pageStoreAddDevice.serialNumber.placeholder')}
              name="serialNumber"
              defaultValue={currentDevice?.id}
              required
              validation={VALIDATION_SERIAL_NUMBER}
              validationText={t('pageStoreAddDevice.serialNumber.wrongFormat')}
              exportValue={setSerialNumberDetails}
              updateMode="change"
              notValidValues={existingDevices}
              notValidValueText={t(
                'pageStoreAddDevice.serialNumber.existingValue'
              )}
              disabled={isUpdateValue}
            />
          </TextFieldContainer>
        )}
        {isPrinter && (
          <RadioButtonsGroup
            title={t('pageStoreAddDevice.isRfid.title')}
            radios={radioButtonIsRfidLabel}
            name="rfidPrinter"
            ariaLabel="rfidPrinter"
            defaultValue={
              isRfidDetails?.currentVal
                ? isRfidDetails?.currentVal
                : currentDevice?.isRfid!
                ? 'y'
                : 'n'
            }
            setValueDetails={setIsRfidDetails}
          />
        )}
      </ContainerDiv>
      <CTAContainer
        type="SAVE"
        mainButtonLabel={t('save-changes')}
        onClick={confirmClickHandler}
        onBackClick={backClickHandler}
        disabledMainAction={!validationOk}
      />
    </StyledDiv>
  );
};

export default PageAddDevice;
