import { FC, useEffect, useState } from 'react';
import { StyledDiv, SheetContainer } from './style';
import InfoContainer from '@/components/ui/InfoContainer/InfoContainer';
import TableDevices from '@/components/ui/TableDevices/TableDevices';
import { Tabs } from '@/components/layout/Tabs';
import { useSelector } from '@/hooks/useSelector';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { SplitString } from '@/utils/string';
import { useAppDispatch } from '@/app/store';
import { deleteDevice, resetCRUDDevice } from '@/features/devices/devicesSlice';
import { useAsync } from 'react-use';
import { mergeReadersAndPrinters } from '@/utils/devices';
import { useHistory, useParams } from 'react-router';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { useTranslation } from 'react-i18next';
import { UrlUpdateStore } from './types';
import { AppRoutes } from '@/app/routers';
import { Device, ReceiveShipmentsService, StoreWithPrinters } from '@/api';
import {
  PrintDeviceService,
  ScanDeviceService,
  StoreService,
  TypeOfStore,
} from '@/api/receive';
import { PageLoader } from '@/components/ui/PageLoader';
import ProfilingUsersList from '@/components/layout/ProfilingUsersList/ProfilingUsersList';
import { fetchUsers } from '@/features/profiling/usersSlice';
import { fetchGroupList } from '@/features/profiling/reasonCodeSlice';
import { getSessionItem, setSessionItem } from '@/utils/sessionStorage';
import { ProfilingGroupList } from '@/components/layout/ProfilingGroupList';
import { isEmpty } from '@/utils/validation';

export const PageStoreDetails: FC = (): JSX.Element => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const { store } = useParams<UrlUpdateStore>();
  const [refresh, forceRefresh] = useState<boolean>(false);

  const { t } = useTranslation();
  const [currentStore, setCurrentStore] = useState<
    StoreWithPrinters | undefined
  >(undefined);
  const [devices, setDevices] = useState<Device[]>([]);

  const [isLoadingDeviceStatus, setIsLoadingDeviceStatus] =
    useState<boolean>(false);

  const [timezone, setTimezone] = useState<
    { value: string; label: string } | undefined
  >(undefined);

  const [storeType, setStoreType] = useState<
    { value: string; label: string } | undefined
  >(undefined);

  const { errorDeleatingDevice } = useSelector(state => state.devices);

  const { list: users } = useSelector(state => state.profiling.user);

  const { groupList } = useSelector(state => state.profiling.reasonCode);

  const clickHandler = async (
    storeCode: string,
    storeStatus: 'Active' | 'Inactive',
    isLive: boolean,
    timeZone: string,
    typeOfStore: TypeOfStore
  ): Promise<void> => {
    await StoreService.storeserviceUpdateStore({
      requestBody: { storeCode, storeStatus, isLive, timeZone, typeOfStore },
    });
    forceRefresh(prev => !prev);
  };

  useAsync(async () => {
    await dispatch(resetCRUDDevice());

    if (!store) {
      history.push(AppRoutes.MANAGE_STORES);
    }

    const current =
      await ReceiveShipmentsService.receiveshipReadStoreByStoreCode({
        storeCode: store,
      });

    if (store && current) {
      setCurrentStore(current);

      dispatch(fetchUsers({ storeCode: store }));

      dispatch(fetchGroupList(store));

      setIsLoadingDeviceStatus(true);

      const deviceList =
        await ScanDeviceService.scandeviceSearchListScanDevices({
          storeId: store,
        });

      setIsLoadingDeviceStatus(false);

      setDevices(deviceList.deviceList || []);
    }
  }, [store, history, dispatch, refresh]);

  const { printers } = currentStore || { printers: [] };

  const removeDeviceHandler = async (deviceId: string): Promise<void> => {
    await dispatch(
      deleteDevice({
        deviceId: deviceId || '',
        storeId: currentStore?.storeCode || '',
      })
    );
    history.go(0);
  };

  const removePrinterHandler = async (deviceId: string): Promise<void> => {
    await PrintDeviceService.printDeletePrintDevice({
      printerCode: deviceId,
      storeCode: store || '',
    });
    history.go(0);
  };

  const mergedDeviceList = mergeReadersAndPrinters(devices, printers || []);

  const sessionTab = getSessionItem(
    'selectedTab',
    'string',
    `${store}|0`
  ) as string;

  const [sessionStore, currentTab] = SplitString(sessionTab, '|');

  const [selectedTab, setSelectedTab] = useState<number>(
    sessionStore === store ? parseInt(currentTab) : 0
  );

  const [isOpenSnackbar, setIsOpenSnackbar] = useState<boolean>(false);
  const [storeActive, setStoreActive] = useState<boolean>(false);
  const [storeLive, setStoreLive] = useState<boolean>(false);
  const [storeLiveDisabled, setStoreLiveDisabled] = useState<boolean>(false);

  const curTimezone = currentStore?.timeZone;
  const curStoreType = currentStore?.typeOfStore;

  useEffect(() => {
    if (curTimezone) {
      setTimezone({
        label: curTimezone,
        value: curTimezone,
      });
    }
  }, [curTimezone]);

  useEffect(() => {
    if (curStoreType) {
      setStoreType({
        label: curStoreType,
        value: curStoreType,
      });
    }
  }, [curStoreType]);

  useEffect(() => {
    setStoreActive(currentStore?.storeStatus === 'Active');
  }, [currentStore?.storeStatus]);

  useEffect(() => {
    setStoreLive(currentStore?.isLive || false);
    setStoreLiveDisabled(currentStore?.isLive || false);
  }, [currentStore?.isLive]);

  useEffect(() => {
    setSessionItem('selectedTab', `${store}|${selectedTab}`);
  }, [selectedTab, store]);

  useEffect(() => {
    setIsOpenSnackbar(typeof errorDeleatingDevice !== 'undefined');
  }, [errorDeleatingDevice]);

  const PAGE_STORE_DETAILS_SHEETS = ['Users', 'Devices', 'Reason codes'];

  if (isLoadingDeviceStatus) {
    return <PageLoader />;
  }

  const disabledMainAction =
    isEmpty(curTimezone) &&
    (isEmpty(timezone?.value) || timezone?.value === curTimezone);

  return currentStore ? (
    <StyledDiv>
      <ErrorSnackbar
        setIsOpen={setIsOpenSnackbar}
        open={isOpenSnackbar}
        errorMessage={errorDeleatingDevice?.body}
      />

      <InfoContainer
        name={currentStore?.storeDescription || ''}
        id={currentStore?.storeCode || ''}
        address={currentStore?.storeAddress || ''}
        setStoreActive={setStoreActive}
        setStoreLive={setStoreLive}
        storeStatus={storeActive}
        storeIsLive={storeLive}
        storeLiveDisabled={storeLiveDisabled}
        currentTimezone={curTimezone}
        setTimezone={setTimezone}
        newTimezone={timezone?.value || ''}
        currentStoreType={curStoreType}
        newStoreType={storeType?.value || ''}
        setStoreType={setStoreType}
      />

      <Tabs
        values={PAGE_STORE_DETAILS_SHEETS}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <SheetContainer>
        {selectedTab === 0 && (
          <ProfilingUsersList users={users} $showPartial={true} />
        )}

        {selectedTab === 1 && (
          <TableDevices
            data={mergedDeviceList}
            isLoading={isLoadingDeviceStatus}
            onRemoveElement={(id: string, type: string): Promise<void> =>
              type === 'reader'
                ? removeDeviceHandler(id)
                : removePrinterHandler(id)
            }
          />
        )}

        {selectedTab === 2 && (
          <ProfilingGroupList
            isSingleStore
            groupList={groupList.groups || []}
          />
        )}
      </SheetContainer>

      <CTAContainer
        type="SAVE"
        mainButtonLabel={t('save-changes')}
        disabledMainAction={disabledMainAction}
        onClick={(): Promise<void> =>
          clickHandler(
            currentStore?.storeCode || '',
            storeActive ? 'Active' : 'Inactive',
            storeLive,
            timezone!.value,
            storeType!.value as TypeOfStore
          )
        }
        onBackClick={(): void => history.push(AppRoutes.MANAGE_STORES)}
      />
    </StyledDiv>
  ) : (
    <div />
  );
};

export default PageStoreDetails;
