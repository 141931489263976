import React, { useEffect } from 'react';
import { useAppDispatch } from '@/app/store';
import { useAsync } from 'react-use';
import { useSelector } from '@/hooks/useSelector';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  getSaleExceptionsItems,
  resetManagedCheckedItems,
  searchItems,
  setLiteFunctionality,
} from '@/features/pos/posSlice';
import { PageLoader } from '@/components/ui/PageLoader';
import { CTAContainer } from '@/components/layout/CTAContainer';
import ProductList from '@/pages/Sale/components/ProductList';
import { ListType } from '../components/ProductList/types';

import { AppRoutes } from '@/app/routers';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import { EnumMode } from '@/types/enum';
import useFinishScan from '@/hooks/device/useFinishScan';
import useConfirmSoldItems from '../hooks/useConfirmSoldItems';
import { ModalDataSavedError } from '@/components/layout/ModalDataSaved';

const PageSaleExceptionsScan: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const finishScan = useFinishScan();
  const confirmSoldItems = useConfirmSoldItems();

  const [modalScanIsVisible, setModalScanVisibility] =
    React.useState<boolean>(true);
  const [isStartScanning, setIsStartScanning] = React.useState<boolean>(false);
  const [allItemsFound, setAllItemsFound] = React.useState<boolean>(false);
  const [findModeTags, setFindModeTags] = React.useState<string[]>([]);

  const readTags = useSelector(state => state.devices.tags);
  const { checkedManagedItems, exceptionsManaged, getSaleExceptionsIsLoading } =
    useSelector(state => state.pos);

  useEffect(() => {
    dispatch(setLiteFunctionality(true));
  }, [dispatch]);

  useAsync(async () => {
    const upcCodes = Object.keys(checkedManagedItems);

    const { productDetailsItems } = await dispatch(
      getSaleExceptionsItems(upcCodes)
    ).unwrap();

    if (productDetailsItems && productDetailsItems.length > 0) {
      const findModeTags = productDetailsItems.flatMap(
        ({ epcCodes }) => epcCodes || []
      );

      setFindModeTags(findModeTags);
      dispatch(resetManagedCheckedItems());
    }
  }, []);

  useEffect(() => {
    if (readTags.length > 0) {
      dispatch(searchItems(readTags));

      // Remove read tags from state sent to the ModalScanDetails
      setFindModeTags(prevTags =>
        prevTags.filter(tag => !readTags.includes(tag))
      );
    }
  }, [dispatch, readTags]);

  useEffect(() => {
    if (
      findModeTags.length === 0 &&
      exceptionsManaged.length === 0 &&
      readTags.length > 0
    ) {
      setAllItemsFound(true);
    }
  }, [exceptionsManaged.length, findModeTags.length, readTags.length]);

  // Reset checked items if the user leaves the page
  useEffect(() => {
    return (): void => {
      dispatch(resetManagedCheckedItems());
    };
  }, [dispatch]);

  const handleStartReaderClick = (): void => {
    setIsStartScanning(true);
    setModalScanVisibility(false);
  };

  const handleConfirmSoldItems = async (): Promise<void> => {
    try {
      const upcCodes = exceptionsManaged.map(({ upcCode }) => upcCode || '');

      const epcCodeSelected = Object.values(checkedManagedItems).flatMap(
        codes => codes
      );

      await confirmSoldItems.execute();
      await dispatch(getSaleExceptionsItems(upcCodes)).unwrap();

      // Remove read tags from state sent to the ModalScanDetails
      setFindModeTags(prevTags =>
        prevTags.filter(tag => !epcCodeSelected.includes(tag))
      );

      history.push(AppRoutes.SALE_EXCEPTIONS);
    } catch {}
  };

  return (
    <>
      {getSaleExceptionsIsLoading ? (
        <PageLoader />
      ) : (
        <>
          <ModalDataSavedError
            iconType="ERROR"
            open={allItemsFound}
            onClose={(): void => history.push(AppRoutes.SALE_EXCEPTIONS)}
            title={t('noItemsFoundInAppSearch')}
            message={t('searchManuallyInCheckoutLight')}
          />
          <ModalScanDetailsV2
            hideModes={[EnumMode.INVENTORY]}
            defaultMode={EnumMode.FIND}
            open={modalScanIsVisible}
            onClose={(): void => setModalScanVisibility(false)}
            isScanningAgain={finishScan.isCompleted}
            setIsScanCompleted={finishScan.setIsScanCompleted}
            onStartReader={handleStartReaderClick}
            tags={findModeTags.map(epc => ({ epc }))}
          />
          {isStartScanning && (
            <ProductList
              title={t('notFound')}
              type={ListType.MANAGED}
              products={exceptionsManaged}
              actionsDisabled={!finishScan.isCompleted}
              managedList={{
                onConfirm: handleConfirmSoldItems,
              }}
              productsCount={exceptionsManaged.reduce(
                (acc, item) => acc + Number(item.epcCodes?.length),
                0
              )}
            />
          )}
          {!finishScan.isCompleted && isStartScanning ? (
            <CTAContainer
              type="FINISH SCAN"
              loading={finishScan.isLoading}
              onClick={finishScan.execute}
            />
          ) : (
            <CTAContainer
              type="TWO_BUTTONS"
              onClick={(): void => setModalScanVisibility(true)}
              disabledMainAction={false}
              onBackClick={(): void => history.push(AppRoutes.SALE_EXCEPTIONS)}
              mainButtonLabel={t('scan-now')}
              backButtonLabel={t('back')}
            />
          )}
        </>
      )}
    </>
  );
};

export default PageSaleExceptionsScan;
