import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SelectedStore,
  SelectStore,
  StatusUser,
  UsersInitialState,
} from '@/types/profiling';
import {
  PersonalData,
  PersonalDataDetails,
  UserList,
  ProfilingService,
  SearchStoreFilter,
  StoresAndRoles,
  SavePersonalData,
  SaveStoresAndRoles,
  RoleList,
} from '@/api/receive';
import { RootState } from '@/app/rootReducer';
import { ApiError } from '@/api/bff';

const initialState: UsersInitialState = {
  list: [],
  roles: [],
  stores: {
    defaultStore: {
      selected: false,
    },
    storeBySalesOrganization: [],
    salesOrganizations: [],
  },
  details: {
    jobTitles: [],
    salesOrganization: [],
  },
  editUserDetail: {
    statusUser: '',
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    language: '',
    jobTitle: '',
  },
};

export const fetchUsers = createAsyncThunk<
  UserList,
  { storeCode: string } | undefined,
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/fetchUsers', async (selectedStore, { rejectWithValue }) => {
  try {
    const response = await ProfilingService.userFindAllUsers({
      storeCode: selectedStore?.storeCode,
    });

    return response
      ? response
      : {
          users: [],
        };
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const getUserDetails = createAsyncThunk<
  PersonalDataDetails,
  void,
  {
    state: RootState;
    rejectWithValue: ApiError;
  }
>('profiling/getUserDetails', async (_, { rejectWithValue }) => {
  try {
    return await ProfilingService.userUserPersonalDataDetails();
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const getEditUserData = createAsyncThunk<
  PersonalData,
  { userId: string },
  {
    state: RootState;
    rejectWithValue: ApiError;
  }
>('profile/getEditUserData', async ({ userId }, { rejectWithValue }) => {
  try {
    return await ProfilingService.userGetPersonalData({ userId });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const getEditStoresAndRoles = createAsyncThunk<
  StoresAndRoles,
  { userId: string },
  {
    state: RootState;
    rejectWithValue: ApiError;
  }
>('profile/getEditStoresAndRoles', async ({ userId }, { rejectWithValue }) => {
  try {
    return await ProfilingService.userGetStoreAndRoles({ userId });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const removeUser = createAsyncThunk<
  void,
  Parameters<typeof ProfilingService.userRemoveUser>[number],
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/removeUser', async ({ userId }, { rejectWithValue }) => {
  try {
    return await ProfilingService.userRemoveUser({
      userId,
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const createUser = createAsyncThunk<
  void,
  Parameters<typeof ProfilingService.userCreateUser>[number]['requestBody'],
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/createUser', async (requestBody, { rejectWithValue }) => {
  try {
    return await ProfilingService.userCreateUserEncoded({
      requestBody: {
        createUserRequest: btoa(JSON.stringify(requestBody)),
      },
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const getStores = createAsyncThunk<
  StoresAndRoles & { initDefaultStore?: boolean },
  SearchStoreFilter & {
    initDefaultStore?: boolean;
  },
  {
    state: RootState;
    rejectWithValue: ApiError;
  }
>('profiling/getStores', async (requestBody, { rejectWithValue }) => {
  try {
    const { initDefaultStore, ...body } = requestBody;
    const response = await ProfilingService.userSearchStoreForSalesAndStoreCode(
      {
        requestBody: body,
      }
    );

    if (response) {
      const { storesAndRoles } = response;

      if (storesAndRoles) {
        return {
          ...response,
          initDefaultStore,
        };
      }
    }

    return {
      salesOrganization: [],
      storesAndRoles: [],
    };
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const getRoles = createAsyncThunk<
  RoleList,
  void,
  {
    state: RootState;
    rejectWithValue: ApiError;
  }
>('profiling/getRoles', async (_, { rejectWithValue }) => {
  try {
    return await ProfilingService.userFindAllRoles();
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const saveEditData = createAsyncThunk<
  void,
  SavePersonalData,
  {
    state: RootState;
    rejectWithValue: ApiError;
  }
>('profiling/saveEditData', async (requestBody, { rejectWithValue }) => {
  try {
    return await ProfilingService.userSavePersonalDataDetails({ requestBody });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const saveEditStores = createAsyncThunk<
  void,
  SaveStoresAndRoles,
  {
    state: RootState;
    rejectWithValue: ApiError;
  }
>('profiling/saveEditStores', async (requestBody, { rejectWithValue }) => {
  try {
    return await ProfilingService.userSaveStoresAndRolesEncoded({
      requestBody: {
        saveStoreAndRolesRequest: btoa(JSON.stringify(requestBody)),
      },
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

const userProfilingSlice = createSlice({
  name: 'userProfiling',
  initialState,
  reducers: {
    setRoles: (state, { payload }: PayloadAction<string[]>) => {
      state.roles = payload;
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    changeUserID: (state, { payload }: PayloadAction<string>) => {
      state.editUserDetail.userId = payload;
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    changeUserStatus: (state, { payload }: PayloadAction<StatusUser>) => {
      state.editUserDetail.statusUser = payload;
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    changeDefaultStore: (state, { payload }: PayloadAction<SelectStore>) => {
      state.stores.defaultStore = payload;
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    removeUserFromRedux: (state, { payload }: PayloadAction<string>) => {
      state.list = state.list.filter(({ userId }) => userId !== payload);
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    removeStoresBySale: (state, { payload }: PayloadAction<string>) => {
      state.stores.storeBySalesOrganization =
        state.stores.storeBySalesOrganization?.filter(
          ({ salesOrg }) => salesOrg?.toUpperCase() !== payload.toUpperCase()
        );
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    changeStoreStatus: (state, { payload }: PayloadAction<string>) => {
      if (state.stores.storeBySalesOrganization) {
        const stores = [...state.stores.storeBySalesOrganization];
        const storeIndex = stores.findIndex(
          ({ storeCode }) => storeCode === payload
        );

        if (storeIndex !== -1) {
          if (stores[storeIndex].selected) {
            stores[storeIndex].selected = false;
          } else {
            stores[storeIndex].selected = true;
          }
        }
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    changeDefaultStoreStatus: state => {
      const defaultStore = state.stores.defaultStore;

      if (defaultStore) {
        if (defaultStore.selected) {
          defaultStore.selected = false;
        } else {
          defaultStore.selected = true;
        }
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    changeAllStoresStatus: (
      state,
      { payload }: PayloadAction<{ storeCodes: string[]; status: boolean }>
    ) => {
      if (state.stores.defaultStore && state.stores.storeBySalesOrganization) {
        const defaultStoreCode = state.stores.defaultStore.storeCode;
        const { storeCodes, status } = payload;

        if (defaultStoreCode) {
          if (storeCodes.includes(defaultStoreCode)) {
            state.stores.defaultStore.selected = status;
          }
        }

        for (const storeCode of storeCodes) {
          const storeIndex = state.stores.storeBySalesOrganization.findIndex(
            store => store.storeCode === storeCode
          );

          if (storeIndex !== -1) {
            state.stores.storeBySalesOrganization[storeIndex].selected = status;
          }
        }
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    insertStores: (state, { payload }: PayloadAction<SelectStore[]>) => {
      const stores = state.stores.storeBySalesOrganization;

      if (stores) {
        const storesToInsert = payload.map(store => {
          return {
            ...store,
            roles: ['Operator'],
            //@ts-ignore
            salesOrg: store.salesOrganization,
            //@ts-ignore
            streetAndHouseNumber: store.storeAddress,
          };
        });

        stores.unshift(...storesToInsert);
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    undoInsertedStores: (
      state,
      { payload: storeCodesToRemove }: PayloadAction<string[]>
    ) => {
      const stores = state.stores.storeBySalesOrganization;

      if (stores) {
        for (const storeCode of storeCodesToRemove) {
          const storeCodeToRemoveIndex = stores.findIndex(
            store => store.storeCode === storeCode
          );

          if (storeCodeToRemoveIndex !== -1) {
            stores.splice(storeCodeToRemoveIndex, 1);
          }
        }
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    undoRemovedStore: (state, { payload }) => {
      const stores = state.stores.storeBySalesOrganization;

      if (stores) {
        stores.splice(payload.index, 0, payload.store);
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    removeStore: (state, { payload }: PayloadAction<string>) => {
      const stores = state.stores.storeBySalesOrganization;

      if (stores) {
        const storeIndex = stores.findIndex(
          store => store.storeCode === payload
        );

        if (storeIndex !== -1) {
          stores.splice(storeIndex, 1);
        }
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    insertRoles: (
      state,
      { payload }: PayloadAction<{ storeCodes: string[]; roles: string[] }>
    ) => {
      const defaultStore = state.stores.defaultStore;
      const stores = state.stores.storeBySalesOrganization;

      if (stores) {
        for (const storeCode of payload.storeCodes) {
          const storeIndex = stores.findIndex(
            store => store.storeCode === storeCode
          );

          if (storeIndex !== -1) {
            stores[storeIndex].roles = [
              ...new Set([...stores[storeIndex].roles!, ...payload.roles]),
            ];
          }

          if (storeCode === defaultStore?.storeCode) {
            defaultStore.roles = [
              ...new Set([...defaultStore.roles!, ...payload.roles]),
            ];
          }
        }
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    undoInsertedRoles: (state, { payload }: PayloadAction<SelectedStore[]>) => {
      const defaultStore = state.stores.defaultStore;
      const stores = state.stores.storeBySalesOrganization;

      if (stores) {
        for (const store of payload) {
          const storeIndex = stores.findIndex(
            ({ storeCode }) => storeCode === store.storeCode
          );

          if (storeIndex !== -1) {
            stores[storeIndex].roles = stores[storeIndex].roles?.filter(role =>
              store.roles.includes(role)
            );
          }

          if (store.storeCode === defaultStore?.storeCode) {
            defaultStore.roles = defaultStore?.roles?.filter(role =>
              store.roles.includes(role)
            );
          }
        }
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    deleteRoles: (
      state,
      { payload }: PayloadAction<{ storeCodes: string[]; roles: string[] }>
    ) => {
      const defaultStore = state.stores.defaultStore;
      const stores = state.stores.storeBySalesOrganization;

      if (stores) {
        for (const storeCode of payload.storeCodes) {
          const storeIndex = stores.findIndex(
            store => store.storeCode === storeCode
          );

          if (storeIndex !== -1) {
            stores[storeIndex].roles = stores[storeIndex].roles?.filter(
              role => !payload.roles.includes(role)
            );
          }

          if (storeCode === defaultStore?.storeCode) {
            defaultStore.roles = defaultStore.roles?.filter(
              role => !payload.roles.includes(role)
            );
          }
        }
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    undoDeletedRoles: (state, { payload }: PayloadAction<SelectedStore[]>) => {
      const defaultStore = state.stores.defaultStore;
      const stores = state.stores.storeBySalesOrganization;

      if (stores) {
        for (const store of payload) {
          const storeIndex = stores.findIndex(
            ({ storeCode }) => storeCode === store.storeCode
          );

          if (storeIndex !== -1) {
            stores[storeIndex].roles = store.roles;
          }

          if (store.storeCode === defaultStore?.storeCode) {
            defaultStore.roles = store.roles;
          }
        }
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    insertOrDeleteRoles: (state, { payload }: PayloadAction<SelectedStore>) => {
      const defaultStore = state.stores.defaultStore;
      const stores = state.stores.storeBySalesOrganization;

      if (stores) {
        const storeIndex = stores.findIndex(
          ({ storeCode }) => storeCode === payload.storeCode
        );

        if (storeIndex !== -1) {
          stores[storeIndex].roles = payload.roles;
        }

        if (defaultStore?.storeCode === payload.storeCode) {
          defaultStore.roles = payload.roles;
        }
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    undoInsertOrDeleteRoles: (
      state,
      { payload }: PayloadAction<SelectedStore[]>
    ) => {
      const defaultStore = state.stores.defaultStore;
      const stores = state.stores.storeBySalesOrganization;

      if (stores) {
        for (const store of payload) {
          const storeIndex = stores.findIndex(
            ({ storeCode }) => storeCode === store.storeCode
          );

          if (storeIndex !== -1) {
            stores[storeIndex].roles = stores[storeIndex].roles?.filter(
              role => !store.roles.includes(role)
            );
          }

          if (store.storeCode === defaultStore?.storeCode) {
            defaultStore.roles = defaultStore?.roles?.filter(
              role => !store.roles.includes(role)
            );
          }
        }
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    clearStoresAndDetails: state => {
      state.details = initialState.details;
      state.stores = initialState.stores;
      state.editUserDetail = initialState.editUserDetail;
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    resetSelectedStores: state => {
      let defaultStore = state.stores.defaultStore;

      if (defaultStore) {
        defaultStore.selected = false;
        state.stores.storeBySalesOrganization =
          state.stores.storeBySalesOrganization?.map(store => ({
            ...store,
            selected: false,
          }));
      }
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    initProfilingUserState: () => initialState,
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    initProfilingDefaultStore: state => ({
      ...state,
      stores: initialState.stores,
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.pending, state => {
        state.fetchUsersIsLoading = true;
        state.fetchUsersHasError = undefined;
      })
      .addCase(fetchUsers.fulfilled, (state, { payload }) => {
        if (payload?.users) {
          state.fetchUsersIsLoading = false;
          state.list = payload.users;
        }
      })
      .addCase(fetchUsers.rejected, (state, { payload }) => {
        state.fetchUsersIsLoading = false;
        state.fetchUsersHasError = payload as ApiError;
      })
      .addCase(getUserDetails.pending, state => {
        state.getUserDetailsIsloading = true;
        state.getUserDetailsHasError = undefined;
      })
      .addCase(getUserDetails.fulfilled, (state, { payload }) => {
        state.getUserDetailsIsloading = false;
        state.details = payload;
      })
      .addCase(getUserDetails.rejected, (state, { payload }) => {
        state.getUserDetailsIsloading = false;
        state.getUserDetailsHasError = payload as ApiError;
      })
      .addCase(getEditUserData.pending, state => {
        state.editUserDetailIsLoading = true;
        state.editUserDetailError = undefined;
      })
      .addCase(getEditUserData.fulfilled, (state, { payload }) => {
        state.editUserDetailIsLoading = false;
        state.editUserDetail = payload;
        state.editUserDetail.language = payload.language.toLowerCase();
      })
      .addCase(getEditUserData.rejected, (state, { payload }) => {
        state.editUserDetailIsLoading = false;
        state.editUserDetailError = payload as ApiError;
      })
      .addCase(getEditStoresAndRoles.pending, state => {
        state.storesIsLoading = true;
        state.storesHasError = undefined;
      })
      .addCase(getEditStoresAndRoles.fulfilled, (state, { payload }) => {
        state.storesIsLoading = false;
        state.stores.defaultStore = payload.defaultStoreAndRoles;
        state.stores.storeBySalesOrganization = payload.storesAndRoles;
        state.stores.salesOrganizations = payload.salesOrganization;
      })
      .addCase(getEditStoresAndRoles.rejected, (state, { payload }) => {
        state.storesIsLoading = false;
        state.storesHasError = payload as ApiError;
      })
      .addCase(removeUser.pending, state => {
        state.removeUserIsLoading = true;
        state.removeUserHasError = undefined;
      })
      .addCase(removeUser.fulfilled, state => {
        state.removeUserIsLoading = false;
      })
      .addCase(removeUser.rejected, (state, { payload }) => {
        state.removeUserIsLoading = false;
        state.removeUserHasError = payload as ApiError;
      })
      .addCase(createUser.pending, state => {
        state.createUserIsLoading = true;
        state.createUserHasError = undefined;
      })
      .addCase(createUser.fulfilled, state => {
        state.createUserIsLoading = false;
      })
      .addCase(createUser.rejected, (state, { payload }) => {
        state.createUserIsLoading = false;
        state.createUserHasError = payload as ApiError;
      })
      .addCase(getStores.pending, state => {
        state.storesIsLoading = true;
        state.storesHasError = undefined;
      })
      .addCase(getStores.fulfilled, (state, { payload }) => {
        state.storesIsLoading = false;

        if (payload) {
          const {
            defaultStoreAndRoles,
            storesAndRoles,
            initDefaultStore = true,
          } = payload;

          if (initDefaultStore) {
            state.stores.defaultStore = {
              ...defaultStoreAndRoles,
              roles: [...defaultStoreAndRoles?.roles!, 'Operator'],
              selected: false,
            };
          }

          if (storesAndRoles) {
            if (storesAndRoles.length > 0) {
              const prevState = state.stores.storeBySalesOrganization;

              if (prevState) {
                const response = storesAndRoles.map(store => ({
                  ...store,
                  roles: [...store.roles!, 'Operator'],
                  selected: false,
                }));

                state.stores.storeBySalesOrganization = [
                  ...prevState,
                  ...response,
                ].filter((store, index, array) => {
                  const indexToMatch = array.findIndex(
                    ({ storeCode }) => store.storeCode === storeCode
                  );
                  return indexToMatch === index;
                });
              }
            } else {
              state.stores.storeBySalesOrganization = storesAndRoles;
            }
          }
        }
      })
      .addCase(getStores.rejected, (state, { payload }) => {
        state.storesIsLoading = false;
        state.storesHasError = payload as ApiError;
      })
      .addCase(saveEditData.pending, state => {
        state.saveEditDataIsLoading = true;
      })
      .addCase(saveEditData.fulfilled, state => {
        state.saveEditDataIsLoading = false;
      })
      .addCase(saveEditData.rejected, (state, { payload }) => {
        state.saveEditDataIsLoading = false;
        state.saveEditDataHasError = payload as ApiError;
      })
      .addCase(saveEditStores.pending, state => {
        state.saveEditStoresIsLoading = true;
      })
      .addCase(saveEditStores.fulfilled, state => {
        state.saveEditStoresIsLoading = false;
      })
      .addCase(saveEditStores.rejected, (state, { payload }) => {
        state.saveEditStoresIsLoading = false;
        state.saveEditStoresHasError = payload as ApiError;
      });
  },
});

export const {
  setRoles,
  changeUserID,
  changeUserStatus,
  changeDefaultStore,
  removeUserFromRedux,
  removeStoresBySale,
  changeAllStoresStatus,
  changeStoreStatus,
  changeDefaultStoreStatus,
  insertStores,
  undoInsertedStores,
  removeStore,
  undoRemovedStore,
  insertRoles,
  undoInsertedRoles,
  deleteRoles,
  undoDeletedRoles,
  insertOrDeleteRoles,
  undoInsertOrDeleteRoles,
  clearStoresAndDetails,
  resetSelectedStores,
  initProfilingUserState,
  initProfilingDefaultStore,
} = userProfilingSlice.actions;
export default userProfilingSlice.reducer;
