import { FC } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { Accordion } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary, {
  AccordionSummaryProps,
} from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

interface ProductAccordionProps {
  items: string;
  className?: string;
  $v2?: boolean;
  expanded?: boolean;
  padding?: CSSProperties['padding'];
  toggleAccordion?: React.MouseEventHandler<HTMLDivElement>;
}

const StyledAccordion = styled(Accordion)`
  && {
    color: #fff;
    margin: 5px 0;
    display: flex;
    flex-direction: column-reverse;
    border: none;
    box-shadow: none;
    background: transparent;
  }

  &::before {
    display: none;
  }

  svg {
    height: 20px;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)<
  AccordionSummaryProps & { $v2?: boolean }
>`
  display: ${({ $v2 }): string => ($v2 ? 'none' : 'auto')};
  && {
    min-height: 16px !important;
    max-width: 110px;
    padding: 2px 16px;
    background: #005192;
    border-radius: 50px;
    align-self: center;
  }

  div {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)<{
  padding?: CSSProperties['padding'];
}>`
  && {
    color: #929496;
    padding: ${({ padding }): CSSProperties['padding'] =>
      padding ? padding : '24px 116px'};
    flex-direction: column;
  }

  & > * {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

const ProductAccordion: FC<ProductAccordionProps> = ({
  children,
  items,
  className,
  expanded = false,
  toggleAccordion,
  $v2,
  padding,
}) => {
  return (
    <StyledAccordion className={className} expanded={expanded}>
      <StyledAccordionSummary
        id="accordion"
        expandIcon={<ExpandMoreIcon fill="white" htmlColor="white" />}
        onClick={toggleAccordion}
        $v2={$v2}
      >
        {items}
      </StyledAccordionSummary>

      <StyledAccordionDetails padding={padding}>
        {children}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default ProductAccordion;
