import { UIBox } from '@/components/ui/Box';
import React, { memo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { useHistory } from 'react-router';
import {
  AVAILABLE_NATIONALITIES,
  tAvailableNationality,
} from '@/configs/settings';
import styled from 'styled-components';
import { StockSummaryService } from '@/api';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '@/utils/validation';
import { AlertSnackbar } from '../components/ui/AlertSnackbar';

const StyledSoHWrapper = styled(UIBox)`
  maxwidth: 1366px;
  width: 100%;
  margin: 64px 32px;
`;

const StyledFieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const PageSohExtraction: React.FC = memo((): JSX.Element => {
  const [lang, setLang] = useState<string | null>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [date, setDate] = useState<string | undefined>(undefined);

  const disabled = isEmpty(lang) || !date;
  const history = useHistory();
  const { t } = useTranslation();
  const nationalities = Object.keys(AVAILABLE_NATIONALITIES);
  const clickHandler = async (): Promise<void> => {
    if (!disabled) {
      try {
        setLoading(true);

        const { csvFile, nameFile } =
          await StockSummaryService.stockStockOnHandByCountryAndDate({
            country: lang !== null ? lang : '',
            date: date?.replaceAll('-', ''),
          });
        if (csvFile && nameFile) {
          const linkSource = `data:application/zip;base64,${csvFile}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = nameFile;
          downloadLink.click();
        } else {
          setError(true);
        }
      } catch (e) {
        console.log(e);
        setError(true);
      }
      setLoading(false);
    }
  };

  return (
    <StyledSoHWrapper>
      <StyledFieldsWrapper>
        <Autocomplete
          onChange={(_, selected): void => {
            setLang(selected);
          }}
          options={nationalities}
          getOptionLabel={(option: string): string =>
            t(AVAILABLE_NATIONALITIES[option as tAvailableNationality])
          }
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              label={t('soh.country')}
              variant="outlined"
              required
            />
          )}
          style={{ minWidth: '250px' }}
        />
        <TextField
          id="date"
          label={t('soh.date')}
          type="date"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          required
          onChange={(e): void => {
            setDate(e.target.value);
          }}
          style={{ minWidth: '250px' }}
        />
      </StyledFieldsWrapper>
      <CTAContainer
        type="TWO_BUTTONS"
        onClick={(): Promise<void> => clickHandler()}
        onBackClick={(): void => history.push('/')}
        mainButtonLabel={t('button.downloadSoH')}
        disabledMainAction={disabled}
        loading={loading}
      />
      <AlertSnackbar
        open={error}
        setIsOpen={setError}
        message={t('soh.nodata')}
      />
    </StyledSoHWrapper>
  );
});

PageSohExtraction.displayName = 'PageSohExtraction';
export default PageSohExtraction;
