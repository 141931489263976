import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/app/store';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { UIBox } from '@/components/ui/Box';
import { UIAction } from '@/components/ui/Action';
import { PaginationList } from '@/components/layout/PaginationList';
import ProductListItem from '../ProductListItem';
import SubHeader from '@/components/ui/List/ListSubHeader';
import { Typography } from '@/components/ui/Typography';

import { Product, ProductValues } from '@/types/enum';
import { StyledUIList, StyledUnexpectedListHeader } from './style';
import {
  initSelectedEpcs,
  removeEpcsNotDecoded,
  setSelectedAll,
  addReceivingEpcs,
  removeReceivingEpcs,
  incrementUnexpected,
  getScannedProducts,
} from '@/features/products/scannedProductsSlice';
import { removeTags, tagsMQTTDevice } from '@/features/devices/devicesSlice';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import type { EpcSelected, ScannedProduct } from '@/types/product';
import type { ProductDetailEpc } from '@/api/receive';
import useSROTrackEvent from '@/hooks/useSROTrackEvent';
import displayProductHeader from '@/utils/displayProductHeader';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { UISwitch } from '@/components/ui/Switch';

type ProductListProps = {
  type: ProductValues;
  data: ScannedProduct[];
  onProductImageClick: (e: React.MouseEvent, upcCode: string) => void;
  onPrintClick?: (product: ScannedProduct) => void;
  disableActions?: boolean;
  hasSubheader?: boolean;
};

type DecodedList = {
  decoded: ProductDetailEpc[];
  notDecoded: EpcSelected[];
};

const ProductList = ({
  data,
  type,
  onPrintClick,
  onProductImageClick,
  disableActions,
  hasSubheader = false,
}: ProductListProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();
  const [expanded, setExpended] = useState<boolean>(false);
  const [actionError, setActionError] = useState<boolean>(false);
  const [actionRemoveSuccess, setActionRemoveSuccess] =
    useState<boolean>(false);
  const [showAddConfirm, setShowAddConfirm] = useState<boolean>(false);
  const [showDeliveryNumber, setShowDeliveryNumber] = useState<boolean>(true);
  const [epcToAdd, setEpcToAdd] = useState<EpcSelected[]>([]);

  const { removeEpcHasError, addEpcHasError } = useSelector(
    state => state.scannedProducts
  );
  const { selectedDeliveries } = useSelector(state => state.deliveries);
  const selectedDeliveriesCodes = selectedDeliveries.map(
    item => item.codeDelivery
  );

  const { username } = useSelector(state => state.user);
  const scannedTags = useSelector(state => state.devices.tags);
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const found = useSelector(state => state.scannedProducts.products.found.list);
  const {
    ui: { disableScanAgain },
  } = useSelector(state => state.scannedProducts);
  const unexpected = useSelector(
    state => state.scannedProducts.products.unexpected.list
  );

  const epcsSelected = useSelector(
    state => state.scannedProducts.unexpectedEpcs
  );

  const epcsSelectedCount = epcsSelected.flatMap(
    ({ epcCodes }) => epcCodes
  ).length;

  const foundItemsCount = found?.reduce(
    (acc: number, b: ScannedProduct) => acc + Number(b?.epcCodes?.length!),
    0
  );

  const unexpectedEpcs: EpcSelected[] = unexpected
    ? unexpected.flatMap(({ upcCode, epcCodes }) => ({
        upcCode: upcCode!,
        epcCodes: epcCodes!,
      }))
    : [];

  const trackAddUnexpectedEpc = useSROTrackEvent(
    appInsights,
    'Add Unexpected Epc On Receveing',
    {
      User: username,
      StoreCode: storeCode,
      UPC: '',
      EPC: '',
      LinkedEvent: selectedDeliveries
        .map(({ codeDelivery }) => codeDelivery)
        .join(', '),
    }
  );

  const selectAllUnexpectedEpcs = (): void => {
    if (epcsSelected.length > 0) {
      dispatch(setSelectedAll([]));
    } else {
      dispatch(setSelectedAll(unexpectedEpcs));
    }
  };

  const splitSelectedEpcs = (epcs: EpcSelected[]): DecodedList => {
    return epcs.reduce(
      (prev, curr) => {
        const getPhysicalRemovableEpcs = (
          physical: boolean
        ): ProductDetailEpc[] =>
          curr.epcCodes.filter(({ isDecoded, deliveryNumber }) => {
            if (physical) {
              return (
                isDecoded &&
                selectedDeliveriesCodes.includes(deliveryNumber || '')
              );
            } else {
              return isDecoded
                ? !selectedDeliveriesCodes.includes(deliveryNumber || '')
                : true;
            }
          });

        return {
          decoded: [...prev.decoded, ...getPhysicalRemovableEpcs(true)],
          notDecoded: [
            ...prev.notDecoded,
            {
              upcCode: curr.upcCode,
              epcCodes: getPhysicalRemovableEpcs(false),
            },
          ],
        };
      },
      {
        decoded: [],
        notDecoded: [],
      } as DecodedList
    );
  };

  const removeEpcs = async (
    selected?: EpcSelected[] | undefined
  ): Promise<void> => {
    try {
      const toSplit = selected || epcsSelected;
      const epcs = splitSelectedEpcs(toSplit);

      const flattenDecoded = epcs.decoded.map(({ epcCode }) => epcCode!);
      const flattenNotDecoded = epcs.notDecoded
        .flatMap(({ epcCodes }) => epcCodes)
        .map(({ epcCode }) => epcCode!);

      if (epcs.decoded.length > 0) {
        await dispatch(removeReceivingEpcs(epcs.decoded)).unwrap();
      }

      if (flattenNotDecoded.length > 0) {
        dispatch(removeEpcsNotDecoded(epcs.notDecoded));
      }

      dispatch(removeTags([...flattenDecoded, ...flattenNotDecoded]));

      if (disableScanAgain) {
        await dispatch(getScannedProducts(scannedTags)).unwrap();
      }

      if (epcsSelected.length > 0) setActionRemoveSuccess(true);
      dispatch(initSelectedEpcs());
    } catch {
      setActionError(true);
    }
  };

  // const addEpcs = async (): Promise<void> => {
  const addEpcs = async (selected: EpcSelected[]): Promise<void> => {
    const epcs = selected
      .flatMap(({ epcCodes }) => epcCodes)
      .map(({ epcCode }) => epcCode || '');

    const tags = epcs.map(epc => ({ epc }));

    try {
      // const epcs = epcsSelected
      //   .flatMap(({ epcCodes }) => epcCodes)
      //   .map(({ epcCode }) => epcCode || '');

      await dispatch(addReceivingEpcs(selected)).unwrap();

      if (disableScanAgain) {
        await dispatch(getScannedProducts([...scannedTags, ...epcs])).unwrap();
      }

      dispatch(tagsMQTTDevice(tags));

      // It needs to send KPI data on confirm
      dispatch(incrementUnexpected(tags.length));

      // await Promise.all(epcsSelected).then(r => {
      //   r.forEach(({ upcCode, epcCodes }) => {
      //     epcCodes.forEach(({ epcCode }) => {
      //       trackAddUnexpectedEpc({
      //         User: username,
      //         StoreCode: storeCode,
      //         UPC: upcCode || '',
      //         EPC: epcCode || '',
      //         LinkedEvent: selectedDeliveries
      //           .map(({ codeDelivery }) => codeDelivery)
      //           .join(', '),
      //       });
      //     });
      //   });
      // });

      await Promise.all(selected).then(r => {
        r.forEach(({ upcCode, epcCodes }) => {
          epcCodes.forEach(({ epcCode }) => {
            trackAddUnexpectedEpc({
              User: username,
              StoreCode: storeCode,
              UPC: upcCode || '',
              EPC: epcCode || '',
              LinkedEvent: selectedDeliveries
                .map(({ codeDelivery }) => codeDelivery)
                .join(', '),
            });
          });
        });
      });

      dispatch(initSelectedEpcs());
    } catch {
      setActionError(true);
      dispatch(tagsMQTTDevice(tags));
      // It needs to send KPI data on confirm
      dispatch(incrementUnexpected(tags.length));
    }
  };

  const onConfirmAddClick = async (): Promise<void> => {
    if (epcToAdd.length > 0) {
      await addEpcs(epcToAdd);
    }
    setShowAddConfirm(false);
  };

  const openAddConfirm = (selected: EpcSelected[]): void => {
    setEpcToAdd(selected);
    setShowAddConfirm(true);
  };

  return (
    <>
      {type === Product.UNEXPECTED && data.length > 0 && (
        <StyledUnexpectedListHeader
          text={t('item_selected', { selected: epcsSelectedCount })}
          linkText={epcsSelected.length > 0 ? t('deselectAll') : t('selectAll')}
          onLinkClick={selectAllUnexpectedEpcs}
          rightComponent={
            <UIBox alignItems="center">
              <UIBox mr={3}>
                <UIBox alignItems="center">
                  <span>{t('receiving.showdeliverynumber')}</span>
                  <UISwitch
                    checked={showDeliveryNumber}
                    onChange={(): void => {
                      setShowDeliveryNumber(prevState => !prevState);
                    }}
                  />
                </UIBox>
                <UIAction
                  label={t('remove')}
                  icon="delete"
                  onClick={removeEpcs}
                  disabled={disableActions || epcsSelectedCount === 0}
                />
              </UIBox>
            </UIBox>
          }
        />
      )}
      <StyledUIList
        rounded={data.length > 0}
        backgrounded={data.length > 0}
        shadowed={data.length > 0}
        subheader={
          hasSubheader ? (
            <SubHeader
              title={t('found')}
              itemsCount={foundItemsCount}
              accordion={{
                expanded,
                toggleAccordionState: (): void => setExpended(p => !p),
              }}
            />
          ) : undefined
        }
        margin={type !== Product.FOUND ? [0, 0, 24] : []}
      >
        {(!hasSubheader || (hasSubheader && !expanded)) && (
          <PaginationList
            data={data}
            pageSize={50}
            noItems={
              <UIBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="10%"
              >
                <Typography font="heavy" color="grey">
                  {t('noItems')}
                </Typography>
              </UIBox>
            }
            renderItem={(
              productItem: ScannedProduct,
              i: number
            ): JSX.Element => (
              <>
                {displayProductHeader(data, type, i) && (
                  <UIBox width="100%" mt={4} justifyContent="space-between">
                    <Typography font="heavy">
                      {productItem.brandDescription}
                    </Typography>
                    {productItem.modelCode && (
                      <UIBox alignItems="center">
                        <Typography font="heavy">{t('modelCode')}:</Typography>
                        <Typography margin="0 0 0 8px">
                          {productItem.modelCode}
                        </Typography>
                      </UIBox>
                    )}
                  </UIBox>
                )}
                <ProductListItem
                  key={productItem.upcCode}
                  type={type}
                  onPrintClick={onPrintClick}
                  onProductImageClick={onProductImageClick}
                  disableActions={disableActions || epcsSelectedCount > 0}
                  setActionError={setActionError}
                  openAddConfirm={openAddConfirm}
                  onRemoveClick={removeEpcs}
                  showDeliveryNumber={showDeliveryNumber}
                  {...productItem}
                />
              </>
            )}
          />
        )}
      </StyledUIList>
      <AlertSnackbar
        open={actionError && !!removeEpcHasError}
        setIsOpen={setActionError}
        message={
          removeEpcHasError?.body?.errorMessage || removeEpcHasError?.body
        }
      />
      <AlertSnackbar
        open={actionError && !!addEpcHasError}
        setIsOpen={setActionError}
        message={addEpcHasError?.body?.errorMessage || addEpcHasError?.body}
      />
      <AlertSnackbar
        severity="success"
        open={actionRemoveSuccess}
        setIsOpen={setActionRemoveSuccess}
        message={t('receiving.deleteDone')}
      />
      <ModalAttention
        open={showAddConfirm}
        onConfirmClick={onConfirmAddClick}
        onClose={(): void => setShowAddConfirm(false)}
        title={t('receiving.confirmAddUnxpectedTitle')}
        message={t('receiving.confirmAddUnxpectedMessage')}
        messageMaxWidth="80%"
      />
    </>
  );
};

export default ProductList;
