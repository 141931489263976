import React, { useState } from 'react';
import { RoleModalState } from '@/types/profiling';

interface IRoleModalContext {
  roleModal: RoleModalState;
  setRoleModalState: React.Dispatch<React.SetStateAction<RoleModalState>>;
}

const RoleModalContext = React.createContext<IRoleModalContext>({
  roleModal: {
    open: false,
    type: 'ADD',
    roles: [],
    rolesChecked: [],
  },
  setRoleModalState: () => false,
});

export const RoleModalContextProvider: React.FC = ({ children }) => {
  const [roleModal, setRoleModalState] = useState<RoleModalState>({
    open: false,
    type: 'ADD',
    roles: [],
    rolesChecked: [],
    externalFn: (): void => {},
  });

  return (
    <RoleModalContext.Provider
      value={{
        roleModal,
        setRoleModalState,
      }}
    >
      {children}
    </RoleModalContext.Provider>
  );
};

export const useRoleModalContext = (): IRoleModalContext =>
  React.useContext(RoleModalContext);
