import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { palette } from '@/theme';

export const PageWrapper = styled(UIBox)`
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(UIBox)`
  margin-top: 40px;
  margin-bottom: 32px;
`;

export const TagsWrapper = styled(UIBox)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  padding: 0 24px;
`;

export const Tags = styled(UIBox)`
  align-items: center;
  grid-column: 2 / 3;
`;

export const DownloadWrapper = styled(UIBox)`
  grid-column: 3 / -1;
  justify-self: end;
`;

export const List = styled(UIBox)`
  width: 100%;
  flex-direction: column;
  margin-top: 64px;
  padding: 24px;
`;

export const ListHeader = styled(UIBox)`
  width: 100%;
  padding: 0 12px;
  border-bottom: 1px solid black;
  display: grid;
  grid-template-columns: 350px auto;
`;

export const ListItem = styled(UIBox)`
  width: 100%;
  padding: 24px 12px;
  display: grid;
  grid-template-columns: 350px auto;

  &:not(:nth-of-type(odd)) {
    background: ${palette.colors.midGrey};
  }
`;
