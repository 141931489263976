import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import {
  getScannedProducts,
  printReceivingProductTag,
  setDisableScanAgain,
} from '@/features/products/scannedProductsSlice';
import { RootState } from '@/app/rootReducer';
import { tagsMQTTDevice } from '@/features/devices/devicesSlice';
import { ApiError } from '@/api/receive';

type Dispatch = ThunkDispatch<RootState, unknown, AnyAction>;

type ReceivingActionsBody = {
  upcCode: string;
  printedEPC: string;
  tags: string[];
};

export namespace ReceivingRedux {
  export const update = async (
    dispatch: Dispatch,
    { upcCode, tags, printedEPC }: ReceivingActionsBody
  ): Promise<void | ApiError> => {
    try {
      await dispatch(
        printReceivingProductTag({
          epcCode: printedEPC,
          upcCode: upcCode,
        })
      ).unwrap();

      await dispatch(getScannedProducts([...tags, printedEPC]));

      dispatch(setDisableScanAgain(true));
      dispatch(tagsMQTTDevice([{ epc: printedEPC }]));
    } catch (error) {
      return error as ApiError;
    }
  };
}
