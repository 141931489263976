import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs } from '@material-ui/core';
import { UILink } from '@/components/ui/Link';
import { Typography } from '@/components/ui/Typography';

import { BreadcrumbsItem } from '@/types/breadcrumbs';
import { useRouteMatch } from 'react-router';
import { makeUrl } from '@/utils/links';

export interface UIBreadcrumbsProps {
  items?: BreadcrumbsItem[];
}

export interface UIBreadcrumbProps {
  item: BreadcrumbsItem;
  countItems: number;
  index: number;
}

const UIBreadcrumbItem: FC<UIBreadcrumbProps> = ({
  item,
  countItems,
  index,
}): JSX.Element => {
  const { title, path, onClick } = item;
  const match = useRouteMatch(path as string);

  return path && countItems > index + 1 ? (
    <UILink
      size="sm"
      onClick={onClick}
      to={match?.params ? makeUrl(path, match?.params) : path}
    >
      <Typography size="sm">
        {match?.params ? makeUrl(title, match?.params) : title}
      </Typography>
    </UILink>
  ) : (
    <Typography
      size="sm"
      color={countItems === index + 1 ? 'disabled' : 'text'}
    >
      {match?.params ? makeUrl(title, match?.params) : title}
    </Typography>
  );
};

const UIBreadcrumbs: FC<UIBreadcrumbsProps> = memo(
  ({ items }: UIBreadcrumbsProps) => {
    const { t } = useTranslation();

    return (
      <Breadcrumbs aria-label="breadcrumb">
        <UILink to="/">
          <Typography size="sm">{t('page.home')}</Typography>
        </UILink>
        {items?.map(
          (item: BreadcrumbsItem, index: number): JSX.Element => (
            <UIBreadcrumbItem
              key={index}
              item={item}
              index={index}
              countItems={items.length}
            />
          )
        )}
      </Breadcrumbs>
    );
  }
);

UIBreadcrumbs.displayName = 'Breadcrumbs';

export default UIBreadcrumbs;
