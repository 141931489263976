import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';

export const StyledFilterWrapper = styled(UIBox)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  padding: 0 24px 24px;
  margin-bottom: 16px;

  & > * {
    margin: 0;
    align-self: flex-end;

    & > div {
      width: 100% !important;
    }
  }
`;

export const StyledWrapper = styled.div`
  width: 100%;
  padding: 0 24px;
  margin-bottom: 120px;
  .kPaginationListItem > div {
    padding: 11px 0;
  }
`;
