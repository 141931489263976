import React from 'react';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { palette } from './_colors';
import { typography } from './_typography';
import { breakpoints } from './_breakpoints';
import { overrides } from './_overrides';

import './fonts/fonts.css';

export * from './_colors';
export * from './_typography';
export * from './_breakpoints';

export const theme = createTheme({
  breakpoints,
  palette,
  typography,
  overrides,
});

export const ThemeProvider: React.FC = ({ children }) => (
  <StyledThemeProvider theme={theme}>
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  </StyledThemeProvider>
);
