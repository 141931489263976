import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { UIButtonWithIcon } from '@/components/ui/Button';

export const StyledProductsWrapper = styled(UIBox)`
  margin: 145px 0 100px;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const StyledCycleCountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
  margin-top: 24px;
  margin-bottom: 12px;
  position: relative;

  &&& {
    span {
      line-height: 1.4;
      text-align: center;

      ${({ theme }): string =>
        `${theme.breakpoints.down('sm')} {
					text-align: left;
				}`}
    }
  }
`;

export const StyledRecapWrapper = styled(UIBox)`
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
					margin-left: 0;
					max-width: 80%;
				}`}
`;

export const StyledSwitchWrapper = styled(UIBox)`
  width: 100%;
  margin-right: 24px;

  div {
    margin-left: auto;
  }
`;

export const StyledUpdateButton = styled(UIButtonWithIcon)`
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
`;
