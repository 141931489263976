import React, { FC, memo } from 'react';
import styled from 'styled-components';

export interface AppContainerProps {
  children: React.ReactNode;
}

const Container = styled.div`
  ${({ theme }): string =>
    `${theme.breakpoints.up('lg')} {
    max-width: ${theme.breakpoints.values.lg}px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }`}
`;

const AppContainer: FC<AppContainerProps> = memo(
  ({ children, ...rest }: AppContainerProps): JSX.Element => (
    <Container {...rest}>{children}</Container>
  )
);

AppContainer.displayName = 'UICommonContainer';

export default AppContainer;
