import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UIList } from '@/components/ui/List';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { PaginationList } from '@/components/layout/PaginationList';
import DamageListitem from '@/components/layout/DamageList/DamageListitem';

import { useAppDispatch } from '@/app/store';
import {
  deselectAllEpcByUpc,
  deselectUpc,
  selectAllEpcByUpc,
  selectUpc,
} from '@/features/storeDamages/storeDamagesSlice';
import type { SearchItemsForCreateDamagesResponse } from '@/api/receive';
import { colors } from '@/theme';

interface DamageListProps {
  paginationItems: SearchItemsForCreateDamagesResponse[];
  listCurrentPage: number;
  setListCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  hasSelectAll?: boolean;
  actions?: JSX.Element;
}

const StyledSelectAll = styled(UIBox)`
  cursor: pointer;

  span {
    text-decoration: underline;
    color: #005192 !important;
  }
`;

const StyledSpan = styled(Typography)`
  && {
    display: block;
    color: gray;
    margin-left: 24px;
  }
`;

const Sticky = styled(UIBox)`
  position: sticky;
  top: 136px;
  width: 100%;
  background-color: ${colors.white};
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${colors.lightGrey};
  z-index: 1;
`;

const DamageList: React.FC<DamageListProps> = ({
  paginationItems,
  listCurrentPage,
  setListCurrentPage,
  hasSelectAll = true,
  actions,
}) => {
  const pageSize = 20;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedEpcs } = useSelector(state => state.storeDamages);

  const [itemsInPage, setItemsInPage] = useState<
    SearchItemsForCreateDamagesResponse[]
  >([]);

  const upcsInPage = useMemo(
    () => itemsInPage.map(({ upcCode }) => upcCode),
    [itemsInPage]
  );

  const allEpcs = useMemo(
    () =>
      paginationItems
        ?.flatMap(({ epcCodes }) => epcCodes)
        .map(({ epcCode }) => epcCode),
    [paginationItems]
  );

  const selectedEpcsInPage = useMemo(
    () => selectedEpcs.filter(epc => allEpcs?.includes(epc)),
    [allEpcs, selectedEpcs]
  );

  const hasSelectedAll = selectedEpcsInPage.length === allEpcs?.length;

  const selectAllHandler = (): void => {
    if (hasSelectedAll) {
      dispatch(deselectUpc(upcsInPage));
      dispatch(deselectAllEpcByUpc(allEpcs || []));
    } else {
      dispatch(selectUpc(upcsInPage));
      dispatch(selectAllEpcByUpc(allEpcs || []));
    }
  };

  return (
    <>
      <Sticky
        marginTop="24px"
        alignItems="center"
        justifyContent="space-between"
      >
        {hasSelectAll && (
          <UIBox>
            <StyledSelectAll onClick={selectAllHandler}>
              <Typography>
                {hasSelectedAll ? `${t('deselectAll')}` : `${t('selectAll')}`}
              </Typography>
            </StyledSelectAll>
            <StyledSpan>
              {t('item_selected', { selected: selectedEpcs.length })}
            </StyledSpan>
          </UIBox>
        )}
        {actions}
      </Sticky>
      {paginationItems && (
        <UIList
          margin={[24, 0, 100]}
          padding={[0]}
          backgrounded
          rounded
          bordered
        >
          <PaginationList
            data={paginationItems}
            setItemsInPage={setItemsInPage}
            currPage={listCurrentPage}
            setCurrPage={setListCurrentPage}
            pageSize={pageSize}
            renderItem={(
              item: SearchItemsForCreateDamagesResponse
            ): JSX.Element => <DamageListitem key={item.upcCode} {...item} />}
          />
        </UIList>
      )}
    </>
  );
};

export default DamageList;
