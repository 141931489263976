/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfirmStockOnHand } from '../models/ConfirmStockOnHand';
import type { ConfirmStockOnHandEncode } from '../models/ConfirmStockOnHandEncode';
import type { DownloadCsvResponse } from '../models/DownloadCsvResponse';
import type { DownloadCsvSoHRequest } from '../models/DownloadCsvSoHRequest';
import type { ProductMasterDataList } from '../models/ProductMasterDataList';
import type { ReportDetailsResponse } from '../models/ReportDetailsResponse';
import type { StartSohAlighmentRequest } from '../models/StartSohAlighmentRequest';
import type { StartSohAlighmentRequestEncoded } from '../models/StartSohAlighmentRequestEncoded';
import type { StartSohAlighmentResponse } from '../models/StartSohAlighmentResponse';
import type { StockOnHandItemsList } from '../models/StockOnHandItemsList';
import { request as __request } from '../core/request';

export class StockOnHandAlignmentService {
  /**
   * 2. SA wants to see list of last stock on hand
   * Retrieve a list of Stock On Hand performed by a store in a configurable number of days befor now
   * @returns StockOnHandItemsList OK
   * @throws ApiError
   */
  public static async stockonhandRecapSohAlignment({
    storeCode,
  }: {
    /** filter for storeCode **/
    storeCode: any;
  }): Promise<StockOnHandItemsList> {
    const result = await __request({
      method: 'GET',
      path: `/stockonhand/recapSohAlignment`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Internal server error.`,
      },
    });
    return result.body;
  }

  /**
   * service to verify PMD
   * service to verify PMD
   * @returns ProductMasterDataList OK
   * @throws ApiError
   */
  public static async stockonhandVerifyProductMasterData(): Promise<ProductMasterDataList> {
    const result = await __request({
      method: 'GET',
      path: `/stockonhand/verifyProductMasterData`,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Internal server error.`,
      },
    });
    return result.body;
  }

  /**
   * Send email to IT to manage PMD
   * send email to IT
   * @returns any OK
   * @throws ApiError
   */
  public static async stockonhandSendEmail(): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/stockonhand/sendEmail`,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Internal server error.`,
      },
    });
    return result.body;
  }

  /**
   * start stock on hand
   * send email to IT
   * @returns StartSohAlighmentResponse OK
   * @throws ApiError
   */
  public static async stockonhandStartStockOnHand({
    requestBody,
  }: {
    requestBody: StartSohAlighmentRequest;
  }): Promise<StartSohAlighmentResponse> {
    const result = await __request({
      method: 'POST',
      path: `/stockonhand/startStockOnHand`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Internal server error.`,
      },
    });
    return result.body;
  }

  /**
   * start stock on hand
   * send email to IT
   * @returns StartSohAlighmentResponse OK
   * @throws ApiError
   */
  public static async stockonhandStartStockOnHandEncoded({
    requestBody,
  }: {
    requestBody: StartSohAlighmentRequestEncoded;
  }): Promise<StartSohAlighmentResponse> {
    const result = await __request({
      method: 'POST',
      path: `/stockonhand/startStockOnHandEncoded`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Internal server error.`,
      },
    });
    return result.body;
  }

  /**
   * delete print device
   * delete print device
   * @returns any OK
   * @throws ApiError
   */
  public static async stockonhandDeleteStockOnHand({
    storeCode,
    stockOnHandId,
  }: {
    /** store code **/
    storeCode: string;
    /** pass stockOnHandID **/
    stockOnHandId: string;
  }): Promise<any> {
    const result = await __request({
      method: 'DELETE',
      path: `/stockonhand/deleteStockOnHand`,
      query: {
        storeCode: storeCode,
        stockOnHandId: stockOnHandId,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Internal server error.`,
      },
    });
    return result.body;
  }

  /**
   * SA wants to CONFIRM a Stock on Hand
   * Confirm Stock on Hand after scan
   * @returns any Stock On Hand confirmed
   * @throws ApiError
   */
  public static async stockonhandConfirmStockOnHand({
    requestBody,
  }: {
    requestBody: ConfirmStockOnHand;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/stockonhand/confirmStockOnHand`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error or SAP system not available`,
      },
    });
    return result.body;
  }

  /**
   * SA wants to CONFIRM a Stock on Hand
   * Confirm Sotck on Hand after scan
   * @returns any Stock On Hand confirmed
   * @throws ApiError
   */
  public static async stockonhandConfirmStockOnHandEncoded({
    requestBody,
  }: {
    requestBody: ConfirmStockOnHandEncode;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/stockonhand/confirmStockOnHandEncoded`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error or SAP system not available`,
      },
    });
    return result.body;
  }

  /**
   * Service to view the details of the UI reports
   * Service to view the details of the UI reports
   * @returns ReportDetailsResponse OK
   * @throws ApiError
   */
  public static async stockonhandReportDetails({
    stockOnHandId,
    isSapReport,
  }: {
    /** filter for storeId **/
    stockOnHandId: any;
    /** filter for reportUse, used to understand you are viewing the app report or sap report **/
    isSapReport: any;
  }): Promise<ReportDetailsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/stockonhand/reportDetails`,
      query: {
        stockOnHandId: stockOnHandId,
        isSapReport: isSapReport,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Internal server error.`,
      },
    });
    return result.body;
  }

  /**
   * download csv (Stock On Hand Alignment in App)
   * download csv (Stock On Hand Alignment in App)
   * @returns DownloadCsvResponse OK.
   * @throws ApiError
   */
  public static async stockonhandDownloadCsv({
    requestBody,
  }: {
    requestBody: DownloadCsvSoHRequest;
  }): Promise<DownloadCsvResponse> {
    const result = await __request({
      method: 'POST',
      path: `/stockonhand/downLoadCsv`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
