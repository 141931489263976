import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { palette } from '@/theme';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';

export const HeaderList = styled(UIBox)`
  width: 100%;
  padding: 8px 16px;
  border-bottom: 1px solid black;
`;

export const List = styled(UIBox)`
  flex-direction: column;

  & > * {
    &:first-child {
      padding: 0;

      .kPaginationListItem {
        & > * {
          &:nth-child(odd) {
            background: ${palette.colors.midGrey};
          }
        }
      }
    }
  }
`;

export const SearchBar = styled(SearchBarLight)`
  &&& {
    padding: 0;
  }
`;
