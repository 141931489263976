import { UIBox } from '@/components/ui/Box';
import styled from 'styled-components';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MuiCheckIcon from '@material-ui/icons/Check';
import { palette } from '@/theme';

export const ButtonContainer = styled(UIBox)`
  margin-top: 24px;
  max-width: 300px;
  align-self: end;

  & > * {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`;

export const ErrorIcon = styled(ErrorOutlineIcon)`
  margin-right: 8px;
  height: 32px;
  width: 32px;
  color: ${palette.colors.yellow};
`;

export const CheckIcon = styled(MuiCheckIcon)`
  margin-right: 8px;
  height: 32px;
  width: 32px;
  color: ${palette.colors.green};
`;
