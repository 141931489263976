import { Typography } from '@/components/ui/Typography';

import React, { useEffect, useState } from 'react';

import { IEpcLI } from './types';
import { ProductImage } from '@/components/layout/ProductImage';
import { ProductPrice } from '@/components/layout/ProductPrice';
import {
  StyledContentInfo,
  StyledDescription,
  StyledProductContent,
  StyledProductItem,
  StyledCheckbox,
  StyledEpcCode,
} from '@/pages/CycleCount/listItemStyle';
import { UIBox } from '@/components/ui/Box';
import { useTranslation } from 'react-i18next';

type ClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

const EpcLI: React.FC<IEpcLI> = ({ el, setChecked, forceChecked }: IEpcLI) => {
  const { t } = useTranslation();
  const {
    imageUrl,
    brandDescription,
    modelCode,
    colorCode,
    brandCode,
    commodity,
    price,
    currency,
    upcCode,
    epcCodes,
  } = el;

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(!!forceChecked);
  }, [forceChecked]);

  const handleCheckboxClick = (e: ClickEvent): void => {
    const isChecked = (e.target as HTMLInputElement).checked;

    setChecked(p => ({
      ...p,
      [epcCodes[0].epcCode]: isChecked,
    }));
  };

  return (
    <StyledProductItem>
      <StyledProductContent>
        <StyledContentInfo>
          <StyledCheckbox
            key={1}
            checked={isChecked}
            onClick={handleCheckboxClick}
          />
          <ProductImage
            imageUrl={imageUrl}
            colorCode={colorCode}
            modelCode={modelCode}
            brandCode={brandCode}
            onClick={(): void => {}}
          />
          <UIBox flexDirection="column">
            <StyledDescription>
              <Typography size="lg" font="heavy" as="span">
                {brandCode} - {brandDescription}
              </Typography>
              <Typography margin="8px 0 0 0" size="md" font="book" as="span">
                {upcCode} - {modelCode} - {commodity}
                <StyledEpcCode> - {epcCodes[0].epcCode}</StyledEpcCode>
              </Typography>
            </StyledDescription>
          </UIBox>
        </StyledContentInfo>
        <UIBox flexDirection="column">
          <ProductPrice
            currency={currency}
            price={price ? String(price) : undefined}
          />
          <UIBox alignSelf="end">
            <Typography>
              {t('itemQuantity', {
                itemQuantity: epcCodes.length,
              })}{' '}
              - {epcCodes[0].epcStatus}
            </Typography>
          </UIBox>
        </UIBox>
      </StyledProductContent>
    </StyledProductItem>
  );
};

export default EpcLI;
