import { ReceiveShipmentsService } from '@/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { ReceiveApiError as ApiError } from '@/api';
import { ProductDetailsItem } from '@/api/receive';
import { RootState } from '@/app/rootReducer';
import getCurrentDate from '@/utils/getCurrentDate';

export interface DeliveryProductsSlice {
  products: ProductDetailsItem[];
  isLoading: boolean;
  error?: ApiError;
}

const initialState: DeliveryProductsSlice = {
  isLoading: false,
  error: undefined,
  products: [],
};

export const getDeliveryProducts = createAsyncThunk<
  ProductDetailsItem[] | undefined,
  NonNullable<
    NonNullable<
      NonNullable<
        Parameters<
          typeof ReceiveShipmentsService.receiveshipSearchProductDeliveryItems
        >[number]
      >['requestBody']
    >['deliveryCodes']
  >,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'deliveryProducts/getDeliveryProducts',
  // @ts-ignore
  async (deliveryCodes, { rejectWithValue, getState }) => {
    const {
      user: { username },
      currentStore,
    } = getState();
    const date = getCurrentDate();

    if (username) {
      try {
        const { productDetailsItems } =
          await ReceiveShipmentsService.receiveshipSearchProductDeliveryItems({
            requestBody: {
              deliveryCodes,
              webusername: username,
              storeCode: currentStore.store!.storeCode,
              date,
            },
          });

        return productDetailsItems;
      } catch (error) {
        return rejectWithValue(error as ApiError);
      }
    }
  }
);

const deliveryProductsSlice = createSlice({
  name: 'deliveryProducts',
  initialState,
  reducers: {
    initDeliveryProducts: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getDeliveryProducts.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getDeliveryProducts.fulfilled, (state, { payload }) => {
        if (payload) {
          state.products = payload;
        }
        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(getDeliveryProducts.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload as ApiError;
      });
  },
});

export const { initDeliveryProducts } = deliveryProductsSlice.actions;
export default deliveryProductsSlice.reducer;
