import React from 'react';

import { UIMacroFlowMenu } from '@/components/layout/MacroFlowMenu';
import { AppFunctionalities, AppRoutes } from '@/app/routers';
import { UIButton } from '@/components/ui/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/app/store';
import { setProcessLocked } from '@/features/cycleCount/cycleCountSlice';
import useHandleProcess, { Process } from '@/hooks/useHandleProcess';
import { useHistory } from 'react-router';
import { ModalDataSavedError as Modal } from '@/components/layout/ModalDataSaved';
import { useSelector } from '@/hooks/useSelector';
import { isCycleCountAllowed, isSohaAllowed } from '@/utils/user';
import { useAsync } from 'react-use';

const PageCycleCountIntro: React.FC<AppFunctionalities> = ({ process }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleProcess = useHandleProcess();

  const user = useSelector(state => state.user);
  const sohLocked = useSelector(state => state.cycleCount.sohLocked);

  const [onGoingActivities, setOnGoingActivities] =
    React.useState<boolean>(false);

  useAsync(async () => {
    if (sohLocked) {
      await lockProcess('SOHA', false);
    }
  }, []);

  const lockProcess = async (
    process: Process,
    isLocked = true
  ): Promise<void> => {
    const body: {
      process: Process;
      isLocked: boolean;
    } = {
      process,
      isLocked,
    };

    dispatch(setProcessLocked(body));
    await handleProcess(body);
  };

  const handleCycleCountClick = (): void => {
    history.push(AppRoutes.CYCLE_COUNT);
  };

  const handleSOHClick = (): void => {
    history.push(AppRoutes.SOH_ALIGNMENT);
  };

  return (
    <>
      <Modal
        iconType="ERROR"
        $minWidth="420px"
        $minHeight="230px"
        title={t('tryAgain')}
        message={t('onGoingCycleCountMessage')}
        open={onGoingActivities}
        onClose={(): void => setOnGoingActivities(false)}
      />
      <UIMacroFlowMenu title={t('menu.title')}>
        {isCycleCountAllowed(user) && (
          <UIButton
            label={t('menu.cycle_count')}
            disabled={process?.process === 'SOHA'}
            outlined={false}
            onClick={handleCycleCountClick}
          />
        )}
        {isSohaAllowed(user) && (
          <UIButton
            label={t('menu.sohAlignment')}
            outlined={false}
            disabled={process?.process !== 'SOHA' && process?.isLocked}
            onClick={handleSOHClick}
          />
        )}
      </UIMacroFlowMenu>
    </>
  );
};

export default PageCycleCountIntro;
