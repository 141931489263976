import styled, { css } from 'styled-components';
import { palette } from '@/theme';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { UIBox } from '@/components/ui/Box';
import Link from '@material-ui/core/Link/index';

const StyledGridMediaQueries = css`
  ${({ theme }): string =>
    `${theme.breakpoints.down('md')} {
      padding: 18px 16px;
      grid-template-columns: 50px 150px auto auto;
    }`}

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
      padding: 18px 16px;
      grid-template-columns: 50px 150px auto auto;
    }`}
`;

const StyledGridColumns = css`
  display: grid;
  grid-template-columns: 50px 150px auto auto;
  gap: 48px;
  align-items: center;
`;

export const StyledListGrid = styled(UIBox)<{ $isAccordionExpanded?: boolean }>`
  ${StyledGridColumns}
  ${StyledGridMediaQueries}

	border-top: ${({ $isAccordionExpanded }): string =>
    $isAccordionExpanded ? '1px solid #EDEDED' : 'none'};
  border-left: ${({ $isAccordionExpanded }): string =>
    $isAccordionExpanded ? '1px solid #EDEDED' : 'none'};
  border-right: ${({ $isAccordionExpanded }): string =>
    $isAccordionExpanded ? '1px solid #EDEDED' : 'none'};
`;

export const StyledListGridItem = styled(UIBox)`
  padding: 5px 0;
  text-transform: capitalize;
`;

export const StyledAccordionItem = styled(UIBox)`
  padding: 12px 0 12px 112px;
  border-bottom: 1px solid #ededed;
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;

  span {
    &:not(:last-child) {
      display: block;
    }
  }
  > div {
    padding: 0;
    > div {
      margin: 0;
      .MuiButton-label {
        display: inline-flex;
      }
    }
  }
`;

export const StyledMoreHorizDot = styled(MoreHorizIcon)`
  color: #fff;
  background: #005192;
  border-radius: 15px;
`;

export const StyledPageWrapper = styled('div')`
  position: relative;
  width: 100%;
`;

export const StyledFilterWrapper = styled(UIBox)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  padding: 24px;

  & > * {
    margin: 0;
    align-self: flex-end;

    & > div {
      width: 100% !important;
    }
  }
`;

export const StyledBrandsList = styled.div`
  width: 100%;
  padding: 10px 24px 120px;

  .page-overlay {
    height: 40vh;
  }

  > div:last-child {
    padding: 0;

    .kPaginationListItem > * {
      flex-direction: column;
      &:nth-child(odd) {
        background: ${palette.colors.midGrey};
      }
    }
  }
`;

export const StyledListGridHeader = styled(UIBox)`
  padding: 18px 24px;
  border-bottom: 1px solid ${palette.colors.text};

  ${StyledGridColumns}
  ${StyledGridMediaQueries}
`;

export const StyledDownloadWrapper = styled(UIBox)`
  width: 100%;
  margin: 12px 0;

  & > button {
    margin-left: auto;
  }
`;

export const StyledSelectAllLink = styled(Link)`
  width: max-content;
  cursor: pointer;
`;
