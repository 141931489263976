export const isEmpty = (value: string | undefined | null): boolean =>
  typeof value === 'undefined' || value === null || value?.trim() === '';

export const singleElementArray = (
  arr: Array<string | number | boolean | object> | undefined | null
): boolean => {
  if (Array.isArray(arr)) {
    return arr.length === 1;
  }

  return false;
};

export const multipleElementsArray = (
  arr: Array<string | number | boolean | object> | undefined | null
): boolean => {
  if (Array.isArray(arr)) {
    return arr.length > 1;
  }

  return false;
};

export const isEmptyArray = (
  arr: Array<string | number | boolean | object> | undefined | null
): boolean => {
  if (Array.isArray(arr)) {
    return arr.length === 0;
  }

  return false;
};

export const isValidSerialNumber = (serialNumber: string): boolean =>
  /\b[0-9a-zA-z]{12}\b/g.test(serialNumber);

export const isValidIpAddress = (ipAddress: string): boolean =>
  /[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}/.test(ipAddress);

export const isValidUpc = (upc: string): boolean => /\d{4,13}/.test(upc);

export const isValidEpc = (epc: string): boolean =>
  /([A-Z0-9]{4,24})/i.test(epc);

export const isValidModel = (model: string): boolean =>
  /([A-Z0-9]{4,24})/i.test(model);
