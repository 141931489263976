import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '@/app/store';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useAsync } from 'react-use';

import { CTAContainer } from '@/components/layout/CTAContainer';
import { UIBox } from '@/components/ui/Box';
import { PageLoader } from '@/components/ui/PageLoader';
import { Typography } from '@/components/ui/Typography';

import getCurrentDate from '@/utils/getCurrentDate';
import {
  changeRecallStatus,
  initRecallState,
} from '@/features/recall/recallSlice';
import {
  deleteTags,
  updateDevice,
  removeDeviceInUse,
  initMQTTTags,
} from '@/features/devices/devicesSlice';
import { AppRoutes } from '@/app/routers';

import { RecallResponse, RecallService } from '@/api/receive';
import { StyledList, StyledTitle } from './style';
import { useRecallContext } from '@/context/recallContext';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { DeviceStatus } from '@/types/enum';
import { useSubscribeDeviceStatus } from '@/hooks/mqtt/useSubscribeDeviceStatus';
import { MQTTContext } from '@/context/MQTT';
import RecallOrderListItem from './components/RecallOrderListItem';

const PageRecall: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    recallConfirmed,
    setRecallConfirmed,
    setBackup,
    setRecallItems,
    setProductBrands,
  } = useRecallContext();
  const { changeRecallStatusHasError } = useSelector(state => state.recall);
  const { deviceInUse } = useSelector(state => state.devices);
  const history = useHistory();
  const [error, setError] = useState<boolean>(false);
  const [deviceError, setDeviceError] = useState<boolean>(false);
  const [recallOrders, setRecallOrders] = useState<RecallResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);

  const subscribeDevice = useSubscribeDeviceStatus();
  const { setDeviceIdOnMqttClient } = React.useContext(MQTTContext);

  useAsync(async () => {
    try {
      if (storeCode) {
        setLoading(true);

        if (!recallConfirmed) {
          await dispatch(changeRecallStatus()).unwrap();
        } else {
          dispatch(initRecallState());
          setRecallConfirmed(false);
        }

        setProductBrands([]);
        setBackup({
          found: [],
          missing: [],
          error: [],
        });
        setRecallItems({
          found: [],
          missing: [],
          error: [],
        });

        const date = getCurrentDate();
        const response = await RecallService.recallFindRecallOrders({
          storeCode,
          date,
        });

        if (response) {
          setRecallOrders(response.recallDetails || []);
        }

        if (!deviceInUse) {
          setLoading(false);
        }
      }
    } catch {
      setLoading(false);
      setError(true);
    }
  }, []);

  useAsync(async () => {
    if (deviceInUse) {
      try {
        await dispatch(deleteTags()).unwrap();
        dispatch(initMQTTTags());
        await dispatch(
          updateDevice({
            deviceId: deviceInUse.deviceId!,
            statusMode: 'no mode',
            statusDevice: DeviceStatus.ONLINE,
            storeId: deviceInUse.storeId!,
            nameDevice: deviceInUse.nameDevice!,
          })
        ).unwrap();
        subscribeDevice.publish(deviceInUse?.deviceId!, undefined);
        setDeviceIdOnMqttClient(undefined);
        dispatch(removeDeviceInUse());

        setLoading(false);
      } catch {
        setLoading(false);
        setDeviceError(true);
      }
    }
  }, []);

  useEffect(() => {
    if (changeRecallStatusHasError) {
      setError(true);
    }
  }, [changeRecallStatusHasError]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <StyledTitle>
        <Typography size="lg" font="heavy">
          {t('allReturnOrders')}
        </Typography>
      </StyledTitle>
      <UIBox padding={3} width="100%">
        <StyledList>
          {recallOrders.map(order => (
            <RecallOrderListItem key={order.idRecall} {...order} />
          ))}
        </StyledList>
        <CTAContainer
          type="BACK"
          onClick={(): void => history.push(AppRoutes.INTRO)}
        />
      </UIBox>
      <ErrorSnackbar
        open={error}
        setIsOpen={setError}
        errorMessage={t('error.recall_loading')}
      />
      <ErrorSnackbar
        open={deviceError}
        setIsOpen={setDeviceError}
        errorMessage={t('error.deviceCommunication')}
      />
    </>
  );
};

export default PageRecall;
