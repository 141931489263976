import styled from 'styled-components';

import Checkbox from '@material-ui/core/Checkbox';
import { ListItem } from '@material-ui/core';

export const StyledListItem = styled(ListItem)`
  width: 100%;
  flex-wrap: wrap;
  padding: 16px 24px;
  background: #fbfbfb;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.05);

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const StyledListItemCheckbox = styled(Checkbox)`
  svg {
    fill: #005192;
  }
`;
