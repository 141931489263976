import { UIBox } from '@/components/ui/Box';
import { UIList } from '@/components/ui/List';
import { Product } from '@/types/enum';
import styled from 'styled-components';

export const StyledUIListWrapper = styled(UIBox)`
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  z-index: 0;

  &.${Product.UNEXPECTED}, &.${Product.NOT_FOUND}, &.${Product.FOUND} {
    margin-top: 24px;
  }
`;

export const StyledUIList = styled(UIList)`
  &.${Product.QUALITY_INSPECTION},
    &.${Product.DAMAGED},
    &.${Product.RECALLED},
    &.${Product.IN_TRANSIT},
    &.${Product.CHECKOUT_ERROR} {
    padding: 0 24px;
    margin-bottom: 8px;
  }

  &.${Product.UNEXPECTED}, &.${Product.NOT_FOUND} {
    padding: 0 24px;
  }
`;
