import {
  EpcFoundReason,
  EpcNotFoundReason,
} from '@/components/CycleCount/ModalReason';
import { useSelector } from '@/hooks/useSelector';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import useSROTrackEvent from '@/hooks/useSROTrackEvent';

type TrackEpc = {
  User: string;
  StoreCode: string;
  UPC: string;
  EPC: string;
  ReasonCode: string;
  LinkedEvent: string;
};

type TrackEpcBody = {
  upcCode: string;
  epcCodes?: string[];
  epcCode?: string;
  reasonCode: EpcFoundReason | EpcNotFoundReason;
};

type ReturnHookType = (body: TrackEpcBody) => Promise<void>;

const useTrackEpc = (eventName: string): ReturnHookType => {
  const appInsights = useAppInsightsContext();
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const { username } = useSelector(state => state.user);

  const data: TrackEpc = {
    User: username || '',
    StoreCode: storeCode || '',
    UPC: '',
    EPC: '',
    ReasonCode: '',
    LinkedEvent: `${username}-${sessionStorage.getItem('linkedEventId')}`,
  };

  const trackEvent = useSROTrackEvent<TrackEpc>(appInsights, eventName, data);

  const trackUnexpected = async ({
    upcCode,
    epcCodes,
    epcCode,
    reasonCode,
  }: TrackEpcBody): Promise<void> => {
    const track = async (epcCode: string): Promise<void> => {
      await trackEvent({
        ...data,
        UPC: upcCode,
        EPC: epcCode,
        ReasonCode: reasonCode!,
      });
    };

    if (epcCode) {
      await track(epcCode);
    } else if (epcCodes) {
      for await (const epcCode of epcCodes) {
        await track(epcCode);
      }
    }
  };

  return trackUnexpected;
};

export default useTrackEpc;
