import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteMassiveLoaderDateResponse } from '@/api/receive';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { UIAction } from '@/components/ui/Action';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import * as S from './style';
import { theme } from '@/theme';
import { DateTime } from 'luxon';

type File = DeleteMassiveLoaderDateResponse;

type FileDetailProps = {
  onRemoveClick: (id: string, type: string) => Promise<void> | void;
};

const FileDetail: React.FC<FileDetailProps & File> = ({
  date,
  loaders,
  onRemoveClick,
}) => {
  const { i18n, t } = useTranslation();

  const [loaderID, setLoaderID] = React.useState<string>('');
  const [loaderType, setLoaderType] = React.useState<string>('');
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value.split('-');
    const id = value[0];
    const type = value[1];

    setLoaderID(id);
    setLoaderType(type);

    console.log(value);
  };

  return (
    <>
      <UIBox alignItems="center" width="100%" p={1}>
        {loaders && loaders.length > 0 && (
          <S.Accordion
            expanded={expanded}
            onClick={(): void => setExpanded(e => !e)}
          />
        )}
        <Typography>
          {DateTime.fromFormat(date!, 'yyyy-MM-dd').toFormat('yyyy LLLL dd', {
            locale: i18n.language,
          })}
        </Typography>
      </UIBox>
      {loaders && loaders.length > 0 && expanded && (
        <>
          <UIBox pt={2} pl={5} pr={5} flexDirection="column">
            <UIBox ml="auto">
              <UIAction
                icon="delete"
                label={t('remove')}
                disabled={!loaderID}
                onClick={(): Promise<void> | void =>
                  onRemoveClick(loaderID, loaderType)
                }
              />
            </UIBox>
            <UIBox flexDirection="column">
              {loaders.map(({ loaderType, loaderId, totalItems, date }) => (
                <>
                  <S.Grid mb={2} alignItems="center">
                    <FormControl>
                      <RadioGroup
                        value={`${loaderID}-${loaderType}`}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          value={`${loaderId}-${loaderType}`}
                          label={t(`${loaderType}`)}
                          control={
                            <Radio
                              style={{
                                color: theme.palette.primary.main,
                              }}
                            />
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                    <UIBox alignItems="center">
                      <Typography margin="0 4px 0 0">
                        {t('totalItems')}:
                      </Typography>
                      <Typography font="heavy">{totalItems}</Typography>
                    </UIBox>
                  </S.Grid>
                  {date && (
                    <UIBox mt={-1.5} mb={4}>
                      <Typography color="grey" margin="0 4px 0 0">
                        {t('time')}:
                      </Typography>
                      <Typography size="sm" color="grey">
                        {DateTime.fromISO(date.replaceAll(' ', 'T')).toFormat(
                          'yyyy LLLL dd - H:mm',
                          {
                            locale: i18n.language,
                          }
                        )}
                      </Typography>
                    </UIBox>
                  )}
                </>
              ))}
            </UIBox>
          </UIBox>
        </>
      )}
    </>
  );
};

export default FileDetail;
