import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { UIBox } from '@/components/ui/Box';
import { PageLoader } from '@/components/ui/PageLoader';
import { Typography } from '@/components/ui/Typography';
import { findStockItems } from '@/features/stockSummary/stockSummarySlice';
import { useSelector } from '@/hooks/useSelector';
import { palette } from '@/theme';
import { List } from '@material-ui/core';
import React, { FC, useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import qs from 'qs';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { useUpdateEffect } from 'react-use';
import { UIButton } from '@/components/ui/Button';
import { addItem, reset } from '@/features/breadcrumbs/breadcrumbsSlice';
import { useTranslation } from 'react-i18next';
import { FilterBarStockSummary } from './components/FilterBarStockSummary';
import { ItemGridList } from './components/ItemGridList';
import { StockItemDetailList } from '../../api/receive/models/StockItemDetailList';
import ModalPrintTemp from '@/components/layout/ModalPrintTemp';
import { usePrintContext } from '@/context/Print';
import type { ProductItemForPrint } from '@/api';
import { printLabel } from '@/features/print/printSlice';
import { PrintService, StockItemDetail } from '@/api/receive';
import { PaginationList } from '@/components/layout/PaginationList';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { handlePrintLayout } from '@/utils/handlePrintLayout';

//#region - Styled Components
const StyledGridMediaQueries = css<{ $query?: boolean }>`
  ${({ theme, $query }): string =>
    `${theme.breakpoints.down('md')} {
      padding: 18px 16px;
      grid-template-columns: ${$query ? '1fr 100px 100px' : 'auto 100px 100px'};
    }`}

  ${({ theme, $query }): string =>
    `${theme.breakpoints.down('sm')} {
      padding: 18px 16px;
      grid-template-columns: ${$query ? '1fr 100px 100px' : 'auto 100px 100px'};
    }`}
`;
const StyledGridColumns = css<{ $query?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $query }): string =>
    $query ? '1fr 100px 100px' : 'auto 100px 100px'};
  gap: 48px;
`;

const StyledPageWrapper = styled('div')`
  position: relative;
  width: 100%;
`;

// const StyledFilterWrapper = styled(UIBox)`
//   width: 100%;
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   gap: 40px;
//   padding: 24px;

//   & > * {
//     margin: 0;

//     & > div {
//       width: 100% !important;
//     }
//   }
// `;

const StyledBrandsList = styled(List)`
  width: 100%;
  padding-bottom: 100px;
`;

const StyledListGridWrapper = styled(UIBox)`
  flex-direction: column;

  & > * {
    &:nth-child(odd) {
      background: ${palette.colors.midGrey};
    }
  }
`;

const StyledListGridHeader = styled(UIBox)<{ $query?: boolean }>`
  padding: 18px 24px;
  border-bottom: 1px solid ${palette.colors.text};

  & > * {
    &:first-child {
      grid-column: 2/3;
    }

    &:last-child {
      grid-column: 3/-1;
    }
  }

  ${StyledGridColumns} {
    grid-template-columns: ${({ $query }): string =>
      $query ? '1fr 100px 100px' : '100px 100px'} !important;
    justify-content: flex-end;
  }
  ${StyledGridMediaQueries}
`;

const FixedContainer = styled(UIBox)`
  ${({ theme }): string => `
		position: fixed;
		flex-wrap: wrap;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 24px;
		background: ${theme.palette.common.white};
		box-shadow: 0 -1px 21px rgba(0, 0, 0, 0.25);
		justify-content: space-between;
		${theme.breakpoints.down('md')} {
			padding: 24px 16px;
		}
	`}
`;

const FixedContainerRight = styled(UIBox)`
  ${({ theme }): string => `
		gap: 105px;
		margin: 0 auto;
		justify-content: flex-end;
		padding: 0 24px;
		width: 100%;
		max-width: ${theme.breakpoints.values.lg}px;
		.lastItem {
			display: inline-block;
			width: 60px;
		}
		${theme.breakpoints.down('md')} {
			padding: 0;

			& > h1:not(:last-child) {
				margin-right: 36px;
			}
		}
	`}
`;

//#endregion

const PageStockSummaryDetails: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const { print, setPrint } = usePrintContext();

  const [brandFilter, setBrandFilter] = useState<string>('');
  const [alertSnackbar, setAlertSnackbar] = useState<{
    visible: boolean;
    message: string;
  }>({ visible: false, message: '' });

  const query: {
    brand?: string;
    upcCode?: string;
    commodity?: string;
    epcCode?: string;
    modelCode?: string;
  } = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );

  const [commodityFilter, setCommodityFilter] = useState<string>(
    query.commodity ?? t('stock.all')
  );

  const {
    stockItemDetail,
    totalStock: initialStock,
    totalTransit: initialTransit,
  } = useSelector<StockItemDetailList>(
    state => state.stockSummary.stockBrandDetail
  );

  const [totalStock, setTotalStock] = useState<number>(initialStock || 0);
  const [totalTransit, setTotalTransit] = useState<number>(initialTransit || 0);
  const [currentPage, setListCurrentPage] = useState<number>(1);

  const [oldEpc, setOldEpc] = useState<string>();
  const [productToPrint, setProductToPrint] = useState<ProductItemForPrint>();
  const [modalPrintIsVisible, setModalPrintVisibility] =
    useState<boolean>(false);
  const [updateErrorEpc, setUpdateEpcError] = useState<boolean>(false);
  const [updateEpcSuccess, setUpdateEpcSuccess] = useState<boolean>(false);

  const { stockItemsIsLoading } = useSelector(state => state.stockSummary);
  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );

  const onChangeCommodityFilter = (commodity: string): void =>
    setCommodityFilter(commodity);
  const onChangeBrandFilter = (brand: string): void => setBrandFilter(brand);

  useEffect(() => {
    const { brand, upcCode, modelCode, epcCode } = query;
    dispatch(reset());

    if (!brand && !upcCode && !modelCode && !epcCode) {
      dispatch(findStockItems());
      dispatch(addItem({ title: t('page.stock_summary') }));
    } else if (brand || upcCode || modelCode || epcCode) {
      dispatch(
        addItem({
          title: t('page.stock_summary'),
          path: AppRoutes.STOCK_SUMMARY,
        })
      );
      dispatch(addItem({ title: t('page.stock_summary_details') }));
    }
  }, [dispatch, query, t]);

  useEffect(() => {
    const { brand, upcCode, commodity, epcCode, modelCode } = query;

    dispatch(findStockItems({ brand, upcCode, epcCode, modelCode }));

    if (commodity) {
      setCommodityFilter(commodity);
    } else {
      setCommodityFilter(t('stock.all'));
    }
    //setBrandFilter('');
  }, [dispatch, query, t]);

  useUpdateEffect(() => {
    const { brand, upcCode, epcCode, modelCode } = query;

    if (!brand || !upcCode || !epcCode || !modelCode) {
      setAlertSnackbar({ visible: false, message: '' });
    }
  }, [query]);

  useUpdateEffect(() => {
    if (stockItemDetail?.length === 0 && !stockItemsIsLoading) {
      let message = '';
      switch (true) {
        case !!query.upcCode:
          message = t('stock.no_upc');
          break;
        case !!query.epcCode:
          message = t('stock.no_epc');
          break;
        case !!query.modelCode:
          message = t('stock.no_model');
          break;
        default:
          break;
      }
      setAlertSnackbar({ visible: true, message });
    }
  }, [stockItemDetail, stockItemsIsLoading]);

  useEffect(() => {
    setTotalStock(
      stockItemDetail
        ?.filter(({ brandDescription, commodity }) => {
          switch (true) {
            case commodityFilter !== t('stock.all') && brandFilter !== '':
              return (
                commodityFilter === commodity &&
                brandFilter === brandDescription
              );

            case commodityFilter !== t('stock.all'):
              return commodityFilter === commodity;

            case brandFilter !== '':
              return brandFilter === brandDescription;

            default:
              return true;
          }
        })
        .sort((a, b) => {
          if (query.brand && a.upcCode && b.upcCode) {
            if (a.upcCode > b.upcCode) {
              return 1;
            }
            if (a.upcCode < b.upcCode) {
              return -1;
            }
          }
          if (query.brand && a.modelCode && b.modelCode) {
            if (a.modelCode > b.modelCode) {
              return 1;
            }
            if (a.modelCode < b.modelCode) {
              return -1;
            }
          }
          return 0;
        })
        .map(({ stock }) => stock)
        .filter(stock => stock)
        .reduce((previousStock, currentStock) => {
          return previousStock! + currentStock!;
        }, 0) || 0
    );
  }, [brandFilter, commodityFilter, query.brand, stockItemDetail, t]);

  useEffect(() => {
    setTotalTransit(
      stockItemDetail
        ?.filter(({ commodity }) => {
          switch (true) {
            case commodityFilter !== t('stock.all'):
              return commodityFilter === commodity;
            default:
              return true;
          }
        })
        .map(({ transit }) => transit)
        .filter(transit => transit)
        .reduce((previousTransit, currentTransit) => {
          return previousTransit! + currentTransit!;
        }, 0) || 0
    );
  }, [commodityFilter, query.brand, stockItemDetail, t]);

  const onPrintActionClick = (epcCode: string, upcCode: string): void => {
    setOldEpc(epcCode);
    setProductToPrint({ upcCode });
    setModalPrintVisibility(true);
  };

  const showPrintError = (visible: boolean, message?: string): void => {
    setPrint(prevState => ({
      ...prevState,
      error: {
        visible,
        message,
      },
    }));
  };

  const refetchItems = async (): Promise<void> => {
    const { brand, upcCode, epcCode, modelCode } = query;
    dispatch(findStockItems({ brand, upcCode, epcCode, modelCode }));
  };

  const updateEpc = async (epcCodeNew: string): Promise<void> => {
    if (oldEpc) {
      try {
        await PrintService.receiveshipUpdateEpc({
          requestBody: {
            storeCode,
            epcs: [
              {
                epcCodeOld: oldEpc.toUpperCase(),
                epcCodeNew: epcCodeNew.toUpperCase(),
              },
            ],
          },
        });

        setUpdateEpcSuccess(true);
      } catch {
        setUpdateEpcError(true);
      }
    }
  };

  const onPrintClick = async (): Promise<void> => {
    if (print.error.message) {
      showPrintError(false);
    }

    if (storeCode && productToPrint) {
      try {
        const { upcCode, currency } = productToPrint;

        const response = await dispatch(
          printLabel({
            storeCode,
            layout: handlePrintLayout(
              currency || '',
              print.printer?.isRfid || false
            ),
            serialNumberPrinter: print.printer?.printerCode || '',
            upcCode: upcCode!,
          })
        ).unwrap();

        setPrint(prevState => ({
          ...prevState,
          data: {
            printedEPC: response.epc,
          },
        }));

        if (print.printer?.isRfid && response.epc) {
          await updateEpc(response.epc);
          await refetchItems();
          setModalPrintVisibility(false);
        }
      } catch {
        showPrintError(true, t('error.something_went_wrong'));
      }
    }
  };

  /**
   * Redux state is gonna change on the start use reader
   * if the selected printer is NO-RFID
   */
  const onStartUseReaderClick = async (): Promise<void> => {
    if (print.printer && !print.printer.isRfid) {
      const printedEPC = print.data.printedEPC || '';
      await updateEpc(printedEPC);
      await refetchItems();
    }
  };

  const onModalPrintClose = (): void => {
    setModalPrintVisibility(false);
    setOldEpc(undefined);
  };

  if (stockItemsIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalPrintTemp
        hideReasons
        open={modalPrintIsVisible}
        onClose={onModalPrintClose}
        productToPrint={productToPrint}
        onPrintClick={onPrintClick}
        onStartClick={onStartUseReaderClick}
      />
      <StyledPageWrapper>
        <FilterBarStockSummary
          onChangeCommodityFilter={onChangeCommodityFilter}
          onChangeBrandFilter={onChangeBrandFilter}
        />
        <StyledBrandsList>
          <StyledListGridHeader $query={!!(query.brand || query.upcCode)}>
            <Typography font="heavy" style={{ textAlign: 'center' }}>
              {t('stock.in_transit')}
            </Typography>
            <Typography font="heavy" style={{ textAlign: 'center' }}>
              {t('stock.in_stock')}
            </Typography>
          </StyledListGridHeader>
          <StyledListGridWrapper>
            <PaginationList
              currPage={currentPage}
              setCurrPage={setListCurrentPage}
              pageSize={25}
              data={
                stockItemDetail?.filter(({ commodity }) => {
                  switch (true) {
                    case commodityFilter !== t('stock.all'):
                      return commodityFilter === commodity;
                    default:
                      return true;
                  }
                }) || []
              }
              renderItem={(
                item: StockItemDetail,
                index: number
              ): JSX.Element => (
                <ItemGridList
                  key={`${item.upcCode}-${index}`}
                  item={item}
                  index={index}
                  query={query}
                  onPrintActionClick={onPrintActionClick}
                />
              )}
            ></PaginationList>
          </StyledListGridWrapper>
        </StyledBrandsList>
        <FixedContainer>
          <FixedContainerRight>
            <UIButton
              onClick={(): void => {
                history.push(AppRoutes.STOCK_SUMMARY);
              }}
              label={'back'}
              outlined={true}
              disabled={false}
              style={{ marginRight: 'auto' }}
            />
            <Typography as="h1" font="medium">
              {t('stock.total_transit')}{' '}
              <Typography font="heavy">{totalTransit}</Typography>
            </Typography>
            <Typography as="h1" font="medium">
              {t('stock.total_stock')}{' '}
              <Typography className="lastItem" font="heavy">
                {totalStock}
              </Typography>
            </Typography>
          </FixedContainerRight>
        </FixedContainer>
      </StyledPageWrapper>

      <AlertSnackbar
        open={alertSnackbar.visible}
        setIsOpen={(): React.Dispatch<
          React.SetStateAction<{
            visible: boolean;
            message: string;
          }>
        > => setAlertSnackbar}
        message={alertSnackbar.message}
      />

      <AlertSnackbar
        severity="success"
        open={updateEpcSuccess}
        setIsOpen={setUpdateEpcSuccess}
        message={t('success.updateEpc')}
      />

      <ErrorSnackbar
        open={updateErrorEpc}
        setIsOpen={setUpdateEpcError}
        errorMessage={t('error.updateEpc')}
      />
    </>
  );
};

export default PageStockSummaryDetails;
