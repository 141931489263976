import { FC, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { UIBox } from '@/components/ui/Box';
import { UIModal } from '@/components/ui/Modal';
import { Typography } from '@/components/ui/Typography';

import { palette } from '@/theme';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export interface ModalDataSavedProps {
  open: boolean;
  onClose: () => void;
}

//#region - Styled Components

const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const StyledCloseIcon = styled(IconButton)`
  align-self: baseline;

  svg {
    fill: ${palette.colors.primary};
  }
`;

const StyledConfirmModalContent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledConfirmModalTextWrapper = styled(UIBox)`
  flex-direction: column;
`;

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  margin-right: 16px;
  height: 36px;
  width: 36px;
`;

//#endregion

export const ModalReceiptInfo: FC<ModalDataSavedProps> = memo(
  ({ onClose, open }): JSX.Element => {
    const { t } = useTranslation();

    return (
      <UIModal open={open} $width="500px" $height="160px" onClose={onClose}>
        <StyledWrapper>
          <StyledConfirmModalContent>
            <StyledErrorOutlineIcon htmlColor="#F6BB3B" />
            <StyledConfirmModalTextWrapper>
              <Typography size="lg" font="heavy" as="h1">
                {t('receiptInfo')}
              </Typography>
            </StyledConfirmModalTextWrapper>
          </StyledConfirmModalContent>
          <StyledCloseIcon onClick={onClose}>
            <HighlightOffIcon />
          </StyledCloseIcon>
        </StyledWrapper>
      </UIModal>
    );
  }
);

ModalReceiptInfo.displayName = 'ModalReceiptInfo';

export default ModalReceiptInfo;
