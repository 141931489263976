import React, { useEffect, useState } from 'react';
import { useToggle } from 'react-use';

import { UIBox } from '@/components/ui/Box';
import RecallListItemInfo from '@/components/layout/RecallList/RecallListItem/RecallListItemInfo';
import { ProductAccordionItem } from '@/components/layout/ProdocutAccordionItem';

import {
  StyledProductAccordion,
  StyledProductItemWrapper,
  StyledAccortionItemContent,
} from '../style';
import type { CustomRecallUpc } from '@/types/recallSlice';
import type { ItemType } from '@/components/layout/RecallList/RecallListItem/type';

type RecallBrandListItemProps = {
  isMount?: boolean;
};

const RecallBrandListItem: React.FC<
  RecallBrandListItemProps & CustomRecallUpc
> = ({ isMount, ...productInfo }) => {
  const { epcs, missing, found, recallQuantity, quantityInStock } = productInfo;
  const [type, setType] = useState<ItemType>('NOT SPECIFIED');
  const [isAccordionExpanded, toggleAccordion] = useToggle(false);

  useEffect(() => {
    if (found) {
      const scannedQty = found.length;

      if (isMount) {
        return setType('NOT SPECIFIED');
      }

      if (scannedQty < recallQuantity && scannedQty === quantityInStock) {
        setType('NOT FOUND');
      } else {
        setType('FOUND');
      }
    }
  }, [found, isMount, quantityInStock, recallQuantity]);

  return (
    <StyledProductItemWrapper>
      <RecallListItemInfo
        {...productInfo}
        type={type}
        scannedQuantity={found?.length || 0}
        accordion={{
          isVisible: epcs?.length! > 0,
          isExpanded: isAccordionExpanded,
          toggle: toggleAccordion,
        }}
      />

      {isAccordionExpanded && (
        <>
          <StyledProductAccordion
            $v2
            items=""
            padding="0"
            expanded={isAccordionExpanded}
            toggleAccordion={toggleAccordion}
          >
            {(isMount ? missing : found)?.map(epcCode => (
              <StyledAccortionItemContent key={epcCode}>
                <UIBox alignItems="center">
                  <ProductAccordionItem epcCode={epcCode} />
                </UIBox>
              </StyledAccortionItemContent>
            ))}
          </StyledProductAccordion>
        </>
      )}
    </StyledProductItemWrapper>
  );
};

export default RecallBrandListItem;
