import { AppRoutes } from '@/app/routers';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { StyledManageRolesContainer, StyledListContainer } from './style';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import RoleListItem from '@/pages/ControlPanel/PageControlManageRole/components/RoleListItem/RoleListItem';
import { Typography } from '@/components/ui/Typography';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/app/store';
import { findAllRoles, initRolesState } from '@/features/profiling/rolesSlice';
import { useAsync } from 'react-use';
import { RolesError, RolesSelector } from '@/features/profiling/rolesSelector';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar/index';
import ModalDeleteRole from './components/ModalDeleteRole';
import { DeletedRoleInfo } from './types';

const PageControlManageRole: FC = () => {
  const [openedSnackbarError, setOpenedSnackbarError] =
    useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [currentRole, setCurrentRole] = useState<string | undefined>();
  const [deleteRole, setDeleteRole] = useState<DeletedRoleInfo | undefined>();

  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initRolesState());
  }, [dispatch]);

  useAsync(async () => {
    if (typeof deleteRole === 'undefined' || deleteRole?.deleted) {
      await dispatch(findAllRoles());
    }
  }, [dispatch, deleteRole?.role, deleteRole?.deleted]);

  const onSaveClick = async (): Promise<void> =>
    history.push(AppRoutes.CREATE_ROLE);

  const roles = RolesSelector();
  const rolesError = RolesError();

  useEffect(() => {
    setOpenedSnackbarError(typeof rolesError !== 'undefined');
  }, [rolesError]);

  const onBackClick = (): void => history.push(AppRoutes.ADMIN);

  const deleteAction = (role: string): void => {
    setCurrentRole(role);
    setOpenDeleteModal(true);
  };

  return (
    <StyledManageRolesContainer>
      <Typography font="black">{t('pageControlManageRole.title')}</Typography>

      <ErrorSnackbar
        open={openedSnackbarError}
        setIsOpen={setOpenedSnackbarError}
        errorMessage={rolesError?.message || rolesError?.body}
      />

      <StyledListContainer>
        {roles.map(role => (
          <RoleListItem role={role} key={role} deleteFn={deleteAction} />
        ))}
      </StyledListContainer>

      <ModalDeleteRole
        open={openDeleteModal}
        onClose={(): void => {
          setOpenDeleteModal(false);
        }}
        exportResult={setDeleteRole}
        role={currentRole}
      />

      <CTAContainer
        type="TWO_BUTTONS"
        onClick={(): Promise<void> => onSaveClick()}
        onBackClick={(): void => onBackClick()}
        mainButtonLabel={t('PageControlManageRole.button.0.label')}
      />
    </StyledManageRolesContainer>
  );
};

export default PageControlManageRole;
