import React, { FC, useEffect, useState, useCallback, useRef } from 'react';
import { useToggle } from 'react-use';
import { AppRoutes } from '@/app/routers';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ColumnDevice from './ColumnDevice';
import ColumnMode from './ColumnMode';
import ColumnInstructions from './ColumnInstruction';

import {
  removeDeviceInUse,
  updateDeviceStatus,
} from '@/features/devices/devicesSlice';
import { deleteTags, startReader } from '@/features/devices/devicesSlice';
import { addEpcCodesNotFound } from '@/features/products/scannedProductsSlice';

import { IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { UIBox } from '@/components/ui/Box';
import { UIModal } from '@/components/ui/Modal';
import { Typography } from '@/components/ui/Typography';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';

import { DeviceStatus, EnumMode } from '@/types/enum';
import { useAppDispatch } from '@/app/store';
import { ScanDevice } from '@/types/device';

import { theme } from '@/theme';
import { useSubscribeEpcs } from '@/hooks/mqtt/useSubscribeEpcs';
import { useSubscribeDeviceStatus } from '@/hooks/mqtt/useSubscribeDeviceStatus';
import { MQTTContext } from '@/context/MQTT';

//#region - Types
export interface ModalScanDetailsProps {
  open: boolean;
  onClose: () => void;
  resetDeviceInUse?: boolean;
  nextPageURL?: string;
  defaultMode?: EnumMode;
  disabledModes?: EnumMode[];
  hideModes?: EnumMode[];
  setIsScanCompleted?: React.Dispatch<React.SetStateAction<boolean>>;
  isScanningAgain?: boolean;
  setScanAgainCounter?: React.Dispatch<React.SetStateAction<number>>;
  scanAgainCounter?: number;
}
//#endregion

//#region - Styled Components
export const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: auto;

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
				overflow-y: auto;
		}`}
`;

export const StyledContainer = styled(UIBox)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
				display: grid !important;
				grid-template-columns: 1fr;
		}`}
`;

export const StyledCloseIcon = styled(IconButton)`
  align-self: flex-end;
  position: absolute !important;

  svg {
    fill: #005192;
  }

  ${theme.breakpoints.down('md')} {
    top: 0;
    right: 0.5rem;
  }
`;

const StyledModalTitle = styled(Typography)`
  margin-bottom: 1rem;
`;
//#endregion

export const ModalScanDetails: FC<ModalScanDetailsProps> = ({
  open,
  onClose,
  nextPageURL,
  defaultMode = EnumMode.INVENTORY,
  disabledModes = [],
  hideModes = [],
  setIsScanCompleted,
  isScanningAgain = false,
  resetDeviceInUse = false,
  setScanAgainCounter,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { username } = useSelector(state => state.user);

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [mode, setMode] = useState<EnumMode>(defaultMode);
  const [somethingGoesWrong, setSomethingGoesWrong] = useState<boolean>(false);
  const timerRef = useRef<number>();

  const {
    startReaderHasError,
    deviceInUse,
    tags: readTags,
  } = useSelector(state => state.devices);
  const [selectedDevice, setSelectedDevice] = useState<ScanDevice | undefined>(
    deviceInUse
  );

  const subscribeDevice = useSubscribeDeviceStatus();
  const subscribeEPCS = useSubscribeEpcs(deviceInUse?.deviceId!);
  const { setDeviceIdOnMqttClient } = React.useContext(MQTTContext);

  const { notFound: cycleCountProductsNotFound } = useSelector(
    state => state.cycleCount.products
  );
  const { epcs: receivingMissingEpcs } = useSelector(
    state => state.scannedProducts?.products.missing
  );

  const { store } = useSelector(state => state.currentStore);

  const [
    isDeviceAlreadyInUseErrorVisible,
    setIsDeviceAlreadyInUseErrorVisible,
  ] = useToggle(false);

  const resetDevice = useCallback(async (): Promise<void> => {
    if (deviceInUse) {
      await dispatch(deleteTags());
      await dispatch(
        updateDeviceStatus({
          deviceId: deviceInUse.deviceId!,
          statusMode: mode,
          statusDevice: DeviceStatus.ONLINE,
          storeId: deviceInUse.storeId!,
          nameDevice: deviceInUse.nameDevice!,
        })
      );
      subscribeDevice.publish(deviceInUse?.deviceId!, undefined);
      setDeviceIdOnMqttClient(undefined);
      sessionStorage.removeItem('deviceInUse');
      dispatch(removeDeviceInUse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, mode, deviceInUse]);

  useEffect(() => {
    if (startReaderHasError) {
      setIsDeviceAlreadyInUseErrorVisible(true);
    }
  }, [startReaderHasError, setIsDeviceAlreadyInUseErrorVisible]);

  useEffect(() => {
    if (deviceInUse) {
      if (readTags && readTags.length > 0) {
        if (nextPageURL) {
          history.push(nextPageURL);
        }
      }
    }
  }, [readTags, deviceInUse, nextPageURL, history]);

  const handleMode = (): void =>
    mode === EnumMode.INVENTORY
      ? setMode(EnumMode.FIND)
      : setMode(EnumMode.INVENTORY);

  const handleClose = async (): Promise<void> => {
    if (onClose) {
      onClose();

      if (
        pathname === AppRoutes.CYCLE_COUNT_FILTER_SUMMARY ||
        pathname === AppRoutes.RECEIVING_DELIVERIES ||
        pathname === AppRoutes.STORE_TRANSFER
      ) {
        clearTimeout(timerRef.current);
      }

      if (resetDeviceInUse) {
        await resetDevice();
      }
    }
  };

  const deviceTags = ():
    | {
        epc: string;
      }[] => {
    if (pathname === AppRoutes.CYCLE_COUNT_FILTER_SUMMARY) {
      if (cycleCountProductsNotFound) {
        return cycleCountProductsNotFound
          .map(({ epcCodes }) => epcCodes)
          .flat()
          .map(({ epcCode }) => ({ epc: epcCode }));
      }
    }

    return [];
  };

  const onStartReaderClick = async (): Promise<void> => {
    if (selectedDevice) {
      if (username && store?.storeCode) {
        subscribeEPCS.subscribe();
        try {
          if (isScanningAgain) {
            if (mode === EnumMode.FIND) {
              if (pathname === AppRoutes.SCAN) {
                await dispatch(
                  startReader({
                    deviceId: selectedDevice.deviceId!,
                    statusMode: mode,
                    statusDevice: DeviceStatus.BUSY,
                    storeId: store?.storeCode,
                    nameDevice: selectedDevice.nameDevice!,
                    userName: username,
                    tags: receivingMissingEpcs?.map(epc => ({ epc })),
                  })
                ).unwrap();
              } else if (pathname === AppRoutes.CYCLE_COUNT_FILTER_PRODUCTS) {
                await dispatch(
                  addEpcCodesNotFound(
                    cycleCountProductsNotFound
                      .map(({ epcCodes }) => epcCodes)
                      .flat()
                      .map(({ epcCode }) => epcCode)
                  )
                );

                await dispatch(
                  startReader({
                    deviceId: selectedDevice.deviceId!,
                    statusMode: mode,
                    statusDevice: DeviceStatus.BUSY,
                    storeId: store?.storeCode,
                    nameDevice: selectedDevice.nameDevice!,
                    userName: username,
                    tags: cycleCountProductsNotFound
                      .map(({ epcCodes }) => epcCodes)
                      .flat()
                      .map(({ epcCode }) => ({ epc: epcCode })),
                  })
                ).unwrap();
              }
              setScanAgainCounter?.(prevState => (prevState += 1));
            } else {
              await dispatch(
                startReader({
                  deviceId: selectedDevice.deviceId!,
                  statusMode: mode,
                  statusDevice: DeviceStatus.BUSY,
                  storeId: store?.storeCode,
                  nameDevice: selectedDevice.nameDevice!,
                  userName: username,
                })
              ).unwrap();
            }

            setIsScanCompleted?.(false);
            onClose?.();
          } else {
            await dispatch(
              startReader({
                deviceId: selectedDevice.deviceId!,
                statusMode: mode,
                statusDevice: DeviceStatus.BUSY,
                storeId: store?.storeCode,
                nameDevice: selectedDevice.nameDevice!,
                userName: username,
                tags: mode === EnumMode.FIND ? deviceTags() : undefined,
              })
            ).unwrap();
          }

          if (
            pathname === AppRoutes.CYCLE_COUNT_FILTER_SUMMARY ||
            pathname === AppRoutes.RECEIVING_DELIVERIES ||
            pathname === AppRoutes.STORE_TRANSFER
          ) {
            const timer = setTimeout(() => {
              if (nextPageURL) {
                history.push(nextPageURL);
              }
            }, 5000);

            //@ts-ignore
            timerRef.current = timer;
          }

          // ON SALE ITEMS PAGE
          if (pathname === AppRoutes.SALE_ITEMS) {
            setIsScanCompleted?.(prevScanStatus => !prevScanStatus);
            onClose?.();
          }
          setDeviceIdOnMqttClient(selectedDevice.deviceId);
          subscribeDevice.publish(selectedDevice.deviceId, username);
          return;
        } catch (error) {
          setScanAgainCounter?.(prevState => (prevState -= 1));
        }
      }
    }
  };

  return (
    <>
      <ErrorSnackbar
        open={somethingGoesWrong}
        setIsOpen={setSomethingGoesWrong}
        errorMessage={t('error.deviceCommunication')}
      />
      <ErrorSnackbar
        open={isDeviceAlreadyInUseErrorVisible}
        setIsOpen={setIsDeviceAlreadyInUseErrorVisible}
        errorMessage={startReaderHasError?.body?.msg}
      />
      <UIModal open={open} onClose={handleClose}>
        <StyledWrapper>
          <StyledCloseIcon onClick={handleClose}>
            <HighlightOffIcon />
          </StyledCloseIcon>
          <StyledModalTitle font="heavy" size="lg">
            {t('scanwizard.title')}
          </StyledModalTitle>
          <StyledContainer>
            <ColumnDevice
              scanContinue={isScanningAgain}
              selectedDevice={selectedDevice!}
              setDevice={setSelectedDevice}
            />
            <ColumnMode
              mode={mode}
              disabledModes={disabledModes}
              hideModes={hideModes}
              handleMode={handleMode}
            />
            <ColumnInstructions
              onStartReaderClick={onStartReaderClick}
              device={selectedDevice}
              isScanAgain={isScanningAgain}
            />
          </StyledContainer>
        </StyledWrapper>
      </UIModal>
    </>
  );
};

ModalScanDetails.displayName = 'ModalScanDetails';

export default ModalScanDetails;
