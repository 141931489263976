import styled from 'styled-components';

import { HighlightOff } from '@material-ui/icons';
import { UIButton } from '@/components/ui/Button';

export const StyledModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 16px;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
`;

export const StyledMainWrapper = styled.div`
  height: 100%;
  margin-top: 24px;
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-self: end;
  gap: 20px;
  border-top: 1px solid #eee;
  padding-top: 8px;
`;

export const CloseIcon = styled(HighlightOff)`
  cursor: pointer;
`;

export const StyledTopSpace = styled.div`
  padding-bottom: 12px;
`;

export const StyledBtn = styled(UIButton)`
  width: 72px;
`;
