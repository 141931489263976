import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { palette } from '@/theme';

export const HeaderList = styled(UIBox)`
  width: 100%;
  justify-content: space-between;
  padding: 4px 12px 4px 58px;
  border-bottom: 1px solid black;
`;

export const List = styled(UIBox)`
  flex-direction: column;

  & > * {
    &:nth-child(odd) {
      background: ${palette.colors.midGrey};
    }
  }
`;

export const InputWrapper = styled.div`
  input {
    display: none;
  }
`;
