import { FC } from 'react';
import styled from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { CheckboxProps } from '@material-ui/core';
import { UICheckbox } from '@/components/ui/Checkbox';

interface TablesCheckboxProps extends CheckboxProps {
  label: string;
  selected: number;
}

const StyledWrapper = styled(UIBox)`
  flex-direction: column;
`;

const StyledCheckboxWrapper = styled(UIBox)`
  align-items: center;

  & > span {
    padding: 9px 9px 9px 0;
  }

  p {
    color: #707173;
  }
`;

const TablesCheckbox: FC<TablesCheckboxProps> = ({
  label,
  selected,
  ...checkboxProps
}) => {
  return (
    <StyledWrapper>
      <Typography font="heavy">{label}</Typography>
      <StyledCheckboxWrapper>
        <UICheckbox {...checkboxProps} />
        <Typography as="p" font="medium">
          {selected} select
        </Typography>
      </StyledCheckboxWrapper>
    </StyledWrapper>
  );
};

export default TablesCheckbox;
