import React, { useMemo, useState } from 'react';

export type Chip = {
  key: number | string;
  label: string;
  active?: boolean;
};

const useChips = (): {
  state: string[];
  chips: Chip[];
  setChips: React.Dispatch<React.SetStateAction<string[]>>;
} => {
  const [state, setState] = useState<string[]>([]);

  const chips = useMemo(
    () =>
      state.map((value, index) => ({
        key: index,
        label: value?.toUpperCase(),
      })),
    [state]
  );

  return {
    state,
    chips,
    setChips: setState,
  };
};

export default useChips;
