import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RecallSliceInitialState } from '@/types/recallSlice';
import {
  ConfimRecallResponse,
  RecallConfirmList,
  RecallService,
} from '@/api/receive';

import type { RootState } from '@/app/rootReducer';
import type { ApiError, RecallBrand } from '@/api/receive';
import { RecallStatus } from '@/context/recallContext';

const initialState: RecallSliceInitialState = {
  recall: {
    id: '',
    status: 'New',
  },
  brands: [],
  tags: [],
  pdfCode: '',
};

export const fetchRecallOrdersDetails = createAsyncThunk<
  RecallBrand[],
  string,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'recall/fetchRecallOrdersDetails',
  async (idRecall, { getState, rejectWithValue }) => {
    const storeCode = getState().currentStore.store?.storeCode;

    try {
      if (storeCode) {
        const response = await RecallService.recallDetailsRecallOrders({
          storeCode,
          idRecall,
        });

        if (response && response.recallItemsDetails) {
          return response.recallItemsDetails;
        }
      }

      return [];
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

export const confirmRecallOrderEncoded = createAsyncThunk<
  ConfimRecallResponse,
  Omit<RecallConfirmList, 'storeCode'>,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'recall/confirmRecallOrderEncoded',
  async (requestBody, { getState, rejectWithValue }) => {
    const storeCode = getState().currentStore.store?.storeCode;

    try {
      if (storeCode) {
        const response = await RecallService.recallConfirmRecallEncoded({
          requestBody: {
            recallConfirmEncoded: btoa(
              JSON.stringify({ storeCode, ...requestBody })
            ),
          },
        });

        if (response) {
          return response;
        }
      }

      return {
        documentNumber: '',
      };
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

export const changeRecallStatus = createAsyncThunk<
  RecallSliceInitialState,
  { id: string; status: RecallStatus } | undefined,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>('recall/changeRecallStatus', async (body, { getState, rejectWithValue }) => {
  const { recall } = getState().recall;

  if (recall.id || body?.id) {
    try {
      await RecallService.recallChgangeRecallStatus({
        requestBody: {
          idRecall: body?.id || recall.id,
          status: body?.status || recall.status,
        },
      });
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }

  return initialState;
});

const recallSlice = createSlice({
  name: 'recall',
  initialState,
  reducers: {
    recallStatus: (state, { payload }: PayloadAction<RecallStatus>) => {
      state.recall.status = payload;
    },
    removeFoundTags: (
      state,
      { payload }: PayloadAction<{ upcCode: string; epcCodes: string[] }>
    ) => {
      const brands = state.brands.flatMap(({ upcs }) => upcs);
      const brand = brands.find(({ upcCode }) => upcCode === payload.upcCode);

      if (brand) {
        brand.found = brand.found?.filter(
          epcCode => !payload.epcCodes.includes(epcCode)
        );
      }
    },
    removeTags: state => {
      state.tags = [];
    },
    setRecall: (
      state,
      {
        payload,
      }: PayloadAction<{ idRecall: string; recallStatus: RecallStatus }>
    ) => {
      state.recall.id = payload.idRecall;
      state.recall.status = payload.recallStatus;
    },
    updateScannedQuantity: (state, { payload }: PayloadAction<string[]>) => {
      for (const tag of payload) {
        state.brands
          .flatMap(({ upcs }) => upcs)
          .forEach(product => {
            if (product.epcs?.includes(tag) && !product.found?.includes(tag)) {
              product.found?.push(tag);
            }
          });
      }
    },
    initRecallState: state => {
      state.recall.id = '';
      state.recall.status = undefined;
      state.brands = [];
      state.tags = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRecallOrdersDetails.pending, state => {
        state.fetchBrandsIsLoading = true;
      })
      .addCase(fetchRecallOrdersDetails.fulfilled, (state, { payload }) => {
        state.fetchBrandsIsLoading = false;
        state.brands = payload.map(brand => ({
          ...brand,
          upcs: brand.upcs.map(item => ({
            ...item,
            missing: item.epcs || [],
            found: [],
          })),
        }));
      })
      .addCase(fetchRecallOrdersDetails.rejected, (state, { payload }) => {
        state.fetchBrandsIsLoading = false;
        state.fetchBrandsHasError = payload as ApiError;
      })
      .addCase(confirmRecallOrderEncoded.pending, state => {
        state.confirmRecallOrderIsLoading = true;
      })
      .addCase(confirmRecallOrderEncoded.fulfilled, (state, { payload }) => {
        state.confirmRecallOrderIsLoading = false;
        state.pdfCode = payload.documentNumber;
      })
      .addCase(confirmRecallOrderEncoded.rejected, (state, { payload }) => {
        state.confirmRecallOrderIsLoading = false;
        state.confirmRecallOrderHasError = payload as ApiError;
      })
      .addCase(changeRecallStatus.fulfilled, () => initialState)
      .addCase(changeRecallStatus.rejected, (state, { payload }) => {
        state.changeRecallStatusHasError = payload;
      });
  },
});

export const {
  removeTags,
  setRecall,
  updateScannedQuantity,
  removeFoundTags,
  initRecallState,
  recallStatus,
} = recallSlice.actions;
export default recallSlice.reducer;
