import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  StyledInfoContainer,
  StyledInfoRow,
  StyledInfoColumn,
  StyledInfoLabel,
  StyledActiveStore,
  ButtonText,
} from './style';
import { InfoContainerProps } from './types';
import AntSwitch from '../Switch/AntSwitch/AntSwitch';
import ModalSetTimezone from '@/components/ui/ModalSetTimezone';
import { Typography } from '../Typography';
import ModalSetStoreType from '../ModalSetStoreType';

export const InfoContainer: FC<InfoContainerProps> = memo(
  ({
    name,
    address,
    id,
    storeStatus,
    storeIsLive,
    storeLiveDisabled,
    setStoreActive,
    setStoreLive,
    currentTimezone,
    setTimezone,
    newTimezone,
    currentStoreType,
    setStoreType,
    newStoreType,
  }): JSX.Element => {
    const { t } = useTranslation();
    const [openUpdateTimezone, setOpenUpdateTimezone] =
      useState<boolean>(false);
    const [openUpdateStoreType, setOpenUpdateStoreType] =
      useState<boolean>(false);
    const hasNewTz = newTimezone !== '' && newTimezone !== currentTimezone;
    const hasNewStoreType =
      newStoreType !== '' && newStoreType !== currentStoreType;

    return (
      <>
        <StyledInfoContainer>
          <StyledInfoRow>
            <LocationOnIcon />
            <StyledInfoColumn>
              <StyledInfoLabel theme="bold">{name}</StyledInfoLabel>
              <StyledInfoLabel theme="bold">{id}</StyledInfoLabel>
              <StyledInfoLabel>{address}</StyledInfoLabel>
            </StyledInfoColumn>
          </StyledInfoRow>
        </StyledInfoContainer>
        <StyledInfoContainer className="second-row">
          <StyledInfoRow>
            <strong>{t('infoContainer.timezone.title')} * :</strong>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                style={
                  hasNewTz
                    ? { textDecoration: 'line-through' }
                    : !currentTimezone
                    ? { fontWeight: 'bold', color: '#E20404' }
                    : {}
                }
              >
                {currentTimezone || t('notFound')}
              </Typography>
              {hasNewTz && <Typography>{newTimezone}</Typography>}
            </div>
            <ButtonText
              onClick={(): void => setOpenUpdateTimezone(true)}
              color="primary"
            >
              {t('infoContainer.timezone.change')}
            </ButtonText>
          </StyledInfoRow>
          <StyledInfoRow>
            <strong>{t('infoContainer.storetype.title')} * :</strong>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                style={
                  hasNewStoreType
                    ? { textDecoration: 'line-through' }
                    : !currentStoreType
                    ? { fontWeight: 'bold', color: '#E20404' }
                    : {}
                }
              >
                {currentStoreType || t('notFound')}
              </Typography>
              {hasNewStoreType && <Typography>{newStoreType}</Typography>}
            </div>
            <ButtonText
              onClick={(): void => setOpenUpdateStoreType(true)}
              color="primary"
            >
              {t('infoContainer.storetype.change')}
            </ButtonText>
          </StyledInfoRow>
          <StyledInfoRow>
            <StyledActiveStore>{t('isLive')}</StyledActiveStore>
            <AntSwitch
              checked={storeIsLive || false}
              disabled={storeLiveDisabled}
              onClick={(): void => setStoreLive?.(isLive => !isLive)}
            />
          </StyledInfoRow>
          <StyledInfoRow>
            <StyledActiveStore>{t('Active store')}</StyledActiveStore>
            <AntSwitch
              checked={storeStatus || false}
              onClick={(): void => setStoreActive?.(!storeStatus)}
            />
          </StyledInfoRow>
        </StyledInfoContainer>
        <ModalSetTimezone
          open={openUpdateTimezone}
          setOpen={setOpenUpdateTimezone}
          setCurTimezone={setTimezone}
          curTimezone={currentTimezone || ''}
        />
        <ModalSetStoreType
          open={openUpdateStoreType}
          setOpen={setOpenUpdateStoreType}
          setCurStoreType={setStoreType}
          curStoreType={currentStoreType || ''}
        />
      </>
    );
  }
);

InfoContainer.displayName = 'InfoContainer';

export default InfoContainer;
