import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Calendar from 'color-calendar';
import 'color-calendar/dist/css/theme-basic.css';

import { colors } from '@/theme';
import type { Event } from '@/types/notification';
import type { NotificationDetailsResponse as Notification } from '@/api/receive';
import { useTranslation } from 'react-i18next';

type UICalendarProps = {
  currentDate?: Date;
  events: Notification[];
  filters?: string[];
  dayIsSelected?: boolean;
  onDayClick?: Calendar['selectedDateClicked'];
  onDateChange: Calendar['dateChanged'];
  onMonthChange: Calendar['monthChanged'];
};

const StyledCalendar = styled('div')<{ dayIsSelected?: boolean }>`
  width: 100%;
  padding: 0 32px;

  .color-calendar.basic {
    --cal-color-primary: ${colors.primary};
    --cal-header-color: ${colors.primary};
    --cal-weekdays-color: #929496;
    --cal-font-family-header: 'AvenirLTStd-Heavy', sans-serif;
    --cal-font-family-weekdays: 'AvenirLTStd-Medium', sans-serif;
    --cal-font-family-body: 'AvenirLTStd-Medium', sans-serif;
  }

  .color-calendar {
    width: 100%;
  }

  .calendar__header,
  .calendar__days,
  .calendar__weekdays {
    grid-template-columns: repeat(7, minmax(20px, 100%)) !important;
  }

  .calendar__days {
    row-gap: 16px;
  }

  .calendar__day {
    &-box {
      width: initial !important;
      padding: 18px !important;
      border-radius: 100px !important;
      box-shadow: none !important;
    }

    &-bullet {
      background-color: #929496 !important;
    }
  }

  /* .calendar__day-selected {
    .calendar__day-box {
      border: 1px solid ${colors.primary} !important;
      background-color: transparent !important;
    }
  } */

  .calendar__day-selected.calendar__day-event {
    .calendar__day-text {
      color: ${({ dayIsSelected }): string =>
        dayIsSelected ? '#fff' : colors.primary} !important;
    }

    .calendar__day-box {
      border: 1px solid ${colors.primary} !important;
      background-color: ${({ dayIsSelected }): string =>
        dayIsSelected ? colors.primary : 'transparent'} !important;
    }
  }

  .calendar__day-today {
    .calendar__day-box {
      border: 1px solid ${colors.primary} !important;
      background-color: transparent !important;
    }
  }

  .calendar__day-event {
    .calendar__day-bullet {
      width: 8px !important;
      height: 8px !important;
      top: 100% !important;
    }
  }

  .calendar__day-no-event {
    pointer-events: none !important;
    color: grey !important;

    &.calendar__day-selected {
      .calendar__day-box {
        border: 1px solid ${colors.primary} !important;
        background-color: transparent !important;
      }
    }
  }
`;

const UICalendar: React.FC<UICalendarProps> = ({
  currentDate,
  events,
  filters,
  onDayClick,
  onDateChange,
  onMonthChange,
  dayIsSelected,
}) => {
  const { t } = useTranslation();
  const [calendar, setCalendar] = useState<Calendar>();

  const eventsData: Event[] = useMemo(
    () =>
      events
        .map(event => ({
          ...event,
          start: `${event.dateNotification.replaceAll('/', '-')}T06:00:00`,
          end: `${event.dateNotification.replaceAll('/', '-')}T06:00:00`,
        }))
        .filter(({ typeProcess }) => filters?.includes(typeProcess)),
    [events, filters]
  );

  useEffect(() => {
    setCalendar(
      () =>
        new Calendar({
          id: '#color-calendar',
          theme: 'basic',
          monthDisplayType: 'long',
          calendarSize: 'large',
          dropShadow: 'none',
          customMonthValues: [
            t('jan'),
            t('feb'),
            t('mar'),
            t('apr'),
            t('may'),
            t('jun'),
            t('jul'),
            t('aug'),
            t('sep'),
            t('oct'),
            t('nov'),
            t('dec'),
          ],
          customWeekdayValues: [
            t('sun'),
            t('mon'),
            t('tue'),
            t('wed'),
            t('thu'),
            t('fri'),
            t('sat'),
          ],
          eventsData,
        })
    );
  }, [eventsData, t]);

  useEffect(() => {
    if (calendar) {
      calendar.dateChanged = onDateChange;
      calendar.monthChanged = onMonthChange;
      calendar.selectedDateClicked = onDayClick;
    }
  }, [calendar, onDateChange, onDayClick, onMonthChange]);

  useEffect(() => {
    if (calendar && currentDate) {
      calendar.reset(currentDate);
    }
  }, [calendar, currentDate]);

  return <StyledCalendar id="color-calendar" dayIsSelected={dayIsSelected} />;
};

export default UICalendar;
