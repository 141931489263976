import { ProductDetailsItemsPos } from '@/api/receive';

export enum ListType {
  MANAGED = 'MANAGED',
  NOT_MANAGED = 'NOT_MANAGED',
}

export type ActionsDisabled = 'UPC_CODE' | 'EPC_CODE' | boolean;

export type SaleExceptionsListProps = {
  title: string;
  products: ProductDetailsItemsPos[];
  productsCount: number;
  type: ListType;
  actionsDisabled?: ActionsDisabled;
  managedList?: {
    onConfirm: () => Promise<void> | void;
    onRemove?: () => Promise<void> | void;
  };
  notManagedList?: {
    onRemove: () => Promise<void> | void;
  };
};
