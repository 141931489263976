/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Reprice } from '../models/Reprice';
import type { RepricePrintStatusRequest } from '../models/RepricePrintStatusRequest';
import type { SearchProductToRepriceRequest } from '../models/SearchProductToRepriceRequest';
import { request as __request } from '../core/request';

export class RepriceService {
  /**
   * /repricePrintStatus
   * Update print status and gets the products to reprice
   * @returns Reprice OK
   * @throws ApiError
   */
  public static async repricePrintStatus({
    requestBody,
  }: {
    requestBody: RepricePrintStatusRequest;
  }): Promise<Reprice> {
    const result = await __request({
      method: 'PUT',
      path: `/reprice/repricePrintStatus`,
      body: requestBody,
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /searchProductToReprice
   * Gets the products to reprice
   * @returns Reprice OK
   * @throws ApiError
   */
  public static async searchProductToReprice({
    requestBody,
  }: {
    requestBody: SearchProductToRepriceRequest;
  }): Promise<Reprice> {
    const result = await __request({
      method: 'POST',
      path: `/reprice/searchProductToReprice`,
      body: requestBody,
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }
}
