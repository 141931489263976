import React, { useEffect, useState } from 'react';
import styled, {
  css,
  CSSProperties,
  FlattenSimpleInterpolation,
} from 'styled-components';
import { useAsync } from 'react-use';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UIBox } from '@/components/ui/Box';
import { UIAction } from '@/components/ui/Action';
import { UIModal } from '@/components/ui/Modal';
import { UIButton } from '@/components/ui/Button';
import { Typography } from '@/components/ui/Typography';
import { DamageList } from '@/components/layout/DamageList';
import { PageLoader } from '@/components/ui/PageLoader';
import { IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import {
  backToStockDamagedItems,
  confirmValidateDamages,
  fetchDamageItemsToValidate,
} from '@/features/storeDamages/storeDamagesSlice';

import type {
  ConfimCreateDamages,
  SearchItemsForCreateDamagesResponse,
  UpcDamagesAndReturns,
} from '@/api/receive';
import { AppRoutes } from '@/app/routers';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';
import { CTAContainer } from '@/components/layout/CTAContainer';

//#region - Styled Components
const StyledPageWrapper = styled('div')`
  position: relative;
  padding: 24px;
  width: 100%;
`;

const SyledModalWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const StyledCloseIcon = styled(IconButton)`
  align-self: flex-end;
  right: 12px;
  top: 12px;
  position: absolute !important;
  z-index: 1;

  svg {
    fill: #005192;
  }
`;

const StyledSearchBar = styled(SearchBarLight)`
  padding: 0;
`;

const SyledButtonContainer = styled(UIBox)`
  align-items: center;
  gap: 43px;
  width: 60%;
  align-self: flex-end;
`;

const StyledSpan = styled(Typography)`
  margin-top: 8px;
`;

const StyledAction = styled(UIAction)<{ margin?: CSSProperties['margin'] }>`
  ${({ margin }): false | FlattenSimpleInterpolation | undefined => css`
    margin: ${margin};
  `};
`;
//#endregion

const PageStoreDamagesValidate: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [listCurrentPage, setListCurrentPage] = useState<number>(1);
  const [listSearchedCurrentPage, setListSearchedCurrentPage] =
    useState<number>(1);
  const [action, setAction] = useState<'CONFIRM' | 'BACK-TO-STOCK'>('CONFIRM');
  const [searchedEpc, setSearchedEpc] = useState<string>('');
  const [confirmError, setConfirmError] = useState<boolean>(false);
  const [confirmModalIsOpen, setConfirmModalVisibility] =
    useState<boolean>(false);
  const [paginationItems, setPaginationItems] = useState<
    SearchItemsForCreateDamagesResponse[]
  >([]);
  const [searchedItems, setSearchedItems] = useState<
    SearchItemsForCreateDamagesResponse[]
  >([]);

  const { store } = useSelector(state => state.currentStore);
  const {
    damageItems,
    selectedEpcs,
    fetchDamageItemsToValidateIsLoading,
    confirmValidateDamagesIsLoading,
    confirmValidateDamagesHasError,
    backToStockDamagedItemsIsLoading,
    backToStockDamagedItemsHasError,
  } = useSelector(state => state.storeDamages);

  const actionIsLoading =
    action === 'CONFIRM'
      ? confirmValidateDamagesIsLoading
      : backToStockDamagedItemsIsLoading;

  useAsync(async () => {
    await dispatch(fetchDamageItemsToValidate());
  }, []);

  useEffect(() => {
    if (confirmValidateDamagesHasError) {
      setConfirmError(true);
    }
  }, [confirmValidateDamagesHasError]);

  useEffect(() => {
    if (backToStockDamagedItemsHasError) {
      setConfirmError(true);
    }
  }, [backToStockDamagedItemsHasError]);

  useEffect(() => {
    if (damageItems) {
      setPaginationItems(damageItems);
    }
  }, [damageItems]);

  const damageItemsCount = damageItems?.flatMap(
    ({ epcCodes }) => epcCodes
  ).length;

  const confirmDamagesHandleClick = async (): Promise<void> => {
    if (store?.storeCode && damageItems) {
      try {
        const storeCode = store.storeCode;

        const upcCodes: UpcDamagesAndReturns[] = damageItems
          .map(({ upcCode, epcCodes }) => ({
            upcCode,
            epcs: epcCodes
              .filter(({ epcCode }) => selectedEpcs.includes(epcCode))
              .map(({ epcCode, movementTypeCode, movementReasonCode }) => ({
                epcCode,
                movementTypeCode,
                movementReasonCode,
              })),
          }))
          .filter(({ epcs }) => epcs.length > 0);

        const requestBody: ConfimCreateDamages = { storeCode, upcCodes };

        if (action === 'CONFIRM') {
          await dispatch(confirmValidateDamages(requestBody)).unwrap();
        } else {
          await dispatch(backToStockDamagedItems(requestBody)).unwrap();
        }

        history.push(AppRoutes.PDF_DOWNLOAD_VALIDATE_DAMAGE);
      } catch (err) {
        setConfirmModalVisibility(false);
        setConfirmError(true);
      }
    }
  };

  const confirmActionHandler = (): void => {
    setConfirmModalVisibility(true);
    setAction('CONFIRM');
  };

  const backToStockActionHandler = (): void => {
    setConfirmModalVisibility(true);
    setAction('BACK-TO-STOCK');
  };

  const changeEpcHandler = (value: string): void => {
    setSearchedEpc(value);
  };

  const searchBarKeyPressHandler = (
    e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>
  ): void => {
    const searchEpcs = (): void => {
      if (damageItems) {
        if (searchedEpc !== '') {
          setSearchedItems(state => {
            const prevState = [...state];

            const items = damageItems.filter(
              ({ epcCodes }) =>
                epcCodes.filter(({ epcCode }) =>
                  epcCode.toLowerCase().includes(searchedEpc.toLowerCase())
                ).length > 0
            );

            items.forEach(item => {
              const filteredEpcs = item.epcCodes.filter(({ epcCode }) =>
                epcCode.toLowerCase().includes(searchedEpc.toLowerCase())
              );

              const itemIndex = prevState.findIndex(
                ({ upcCode }) => item.upcCode === upcCode
              );

              if (itemIndex !== -1) {
                const epcCodes = [
                  ...prevState[itemIndex].epcCodes,
                  ...filteredEpcs,
                ];

                const filtered = [
                  ...new Map(
                    epcCodes.map(item => [item.epcCode, item])
                  ).values(),
                ];

                prevState[itemIndex].epcCodes = filtered;
              } else {
                prevState.push({ ...item, epcCodes: filteredEpcs });
              }
            });

            return prevState;
          });

          setPaginationItems(prevState => [
            ...prevState
              .map(item => ({
                ...item,
                epcCodes: item.epcCodes.filter(
                  ({ epcCode }) =>
                    !epcCode.toLowerCase().includes(searchedEpc.toLowerCase())
                ),
              }))
              .filter(({ epcCodes }) => epcCodes.length > 0),
          ]);
        } else {
          setSearchedItems([]);
          setPaginationItems(damageItems);
        }
      }
    };

    if ('key' in e) {
      if (e.key === 'Enter') {
        return searchEpcs();
      }

      return;
    }

    searchEpcs();
  };

  const modalCloseHandler = (): void => {
    setConfirmModalVisibility(false);
  };

  if (fetchDamageItemsToValidateIsLoading) {
    return <PageLoader />;
  }

  return (
    <StyledPageWrapper>
      <UIModal
        $minWidth="600px"
        $minHeight="225px"
        open={confirmModalIsOpen}
        onClose={modalCloseHandler}
      >
        <SyledModalWrapper>
          <StyledCloseIcon onClick={modalCloseHandler}>
            <HighlightOffIcon />
          </StyledCloseIcon>
          <UIBox marginTop={3} flexDirection="column">
            <Typography font="medium" size="lg">
              {action === 'CONFIRM'
                ? t('declareDamagedItems', { selected: selectedEpcs.length })
                : t('backToStockDamagedItems', {
                    selected: selectedEpcs.length,
                  })}
            </Typography>
            <StyledSpan font="medium" size="lg">
              {t('checkConfirm')}
            </StyledSpan>
          </UIBox>
          <SyledButtonContainer>
            <UIButton
              outlined
              label={t('no')}
              disabled={actionIsLoading}
              onClick={modalCloseHandler}
            />
            <UIButton
              outlined
              label={t('yes')}
              loading={actionIsLoading}
              disabled={actionIsLoading}
              onClick={confirmDamagesHandleClick}
            />
          </SyledButtonContainer>
        </SyledModalWrapper>
      </UIModal>
      <UIBox justifyContent="space-between">
        <UIBox flexDirection="column">
          <Typography font="heavy" size="lg">
            {t('damagedItemsList')}
          </Typography>
        </UIBox>
        <StyledSearchBar
          label=""
          value={searchedEpc}
          placeholder={t('searchByEpc')}
          disabled={false}
          onKeyPress={searchBarKeyPressHandler}
          onSearch={searchBarKeyPressHandler}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            changeEpcHandler(e.target.value)
          }
          loading={false}
          hideButton={true}
          helperText={t('searchItemManually')}
          inputProps={{ maxLength: 25 }}
        />
      </UIBox>

      <UIBox marginTop={3} flexDirection="column">
        <Typography>
          {t('items')}: <Typography font="black">{damageItemsCount}</Typography>
        </Typography>
      </UIBox>
      {searchedItems.length > 0 && (
        <DamageList
          paginationItems={searchedItems}
          listCurrentPage={listSearchedCurrentPage}
          setListCurrentPage={setListSearchedCurrentPage}
          hasSelectAll={false}
          actions={
            <UIBox marginLeft="auto">
              <StyledAction
                label={t('confirmDamage')}
                disabled={selectedEpcs.length === 0}
                icon="confirm"
                onClick={confirmActionHandler}
                margin="0 24px"
              />
              <StyledAction
                label={t('backToStock')}
                icon="backupRestore"
                onClick={backToStockActionHandler}
                disabled={selectedEpcs.length === 0}
              />
            </UIBox>
          }
        />
      )}
      <DamageList
        paginationItems={paginationItems}
        listCurrentPage={listCurrentPage}
        setListCurrentPage={setListCurrentPage}
        actions={
          <>
            {searchedItems.length === 0 && (
              <UIBox marginLeft="auto">
                <StyledAction
                  label={t('confirmDamage')}
                  disabled={selectedEpcs.length === 0}
                  icon="confirm"
                  onClick={confirmActionHandler}
                  margin="0 24px"
                />
                <StyledAction
                  label={t('backToStock')}
                  icon="backupRestore"
                  onClick={backToStockActionHandler}
                  disabled={selectedEpcs.length === 0}
                />
              </UIBox>
            )}
          </>
        }
      />
      <CTAContainer type="BACK" onClick={(): void => history.goBack()} />
      <ErrorSnackbar
        open={confirmError}
        setIsOpen={setConfirmError}
        errorMessage={
          action === 'CONFIRM'
            ? confirmValidateDamagesHasError?.body.errorMessage ||
              confirmValidateDamagesHasError?.body
            : backToStockDamagedItemsHasError?.body.errorMessage ||
              backToStockDamagedItemsHasError?.body
        }
      />
    </StyledPageWrapper>
  );
};

export default PageStoreDamagesValidate;
