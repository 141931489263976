import { UIBox } from '@/components/ui/Box';
import styled from 'styled-components';

export const StyledCard = styled(UIBox)`
  width: calc(100% - 5vw);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 10px 0px #0000001a;
  margin: 56px auto;
  padding: 100px;
  text-align: center;

  h3 {
    margin: 50px 0 24px 0;
  }

  p {
    margin: 0;
  }
`;
