import React from 'react';
import * as S from '../style';
import { StockOnHandItems } from '@/api/receive';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { useTranslation } from 'react-i18next';
import { UIButtonWithIcon } from '@/components/ui/Button';
import { ReportData } from '..';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

type RecapItemProps = {
  onClick: (id: string) => Promise<void> | void;
  onReportClick: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    report: ReportData
  ) => void;
};

const RecapItem: React.FC<StockOnHandItems & RecapItemProps> = ({
  id,
  date,
  stockOnHandStatus,
  onClick,
  onReportClick,
}) => {
  const { t } = useTranslation();

  return (
    <S.ListItem
      //@ts-ignore
      button={stockOnHandStatus === 'ONGOING'}
      onClick={(): Promise<void> | void => onClick(id || '')}
    >
      <UIBox width="100%" alignItems="center">
        <S.CalendarIcon />
        <Typography>{date}</Typography>
        {stockOnHandStatus === 'ONGOING' ? (
          <UIBox marginLeft="auto" alignItems="center">
            <Typography>{t('ongoing')}</Typography>
            <S.IconWrapper>
              <MoreHorizIcon htmlColor="#fff" />
            </S.IconWrapper>
          </UIBox>
        ) : (
          <S.Actions>
            <UIButtonWithIcon
              label={t('appReportDetails')}
              endIcon={<S.Sarch />}
              onClick={(e): void =>
                onReportClick(e, {
                  id: id || '',
                  report: 'APP',
                })
              }
            />
            <UIButtonWithIcon
              label={t('sapReportDetails')}
              endIcon={<S.Sarch />}
              disabled={stockOnHandStatus !== 'CONFIRMED IN SAP'}
              onClick={(e): void =>
                onReportClick(e, {
                  id: id || '',
                  report: 'SAP',
                })
              }
            />
          </S.Actions>
        )}
      </UIBox>
      {stockOnHandStatus !== 'CONFIRMED IN SAP' &&
        stockOnHandStatus !== 'ONGOING' && (
          <UIBox mt={2} alignItems="center" ml="auto">
            <S.ErrorIcon />
            <Typography size="xs">{t('sapReportUnavailable')}</Typography>
          </UIBox>
        )}
    </S.ListItem>
  );
};

export default RecapItem;
