import styled from 'styled-components';

import { Chip } from '@material-ui/core';
import { UIBox } from '@/components/ui/Box';
import { SearchBar } from '@/components/layout/SearchBar';
import { palette } from '@/theme';
import { Typography } from '@/components/ui/Typography';

export const StyledPageContainer = styled(UIBox)`
  width: 100%;
  flex-direction: column;

  & > * {
    padding: 0 24px;
  }
`;

export const StyledTitle = styled(Typography)`
  margin: 24px 0 24px;
  text-align: center;
`;

export const StyledSearchWrapper = styled(UIBox)`
  width: 100%;
  flex-direction: column;
  margin-top: 36px;
`;

export const StyledSearchBar = styled(SearchBar)`
  && {
    align-self: baseline;
    padding: 16px 0 0;
  }
`;

export const StyledListWrapper = styled(UIBox)`
  flex-direction: column;
  align-items: flex-start;
  margin-top: 32px;
`;

export const StyledCycleCountChip = styled(Chip)`
  && {
    background: #707173; // ? Add color in palette object
    color: ${palette.colors.white};
    min-width: 85px;
    font-size: 12px;
  }
`;

export const TitleListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
