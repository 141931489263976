/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckEps } from '../models/CheckEps';
import type { CheckStatusMigration } from '../models/CheckStatusMigration';
import type { CsvCheckMigration } from '../models/CsvCheckMigration';
import type { MigrationStock } from '../models/MigrationStock';
import { request as __request } from '../core/request';

export class MigrationService {
  /**
   * CSV loading service
   * PoC US CSV loading service by store code and file name
   * @returns any File wrote
   * @throws ApiError
   */
  public static async migrationLoadPocUsCsv({
    storeCode,
    fileName,
  }: {
    /** load csv in the following store **/
    storeCode: string;
    /** file name with the extension **/
    fileName: string;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/migration/loadPocUsCsv`,
      query: {
        storeCode: storeCode,
        fileName: fileName,
      },
      errors: {
        400: `Bad request`,
        500: `Internal server error`,
      },
    });
    return result.body;
  }

  /**
   * checkStatusMigration
   * check Status Migration
   * @returns CheckStatusMigration OK
   * @throws ApiError
   */
  public static async migrationCheckStatusMigration({
    storeCode,
  }: {
    /** filter for storeCode **/
    storeCode: any;
  }): Promise<CheckStatusMigration> {
    const result = await __request({
      method: 'GET',
      path: `/migration/checkStatusMigration`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad request.`,
      },
    });
    return result.body;
  }

  /**
   * checkTags
   * check Tags
   * @returns CsvCheckMigration OK
   * @throws ApiError
   */
  public static async migrationCheckTags({
    requestBody,
  }: {
    requestBody: CheckEps;
  }): Promise<CsvCheckMigration> {
    const result = await __request({
      method: 'POST',
      path: `/migration/checkTags`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
      },
    });
    return result.body;
  }

  /**
   * migration Stock
   * migration Stock after scan
   * @returns any Confirmed
   * @throws ApiError
   */
  public static async migrationMigrationStock({
    requestBody,
  }: {
    requestBody: MigrationStock;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/migration/migrationStock`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        500: `Unexpected error`,
      },
    });
    return result.body;
  }
}
