import { FC } from 'react';
import styled from 'styled-components';

import Radio, { RadioProps } from '@material-ui/core/Radio';

import { DeviceInfo, DeviceInfoProps } from '@/components/layout/DeviceInfo';
import { colors } from '@/theme';

export const StyledRadio = styled(Radio)<RadioProps>`
  svg {
    fill: ${({ disabled }): string =>
      disabled ? 'gray' : `${colors.primary}`};
  }
`;

export const DeviceInfoCheckbox: FC<
  DeviceInfoProps & RadioProps & { $v2?: boolean }
> = ({
  deviceName,
  status,
  battery,
  userName,
  num_tag,
  $v2 = false,
  ...radioProps
}): JSX.Element => {
  return (
    <>
      <StyledRadio {...radioProps} />
      <DeviceInfo
        deviceName={deviceName}
        status={status}
        battery={battery}
        num_tag={num_tag}
        userName={userName}
        $v2={$v2}
      />
    </>
  );
};

DeviceInfoCheckbox.displayName = 'DeviceInfoCheckbox';

export default DeviceInfoCheckbox;
