import React, { FC, useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  ModalProps,
  RadioGroup,
} from '@material-ui/core';
import styled from 'styled-components';

import { UIModal } from '@/components/ui/Modal';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { useSelector } from '@/hooks/useSelector';
import { UIButton } from '@/components/ui/Button';
import { useTranslation } from 'react-i18next';
import { ScanDevice } from '@/types/device';
import { StyledDeviceInfoWrapper } from '../ModalScanDetailsV2/styles';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import { DeviceInfoCheckbox } from '../DeviceinfoCheckbox';
import { useSubscribeEpcs } from '@/hooks/mqtt/useSubscribeEpcs';
import { useSubscribeDeviceStatus } from '@/hooks/mqtt/useSubscribeDeviceStatus';
import { MQTTContext } from '@/context/MQTT';
import { useAppDispatch } from '@/app/store';
import { DeviceStatus } from '@/types/enum';
import { addDeviceInUse } from '@/features/devices/devicesSlice';
import { useAsync } from 'react-use';

export interface ModalSelectDevicesProps
  extends Omit<ModalProps, 'onClose' | 'children'> {
  onClose: () => void;
  onConfirm?: () => void;
}

//#region - Styled Components
export const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const StyledCloseIcon = styled(IconButton)`
  align-self: flex-end;
  position: absolute !important;
  z-index: 1;

  svg {
    fill: #005192;
  }
`;

export const StyledFormControl = styled(FormControl)`
  height: 100%;
  padding: 32px 8px;
  margin-top: 8px;
`;

export const StyledControlLabel = styled(FormControlLabel)`
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }

  svg {
    fill: #005192;
  }
`;
//#endregion

const ModalSelectDevices: FC<ModalSelectDevicesProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { deviceList, deviceInUse } = useSelector(state => state.devices);
  const [device, setDevice] = useState<ScanDevice>();
  const [devices, setDevices] = useState<ScanDevice[]>([]);
  const { username } = useSelector(state => state.user);
  const { store } = useSelector(state => state.currentStore);
  const { setDeviceIdOnMqttClient } = React.useContext(MQTTContext);
  const subscribeDevice = useSubscribeDeviceStatus();
  const subscribeEPCS = useSubscribeEpcs();

  const onDeviceChange = (dev: ScanDevice): void => {
    setDevice(dev);
  };

  const onDeviceSelect = (): void => {
    if (device && store && username) {
      setDeviceIdOnMqttClient(device.deviceId);
      subscribeDevice.publish(device.deviceId, username);
      subscribeEPCS.setTopic(device.deviceId);
      subscribeEPCS.subscribe();
      dispatch(
        addDeviceInUse({
          ...device,
          userName: username,
          storeId: store.storeCode || '',
          status: DeviceStatus.BUSY,
        })
      );
    }
  };

  useEffect(() => {
    setDevices(deviceList.filter(({ status }) => status !== 'offline'));
  }, [deviceList, deviceList.length]);

  useAsync(async () => {
    if (device) {
      if (deviceInUse && deviceInUse.deviceId) {
        if (onConfirm) {
          await onConfirm();
        }
        onClose();
      }
    }
  }, [device, deviceInUse?.deviceId]);

  return (
    <UIModal $minWidth="450px" $minHeight="auto" open={open} onClose={onClose}>
      <StyledWrapper>
        <StyledFormControl>
          <Typography size="lg" font="heavy" as="h1">
            {t('selectDevice')}
          </Typography>
          <RadioGroup>
            {devices.map(
              ({
                status,
                battery,
                mode,
                storeId,
                deviceId,
                nameDevice,
                userName,
                num_tag,
              }) => (
                <StyledDeviceInfoWrapper key={deviceId}>
                  <DeviceInfoCheckbox
                    value={deviceId}
                    status={status}
                    deviceName={nameDevice || deviceId}
                    userName={userName}
                    num_tag={num_tag}
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleChecked />}
                    onChange={(): void =>
                      onDeviceChange({
                        status,
                        battery,
                        deviceId,
                        userName,
                        nameDevice,
                        mode,
                        num_tag,
                        storeId,
                      })
                    }
                    $v2
                  />
                </StyledDeviceInfoWrapper>
              )
            )}
          </RadioGroup>
          <UIBox alignItems="center" justifyContent="center" marginTop="32px">
            <UIButton
              label={t('select')}
              onClick={onDeviceSelect}
              disabled={!device}
            />
          </UIBox>
        </StyledFormControl>
      </StyledWrapper>
    </UIModal>
  );
};

export default ModalSelectDevices;
