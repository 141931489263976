import React from 'react';
import styled, { CSSProperties } from 'styled-components';

import { useTranslation } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import Select, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { UIBox } from '../Box';
import { Typography } from '@/components/ui/Typography';

import { palette } from '@/theme';
import { InputLabel } from '@material-ui/core';

export interface UISelectProps extends SelectProps {
  title?: string;
  values: { label: string; value?: string; disabled?: boolean }[];
}

interface StyledSelectWrapperProps {
  wrapperwidth?: string;
  selectwidth?: string;
  aligned?: string;
  inputLabel?: string;
  marg?: CSSProperties['marginTop'];
  className?: string;
  exportValue?: React.Dispatch<React.SetStateAction<string>>;
  description?: JSX.Element;
}

//#region - Styled Components
const StyledSelectWrapper = styled(UIBox)<{
  wrapperwidth?: string;
  aligned?: string;
  marg?: CSSProperties['marginTop'];
}>`
  flex-direction: column;
  align-items: ${({ aligned }): string => (aligned ? aligned : 'initial')};
  width: ${({ wrapperwidth }): string =>
    wrapperwidth ? wrapperwidth : '100%'};
  margin: ${({ marg }): CSSProperties['marginTop'] =>
    marg ? marg : '0 auto 47px'};

  & > h1 {
    max-width: 280px;
    text-align: center;
  }
`;

const StyledFromControl = styled(FormControl)<{
  marg?: CSSProperties['marginTop'];
  selectwidth?: string;
}>`
  && {
    width: ${({ selectwidth }): string =>
      selectwidth ? selectwidth : '65.2%'};
    margin-top: ${({ marg }): CSSProperties['marginTop'] =>
      marg ? marg : '21px'};
  }

  &:focus {
    background-color: white;
  }
`;

const StyledSelect = styled(Select)`
  && {
    margin-top: 0;
    border-radius: 4px;
    position: relative;
    background-color: ${palette.colors.white};
    font-size: 16px;

    fieldset {
      border: 1px solid ${palette.colors.grey};
    }

    &:hover {
      fieldset {
        border: 1px solid ${palette.colors.grey};
      }
    }
  }
`;

const StyledInputLabel = styled(InputLabel)`
  left: 14px;
  top: -8px;
  z-index: 1;
  padding: 4px;
  background: ${palette.colors.white};
`;
//#endregion

export const UISelect = React.forwardRef<
  HTMLInputElement,
  UISelectProps & StyledSelectWrapperProps
>(
  (
    {
      title,
      values,
      inputLabel,
      wrapperwidth,
      selectwidth,
      marg,
      aligned,
      exportValue,
      className,
      description,
      ...selectProps
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <StyledSelectWrapper
        className={className}
        marg={marg}
        wrapperwidth={wrapperwidth}
        aligned={aligned}
      >
        {title && (
          <Typography as="h1" font="heavy">
            {t(`${title}`)}
          </Typography>
        )}
        {description}
        <StyledFromControl selectwidth={selectwidth} marg={marg}>
          {inputLabel !== '' && (
            <StyledInputLabel
              htmlFor={inputLabel?.toLowerCase().replace(' ', '-')}
              id={inputLabel?.toLowerCase().replace(' ', '-')}
            >
              {inputLabel}
            </StyledInputLabel>
          )}
          <StyledSelect
            ref={ref}
            labelId={inputLabel?.toLowerCase().replace(' ', '-')}
            inputProps={{
              id:
                inputLabel !== ''
                  ? inputLabel?.toLowerCase().replace(' ', '-')
                  : undefined,
            }}
            onChange={(event): void => {
              if (event && event.target && exportValue) {
                exportValue(event.target.value as string);
              }
            }}
            {...selectProps}
          >
            {values.map(({ value, label, disabled }, i) => (
              <MenuItem
                key={`${value}-${i}-value`}
                value={value || label}
                disabled={disabled}
              >
                {label || value}
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledFromControl>
      </StyledSelectWrapper>
    );
  }
);

UISelect.displayName = 'UISelect';

export default UISelect;
