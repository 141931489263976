import { UIBox } from '@/components/ui/Box';
import styled from 'styled-components';

export const HeaderList = styled(UIBox)`
  width: 100%;
  padding: 8px 16px;
  border-bottom: 1px solid black;
`;

export const List = styled(UIBox)`
  flex-direction: column;

  & > * {
    &:first-child {
      padding: 0;
    }
  }
`;
