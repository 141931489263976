import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { UIAction } from '@/components/ui/Action';
import { ReactComponent as Logo } from '@/assets/images/logo_luxottica.svg';
import { theme } from '@/theme';

export const StyledLogo = styled(Logo)`
  display: none;

  @media print {
    display: block;
    margin-bottom: 32px;
  }
`;

export const StyledTitle = styled(UIBox)`
  @media print {
    &&& {
      order: 1;
      align-self: start;
      margin-left: 0;
      font-size: 20px;
    }
  }
`;

export const StyledInventoryInfo = styled(UIBox)`
  flex-direction: column;

  @media print {
    &&& {
      margin: 16px 0 12px 0;
      align-self: start;
      order: 2;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      & > * {
        margin: 0;

        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
`;

export const StyledCountedItemsWrapper = styled(UIBox)`
  align-items: center;
  justify-content: center;

  @media print {
    &&& {
      align-self: start;
      margin-top: -16px;
      margin-bottom: 16px;
    }
  }
`;

export const StyledCountedItems = styled(UIBox)`
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: 0;

  @media print {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 12px;

    &&& > * {
      margin-left: 0;
      margin-bottom: 16px;
      margin-right: 8px;
      text-align: left;

      & > span {
        text-align: left;
        line-height: 1.2;

        &:last-child {
          align-self: start;
          margin-left: 0;
          margin-top: 2px;
        }
      }
    }
  }
`;

export const StyledOperators = styled(UIBox)`
  flex-direction: column;
  max-width: 300px;

  @media print {
    &&& {
      flex-direction: row;
    }
  }
`;

export const StyledHeader = styled(UIBox)`
  position: relative;
  justify-content: space-between;

  @media print {
    &&& {
      flex-direction: column;
    }
  }
`;

export const StyledInventoryItemWrapper = styled(UIBox)`
  max-width: 100%;
  height: 100%;
  background: #fbfbfb;
  border-radius: 4px;
  border: 1px solid #005192;
  display: flex;
  align-items: center;
  position: relative;

  & > * {
    &:not(:last-child) {
      border-right: 1px solid #ededed;
    }
  }
`;

export const StyledInventoryItem = styled(UIBox)`
  width: 100%;
  padding: 16px;
  background: #fbfbfb;
  text-align: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > * {
    &:first-child {
      margin-bottom: 6px;
      max-width: 83%;
    }
  }
`;

export const StyledListInventory = styled(UIBox)`
  margin-bottom: 100px;
  margin: 0 auto;

  ${theme.breakpoints.down(800)} {
    flex-direction: column;
  }

  @media print {
    &&& {
      align-self: start;
      margin: 0;
      flex-direction: column;

      & > * {
        margin-bottom: 24px;

        &:first-child,
        &:last-child {
          align-self: start;
          margin-left: 0;
        }

        // Sold Items
        &:first-child {
          order: 2;
        }

        // Other Items
        &:last-child {
          order: 1;
          flex-wrap: wrap;
        }
      }
    }
  }
`;

export const StyledRfidWrapper = styled(UIBox)`
  flex-direction: column;
  align-items: flex-start;

  ${theme.breakpoints.down(800)} {
    margin-left: 0;
    order: 2;
  }
`;

export const StyledSoldWrapper = styled(UIBox)`
  flex-direction: column;
  margin-left: 48px;

  ${theme.breakpoints.down(800)} {
    width: fit-content;
    margin-left: 0;
    order: 1;
    margin-bottom: 24px;
  }
`;

export const StyledAction = styled(UIAction)`
  @media print {
    &&& {
      display: none;
    }
  }
`;
