import { DateTime } from 'luxon';

export const curDate = (format: string): string =>
  DateTime.now().toFormat(format);

export const convertDateToDateTime = (date: Date): DateTime =>
  DateTime.local(date?.getFullYear(), date?.getMonth()! + 1, date?.getDate());

export const daysBefore = (days: number, format: string): string =>
  DateTime.now().minus({ days }).toFormat(format);

export const daysAfter = (
  days: number,
  format: string,
  start: DateTime = DateTime.now()
): string => {
  return start.plus({ days }).toFormat(format);
};
