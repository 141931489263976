import React from 'react';

import { UIList } from '@/components/ui/List';
import { RecallBrandListItem } from '@/components/layout/RecallList/RecallBrandList';
import SubHeader from '@/components/ui/List/ListSubHeader';

import type { RecallBrand } from '@/api/receive';

type RecallBrandListProps = {
  isMount: boolean;
};

const RecallBrandList: React.FC<RecallBrandListProps & RecallBrand> = ({
  isMount,
  brandName,
  upcs,
}) => {
  return (
    <UIList
      bordered
      shadowed
      backgrounded
      subheader={<SubHeader title={brandName} />}
    >
      {upcs.map(data => (
        <RecallBrandListItem {...data} key={data.upcCode} isMount={isMount} />
      ))}
    </UIList>
  );
};

export default RecallBrandList;
