/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserSettings } from '../models/UserSettings';
import type { UserSettingsConfiguration } from '../models/UserSettingsConfiguration';
import { request as __request } from '../core/request';

export class UserSettingsService {
  /**
   * The following service set user settings
   * create jwt token
   * @returns any User settings updated.
   * @throws ApiError
   */
  public static async usersettingsSetUserSettings({
    requestBody,
  }: {
    requestBody: UserSettings;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/usersettings/setUserSettings`,
      body: requestBody,
      errors: {
        400: `Bad request, UserSettings is mandatory.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * User Settings
   * UserSettings
   * @returns UserSettingsConfiguration OK
   * @throws ApiError
   */
  public static async usersettingsGetUserSettings({
    idUser,
  }: {
    /** filter for idUser **/
    idUser: any;
  }): Promise<UserSettingsConfiguration> {
    const result = await __request({
      method: 'GET',
      path: `/usersettings/getUserSettings`,
      query: {
        idUser: idUser,
      },
      errors: {
        400: `Bad request, idUser is mandatory.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
