import { FC, useEffect, useState } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';

import { useTranslation } from 'react-i18next';

import {
  addCycleCountFilteredByModel,
  getBrandsByStore,
  initCycleCountState,
  setCycleCountFilteredByModel,
  setFilterSelected,
} from '@/features/cycleCount/cycleCountSlice';

import TabCycleCount from '@/components/CycleCount/TabCycleCount/TabCycleCount';
import { CycleCountService, ElasticHandlerRestService } from '@/api/receive';
import GenericFilter from '@/components/CycleCount/GenericFilter/GenericFilter';
import { StyledPageContainer, StyledTitle } from './style';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { useHistory } from 'react-router';
import { AppRoutes } from '@/app/routers';
import { useAsync, useMount, useUpdateEffect } from 'react-use';
import { Iupc } from '@/components/CycleCount/UpcLI/types';

import ListCCSelect from '@/components/CycleCount/ListCCSelect/ListCCSelect';
import UpcLI from '@/components/CycleCount/UpcLI/UpcLI';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';

const PageCycleCountFilterModel: FC = () => {
  const [searchedModel, setSearchedModel] = useState<string>('');
  const [modelList, setModelList] = useState<Array<string>>([]);
  const [requestBodyModelList, setRequestBodyModelList] = useState<
    Array<string>
  >([]);
  const [checkedElements, setCheckedElements] = useState<{
    [k: string]: boolean;
  }>({});
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [latestModel, setLatestModel] = useState<string>('');

  const filterNr = 3;

  const history = useHistory();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useMount(() => {
    dispatch(setFilterSelected(filterNr));
  });

  const { filterByModel, selectedModel } = useSelector(
    state => state?.cycleCount
  );
  const { store } = useSelector(state => state.currentStore);

  const debounceFn = async (): Promise<void> => {
    try {
      const { filterValueList: modelList } =
        await ElasticHandlerRestService.elastichandlerSearchFromUpcByFilterAutocomplete(
          {
            filterType: 'modelCode',
            filterValue: searchedModel,
          }
        );

      setModelList(
        modelList?.map(({ filterValue }) => filterValue || '') || []
      );
    } catch (e) {
      console.error(e);
    }
  };

  const { brandIsLoading, selectedBrands } = useSelector(
    state => state.cycleCount
  );

  useEffect(() => {
    if (brandIsLoading === undefined && selectedBrands?.length === 0) {
      dispatch(getBrandsByStore());
    }
  }, [dispatch, brandIsLoading, selectedBrands?.length]);

  const curDate = new Date();

  const curY = curDate.getFullYear();
  const curM = (curDate.getMonth() + 1).toString().padStart(2, '0');
  const curD = curDate.getDate().toString().padStart(2, '0');

  useAsync(async () => {
    if (requestBodyModelList.length > 0) {
      try {
        const { productDetailsItems } =
          await CycleCountService.cyclecountSearchProductByFilter({
            requestBody: {
              date: `${curY}${curM}${curD}`,
              storeCode: store!.storeCode!,
              modelList: requestBodyModelList,
            },
          });

        if (productDetailsItems) {
          dispatch(addCycleCountFilteredByModel(productDetailsItems));
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [requestBodyModelList.length]);

  useUpdateEffect(() => {
    if (
      filterByModel &&
      !filterByModel.some(({ modelCode }) => modelCode === latestModel)
    ) {
      setOpenSnackbar(true);
    }
  }, [filterByModel]);

  const onAutoCompleteOptionSelect = async (model: string): Promise<void> => {
    if (model !== '') {
      if (selectedModel.length > 0) {
        const models = filterByModel?.map(({ modelCode }) => modelCode) || [];
        setRequestBodyModelList(p => [...new Set([...p, ...models, model])]);
      } else {
        setRequestBodyModelList(p => [...new Set([...p, model])]);
      }
      setLatestModel(model);
    }
  };

  const checkModelCodes = (): string[] => {
    const modelCodes = Object.entries(checkedElements);
    const checkedModelCodes: string[] = [];

    for (const [upcCode, isChecked] of modelCodes) {
      if (isChecked) {
        checkedModelCodes.push(upcCode);
      }
    }

    return checkedModelCodes;
  };

  const applyClickHandler = (): void => {
    const checkedModelCodes = checkModelCodes();
    dispatch(setCycleCountFilteredByModel(checkedModelCodes));
    history.push(AppRoutes.CYCLE_COUNT_FILTER_SUMMARY);
  };

  return (
    <StyledPageContainer>
      <StyledTitle font="heavy" size="lg">
        {t('cycleCount.filtertitle')}
      </StyledTitle>

      <TabCycleCount index={filterNr} />

      <GenericFilter
        debounceFn={debounceFn}
        list={modelList}
        onAutoCompleteOptionSelect={onAutoCompleteOptionSelect}
        setChangeElement={setSearchedModel}
        filterTitle={t('cycleCount.filter.model.label')}
        filterPlaceholder={t('cycleCount.searchByModel')}
      />

      <ListCCSelect
        data={filterByModel as Iupc[]}
        keyName="modelCode"
        checkedElements={checkedElements}
        exportCheckedElements={setCheckedElements}
        component={(el, key, isChecked): JSX.Element => (
          <UpcLI
            el={el}
            keyName={key}
            setChecked={setCheckedElements}
            forceChecked={isChecked}
          />
        )}
      />

      <CTAContainer
        type="APPLY"
        disabledMainAction={checkModelCodes().length === 0}
        onClick={(): void => applyClickHandler()}
        onBackClick={(): void => {
          dispatch(initCycleCountState());
          history.push(AppRoutes.CYCLE_COUNT);
        }}
      />

      <AlertSnackbar
        open={openSnackbar}
        message={t('cycleCount.otherStore.message', { type: 'model' })}
        setIsOpen={setOpenSnackbar}
      />
    </StyledPageContainer>
  );
};

PageCycleCountFilterModel.displayName = 'PageCycleCountFilterModel';

export default PageCycleCountFilterModel;
