/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PosFlowType {
  POS_SALE_EXCEPTION = 'POS SALE EXCEPTION',
  POS_RETURN_EXCEPTION = 'POS RETURN EXCEPTION',
  POS_VOID_SALE_EXCEPTION = 'POS VOID SALE EXCEPTION',
  POS_RETURN = 'POS RETURN',
  POS_VOID_SALE = 'POS VOID SALE',
}
