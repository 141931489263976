import styled from 'styled-components';
import { ListItem as MuiListItem, List as MuiList } from '@material-ui/core';
import { palette } from '@/theme';
import { UIBox } from '@/components/ui/Box';

export const Grid = styled(UIBox)`
  width: 100%;
  display: grid;
  align-items: ${({ alignItems }): string => alignItems || 'center'};
  grid-template-columns: 200px 400px 200px auto;

  & > * {
    &:last-child {
      justify-self: flex-end;
    }
  }
`;

export const List = styled(MuiList)<{ mb?: string }>`
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
  margin-bottom: ${({ mb }): string => mb || '110px'};

  & > * {
    &:last-child {
      width: 100%;
      justify-self: flex-end;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

export const ListItem = styled(MuiListItem)`
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  background: ${palette.colors.midGrey};
  border: 1px solid #dedede;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-direction: column;
`;
