import React from 'react';
import styled from 'styled-components';

import { UIList } from '@/components/ui/List';
import { RecallErrorListItem } from '@/components/layout/RecallList/RecallErrorList';

import type { RecallBrand } from '@/api/receive';

const StyledList = styled(UIList)`
  width: 100%;
`;

const RecallErrorList: React.FC<
  Omit<RecallBrand, 'brandName'> & { isGettingTags: boolean }
> = ({ upcs, isGettingTags }) => {
  return (
    <StyledList bordered shadowed backgrounded>
      {upcs.map(data => (
        <RecallErrorListItem
          key={data.upcCode}
          isGettingTags={isGettingTags}
          {...data}
        />
      ))}
    </StyledList>
  );
};

export default RecallErrorList;
