import { useRef, useEffect } from 'react';

const useUnload = (fn: (e: Event) => void): void => {
  const cb = useRef(fn);

  useEffect(() => {
    const onUnload = cb.current;
    const isIOS = /iPad/i.test(navigator.userAgent);

    window.addEventListener('beforeunload', e => {
      e.preventDefault();
      onUnload(e);
    });

    window.addEventListener('pagehide', e => {
      e.preventDefault();
      if (isIOS) {
        onUnload(e);
      }
    });

    return (): void => {
      window.removeEventListener('beforeunload', onUnload);
      window.removeEventListener('pagehide', onUnload);
    };
  }, [cb]);
};

export default useUnload;
