import styled from 'styled-components';

export const StyledColorDatasWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const StyledColorDataWrapper = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  color: #929496;
`;

export const StyledColorBall = styled.div<{ $color: string }>`
  border-radius: 100%;
  width: 12px;
  height: 12px;
  background: ${({ $color }): string => $color};
  margin-left: 4px;
`;
