import {
  deselectBrand,
  selectBrand,
} from '@/features/cycleCount/cycleCountSlice';
import { CustomBrand } from '@/types/cycleCount';

interface ICustomBrand {
  payload: CustomBrand;
  type: string;
}

export const manageSelectBrand = (
  brandItem: CustomBrand,
  selected: boolean
): ICustomBrand =>
  selected ? selectBrand(brandItem) : deselectBrand(brandItem);
