import React from 'react';
import { useCycleCountContext } from '@/context/cycleCount';
import { useAppDispatch } from '@/app/store';
import useTrackEpc from '../track/useTrackEpc';
import {
  addUnexpectedToFound,
  setUnexpectedProductsToUndo,
} from '@/features/cycleCount/cycleCountSlice';

import type { EpcFoundReason } from '@/components/CycleCount/ModalReason';
import { useSelector } from '@/hooks/useSelector';

type AddEpcFn = (reason?: EpcFoundReason, tracking?: boolean) => void;

const useAddUnexpectedEpcs = (): AddEpcFn => {
  const dispatch = useAppDispatch();
  const isSohProcess = useSelector(state => state.cycleCount.sohLocked);
  const {
    products: { found, unexpected },
  } = useSelector(state => state.cycleCount);
  const trackUnexpected = useTrackEpc('Add Unexpected Epc Reason');
  const {
    checkedUnexpectedUpcs,
    checkedUnexpectedEpcs,
    setCheckedUnexpectedUpcs,
    setCheckedUnexpectedEpcs,
    setCheckedUnexpectedUpcsToUndo,
    setCheckedUnexpectedEpcsToUndo,
    setUndoSnackbarVisibility,
  } = useCycleCountContext();

  const add: AddEpcFn = React.useCallback(
    (reason?: EpcFoundReason, tracking = false) => {
      /**
       * On Cycle count these operations are fired
       * when Modal Reason component is opened
       *
       * */
      if (isSohProcess) {
        setCheckedUnexpectedUpcsToUndo(checkedUnexpectedUpcs);
        setCheckedUnexpectedEpcsToUndo(checkedUnexpectedEpcs);
        dispatch(setUnexpectedProductsToUndo({ unexpected, found }));
      }

      for (const [upcCode, product] of Object.entries(checkedUnexpectedUpcs)) {
        const epcCodes = checkedUnexpectedEpcs[upcCode];

        epcCodes.forEach(epcCode => {
          dispatch(
            addUnexpectedToFound({
              unexpectedProduct: {
                upcCodeFound: upcCode,
                epcFoundQuantity: 1,
                epcFound: [
                  {
                    epcFoundCodeOld: epcCode,
                    reasonEpcFound: reason,
                  },
                ],
              },
              product,
              epc: epcCode,
            })
          );
        });

        if (tracking) {
          trackUnexpected({ upcCode, epcCodes, reasonCode: reason });
        }
      }

      setCheckedUnexpectedUpcs({});
      setCheckedUnexpectedEpcs({});
      setUndoSnackbarVisibility(true);
    },
    [
      checkedUnexpectedEpcs,
      checkedUnexpectedUpcs,
      dispatch,
      found,
      isSohProcess,
      setCheckedUnexpectedEpcs,
      setCheckedUnexpectedEpcsToUndo,
      setCheckedUnexpectedUpcs,
      setCheckedUnexpectedUpcsToUndo,
      setUndoSnackbarVisibility,
      trackUnexpected,
      unexpected,
    ]
  );

  return add;
};

export default useAddUnexpectedEpcs;
