import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { UIBox } from '@/components/ui/Box';
import { PageLoader } from '@/components/ui/PageLoader';
import { Typography } from '@/components/ui/Typography';
import { findStockItems } from '@/features/stockSummary/stockSummarySlice';
import { useSelector } from '@/hooks/useSelector';
import { palette } from '@/theme';
import { List } from '@material-ui/core';
import { FC, useState, useEffect, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import qs from 'qs';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { useUpdateEffect } from 'react-use';
import { UIButton } from '@/components/ui/Button';
import { addItem, reset } from '@/features/breadcrumbs/breadcrumbsSlice';
import { useTranslation } from 'react-i18next';
// import GetAppIcon from '@material-ui/icons/GetApp';
// import { StockSummaryService } from '@/api';
// import { ElasticHandlerRestService } from '@/api/receive';
// import UIAutocomplete from '@/components/ui/AutoComplete/AutoComplete';
// import { ProductImage } from '../../components/ui/ProductImage';
// import { Product } from '../../types/enum';
// import {
//   //StyledProductItem,
//   StyledProductContent,
//   StyledContentInfo,
//   StyledAccordion,
//   StyledDescription,
//   StyledEpcCode,
//   StyledAccordionContent,
//   StyledAccordionItemContent,
// } from '../CycleCount/listItemStyle';

import { FilterBarStockSummary } from './components/FilterBarStockSummary';

//#region - Styled Components
const StyledGridMediaQueries = css<{ $query?: boolean }>`
  ${({ theme, $query }): string =>
    `${theme.breakpoints.down('md')} {
      padding: 18px 16px;
      grid-template-columns: ${
        $query ? 'repeat(6, 1fr)' : '250px auto 100px 100px'
      };
    }`}

  ${({ theme, $query }): string =>
    `${theme.breakpoints.down('sm')} {
      padding: 18px 16px;
      grid-template-columns: ${
        $query ? 'repeat(6, 1fr)' : '100px auto 100px 100px'
      };
    }`}
`;

const StyledGridColumns = css<{ $query?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $query }): string =>
    $query ? 'repeat(6, 1fr)' : '300px auto 100px 100px'};
  gap: 48px;
`;

const StyledPageWrapper = styled('div')`
  position: relative;
  width: 100%;
`;

const StyledBrandsList = styled(List)`
  width: 100%;
  padding-bottom: 100px;
`;

const StyledListGridWrapper = styled(UIBox)`
  flex-direction: column;

  & > * {
    &:nth-child(odd) {
      background: ${palette.colors.midGrey};
    }
  }
`;

const StyledListGridHeader = styled(UIBox)<{ $query?: boolean }>`
  padding: 18px 24px;
  border-bottom: 1px solid ${palette.colors.text};

  ${StyledGridColumns}
  ${StyledGridMediaQueries}
`;

const StyledListGrid = styled(UIBox)<{ $query?: boolean }>`
  padding: 0 24px;

  ${StyledGridColumns}
  ${StyledGridMediaQueries}
`;

const StyledListGridItem = styled(UIBox)`
  padding: 16px 0;
  text-transform: capitalize;
`;

const FixedContainer = styled(UIBox)`
  ${({ theme }): string => `
		position: fixed;
		flex-wrap: wrap;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 24px;
		background: ${theme.palette.common.white};
		box-shadow: 0 -1px 21px rgba(0, 0, 0, 0.25);
		justify-content: space-between;
		${theme.breakpoints.down('md')} {
			padding: 24px 16px;
		}
	`}
`;

const FixedContainerRight = styled(UIBox)`
  ${({ theme }): string => `
		gap: 105px;
		margin: 0 auto;
		justify-content: flex-end;
		padding: 0 24px;
		width: 100%;
		max-width: ${theme.breakpoints.values.lg}px;
		.lastItem {
			display: inline-block;
			width: 60px;
		}
		${theme.breakpoints.down('md')} {
			padding: 0;

			& > h1:not(:last-child) {
				margin-right: 36px;
			}
		}
	`}
`;
//#endregion

const PageStockSummary: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [commodityFilter, setCommodityFilter] = useState<string>(
    t('stock.all')
  );
  const [brandFilter, setBrandFilter] = useState<string>('');

  const [alertSnackbarIsVisible, setAlertSnackbarVisibility] =
    useState<boolean>(false);

  const location = useLocation();

  const query: { brand?: string; upcCode?: string; commodity?: string } =
    useMemo(
      () => qs.parse(location.search, { ignoreQueryPrefix: true }),
      [location.search]
    );

  const {
    stockItems,
    totalStock: initialStock,
    totalTransit: initialTransit,
  } = useSelector(state => state.stockSummary.brands);

  const [totalStock, setTotalStock] = useState<number>(initialStock || 0);
  const [totalTransit, setTotalTransit] = useState<number>(initialTransit || 0);

  const { stockItemsIsLoading } = useSelector(state => state.stockSummary);

  const onChangeCommodityFilter = (commodity: string): void =>
    setCommodityFilter(commodity);
  const onChangeBrandFilter = (brand: string): void => setBrandFilter(brand);

  useEffect(() => {
    const { brand, upcCode } = query;
    dispatch(reset());

    if (!brand && !upcCode) {
      dispatch(findStockItems());
      dispatch(addItem({ title: t('page.stock_summary') }));
    } else if (brand || upcCode) {
      dispatch(
        addItem({
          title: t('page.stock_summary'),
          path: AppRoutes.STOCK_SUMMARY_DETAILS,
        })
      );
      dispatch(addItem({ title: t('page.stock_summary_details') }));
    }
  }, [dispatch, query, t]);

  // useEffect(() => {
  //   const { brand, upcCode, commodity } = query;

  //   if (brand && upcCode) {
  //     dispatch(findStockItems({ brand, upcCode }));
  //   } else if (brand) {
  //     dispatch(findStockItems({ brand }));
  //   } else if (upcCode) {
  //     dispatch(findStockItems({ upcCode }));
  //   }

  //   if (commodity) {
  //     setCommodityFilter(commodity);
  //   } else {
  //     setCommodityFilter(t('stock.all'));
  //   }
  //   setBrandFilter('');
  //   if (upcCode) {
  //     setUpcFilter(upcCode);
  //   }
  // }, [dispatch, query, t]);

  useUpdateEffect(() => {
    const { brand, upcCode } = query;

    if (!brand || !upcCode) {
      setAlertSnackbarVisibility(false);
    }
  }, [query]);

  useUpdateEffect(() => {
    if (stockItems?.length === 0 && !stockItemsIsLoading) {
      setAlertSnackbarVisibility(true);
    }
  }, [stockItems, stockItemsIsLoading]);

  useEffect(() => {
    setTotalStock(
      stockItems
        ?.filter(({ brand, commodity }) => {
          switch (true) {
            case commodityFilter !== t('stock.all') && brandFilter !== '':
              return commodityFilter === commodity && brandFilter === brand;

            case commodityFilter !== t('stock.all'):
              return commodityFilter === commodity;

            case brandFilter !== '':
              return brandFilter === brand;

            default:
              return true;
          }
        })
        .sort((a, b) => {
          // if (query.brand && a.upcCode && b.upcCode) {
          //   if (a.upcCode > b.upcCode) {
          //     return 1;
          //   }
          //   if (a.upcCode < b.upcCode) {
          //     return -1;
          //   }
          // }
          if (query.brand === undefined && a.brand && b.brand) {
            if (a.brand > b.brand) {
              return 1;
            }
            if (a.brand < b.brand) {
              return -1;
            }
          }
          return 0;
        })
        .map(({ stock }) => stock)
        .filter(stock => stock)
        .reduce((previousStock, currentStock) => {
          return previousStock! + currentStock!;
        }, 0) || 0
    );
  }, [brandFilter, commodityFilter, query.brand, stockItems, t]);

  useEffect(() => {
    setTotalTransit(
      stockItems
        ?.filter(({ brand, commodity }) => {
          switch (true) {
            case commodityFilter !== t('stock.all') && brandFilter !== '':
              return commodityFilter === commodity && brandFilter === brand;

            case commodityFilter !== t('stock.all'):
              return commodityFilter === commodity;

            case brandFilter !== '':
              return brandFilter === brand;

            default:
              return true;
          }
        })
        .sort((a, b) => {
          // if (query.brand && a.upcCode && b.upcCode) {
          //   if (a.upcCode > b.upcCode) {
          //     return 1;
          //   }
          //   if (a.upcCode < b.upcCode) {
          //     return -1;
          //   }
          // }
          if (query.brand === undefined && a.brand && b.brand) {
            if (a.brand > b.brand) {
              return 1;
            }
            if (a.brand < b.brand) {
              return -1;
            }
          }
          return 0;
        })
        .map(({ transit }) => transit)
        .filter(transit => transit)
        .reduce((previousTransit, currentTransit) => {
          return previousTransit! + currentTransit!;
        }, 0) || 0
    );
  }, [brandFilter, commodityFilter, query.brand, stockItems, t]);

  if (stockItemsIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <StyledPageWrapper>
        <FilterBarStockSummary
          onChangeCommodityFilter={onChangeCommodityFilter}
          onChangeBrandFilter={onChangeBrandFilter}
        />
        <StyledBrandsList>
          <StyledListGridHeader $query={!!(query.brand || query.upcCode)}>
            <Typography font="heavy">{t('stock.commodity')}</Typography>
            <Typography font="heavy">{t('stock.brand')}</Typography>
            {(query.brand || query.upcCode) && (
              <>
                <Typography font="heavy">UPC</Typography>
                <Typography font="heavy">{t('stock.details')}</Typography>
              </>
            )}
            <Typography font="heavy" style={{ textAlign: 'center' }}>
              {t('stock.in_transit')}
            </Typography>
            <Typography font="heavy" style={{ textAlign: 'center' }}>
              {t('stock.in_stock')}
            </Typography>
          </StyledListGridHeader>
          <StyledListGridWrapper>
            {stockItems
              ?.filter(({ commodity, brand }) => {
                switch (true) {
                  case commodityFilter !== t('stock.all') && brandFilter !== '':
                    return (
                      commodityFilter === commodity && brandFilter === brand
                    );

                  case commodityFilter !== t('stock.all'):
                    return commodityFilter === commodity;

                  case brandFilter !== '':
                    return brandFilter === brand;

                  default:
                    return true;
                }
              })
              .map(({ brand, commodity, stock, transit }, index) => (
                <StyledListGrid
                  key={`${brand}-${index}-item`}
                  $query={!!(query.brand || query.upcCode)}
                >
                  <StyledListGridItem>
                    <Typography font="medium">{commodity}</Typography>
                  </StyledListGridItem>
                  {!query.brand && !query.upcCode ? (
                    <StyledListGridItem>
                      <Typography font="medium">
                        <Link
                          to={`${
                            AppRoutes.STOCK_SUMMARY_DETAILS
                          }?brand=${encodeURIComponent(brand!)}&commodity=${
                            commodity || ''
                          }`}
                        >
                          {brand}
                        </Link>
                      </Typography>
                    </StyledListGridItem>
                  ) : (
                    <>
                      <StyledListGridItem>
                        <Typography font="medium">{brand}</Typography>
                      </StyledListGridItem>
                      {/* <StyledListGridItem>
                        <Typography font="medium">{upcCode}</Typography>
                      </StyledListGridItem> */}
                      {/* <StyledListGridItem>
                          <Typography font="medium">{details}</Typography>
                        </StyledListGridItem> */}
                    </>
                  )}
                  <StyledListGridItem style={{ justifySelf: 'center' }}>
                    <Typography font="medium">{transit}</Typography>
                  </StyledListGridItem>
                  <StyledListGridItem style={{ justifySelf: 'center' }}>
                    <Typography font="medium">{stock}</Typography>
                  </StyledListGridItem>
                </StyledListGrid>
              ))}
          </StyledListGridWrapper>
        </StyledBrandsList>
        <FixedContainer>
          <FixedContainerRight>
            {(query.brand || query.upcCode) && (
              <UIButton
                onClick={(): void => {
                  history.goBack();
                }}
                label={'back'}
                outlined={true}
                disabled={false}
                style={{ marginRight: 'auto' }}
              />
            )}
            <Typography as="h1" font="medium">
              {t('stock.total_transit')}{' '}
              <Typography font="heavy">{totalTransit}</Typography>
            </Typography>
            <Typography as="h1" font="medium">
              {t('stock.total_stock')}{' '}
              <Typography className="lastItem" font="heavy">
                {totalStock}
              </Typography>
            </Typography>
          </FixedContainerRight>
        </FixedContainer>
      </StyledPageWrapper>

      <AlertSnackbar
        open={alertSnackbarIsVisible}
        setIsOpen={setAlertSnackbarVisibility}
        message={t('stock.no_upc')}
      />
    </>
  );
};

export default PageStockSummary;
