import React, { FC, useEffect, useState } from 'react';
import { useSelector } from '@/hooks/useSelector';
import styled from 'styled-components';
import { useAppDispatch } from '@/app/store';

import { HighlightOff } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { IconButton } from '@material-ui/core';

import { UIModal } from '@/components/ui/Modal';
import { Typography } from '@/components/ui/Typography';
import { ModalReasonType } from '@/types/enum';
import { UISelect } from '@/components/ui/Select';
import { UIBox } from '@/components/ui/Box';
import { UIButton } from '@/components/ui/Button';
import { palette } from '@/theme';
import {
  addNotFoundReason,
  setNotFoundProductsToUndo,
  setUnexpectedProductsToUndo,
} from '@/features/cycleCount/cycleCountSlice';
import { ConfirmCycleCount } from '@/api';

import { useTranslation } from 'react-i18next';
import { useCycleCountContext } from '@/context/cycleCount';
import useTrackEpc from '@/pages/CycleCount/track/useTrackEpc';
import useAddUnexpectedEpcs from '@/pages/CycleCount/hooks/useAddUnexpectedEpcs';
//#region types
interface ModalReasonProps {
  type: keyof typeof ModalReasonType;
  open: boolean;
  onClose: () => void;
}

export type EpcFoundReason = NonNullable<
  ConfirmCycleCount['epcFoundCycleCountList']
>[number]['epcFound'][number]['reasonEpcFound'];

export type EpcNotFoundReason = NonNullable<
  ConfirmCycleCount['epcNotFoundCycleCountList']
>[number]['epcNotFound'][number]['reasonEpcNotFound'];
//#endregion

//#region styled-components
const StyledModalWrapper = styled(UIBox)`
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 1em;
`;

const StyledSelectWrapper = styled(UIBox)`
  position: relative;
  width: 100%;
`;

const StyledErrorIcon = styled(ErrorOutlineIcon)<{
  $modalType: 'UNEXPECTED' | 'NOTFOUND';
}>`
  color: ${({ $modalType }): string =>
    $modalType === 'UNEXPECTED'
      ? `${palette.colors.yellow}`
      : `${palette.colors.red}`};
`;

const StyledInstruction = styled(Typography)`
  margin: -31px 0 16px;
`;

const StyledClose = styled(IconButton)`
  height: 1rem;
  width: 1rem;
  position: absolute;
  top: 1rem;
  right: 5%;
  z-index: 9999;
`;
//#endregion

const REASONCODES_LIMIT = 100;

export const ModalReason: FC<ModalReasonProps> = ({ type, open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    setNotFoundEpcsWithReason,
    checkedNotFoundUpcs,
    checkedNotFoundEpcs,
    checkedUnexpectedUpcs,
    checkedUnexpectedEpcs,
    setCheckedNotFoundEpcs,
    setCheckedNotFoundEpcsToUndo,
    setCheckedNotFoundUpcs,
    setCheckedNotFoundUpcsToUndo,
    setCheckedUnexpectedEpcsToUndo,
    setCheckedUnexpectedUpcsToUndo,
    setUndoSnackbarVisibility,
  } = useCycleCountContext();
  const [reasonEpcFound, setReasonEpcFound] = useState<EpcFoundReason>();
  const [reasonEpcNotFound, setReasonEpcNotFound] =
    useState<EpcNotFoundReason>();

  const { unexpected, found } = useSelector(state => state.cycleCount.products);
  const { notFoundWithReason } = useSelector(state => state.cycleCount);

  const [countMarkedReason, setMarkedReason] = useState<{
    [key: string]: number;
  }>({});

  const [isAddingUnexpected, setAddUnexpectedLoading] =
    useState<boolean>(false);

  const addUnexpectedToFound = useAddUnexpectedEpcs();
  const trackNotFound = useTrackEpc('Add Not Found Epc Reason');

  const selectedNotFoundCount = Object.values(checkedNotFoundEpcs).flatMap(
    e => e
  ).length;

  const isSohProcess = useSelector(state => state.cycleCount.sohLocked);

  useEffect(() => {
    if (!isSohProcess && open) {
      const settedReason = notFoundWithReason.flatMap(({ epcNotFound }) =>
        epcNotFound.map(({ reasonEpcNotFound }) => reasonEpcNotFound)
      );
      const mapCountReason = settedReason.reduce(
        (acc: { [key: string]: number }, reason) => {
          if (reason === undefined || reason === 'CheckOut Error') return acc;
          if (!Object.keys(acc).includes(reason)) acc[reason] = 1;
          else acc[reason] = acc[reason] + 1;
          return acc;
        },
        {
          'Mistag - Remove Qty': 0,
          'Theft - Remove Qty': 0,
          'Cycle Count - Remove Qty': 0,
        }
      );
      setMarkedReason(mapCountReason);
    }
  }, [notFoundWithReason, open, isSohProcess]);

  useEffect(() => {
    if (open) {
      setCheckedNotFoundUpcsToUndo(checkedNotFoundUpcs);
      setCheckedNotFoundEpcsToUndo(checkedNotFoundEpcs);
      setCheckedUnexpectedUpcsToUndo(checkedUnexpectedUpcs);
      setCheckedUnexpectedEpcsToUndo(checkedUnexpectedEpcs);
      dispatch(setNotFoundProductsToUndo(notFoundWithReason));
      dispatch(setUnexpectedProductsToUndo({ unexpected, found }));
    }
  }, [
    checkedNotFoundEpcs,
    checkedNotFoundUpcs,
    checkedUnexpectedEpcs,
    checkedUnexpectedUpcs,
    dispatch,
    found,
    notFoundWithReason,
    open,
    setCheckedNotFoundEpcsToUndo,
    setCheckedNotFoundUpcsToUndo,
    setCheckedUnexpectedEpcsToUndo,
    setCheckedUnexpectedUpcsToUndo,
    type,
    unexpected,
  ]);

  const handleClose = (): void => {
    setReasonEpcFound(undefined);
    setReasonEpcNotFound(undefined);
    onClose();
  };

  const onUnexpectedButtonClick = async (): Promise<void> => {
    setAddUnexpectedLoading(true);
    addUnexpectedToFound(reasonEpcFound, true);
    handleClose();
    setAddUnexpectedLoading(false);
  };

  const onNotFoundButtonClick = (): void => {
    for (const [upcCode, product] of Object.entries(checkedNotFoundUpcs)) {
      const epcCodes = checkedNotFoundEpcs[upcCode];

      epcCodes.forEach(epcCode => {
        dispatch(
          addNotFoundReason({
            notFoundProduct: {
              upcCodeNotFound: upcCode,
              epcNotFoundQuantity: 1,
              epcNotFound: [
                {
                  epcNotFoundCode: epcCode,
                  reasonEpcNotFound,
                },
              ],
            },
            product,
            epc: epcCode,
          })
        );
      });

      setNotFoundEpcsWithReason?.(prevState => [...prevState, ...epcCodes]);
      trackNotFound({ upcCode, epcCodes, reasonCode: reasonEpcNotFound });
    }

    setUndoSnackbarVisibility(true);
    setCheckedNotFoundUpcs({});
    setCheckedNotFoundEpcs({});
    handleClose();
  };

  if (type === 'UNEXPECTED') {
    return (
      <UIModal
        $minWidth="400px"
        $minHeight="250px"
        open={open}
        onClose={handleClose}
      >
        <StyledModalWrapper>
          <StyledClose onClick={handleClose}>
            <HighlightOff color={'primary'} />
          </StyledClose>
          <StyledSelectWrapper>
            <StyledErrorIcon fontSize="large" $modalType={type} />
            <UISelect
              title={t('modal.reason.title-unexpected')}
              aligned="center"
              variant="outlined"
              values={[
                {
                  label: t('modal.reason.unexpected.mistag'),
                  value: 'Mistag - Add Qty',
                },
                {
                  label: t('modal.reason.unexpected.theft'),
                  value: 'Theft RTS - Add Qty',
                },
                {
                  label: t('modal.reason.unexpected.cycle-count'),
                  value: 'Cycle Count - Add Qty',
                },
              ]}
              onChange={(e: React.ChangeEvent<{ value: unknown }>): void =>
                setReasonEpcFound(e.target.value as EpcFoundReason)
              }
            />
          </StyledSelectWrapper>
          <StyledInstruction size="sm">
            {t('modal.reason.instruction')}
          </StyledInstruction>
          <UIButton
            label="OK"
            disabled={!reasonEpcFound || isAddingUnexpected}
            loading={isAddingUnexpected}
            onClick={onUnexpectedButtonClick}
          />
        </StyledModalWrapper>
      </UIModal>
    );
  }

  return (
    <UIModal
      $minWidth="400px"
      $minHeight="250px"
      open={open}
      onClose={handleClose}
    >
      <StyledModalWrapper>
        <StyledClose onClick={handleClose}>
          <HighlightOff color={'primary'} />
        </StyledClose>
        <UIBox alignItems="center" gridGap="10px">
          <StyledErrorIcon fontSize="large" $modalType={type} />
          <Typography as="h1" font="heavy">
            {t('modal.reason.title-notFound')}
          </Typography>
        </UIBox>
        {Object.values(countMarkedReason).reduce((a, b) => a + b, 0) +
          selectedNotFoundCount <=
        REASONCODES_LIMIT ? (
          <>
            <UIBox
              marginTop="20px"
              alignItems="center"
              flexDirection="column"
              gridGap="10px"
            >
              <Typography size="sm">
                {t('modal.reason.rememberlimit', {
                  limit: REASONCODES_LIMIT,
                })}
              </Typography>
              <Typography size="sm">
                {t('modal.reason.exceededlimit.2')}
              </Typography>
              <ul>
                <li>{t('modal.reason.notFound.mistag')}</li>
                <li>{t('modal.reason.notFound.theft')}</li>
                <li>{t('modal.reason.notFound.cycle-count')}</li>
              </ul>
            </UIBox>
          </>
        ) : (
          <></>
        )}
        <StyledSelectWrapper>
          <UISelect
            aligned="center"
            variant="outlined"
            values={[
              {
                label: `${t('modal.reason.notFound.mistag')} (${
                  countMarkedReason['Mistag - Remove Qty']
                })`,
                value: 'Mistag - Remove Qty',
                disabled:
                  Object.values(countMarkedReason).reduce((a, b) => a + b, 0) +
                    selectedNotFoundCount >
                  REASONCODES_LIMIT,
              },
              {
                label: `${t('modal.reason.notFound.theft')} (${
                  countMarkedReason['Theft - Remove Qty']
                })`,
                value: 'Theft - Remove Qty',
                disabled:
                  Object.values(countMarkedReason).reduce((a, b) => a + b, 0) +
                    selectedNotFoundCount >
                  REASONCODES_LIMIT,
              },
              {
                label: `${t('modal.reason.notFound.cycle-count')} (${
                  countMarkedReason['Cycle Count - Remove Qty']
                })`,
                value: 'Cycle Count - Remove Qty',
                disabled:
                  Object.values(countMarkedReason).reduce((a, b) => a + b, 0) +
                    selectedNotFoundCount >
                  REASONCODES_LIMIT,
              },
              {
                label: t('modal.reason.notFound.checkout'),
                value: 'CheckOut Error',
              },
            ]}
            onChange={(e: React.ChangeEvent<{ value: unknown }>): void =>
              setReasonEpcNotFound(e.target.value as EpcNotFoundReason)
            }
            marg="20px auto 20px"
          />
        </StyledSelectWrapper>
        {Object.values(countMarkedReason).reduce((a, b) => a + b, 0) +
          selectedNotFoundCount >
          REASONCODES_LIMIT && (
          <UIBox alignItems="center" flexDirection="column" gridGap="10px">
            <Typography size="sm">
              {t('modal.reason.exceededlimit.1', {
                limit: REASONCODES_LIMIT,
              })}
            </Typography>
            <Typography size="sm">
              {t('modal.reason.exceededlimit.2')}
            </Typography>
            <ul>
              {Object.entries(countMarkedReason).map(([key, _]) => (
                <li>
                  {key} ({countMarkedReason[key]})
                </li>
              ))}
            </ul>
          </UIBox>
        )}
        <UIButton
          label="OK"
          disabled={!reasonEpcNotFound}
          onClick={onNotFoundButtonClick}
        />
      </StyledModalWrapper>
    </UIModal>
  );
};

export default ModalReason;
