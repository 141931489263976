import React from 'react';
import { useAppDispatch } from '@/app/store';
import {
  confirmSoldItems,
  resetManagedCheckedItems,
  resetNotManagedCheckedItems,
  setDataSaved,
} from '@/features/pos/posSlice';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';

type ReturnType = {
  execute: () => Promise<void>;
  loading: boolean;
  error: boolean;
  errorCode: number;
  errorMessage: string;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  success: boolean;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
};

const useConfirmSoldItems = (): ReturnType => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [error, setError] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);

  const loading = useSelector(state => state.pos.confirmIsLoading || false);

  const errorCode = useSelector(state =>
    error ? state.pos.confirmHasError?.status : undefined
  );

  const errorMessage = useSelector(state =>
    error
      ? state.pos.confirmHasError?.body?.errorMessage ||
        state.pos.confirmHasError?.body ||
        t('error.confirmSoldItems')
      : undefined
  );

  const confirm = React.useCallback(async () => {
    try {
      await dispatch(confirmSoldItems()).unwrap();
      dispatch(resetManagedCheckedItems());
      dispatch(resetNotManagedCheckedItems());
      dispatch(setDataSaved(true));
    } catch {
      setError(true);
    }
  }, [dispatch]);

  return {
    execute: confirm,
    error,
    errorCode,
    errorMessage,
    setError,
    success,
    setSuccess,
    loading,
  };
};

export default useConfirmSoldItems;
