import { Printer } from '@/types/store';

export const isRFIDStore = (
  printers: Array<Printer> | null | undefined
): boolean => {
  if (!Array.isArray(printers)) return false;

  const noRfid = printers.filter((printer: Printer) => printer.isRfid !== true);

  if (noRfid.length > 0) {
    return false;
  }

  return true;
};

export const encodeRequest = <T>(b: T): string => {
  return btoa(JSON.stringify(b));
};
