import { ElasticHandlerRestService } from '@/api/receive';
import styled from 'styled-components';
import { Search as SearchIcon } from '@material-ui/icons';
import UIAutocomplete from '@/components/ui/AutoComplete/AutoComplete';
import { UIButton } from '@/components/ui/Button';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/app/store';
import {
  findUpc,
  clearProductDetails,
  resetUpc,
  printLabel,
} from '@/features/print/printSlice';
import { ModalPrintReturnsBackup } from '@/components/layout/ModalPrintReturnsBackup';
// import { ModalPrintNoRfid } from '@/components/layout/ModalPrintNoRfid';
import { fetchDamageReasons } from '@/features/storeDamages/storeDamagesSlice';
import { useAsync, useUnmount } from 'react-use';
// import { PrintMode } from '@/types/enum';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { usePrintContext } from '@/context/Print';
import {
  confirmBadCondtionItems,
  confirmGoodCondtionItems,
} from '@/features/storeDamages/storeDamagesSlice';
import { useHistory } from 'react-router';
import { AppRoutes } from '@/app/routers';
import ModalPrintTemp from '@/components/layout/ModalPrintTemp';
import { PrintReasonCodeStatus } from '@/types/enum';
import { handlePrintLayout } from '@/utils/handlePrintLayout';

//#region  styled-component
const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  padding: 34px 0 0;
`;

const SearchButton = styled(UIButton)`
  && {
    width: 150px;
    padding: 26px 0;
  }
`;
//#endregion

const PageReturnsBackup: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const autocompleteInputRef = useRef<HTMLInputElement>(null);

  const { print, setPrint } = usePrintContext();
  const { store } = useSelector(state => state.currentStore);
  const {
    isLoadingProductDetails,
    productDetails,
    lastPrintsError,
    printError,
  } = useSelector(state => state.print);

  const [upcs, setUpcs] = useState<string[]>([]);
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const [isPrintErrorVisible, setIsPrintErrorVisible] =
    useState<boolean>(false);
  const [noPrinterAvailable, setNoPrinterAvailable] = useState<boolean>(false);

  const [upcCode, setUpcCode] = useState<string>('');
  const [isUpcCodeValid, setUpcCodeValid] = useState<boolean>(false);

  const [isProductDetailsModalVisible, setProductDetailsModalVibility] =
    useState<boolean>(false);
  const [isPrintModalVisible, setPrintModalVisibility] =
    useState<boolean>(false);

  // const [printedEpc, setPrintedEpc] = useState<string | undefined>();
  // const [openDeviceScan, setOpenDeviceScan] = useState<boolean>(false);
  // const [tagVisibility, setTagVisibility] = useState<boolean>(false);

  const [damagesProduct, setDamagesProduct] = useState<boolean>(false);

  useAsync(async () => {
    await dispatch(fetchDamageReasons());
  }, [dispatch]);

  useUnmount(() => {
    dispatch(resetUpc());
  });

  const debounceFn = async (): Promise<void> => {
    if (upcCode.length >= 4) {
      try {
        const { filterValueList: upcList } =
          await ElasticHandlerRestService.elastichandlerSearchFromUpcByFilterAutocomplete(
            { filterValue: upcCode, filterType: 'upcCode' }
          );

        if (upcList) {
          if (upcList?.length > 0) {
            const list = upcList.map(({ filterValue }) => filterValue);

            return setUpcs(list);
          }
        }

        return setUpcs([]);
      } catch (e) {
        console.log('error', e);
      }
    }

    setUpcs([]);
  };

  const searchInputHandler: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    setUpcCode(value);
  };

  useEffect(() => {
    if (upcCode.length > 0) {
      setUpcCodeValid(/\d{4,13}/.test(upcCode));
    } else {
      setUpcCodeValid(true);
    }
  }, [upcCode]);

  useEffect(() => {
    if (productDetails) {
      setProductDetailsModalVibility(true);
    }
  }, [productDetails]);

  useEffect(() => {
    if (store) {
      const { printers } = store;

      if (!printers || printers?.length === 0) {
        setNoPrinterAvailable(true);
      }
    }
  }, [store]);

  useEffect(() => {
    if (lastPrintsError) {
      setIsErrorVisible(true);
    }
  }, [lastPrintsError]);

  useEffect(() => {
    if (store) {
      const { printers } = store;

      if (!printers || printers?.length === 0) {
        setIsPrintErrorVisible(true);
      }
    }
  }, [store]);

  useEffect(() => {
    setIsPrintErrorVisible(printError !== undefined);
  }, [printError]);

  useEffect(() => {
    if (upcCode.length === 0) {
      setUpcs([]);
    }
  }, [upcCode]);

  const searchButtonHandler: React.MouseEventHandler = async () => {
    autocompleteInputRef.current?.focus();
    onAutoCompleteOptionSelect(upcCode);
  };

  const handlePrintModalClose = (): void => {
    setPrintModalVisibility(false);

    dispatch(clearProductDetails());
  };

  const onAutoCompleteOptionSelect = (upcCode: string): void => {
    dispatch(
      findUpc({
        upcCode,
        storeCode: store?.storeCode!,
      })
    );
    setUpcCode(upcCode);
  };

  const onAutoCompleteFocus = (): void => {
    if (upcCode.length === 0) {
      setUpcs([]);
    }
  };

  const showPrintError = (visible: boolean, message?: string): void => {
    setPrint(prevState => ({
      ...prevState,
      error: {
        visible,
        message,
      },
    }));
  };

  const handleConfirmCondition = async (epcCode: string): Promise<void> => {
    const damagesDetails = print.reason;

    const requestBody = {
      storeCode: store?.storeCode || '',
      upcCodes: [
        {
          upcCode: upcCode,
          epcs: [
            {
              epcCode,
              movementReasonCode: damagesDetails?.movementReasonCode,
              movementTypeCode: damagesDetails?.movementTypeCode,
            },
          ],
        },
      ],
    };

    try {
      !damagesProduct
        ? await dispatch(confirmBadCondtionItems(requestBody)).unwrap()
        : await dispatch(confirmGoodCondtionItems(requestBody)).unwrap();

      history.push(AppRoutes.PDF_DOWNLOAD_RETURN_DAMAGE);
    } catch {
      return;
    }
  };

  const onPrintModalClick = async (): Promise<void> => {
    if (print.error.message) {
      showPrintError(false);
    }

    if (store && store.storeCode && productDetails) {
      const reasonCode = print.reason
        .movementReasonCode as PrintReasonCodeStatus;

      try {
        const response = await dispatch(
          printLabel({
            storeCode: store?.storeCode!,
            layout: handlePrintLayout(
              productDetails.currency || '',
              print.printer?.isRfid || false
            ),
            serialNumberPrinter: print.printer?.printerCode || '',
            upcCode,
            reasonCode,
          })
        ).unwrap();

        if (print.printer?.isRfid) {
          handleConfirmCondition(response.epc || '');
        } else {
          setPrint(prevState => ({
            ...prevState,
            data: {
              printedEPC: response.epc,
            },
          }));
        }
      } catch {
        showPrintError(true, t('error.something_went_wrong'));
      }
    }
  };

  const onStartUseReaderClick = async (): Promise<void> => {
    if (print && !print.printer?.isRfid) {
      if (productDetails) {
        handleConfirmCondition(print.data.printedEPC || '');
      }
    }
  };

  return (
    <>
      <AlertSnackbar
        open={noPrinterAvailable}
        setIsOpen={setNoPrinterAvailable}
        message={t('error.noPrinterAvailable')}
      />

      <ErrorSnackbar
        open={isErrorVisible}
        setIsOpen={setIsErrorVisible}
        errorMessage={
          lastPrintsError?.status === 400
            ? t('error.upcNotFound')
            : `${t('error.print_new')}: ${lastPrintsError?.message}`
        }
      />
      <ErrorSnackbar
        open={isPrintErrorVisible}
        setIsOpen={setIsPrintErrorVisible}
        errorMessage={
          `${t('error.print_new')}: ${
            printError?.body
              ? printError.body.message
              : t('error.printGenericError')
          }` || ''
        }
      />
      <SearchContainer>
        <UIAutocomplete
          rounded
          width={350}
          minSearchTermLength={4}
          label={t('searchPrintItem')}
          searchTerm={upcCode}
          options={upcs}
          getOptions={debounceFn}
          inputRef={autocompleteInputRef}
          onChange={searchInputHandler}
          resetValue={(): void => setUpcCode('')}
          error={!isUpcCodeValid}
          onFocus={onAutoCompleteFocus}
          onOptionSelect={(_e, upcCode): void =>
            onAutoCompleteOptionSelect(upcCode!)
          }
          noOptionsText={t('error.no_results_available')}
          loadingText={t('searching')}
          helperText={!isUpcCodeValid && t('error.invalidUpc')}
          inputProps={{
            maxLength: 13,
            minLength: 5,
          }}
        />

        <SearchButton
          label={t('search')}
          disabled={
            isLoadingProductDetails || upcCode.length === 0 || !isUpcCodeValid
          }
          onClick={searchButtonHandler}
          startIcon={<SearchIcon />}
        />
      </SearchContainer>
      <ModalPrintReturnsBackup
        open={isProductDetailsModalVisible}
        onClose={(): void => setProductDetailsModalVibility(false)}
        onCloseHandler={(): void => setProductDetailsModalVibility(false)}
        product={{
          ...productDetails,
          upcCode: productDetails?.upcCode!,
          layout: productDetails?.currency === 'USD' ? 'SRO NA' : 'SRO EMEA',
          serialNumberPrinter: store?.printers?.[0].printerCode!,
          storeCode: store?.storeCode!,
        }}
        onPrintClick={(): void => {
          setProductDetailsModalVibility(false);
          setPrintModalVisibility(true);
        }}
        exportIsDamaged={setDamagesProduct}
      />
      <ModalPrintTemp
        hideReasons={damagesProduct}
        open={isPrintModalVisible}
        onClose={handlePrintModalClose}
        productToPrint={productDetails}
        onStartClick={onStartUseReaderClick}
        onPrintClick={onPrintModalClick}
      />
    </>
  );
};

export default PageReturnsBackup;
