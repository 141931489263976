import React, { FC, memo } from 'react';
import { Modal, ModalProps } from '@material-ui/core';
import styled from 'styled-components';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export interface UIModalProps extends ModalProps {
  children: React.ReactElement;
}

export interface StyledModalProps {
  $minWidth: CSSProperties['minWidth'];
  $minHeight: CSSProperties['minHeight'];
  $maxHeight: CSSProperties['maxHeight'];
  $maxWidth: CSSProperties['maxWidth'];
  $width: CSSProperties['width'];
  $height: CSSProperties['height'];
}

//#region styled-components
const StyledModal = styled(Modal)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: auto;
`;

const StyledContainer = styled.div<Partial<StyledModalProps>>`
  background-color: white;
  display: flex;
  width: ${({ $width }): CSSProperties['width'] => $width || 'auto'};
  height: ${({ $height }): CSSProperties['height'] => $height || 'auto'};
  min-width: ${({ $minWidth }): CSSProperties['minWidth'] =>
    $minWidth || '50vw'};
  min-height: ${({ $minHeight }): CSSProperties['minHeight'] =>
    $minHeight || '50vh'};
  max-height: ${({ $maxHeight }): CSSProperties['maxHeight'] =>
    $maxHeight || '100vh'};
  max-width: ${({ $maxWidth }): CSSProperties['maxWidth'] =>
    $maxWidth || '95vw'};
  border-radius: 10px;

  ${({ theme }): string =>
    `${theme.breakpoints.down('md')} and (orientation: portrait) {
				max-width: 100vw;
  	}`}

  &:focus {
    outline: none;
  }
`;
//#endregion

export const UIModal: FC<UIModalProps & Partial<StyledModalProps>> = memo(
  ({
    children,
    open,
    onClose,
    $width,
    $height,
    $minWidth,
    $minHeight,
    $maxWidth,
    $maxHeight,
    ...rest
  }): JSX.Element => {
    return (
      <StyledModal open={open} onClose={onClose} {...rest}>
        <StyledContainer
          $width={$width}
          $height={$height}
          $minWidth={$minWidth}
          $minHeight={$minHeight}
          $maxHeight={$maxHeight}
          $maxWidth={$maxWidth}
        >
          {children}
        </StyledContainer>
      </StyledModal>
    );
  }
);

UIModal.displayName = 'Modal';

export default UIModal;
