import { FC } from 'react';
import { UIBox } from '@/components/ui/Box';
import styled from 'styled-components';

const StyledTableHeader = styled(UIBox)`
  width: 100%;
  padding: 8px 24px;
  align-items: center;
  border-bottom: 1px solid #4b4b4b;
`;

const TablesHeader: FC = ({ children, ...props }) => {
  return <StyledTableHeader {...props}>{children}</StyledTableHeader>;
};

export default TablesHeader;
