import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { colors, theme } from '@/theme';
import { Typography } from '@/components/ui/Typography';

export const StyledHeader = styled(UIBox)`
  padding: 24px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: sticky;
  top: 136px;
  left: 0;
  z-index: 10;
  background: ${colors.white};
`;

export const StyledHeaderContent = styled('div')`
  width: 100%;

  ${theme.breakpoints.down(1110)} {
    padding: 14px;
    background: #fbfbfb;
    border-radius: 4px;
    box-shadow: 0px 1px 5px 0px #0000001a;
  } ;
`;

export const StyledScanStatusWrapper = styled(UIBox)`
  width: 100%;
  align-items: center;
`;

export const StyledTabWrapper = styled(UIBox)`
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;

  ${theme.breakpoints.down(1110)} {
    flex-direction: column;
  } ;
`;

export const StyledTab = styled(UIBox)<{
  $selected?: boolean;
  $disabled?: boolean;
}>`
  cursor: pointer;
  pointer-events: auto;
  max-width: 110px;
  padding: 5px 16px;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px 0px #0000001a;

  &:not(:last-of-type) {
    margin-right: 22px;
  }

  ${({ $selected }): false | FlattenSimpleInterpolation | undefined =>
    $selected &&
    css`
      background: #fbfbfb;
      border: 1px solid ${colors.primary};
    `};

  ${({ $disabled }): false | FlattenSimpleInterpolation | undefined =>
    $disabled &&
    css`
      cursor: auto;
      pointer-events: none;
      border: 1px solid ${colors.grey};
      box-shadow: none;
    `};
`;

export const StyledTabBadge = styled('div')<{ color?: string }>`
  width: 32px;
  height: 8px;
  border-radius: 100px;
  background: ${({ color }): string | undefined => color};
`;

export const StyledRefreshIcon = styled(UIBox)`
  margin-left: 12px;

  svg {
    width: 32px;
    fill: ${colors.primary};
  }
`;

export const StyledActionWrapper = styled(UIBox)`
  margin-left: auto;
  align-items: center;

  &.desktop-visible {
    display: flex;
    padding-top: 20px;
  }

  &.ipad-visible {
    align-self: end;
    margin-top: 15px;
  }

  ${theme.breakpoints.down(1110)} {
    margin-left: 0;

    &.desktop-visible {
      display: none;
    }
    &.ipad-visible {
      display: flex;
    }
  } ;
`;

export const StyledSpan = styled(Typography)`
  ${theme.breakpoints.down(820)} {
    display: none;
  } ;
`;
