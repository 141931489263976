/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Layout {
  SRO_EMEA = 'SRO EMEA',
  SRO_EMEA_CREATE_TAG = 'SRO EMEA CREATE-TAG',
  SRO_EMEA_RE_PRICING = 'SRO EMEA RE-PRICING',
  SRO_EMEA_RE_TAG = 'SRO EMEA RE-TAG',
  SRO_NA = 'SRO NA',
  SRO_NA_CREATE_TAG = 'SRO NA CREATE-TAG',
  SRO_NA_RE_PRICING = 'SRO NA RE-PRICING',
  SRO_NA_RE_TAG = 'SRO NA RE-TAG',
}
