import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { IconButton, ModalProps } from '@material-ui/core';
import { UIModal } from '@/components/ui/Modal';
import { UICheckbox } from '@/components/ui/Checkbox';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { theme } from '@/theme';
import { UIButton } from '@/components/ui/Button';
import { RoleModalState, SelectedStore } from '@/types/profiling';
import { useAppDispatch } from '@/app/store';
import {
  deleteRoles,
  insertOrDeleteRoles,
  insertRoles,
  resetSelectedStores,
} from '@/features/profiling/usersSlice';
import { useRoleModalContext } from '@/context/roleModalContext';
import { useSelector } from '@/hooks/useSelector';

export interface ModalRoleProps
  extends Omit<ModalProps, 'onClose' | 'children'> {
  type: RoleModalState['type'];
  externalFn?: (roles: string[]) => void | Promise<void>;
  externalDisabled?: boolean;
  externalTitle?: string;
  onClose: () => void;
  roles: string[];
  selectedStores: SelectedStore[];
  setSelectedStores: React.Dispatch<React.SetStateAction<SelectedStore[]>>;
  setRolesToUndo?: React.Dispatch<React.SetStateAction<SelectedStore[]>>;
  setDeletedRolesToUndo?: React.Dispatch<React.SetStateAction<SelectedStore[]>>;
  setInsertedRolesToUndo?: React.Dispatch<
    React.SetStateAction<SelectedStore[]>
  >;
  showDeletedRolesSnackbar?: React.Dispatch<React.SetStateAction<boolean>>;
  showInsertedRolesSnackbar?: React.Dispatch<React.SetStateAction<boolean>>;
  showUndoRolesSnackbar?: React.Dispatch<React.SetStateAction<boolean>>;
  setSaveEnable?: React.Dispatch<React.SetStateAction<boolean>>;
}

//#region - Styled Components
export const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px 50px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: auto;

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                  overflow-y: auto;
              }`}
`;

const StyledTitle = styled(UIBox)`
  text-align: center;
  margin: 12px 0;
`;

export const StyledCloseIcon = styled(IconButton)`
  position: absolute !important;
  right: 0.5rem;
  top: 0.5rem;

  svg {
    fill: #005192;
  }

  ${theme.breakpoints.down('md')} {
    top: 0;
    right: 0.5rem;
  }
`;

export const StyledContent = styled(UIBox)`
  height: 100%;
  width: 100%;
  padding: 0 54px 54px;
  flex-direction: column;

  & > * {
    width: 100%;
  }
`;

const StyledRole = styled(UIBox)`
  align-items: center;
`;
//#endregion

const ModalRole: React.FC<ModalRoleProps> = ({
  type,
  externalFn,
  externalDisabled,
  externalTitle,
  open,
  onClose,
  roles,
  setSaveEnable,
  setSelectedStores,
  selectedStores,
  setRolesToUndo,
  setInsertedRolesToUndo,
  setDeletedRolesToUndo,
  showInsertedRolesSnackbar,
  showDeletedRolesSnackbar,
  showUndoRolesSnackbar,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useSelector(state => state.user);
  const [rolesChecked, setRolesChecked] = useState<string[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const {
    roleModal: { rolesChecked: defaultRolesChecked },
  } = useRoleModalContext();

  useEffect(() => {
    if (type === 'HYBRID') {
      setRolesChecked(defaultRolesChecked!);
    } else {
      setRolesChecked([]);
    }
  }, [defaultRolesChecked, type]);

  useEffect(() => {
    switch (type) {
      case 'ADD':
        return setButtonDisabled(rolesChecked.length === 0);

      case 'DELETE':
        const stores = selectedStores.filter(
          ({ roles }) =>
            roles.filter(role => !rolesChecked.includes(role)).length < 1
        );

        return setButtonDisabled(stores.length > 0);

      case 'EXTERNAL':
        return setButtonDisabled(externalDisabled || false);

      default:
        const storeRoles = selectedStores[0]?.roles.filter(
          role => !rolesChecked.includes(role)
        );

        return setButtonDisabled(
          storeRoles?.length === defaultRolesChecked!.length &&
            rolesChecked.length === 0
        );
    }
  }, [
    defaultRolesChecked,
    externalDisabled,
    rolesChecked,
    rolesChecked.length,
    selectedStores,
    type,
  ]);

  const initState = (): void => {
    dispatch(resetSelectedStores());
    setRolesChecked([]);
    setSelectedStores([]);
    setSaveEnable?.(true);
  };

  const onCheckboxClick = (role: string): void => {
    setRolesChecked(prevState => {
      if (prevState.includes(role)) {
        return prevState.filter(r => r !== role);
      }

      return [...prevState, role];
    });
  };

  const onApplyClick = (): void => {
    const storeCodes = selectedStores.map(({ storeCode }) => storeCode);

    switch (type) {
      case 'ADD':
        dispatch(
          insertRoles({
            storeCodes,
            roles: rolesChecked,
          })
        );

        setInsertedRolesToUndo?.(selectedStores);
        showInsertedRolesSnackbar?.(true);
        initState();
        return onClose();

      case 'DELETE':
        dispatch(
          deleteRoles({
            storeCodes,
            roles: rolesChecked,
          })
        );

        setDeletedRolesToUndo?.(selectedStores);
        showDeletedRolesSnackbar?.(true);
        initState();
        return onClose();

      case 'EXTERNAL':
        externalFn?.(rolesChecked);
        return onClose();

      default:
        dispatch(
          insertOrDeleteRoles({
            storeCode: storeCodes[0],
            roles: rolesChecked,
          })
        );

        setRolesToUndo?.([
          {
            storeCode: storeCodes[0],
            roles: rolesChecked.filter(
              role => !defaultRolesChecked?.includes(role)
            ),
          },
        ]);

        showUndoRolesSnackbar?.(true);
        initState();
        return onClose();
    }
  };

  return (
    <UIModal
      $maxWidth="390px"
      $minWidth="390px"
      $minHeight="auto"
      open={open}
      onClose={onClose}
    >
      <StyledWrapper>
        <StyledCloseIcon onClick={onClose}>
          <HighlightOffIcon />
        </StyledCloseIcon>
        <StyledTitle>
          <Typography size="lg" font="heavy" as="h1">
            {type === 'ADD' && `${t('modal.add.role')}`}
            {type === 'DELETE' && `${t('modal.delete.role')}`}
            {type === 'HYBRID' && `${t('modal.add.delete.role')}`}
            {type === 'EXTERNAL' && externalTitle}
          </Typography>
        </StyledTitle>
        <StyledContent>
          {roles.map((role, index) => (
            <StyledRole key={`${role}-${index}`}>
              <UICheckbox
                disabled={role === 'Admin' && !user.roles?.includes('Admin')}
                onClick={(): void => onCheckboxClick(role)}
                checked={rolesChecked.includes(role)}
              />
              <Typography>{role}</Typography>
            </StyledRole>
          ))}
        </StyledContent>
        <UIButton
          label={t('apply')}
          onClick={onApplyClick}
          disabled={buttonDisabled}
        />
      </StyledWrapper>
    </UIModal>
  );
};

export default ModalRole;
