import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UIListSubHeader } from '@/components/ui/List';
import { UIBox } from '@/components/ui/Box';
import { PaginationList } from '@/components/layout/PaginationList';
import { Typography } from '@/components/ui/Typography';
import ProductListHeader from '../ProductListHeader';
import ProductListItem from '../ProductListItem';

import type { CycleDetailsItems } from '@/api';
import type { ProductListProps, ProductListType } from '../types';
import { Product } from '@/types/enum';
import displayProductHeader from '@/utils/displayProductHeader';
import { StyledUIList, StyledUIListWrapper } from './style';
import { useSelector } from '@/hooks/useSelector';

const ProductList: React.FC<ProductListProps & ProductListType> = ({
  data,
  type,
  count,
  disableActions,
  hasSubheader = false,
}) => {
  const { t } = useTranslation();
  const { isMissingItemsCycleCount } = useSelector(state => state.cycleCount);
  const [expanded, setExpended] = useState<boolean>(false);

  const showHeaderList =
    (!isMissingItemsCycleCount && type === Product.NOT_FOUND) ||
    type === Product.UNEXPECTED;

  const showSubheaderList =
    type !== Product.NOT_FOUND && type !== Product.UNEXPECTED;

  const creatSubheaderTitle = (): string => {
    switch (type) {
      case Product.FOUND:
        return `${t('receiving.founditems', { count })}`;

      case Product.RECALLED:
        return `${t('items')} - ${t('recalledStatus')}`;

      case Product.DAMAGED:
        return `${t('items')} - ${t('damagedStatus')}`;

      case Product.QUALITY_INSPECTION:
        return `${t('items')} - ${t('qualityInspectionStatus')}`;

      case Product.IN_TRANSIT:
        return `${t('items')} - ${t('inTransitStatus')}`;

      default:
        return `${t('items')} - ${t('missingCheckoutErrorStatus')}`;
    }
  };

  return (
    <StyledUIListWrapper
      className={type}
      order={isMissingItemsCycleCount && type === Product.FOUND ? '1' : '2'}
    >
      {(type === Product.NOT_FOUND || type === Product.UNEXPECTED) && (
        <UIBox pl={3} alignSelf="start">
          <Typography size="lg" font="heavy" as="h1">
            {type === Product.UNEXPECTED
              ? t('receiving.unexpecteditems', { count })
              : t('receiving.notfounditems', {
                  count,
                })}
          </Typography>
        </UIBox>
      )}
      {showHeaderList && (
        <ProductListHeader type={type} disableActions={disableActions} />
      )}
      <StyledUIList
        subheader={
          <>
            {showSubheaderList && (
              <UIListSubHeader
                title={creatSubheaderTitle()}
                itemsCount={count}
                accordion={{
                  expanded,
                  toggleAccordionState: setExpended,
                }}
              />
            )}
          </>
        }
        className={type}
        rounded
        backgrounded
        shadowed
      >
        {(!hasSubheader || (hasSubheader && expanded)) && (
          <PaginationList
            data={data}
            pageSize={25}
            renderItem={(
              productItem: NonNullable<CycleDetailsItems>,
              i: number
            ): JSX.Element => (
              <React.Fragment key={i}>
                {displayProductHeader(data, type, i) && (
                  <UIBox width="100%" mt={3} justifyContent="space-between">
                    <Typography font="heavy">
                      {productItem.brandDescription}
                    </Typography>
                    {productItem.modelCode && (
                      <UIBox alignItems="center">
                        <Typography font="heavy">{t('modelCode')}:</Typography>
                        <Typography margin="0 0 0 8px">
                          {productItem.modelCode}
                        </Typography>
                      </UIBox>
                    )}
                  </UIBox>
                )}
                <ProductListItem
                  key={`${productItem.upcCode}-${i}`}
                  type={type}
                  disableActions={disableActions}
                  {...productItem}
                />
              </React.Fragment>
            )}
          />
        )}
      </StyledUIList>
    </StyledUIListWrapper>
  );
};

export default ProductList;
