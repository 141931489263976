import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from '@/components/ui/Typography';

interface ProductPriceProps {
  price?: string;
  currency?: string;
}

const StyledProductPrice = styled.div`
  margin-left: auto;
  align-self: flex-start;
  text-align: right;

  h1 {
    margin: 0 0 8px 0;
  }
`;

export const ProductPrice: FC<ProductPriceProps> = ({
  price,
  currency,
  children,
}) => {
  const { i18n } = useTranslation();
  const isoCode = i18n.language;
  // it isocode not translate USD currency with '$'
  const productPrice = price
    ? new Intl.NumberFormat(isoCode === 'it' ? 'de' : isoCode, {
        style: 'currency',
        currency: currency ? currency : isoCode === 'en' ? 'USD' : 'EUR',
      }).format(+price!)
    : isoCode === 'en'
    ? '0.00'
    : '0,00';

  return (
    <>
      <StyledProductPrice>
        <Typography size="lg" font="heavy" as="h1">
          {productPrice}
        </Typography>
        {children}
      </StyledProductPrice>
    </>
  );
};

export default ProductPrice;
