import { InitialLoaderState } from '@/types/loader';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialLoaderState = {
  manualProcessLocked: false,
  readerProcessLocked: false,
  deleteProcessLocked: false,
};

type SetProcessLockedBody = {
  process: keyof InitialLoaderState;
  locked: boolean;
};

export const loaderSlice = createSlice({
  name: 'loaderSlice',
  initialState,
  reducers: {
    setProcessLocked: (
      state,
      { payload }: PayloadAction<SetProcessLockedBody>
    ) => {
      state[payload.process] = payload.locked;
    },
  },
});

export const { setProcessLocked } = loaderSlice.actions;
export default loaderSlice.reducer;
