import { CheckedPosItems } from '@/types/pos';

export namespace Utils {
  export const checkItem = (
    state: CheckedPosItems,
    upcCode: string,
    epcCode?: string,
    epcCodes?: string[]
  ): CheckedPosItems => {
    if (epcCodes) {
      state = {
        ...state,
        [upcCode]: epcCodes,
      };

      return state;
    }

    // Update epc codes if the user clicks on epc code checkbox
    if (epcCode) {
      state = {
        ...state,
        [upcCode]: upcCode in state ? [...state[upcCode], epcCode] : [epcCode],
      };

      return state;
    }

    // Update upc codes if the user clicks on upc code checkbox
    return (state = {
      ...state,
      [upcCode]: [],
    });
  };
  export const unCheckItem = (
    state: CheckedPosItems,
    upcCode: string,
    epcCode: string | undefined
  ): CheckedPosItems => {
    // Update epc codes if the user clicks on epc code checkbox
    if (epcCode) {
      const alreadyChecked =
        upcCode in state && state[upcCode].includes(epcCode);

      const removeEpcCode = (): string[] =>
        state[upcCode].filter(epc => epc !== epcCode);

      state = {
        ...state,
        [upcCode]: alreadyChecked ? removeEpcCode() : state[upcCode],
      };

      if (state[upcCode].length === 0) {
        delete state[upcCode];
      }

      return state;
    }

    // Update upc codes if the user clicks on upc code checkbox
    delete state[upcCode];

    return state;
  };
}
