import { FC } from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { UIBox } from '@/components/ui/Box';

import { Timer } from '@/components/layout/Timer';
import { MultiProgressBar } from '@/components/layout/MultiProgressBar';
import { Counter } from '@/components/layout/Counter';
import { useLocation } from 'react-router';
import { AppRoutes } from '@/app/routers';
import MultiProgressBarCycleCount from '../MultiProgressBar/MultiProgressBarCycleCount';

interface ScanStatusBarProps {
  sticky: boolean;
  inPause: boolean;
  setPause: React.Dispatch<React.SetStateAction<boolean>>;
  foundItemsCount: number;
  notFoundItemsCount: number;
  unexpectedItemsCount: number;
  hasEpcDifference?: boolean;
}

const StyledScanStatusBar = styled(UIBox)<{ $sticky?: boolean }>`
  position: fixed;
  top: 136px;
  width: 100%;
  max-width: 1440px;
  padding: 24px;
  background: #fff;
  justify-content: space-between;
  z-index: 1;

  & > * {
    border: 1px solid #005192;
    align-items: center;
    border-radius: 4px;
    background: #fbfbfb;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    ${({ $sticky }): false | FlattenSimpleInterpolation | undefined =>
      $sticky &&
      css`
        padding: 0 !important;
        border: none;
        background: #4b4b4b !important;
      `}

    &:nth-child(1) {
      padding: 16px 24px;
    }

    &:nth-child(2) {
      padding: 8px;
    }
  }
`;

const ScanStatusBar: FC<ScanStatusBarProps> = ({
  sticky,
  inPause,
  setPause,
  hasEpcDifference,
  ...props
}) => {
  const { pathname } = useLocation();

  return (
    <StyledScanStatusBar $sticky={sticky}>
      <Timer
        sticky={sticky}
        inPause={inPause}
        setPause={setPause}
        minutes="0"
        seconds="0"
      />
      {pathname === AppRoutes.SCAN && hasEpcDifference ? (
        <MultiProgressBar sticky={sticky} {...props} />
      ) : (
        <MultiProgressBarCycleCount sticky={sticky} {...props} />
      )}
      <Counter sticky={sticky} {...props} />
    </StyledScanStatusBar>
  );
};

export default ScanStatusBar;
