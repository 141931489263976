import { FC, memo } from 'react';
import { StyledLink } from './style';
import { TypesAddLink } from './types';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const AddLink: FC<TypesAddLink> = memo(
  ({ label, url, action }): JSX.Element => (
    <StyledLink href={url} onClick={action}>
      {label}
      <AddCircleOutlineIcon />
    </StyledLink>
  )
);
AddLink.displayName = 'AddLink';

export default AddLink;
