import React, { FC, memo } from 'react';
import styled from 'styled-components';
import {
  colors,
  fontFamilies,
  fontSizes,
  TColorName,
  TColorValues,
  TFontFamily,
  TFontFamilyValues,
  TFontSize,
  TFontSizeValues,
} from '@/theme';

import type { TypographyProps as MuiTypographyProps } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
export interface TypographyProps extends Omit<MuiTypographyProps, 'color'> {
  as?: string;
  font?: TFontFamily;
  children: React.ReactNode;
  className?: string;
  color?: TColorName;
  size?: TFontSize;
  margin?: CSSProperties['margin'];
}

const defaultProps: Partial<TypographyProps> = {
  as: 'span',
  size: 'md',
  font: 'default',
  color: 'text',
};

const StyledTypography = styled(
  ({ component: Component = defaultProps.as, ...props }) => (
    <Component {...props} />
  )
)`
  && {
    font-size: ${({ size }: TypographyProps): TFontSizeValues =>
      fontSizes[size!]};
    line-height: ${({ size }: TypographyProps): TFontSizeValues =>
      fontSizes[size!]};
    font-family: ${({ font }: TypographyProps): TFontFamilyValues =>
      fontFamilies[font!]};
    color: ${({ color }: TypographyProps): TColorValues => colors[color!]};
    margin: ${({ margin }): string => margin};
  }
`;

const Typography: FC<TypographyProps> = memo(
  ({ as, ...props }: TypographyProps): JSX.Element => {
    return <StyledTypography component={as} {...props}></StyledTypography>;
  }
);

Typography.defaultProps = defaultProps;
Typography.displayName = 'Typography';

export default Typography;
