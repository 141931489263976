import { FC } from 'react';
import styled from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { ProductImage } from '@/components/ui/ProductImage';
import { Checkbox } from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';

interface ScannedProductImageProps {
  colorCode?: string;
  modelCode?: string;
  brandCode?: string;
  imageUrl?: string;
  upc?: string;
  brandName?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onClickAccordion?: React.MouseEventHandler<SVGElement>;
  onCheckboxClick?: (upc?: string, brandName?: string) => void;
  checkedUpcs?: string[];
  checkboxIsVisible?: boolean;
  checkboxIsDisabled?: boolean;
  $v2?: boolean;
  checked?: boolean;
  $isAccordionExpanded?: boolean;
}

const StyledScannedProductImage = styled(UIBox)`
  max-width: 80px;
  max-height: 60px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ededed;
  cursor: pointer;
`;

const StyledAccordionOpener = styled(ExpandMore)<
  Partial<typeof ExpandMore> & {
    $isAccordionExpanded?: boolean;
  }
>`
  margin-right: 20px;
  fill: #145d95;
  font-size: xx-large;
  transition: 0.25s ease-in-out;
  transform: ${({ $isAccordionExpanded }): string =>
    $isAccordionExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
  cursor: pointer;
`;

const StyledCheckbox = styled(Checkbox)<{ $isDisabled: boolean }>`
  margin: 0 20px 0 0;

  svg {
    fill: ${({ $isDisabled }): string => ($isDisabled ? 'gray' : '#005192')};
  }
`;

const ScannedProductImage: FC<ScannedProductImageProps> = ({
  onClick,
  colorCode,
  modelCode,
  brandCode,
  $v2 = false,
  checkboxIsVisible = false,
  checkboxIsDisabled = false,
  onCheckboxClick,
  upc,
  brandName,
  onClickAccordion = (): void => {},
  $isAccordionExpanded,
  checkedUpcs,
  checked = false,
  imageUrl,
}) => {
  return (
    <>
      {$v2 && (
        <StyledAccordionOpener
          onClick={onClickAccordion}
          $isAccordionExpanded={$isAccordionExpanded}
        />
      )}
      {checkboxIsVisible && (
        <StyledCheckbox
          checked={checkedUpcs?.includes(upc!) || checked}
          $isDisabled={checkboxIsDisabled}
          disabled={checkboxIsDisabled}
          onChange={(): void => onCheckboxClick?.(upc, brandName)}
        />
      )}
      <StyledScannedProductImage onClick={onClick}>
        <ProductImage
          imageUrl={imageUrl}
          colorCode={colorCode}
          modelCode={modelCode}
          brandCode={brandCode}
          width={80}
          height={60}
        />
      </StyledScannedProductImage>
    </>
  );
};

export default ScannedProductImage;
