import { FC, memo } from 'react';
import { UIBox, UIBoxProps } from '@/components/ui/Box';
import styled from 'styled-components';

export interface RowProps extends UIBoxProps {
  mt?: string;
}

const StyledRow = styled(UIBox)<{ mt?: string }>`
  align-items: center;
  margin-top: ${({ mt }): string => (mt ? `${mt}px` : '16px')};
`;

export const Row: FC<RowProps> = memo(({ children, ...rest }) => {
  return <StyledRow {...rest}>{children}</StyledRow>;
});

Row.displayName = 'Row';

export default Row;
