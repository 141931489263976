import InputBase from '@material-ui/core/InputBase';
import styled from 'styled-components';
import {
  createStyles,
  // alpha,
  Theme,
  withStyles,
} from '@material-ui/core/styles';

export const StyledInfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  ${({ theme }): string => (theme === 'error' ? `color: #f00;` : '')}
`;

export const StyledErrorMessage = styled.div`
  color: #f00;
`;

export const DisabledInput = styled.div`
  padding: 10px 12px;
`;

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  })
)(InputBase);
