import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  current,
} from '@reduxjs/toolkit';
import {
  ReceiveApiError as ApiError,
  ProductDetailsTransferItems,
  ProductTransferList,
  StoreList,
  StoreToStoreService,
} from '@/api';
import { RootState } from '@/app/rootReducer';
import { StoreToStoreInitialState, ToStore } from '@/types/storeToStoreSlice';
import getCurrentDate from '@/utils/getCurrentDate';
import { ConfirmStoreToStoreResponse } from '@/api/receive';

const initialState: StoreToStoreInitialState = {
  stores: [],
  storesIsLoading: false,
  storesHasError: undefined,
  toStore: {
    address: '',
    code: '',
  },
  itemsToTransfer: [],
  itemsDamages: [],
  itemsQualityInspection: [],
  itemsRecall: [],
  itemsCheckoutError: [],
  itemsToTransferIsLoading: false,
  itemsToTransferHasError: undefined,
  confirmIsLoading: false,
  confirmHasError: undefined,
  productsRemoved: [],
  epcsRemoved: {},
  tags: [],
  pdfCode: '',
};

export const findStoreToTransfer = createAsyncThunk<
  StoreList | undefined,
  void,
  { state: RootState; rejectValue: ApiError }
>('findStoreToTransfer', async (_, { getState, rejectWithValue }) => {
  const { store } = getState().currentStore;

  if (store?.storeCode) {
    try {
      return await StoreToStoreService.storeFindStoreToTransfer({
        storeCode: store.storeCode,
      });
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
});

export const searchStoreByFilter = createAsyncThunk<
  StoreList | undefined,
  string,
  { rejectValue: ApiError }
>('searchStoreByFilter', async (storeFilter, { rejectWithValue }) => {
  try {
    return await StoreToStoreService.storeSearchStoreListByFilter({
      storeFilter,
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const searchItemsToTransfer = createAsyncThunk<
  ProductTransferList | undefined,
  string[],
  { state: RootState; rejectValue: ApiError }
>('searchItemsToTransfer', async (epcCodes, { rejectWithValue, getState }) => {
  const { store } = getState().currentStore;
  const date = getCurrentDate();

  if (store?.storeCode) {
    try {
      return await StoreToStoreService.storeSearchItemToTransfer({
        requestBody: {
          storeCode: store.storeCode,
          epcCodes,
          date,
        },
      });
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
});

export const confirmStoreToStore = createAsyncThunk<
  ConfirmStoreToStoreResponse,
  NonNullable<
    Parameters<
      typeof StoreToStoreService.storeConfirmStoreToStore
    >[number]['requestBody']
  >['upcCodes'],
  {
    state: RootState;
    rejectValue: ApiError;
  }
>('confirmStoreToStore', async (upcCodes, { getState, rejectWithValue }) => {
  const { store } = getState().currentStore;
  const { toStore } = getState().s2s;

  try {
    if (store?.storeCode) {
      const requestBody = {
        storeCodeFrom: store.storeCode,
        storeCodeTo: toStore.code,
        upcCodes,
      };
      return await StoreToStoreService.storeConfirmStoreToStoreEncoded({
        requestBody: {
          confirmStoreToStore: btoa(JSON.stringify(requestBody)),
        },
      });
    }

    return {
      orderId: '',
      message: '',
    };
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

const storeToStoreSlice = createSlice({
  name: 'storeToStore',
  initialState,
  reducers: {
    removeDamagesList: state => {
      state.itemsDamages = [];
    },
    removeInspectionList: state => {
      state.itemsQualityInspection = [];
    },
    removeRecallList: state => {
      state.itemsRecall = [];
    },
    removeCheckoutErrorList: state => {
      state.itemsCheckoutError = [];
    },
    selectStore: (state, { payload }: PayloadAction<ToStore>): void => {
      state.toStore.address = payload.address;
      state.toStore.code = payload.code;
    },
    removeStoreToStoreProductEpc: (
      state,
      {
        payload,
      }: PayloadAction<{
        upcCode: string;
        epcCode: string;
      }>
    ) => {
      const product = state.itemsToTransfer.find(
        ({ upcCode }) => upcCode === payload.upcCode
      );
      const productIndex = state.itemsToTransfer.findIndex(
        ({ upcCode }) => upcCode === payload.upcCode
      );

      const epcCodes =
        product?.epcCodes?.epcCodes?.filter(
          (code: string) => code !== payload.epcCode!
        ) || [];

      if (state.epcsRemoved[payload.upcCode]) {
        state.epcsRemoved[payload.upcCode].push(payload.epcCode);
      } else {
        state.epcsRemoved[payload.upcCode] = [payload.epcCode];
      }

      if (product) {
        state.itemsToTransfer[productIndex] = {
          ...product,
          epcCodes: { epcCodes },
        };
      }
    },
    readdDeletedProductEpc: (
      state,
      { payload }: PayloadAction<{ upcCode: string; epcCode: string }>
    ) => {
      if (state.epcsRemoved[payload.upcCode].length > 0) {
        const productIndex = state.itemsToTransfer.findIndex(
          ({ upcCode }) => upcCode === payload.upcCode
        );

        if (productIndex !== -1) {
          state.itemsToTransfer[productIndex].epcCodes?.epcCodes?.push(
            payload.epcCode
          );

          state.epcsRemoved[payload.upcCode] = state.epcsRemoved[
            payload.upcCode
          ].filter(epc => epc !== payload.epcCode);
        }
      }
    },
    removeStoreToStoreProduct: (state, { payload }: PayloadAction<string>) => {
      const lastProductRemoved = state.itemsToTransfer.find(
        ({ upcCode }) => upcCode === payload
      );
      const lastProductRemovedIndex = state.itemsToTransfer.findIndex(
        ({ upcCode }) => upcCode === payload
      );

      if (lastProductRemoved) {
        state.itemsToTransfer.splice(lastProductRemovedIndex, 1);

        state.productsRemoved.push({
          ...lastProductRemoved,
          index: lastProductRemovedIndex,
        });
      }
    },
    readdDeletedProduct: (state, { payload }: PayloadAction<string>) => {
      if (state.productsRemoved.length > 0) {
        const deletedProduct = state.productsRemoved.find(
          ({ upcCode }) => upcCode === payload
        );

        state.itemsToTransfer.splice(
          deletedProduct!.index,
          0,
          deletedProduct as ProductDetailsTransferItems
        );

        state.productsRemoved = current(state).productsRemoved.filter(
          ({ upcCode }) => upcCode !== payload
        );
      }
    },
    initStoreToStoreState: () => initialState,
    resetConfirmHasError: state => {
      state.confirmHasError = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(findStoreToTransfer.pending, state => {
        state.storesIsLoading = true;
      })
      .addCase(findStoreToTransfer.fulfilled, (state, { payload }) => {
        state.storesIsLoading = false;

        if (payload?.stores) {
          state.stores = payload.stores;
        }
      })
      .addCase(findStoreToTransfer.rejected, (state, { payload }) => {
        state.storesIsLoading = false;
        state.storesHasError = payload as ApiError;
      })
      .addCase(searchStoreByFilter.pending, state => {
        state.storesIsLoading = true;
      })
      .addCase(searchStoreByFilter.fulfilled, (state, { payload }) => {
        state.storesIsLoading = false;

        if (payload?.stores) {
          state.stores = payload.stores;
        }
      })
      .addCase(searchStoreByFilter.rejected, (state, { payload }) => {
        state.storesIsLoading = false;
        state.storesHasError = payload as ApiError;
      })
      .addCase(searchItemsToTransfer.pending, state => {
        state.itemsToTransferIsLoading = true;
      })
      .addCase(searchItemsToTransfer.fulfilled, (state, { payload }) => {
        state.itemsToTransferIsLoading = false;

        if (payload?.recalls) {
          state.itemsRecall = payload.recalls;
        }
        if (payload?.qualityInspection) {
          state.itemsQualityInspection = payload.qualityInspection;
        }
        if (payload?.damages) {
          state.itemsDamages = payload.damages;
        }
        if (payload?.missingCheckoutError) {
          state.itemsCheckoutError = payload.missingCheckoutError;
        }
        if (payload?.found) {
          state.itemsToTransfer = payload.found;
        }
      })
      .addCase(searchItemsToTransfer.rejected, (state, { payload }) => {
        state.itemsToTransferIsLoading = false;
        state.itemsToTransferHasError = payload as ApiError;
      })
      .addCase(confirmStoreToStore.pending, state => {
        state.confirmIsLoading = true;
      })
      .addCase(confirmStoreToStore.fulfilled, (state, { payload }) => {
        state.confirmIsLoading = false;
        state.pdfCode = payload.orderId;
        state.confirmHasError = false;
      })
      .addCase(confirmStoreToStore.rejected, (state, { payload }) => {
        state.confirmIsLoading = false;
        state.confirmHasError = payload as ApiError;
      });
  },
});

export const {
  removeDamagesList,
  removeInspectionList,
  removeRecallList,
  removeCheckoutErrorList,
  removeStoreToStoreProductEpc,
  removeStoreToStoreProduct,
  readdDeletedProduct,
  readdDeletedProductEpc,
  initStoreToStoreState,
  resetConfirmHasError,
  selectStore,
} = storeToStoreSlice.actions;
export default storeToStoreSlice.reducer;
