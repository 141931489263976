import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { useHistory, useLocation, useParams } from 'react-router';
import { useAsync } from 'react-use';
import { useAppDispatch } from '@/app/store';
import useResetDevice from '@/hooks/useResetDevice';
import { DateTime } from 'luxon';

import { ModalAttention } from '@/components/layout/ModalAttention';
import { MultiProgressBarCycleCount } from '@/components/layout/MultiProgressBar';
import { Typography } from '@/components/ui/Typography';
import { UIBox } from '@/components/ui/Box';
import { Counter } from '@/components/layout/Counter';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { PageLoader } from '@/components/ui/PageLoader';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { ModalDataSaved } from '@/components/layout/ModalDataSaved';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import InventoryReportList from './InventoryReportList';
import PersonIcon from '@material-ui/icons/Person';

import { colors } from '@/theme';
import {
  addInventoryEpc,
  fetchInventoryDetails,
  fetchInventoryReport,
  setInventoryOwner,
} from '@/features/inventory/inventorySlice';
import { StyledHeader } from './style';
import { InventoryService } from '@/api/receive';
import { EnumMode } from '@/types/enum';
import { ScanDevicesService } from '@/api';
import { deleteTags, tagsMQTTDevice } from '@/features/devices/devicesSlice';
import { AppRoutes } from '@/app/routers';

type Params = { id: string };

const PageInventoryReport: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams<Params>();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { resetDevice } = useResetDevice();
  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );
  const user = useSelector(state => state.user);

  const { inventoryStartTime } = useSelector(
    state => state.inventory.inventoryDetails
  );
  const {
    inventoryOwner,
    fetchInventoryIsLoading,
    fetchInventoryReportHasError,
  } = useSelector(state => state.inventory);
  const { brands } = useSelector(state => state.inventory.inventoryReport);
  const { deviceInUse, tags } = useSelector(state => state.devices);

  const [isLoadingFinishScan, setLoadingFinishScan] = useState<boolean>(false);
  const [scanModalIsVisible, setScanModalVisibility] = useState<boolean>(false);
  const [isGettingTags, setGettingTags] = useState<boolean>(false);
  const [updateTagsLoading, setUpdateTagsLoading] = useState<boolean>(false);
  const [scanCompleted, setScanCompleted] = useState<boolean>(false);
  const [quitError, setQuitError] = useState<boolean>(false);
  const [quitLoading, setQuitLoading] = useState<boolean>(false);
  const [epcCode, setEpcCode] = useState<string>();
  const [deviceError, setDeviceError] = useState<boolean>(false);
  const [retryLoading, setRetryLoading] = useState<boolean>(false);
  const [retryError, setRetryError] = useState<boolean>(false);
  const [deviceErrorMsg, setDeviceErrorMsg] = useState<string>();
  const [epcError, setEpcError] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<boolean>(false);
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [reportError, setReportError] = useState<boolean>(false);
  const [addModalIsVisibile, setAddModalVisibility] = useState<boolean>(false);
  const [joinInventoryError, setJoinInventoryError] = useState<boolean>(false);
  const [validationModalIsVisible, setValidationModalVisibility] =
    useState<boolean>(false);
  const [savedModalIsVisible, setSavedModalVisibility] =
    useState<boolean>(false);
  const [quitModalIsVisibile, setQuitModalVisibility] =
    useState<boolean>(false);

  const isRetry = pathname.indexOf(`${AppRoutes.INVENTORY}-retry/`) > -1;

  // Runs if the user refresh the page
  useAsync(async () => {
    if (brands && brands.length === 0) {
      await dispatch(fetchInventoryReport(id));
    }
  }, []);

  // Runs if the user refresh the page
  useAsync(async () => {
    if (!inventoryOwner && storeCode) {
      await dispatch(
        fetchInventoryDetails({
          inventoryId: id,
          storeCode,
        })
      );

      dispatch(setInventoryOwner());
    }
  }, []);

  useEffect(() => {
    if (fetchInventoryReportHasError) {
      setReportError(true);
    }
  }, [fetchInventoryReportHasError]);

  const addEpcs = async (epcs: string[]): Promise<void> => {
    try {
      const requestBody = {
        isInventoryOwner: true,
        inventoryId: id,
        epcs,
      };

      await dispatch(addInventoryEpc(requestBody)).unwrap();
    } catch {
      setEpcError(true);
    }
  };

  useAsync(async () => {
    try {
      if (tags && tags.length > 0) {
        await addEpcs(tags);
        setScanModalVisibility(false);
      }
    } catch (e) {
      setEpcError(true);
    }
  }, [tags.length]);

  const addEpcClickHandler = (epc: string): void => {
    setEpcCode(epc);
    setAddModalVisibility(true);
  };

  const confirmAddEpcHandler = async (): Promise<void> => {
    if (epcCode) {
      await addEpcs([epcCode]);
      setEpcCode(undefined);
      setAddModalVisibility(false);
    }
  };

  const cancelAddEpcHandler = async (): Promise<void> => {
    setEpcCode(undefined);
    setAddModalVisibility(false);
  };

  const validateInventory = async (): Promise<void> => {
    if (storeCode) {
      try {
        setValidationLoading(true);

        await InventoryService.inventoryApplyValidation({
          requestBody: {
            inventoryId: id,
            storeCode,
          },
        });

        setValidationLoading(false);
        setValidationModalVisibility(false);
        setSavedModalVisibility(true);
      } catch {
        setValidationLoading(false);
        setValidationError(true);
      }
    }
  };

  const finishScanClickHandler = async (): Promise<void> => {
    try {
      setGettingTags(false);
      setScanCompleted(true);

      const { tags: deviceTags, mode } = await ScanDevicesService.getTags({
        devId: deviceInUse?.deviceId!,
      });

      if (mode === 'no mode') {
        setDeviceErrorMsg(t('error.deviceCommunication'));
        return setDeviceError(true);
      }

      setLoadingFinishScan(true);

      if (deviceTags && deviceTags.length > 0) {
        dispatch(tagsMQTTDevice(deviceTags));
      }

      const { code, message } = await ScanDevicesService.activateScanning({
        requestBody: {
          dev_id: deviceInUse?.deviceId!,
          enable: 'False',
        },
      });

      if (code !== 'OK') {
        setDeviceErrorMsg(message);
        setLoadingFinishScan(false);
        return setDeviceError(true);
      }

      await dispatch(deleteTags());
      setLoadingFinishScan(false);
    } catch {
      setLoadingFinishScan(false);
      setDeviceError(true);
    }
  };

  const quitHandler = async (): Promise<void> => {
    if (storeCode) {
      try {
        setQuitLoading(true);

        await InventoryService.inventoryQuitInventory({
          requestBody: {
            inventoryId: id,
            storeCode,
          },
        });

        await resetDevice();

        setQuitLoading(false);
        history.push(AppRoutes.INVENTORY);
      } catch {
        setQuitLoading(false);
        setQuitError(true);
      }
    }
  };

  const applyForValidationHandler = async (): Promise<void> => {
    try {
      setUpdateTagsLoading(true);

      const { tags: deviceTags } = await ScanDevicesService.getTags({
        devId: deviceInUse?.deviceId!,
      });

      if (deviceTags && deviceTags.length > 0) {
        dispatch(tagsMQTTDevice(deviceTags));
        await addEpcs(deviceTags?.map(({ epc }) => epc) || []);
      }

      setUpdateTagsLoading(false);
      setValidationModalVisibility(true);
    } catch {
      setUpdateTagsLoading(false);
    }
  };

  const retryHandler = async (): Promise<void> => {
    try {
      setRetryLoading(true);

      await InventoryService.inventoryChangeInventoryStatus({
        requestBody: {
          storeCode,
          idInventory: id,
          isRetry: true,
          status: 'Ongoing',
        },
      });

      setRetryLoading(false);
      history.push(AppRoutes.INVENTORY);
    } catch {
      setRetryLoading(false);
      setRetryError(true);
    }
  };

  const startReaderHandler = async (): Promise<void> => {
    setGettingTags(true);
    setScanCompleted(false);
    setScanModalVisibility(false);

    if (isRetry) {
      if (user.username === inventoryOwner) {
        return history.push(`${AppRoutes.INVENTORY}/${id}`);
      }

      try {
        await InventoryService.inventoryJoinInventory({
          requestBody: {
            storeCode,
            inventoryId: id,
          },
        });
      } catch {
        setScanModalVisibility(false);
        setGettingTags(false);
        setJoinInventoryError(true);
        return history.push(AppRoutes.INTRO);
      }

      history.push(`${AppRoutes.INVENTORY}/${id}`);
    }
  };

  const closeModalScanHandler = (): void => {
    setScanModalVisibility(false);
    setScanCompleted(false);
  };

  const closeModalDataSavedHandler = (): void => {
    setSavedModalVisibility(false);
    history.push(AppRoutes.INVENTORY);
  };

  const inventoryDate = DateTime.fromSeconds(
    Number(inventoryStartTime) || 0
  ).toFormat('yyyy LLLL dd hh:mm', {
    locale: i18n.language,
  });

  const found = useMemo(
    () => brands && brands.reduce((a, b) => a + b.found!, 0),
    [brands]
  );

  const unexpected = useMemo(
    () => brands && brands.reduce((a, b) => a + b.unexpected!, 0),
    [brands]
  );

  const expected = useMemo(
    () => brands && brands.reduce((a, b) => a + b.expected!, 0),
    [brands]
  );

  const notFound = useMemo(
    () =>
      brands &&
      brands.flatMap(({ notFound }) => notFound?.flatMap(({ epcs }) => epcs))
        .length,
    [brands]
  );

  if (fetchInventoryIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalAttention
        open={quitModalIsVisibile}
        onConfirmClick={quitHandler}
        loadingConfirm={quitLoading}
        disableConfirm={quitLoading}
        onClose={(): void => setQuitModalVisibility(false)}
        messageMaxWidth="75%"
        message={t('quitInventoryMessage')}
      />
      <ModalAttention
        open={addModalIsVisibile}
        onConfirmClick={confirmAddEpcHandler}
        onClose={cancelAddEpcHandler}
        messageMaxWidth="75%"
        message={t('addItemMessage')}
      />
      <ModalAttention
        open={validationModalIsVisible}
        onConfirmClick={validateInventory}
        loadingConfirm={validationLoading}
        disableConfirm={validationLoading}
        onClose={(): void => setValidationModalVisibility(false)}
        messageMaxWidth="75%"
        message={t('validateInventoryMessage')}
      />
      <ModalDataSaved
        $minWidth="400px"
        $minHeight="160px"
        open={savedModalIsVisible}
        onClose={closeModalDataSavedHandler}
        message={t('modal.datasaved')}
      />
      <ModalScanDetailsV2
        open={scanModalIsVisible}
        startGetTags={isGettingTags}
        startGettingTags={setGettingTags}
        isScanningAgain={scanCompleted}
        setIsScanCompleted={setScanCompleted}
        onClose={closeModalScanHandler}
        defaultMode={isRetry ? EnumMode.INVENTORY : EnumMode.FIND}
        disabledModes={isRetry ? [EnumMode.FIND] : [EnumMode.INVENTORY]}
        hideModes={isRetry ? [EnumMode.FIND] : [EnumMode.INVENTORY]}
        onStartReader={startReaderHandler}
      />
      <UIBox flexDirection="column" width="100%">
        <StyledHeader>
          <UIBox>
            <UIBox mt={-1} alignItems="center">
              <PersonIcon fontSize="large" htmlColor={colors.grey} />
              <UIBox ml={2} flexDirection="column">
                <Typography margin="4px 0" color="grey">
                  {inventoryOwner}
                </Typography>
                <Typography font="heavy">{t('completed')}</Typography>
              </UIBox>
            </UIBox>
          </UIBox>
          <MultiProgressBarCycleCount
            foundItemsCount={found || 0}
            notFoundItemsCount={notFound || 0}
            unexpectedItemsCount={unexpected || 0}
            expectedItemsCount={expected || 0}
          />
          <Counter
            foundItemsCount={found}
            notFoundItemsCount={notFound}
            unexpectedItemsCount={unexpected}
          />
        </StyledHeader>
        <Typography size="lg" font="heavy" margin="0 auto">
          {t('inventory')}: {inventoryDate}
        </Typography>
        <UIBox flexDirection="column" pl={3} pr={3} mt={5} mb="100px">
          {brands && (
            <>
              {brands.length > 0 ? (
                <>
                  {brands.map((brand, index) => (
                    <InventoryReportList
                      key={`${brand.brandName}-${index}`}
                      actionDisabled={isGettingTags || isRetry}
                      actionClickHandler={addEpcClickHandler}
                      {...brand}
                    />
                  ))}
                </>
              ) : (
                <Typography color="grey" font="heavy" margin="24px auto 0 auto">
                  {t('proceedWithInvetoryConfirmation')}
                </Typography>
              )}
            </>
          )}
        </UIBox>
      </UIBox>
      {isGettingTags ? (
        <CTAContainer
          type="FINISH SCAN"
          loading={isLoadingFinishScan}
          onClick={finishScanClickHandler}
        />
      ) : (
        <>
          {isRetry ? (
            <CTAContainer
              type="TWO_BUTTONS"
              onClick={(): void => setScanModalVisibility(true)}
              onBackClick={(): void => history.push(AppRoutes.INVENTORY)}
              disabledMainAction={false}
              mainButtonLabel={t('proceed')}
            />
          ) : (
            <CTAContainer
              type="FOUR_BUTTONS"
              fourthButtonLabel={t('quit')}
              thirdButtonLabel={t('retry')}
              secondButtonLabel={t('seek&found')}
              firstButtonLabel={t('applyForValidation')}
              loadingThirdButton={retryLoading}
              loadingFirstButton={updateTagsLoading}
              disabledFourthButton={retryLoading}
              disabledThirdButton={retryLoading}
              disabledSecondButton={retryLoading}
              disabledFirstButton={updateTagsLoading || retryLoading}
              onFourthClick={(): void => setQuitModalVisibility(true)}
              onThirdClick={retryHandler}
              onSecondClick={(): void => setScanModalVisibility(true)}
              onFirstClick={applyForValidationHandler}
            />
          )}
        </>
      )}
      <ErrorSnackbar
        open={reportError}
        setIsOpen={setReportError}
        errorMessage={fetchInventoryReportHasError?.body}
      />
      <ErrorSnackbar
        open={epcError}
        setIsOpen={setEpcError}
        errorMessage={t('error.inventory_add_epc')}
      />
      <ErrorSnackbar
        open={validationError}
        setIsOpen={setValidationError}
        errorMessage={t('error.validate_inventory')}
      />
      <ErrorSnackbar
        open={joinInventoryError}
        setIsOpen={setJoinInventoryError}
        errorMessage={t('error.inventory_join')}
      />
      <ErrorSnackbar
        open={quitError}
        setIsOpen={setQuitError}
        errorMessage={t('error.inventory_quit')}
      />
      <ErrorSnackbar
        open={deviceError}
        setIsOpen={setDeviceError}
        errorMessage={deviceErrorMsg}
      />
      <ErrorSnackbar
        open={retryError}
        setIsOpen={setRetryError}
        errorMessage={t('error.changeInventoryStatus')}
      />
    </>
  );
};

export default PageInventoryReport;
