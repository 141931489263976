import { useCallback, useContext, useState } from 'react';
import { useSelector } from './useSelector';
import { useAppDispatch } from '@/app/store';
import { removeDeviceInUse } from '@/features/devices/devicesSlice';
import { useSubscribeDeviceStatus } from './mqtt/useSubscribeDeviceStatus';
import { MQTTContext } from '@/context/MQTT';

const useResetDevice = (): {
  resetDevice: () => Promise<void>;
  resetDeviceError: boolean;
} => {
  const dispatch = useAppDispatch();
  const { setDeviceIdOnMqttClient } = useContext(MQTTContext);
  const device = useSelector(state => state.devices.deviceInUse);
  const subscribeDevice = useSubscribeDeviceStatus();

  const [resetDeviceError, setResetDeviceError] = useState<boolean>(false);

  const resetDevice = useCallback(async (): Promise<void> => {
    if (device) {
      try {
        setDeviceIdOnMqttClient(undefined);
        subscribeDevice.publish(device.deviceId, undefined);
        dispatch(removeDeviceInUse());
      } catch {
        setResetDeviceError(true);
      }
    }
  }, [device, dispatch, setDeviceIdOnMqttClient, subscribeDevice]);

  return {
    resetDevice,
    resetDeviceError,
  };
};

export default useResetDevice;
