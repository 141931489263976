import { FC, useEffect, useState, useMemo, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { useAsync } from 'react-use';
import { useHistory } from 'react-router-dom';
import { useReasonCodeContext } from '@/context/reasonCodeContext';
import styled from 'styled-components';
import {
  fetchGroupList,
  deleteGroup,
  deleteGroupFromState,
  initProfilingReasonCodeGroup,
} from '@/features/profiling/reasonCodeSlice';

import { UIBox } from '@/components/ui/Box';
import { UISelect } from '@/components/ui/Select';
import { UIButtonWithIcon } from '@/components/ui/Button';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { PageLoader } from '@/components/ui/PageLoader';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { ProfilingGroupList } from '@/components/layout/ProfilingGroupList';
import { ModalDataSaved } from '@/components/layout/ModalDataSaved';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';
import PublishIcon from '@material-ui/icons/Publish';

import { isReasonsUploader } from '@/utils/user';
import { AppRoutes } from '@/app/routers';
import { Group, ReasonCodeService } from '@/api/receive';
import type { ReasonCodeFilters } from '@/types/filters';
import type { SelectOption } from '@/types/selectOption';

//#region - Styled Components
const StyledFilterWrapper = styled(UIBox)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  row-gap: 8px;
  padding: 0 24px 24px;
  margin-bottom: 16px;

  & > * {
    margin: 0;
    align-self: flex-end;

    & > div {
      width: 100% !important;
    }
  }
`;

const StyledUploadWrapper = styled(UIBox)`
  position: relative;
  align-self: flex-end;
  justify-self: flex-end;
  margin-bottom: 8px;

  input {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 99999;
  }
`;
//#endregion

const PageControlReasonCodes: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const user = useSelector(state => state.user);
  const { processes } = useReasonCodeContext();
  const { groups, lastUploadDate } = useSelector(
    state => state.profiling.reasonCode.groupList
  );
  const {
    fetchGroupListIsLoading,
    fetchProcessIsLoading,
    fetchProcessHasError,
    fetchGroupListHasError,
  } = useSelector(state => state.profiling.reasonCode);

  const [groupTitle, setGroupTitle] = useState<string>();
  const [groupList, setGroupList] = useState<Group[]>([]);

  const [errorSnackbar, setErrorSnackbarVisibility] = useState<boolean>(false);
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const [uploadErrorSnackbar, setUploadErrorSnackbar] =
    useState<boolean>(false);
  const [attentionModalIsVisible, setAttentionModalVisibility] =
    useState<boolean>(false);

  const [filters, setFilters] = useState<ReasonCodeFilters>({
    groupTitle: '',
    process: 'all',
    sales: '',
  });

  useLayoutEffect(() => {
    dispatch(initProfilingReasonCodeGroup());
  }, [dispatch]);

  useAsync(async () => {
    await dispatch(fetchGroupList());
  }, [dispatch]);

  useEffect(() => {
    if (groups) {
      setGroupList(groups);
    }
  }, [groups]);

  useEffect(() => {
    if (fetchGroupListHasError) {
      setErrorSnackbarVisibility(true);
    }
  }, [fetchGroupListHasError]);

  useEffect(() => {
    if (fetchProcessHasError) {
      setErrorSnackbarVisibility(true);
    }
  }, [fetchProcessHasError]);

  useEffect(() => {
    const { groupTitle, process, sales } = filters;

    if (groups) {
      if (groupTitle !== '' || sales !== '' || process !== 'all') {
        setGroupList(() => {
          return groups.filter(
            ({ groupTitle: title, salesOrg, process: groupProcess }) => {
              const organizations = salesOrg?.map(sale => sale.toLowerCase());

              if (process !== 'all') {
                return (
                  title?.toLowerCase().includes(groupTitle.toLowerCase()) &&
                  groupProcess?.toLowerCase().includes(process.toLowerCase()) &&
                  organizations?.some(org => org.includes(sales.toLowerCase()))
                );
              }

              return (
                title?.toLowerCase().includes(groupTitle.toLowerCase()) &&
                organizations?.some(org => org.includes(sales.toLowerCase()))
              );
            }
          );
        });
      }

      if (groupTitle === '' && sales === '' && process === 'all') {
        setGroupList(groups);
      }
    }
  }, [filters, groups]);

  const processOptions: SelectOption[] = useMemo(() => {
    const options = processes.map(process => ({
      label: t(`${process}`),
      value: process,
    }));

    options.unshift({
      label: t('all'),
      value: 'all',
    });

    return options;
  }, [processes, t]);

  const onFilterChange = (
    key: keyof ReasonCodeFilters,
    value: string
  ): void => {
    setFilters(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const uploadClickHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target;

    if (files && files.length > 0) {
      const csv = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(csv);

      reader.onload = async (): Promise<void> => {
        const result = reader.result;

        if (result && typeof result === 'string') {
          try {
            await ReasonCodeService.reasoncodesLoadReasonCodesFile({
              requestBody: {
                fileName: csv.name,
                fileContent: result.split(',')[1],
              },
            });

            setFileUploaded(true);
          } catch (e) {
            setUploadErrorSnackbar(true);
          }
        }
      };

      reader.onerror = (): void => {
        setUploadErrorSnackbar(true);
      };
    }
  };

  const onRemoveGroupClick = (title: string | undefined): void => {
    setGroupTitle(title);
    setAttentionModalVisibility(true);
  };

  const onEditGroupClick = (title: string | undefined): void => {
    if (title) {
      history.push(`${AppRoutes.MANAGE_REASON_CODES}/${title}/edit`);
    }
  };

  const onConfirmRemoveClick = async (): Promise<void> => {
    if (groupTitle) {
      try {
        await dispatch(deleteGroup({ groupTitle })).unwrap();
        dispatch(deleteGroupFromState(groupTitle));
        setAttentionModalVisibility(false);
      } catch (err) {
        setErrorSnackbarVisibility(true);
      }
    }
  };

  const onModalCloseClick = (): void => {
    setAttentionModalVisibility(false);
    setGroupTitle('');
  };

  const onModalDataSavedClose = (): void => {
    setFileUploaded(false);
    window.location.reload();
  };

  if (fetchGroupListIsLoading || fetchProcessIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalDataSaved
        $minWidth="400px"
        $minHeight="160px"
        open={fileUploaded}
        onClose={onModalDataSavedClose}
        message={t('modal.csvUploaded')}
      />
      <ModalAttention
        open={attentionModalIsVisible}
        onConfirmClick={onConfirmRemoveClick}
        onClose={onModalCloseClick}
        message={t('attentionDeleteGroup')}
      />
      <StyledFilterWrapper>
        <SearchBarLight
          label=""
          value={filters.groupTitle}
          placeholder={t('profiling.filterByGroupTitle')}
          disabled={false}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onFilterChange('groupTitle', e.target.value)
          }
          loading={false}
          hideButton={true}
        />
        <UISelect
          title=""
          inputLabel={t('profiling.filterByProcess')}
          variant="outlined"
          onChange={(e: React.ChangeEvent<{ value: unknown }>): void =>
            onFilterChange('process', e.target.value as string)
          }
          value={filters.process}
          values={processOptions}
        />
        {isReasonsUploader(user) && (
          <StyledUploadWrapper>
            <input type="file" accept=".csv" onChange={uploadClickHandler} />
            <UIButtonWithIcon
              startIcon={<PublishIcon />}
              label={t('uploadCSV')}
              subLabel={
                lastUploadDate
                  ? `${t('lastUpdate')}: ${lastUploadDate}`
                  : t('noFileUploadedYet')
              }
            />
          </StyledUploadWrapper>
        )}
        <SearchBarLight
          label=""
          value={filters.sales}
          placeholder={t('profiling.filterBySalesOrganizations')}
          disabled={false}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onFilterChange('sales', e.target.value)
          }
          loading={false}
          hideButton={true}
        />
      </StyledFilterWrapper>

      <ProfilingGroupList
        groupList={groupList}
        onEditClick={onEditGroupClick}
        onRemoveClick={onRemoveGroupClick}
      />

      <CTAContainer
        type="TWO_BUTTONS"
        onClick={(): void => history.push(AppRoutes.MANAGE_REASON_CODES_CREATE)}
        onBackClick={(): void => history.push(AppRoutes.ADMIN)}
        disabledMainAction={false}
        mainButtonLabel={t('createNewGroup')}
      />

      <ErrorSnackbar
        open={errorSnackbar && !!fetchGroupListHasError}
        setIsOpen={setErrorSnackbarVisibility}
        errorMessage={fetchGroupListHasError?.body}
      />

      <ErrorSnackbar
        open={uploadErrorSnackbar}
        setIsOpen={setUploadErrorSnackbar}
        errorMessage={t('error.file_upload')}
      />

      <ErrorSnackbar
        open={errorSnackbar && !!fetchProcessHasError}
        setIsOpen={setErrorSnackbarVisibility}
        errorMessage={fetchProcessHasError?.body}
      />
    </>
  );
};

export default PageControlReasonCodes;
