import React, { useEffect, useMemo } from 'react';
import { MQTTContext } from '@/context/MQTT';

import { DeviceStatus, SubscribeDeviceStatus } from './types';

import { useSelector } from '../useSelector';
import { useAppDispatch } from '@/app/store';
import { updateMQTTDevice } from '@/features/devices/devicesSlice';

export const parseDeviceStatus = (payload: string): DeviceStatus => {
  const parts = payload.split('|');

  if (parts.length !== 6) {
    throw new Error(
      `Invalid message on topic L/device_id/S. The message shoud have 6 parts with this schema: status num_tag_mem|mode|power|battery|status|client ID, but it is: ${payload} (${parts.length} parts)`
    );
  }

  return {
    statusNumTagMem: parts[0],
    mode: parts[1],
    power: parts[2],
    battery: parts[3],
    status: parts[4],
    clientId: parts[5],
  };
};

export const useSubscribeDeviceStatus = (): SubscribeDeviceStatus => {
  const { client, newMessage } = React.useContext(MQTTContext);
  const { store } = useSelector(state => state.currentStore);
  const { username } = useSelector(state => state.user);
  const { deviceList } = useSelector(state => state.devices);

  const devicesInStore = useMemo(
    () => deviceList.map(({ deviceId }) => deviceId),
    [deviceList]
  );

  const dispatch = useAppDispatch();

  const topics: string[] = useMemo(() => {
    return devicesInStore.map(deviceId => `L/${deviceId}/S`);
  }, [devicesInStore]);

  useEffect(() => {
    if (client) {
      for (const topic of topics) {
        client.subscribe(topic, { qos: 1, rap: true }, (error: Error) => {
          if (error) {
            console.log(error);
          }
        });
      }
    }
  }, [client, topics]);

  useEffect(() => {
    topics.forEach(topic => {
      if (newMessage?.topic === topic) {
        if (store?.storeCode) {
          const { mode, status, statusNumTagMem, battery, clientId, power } =
            parseDeviceStatus(newMessage.payload);

          dispatch(
            updateMQTTDevice({
              topic,
              mode,
              status: !['free', 'null'].includes(clientId) ? 'busy' : status,
              statusNumTagMem,
              battery,
              clientId,
              power,
              userName: !['free', 'null'].includes(clientId) ? clientId : '',
              storeId: store.storeCode,
            })
          );
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, newMessage, store, username]);

  const publishDeviceLock = (
    selectedDeviceId: string,
    username: string | undefined
  ): void => {
    if (client && selectedDeviceId) {
      console.log('📱 ~ lock ~ ', selectedDeviceId, username);
      const lockTopic = `L/${selectedDeviceId}/CLIENT`;
      client.publish(lockTopic, username || 'free', { retain: true });
    }
  };

  const braodcasting = (message: string): void => {
    if (client) {
      topics.forEach(topic => {
        client.publish(topic, message, { retain: true });
      });
    }
  };

  return {
    publish: publishDeviceLock,
    broadcasting: braodcasting,
  };
};
