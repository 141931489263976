import { FC } from 'react';
import styled from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';

import Link from '@material-ui/core/Link';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface UIListHeaderProps {
  text?: string;
  linkText?: string;
  extraText?: string;
  disableLink?: boolean;
  onLinkClick?: () => void;
  className?: string;
  middleComponent?: JSX.Element;
  rightComponent?: JSX.Element;
}

const StyledBrandsHeader = styled(UIBox)<CSSProperties>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 14px 0;
  padding: ${({ padding }): string => padding && padding};
  align-items: center;
`;

const StyledBrandsHeaderLeft = styled(UIBox)`
  display: flex;
  align-items: center;

  button {
    margin-right: 18px;
  }
`;

export const UIListHeader: FC<UIListHeaderProps & CSSProperties> = ({
  text,
  linkText,
  extraText,
  onLinkClick,
  rightComponent,
  middleComponent,
  className,
  disableLink,
  ...cssProps
}) => {
  return (
    <StyledBrandsHeader className={className} {...cssProps}>
      <StyledBrandsHeaderLeft>
        {linkText && (
          <Link
            component="button"
            variant="body2"
            underline="always"
            onClick={onLinkClick}
            disabled={disableLink}
            color={disableLink ? 'textSecondary' : 'primary'}
            style={{
              cursor: disableLink ? 'auto' : 'pointer',
            }}
          >
            {linkText}
          </Link>
        )}
        <Typography font="heavy" size="sm">
          {text}
        </Typography>
        {middleComponent}
      </StyledBrandsHeaderLeft>
      {extraText && (
        <Typography font="heavy" size="sm">
          {extraText}
        </Typography>
      )}
      {rightComponent}
    </StyledBrandsHeader>
  );
};

UIListHeader.displayName = 'UIListHeader';

export default UIListHeader;
