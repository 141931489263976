import {
  CycleDetailsItems,
  ProcessService,
  ProcessStatus,
  StockOnHandAlignmentService,
  StockOnHandItems,
} from '@/api/receive';
import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { PageLoader } from '@/components/ui/PageLoader';
import {
  initCycleCountState,
  setProcessLocked,
} from '@/features/cycleCount/cycleCountSlice';
import useHandleProcess from '@/hooks/useHandleProcess';
import { useSelector } from '@/hooks/useSelector';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAsync } from 'react-use';
import * as S from './style';
import { useSignalRContext, MessageBody } from '@/context/signalR';
import RecapItem from './RecapItem';
import { Typography } from '@/components/ui/Typography';
import { UIBox } from '@/components/ui/Box';
import { ModalDataSavedError as Modal } from '@/components/layout/ModalDataSaved';
import { isSohSuperUser } from '@/utils/user';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import { EnumMode } from '@/types/enum';
import useResetDevice from '@/hooks/useResetDevice';
import { initMQTTTags } from '@/features/devices/devicesSlice';

export type SOH = {
  epcs: string[];
  upcs: string[];
  brands: string[];
  commodities: string[];
  products: string;
  owner: string;
  status: string;
};

export type ReportData = {
  id: string;
  report: 'APP' | 'SAP';
};

const PageSohAlignment: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleProcess = useHandleProcess();
  const signalr = useSignalRContext();
  const { resetDevice } = useResetDevice();
  const isSohProcessLocked = useSelector(state => state.cycleCount.sohLocked);
  const deviceInUse = useSelector(state => state.devices.deviceInUse);
  const user = useSelector(state => state.user);
  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );

  const [recap, setRecap] = React.useState<StockOnHandItems[]>([]);
  const [id, setId] = React.useState<string>('');
  const [notFoundEpcs, setNotFoundEpcs] = React.useState<{ epc: string }[]>([]);
  const [onGoingRecap, setOngoingRecap] = React.useState<StockOnHandItems[]>(
    []
  );
  const [fetchRecapError, setFetchRecapError] = React.useState<boolean>(false);
  const [openScanModal, setScanModalVisibility] = React.useState(false);
  const [sohaSuperUser, setSohaSuperUser] = React.useState<string>();
  const [onGoingActivities, setOnGoingActivities] =
    React.useState<boolean>(false);
  const [sohaWentWrongModal, setSohaWentWrongModal] =
    React.useState<boolean>(false);
  const [sohaLockedBySuperUserModal, setSohaLockedBySuperUserModal] =
    React.useState<boolean>(false);
  const [fetchRecapLoading, setFetchRecapLoading] =
    React.useState<boolean>(false);
  const [processLockedBy, setProcessLockedBy] = React.useState<string>('');

  const fetchSohRecap = React.useCallback(async () => {
    try {
      setFetchRecapLoading(true);

      const response =
        await StockOnHandAlignmentService.stockonhandRecapSohAlignment({
          storeCode,
        });

      if (response && response.stockOnHandItemsList.length > 0) {
        setRecap(
          response.stockOnHandItemsList.filter(
            ({ stockOnHandStatus }) => stockOnHandStatus !== 'ONGOING'
          )
        );

        setOngoingRecap(
          response.stockOnHandItemsList.filter(
            ({ stockOnHandStatus }) => stockOnHandStatus === 'ONGOING'
          )
        );
      }

      setFetchRecapLoading(false);
    } catch {
      setFetchRecapLoading(false);
      setFetchRecapError(true);
    }
  }, [storeCode]);

  useAsync(async () => {
    dispatch(initMQTTTags());

    if (deviceInUse) {
      await resetDevice();
    }
  }, []);

  useAsync(async () => {
    if (isSohProcessLocked) {
      await handleProcess({ process: 'SOHA', isLocked: false });
      dispatch(initCycleCountState());
    }
  }, [isSohProcessLocked]);

  useAsync(async () => {
    await fetchSohRecap();
  }, []);

  const onListItemClick = async (id: string): Promise<void> => {
    try {
      const { data } = (await signalr.retrieveStatus(
        'SOHA'
      )) as MessageBody<SOH>;

      const sohProcess: ProcessStatus[] =
        await ProcessService.processReadProcessStatus({
          storeCode,
          process: 'SOHA',
          filterByStoreCode: true,
        });

      if (data) {
        if (data.owner === user.username) {
          await handleProcess({ process: 'SOHA', isLocked: true });
          history.push(`${AppRoutes.SOH_ALIGNMENT_FILTER_PRODUCTS}?id=${id}`);
          dispatch(setProcessLocked({ process: 'SOHA', isLocked: true }));
        } else {
          if (data.status === 'PENDING') {
            return history.push(AppRoutes.INTRO);
          }

          const notFound: CycleDetailsItems[] = JSON.parse(
            Buffer.from(data.products, 'base64').toString()
          ).notFound;

          const notFoundEpcs = notFound
            .flatMap(({ epcCodes }) => epcCodes)
            .map(({ epcCode }) => ({ epc: epcCode }));

          setId(id);
          setNotFoundEpcs(notFoundEpcs);
          setScanModalVisibility(true);
        }
      } else {
        const soha = sohProcess[0];

        if (soha && soha.isLocked) {
          if (isSohSuperUser(user) && user.username === soha.lockedBy) {
            await StockOnHandAlignmentService.stockonhandDeleteStockOnHand({
              storeCode,
              stockOnHandId: id,
            });

            await handleProcess({ process: 'SOHA', isLocked: false });

            setSohaWentWrongModal(true);
          } else {
            setSohaSuperUser(soha.lockedBy);
            setSohaLockedBySuperUserModal(true);
          }
        }
      }
    } catch {}
  };

  const onCreateNewClick = async (): Promise<void> => {
    try {
      await handleProcess({ process: 'SOHA', isLocked: true });
      history.push(AppRoutes.SOH_ALIGNMENT_VERIFY);
      dispatch(setProcessLocked({ process: 'SOHA', isLocked: true }));
    } catch {
      setOnGoingActivities(true);

      const exceptionProcess = await ProcessService.processReadProcessStatus({
        storeCode,
        process: 'SOHA',
        filterByStoreCode: true,
      });

      if (exceptionProcess && exceptionProcess[0].isLocked) {
        setProcessLockedBy(exceptionProcess[0].lockedBy);
      }
    }
  };

  const onReportClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    { id, report }: ReportData
  ): void => {
    event.stopPropagation();
    history.push(`${AppRoutes.SOH_ALIGNMENT_REPORT}?id=${id}&report=${report}`);
  };

  return (
    <>
      {fetchRecapLoading ? (
        <PageLoader />
      ) : (
        <>
          <ModalScanDetailsV2
            defaultMode={EnumMode.INVENTORY}
            open={openScanModal}
            onClose={(): void => setScanModalVisibility(false)}
            tags={notFoundEpcs}
            onStartReader={(): void => {
              history.push(
                `${AppRoutes.SOH_ALIGNMENT_FILTER_PRODUCTS}?id=${id}`
              );
              dispatch(setProcessLocked({ process: 'SOHA', isLocked: true }));
            }}
          />
          <Modal
            iconType="ERROR"
            $minWidth="420px"
            $minHeight="230px"
            title={t('tryAgain')}
            message={t('onGoingActivitiesMessage', { user: processLockedBy })}
            open={onGoingActivities}
            onClose={(): void => {
              setOnGoingActivities(false);
              setProcessLockedBy('');
            }}
          />
          <Modal
            iconType="ERROR"
            $minWidth="420px"
            $minHeight="230px"
            title={t('error.something_went_wrong')}
            message={t('sohaWentWrongMessage')}
            open={sohaWentWrongModal}
            onClose={async (): Promise<void> => {
              setSohaWentWrongModal(false);
              await fetchSohRecap();
            }}
          />
          <Modal
            iconType="ERROR"
            $minWidth="420px"
            $minHeight="230px"
            title={t('error.something_went_wrong')}
            message={t('sohaLockedBySuperUserMessage', { user: sohaSuperUser })}
            open={sohaLockedBySuperUserModal}
            onClose={(): void => {
              setSohaLockedBySuperUserModal(false);
              setSohaSuperUser(undefined);
            }}
          />
          <UIBox width="100%" flexDirection="column" p={3}>
            {onGoingRecap.length > 0 && (
              <>
                <UIBox mb={1}>
                  <Typography size="lg" font="heavy">
                    {t('onGoingSoh')}
                  </Typography>
                </UIBox>
                <S.List mb="48px">
                  {onGoingRecap.map(item => (
                    <RecapItem
                      key={item.id}
                      onClick={onListItemClick}
                      onReportClick={onReportClick}
                      {...item}
                    />
                  ))}
                </S.List>
              </>
            )}
            {recap.length > 0 && (
              <>
                <UIBox mb={1}>
                  <Typography size="lg" font="heavy">
                    {t('pastSoh')}
                  </Typography>
                </UIBox>
                <S.List>
                  {recap.map(item => (
                    <RecapItem
                      key={item.id}
                      onClick={onListItemClick}
                      onReportClick={onReportClick}
                      {...item}
                    />
                  ))}
                </S.List>
              </>
            )}
          </UIBox>
          <CTAContainer
            type="TWO_BUTTONS"
            mainButtonLabel={t('createNew')}
            onBackClick={(): void => history.push(AppRoutes.COUNTING_ITEMS)}
            disabledMainAction={
              !isSohSuperUser(user) || onGoingRecap.length > 0
            }
            onClick={onCreateNewClick}
          />
        </>
      )}
      <ErrorSnackbar
        open={fetchRecapError}
        setIsOpen={setFetchRecapError}
        errorMessage={t('error.fetchSohRecap')}
      />
    </>
  );
};

export default PageSohAlignment;
