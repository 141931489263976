import React, { useState } from 'react';
import { UserProfile } from '@/api/receive';
import { PaginationList } from '@/components/layout/PaginationList';
import { ModalAttention } from '@/components/layout/ModalAttention';

import { Typography } from '@/components/ui/Typography';

import {
  removeUser,
  removeUserFromRedux,
} from '@/features/profiling/usersSlice';
import { useAppDispatch } from '@/app/store';
import { useTranslation } from 'react-i18next';
import ProfilingUsersRow from './ProfilingUsersRow';
import { StyledGrid, StyledGridHeader } from './style';
import { ProfilingUserListProps, TUserSelect } from './types';
import { useUpdateEffect } from 'react-use';

const ProfilingUsersList: React.FC<ProfilingUserListProps> = ({
  users,
  $showPartial,
  setAlertSnackbarVisibility,
  exportUsersSelected,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [id, setUserId] = useState<string>();
  const [userSelected, setUserSelected] = useState<TUserSelect | undefined>();
  const [usersSelected, setUsersSelected] = useState<{ [k: string]: boolean }>(
    {}
  );

  const [attentionModalIsVisible, setAttentionModalVisibility] =
    useState<boolean>(false);

  const onModalCloseClick = (): void => {
    setAttentionModalVisibility(false);
    setUserId(undefined);
  };

  useUpdateEffect(() => {
    if (userSelected) {
      setUsersSelected(prev => ({
        ...prev,
        ...{ [userSelected.user]: userSelected.checked },
      }));
    }
  }, [userSelected]);

  useUpdateEffect(() => {
    exportUsersSelected?.(
      Object.keys(usersSelected).filter(k => usersSelected[k])
    );
  }, [usersSelected]);

  const onConfirmRemoveClick = async (): Promise<void> => {
    try {
      if (id) {
        await dispatch(removeUser({ userId: id })).unwrap();
        dispatch(removeUserFromRedux(id));
        setAttentionModalVisibility(false);
      }
    } catch (err) {
      setAlertSnackbarVisibility?.(true);
    }
  };

  return (
    <>
      <ModalAttention
        open={attentionModalIsVisible}
        onConfirmClick={onConfirmRemoveClick}
        onClose={onModalCloseClick}
        message={t('attentionDeleteUser')}
      />
      <StyledGrid>
        <StyledGridHeader $showPartial={$showPartial}>
          <Typography font="heavy" align="right">
            {t('userId')}
          </Typography>
          <Typography font="heavy">{t('fullName')}</Typography>
          <Typography font="heavy">{t('role')}</Typography>
          {!$showPartial ? (
            <>
              <Typography font="heavy">{t('salesOrganization')}</Typography>
              <Typography font="heavy">{t('defaultStore')}</Typography>
            </>
          ) : (
            <Typography font="heavy">{t('jobTitle')}</Typography>
          )}
          <Typography font="heavy">{t('statusUser')}</Typography>
        </StyledGridHeader>

        <PaginationList
          data={users}
          pageSize={20}
          renderItem={(user: UserProfile): JSX.Element =>
            user && (
              <ProfilingUsersRow
                {...user}
                key={user.userId}
                setUserId={setUserId}
                $showPartial={$showPartial}
                setAttentionModalVisibility={setAttentionModalVisibility}
                exportUserSelected={setUserSelected}
                checked={usersSelected?.[user?.userId || ''] || false}
              />
            )
          }
        />
      </StyledGrid>
    </>
  );
};

export default ProfilingUsersList;
