import { FC, memo, useState } from 'react';
import styled from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { ProductImage } from '@/components/ui/ProductImage';
import { Typography } from '@/components/ui/Typography';
import { Chip, makeStyles } from '@material-ui/core';

import { Product } from '@/types/product';
import { RecapConfirmList } from '@/api';
import { useTranslation } from 'react-i18next';
import { palette } from '@/theme';
import { ModalReceiptInfo } from '@/components/layout/ModalReceiptInfo';

//#region styled-components
export const StyledDeliveryRecapProductItem = styled(UIBox)<{
  onClick?: (e: React.MouseEvent, product: Product) => void;
}>`
  width: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  cursor: ${({ onClick }): string => (onClick ? 'pointer' : 'auto')};

  &:not(:last-of-type) {
    border-bottom: 1px solid #ededed;
  }
`;

const StyledDeliveryProductInfo = styled(UIBox)`
  position: relative;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    left: -24px;
    height: 100%;
    width: 8px;
    background: #ededed;
    border-radius: 50px;
  }
`;

const StyledDeliveryProductImage = styled(UIBox)`
  max-width: 80px;
  max-height: 60px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ededed;
`;

const StyledDeliveryProductDescription = styled.div`
  margin-left: 16px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;

  .epc-code {
    color: ${palette.colors.grey};

    ${({ theme }): string =>
      `${theme.breakpoints.down('sm')} {
            display: block;
            margin-top: 8px;
        }`}
  }

  p {
    margin: 0;

    &:last-child {
      margin-top: 8px;
      letter-spacing: 0.8px;
    }
  }
`;

// const StyledTitle = styled.div`
//   & h1 {
//     position: absolute;
//     right: 0px;
//     top: -20px;
//   }
// `;

const StyledDeliveryStockRight = styled.div`
  ${({ theme }): string =>
    `display: flex;
		align-items: center;
		justify-content: space-around;
		flex-wrap: wrap;
		text-align: right;
		width: 350px;
		${theme.breakpoints.down('sm')} {
			width: 200px;
		}
	`}
`;

//#endregion

//#region types
type RecapConfirmItem = Required<RecapConfirmList>['productDetailsItems'][0];

export interface DeliveryRecapProductItemProps extends RecapConfirmItem {
  cardTitle?: string;
}
//#endregion

const useStyles = makeStyles({
  chip: {
    height: '50%',
    marginLeft: '24px',
    backgroundColor: '#005192',
    color: 'white',
    marginTop: '-4px',
  },
  chipLabel: {
    fontWeight: 'normal',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
});

export const DeliveryRecapProductItem: FC<DeliveryRecapProductItemProps> = memo(
  ({
    brandDescription,
    brandCode,
    colorCode,
    commodity,
    modelCode,
    sizeCode,
    styleName,
    upcCode,
    quantityStock,
    quantityConfirm,
    showBeforeReceipt,
    imageUrl,
  }): JSX.Element => {
    const classes = useStyles();

    const [isModalRecapVisible, setModalRecapVisible] =
      useState<boolean>(false);

    const toggleModalRecapVisibility = (): void => {
      setModalRecapVisible(wasPreviouslyVisible => !wasPreviouslyVisible);
    };

    const { t } = useTranslation();

    return (
      <>
        <StyledDeliveryRecapProductItem>
          <StyledDeliveryProductInfo>
            <StyledDeliveryProductImage>
              <ProductImage
                imageUrl={imageUrl}
                colorCode={colorCode}
                modelCode={modelCode}
                brandCode={brandCode}
                width={80}
                height={60}
              />
            </StyledDeliveryProductImage>

            <StyledDeliveryProductDescription>
              <div>
                <Typography size="lg" font="heavy" as="h1">
                  {brandDescription} - {sizeCode} {commodity} {colorCode}{' '}
                  {styleName}
                  {showBeforeReceipt && (
                    <Chip
                      label={t('new')}
                      classes={{
                        root: classes.chip,
                        label: classes.chipLabel,
                      }}
                    />
                  )}
                </Typography>
                <Typography size="md" font="default" as="p">
                  {upcCode} - {modelCode} - {brandCode}
                </Typography>
              </div>

              <StyledDeliveryStockRight>
                <Typography size="xl" font="heavy" as="h1">
                  {quantityConfirm}
                </Typography>
                <Typography size="xl" font="heavy" as="h1">
                  {quantityStock}
                </Typography>
                {showBeforeReceipt && (
                  <Typography
                    size="xs"
                    font="default"
                    as="p"
                    style={{ width: '100%' }}
                  >
                    *{t('receiptInfo')}
                  </Typography>
                )}
              </StyledDeliveryStockRight>
            </StyledDeliveryProductDescription>
          </StyledDeliveryProductInfo>
        </StyledDeliveryRecapProductItem>
        <ModalReceiptInfo
          open={isModalRecapVisible}
          onClose={toggleModalRecapVisibility}
        />
      </>
    );
  }
);

DeliveryRecapProductItem.displayName = 'DeliveryRecapProductItem';

export default DeliveryRecapProductItem;
