import { ReceiveShipmentsService, ReceiveApiError as ApiError } from '@/api';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  EpcSelected,
  ScannedProduct,
  ScannedProductList,
  ScannedProductsSlice,
} from '@/types/product';
import getCurrentDate from '@/utils/getCurrentDate';
import type { RootState } from '@/app/rootReducer';
import type {
  AddEpcRequest,
  ConfirmDelivery,
  PrintTag,
  ProductDetail,
  ProductDetailEpc,
  RemoveEpcRequest,
  SearchDeliveryListScannedResponse,
} from '@/api/receive';

const initialProductList: ScannedProductList = { epcs: [], qty: 0, list: [] };

const initialState: ScannedProductsSlice = {
  isLoading: false,
  error: undefined,
  products: {
    found: initialProductList,
    missing: initialProductList,
    unexpected: initialProductList,
    sold: initialProductList,
    invalidProduct: [],
  },
  unexpectedEpcs: [],
  unexpectedAdded: 0,
  epcCodesProductsNotFound: [],
  ui: {
    disableScanAgain: false,
  },
};

const sortProductList = (list: ProductDetail[]): ScannedProduct[] => {
  return list.sort((a, b) => {
    if (a.modelCode && b.modelCode) {
      return (
        a.brandCode?.localeCompare(b.brandCode!) ||
        a.modelCode.localeCompare(b.modelCode)
      );
    }

    return a.brandCode!.localeCompare(b.brandCode!);
  });
};

export const getScannedProducts = createAsyncThunk<
  SearchDeliveryListScannedResponse,
  string[],
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'scannedProducts/getScannedProducts',
  async (epcCodes, { getState, rejectWithValue }) => {
    const {
      deliveries: { selectedDeliveries },
      currentStore: { store },
    } = getState();
    const date = getCurrentDate();

    if (selectedDeliveries.length > 0) {
      try {
        const deliveryCodes = selectedDeliveries.map(
          ({ codeDelivery }) => codeDelivery
        );

        const response =
          await ReceiveShipmentsService.receiveshipSearchDeliveryListScanned({
            requestBody: {
              deliveryCodes,
              epcCodes,
              date,
              storeCode: store!.storeCode!,
            },
          });
        if (response) {
          return response;
        }

        return {
          found: initialProductList,
          missing: initialProductList,
          sold: initialProductList,
          unexpected: initialProductList,
          invalidProduct: [],
        };
      } catch (error) {
        return rejectWithValue(error as ApiError);
      }
    }

    return {
      found: initialProductList,
      missing: initialProductList,
      sold: initialProductList,
      unexpected: initialProductList,
      invalidProduct: [],
    };
  }
);

export const printReceivingProductTag = createAsyncThunk<
  void,
  { epcCode: string; upcCode: string },
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'scannedProducts/printScannedProduct',
  async ({ epcCode, upcCode }, { getState, rejectWithValue }) => {
    const {
      deliveries: { selectedDeliveries },
    } = getState();
    const storeCode = getState().currentStore.store?.storeCode;
    const deliveryNumbers = selectedDeliveries.map(
      ({ codeDelivery }) => codeDelivery
    );

    try {
      if (storeCode) {
        const requestBody: PrintTag = {
          deliveryNumbers,
          epcCode,
          storeCode,
          upcCode,
        };

        await ReceiveShipmentsService.receiveshipPrintTag({
          requestBody,
        });
      }
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  }
);

export const removeReceivingEpcs = createAsyncThunk<
  void,
  ProductDetailEpc[],
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'scannedProducts/removeReceivingEpcs',
  async (epcCodes, { getState, rejectWithValue }) => {
    const storeCode = getState().currentStore.store?.storeCode;

    try {
      if (storeCode) {
        const requestBody: RemoveEpcRequest = {
          epcCodes,
          storeCode,
        };

        await ReceiveShipmentsService.receiveshipRemoveEpcEncoded({
          requestBody: {
            removeEncodedEpc: btoa(JSON.stringify(requestBody)),
          },
        });
      }
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  }
);

export const addReceivingEpcs = createAsyncThunk<
  void,
  EpcSelected[] | undefined,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'scannedProducts/addReceivingEpcs',
  async (epcs, { getState, rejectWithValue }) => {
    const {
      deliveries: { selectedDeliveries },
    } = getState();
    const storeCode = getState().currentStore.store?.storeCode;
    const epcCodes = epcs?.flatMap(({ epcCodes }) => epcCodes);
    // getState().scannedProducts.unexpectedEpcs.flatMap(
    //   ({ epcCodes }) => epcCodes
    // );
    const deliveryNumbers = selectedDeliveries.map(
      ({ codeDelivery }) => codeDelivery
    );

    try {
      if (storeCode) {
        const requestBody: AddEpcRequest = {
          epcCodes: epcCodes?.map(item => ({
            epcCode: item.epcCode,
            isDecoded: item.isDecoded,
          })),
          storeCode,
          deliveryNumbers,
        };

        await ReceiveShipmentsService.receiveshipAddEpcEncoded({
          requestBody: {
            addEpcEncoded: btoa(JSON.stringify(requestBody)),
          },
        });
      }
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  }
);

export const confirmDelivery = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'scannedProducts/confirmDelivery',
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        deliveries: { selectedDeliveries },
        scannedProducts: {
          products: {
            found: { list },
          },
        },
      } = getState();
      const storeCode = getState().currentStore.store?.storeCode;
      const deliveryCodes = selectedDeliveries.map(
        ({ codeDelivery }) => codeDelivery
      );
      const found =
        list
          ?.flatMap(({ epcCodes }) => epcCodes!)
          .map(({ epcCode }) => epcCode!) || [];

      if (storeCode) {
        const requestBody: ConfirmDelivery = {
          storeCode,
          deliveryCodes,
          found,
        };

        await ReceiveShipmentsService.receiveshipConfirmDeliveryEncoded({
          requestBody: {
            confirmDelivery: btoa(JSON.stringify(requestBody)),
          },
        });
      }
    } catch (e) {
      return rejectWithValue(e as ApiError);
    }
  }
);

const scannedProductsSlice = createSlice({
  name: 'scannedProducts',
  initialState,
  reducers: {
    addEpcCodesNotFound: (state, action: PayloadAction<string[]>) => {
      state.epcCodesProductsNotFound = [
        ...new Set([...state.epcCodesProductsNotFound, ...action.payload]),
      ];
    },
    setDisableScanAgain: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.disableScanAgain = payload;
    },
    removeEpcsNotDecoded: (
      state,
      { payload }: PayloadAction<EpcSelected[]>
    ) => {
      const unexpected = state.products.unexpected.list;

      if (unexpected) {
        for (const product of payload) {
          const upcIndex = unexpected.findIndex(
            ({ upcCode }) => upcCode === product.upcCode
          );

          if (upcIndex !== -1) {
            product.epcCodes.forEach(product => {
              const epcIndex = unexpected[upcIndex].epcCodes?.findIndex(
                ({ epcCode }) => epcCode === product.epcCode
              );

              if (epcIndex !== -1) {
                unexpected[upcIndex].epcCodes?.splice(epcIndex!, 1);
              }
            });

            if (unexpected[upcIndex].epcCodes?.length === 0) {
              unexpected.splice(upcIndex, 1);
            }
          }
        }
      }
    },
    setSelectedAll: (state, { payload }: PayloadAction<EpcSelected[]>) => {
      const alreadySelected = state.unexpectedEpcs.length === payload.length;

      if (alreadySelected) {
        state.unexpectedEpcs = [];
      } else {
        state.unexpectedEpcs = payload;
      }
    },
    setUpcSelected: (state, { payload }: PayloadAction<EpcSelected>) => {
      const upcIndex = state.unexpectedEpcs.findIndex(
        ({ upcCode }) => upcCode === payload.upcCode
      );

      if (upcIndex !== -1) {
        const allEpcSelected =
          state.unexpectedEpcs[upcIndex].epcCodes.length ===
          payload.epcCodes.length;

        if (allEpcSelected) {
          state.unexpectedEpcs.splice(upcIndex, 1);
        } else {
          state.unexpectedEpcs[upcIndex].epcCodes = payload.epcCodes;
        }
      } else {
        state.unexpectedEpcs = [...state.unexpectedEpcs, payload];
      }
    },
    setEpcSelected: (
      state,
      {
        payload,
      }: PayloadAction<{ upcCode: string; epcCode: string; isDecoded: boolean }>
    ) => {
      const upcIndex = state.unexpectedEpcs.findIndex(
        ({ upcCode }) => upcCode === payload.upcCode
      );

      if (upcIndex !== -1) {
        const epcCodes = state.unexpectedEpcs[upcIndex].epcCodes;
        const epcIndex = epcCodes.findIndex(
          ({ epcCode }) => epcCode === payload.epcCode
        );

        if (epcIndex !== -1) {
          epcCodes.splice(epcIndex, 1);
        } else {
          epcCodes.push({
            epcCode: payload.epcCode,
            isDecoded: payload.isDecoded,
          });
        }
        if (state.unexpectedEpcs[upcIndex].epcCodes.length === 0) {
          state.unexpectedEpcs.splice(upcIndex, 1);
        }
      } else {
        state.unexpectedEpcs = [
          ...state.unexpectedEpcs,
          {
            upcCode: payload.upcCode,
            epcCodes: [
              {
                epcCode: payload.epcCode,
                isDecoded: payload.isDecoded,
              },
            ],
          },
        ];
      }
    },
    initSelectedEpcs: state => {
      state.unexpectedEpcs = [];
    },
    incrementUnexpected: (state, { payload }: PayloadAction<number>) => {
      state.unexpectedAdded = state.unexpectedAdded + payload;
    },
    initScannedProducts: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getScannedProducts.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getScannedProducts.fulfilled, (state, { payload }) => {
        if (payload) {
          state.products.unexpected = payload.unexpected || initialProductList;

          state.products.missing =
            {
              qty: payload.missing?.qty,
              list: sortProductList(payload.missing?.list || []),
              epcs: payload.missing?.epcs,
            } || initialProductList;

          state.products.found =
            {
              qty: payload.found?.qty,
              list: sortProductList(payload.found?.list || []),
            } || initialProductList;

          state.products.sold =
            {
              qty: payload.sold?.qty,
              list: sortProductList(payload.sold?.list || []),
            } || initialProductList;

          state.products.invalidProduct = payload.invalidProduct
            ? [...payload.invalidProduct]
            : [];
        }

        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(getScannedProducts.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(printReceivingProductTag.pending, state => {
        state.printEpcHasError = undefined;
      })
      .addCase(printReceivingProductTag.fulfilled, state => {
        state.printEpcHasError = undefined;
      })
      .addCase(printReceivingProductTag.rejected, (state, { payload }) => {
        state.printEpcHasError = payload;
      })
      .addCase(removeReceivingEpcs.pending, state => {
        state.removeEpcHasError = undefined;
      })
      .addCase(removeReceivingEpcs.fulfilled, state => {
        state.removeEpcHasError = undefined;
      })
      .addCase(removeReceivingEpcs.rejected, (state, { payload }) => {
        state.removeEpcHasError = payload;
      })
      .addCase(addReceivingEpcs.pending, state => {
        state.addEpcHasError = undefined;
      })
      .addCase(addReceivingEpcs.fulfilled, state => {
        state.addEpcHasError = undefined;
      })
      .addCase(addReceivingEpcs.rejected, (state, { payload }) => {
        state.addEpcHasError = payload;
      })
      .addCase(confirmDelivery.pending, state => {
        state.confirmDeliveryIsLoading = true;
        state.confirmDeliveryHasError = undefined;
      })
      .addCase(confirmDelivery.fulfilled, state => {
        state.confirmDeliveryIsLoading = false;
        state.confirmDeliveryHasError = undefined;
      })
      .addCase(confirmDelivery.rejected, (state, { payload }) => {
        state.confirmDeliveryIsLoading = false;
        state.confirmDeliveryHasError = payload;
      });
  },
});

export const {
  incrementUnexpected,
  addEpcCodesNotFound,
  setDisableScanAgain,
  removeEpcsNotDecoded,
  setSelectedAll,
  setUpcSelected,
  setEpcSelected,
  initSelectedEpcs,
  initScannedProducts,
} = scannedProductsSlice.actions;
export default scannedProductsSlice.reducer;
