import React from 'react';
import { useTranslation } from 'react-i18next';
import { UIModal, UIModalProps } from '@/components/ui/Modal';
import { EditExceptionModalProps } from './types';
import { HighlightOff } from '@material-ui/icons';

import * as S from './style';
import { Typography } from '@/components/ui/Typography';
import { FormInputs } from '@/types/hookFormInput';
import { useForm } from 'react-hook-form';
import { UIButton } from '@/components/ui/Button';
import { UIBox } from '@/components/ui/Box';
import { UIAction } from '@/components/ui/Action';

const EditExceptionModal: React.FC<
  EditExceptionModalProps & Omit<UIModalProps, 'children'>
> = ({ ...props }) => {
  const { t } = useTranslation();

  const {
    control,
    formState,
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: props.defaultValues || undefined,
  });

  React.useEffect(() => {
    if (!props.open) {
      reset(undefined);
    }
  }, [props.open, reset]);

  React.useEffect(() => {
    if (!formState.isDirty && props.defaultValues) {
      reset(props.defaultValues);
    }
  }, [formState.isDirty, props.defaultValues, reset]);

  const onSubmit = async (): Promise<void> => {
    let values = {};

    props.fields.forEach(field => {
      values = {
        ...values,
        [field]: watch(field),
      };
    });

    await handleSubmit((): Promise<void> | void =>
      props.handleFormSubmit(values)
    )();
  };

  const pattern = (name: string): RegExp | undefined => {
    switch (name) {
      case 'dateOfTransaction':
        return /^(\d{4}-(0\d|1[0-2])-(0\d|1\d|2\d|3[0-1])) ((0[1-9]|1\d|2[0-3]):([0-5]\d):([0-5]\d))([.]\d{3})$/;
      case 'tagCode':
        return /^[0-9A-Fa-f]+$/;
      default:
        return undefined;
    }
  };

  return (
    <UIModal {...props}>
      <S.Container>
        <S.CloseIcon onClick={(): void => props.onClose?.({}, 'backdropClick')}>
          <HighlightOff color={'primary'} />
        </S.CloseIcon>
        <S.SubContainer>
          <S.Title>
            <Typography font="heavy" size="lg">
              {t('changeFieldsToCorrect')}
            </Typography>
          </S.Title>
          <S.FieldsGrid>
            {props.fields.map(name => (
              <UIBox width="100%" flex="1" alignItems="center">
                <S.Field
                  name={name}
                  label={
                    name === 'dateOfTransaction'
                      ? '(Ex: 2023-05-02 11:16:43.511)'
                      : undefined
                  }
                  control={control}
                  value={watch(name)}
                  placeholder={
                    name === 'dateOfTransaction'
                      ? 'YYYY-MM-DD HH:MM:SS.MS'
                      : undefined
                  }
                  rules={{
                    required: props.requiredFields?.includes(name),
                    pattern: pattern(name),
                  }}
                  isErrorVisible={!!formState.errors[name]}
                  errorType={formState.errors[name]?.type}
                  required={props.requiredFields?.includes(name)}
                />
                {name === 'reasonCode' && (
                  <UIBox mt={2} ml={3}>
                    <UIAction
                      label={t('remove')}
                      icon="delete"
                      //@ts-ignore
                      onClick={(): void => setValue('reasonCode', '')}
                    />
                  </UIBox>
                )}
              </UIBox>
            ))}
          </S.FieldsGrid>
          <UIBox
            mt={5}
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <UIButton
              label={t('save')}
              onClick={onSubmit}
              disabled={
                props.requiredFields?.some(field => !getValues(field)) ||
                // In void sale exception tab reason code must be deleted
                (!props.requiredFields?.includes('reasonCode')
                  ? getValues('reasonCode')
                  : false) ||
                false
              }
            />
          </UIBox>
        </S.SubContainer>
      </S.Container>
    </UIModal>
  );
};

export default EditExceptionModal;
