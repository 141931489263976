import React from 'react';

import { UIList } from '@/components/ui/List';
import ProductListItem from '../ProductListItem';

import * as S from './style';

import { ProductListProps } from './types';
import { PaginationList } from '@/components/layout/PaginationList';
import { CustomReturnPosItem } from '@/types/returnPos';

const ProductList: React.FC<ProductListProps> = ({
  products,
  type,
  onPrintClick,
  onRemoveClick,
}) => {
  return (
    <>
      <S.UIListWrapper>
        <UIList
          margin={[0, 0, 150]}
          padding={[0, 24]}
          bordered
          backgrounded
          rounded
        >
          <PaginationList
            data={products}
            pageSize={10}
            renderItem={(product: CustomReturnPosItem): JSX.Element => (
              <ProductListItem
                {...product}
                type={type}
                onPrintClick={onPrintClick}
                onRemoveClick={onRemoveClick}
              />
            )}
          />
        </UIList>
      </S.UIListWrapper>
    </>
  );
};

export default ProductList;
