import React, { useCallback, useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useRecallContext } from '@/context/recallContext';
import { useAppDispatch } from '@/app/store';

import { ProductAccordionItem } from '@/components/layout/ProdocutAccordionItem';
import { UIBox } from '@/components/ui/Box';
import { UICheckbox } from '@/components/ui/Checkbox';
import { UIAction } from '@/components/ui/Action';
import { AlertUndoSnackbar } from '@/components/ui/AlertSnackbar';

import RecallListItemInfo from '../RecallListItem/RecallListItemInfo';
import { removeFoundTags } from '@/features/recall/recallSlice';

import {
  StyledProductAccordion,
  StyledProductItemWrapper,
  StyledAccortionItemContent,
  StyledMessageWrapper,
  StyledMessage,
} from '../style';

import type { CustomRecallUpc } from '@/types/recallSlice';
import type { RecallItems } from '@/pages/PageRecall/PageRecallOrder';
import { removeTags } from '@/features/devices/devicesSlice';

const RecallErrorListItem: React.FC<
  CustomRecallUpc & { isGettingTags: boolean }
> = ({ isGettingTags, ...productInfo }) => {
  const { t } = useTranslation();
  const { upcCode, epcs, found, recallQuantity } = productInfo;
  const { recallItems, setRecallItems, setBackup, backup } = useRecallContext();
  const dispatch = useAppDispatch();

  const [isFoundItem, setIsFoundItem] = useState<boolean>(false);
  const [isMissingItem, setIsMissingItem] = useState<boolean>(false);
  const [checkedEpcs, setCheckedEpcs] = useState<string[]>([]);
  const [isAccordionExpanded, toggleAccordion] = useToggle(false);
  const [undoSnackbarIsVisible, setUndoSnackbarVisibility] =
    useState<boolean>(false);

  const changeProductStatus = useCallback(
    (key: keyof RecallItems) => {
      // Insert in missing array the response product's epcs
      const product = {
        ...productInfo,
        missing: productInfo.epcs,
      };

      setRecallItems(prevState => ({
        ...prevState,
        [key]: [...prevState[key], product],
        error: prevState.error.filter(
          ({ upcCode }) => upcCode !== productInfo.upcCode
        ),
      }));
    },
    [productInfo, setRecallItems]
  );

  useEffect(() => {
    if (found) {
      if (found.length === recallQuantity) {
        return setIsFoundItem(true);
      }

      if (found.length < recallQuantity) {
        return setIsMissingItem(true);
      }
    }
  }, [found, recallQuantity]);

  useEffect(() => {
    if (isFoundItem) {
      changeProductStatus('found');
      setIsFoundItem(false);
    }

    if (isMissingItem) {
      changeProductStatus('missing');
      setIsMissingItem(false);
    }
  }, [changeProductStatus, isFoundItem, isMissingItem]);

  const resetBackup = (): void =>
    setBackup({
      found: [],
      missing: [],
      error: [],
    });

  const epcClickHandler = (epcCode: string): void => {
    setCheckedEpcs(prevState => {
      if (prevState.includes(epcCode)) {
        return prevState.filter(epc => epc !== epcCode);
      }

      return [...prevState, epcCode];
    });
  };

  const removeClickHandler = (): void => {
    setUndoSnackbarVisibility(true);
    setBackup(recallItems);

    setRecallItems(prevState => {
      const { error } = prevState;
      return {
        ...prevState,
        error: error.map(product => ({
          ...product,
          found: product.found?.filter(
            epcCode => !checkedEpcs.includes(epcCode)
          ),
        })),
      };
    });

    dispatch(
      removeFoundTags({
        upcCode,
        epcCodes: checkedEpcs,
      })
    );

    dispatch(removeTags(checkedEpcs));

    setCheckedEpcs([]);
  };

  const undoSnackbarClickHandler = (): void => {
    setRecallItems({
      found: backup.found,
      missing: backup.missing,
      error: backup.error,
    });

    resetBackup();
    setUndoSnackbarVisibility(false);
  };

  return (
    <>
      <StyledProductItemWrapper>
        <RecallListItemInfo
          {...productInfo}
          type="ERROR"
          scannedQuantity={found?.length || 0}
          accordion={{
            isVisible: epcs?.length! > 0,
            isExpanded: isAccordionExpanded,
            toggle: toggleAccordion,
          }}
        />
        {isAccordionExpanded && (
          <>
            <StyledMessageWrapper>
              <StyledMessage size="sm">
                {t('removeUnnecessaryItems', {
                  items: found?.length! - recallQuantity,
                })}
              </StyledMessage>
              <UIAction
                label={t('remove')}
                icon="delete"
                disabled={checkedEpcs.length === 0 || isGettingTags}
                onClick={removeClickHandler}
              />
            </StyledMessageWrapper>
            <StyledProductAccordion
              $v2
              items=""
              padding="0"
              expanded={isAccordionExpanded}
              toggleAccordion={toggleAccordion}
            >
              {found?.map(epcCode => (
                <StyledAccortionItemContent key={epcCode}>
                  <UIBox alignItems="center">
                    <UICheckbox
                      checked={checkedEpcs.includes(epcCode)}
                      onClick={(): void => epcClickHandler(epcCode)}
                    />
                    <ProductAccordionItem epcCode={epcCode} />
                  </UIBox>
                </StyledAccortionItemContent>
              ))}
            </StyledProductAccordion>
          </>
        )}
      </StyledProductItemWrapper>
      <AlertUndoSnackbar
        open={undoSnackbarIsVisible}
        setIsOpen={setUndoSnackbarVisibility}
        onUndoClick={undoSnackbarClickHandler}
        onClose={(): void => setUndoSnackbarVisibility(false)}
        message={t('notification.itemRemoved')}
        margin={'0 0 100px'}
      />
    </>
  );
};

export default RecallErrorListItem;
