/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class LogService {
  /**
   * send log payload to logging service
   * @returns any Default Response
   * @throws ApiError
   */
  public static async postLogService({
    requestBody,
  }: {
    requestBody?: {
      level: 'error' | 'info' | 'trace' | 'warn';
      message: string;
    };
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/api/v1/trace-log`,
      body: requestBody,
    });
    return result.body;
  }
}
