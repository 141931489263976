import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/ui/Typography';

import {
  StyledProductItemWrapper,
  ProductItemWrapper,
} from '@/components/layout/ProductItemWrapper';
import { ProductImage } from '@/components/layout/ProductImage';
import { ProductDescription } from '@/components/layout/ProductDescription';
import { ProductAccordion } from '@/components/layout/ProductAccordion';
import { ProductAccordionItem } from '@/components/layout/ProdocutAccordionItem';
import { ProductPrice } from '@/components/layout/ProductPrice';
import { useToggle } from 'react-use';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { StyledScannedProductItemProps } from './types';

import { StyledRightSideDatas, StyledTimestampEpc } from './style';
import { multipleElementsArray, singleElementArray } from '@/utils/validation';

export const ScannedProductItemDate: FC<StyledScannedProductItemProps> = ({
  $found,
  $notFound,
  $unexpected,
  $sale,
  productItem,
  onClick,
  $v2 = false,
}): JSX.Element => {
  const {
    brandCode,
    brandDescription,
    commodity,
    colorCode,
    epcCodes,
    modelCode,
    price,
    styleName,
    upcCode,
    imageUrl,
    //@ts-ignore
    currency,
  } = productItem;

  const { t } = useTranslation();
  const [$isAccordionExpanded, toggleAccordion] = useToggle(false);
  const singleEpcCode = singleElementArray(epcCodes);
  const multipleEpcCodes = multipleElementsArray(epcCodes);

  return (
    <>
      <StyledProductItemWrapper>
        <ProductItemWrapper
          $found={$found}
          $sale={$sale}
          $notFound={$notFound}
          $unexpected={$unexpected}
          $withAccordion={multipleEpcCodes}
        >
          <ProductImage
            imageUrl={imageUrl}
            onClick={onClick}
            colorCode={colorCode}
            modelCode={modelCode}
            brandCode={brandCode}
            $v2={multipleEpcCodes && $v2}
            onClickAccordion={toggleAccordion}
            $isAccordionExpanded={$isAccordionExpanded}
          />
          <ProductDescription>
            <Typography size="lg" font="heavy" as="span">
              {brandCode} - {brandDescription} - {styleName}
            </Typography>
            <Typography size="md" font="book" as="p">
              {[upcCode, modelCode, commodity].filter(el => el).join(' - ')}
              {singleEpcCode && (
                <span className="epc-code"> - {epcCodes[0].epcCode}</span>
              )}
            </Typography>
          </ProductDescription>
          <ProductPrice
            price={price ? String(price) : undefined}
            currency={currency}
          >
            <Typography font="light">
              {t('itemQuantity', { itemQuantity: epcCodes?.length })}
            </Typography>
            {singleEpcCode && (
              <StyledRightSideDatas>
                <StyledTimestampEpc as="div">
                  <AccessTimeIcon fontSize="small" htmlColor="grey" />
                  <Typography size="sm" color="grey">
                    {epcCodes[0]?.timestamp}
                  </Typography>
                </StyledTimestampEpc>
              </StyledRightSideDatas>
            )}
          </ProductPrice>
        </ProductItemWrapper>
        {multipleEpcCodes && (
          <ProductAccordion
            items={`${epcCodes.length} ${t('items')}`}
            $v2={$v2}
            expanded={$isAccordionExpanded}
            toggleAccordion={toggleAccordion}
          >
            {epcCodes?.map(({ epcCode, timestamp }, i: number) => (
              <ProductAccordionItem
                key={`${epcCode || ''}-${i}-code`}
                epcCode={epcCode || ''}
              >
                <StyledTimestampEpc as="div">
                  <AccessTimeIcon fontSize="small" htmlColor="grey" />
                  <Typography size="sm" color="grey">
                    {timestamp}
                  </Typography>
                </StyledTimestampEpc>
              </ProductAccordionItem>
            ))}
          </ProductAccordion>
        )}
      </StyledProductItemWrapper>
    </>
  );
};

export default ScannedProductItemDate;
