import React from 'react';
import { useTranslation } from 'react-i18next';

import { UIBox } from '@/components/ui/Box';
import { UIList } from '@/components/ui/List';

import { Typography } from '@/components/ui/Typography';

import type {
  InventoryOwnerInfoBrand,
  InventoryOwnerInfoUpc,
} from '@/api/receive';
import InventoryReportListItem from './InventoryReportListItem';
import { PaginationList } from '@/components/layout/PaginationList';

interface InventoryReportListProps extends InventoryOwnerInfoBrand {
  actionClickHandler?: (epc: string) => void;
  actionDisabled?: boolean;
}

const InventoryReportList: React.FC<InventoryReportListProps> = ({
  brandName,
  scannedTags,
  expected,
  notFound,
  actionDisabled,
  actionClickHandler,
}) => {
  const { t } = useTranslation();
  const [listCurrentPage, setListCurrentPage] = React.useState<number>(1);

  return notFound && notFound.length > 0 ? (
    <>
      <UIBox justifyContent="space-between">
        <Typography font="heavy" size="xl">
          {brandName}
        </Typography>
        <Typography font="heavy">
          {t('scannedQty')} {scannedTags} / {expected}
        </Typography>
      </UIBox>
      <Typography margin="16px 0">
        {t('notFoundItems', {
          items: notFound.flatMap(({ epcs }) => epcs).length,
        })}
      </Typography>
      <UIList
        padding={[0]}
        margin={[0, 0, 48, 0]}
        rounded
        backgrounded
        shadowed
      >
        {notFound && (
          <PaginationList
            currPage={listCurrentPage}
            setCurrPage={setListCurrentPage}
            data={notFound}
            pageSize={20}
            renderItem={(
              upc: InventoryOwnerInfoUpc,
              index: number
            ): JSX.Element => (
              <InventoryReportListItem
                key={`${upc.upcCode}-${index}`}
                brandName={brandName}
                actionDisabled={actionDisabled}
                actionClickHandler={actionClickHandler}
                {...upc}
              />
            )}
          />
        )}
      </UIList>
    </>
  ) : null;
};

export default InventoryReportList;
