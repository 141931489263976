import React from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionProps as MuiAccordionProps,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

interface AccordionProps {
  className?: string;
  onClick: React.MouseEventHandler<SVGElement>;
}

const StyledAccordion = styled(Accordion)`
  background: transparent;
  box-shadow: none;
  border: none;
  margin: 0 !important;
`;

const StyledAccordionOpener = styled(ExpandMore)<
  Partial<typeof ExpandMore> & {
    $isAccordionExpanded?: boolean;
  }
>`
  margin-right: 20px;
  fill: #145d95;
  font-size: xx-large;
  margin: 0 !important;
  transition: 0.25s ease-in-out;
  transform: ${({ $isAccordionExpanded }): string =>
    $isAccordionExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
  cursor: pointer;
`;

const UIAccordion: React.FC<
  Omit<MuiAccordionProps, 'children'> & AccordionProps
> = ({ expanded, onClick, className }) => {
  return (
    <StyledAccordion expanded={expanded} className={className}>
      <StyledAccordionOpener
        onClick={onClick}
        $isAccordionExpanded={expanded}
      />
    </StyledAccordion>
  );
};

export default UIAccordion;
