import React, { useEffect } from 'react';
import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import UIChip from '@/components/ui/Chip/Chip';
import { palette } from '@/theme';
import { ChipProps } from '@material-ui/core';
import { Chip } from '@/hooks/useChips';

const StyledChipList = styled(UIBox)`
  flex-direction: row;
  flex-wrap: wrap;

  & > * {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const StyledIcon = styled(HighlightOffIcon)`
  fill: ${palette.colors.white};
`;

interface ChipListProps extends ChipProps {
  chips: Chip[];
  className?: string;
  onChipDelete: (chip: Chip) => void;
}

const ChipList: React.FC<ChipListProps> = ({
  chips,
  className,
  onChipDelete,
}) => {
  const [chipData, setChipData] = React.useState<Chip[]>([]);

  useEffect(() => {
    setChipData(chips);
  }, [chips]);

  const handleDelete = (chipToDelete: Chip) => (): void => {
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
    onChipDelete(chipToDelete);
  };

  return (
    <StyledChipList className={className}>
      {chipData.map(chip => (
        <UIChip
          key={chip.label}
          label={chip.label}
          deleteIcon={<StyledIcon />}
          onDelete={handleDelete(chip)}
        />
      ))}
    </StyledChipList>
  );
};

export default ChipList;
