import UIAutocomplete from '@/components/ui/AutoComplete/AutoComplete';
import { UIButton } from '@/components/ui/Button';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 24px 8px 8px 8px;
`;

export const StyledAutoComplete = styled(UIAutocomplete)`
  margin: 0 8px 0 24px;
`;

export const SearchButton = styled(UIButton)`
  && {
    width: 150px;
    padding: 26px 0;
  }
`;
