import { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { Typography } from '@/components/ui/Typography';

import {
  StyledColumn,
  StyledContent,
  StyledRadio,
  StyledRow,
  StyledText,
} from './style';

import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import { CTAContainer } from '@/components/layout/CTAContainer';
import { AppRoutes } from '@/app/routers';

import { PrintMode } from '@/types/enum';

import { isRFIDStore } from '@/utils';
import { useSelector } from '@/hooks/useSelector';

const PagePrintModeControl: React.FC = memo((): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const printers = useSelector(
    state => state.currentStore.store?.printers || []
  );
  const [mode, setMode] = useState<PrintMode>(PrintMode.New);

  const nextPage = `${AppRoutes.PRINT}/${mode.toLowerCase()}`;

  useBreadcrumbs([{ title: t('printNewTag') }]);

  useEffect(() => {
    if (isRFIDStore(printers)) {
      history.push(`${AppRoutes.PRINT}/new`);
    }
  }, [history, printers]);

  const changeModeHandler = (newMode: PrintMode): void => {
    setMode(newMode);
  };

  return (
    <>
      <Typography
        size="lg"
        font="heavy"
        as="h1"
        style={{ marginTop: 24, textAlign: 'center' }}
      >
        {t('Please select the purpose of printing')}
      </Typography>

      <StyledColumn>
        <StyledContent>
          <StyledRow mt="0">
            <StyledRadio
              icon={<CircleUnchecked />}
              checkedIcon={<CircleChecked />}
              checked={mode === PrintMode.New}
              onChange={(): void => {
                changeModeHandler(PrintMode.New);
              }}
            />
            <StyledText>
              <Typography size="sm" font="heavy" as="p">
                {t('Create new tag')}
                <Typography
                  size="xs"
                  as="span"
                  font="light"
                  style={{ marginLeft: 8 }}
                >
                  {t('(Print and create a new EPC number)')}
                </Typography>
              </Typography>
            </StyledText>
          </StyledRow>
          <StyledRow mt="14">
            <StyledRadio
              icon={<CircleUnchecked />}
              checkedIcon={<CircleChecked />}
              checked={mode === PrintMode.Update}
              onChange={(): void => {
                changeModeHandler(PrintMode.Update);
              }}
            />
            <StyledText>
              <Typography size="sm" font="heavy" as="p">
                {t('Update tag')}
                <Typography
                  size="xs"
                  as="span"
                  font="light"
                  style={{ marginLeft: 8 }}
                >
                  {t(
                    '(Re-print existing label tags. No RFID tag will be generated)'
                  )}
                </Typography>
              </Typography>
            </StyledText>
          </StyledRow>
        </StyledContent>
      </StyledColumn>

      <CTAContainer
        type="NEXT"
        onClick={(): void => {
          history.push(nextPage);
        }}
        onBackClick={(): void => {
          history.push(AppRoutes.INTRO);
        }}
      />
    </>
  );
});

PagePrintModeControl.displayName = 'PagePrintModeControl';

export default PagePrintModeControl;
