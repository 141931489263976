import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const StyledMultiProgressBar = styled(UIBox)`
  flex: 1;
  flex-direction: column;
  margin: 0 24px;

  span {
    color: #929496;
  }
`;

export const StyledMultiProgressBarInfo = styled(UIBox)`
  width: 100%;
  padding: 13px 10px;
  align-self: flex-start;
  justify-content: space-between;

  p {
    margin: 0;
  }
`;

export const StyledMultiProgressBarWrapper = styled(UIBox)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px 0 0;
`;

export const StyledMultiProgressBarInnerWrapper = styled(UIBox)<{
  $sticky?: boolean;
  flex?: CSSProperties['flex'];
}>`
  width: calc(100% - 32px);
  flex: ${({ flex }): CSSProperties['flex'] => (flex ? flex : 'initial')};
  position: relative;
  overflow: hidden;
  height: 16px;
  background: #fff;
  border: 1px solid #ededed;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 32px;
  margin: 0 12px;

  & > * {
    height: 100%;
    position: absolute;
    border-radius: 32px;
    left: 0;
  }

  ${({ $sticky }): false | FlattenSimpleInterpolation | undefined =>
    $sticky &&
    css`
      width: calc(100% - 175px);
      margin: 14px 8px;
      display: flex;
      align-items: center;
    `}
`;

export const StyledScannedStickyBar = styled(UIBox)`
  && {
    width: 100%;
    align-items: center;
    padding: 8px;

    p {
      margin-right: 12px;
    }

    h1,
    p,
    span {
      color: #fff;
    }
  }
`;

export const StyledNotFoundBar = styled.div`
  width: 100%;
  background: #e20404;
  z-index: 1;
`;

export const SyledFoundBar = styled.div<{
  found: number;
  notFound: number;
  unexpected: number;
}>`
  ${({
    found,
    notFound,
    unexpected,
  }): number | FlattenSimpleInterpolation | undefined =>
    found &&
    css`
      width: calc((${found} / ${notFound + unexpected + found}) * 100%);
    `};
  background: #417505;
  z-index: 3;
`;

export const StyledUnexpectedBar = styled.div<{
  found: number;
  notFound: number;
  unexpected: number;
}>`
  ${({
    found,
    notFound,
    unexpected,
  }): number | FlattenSimpleInterpolation | undefined =>
    unexpected &&
    css`
      width: calc((${unexpected} / ${notFound + unexpected + found}) * 100%);
      left: calc((${found} / ${notFound + unexpected + found}) * 100%);
    `};
  background: #f6bb3b;
  position: absolute;
  z-index: 2;
`;
