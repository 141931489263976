import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch } from '@/app/store';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { v4 as uuidv4 } from 'uuid';
import {
  getProductsByFilters,
  setMissingEpcs,
  setMissingItemsCycleCount,
} from '@/features/cycleCount/cycleCountSlice';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { PageLoader } from '@/components/ui/PageLoader';
import { Link } from '@material-ui/core';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import MissingProductList from './MissingProductList';
import { CTAContainer } from '@/components/layout/CTAContainer';

import type { CycleDetailsItems } from '@/api';
import { AppRoutes } from '@/app/routers';
import { StyledContainer } from './style';
import { EnumMode } from '@/types/enum';
import { useCycleCountContext } from '@/context/cycleCount';

const PageCycleCountMissingItems: React.FC = () => {
  const { t } = useTranslation();
  const dayBefore = 20;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const { setMissingItemsBrands } = useCycleCountContext();
  const { products, productsIsLoading, productsHasError } = useSelector(
    state => state.cycleCount
  );

  const [selectedEpcs, setSelectedEpcs] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const brandCodes = useMemo(
    () => products.missing.map(({ brandDescription }) => brandDescription),
    [products]
  );

  const missingEpcs = useMemo(
    () =>
      products.missing
        .flatMap(({ epcCodes }) => epcCodes)
        .map(({ epcCode }) => epcCode),
    [products.missing]
  );

  useAsync(async () => {
    if (storeCode) {
      await dispatch(
        getProductsByFilters({
          requestBody: {
            storeCode,
            dayBefore,
            isMissingCC: true,
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (productsHasError) {
      setError(true);
    }
  }, [productsHasError]);

  useEffect(() => {
    dispatch(setMissingItemsCycleCount(true));
  }, [dispatch]);

  useEffect(() => {
    sessionStorage.setItem('linkedEventId', `MissingCycleCount-${uuidv4()}`);
  }, [dispatch]);

  const handleSelectAll = (): void => {
    if (selectedEpcs.length === missingEpcs.length) {
      setSelectedEpcs([]);
      setMissingItemsBrands([]);
    } else {
      setSelectedEpcs(missingEpcs);
      setMissingItemsBrands(b => [...new Set([...b, ...brandCodes])]);
    }
  };

  const handleScanClick = (): void => {
    dispatch(setMissingEpcs(selectedEpcs));
    setOpenModal(true);
  };

  const productLists = useMemo(
    () =>
      products.missing.reduce(
        (prev, curr) => {
          const uniqueKey = curr.brandDescription;

          if (prev[uniqueKey] || (prev[uniqueKey] = [])) {
            prev[uniqueKey].push(curr);
          }

          return prev;
        },
        {} as {
          [key: string]: CycleDetailsItems[];
        }
      ),
    [products.missing]
  );

  if (productsIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalScanDetailsV2
        resetDeviceInUse
        open={openModal}
        hideModes={[EnumMode.INVENTORY]}
        defaultMode={EnumMode.FIND}
        onClose={(): void => setOpenModal(false)}
        nextPageURL={AppRoutes.CYCLE_COUNT_FILTER_PRODUCTS}
      />
      <StyledContainer>
        <UIBox ml="auto" mr="auto" textAlign="center">
          <Typography size="lg" font="heavy" as="h1">
            {t('cyclecount.historytitleMissingItems')}
          </Typography>
        </UIBox>
        <Link
          component="button"
          variant="body2"
          underline="always"
          onClick={handleSelectAll}
        >
          {selectedEpcs.length === missingEpcs.length
            ? t('deselectAll')
            : t('selectAll')}
        </Link>
        <UIBox mb="100px" width="100%" flexDirection="column">
          {Object.values(productLists).map((products, i) => (
            <MissingProductList
              key={i}
              selectedEpcs={selectedEpcs}
              setSelectedEpcs={setSelectedEpcs}
              products={products}
            />
          ))}
        </UIBox>
      </StyledContainer>
      <CTAContainer
        type="TWO_BUTTONS"
        onClick={handleScanClick}
        disabledMainAction={selectedEpcs.length === 0}
        onBackClick={(): void => history.push(AppRoutes.CYCLE_COUNT)}
        mainButtonLabel={t('scan-now')}
        backButtonLabel={t('back')}
      />
      <ErrorSnackbar
        open={error}
        setIsOpen={setError}
        errorMessage={
          productsHasError?.body ||
          productsHasError?.body?.errorMessage ||
          t('error.missingItems')
        }
      />
    </>
  );
};

export default PageCycleCountMissingItems;
