import React from 'react';
import SaleExceptionsItem from '../ProductListItem';
import { UIList, UIListSubHeader } from '@/components/ui/List';
import { ActionsDisabled, ListType, SaleExceptionsListProps } from './types';

import * as S from './style';
import { useSelector } from '@/hooks/useSelector';
import { UIAction } from '@/components/ui/Action';
import { useTranslation } from 'react-i18next';
import { UIBox } from '@/components/ui/Box';
import { useAppDispatch } from '@/app/store';
import {
  checkAllManagedItems,
  checkAllNotManagedItems,
  resetManagedCheckedItems,
  resetNotManagedCheckedItems,
} from '@/features/pos/posSlice';
import { CheckedPosItems } from '@/types/pos';
import { useHistory, useLocation } from 'react-router';
import { AppRoutes } from '@/app/routers';
import { UISelectAll } from '@/components/ui/SelectAll';
import { PaginationList } from '@/components/layout/PaginationList';
import { ProductDetailsItemsPos } from '@/api/receive';
import { isSaleExceptionsManager } from '@/utils/user';

/**
 *
 * @description
 * The Component changes its functionality thanks to a redux property
 * called "liteFunctionality" set to true by setLiteFunctionality action in PageSaleExceptionsScan page.
 *
 * Component changes if liteFunctionality property is true:
 * 1 - The accordion is hidden
 * 2 - The upc & epc checkboxes are disabled if props actionsDisabled is defined
 *     otherwise they are disabled based on user click (clicks on upc codes disable epc code checkboxes and viceversa).
 * 3 - The user can only confirm selected items
 *
 */
const SaleExceptionsList: React.FC<SaleExceptionsListProps> = ({
  products,
  productsCount,
  title,
  type,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const user = useSelector(state => state.user);
  const {
    liteFunctionality,
    exceptionsManaged,
    checkedManagedItems,
    checkedNotManagedItems,
  } = useSelector(state => state.pos);

  const [accordionOpen, setAccordionOpen] = React.useState<boolean>(
    liteFunctionality || false
  );
  const [actionsDisabled, setActionsDisabled] =
    React.useState<ActionsDisabled>();

  const list = React.useMemo(
    () => ({
      upcCodes: products.map(({ upcCode }) => upcCode || ''),
      epcCodes: products.flatMap(({ epcCodes }) => epcCodes || []),
    }),
    [products]
  );

  const selected = React.useMemo(
    () => ({
      managed: {
        upcCodes: Object.keys(checkedManagedItems),
        epcCodes: Object.values(checkedManagedItems).flatMap(codes => codes),
      },
      notManaged: {
        upcCodes: Object.keys(checkedNotManagedItems),
        epcCodes: Object.values(checkedNotManagedItems).flatMap(codes => codes),
      },
    }),
    [checkedManagedItems, checkedNotManagedItems]
  );

  React.useEffect(() => {
    if (liteFunctionality) return setActionsDisabled(props.actionsDisabled);

    // If user clicks on epc code
    if (type === ListType.MANAGED && selected.managed.epcCodes.length > 0) {
      return setActionsDisabled('UPC_CODE');
    }

    // If user clicks on upc code
    if (
      selected.managed.epcCodes.length === 0 &&
      selected.managed.upcCodes.length > 0
    ) {
      return setActionsDisabled('EPC_CODE');
    }

    return setActionsDisabled(undefined);
  }, [
    liteFunctionality,
    props.actionsDisabled,
    selected.managed.epcCodes.length,
    selected.managed.upcCodes.length,
    type,
  ]);

  // #region - SELECT ALL FUNCTIONALITY ------------------------
  const handleSelectAll = (type: ListType): void => {
    let upcs: CheckedPosItems = {};

    products.forEach(({ upcCode, epcCodes }) => {
      upcs = {
        ...upcs,
        [upcCode || '']: liteFunctionality ? epcCodes || [] : [],
      };
    });

    if (type === ListType.MANAGED) {
      dispatch(checkAllManagedItems(upcs));
    } else {
      dispatch(checkAllNotManagedItems(upcs));
    }
  };

  const handleDeselectAll = (type: ListType): void => {
    if (type === ListType.MANAGED) {
      dispatch(resetManagedCheckedItems());
    } else {
      dispatch(resetNotManagedCheckedItems());
    }
  };
  // #endregion ----------------------------------------

  // #region - MANAGED LIST ------------------------
  const handleSearchItemsClick = async (): Promise<void> => {
    history.push(AppRoutes.SALE_EXCEPTIONS_SCAN);
  };
  // #endregion ----------------------------------------

  return (
    <>
      <S.UIListWrapper>
        <UIList
          subheader={
            <UIListSubHeader
              title={title}
              itemsCount={productsCount}
              accordion={
                liteFunctionality
                  ? undefined
                  : {
                      expanded: accordionOpen,
                      toggleAccordionState: setAccordionOpen,
                    }
              }
            />
          }
          margin={
            type === 'MANAGED'
              ? [0, 0, 150]
              : exceptionsManaged.length > 0
              ? [0, 0, 24]
              : [0, 0, 150]
          }
          padding={[0, 24]}
          bordered
          backgrounded
        >
          {accordionOpen && (
            <>
              <UIBox p={2} mt={2}>
                {type === 'MANAGED' ? (
                  <>
                    <UIBox width="100%" justifyContent="space-between">
                      {actionsDisabled === 'EPC_CODE' && (
                        <UIBox mr="auto" alignItems="center">
                          <UISelectAll
                            selected={selected.managed.upcCodes.length}
                            selectedAll={
                              selected.managed.upcCodes.length ===
                              list.upcCodes.length
                            }
                            actions={{
                              selectAll: (): void => handleSelectAll(type),
                              deselectAll: (): void => handleDeselectAll(type),
                            }}
                          />
                        </UIBox>
                      )}
                      <S.Actions>
                        <UIAction
                          icon="confirm"
                          label={
                            location.pathname === AppRoutes.SALE_EXCEPTIONS_SCAN
                              ? t('confirmSoldItems')
                              : t('confirm')
                          }
                          onClick={props.managedList?.onConfirm}
                          disabled={
                            liteFunctionality &&
                            typeof actionsDisabled === 'boolean'
                              ? actionsDisabled ||
                                selected.managed.epcCodes.length === 0
                              : actionsDisabled !== 'UPC_CODE'
                          }
                        />
                        {!liteFunctionality && (
                          <UIAction
                            icon="search"
                            label={t('searchItems')}
                            onClick={handleSearchItemsClick}
                            disabled={actionsDisabled !== 'EPC_CODE'}
                          />
                        )}
                        {isSaleExceptionsManager(user) &&
                          props.managedList?.onRemove && (
                            <UIAction
                              icon="delete"
                              label={t('remove')}
                              disabled={actionsDisabled !== 'EPC_CODE'}
                              onClick={props.managedList.onRemove}
                            />
                          )}
                      </S.Actions>
                    </UIBox>
                  </>
                ) : (
                  <>
                    <UIBox width="100%" alignItems="center">
                      <UISelectAll
                        selected={selected.notManaged.upcCodes.length}
                        selectedAll={
                          selected.notManaged.upcCodes.length ===
                          list.upcCodes.length
                        }
                        disabled={
                          typeof actionsDisabled === 'boolean'
                            ? actionsDisabled
                            : false
                        }
                        actions={{
                          selectAll: (): void => handleSelectAll(type),
                          deselectAll: (): void => handleDeselectAll(type),
                        }}
                      />
                      <S.Actions>
                        <UIAction
                          icon="delete"
                          label={t('remove')}
                          disabled={selected.notManaged.upcCodes.length === 0}
                          onClick={props.notManagedList?.onRemove}
                        />
                      </S.Actions>
                    </UIBox>
                  </>
                )}
              </UIBox>
              <PaginationList
                data={products}
                pageSize={25}
                renderItem={(product: ProductDetailsItemsPos): JSX.Element => (
                  <SaleExceptionsItem
                    type={type}
                    product={product}
                    actionsDisabled={actionsDisabled!}
                  />
                )}
              />
            </>
          )}
        </UIList>
      </S.UIListWrapper>
    </>
  );
};

export default SaleExceptionsList;
