import { FC } from 'react';
import { Snackbar, SnackbarProps } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

export interface AlertSnackbarProps extends SnackbarProps {
  setIsOpen: (open: boolean) => void;
  message: string;
  severity?: AlertProps['severity'];
}

export const AlertSnackbar: FC<AlertSnackbarProps> = ({
  setIsOpen,
  message,
  severity = 'warning',
  ...rest
}: AlertSnackbarProps): JSX.Element => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    autoHideDuration={6000}
    onClose={(): void => setIsOpen(false)}
    {...rest}
  >
    <MuiAlert elevation={6} variant="filled" severity={severity}>
      {message}
    </MuiAlert>
  </Snackbar>
);

AlertSnackbar.displayName = 'AlertSnackbar';

export default AlertSnackbar;
