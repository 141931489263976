import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useSelector } from '@/hooks/useSelector';
import useSROTrackEvent from '@/hooks/useSROTrackEvent';
import { usePrintContext } from '@/context/Print';

type TrackPrintCycleCount = {
  User: string;
  StoreCode: string;
  UPC: string;
  EPC: string;
  ReasonCode: string;
  LinkedEvent: string;
};

type ReturnHookType = (upcCode: string) => Promise<void>;

const useTrackPrint = (): ReturnHookType => {
  const appInsights = useAppInsightsContext();
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const { username } = useSelector(state => state.user);
  const { print } = usePrintContext();

  const data: TrackPrintCycleCount = {
    User: username || '',
    StoreCode: storeCode || '',
    UPC: '',
    EPC: print.data.printedEPC || '',
    ReasonCode: print.reason.movementReasonCode,
    LinkedEvent: `${username}-${sessionStorage.getItem('linkedEventId')}`,
  };

  const trackEvent = useSROTrackEvent<TrackPrintCycleCount>(
    appInsights,
    'Cycle Count Print Epc',
    data
  );

  const trackPrint = async (upcCode: string): Promise<void> => {
    await trackEvent({
      ...data,
      UPC: upcCode,
    });
  };

  return trackPrint;
};

export default useTrackPrint;
