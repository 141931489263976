import { FC, memo } from 'react';
import styled from 'styled-components';

import { Search as SearchIcon } from '@material-ui/icons';
import { CircularProgress, TextFieldProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { TextField } from '@/components/ui/TextField';
import { UIButton } from '@/components/ui/Button';

interface SearchBarButtonProps {
  label: string;
  disabled: boolean;
  hideButton?: boolean;
  onSearch: (event: React.MouseEvent<Element, MouseEvent>) => void;
}

// TODO: create an input component
const useStyles = makeStyles(() =>
  createStyles({
    input: {
      height: 44,
    },
  })
);

//#region  styled-component
const StyledSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  padding: 34px 0 0;
`;

const StyledSearchBar = styled(TextField)`
  width: 305px;

  fieldset {
    border-radius: 50px;
  }
`;

const StyledSearchButton = styled(UIButton)`
  && {
    width: 150px;
  }
`;
//#endregion

type SearchBarProps = Omit<TextFieldProps, 'variant'> &
  SearchBarButtonProps & {
    loading: boolean;
  } & React.ComponentPropsWithRef<'div'>;

export const SearchBar: FC<SearchBarProps> = memo(
  ({
    placeholder,
    value,
    onChange,
    error,
    helperText,
    label,
    disabled,
    hideButton,
    onSearch,
    loading,
    onKeyPress,
    ...props
  }) => {
    const classes = useStyles();

    return (
      <StyledSearchContainer {...props}>
        <StyledSearchBar
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          variant="outlined"
          InputProps={{
            className: classes.input,
            endAdornment: loading && (
              <CircularProgress color="inherit" size={20} />
            ),
          }}
          inputProps={{
            maxLength: 90,
            minLength: 5,
          }}
          required
          error={error}
          helperText={error && helperText}
          onKeyPress={onKeyPress}
        />

        {!hideButton && (
          <StyledSearchButton
            label={label}
            disabled={disabled}
            onClick={onSearch}
            startIcon={<SearchIcon />}
          />
        )}
      </StyledSearchContainer>
    );
  }
);

SearchBar.displayName = 'SearchBar';

export default SearchBar;
