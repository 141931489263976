import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { useAsync } from 'react-use';
import useTrackConfirm from '../track/useTrackConfirm';
import { useTranslation } from 'react-i18next';

import {
  // ConfirmCycleCount,
  CycleDetailsItems,
  ScanDevicesService,
} from '@/api';

import { Typography } from '@/components/ui/Typography';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { ModalReason } from '@/components/CycleCount/ModalReason/ModalReason_all_count';
import ScanStatusBar from '@/components/layout/ScanStatusBar/ScanStatusBar';
import {
  // confirmCycleCount,
  filterMissingProductsByMissingEpcs,
  findEpcThroughTags,
  setNotFoundProductsToUndo,
  setSohProducts,
  setUnexpectedProductsToUndo,
  undoNotFoundProducts,
  undoUnexpectedProducts,
} from '@/features/cycleCount/cycleCountSlice';
import { AppRoutes } from '@/app/routers';
import { deleteTags, tagsMQTTDevice } from '@/features/devices/devicesSlice';
import { ModalDataSaved } from '@/components/layout/ModalDataSaved';
import { ModalProductDetails } from '@/components/layout/ModalProductDetails';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import ProductList from './ProductList';

import RefreshIcon from '@material-ui/icons/Refresh';
import { DateTime } from 'luxon';
import { EnumMode, ModalReasonType, Product } from '@/types/enum';
import {
  AlertSnackbar,
  AlertUndoSnackbar,
} from '@/components/ui/AlertSnackbar';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import {
  StyledProductsWrapper,
  StyledCycleCountWrapper,
  StyledRecapWrapper,
  StyledUpdateButton,
  StyledSwitchWrapper,
} from './style';
import { findStockItems } from '@/features/stockSummary/stockSummarySlice';
import { useCycleCountContext } from '@/context/cycleCount';
import ModalPrintTemp from '@/components/layout/ModalPrintTemp';
import { printLabel } from '@/features/print/printSlice';
import { usePrintContext } from '@/context/Print';
import { PrintReasonCodeStatus } from '@/types/enum';
import { CycleCountRedux } from '../actions';
import useTrackPrint from '../track/useTrackPrint';
import { handlePrintLayout } from '@/utils/handlePrintLayout';
import { UISwitch } from '@/components/ui/Switch';
import { useSignalRContext } from '@/context/signalR';
import useConfirmCycleCount from '../hooks/useConfirmCycleCount';
import useConfirmSohAlignment from '../hooks/useConfirmSohAlignment';
import {
  ApiError,
  ProcessStatus,
  StockOnHandAlignmentService,
} from '@/api/receive';
import { ModalDataSavedError } from '@/components/layout/ModalDataSaved';
import qs from 'query-string';
import { UIBox } from '@/components/ui/Box';
import { UIButton } from '@/components/ui/Button';
import useHandleProcess from '@/hooks/useHandleProcess';
import { ProcessService } from '@/api/receive';
import { setAvoidAlertInHomePage } from '@/features/bis/backInStockSlice';

const PageCycleCountScanProducts: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { print, setPrint } = usePrintContext();
  const signalr = useSignalRContext();
  const confirmCycleCount = useConfirmCycleCount();
  const confirmSoh = useConfirmSohAlignment();
  const handleProcess = useHandleProcess();
  const {
    notFoundEpcsWithReason,
    setNotFoundEpcsWithReason,

    openPrintModal,
    setPrintModalVisibility,

    oldEpc,

    productToPrint,
    setProductToPrint,

    isNotFoundModalReasonVisibile,
    setNotFoundModalReasonVisibility,

    isUnexpectedModalReasonVisibile,
    setUnexpectedModalReasonVisibility,

    productDetailsByUpc,

    openProductModal,
    setProductModalVisibility,

    undoSnackbarIsVisible,
    setUndoSnackbarVisibility,

    setCheckedNotFoundUpcs,
    setCheckedNotFoundEpcs,
    checkedNotFoundUpcsToUndo,
    checkedNotFoundEpcsToUndo,

    setCheckedUnexpectedUpcs,
    setCheckedUnexpectedEpcs,
    checkedUnexpectedUpcsToUndo,
    checkedUnexpectedEpcsToUndo,

    setCheckedNotFoundUpcsToUndo,
    setCheckedNotFoundEpcsToUndo,

    setCheckedUnexpectedUpcsToUndo,
    setCheckedUnexpectedEpcsToUndo,

    // missingItemsBrands,
  } = useCycleCountContext();

  const startTimestamp = useMemo(() => DateTime.local(), []);
  const [scanAgainCounter, setScanAgainCounter] = useState<number>(1);

  // * UI STATE
  const [sticky, setSticky] = useState(false);
  const [inPause, setPause] = useState(false);
  const [deviceError, setDeviceError] = useState<boolean>(false);
  const [deviceErrorMsg, setDeviceErrorMsg] = useState<string>();
  const [confirmError, setConfirmError] = useState<boolean>(false);
  const [confirmSohError, setConfirmSohError] = useState<boolean>(false);
  const [confirmSohErrorModalIsVisible, setConfirmSohErrorModalIsVisible] =
    useState<boolean>(false);
  const [deleteSohLoading, setDeleteSohLoading] = useState<boolean>(false);
  const [seeResultsLoading, setSeeResultsLoading] = useState<boolean>(false);
  const [deleteSohError, setDeleteSohError] = useState<boolean>(false);
  const [otherItemsVisible, setOtherItemsVisibility] = useState<boolean>(false);
  const [finishAndExitLoading, setFinishAndExitLoading] =
    useState<boolean>(false);
  const [isOperator, setIsOperator] = useState<boolean>(false);

  const [finishButtonClicked, setFinishButtonClicked] =
    useState<boolean>(false);

  const [confirmButtonClicked, setConfirmButtonClicked] =
    useState<boolean>(false);

  // * MODALS STATE
  const [quitModalIsVisible, setQuitModalIsVisible] = useState<boolean>(false);
  const [sohOnGoingModalIsVisible, setSohOnGoingModalVisibility] =
    useState<boolean>(false);

  const [finishAndExitModalIsVisible, setFinishAndExitModalIsVisible] =
    useState<boolean>(false);

  // ** Print details modal
  const [printInError, setPrintInError] = useState<boolean>(false);

  // ** Modal Data Saved
  const [isConfirmModalVisible, setConfirmModalVisibility] =
    useState<boolean>(false);

  // ** Scan details modal
  const [openScanModal, setScanModalVisibility] = useState(false);

  // * FUNCTIONALITIES STATE
  const [processLock, setProcessLock] = useState<ProcessStatus>();
  const [isScanCompleted, setIsScanCompleted] = useState<boolean>(false);
  const [isScanningAgain, setIsScanningAgain] = useState<boolean>(false);
  const [noPrinterAvailable, setNoPrinterAvailable] = useState<boolean>(false);
  const [isFinishResultsButtonClicked, setIsFinishResultsButtonClicked] =
    useState<boolean>(false);

  const trackConfirm = useTrackConfirm(scanAgainCounter);
  const trackPrint = useTrackPrint();
  const isSohProcess = useSelector(state => state.cycleCount.sohLocked);

  const { store } = useSelector(state => state.currentStore);
  const { username } = useSelector(state => state.user);
  const { deviceInUse, tags: cycleCountTags } = useSelector(
    state => state.devices
  );
  const { cycleCountPrintError } = useSelector(state => state.print);
  const {
    // brandList,
    selectedBrands,
    // selectedMarketingStories,
    // selectedMarketingThemes,
    selectedEpc,
    selectedUpc,
    selectedModel,
    filterSelected,
    isMissingItemsCycleCount,
    confirmHasError,
    commodityState,
  } = useSelector(state => state.cycleCount);

  const {
    found,
    notFound,
    unexpected,
    recalled,
    damaged,
    qualityInspection,
    checkoutError,
    inTransit,
    // expectedItems,
    missing,
  } = useSelector(state => state.cycleCount.products);

  const products = useSelector(state => state.cycleCount.products);

  const { notFoundWithReason } = useSelector(state => state.cycleCount);

  const calcItems = (list: CycleDetailsItems[]): number =>
    list.reduce((acc: number, b) => acc + Number(b.epcCodes.length), 0);

  const foundItemsCount = calcItems(found);
  const recalledItemsCount = calcItems(recalled);
  const damagedItemsCount = calcItems(damaged);
  const inTransitItemsCount = calcItems(inTransit);
  const qualityInspectionItemsCount = calcItems(qualityInspection);
  const checkoutErrorItemsCount = calcItems(checkoutError);
  const notFoundItemsCount = calcItems(notFound);
  const missingItemsCount = calcItems(missing);
  const unexpectedItemsCount = calcItems(unexpected);
  const otherItems =
    recalledItemsCount +
    damagedItemsCount +
    inTransitItemsCount +
    qualityInspectionItemsCount +
    checkoutErrorItemsCount;

  // const selectedModels = [
  //   ...new Set(selectedModel.map(upcModel => upcModel.split('|')[1])),
  // ];

  const signalrData = useMemo(() => {
    return {
      id: '',
      epcs: cycleCountTags,
      upcs: selectedUpc,
      brands: selectedBrands,
      commodities: commodityState,
      products: btoa(JSON.stringify(products)),
    };
  }, [commodityState, cycleCountTags, products, selectedBrands, selectedUpc]);

  const query = useMemo(() => qs.parse(location.search), [location.search]);

  const handleScroll = useCallback((): void => {
    const scroll = window.scrollY;
    if (scroll < 20) {
      setSticky(false);
    } else {
      setSticky(true);
    }
  }, [setSticky]);

  useEffect(() => {
    setPrintInError(cycleCountPrintError !== undefined);
  }, [cycleCountPrintError]);

  useAsync(async () => {
    if (isSohProcess) {
      await signalr.connect('SOHA');
    }
  }, [isSohProcess]);

  useAsync(async () => {
    if (isSohProcess && signalr.connected) {
      await signalr.subscribeStore();
    }
  }, [signalr.connected, isSohProcess, signalr.connection]);

  useAsync(async () => {
    signalr.connection?.on('newUser', async (message: string) => {
      if (signalr.connected) {
        const data = JSON.parse(Buffer.from(message, 'base64').toString());
        let productsData = products;

        if (data.data.products !== '') {
          productsData = JSON.parse(
            Buffer.from(data.data.products, 'base64').toString()
          );
        }

        console.group('Signalr - New user connected');
        console.log('SOH owner', data.data.owner);
        console.log('Current SOH Epc codes', data.data.epcs);
        console.log('Connected users', data.data.users);
        console.groupEnd();

        if (data.data.status === 'PENDING') {
          setIsFinishResultsButtonClicked(true);
        }

        if (data.data.owner === username) {
          // It runs from the second time that owner enter in the process
          // The first time the status is NEW, set on proceed button in verify page
          // The second time the status is null
          if (!sessionStorage.getItem('soha-status')) {
            await signalr.send(data.data);
            dispatch(setSohProducts(productsData));
            setIsScanCompleted(true);
          } else {
            // It runs when the owner is connected for the first time
            // and create on signalr the stock on alignment data
            await signalr.send(signalrData);
          }
        } else {
          // It runs when the operator is connected and read
          // the data sent by the owner
          setIsOperator(true);
          setIsScanCompleted(true);
          dispatch(setSohProducts(productsData));
        }

        sessionStorage.setItem('soha-status', data.data.status);
      }
    });
  }, [signalr.connected]);

  useAsync(async () => {
    signalr.connection?.on('newMessage', (message: string) => {
      const data = JSON.parse(Buffer.from(message, 'base64').toString());

      // if (reader.storeCode !== storeCode) return;
      console.group('Signalr - SOH Data');
      console.log('Users connected', data.data.users);
      console.log('Epc codes', data.data.epcs);
      console.groupEnd();

      if (data.data.epcs.length > 0) {
        const tags = data.data.epcs.map((epc: string) => ({ epc }));
        dispatch(tagsMQTTDevice(tags));
      }

      if (isOperator) {
        if (
          data.data.status === 'TERMINATED' ||
          data.data.status === 'PENDING' ||
          data.data.status === 'COMPLETED'
        ) {
          history.push(AppRoutes.INTRO);
        }
      }
    });
  }, [signalr.connected, isOperator]);

  useAsync(async () => {
    // Send the epc codes read to other users
    if (isSohProcess && cycleCountTags.length > 0) {
      await signalr.send(signalrData);
    }
  }, [cycleCountTags.length]);

  useEffect(() => {
    if (finishButtonClicked) {
      if (store) {
        const { printers } = store;

        if (!printers || printers?.length === 0) {
          setNoPrinterAvailable(true);
        }
      }
    }
  }, [store, finishButtonClicked]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return (): void =>
      window.addEventListener('scroll', (): {} => handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (cycleCountTags.length > 0) {
      dispatch(findEpcThroughTags(cycleCountTags));
    }
  }, [cycleCountTags, dispatch]);

  useEffect(() => {
    if (isMissingItemsCycleCount) {
      dispatch(filterMissingProductsByMissingEpcs());
    }
  }, [dispatch, isMissingItemsCycleCount]);

  useAsync(async () => {
    await dispatch(findStockItems());
  }, []);

  const onCloseModalClick = (): void => {
    setConfirmModalVisibility(false);

    if (isSohProcess) {
      history.push(AppRoutes.SOH_ALIGNMENT);
    } else {
      history.push(AppRoutes.INTRO);
    }
  };

  const checkProcessOnGoing = async (): Promise<boolean> => {
    const processStatus: ProcessStatus[] =
      await ProcessService.processReadProcessStatus({
        storeCode: store?.storeCode || '',
        filterByStoreCode: true,
      });
    const sohAlignment = processStatus.find(
      ({ process }) => process === 'SOHA'
    );

    const bisInProgress = processStatus.find(
      ({ process }) => process === 'BIS'
    );

    if (sohAlignment && sohAlignment.isLocked) {
      setProcessLock(sohAlignment);
      setSohOnGoingModalVisibility(true);
      return true;
    }

    if (bisInProgress && bisInProgress.isLocked) {
      setProcessLock(bisInProgress);
      dispatch(setAvoidAlertInHomePage(true));
      setSohOnGoingModalVisibility(true);
      return true;
    }

    return false;
  };

  const onFinishScanClick = async (): Promise<void> => {
    setFinishButtonClicked(true);

    if (!isSohProcess) {
      const processOnGoing = await checkProcessOnGoing();
      if (processOnGoing) return;
    }
    try {
      const { tags: deviceTags, mode } = await ScanDevicesService.getTags({
        devId: deviceInUse?.deviceId!,
      });

      if (mode === 'no mode') {
        setFinishButtonClicked(false);
        setDeviceErrorMsg(t('error.deviceCommunication'));
        setDeviceError(true);
      }

      if (deviceTags && deviceTags.length > 0) {
        dispatch(tagsMQTTDevice(deviceTags));
      }

      setTimeout(async () => {
        const { code, message } = await ScanDevicesService.activateScanning({
          requestBody: {
            dev_id: deviceInUse?.deviceId!,
            enable: 'False',
          },
        });

        if (code !== 'OK') {
          setDeviceErrorMsg(message);
          setFinishButtonClicked(false);
          setDeviceError(true);
        }
      }, 1000);

      setTimeout(async () => {
        await dispatch(deleteTags());

        setFinishButtonClicked(false);
        setIsScanCompleted(true);
      }, 2000);
    } catch {
      setFinishButtonClicked(false);
      setDeviceError(true);
    }
  };

  const onUpdateClick = async (): Promise<void> => {
    const { tags: deviceTags } = await ScanDevicesService.getTags({
      devId: deviceInUse?.deviceId!,
    });
    if (deviceTags && deviceTags.length > 0) {
      dispatch(tagsMQTTDevice(deviceTags));
    }
  };

  const onFinishAndSeeResultsClick = async (): Promise<void> => {
    try {
      setSeeResultsLoading(true);

      if (deviceInUse) {
        await onFinishScanClick();
      }

      await signalr.send({
        ...signalrData,
        id: query.id as string,
      });

      setSeeResultsLoading(false);
      setIsFinishResultsButtonClicked(true);
    } catch {
      setSeeResultsLoading(false);
    }
  };

  const onConfirmClick = async (): Promise<void> => {
    if (store?.storeCode && username) {
      setConfirmButtonClicked(true);

      if (isSohProcess) {
        try {
          await confirmSoh();
          await signalr.unsubscribeStore();
          await handleProcess({ process: 'SOHA', isLocked: false });
          setConfirmButtonClicked(false);
          setConfirmModalVisibility(true);
        } catch (e) {
          const error = e as ApiError;

          if (error.status === 500) {
            setConfirmSohErrorModalIsVisible(true);
          } else {
            setConfirmSohError(true);
          }

          setConfirmButtonClicked(false);

          return;
        }
      } else {
        const processOnGoing = await checkProcessOnGoing();
        if (processOnGoing) return;
        try {
          await confirmCycleCount();
          await trackConfirm(startTimestamp);
          setConfirmButtonClicked(false);
          setConfirmModalVisibility(true);
        } catch {
          return setConfirmError(true);
        }
      }
    }
  };

  const onScanClick = (): void => {
    if (deviceInUse) {
      setIsScanningAgain(true);
    }

    setScanModalVisibility(true);
  };

  const showPrintError = (visible: boolean, message?: string): void => {
    setPrint(prevState => ({
      ...prevState,
      error: {
        visible,
        message,
      },
    }));
  };

  const onPrintModalClick = async (): Promise<void> => {
    try {
      if (print.error.message) {
        showPrintError(false);
      }

      if (store && store.storeCode && productToPrint) {
        const { upcCode, currency } = productToPrint;
        const reasonCode = print.reason
          .movementReasonCode as PrintReasonCodeStatus;

        const response = await dispatch(
          printLabel({
            storeCode: store.storeCode,
            layout: handlePrintLayout(
              currency || '',
              print.printer?.isRfid || false
            ),
            serialNumberPrinter: print.printer?.printerCode || '',
            upcCode: upcCode!,
            reasonCode,
            oldEpc,
          })
        ).unwrap();

        /**
         * Redux state is gonna change on the start use reader
         * if the selected printer is NO-RFID
         */
        if (print.printer?.isRfid) {
          CycleCountRedux.update(dispatch, {
            upcCode: upcCode!,
            printedEPC: response.epc || '',
            oldEpc,
            reasonCode: reasonCode || undefined,
          });

          setPrintModalVisibility(false);

          await trackPrint(upcCode!);
          setCheckedNotFoundUpcs({});
          setCheckedNotFoundEpcs({});
        } else {
          setPrint(prevState => ({
            ...prevState,
            data: {
              printedEPC: response.epc,
            },
          }));
        }
      }
    } catch {
      showPrintError(true, t('error.something_went_wrong'));
    }
  };

  /**
   * It's gonna be executed only if the selected printer
   * is NO-RFID and the active step of the print modal is 1
   */
  const onStartUseReaderClick = async (): Promise<void> => {
    if (print.printer && !print.printer.isRfid) {
      if (productToPrint) {
        const { upcCode } = productToPrint;
        const printedEPC = print.data.printedEPC || '';
        const reasonCode = print.reason
          .movementReasonCode as PrintReasonCodeStatus;

        CycleCountRedux.update(dispatch, {
          upcCode: upcCode!,
          printedEPC,
          oldEpc,
          reasonCode,
        });

        await trackPrint(upcCode!);
        setCheckedNotFoundUpcs({});
        setCheckedNotFoundEpcs({});
      }
    }
  };

  const onModalPrintDetailsClose = (): void => {
    setPrintModalVisibility(false);
    setProductToPrint(undefined);
  };

  const handleUndoSnackbarClose = (): void => {
    setCheckedNotFoundUpcsToUndo({});
    setCheckedNotFoundEpcsToUndo({});
    setCheckedUnexpectedUpcsToUndo({});
    setCheckedUnexpectedEpcsToUndo({});
    dispatch(setNotFoundProductsToUndo([]));
    dispatch(setUnexpectedProductsToUndo({ unexpected: [], found: [] }));
    setUndoSnackbarVisibility(false);
  };

  const handleUndoClick = (): void => {
    setCheckedNotFoundUpcs(checkedNotFoundUpcsToUndo);
    setCheckedNotFoundEpcs(checkedNotFoundEpcsToUndo);

    setCheckedUnexpectedUpcs(checkedUnexpectedUpcsToUndo);
    setCheckedUnexpectedEpcs(checkedUnexpectedEpcsToUndo);

    dispatch(undoNotFoundProducts());
    dispatch(undoUnexpectedProducts());

    const checkedEpcs = Object.values(checkedNotFoundEpcsToUndo).flat();
    const removedReason = notFoundEpcsWithReason.filter(
      epc => !checkedEpcs.includes(epc)
    );
    setNotFoundEpcsWithReason(removedReason || []);

    setUndoSnackbarVisibility(false);
  };

  const handleNotFoundModalReasonClose = (): void => {
    setNotFoundModalReasonVisibility(false);
  };

  const handleUnexpectedModalReasonClose = (): void => {
    setUnexpectedModalReasonVisibility(false);
  };

  const createCycleCountTitle = (): string => {
    if (isMissingItemsCycleCount) {
      return t('cycleCountMissingItems');
    }

    switch (filterSelected) {
      case 0:
        return `${t('cycleCount.of')} ${selectedBrands
          .map(({ brandDescription }) => `#${brandDescription}`)
          .join(', ')}`;

      case 1:
        return `${t('cycleCount.of')} #UPC ${selectedUpc.join(', ')}`;

      case 2:
        return `${t('cycleCount.of')} #EPC ${selectedEpc.join(', ')}`;

      default:
        const selectedModels = [
          ...new Set(selectedModel.map(upcModel => upcModel.split('|')[1])),
        ];

        return `${t('cycleCount.of')} #MODEL ${selectedModels.join(', ')}`;
    }
  };

  const onQuitClick = async (): Promise<void> => {
    if (isSohProcess) {
      if (!isOperator) {
        setQuitModalIsVisible(true);
      } else {
        if (deviceInUse) {
          await onFinishScanClick();
        }
        await signalr.unsubscribeStore();
        history.push(AppRoutes.INTRO);
      }
    } else {
      history.push(AppRoutes.INTRO);
    }
  };

  const onFinishAndExitClick = async (): Promise<void> => {
    try {
      setFinishAndExitLoading(true);

      if (deviceInUse) {
        await onFinishScanClick();
      }

      await signalr.unsubscribeStore();
      setFinishAndExitLoading(false);
      history.push(AppRoutes.INTRO);
    } catch {
      setFinishAndExitLoading(false);
    }
  };

  const deleteSoh = async (): Promise<void> => {
    try {
      setDeleteSohLoading(true);
      const stockOnHandId = query.id as string;

      await StockOnHandAlignmentService.stockonhandDeleteStockOnHand({
        storeCode: store?.storeCode || '',
        stockOnHandId,
      });

      await signalr.unsubscribeStore();
      await handleProcess({ process: 'SOHA', isLocked: false });

      setDeleteSohLoading(false);
      history.push(AppRoutes.INTRO);
    } catch {
      setDeleteSohLoading(false);
      setDeleteSohError(true);
    }
  };

  const confirmDisabled =
    found.length === 0 && notFound.length === 0 && checkoutError.length === 0;

  const cycleCountConfirmDisabled =
    confirmDisabled ||
    unexpected.length !== 0 ||
    notFoundWithReason.map(({ epcNotFound }) => epcNotFound).flat().length !==
      notFound.map(({ epcCodes }) => epcCodes).flat().length;

  const cta = (): { label: string; disabled: boolean } => {
    if (isSohProcess) {
      if (!isFinishResultsButtonClicked) {
        return {
          label: t('finishAndSeeResults'),
          disabled: seeResultsLoading,
        };
      } else {
        return {
          label: t('confirm'),
          disabled: confirmDisabled || unexpected.length !== 0,
        };
      }
    }

    return {
      label: t('confirm'),
      disabled: cycleCountConfirmDisabled,
    };
  };

  const disableProductsActions = (): boolean => {
    if (isSohProcess) {
      return inPause || !isScanCompleted || !isFinishResultsButtonClicked;
    }

    return inPause || !isScanCompleted;
  };

  return (
    <>
      <AlertUndoSnackbar
        open={undoSnackbarIsVisible}
        setIsOpen={setUndoSnackbarVisibility}
        onUndoClick={handleUndoClick}
        onClose={handleUndoSnackbarClose}
        message={t('notification.itemAdded')}
        margin={'0 0 100px'}
      />
      <ErrorSnackbar
        open={deviceError}
        setIsOpen={setDeviceError}
        errorMessage={deviceErrorMsg}
      />
      <ErrorSnackbar
        open={confirmError}
        setIsOpen={setConfirmError}
        errorMessage={
          confirmHasError?.body?.errorMessage ||
          confirmHasError?.body ||
          t('error.cycleCountConfirm')
        }
      />
      <ErrorSnackbar
        open={confirmSohError}
        setIsOpen={setConfirmSohError}
        errorMessage={t('error.sohConfirm')}
      />
      <ErrorSnackbar
        open={deleteSohError}
        setIsOpen={setDeleteSohError}
        errorMessage={t('error.sohDelete')}
      />
      <AlertSnackbar
        open={noPrinterAvailable}
        setIsOpen={setNoPrinterAvailable}
        message={t('error.noPrinterAvailable')}
      />
      <AlertSnackbar
        open={printInError}
        setIsOpen={setPrintInError}
        message={t('error.printGenericError')}
      />
      <ModalDataSavedError
        $minWidth="705px"
        $minHeight="260px"
        title={t('payAttention')}
        message={t('loseSohDataMessage')}
        open={quitModalIsVisible}
        onClose={(): void => setQuitModalIsVisible(false)}
      >
        <UIBox mt={4} alignItems="center" width="60%" alignSelf="end">
          <UIBox width="50%" mr={2}>
            <UIButton
              outlined
              label={t('no')}
              disabled={deleteSohLoading}
              onClick={(): void => setQuitModalIsVisible(false)}
            />
          </UIBox>
          <UIButton
            outlined
            label={t('yes')}
            loading={deleteSohLoading}
            disabled={deleteSohLoading}
            onClick={deleteSoh}
          />
        </UIBox>
      </ModalDataSavedError>
      <ModalDataSavedError
        $minWidth="705px"
        $minHeight="260px"
        title={t('payAttention')}
        message={t('finishAndExitSohaMessage')}
        open={finishAndExitModalIsVisible}
        onClose={(): void => setFinishAndExitModalIsVisible(false)}
      >
        <UIBox mt={4} alignItems="center" width="60%" alignSelf="end">
          <UIBox width="50%" mr={2}>
            <UIButton
              outlined
              label={t('no')}
              disabled={finishAndExitLoading}
              onClick={(): void => setFinishAndExitModalIsVisible(false)}
            />
          </UIBox>
          <UIButton
            outlined
            label={t('yes')}
            loading={finishAndExitLoading}
            disabled={finishAndExitLoading}
            onClick={onFinishAndExitClick}
          />
        </UIBox>
      </ModalDataSavedError>
      <ModalDataSavedError
        iconType="WARNING"
        $minWidth="705px"
        $minHeight="260px"
        title={t('payAttention')}
        message={t('error.submittingSohData')}
        open={confirmSohErrorModalIsVisible}
        onClose={(): void => setConfirmSohErrorModalIsVisible(false)}
      >
        <UIBox mt={4} alignItems="center" width="60%" alignSelf="end">
          <UIBox width="50%" mr={2}>
            <UIButton
              outlined
              label={t('quit')}
              onClick={deleteSoh}
              loading={deleteSohLoading}
              disabled={deleteSohLoading}
            />
          </UIBox>
          <UIButton
            outlined
            loading={cta().disabled}
            disabled={cta().disabled || deleteSohLoading}
            label={t('retryToSubmit')}
            onClick={onConfirmClick}
          />
        </UIBox>
      </ModalDataSavedError>
      <ModalDataSavedError
        iconType="ERROR"
        $minWidth="705px"
        $minHeight="260px"
        title={t('payAttention')}
        message={
          processLock?.process === 'SOHA'
            ? t('cycleCountInterruptedBySoh')
            : t('functionalitiesDisabled', {
                process: t(processLock?.process || ''),
                lockedBy: processLock?.lockedBy || '',
              })
        }
        open={sohOnGoingModalIsVisible}
        onClose={(): void => {
          setSohOnGoingModalVisibility(false);
          history.push(AppRoutes.INTRO);
        }}
      >
        <UIBox mt={4} alignItems="center" width="50%">
          <UIButton
            outlined
            label={t('quit')}
            onClick={(): void => history.push(AppRoutes.INTRO)}
          />
        </UIBox>
      </ModalDataSavedError>
      <ModalScanDetailsV2
        defaultMode={EnumMode.FIND}
        open={openScanModal}
        onClose={(): void => setScanModalVisibility(false)}
        isScanningAgain={isScanningAgain}
        setIsScanCompleted={setIsScanCompleted}
        scanAgainCounter={scanAgainCounter}
        setScanAgainCounter={setScanAgainCounter}
        onStartReader={(): void => {
          if (isSohProcess) {
            setIsScanningAgain(true);
            setScanModalVisibility(false);
            setIsScanCompleted(false);
          }
        }}
      />
      <ModalReason
        type={ModalReasonType.UNEXPECTED}
        open={isUnexpectedModalReasonVisibile}
        onClose={handleUnexpectedModalReasonClose}
      />
      <ModalReason
        type={ModalReasonType.NOTFOUND}
        open={isNotFoundModalReasonVisibile}
        onClose={handleNotFoundModalReasonClose}
      />
      <ModalDataSaved
        $minWidth="400px"
        $minHeight="160px"
        open={isConfirmModalVisible}
        onClose={onCloseModalClick}
        message={t('modal.datasaved')}
      />
      <ModalPrintTemp
        open={openPrintModal}
        onClose={onModalPrintDetailsClose}
        productToPrint={productToPrint}
        onPrintClick={onPrintModalClick}
        onStartClick={onStartUseReaderClick}
        hideReasons={isSohProcess}
      />
      <ModalProductDetails
        open={openProductModal}
        onClose={(): void => setProductModalVisibility(false)}
        productCode={productDetailsByUpc}
      />
      <ScanStatusBar
        sticky={sticky}
        inPause={inPause}
        setPause={setPause}
        foundItemsCount={foundItemsCount}
        notFoundItemsCount={
          isMissingItemsCycleCount ? missingItemsCount : notFoundItemsCount
        }
        unexpectedItemsCount={unexpectedItemsCount}
      />
      <StyledProductsWrapper>
        <StyledCycleCountWrapper>
          {!isSohProcess && (
            <StyledRecapWrapper>
              <Typography font="heavy" size="lg">
                {createCycleCountTitle()}
              </Typography>
            </StyledRecapWrapper>
          )}
          <StyledUpdateButton
            label={t('refresh')}
            startIcon={<RefreshIcon />}
            onClick={onUpdateClick}
            disabled={!deviceInUse}
          />
        </StyledCycleCountWrapper>
        {!isMissingItemsCycleCount && (
          <StyledSwitchWrapper>
            <UISwitch
              checked={otherItemsVisible}
              checkedLabel={`${t('otherItems')}: ${otherItems || 0}`}
              unCheckedLabel={`${t('otherItems')}: ${otherItems || 0}`}
              onChange={(): void => setOtherItemsVisibility(p => !p)}
            />
          </StyledSwitchWrapper>
        )}
        {otherItemsVisible && (
          <>
            {qualityInspection.length > 0 && (
              <ProductList
                hasSubheader
                type={Product.QUALITY_INSPECTION}
                disableActions={disableProductsActions()}
                data={qualityInspection}
                count={qualityInspectionItemsCount}
              />
            )}
            {damaged.length > 0 && (
              <ProductList
                hasSubheader
                type={Product.DAMAGED}
                disableActions={disableProductsActions()}
                data={damaged}
                count={damagedItemsCount}
              />
            )}
            {recalled.length > 0 && (
              <ProductList
                hasSubheader
                type={Product.RECALLED}
                disableActions={disableProductsActions()}
                data={recalled}
                count={recalledItemsCount}
              />
            )}
            {inTransit.length > 0 && (
              <ProductList
                hasSubheader
                type={Product.IN_TRANSIT}
                disableActions={disableProductsActions()}
                data={inTransit}
                count={inTransitItemsCount}
              />
            )}
            {checkoutError.length > 0 && (
              <ProductList
                hasSubheader
                type={Product.CHECKOUT_ERROR}
                disableActions={disableProductsActions()}
                data={checkoutError}
                count={checkoutErrorItemsCount}
              />
            )}
          </>
        )}
        {unexpected.length > 0 && (
          <ProductList
            type={Product.UNEXPECTED}
            disableActions={disableProductsActions()}
            data={unexpected}
            count={unexpectedItemsCount}
          />
        )}
        {isMissingItemsCycleCount && missing.length > 0 && (
          <ProductList
            type={Product.NOT_FOUND}
            disableActions={disableProductsActions()}
            data={missing}
            count={missingItemsCount}
          />
        )}
        {notFound.length > 0 && (
          <ProductList
            type={Product.NOT_FOUND}
            data={notFound}
            count={notFoundItemsCount}
            disableActions={disableProductsActions()}
          />
        )}
        {found.length > 0 && (
          <ProductList
            hasSubheader
            type={Product.FOUND}
            disableActions={disableProductsActions()}
            data={found}
            count={foundItemsCount}
          />
        )}
      </StyledProductsWrapper>
      {isOperator ? (
        <CTAContainer
          type="ONE_BUTTON"
          label={t('finishAndExit')}
          onClick={(): void => setFinishAndExitModalIsVisible(true)}
        />
      ) : (
        <>
          {!isScanCompleted ? (
            <CTAContainer
              type="FINISH SCAN"
              onClick={onFinishScanClick}
              disabled={inPause}
              loading={finishButtonClicked}
            />
          ) : (
            <CTAContainer
              type="THREE_BUTTONS"
              onQuitClick={onQuitClick}
              backButtonLabel={t('quit')}
              secondaryButtonLabel={t('scanagain')}
              mainButtonLabel={cta().label}
              onConfirmClick={
                isSohProcess && !isFinishResultsButtonClicked
                  ? onFinishAndSeeResultsClick
                  : onConfirmClick
              }
              onScanClick={onScanClick}
              disabled={inPause}
              disabledConfirm={cta().disabled}
              loadingConfirm={confirmButtonClicked}
            />
          )}
        </>
      )}
    </>
  );
};

export default PageCycleCountScanProducts;
