import { useMemo } from 'react';
import { SelectOption } from '@/types/selectOption';
import { useSelector } from './useSelector';
import { useTranslation } from 'react-i18next';

const useRolesOptions = (): SelectOption[] => {
  const { t } = useTranslation();
  const { defaultStore, storeBySalesOrganization } = useSelector(
    state => state.profiling.user.stores
  );

  const roles = useMemo(() => {
    if (defaultStore && storeBySalesOrganization) {
      const stores = [defaultStore, ...storeBySalesOrganization];

      const selectOptions: SelectOption[] = [
        ...new Set(stores.flatMap(({ roles }) => roles)),
      ].map(role => ({
        label: t(`${role?.toLowerCase()}`) || '',
        value: role || '',
      }));

      selectOptions.unshift({
        label: t('all'),
        value: 'all',
      });

      return selectOptions;
    }

    return [];
  }, [defaultStore, storeBySalesOrganization, t]);

  return roles;
};

export default useRolesOptions;
