import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { colors, palette } from '@/theme';

export const StyledProductItemWrapper = styled(UIBox)`
  flex-direction: column;

  &&& > * {
    &:first-child {
      padding: 12px 24px;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${palette.colors.lightGrey};
  }
`;

export const StyledHeader = styled(UIBox)`
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 24px;

  & > * {
    border-radius: 4px;
    border: 1px solid ${colors.primary};

    // user
    &:nth-child(1) {
      height: 92px;
      padding-left: 14px;
      padding-right: 14px;
    }

    // Multi Progress Bar
    &:nth-child(2) {
      height: 92px;
    }
  }
`;
