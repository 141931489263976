import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from '@/hooks/useSelector';
import { useAsync } from 'react-use';
import { useHistory } from 'react-router';
import { useInitApplication } from '@/hooks/useInitApplication';
import { useResetBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { ClientStatus, useSocketContext } from '@/context/socketContext';

import { AppFunctionalities, AppRoutes } from '@/app/routers';

import { PageLoader } from '@/components/ui/PageLoader';
import { UIMacroFlowMenu } from '@/components/layout/MacroFlowMenu';

import { UIButton } from '@/components/ui/Button';
import {
  isCountingAllowed,
  isCustomerReturns,
  isInventoryHider,
  isInventoryOperator,
  isInventoryOwner,
  isPrintersAllowed,
  isRecallAllowed,
  isReceivingAllowed,
  isRepricingAllowed,
  isSaleAllowed,
  isStockSummaryAllowed,
  isStoreDamages,
  isStoreDamagesValidate,
  isStoreTransferAllowed,
  isVirtualBuddy,
} from '@/utils/user';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { useAppDispatch } from '@/app/store';
import { userGetPermissions } from '@/features/user/userSlice';
import { UIBox } from '@/components/ui/Box';
import { receiveGetStore } from '@/features/stores/currentStoreSlice';
import useExpectionsNotification from '../hooks/useExpectionsNotification';
import { useSignalRContext } from '@/context/signalR';
import { useNotificationContext } from '@/context/notificationContext';
import { getDeliveryList } from '@/features/deliveries/deliveriesSlice';

//#region - Styled Components
const StyledLink = styled(Link)<{ disabled?: boolean }>`
  text-decoration: none;
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'auto')};
`;
//#endregion

const PageHome: FC<AppFunctionalities> = memo(
  ({ functionalitiesDisabled, process }): JSX.Element => {
    const { t } = useTranslation();
    const loggedUser = useSelector(state => state.user);
    const { connected: isSignalrConnected, disconnect: disconnectSignalr } =
      useSignalRContext();
    const { clientStatus, disconnect } = useSocketContext();
    const { isUpdatingDeviceStatus, deleteTagsIsLoading } = useSelector(
      state => state.devices
    );
    const { store } = useSelector(state => state.currentStore);
    const history = useHistory();
    const isLoading = loggedUser.isLoadingLogin;
    const errorCode = loggedUser.errorCode;
    const loginError = loggedUser.loginError;
    const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const sale = useExpectionsNotification('SLEX');
    const returnPos = useExpectionsNotification('RTPS');
    const initApplicationState = useInitApplication();
    const { inventoryRefused } = useNotificationContext();
    const { deliveryItems } = useSelector(state => state.deliveries);

    useResetBreadcrumbs();

    useAsync(async () => {
      await initApplicationState();
    }, []);

    useAsync(async () => {
      if (clientStatus === ClientStatus.CONNECTED) {
        await disconnect();
      }
    }, []);

    useAsync(async () => {
      if (isSignalrConnected) {
        await disconnectSignalr();
      }
    }, []);

    useEffect(() => {
      setIsErrorVisible(loginError);
    }, [loginError]);

    useEffect(() => {
      if (history) {
        if (!isLoading) {
          if (!store) {
            history.replace(AppRoutes.SETTINGS);
          }
        }
      }
    }, [history, isLoading, store]);

    useEffect(() => {
      if (store?.storeCode) {
        dispatch(userGetPermissions({ storeCode: store?.storeCode }));
        dispatch(receiveGetStore(store?.storeCode));
        dispatch(getDeliveryList());
      }
    }, [dispatch, store?.storeCode]);

    useEffect(() => {
      if (isVirtualBuddy(loggedUser) && loggedUser.permissions?.length === 1) {
        history.push(AppRoutes.INVENTORY_VIRTUAL_BUDDY);
      }
    }, [history, loggedUser]);

    if (
      [isUpdatingDeviceStatus, deleteTagsIsLoading].some(isLoading => isLoading)
    ) {
      return <PageLoader />;
    }

    const isInventoryViewer =
      isInventoryOperator(loggedUser) ||
      isInventoryOwner(loggedUser) ||
      isInventoryHider(loggedUser);

    if (isVirtualBuddy(loggedUser)) {
      return <div></div>;
    }

    const isBisOwner =
      process?.process === 'BIS' &&
      process.isLocked &&
      process.lockedBy === loggedUser.username;

    return (
      <>
        <UIBox padding={3} flexDirection="column">
          <UIMacroFlowMenu title={t('menu.title')}>
            {isReceivingAllowed(loggedUser) && (
              <StyledLink
                disabled={functionalitiesDisabled || inventoryRefused}
                to={AppRoutes.RECEIVING}
              >
                <UIButton
                  disabled={functionalitiesDisabled || inventoryRefused}
                  label={
                    deliveryItems.length > 0
                      ? `${t('menu.receiving')} ( ${deliveryItems.length} )`
                      : t('menu.receiving')
                  }
                  startIcon={
                    <>{deliveryItems.length > 0 && <sale.ExceptionsIcon />}</>
                  }
                  outlined={false}
                />
              </StyledLink>
            )}
            {isSaleAllowed(loggedUser) && (
              <StyledLink
                disabled={
                  !isBisOwner && (functionalitiesDisabled || inventoryRefused)
                }
                to={AppRoutes.SALE}
              >
                <UIButton
                  label={t('menu.sale')}
                  outlined={false}
                  startIcon={
                    <>{sale.exceptionsOccurred && <sale.ExceptionsIcon />}</>
                  }
                  disabled={
                    !isBisOwner && (functionalitiesDisabled || inventoryRefused)
                  }
                />
              </StyledLink>
            )}
            {isCountingAllowed(loggedUser) && (
              <StyledLink
                disabled={
                  (!['SOHA', 'CYCO'].includes(process?.process || '') &&
                    functionalitiesDisabled) ||
                  inventoryRefused
                }
                to={AppRoutes.COUNTING_ITEMS}
              >
                <UIButton
                  disabled={
                    (!['SOHA', 'CYCO'].includes(process?.process || '') &&
                      functionalitiesDisabled) ||
                    inventoryRefused
                  }
                  label={t('menu.countingItems')}
                  outlined={false}
                />
              </StyledLink>
            )}
            {isStoreTransferAllowed(loggedUser) && (
              <StyledLink
                disabled={functionalitiesDisabled || inventoryRefused}
                to={AppRoutes.STORE_TRANSFER_HISTORY}
              >
                <UIButton
                  disabled={functionalitiesDisabled || inventoryRefused}
                  label={t('menu.store_transfer')}
                  outlined={false}
                />
              </StyledLink>
            )}
            {isStockSummaryAllowed(loggedUser) && (
              <StyledLink
                disabled={functionalitiesDisabled || inventoryRefused}
                to={AppRoutes.STOCK_SUMMARY}
              >
                <UIButton
                  label={t('menu.stock_summary')}
                  outlined={false}
                  disabled={functionalitiesDisabled || inventoryRefused}
                />
              </StyledLink>
            )}
            {isPrintersAllowed(loggedUser) && (
              <StyledLink
                disabled={functionalitiesDisabled || inventoryRefused}
                to={AppRoutes.PRINT}
              >
                <UIButton
                  label={t('menu.print_new_tag')}
                  outlined={false}
                  disabled={functionalitiesDisabled || inventoryRefused}
                />
              </StyledLink>
            )}
            {isCustomerReturns(loggedUser) && (
              <StyledLink
                disabled={functionalitiesDisabled || inventoryRefused}
                to={AppRoutes.CUSTOMER_RETURNS_INTRO}
              >
                <UIButton
                  label={t('menu.customerReturns')}
                  outlined={false}
                  startIcon={
                    <>
                      {returnPos.exceptionsOccurred && (
                        <returnPos.ExceptionsIcon />
                      )}
                    </>
                  }
                  disabled={functionalitiesDisabled || inventoryRefused}
                />
              </StyledLink>
            )}
            {isStoreDamages(loggedUser) && (
              <StyledLink
                disabled={functionalitiesDisabled || inventoryRefused}
                to={
                  isStoreDamagesValidate(loggedUser)
                    ? AppRoutes.STORE_DAMAGES_INTRO
                    : AppRoutes.STORE_DAMAGES_CREATE
                }
              >
                <UIButton
                  label={t('menu.inStoreDamages')}
                  outlined={false}
                  disabled={functionalitiesDisabled || inventoryRefused}
                />
              </StyledLink>
            )}
            {isRecallAllowed(loggedUser) && (
              <StyledLink
                disabled={functionalitiesDisabled || inventoryRefused}
                to={AppRoutes.RECALL}
              >
                <UIButton
                  label={t('menu.recall')}
                  outlined={false}
                  disabled={functionalitiesDisabled || inventoryRefused}
                />
              </StyledLink>
            )}
            {isInventoryViewer && (
              <StyledLink
                disabled={functionalitiesDisabled}
                to={AppRoutes.INVENTORY}
              >
                <UIButton
                  label={t('menu.inventory')}
                  outlined={false}
                  disabled={functionalitiesDisabled}
                />
              </StyledLink>
            )}
            {isRepricingAllowed(loggedUser) && (
              <StyledLink
                disabled={functionalitiesDisabled || inventoryRefused}
                to={AppRoutes.REPRICING}
              >
                <UIButton
                  label={t('menu.repricing')}
                  outlined={false}
                  disabled={functionalitiesDisabled || inventoryRefused}
                />
              </StyledLink>
            )}
            <ErrorSnackbar
              open={isErrorVisible}
              setIsOpen={setIsErrorVisible}
              errorMessage={
                errorCode ? t(`error.code_${errorCode}`) : t('error.login')
              }
            />
          </UIMacroFlowMenu>
        </UIBox>
      </>
    );
  }
);

PageHome.displayName = 'PageHome';
export default PageHome;
