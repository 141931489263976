import styled from 'styled-components';
import Link from '@material-ui/core/Link';

export const RoleListItemStyled = styled.li`
  width: 100%;
  flex-wrap: wrap;
  padding: 16px 24px;
  background: #fbfbfb;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
export const LabelContainerStyled = styled.div``;

export const ActionContainerStyled = styled.div`
  display: flex;
  gap: 8px;
`;

export const LinkEditStyled = styled(Link)`
  display: flex;
  gap: 4px;
  font-weight: bold;
`;
