import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation, Trans } from 'react-i18next';

import { SvgIcon } from '@material-ui/core';
import { CalendarTodayRounded } from '@material-ui/icons';

import { Typography } from '@/components/ui/Typography';
import { CycleCountItems } from '@/api';
import ColorDatas from '@/components/ui/ColorDatas/ColorDatas';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { CYCLE_COUNT_STATUS_CONFIRMED } from '@/configs/settings';
import {
  StyledAccordionItemContent,
  StyledAccordionWrapper,
  StyledBrand,
  StyledCycleCountChip,
  StyledCycleCountItemBrand,
  StyledCycleCountItemBrandInfo,
  StyledCycleCountItemDate,
  StyledCycleCountItemInfo,
  StyledCycleCountItemWrapper,
  StyledStatus,
} from './style';
import { UIAccordion } from '@/components/ui/Accordion';
import { UIBox } from '@/components/ui/Box';

type FilterValues = 'brandList' | 'epcList' | 'modelList' | 'upcList';

type ListFilter = {
  [key in FilterValues]?: string[] | undefined;
};

export const CycleCountListItem: FC<CycleCountItems> = memo(
  ({
    brandList,
    epcList,
    modelList,
    upcList,
    itemsFound,
    found,
    date,
    status,
    notfound,
    unexpected,
    isMissingCC,
  }) => {
    const { t, i18n } = useTranslation();
    const [accordionIsVisible, setAccordionIsVisible] =
      useState<boolean>(false);
    const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false);
    const [accordionItems, setAccordionItems] = useState<string[]>([]);
    const cycleCountdate = DateTime.fromISO(date!).toFormat('yyyy LLLL dd', {
      locale: i18n.language,
    });

    const listFilters: ListFilter = useMemo(
      () => ({
        brandList,
        epcList,
        modelList,
        upcList,
      }),
      [brandList, epcList, modelList, upcList]
    );

    const cycleCountFilters: () => ListFilter = useCallback(() => {
      let filters: ListFilter = {};
      let key: FilterValues;
      let filterKeys = Object.keys(listFilters) as (keyof ListFilter)[];

      for (key of filterKeys) {
        const filterValues = listFilters[key];

        if (filterValues && filterValues.length > 0) {
          filters = { [key]: filterValues };
        }
      }

      return filters;
    }, [listFilters]);

    const cycleCountTitle = (): string => {
      const filters = cycleCountFilters();
      const filter = Object.keys(filters)[0] as FilterValues;

      const createTitle = (value: FilterValues, prefix: string): string => {
        const results = (listFilters[value]?.length || 0) - 1;
        const title = listFilters[value]?.[0];
        const missingPrefix = `${t('page.missingitems')} ${prefix}`;

        return results > 0
          ? `${isMissingCC ? missingPrefix : prefix} ${title} (+${results})`
          : `${prefix} ${title}`;
      };

      switch (filter) {
        case 'epcList':
          return createTitle(filter, 'EPC');
        case 'upcList':
          return createTitle(filter, 'UPC');
        default:
          return createTitle(filter, `#`);
      }
    };

    useEffect(() => {
      const filters = cycleCountFilters();
      const filter = Object.keys(filters)[0] as FilterValues;
      const values = filters[filter] || [];

      if (values.length > 1) {
        setAccordionIsVisible(true);
        setAccordionItems(values.slice(1, values.length));
      }
    }, [cycleCountFilters]);

    return (
      <StyledCycleCountItemWrapper flexDirection="column">
        <UIBox width="100%" justifyContent="space-between" alignItems="center">
          {accordionIsVisible && (
            <StyledAccordionWrapper>
              <UIAccordion
                expanded={accordionExpanded}
                onClick={(): void => setAccordionExpanded(p => !p)}
              />
            </StyledAccordionWrapper>
          )}
          <StyledCycleCountItemInfo>
            <StyledCycleCountItemDate>
              <SvgIcon component={CalendarTodayRounded}></SvgIcon>
              <Typography size="xs" font="book">
                {cycleCountdate}
              </Typography>
            </StyledCycleCountItemDate>
            <StyledCycleCountChip
              size="small"
              label={<Trans i18nKey="itemsFound" values={{ itemsFound }} />}
            />
          </StyledCycleCountItemInfo>
          <StyledCycleCountItemBrandInfo $accordion={accordionIsVisible}>
            <StyledCycleCountItemBrand>
              <StyledBrand font="heavy" size="lg">
                {cycleCountTitle()}
              </StyledBrand>
            </StyledCycleCountItemBrand>
            <ColorDatas
              datas={[
                {
                  name: t('cycleCountItem.found'),
                  value: found!,
                  color: '#417505',
                },
                {
                  name: t('cycleCountItem.notFound'),
                  value: notfound!,
                  color: '#E20404',
                },
              ]}
            />
            {unexpected! > 0 && (
              <UIBox mt={1} alignItems="center">
                <Typography size="xs" color="grey">
                  {t('previouslyUnexpected')}:
                </Typography>
                <Typography size="xs" margin="0 0 0 4px">
                  {unexpected}
                </Typography>
              </UIBox>
            )}
          </StyledCycleCountItemBrandInfo>
          <StyledStatus font="book" size="md">
            {status && t(`cycleCountItem.${status.toLowerCase()}`)}
            {status === CYCLE_COUNT_STATUS_CONFIRMED ? (
              <CheckCircleIcon style={{ color: '#417505' }} />
            ) : (
              <MoreHorizIcon fontSize="large" />
            )}
          </StyledStatus>
        </UIBox>
        {accordionItems.length > 0 &&
          accordionExpanded &&
          accordionItems.map(item => (
            <StyledAccordionItemContent key={item} $missingCC={isMissingCC}>
              <UIBox alignItems="center">
                <Typography color="grey">{item}</Typography>
              </UIBox>
            </StyledAccordionItemContent>
          ))}
      </StyledCycleCountItemWrapper>
    );
  }
);

CycleCountListItem.displayName = 'CycleCountListItem';

export default CycleCountListItem;
