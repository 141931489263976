import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useSelector } from '@/hooks/useSelector';
import useSROTrackEvent from '@/hooks/useSROTrackEvent';
import { usePrintContext } from '@/context/Print';

type TrackPrintNewTag = {
  User: string;
  StoreCode: string;
  upcCodePrinted: string;
  epcCodePrinted: string;
  LinkedEvent: string;
};

type ReturnHookType = (upcCode: string, epcCode?: string) => Promise<void>;

const useTrackPrint = (): ReturnHookType => {
  const appInsights = useAppInsightsContext();
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const { username } = useSelector(state => state.user);
  const { print } = usePrintContext();

  const data: TrackPrintNewTag = {
    User: username || '',
    StoreCode: storeCode || '',
    upcCodePrinted: '',
    epcCodePrinted: print.data.printedEPC || '',
    LinkedEvent: `${username}-${sessionStorage.getItem('linkedEventId')}`,
  };

  const trackEvent = useSROTrackEvent<TrackPrintNewTag>(
    appInsights,
    'PrintNewTag',
    data
  );

  const trackPrint = async (
    upcCode: string,
    epcCode?: string
  ): Promise<void> => {
    await trackEvent({
      ...data,
      upcCodePrinted: upcCode,
      epcCodePrinted:
        epcCode?.toUpperCase() || print.data.printedEPC?.toUpperCase() || '',
    });
  };

  return trackPrint;
};

export default useTrackPrint;
