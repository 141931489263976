import { DateTime } from 'luxon';

const getCurrentDate = (): string => {
  const year = DateTime.now().year;
  const month = DateTime.now().month;
  const day = DateTime.now().day;

  return `${year}${String(month).padStart(2, '0')}${String(day).padStart(
    2,
    '0'
  )}`;
};

export default getCurrentDate;
