/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateScanningResponse } from '../models/ActivateScanningResponse';
import type { DeleteTagsOut } from '../models/DeleteTagsOut';
import type { DevStatusOut } from '../models/DevStatusOut';
import type { SearchScanDevices } from '../models/SearchScanDevices';
import type { SendTagsOut } from '../models/SendTagsOut';
import type { SetFilterOut } from '../models/SetFilterOut';
import type { SetFuncModeOut } from '../models/SetFuncModeOut';
import type { WriteTagOut } from '../models/WriteTagOut';
import { request as __request } from '../core/request';

export class ScanDevicesService {
  /**
   * Return all devices by storeId
   * @returns SearchScanDevices Default Response
   * @throws ApiError
   */
  public static async searchScanDevices({
    requestBody,
  }: {
    requestBody?: {
      deviceList?: Array<{
        deviceId: string;
        /**
         * device status
         */
        statusDevice?: 'online' | 'offline' | 'busy';
        statusMode?: string;
        storeId: string;
        nameDevice: string;
        userName?: string;
      }>;
    };
  }): Promise<SearchScanDevices> {
    const result = await __request({
      method: 'POST',
      path: `/api/v1/scan-devices/search`,
      body: requestBody,
    });
    return result.body;
  }

  /**
   * @returns DevStatusOut Default Response
   * @throws ApiError
   */
  public static async scanDeviceStatus({
    requestBody,
  }: {
    requestBody?: {
      dev_id: string;
    };
  }): Promise<DevStatusOut> {
    const result = await __request({
      method: 'POST',
      path: `/api/v1/scan-devices/status`,
      body: requestBody,
      errors: {
        422: `Default Response`,
      },
    });
    return result.body;
  }

  /**
   * @returns SetFuncModeOut Default Response
   * @throws ApiError
   */
  public static async setFunctionMode({
    requestBody,
  }: {
    requestBody?: {
      dev_id: string;
      mode: string;
    };
  }): Promise<SetFuncModeOut> {
    const result = await __request({
      method: 'POST',
      path: `/api/v1/scan-devices/functionMode`,
      body: requestBody,
      errors: {
        422: `Unprocessable Entity`,
      },
    });
    return result.body;
  }

  /**
   * @returns any Default Response
   * @throws ApiError
   */
  public static async getTags({ devId }: { devId: string }): Promise<{
    dev_id?: string;
    mode?: string;
    tags?: Array<{
      epc: string;
    }>;
  }> {
    const result = await __request({
      method: 'GET',
      path: `/api/v1/scan-devices/tags/${devId}`,
      errors: {
        422: `Unprocessable Entity`,
      },
    });
    return result.body;
  }

  /**
   * @returns SendTagsOut Default Response
   * @throws ApiError
   */
  public static async sendTags({
    requestBody,
  }: {
    requestBody?: {
      dev_id?: string;
      mode?: string;
      tags?: Array<{
        epc?: string;
      }>;
    };
  }): Promise<SendTagsOut> {
    const result = await __request({
      method: 'POST',
      path: `/api/v1/scan-devices/tags`,
      body: requestBody,
      errors: {
        422: `Default Response`,
      },
    });
    return result.body;
  }

  /**
   * @returns DeleteTagsOut Default Response
   * @throws ApiError
   */
  public static async deleteTags({
    requestBody,
  }: {
    requestBody?: {
      dev_id: string;
    };
  }): Promise<DeleteTagsOut> {
    const result = await __request({
      method: 'DELETE',
      path: `/api/v1/scan-devices/tags`,
      body: requestBody,
      errors: {
        422: `Default Response`,
      },
    });
    return result.body;
  }

  /**
   * @returns WriteTagOut Default Response
   * @throws ApiError
   */
  public static async writeTags({
    requestBody,
  }: {
    requestBody?: {
      dev_id: string;
      epc: string;
    };
  }): Promise<WriteTagOut> {
    const result = await __request({
      method: 'POST',
      path: `/api/v1/scan-devices/tags/write`,
      body: requestBody,
      errors: {
        422: `Default Response`,
      },
    });
    return result.body;
  }

  /**
   * @returns SetFilterOut Default Response
   * @throws ApiError
   */
  public static async setFilter({
    requestBody,
  }: {
    requestBody?: {
      dev_id: string;
      /**
       * An enumeration.
       */
      enable: 'False' | 'True';
      filter: Array<string>;
    };
  }): Promise<SetFilterOut> {
    const result = await __request({
      method: 'POST',
      path: `/api/v1/scan-devices/filter`,
      body: requestBody,
      errors: {
        422: `Default Response`,
      },
    });
    return result.body;
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static async startReader({
    requestBody,
  }: {
    requestBody?: {
      deviceId: string;
      statusMode:
        | 'inventory'
        | 'find'
        | 'scanning'
        | 'radar'
        | 'write'
        | 'no mode'
        | 'busy';
      statusDevice: 'online' | 'offline' | 'busy';
      storeId: string;
      nameDevice: string;
      userName: string;
      tags?: Array<{
        epc: string;
      }>;
    };
  }): Promise<void> {
    const result = await __request({
      method: 'POST',
      path: `/api/v1/scan-devices/startReader`,
      body: requestBody,
      errors: {
        400: `Default Response`,
      },
    });
    return result.body;
  }

  /**
   * @returns ActivateScanningResponse Default Response
   * @throws ApiError
   */
  public static async activateScanning({
    requestBody,
  }: {
    requestBody?: {
      dev_id: string;
      enable: string;
    };
  }): Promise<ActivateScanningResponse> {
    const result = await __request({
      method: 'POST',
      path: `/api/v1/scan-devices/activate-scanning`,
      body: requestBody,
    });
    return result.body;
  }
}
