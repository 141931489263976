/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Device } from '../models/Device';
import type { DeviceList } from '../models/DeviceList';
import type { FindDevice } from '../models/FindDevice';
import { request as __request } from '../core/request';

export class ScanDeviceService {
  /**
   * create scan device
   * create scan device
   * @returns any Device created.
   * @throws ApiError
   */
  public static async scandeviceCreateScanDevice({
    requestBody,
  }: {
    requestBody: Device;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/scandevice/createScanDevice`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
      },
    });
    return result.body;
  }

  /**
   * return device status
   * Return device status
   * @returns FindDevice OK
   * @throws ApiError
   */
  public static async scandeviceReadScanDevice({
    deviceId,
    storeId,
  }: {
    /** pass deviceId **/
    deviceId: string;
    /** store id **/
    storeId: string;
  }): Promise<FindDevice> {
    const result = await __request({
      method: 'GET',
      path: `/scandevice/readScanDevice`,
      query: {
        deviceId: deviceId,
        storeId: storeId,
      },
      errors: {
        400: `Bad request. deviceId and storeId are mandatory.`,
      },
    });
    return result.body;
  }

  /**
   * get info by device id
   * get info by device id
   * @returns Device Ok
   * @throws ApiError
   */
  public static async scandeviceGetDeviceIdInfo({
    deviceId,
  }: {
    /** pass deviceId **/
    deviceId: string;
  }): Promise<Device> {
    const result = await __request({
      method: 'GET',
      path: `/scandevice/getDeviceIdInfo`,
      query: {
        deviceId: deviceId,
      },
      errors: {
        400: `Bad request.`,
        403: `Forbidden.`,
        500: `Internal server error.`,
      },
    });
    return result.body;
  }

  /**
   * update scan device
   * update device
   * @returns any Scan device updated.
   * @throws ApiError
   */
  public static async scandeviceUpdateScanDevice({
    requestBody,
  }: {
    requestBody?: Device;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/scandevice/updateScanDevice`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
      },
    });
    return result.body;
  }

  /**
   * delete scan device
   * delete scan device
   * @returns any Scan device successfully deleted.
   * @throws ApiError
   */
  public static async scandeviceDeleteScanDevice({
    deviceId,
    storeId,
  }: {
    /** pass deviceId **/
    deviceId: string;
    /** store id **/
    storeId: string;
  }): Promise<any> {
    const result = await __request({
      method: 'DELETE',
      path: `/scandevice/deleteScanDevice`,
      query: {
        deviceId: deviceId,
        storeId: storeId,
      },
      errors: {
        400: `Bad request. deviceId and storeId are mandatory.`,
      },
    });
    return result.body;
  }

  /**
   * return all devices by storeId
   * Return all devices by storeId
   * @returns DeviceList OK
   * @throws ApiError
   */
  public static async scandeviceSearchListScanDevices({
    storeId,
  }: {
    /** storeId **/
    storeId: string;
  }): Promise<DeviceList> {
    const result = await __request({
      method: 'GET',
      path: `/scandevice/searchListScanDevices`,
      query: {
        storeId: storeId,
      },
      errors: {
        400: `Bad request. storeId is mandatory.`,
      },
    });
    return result.body;
  }
}
