import {
  ScanDevicesService,
  StartReader,
  SearchScanDevices,
  DeleteTagsOut,
  SetFuncModeOut,
  WriteTagOut,
  Device,
} from '@/api';
import { PrintService, ScanDeviceService } from '@/api/receive';
import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  Dispatch,
} from '@reduxjs/toolkit';
import { DevicesSlice, ScanDevice } from '@/types/device';
import { RootState } from '@/app/rootReducer';

import type { BFFApiError as ApiError } from '@/api';
import { DeviceStatus as MQTTDevice } from '@/hooks/mqtt/types';
import { Epc } from '@/api/bff';
import { EnumMode } from '@/types/enum';

const initialState: DevicesSlice = {
  startReaderIsLoading: false,
  startReaderHasError: undefined,
  startReaderFullfilled: false,
  isLoadingDeviceStatus: false,
  hasDeviceStatusError: false,
  isUpdatingDeviceStatus: false,
  hasDeviceUpdateError: false,
  deviceList: [],
  deviceInUse: undefined,
  setFunctionModeIsLoading: false,
  deleteTagsIsLoading: false,
  isCreatingDevice: false,
  isCreatedDevice: false,
  errorCreatingDevice: undefined,
  isDeleatingDevice: false,
  isDeleatedDevice: false,
  errorDeleatingDevice: undefined,
  isUpdatingDevice: false,
  isUpdatedDevice: false,
  errorUpdatingDevice: undefined,
  tags: [],
};

export const deleteTags = createAsyncThunk<
  DeleteTagsOut | undefined,
  void,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>('devices/deleteTags', async (_, { getState, rejectWithValue }) => {
  const {
    devices: { deviceInUse },
  } = getState();

  if (deviceInUse) {
    try {
      return await ScanDevicesService.deleteTags({
        requestBody: { dev_id: deviceInUse.deviceId },
      });
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
});

export const setFunctionMode = createAsyncThunk<
  SetFuncModeOut,
  NonNullable<
    Parameters<typeof ScanDevicesService.setFunctionMode>[number]['requestBody']
  >,
  {
    rejectValue: ApiError;
  }
>('devices/setFunctionMode', async (requestBody, { rejectWithValue }) => {
  try {
    return await ScanDevicesService.setFunctionMode({
      requestBody,
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const writeTags = createAsyncThunk<
  WriteTagOut | undefined,
  NonNullable<
    Parameters<typeof ScanDevicesService.writeTags>[number]['requestBody']
  > & {
    upc?: string;
    reason?: 'Printed' | 'Damaged' | 'Missing';
    storeCode?: string;
    confirmPrint?: boolean;
  },
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('devices/writeTags', async (requestBody, { getState, rejectWithValue }) => {
  const {
    devices: { deviceList },
  } = getState();

  const { confirmPrint = false } = requestBody;

  const index = deviceList.findIndex(
    device => device.deviceId === requestBody.dev_id
  );

  const currentDeviceStatus = deviceList[index];

  if (['online', 'busy'].includes(currentDeviceStatus.status)) {
    try {
      const response = await ScanDevicesService.writeTags({
        requestBody: {
          dev_id: requestBody.dev_id,
          epc: requestBody.epc,
        },
      });

      if (
        confirmPrint &&
        requestBody.storeCode &&
        requestBody.upc &&
        response.code === 'OK'
      ) {
        await PrintService.receiveshipConfirmPrintDetails({
          requestBody: {
            storeId: requestBody.storeCode,
            epcCode: requestBody.epc.toUpperCase(),
            upcCode: requestBody.upc,
            reason: requestBody.reason || 'Printed',
          },
        });
      }

      return response;
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
});

export const startReader = createAsyncThunk<
  void,
  StartReader,
  { rejectValue: ApiError; dispatch: Dispatch }
>('devices/startReader', async (requestBody, { dispatch, rejectWithValue }) => {
  try {
    const response = await ScanDevicesService.startReader({
      requestBody,
    });

    dispatch(
      addDeviceInUse({
        ...requestBody,
        battery: 0,
        status: requestBody.statusDevice,
        mode: requestBody.statusMode,
        num_tag: '0',
      })
    );

    return response;
  } catch (err) {
    const error = err as ApiError;
    if (
      requestBody.tags?.length === 0 &&
      requestBody.statusMode === EnumMode.FIND
    ) {
      return rejectWithValue({
        ...error,
        body: {
          message: 'No items to found',
        },
      });
    }
    return rejectWithValue(error);
  }
});

export const getAllDevicesStatus = createAsyncThunk<
  SearchScanDevices | undefined,
  void,
  {
    state: RootState;
  }
>(
  'getAllDevicesStatus',
  async (_, { getState }) => {
    const { currentStore, devices } = getState();

    if (
      !devices.isLoadingDeviceStatus ||
      !devices.isUpdatingDeviceStatus ||
      !devices.hasDeviceUpdateError ||
      !devices.hasDeviceStatusError
    )
      if (currentStore?.store) {
        const { storeCode } = currentStore.store;

        if (storeCode) {
          const response =
            await ScanDeviceService.scandeviceSearchListScanDevices({
              storeId: storeCode,
            });
          if (response) {
            const devices = await ScanDevicesService.searchScanDevices({
              requestBody: response,
            });
            return devices;
          }
          return [];
        }
      }
  },
  {
    condition: (_, { getState }) => {
      const { devices } = getState();
      if (devices.isLoadingDeviceStatus) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

export const createDevice = createAsyncThunk<
  void,
  Parameters<
    typeof ScanDeviceService.scandeviceCreateScanDevice
  >[number]['requestBody'],
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('scandevice/createScanDevice', async (requestBody, { rejectWithValue }) => {
  try {
    return await ScanDeviceService.scandeviceCreateScanDevice({
      requestBody,
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const updateDevice = createAsyncThunk<
  void,
  Parameters<
    typeof ScanDeviceService.scandeviceUpdateScanDevice
  >[number]['requestBody'],
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('scandevice/updateScanDevice', async (requestBody, { rejectWithValue }) => {
  try {
    return await ScanDeviceService.scandeviceUpdateScanDevice({
      requestBody,
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const deleteDevice = createAsyncThunk<
  void,
  Parameters<typeof ScanDeviceService.scandeviceDeleteScanDevice>[number],
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>(
  'scandevice/deleteScanDevice',
  async ({ deviceId, storeId }, { rejectWithValue }) => {
    try {
      return await ScanDeviceService.scandeviceDeleteScanDevice({
        deviceId,
        storeId,
      });
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

// export const updateDeviceStatus = createAsyncThunk<
//   void,
//   NonNullable<
//     Parameters<
//       typeof ScanDeviceService.scandeviceUpdateScanDevice
//     >[number]['requestBody']
//   >,
//   {
//     state: RootState;
//     rejectValue: ApiError;
//   }
// >('updateDeviceStatus', async (requestBody, { getState, rejectWithValue }) => {
//   const {
//     user: { username },
//   } = getState();

//   if (username) {
//     console.log(requestBody.userName);
//     try {
//       return await ScanDeviceService.scandeviceUpdateScanDevice({
//         requestBody: {
//           ...requestBody,
//           userName: requestBody.userName || username,
//         },
//       });
//     } catch (error) {
//       return rejectWithValue(error as ApiError);
//     }
//   }
// });

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    removeTag: (state, { payload }: PayloadAction<string>) => {
      state.tags = state.tags.filter(
        epc => epc.toUpperCase() !== payload.toUpperCase()
      );
    },
    removeTags: (state, { payload }: PayloadAction<string[]>) => {
      state.tags = state.tags.filter(
        epc => !payload.includes(epc.toUpperCase())
      );
    },
    initMQTTTags: state => {
      state.tags = [];
    },
    tagsMQTTDevice: (state, { payload }: PayloadAction<Epc[]>) => {
      const flatTags = payload.map(({ epc }): string => epc.toUpperCase());
      const concatTags = state.tags.concat(flatTags);

      state.tags = [...new Set(concatTags)];
    },
    updateMQTTDevice: (
      state,
      {
        payload,
      }: PayloadAction<
        MQTTDevice & { topic: string; storeId: string; userName: string }
      >
    ) => {
      const id = payload.topic.split('/')[1];
      const deviceIndex = state.deviceList.findIndex(
        ({ deviceId }) => deviceId === id
      );

      if (deviceIndex !== -1) {
        const update = {
          deviceId: id,
          nameDevice: state.deviceList[deviceIndex].nameDevice,
          battery: Number(payload.battery),
          mode: payload.mode,
          num_tag: payload.statusNumTagMem,
          status: payload.status,
          storeId: payload.storeId,
          userName: payload.userName,
        };
        state.deviceList[deviceIndex] = update;
        if (state.deviceInUse?.deviceId === id) {
          state.deviceInUse = update;
        }
      }
    },
    updateDeviceStatus: (state, { payload }: PayloadAction<Device>) => {
      const index = state.deviceList.findIndex(
        device => device.deviceId === payload.deviceId
      );
      if (index !== -1) {
        const currentDeviceStatus = state.deviceList[index];
        state.deviceList[index] = {
          ...currentDeviceStatus,
          ...payload,
        };
      }
      state.isUpdatingDeviceStatus = false;
      state.hasDeviceUpdateError = false;
    },
    addDeviceInUse: (state, { payload }: PayloadAction<ScanDevice>) => {
      sessionStorage.setItem('deviceInUse', JSON.stringify(payload || ''));

      state.deviceInUse = { ...payload, status: 'busy' };
    },
    removeDeviceInUse: state => {
      if (state.deviceInUse) {
        state.deviceList = state.deviceList.map(device => {
          if (device.deviceId === state.deviceInUse?.deviceId) {
            return {
              ...device,
              // status: 'online',
            };
          }

          return device;
        });

        state.deviceInUse = undefined;
        sessionStorage.removeItem('deviceInUse');
      }
    },
    initDevices: state => ({ ...initialState, deviceList: state.deviceList }),
    resetWriteTagsState: state => {
      state.writeTagsHasError = undefined;
      state.writeTagsIsLoading = false;
    },
    resetCRUDDevice: state => ({
      ...state,
      isCreatingDevice: false,
      isCreatedDevice: false,
      errorCreatingDevice: undefined,
      isDeleatingDevice: false,
      isDeleatedDevice: false,
      errorDeleatingDevice: undefined,
      isUpdatingDevice: false,
      isUpdatedDevice: false,
      errorUpdatingDevice: undefined,
      writeTagsIsLoading: false,
      writeTagsHasError: undefined,
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(startReader.pending, state => {
        state.startReaderIsLoading = true;
        state.startReaderFullfilled = false;
      })
      .addCase(startReader.fulfilled, (state, { meta }) => {
        const index = state.deviceList.findIndex(
          device => device.deviceId === meta.arg.deviceId
        );
        const currentDeviceStatus = state.deviceList[index];

        state.startReaderIsLoading = false;
        state.startReaderHasError = undefined;
        state.deviceList[index] = {
          ...currentDeviceStatus,
          status: 'busy',
          userName: meta.arg.userName,
        };
        state.startReaderFullfilled = true;
      })
      .addCase(startReader.rejected, (state, { payload }) => {
        state.startReaderIsLoading = false;
        if (payload) {
          state.startReaderHasError = {
            body: payload.body,
            message: payload.message,
            status: payload.status,
            statusText: payload.statusText,
            url: payload.url,
            name: payload.name,
          };
        }
        state.startReaderFullfilled = false;
      })
      .addCase(deleteTags.pending, state => {
        state.deleteTagsIsLoading = true;
        state.deleteTagsHasError = undefined;
      })
      .addCase(deleteTags.fulfilled, state => {
        state.deleteTagsIsLoading = false;
      })
      .addCase(deleteTags.rejected, (state, { payload }) => {
        state.deleteTagsIsLoading = false;
        state.deleteTagsHasError = payload;
      })
      .addCase(setFunctionMode.pending, state => {
        state.setFunctionModeIsLoading = true;
        state.setFunctionModeHasError = undefined;
      })
      .addCase(setFunctionMode.fulfilled, state => {
        state.setFunctionModeIsLoading = false;
        state.setFunctionModeHasError = undefined;
      })
      .addCase(setFunctionMode.rejected, (state, { payload }) => {
        state.setFunctionModeIsLoading = false;
        state.setFunctionModeHasError = payload;
      })
      .addCase(getAllDevicesStatus.pending, state => {
        state.isLoadingDeviceStatus = true;
        state.hasDeviceStatusError = false;
      })
      .addCase(getAllDevicesStatus.fulfilled, (state, { payload }) => {
        if (payload) {
          state.deviceList = payload.map(device => {
            if (state.deviceInUse) {
              if (device.deviceId === state.deviceInUse.deviceId) {
                return state.deviceInUse;
              }
            }

            return device;
          });
        }

        state.isLoadingDeviceStatus = false;
        state.hasDeviceStatusError = false;
      })
      .addCase(getAllDevicesStatus.rejected, state => {
        state.isLoadingDeviceStatus = false;
        state.hasDeviceStatusError = true;
      })
      .addCase(createDevice.pending, state => {
        state.isCreatingDevice = true;
        state.isCreatedDevice = false;
        state.errorCreatingDevice = undefined;
      })
      .addCase(createDevice.fulfilled, state => {
        state.isCreatingDevice = false;
        state.isCreatedDevice = true;
        state.errorCreatingDevice = undefined;
      })
      .addCase(createDevice.rejected, (state, { payload }) => {
        state.isCreatingDevice = false;
        state.isCreatedDevice = false;
        state.errorCreatingDevice = payload as ApiError;
      })
      .addCase(updateDevice.pending, state => {
        state.isUpdatingDevice = true;
        state.isUpdatedDevice = false;
        state.errorUpdatingDevice = undefined;
      })
      .addCase(updateDevice.fulfilled, state => {
        state.isUpdatingDevice = false;
        state.isUpdatedDevice = true;
        state.errorUpdatingDevice = undefined;
      })
      .addCase(updateDevice.rejected, (state, { payload }) => {
        state.isUpdatingDevice = false;
        state.isUpdatedDevice = false;
        state.errorUpdatingDevice = payload as ApiError;
      })
      .addCase(deleteDevice.pending, state => {
        state.isDeleatingDevice = true;
        state.isDeleatedDevice = false;
        state.errorDeleatingDevice = undefined;
      })
      .addCase(deleteDevice.fulfilled, state => {
        state.isDeleatingDevice = false;
        state.isDeleatedDevice = true;
        state.errorDeleatingDevice = undefined;
      })
      .addCase(deleteDevice.rejected, (state, { payload }) => {
        state.isDeleatingDevice = false;
        state.isDeleatedDevice = false;
        state.errorDeleatingDevice = payload as ApiError;
      })
      .addCase(writeTags.pending, state => {
        state.writeTagsIsLoading = true;
        state.writeTagsHasError = undefined;
      })
      .addCase(writeTags.fulfilled, state => {
        state.writeTagsIsLoading = false;
        state.writeTagsHasError = undefined;
      })
      .addCase(writeTags.rejected, (state, { payload }) => {
        state.writeTagsIsLoading = false;
        state.writeTagsHasError = payload;
      });
  },
});

export const {
  initDevices,
  initMQTTTags,
  addDeviceInUse,
  removeDeviceInUse,
  updateMQTTDevice,
  tagsMQTTDevice,
  resetCRUDDevice,
  updateDeviceStatus,
  removeTag,
  removeTags,
  resetWriteTagsState,
} = devicesSlice.actions;
export default devicesSlice.reducer;
