import { FC, useEffect, useState } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';

import { HighlightOff } from '@material-ui/icons';
import RadioButtonsGroup from '@/components/ui/RadioButtons/RadioButtons';

import { UIModal } from '@/components/ui/Modal';
import { Typography } from '@/components/ui/Typography';

import { FieldDataValuesValues } from '@/types/fieldDataValues';
import {
  StyledClose,
  StyledContainer,
  StyledPrintButton,
  StyledProductImage,
  StyledSubContainer,
} from './style';
import { ModalPrintProps } from './types';

export const ModalPrintReturnsBackup: FC<ModalPrintProps> = ({
  open,
  onClose,
  onCloseHandler,
  product,
  onPrintClick,
  exportIsDamaged,
  defaultRadioValue,
  children,
  ...rest
}): JSX.Element => {
  const [damageProduct, setDamageProduct] = useState<FieldDataValuesValues>({});

  useEffect(() => {
    exportIsDamaged?.(damageProduct?.currentVal === 'Y');
  }, [damageProduct, exportIsDamaged]);

  useEffect(() => {
    if (defaultRadioValue) {
      exportIsDamaged?.(defaultRadioValue === 'Y');

      setDamageProduct({
        currentVal: defaultRadioValue,
      });
    }
  }, [defaultRadioValue, exportIsDamaged]);

  const { t } = useTranslation();

  const { store } = useSelector(state => state.currentStore);

  const { isPrinting } = useSelector(state => state.print);

  const radioButtons = [
    { label: t('Yes'), value: 'Y' },
    { label: t('No'), value: 'N' },
  ];

  const printDisabled =
    store?.printers === null ||
    store?.printers?.length === 0 ||
    isPrinting ||
    Object.keys(damageProduct).length === 0;

  const {
    imageUrl,
    colorCode,
    modelCode,
    brandDescription,
    styleNameDescription,
    upcCode,
    commodityDescription,
  } = product;

  return (
    <UIModal open={open} onClose={onClose} {...rest}>
      <StyledContainer>
        <StyledClose onClick={onCloseHandler}>
          <HighlightOff color={'primary'} />
        </StyledClose>
        <StyledSubContainer>
          <StyledProductImage
            imageUrl={imageUrl}
            colorCode={colorCode}
            modelCode={modelCode}
            brandCode={brandDescription}
            width={240}
            height={180}
          />

          <Typography size="lg" font="heavy" as="h1">
            {[brandDescription, styleNameDescription]
              .filter(info => info)
              .join(' - ')}
          </Typography>
          <Typography size="md" font="book" as="h1">
            {[upcCode, modelCode, commodityDescription]
              .filter(info => info)
              .join(' - ')}
          </Typography>

          {children}

          <RadioButtonsGroup
            title={t('page.damagesAndReturns.backup.modal.goodConditions')}
            radios={radioButtons}
            name="damageProduct"
            ariaLabel="damageProduct"
            setValueDetails={setDamageProduct}
            alignButtons="row"
            defaultValue={defaultRadioValue}
          />

          <StyledPrintButton
            label={t('printNewTag')}
            onClick={(e): void => {
              onPrintClick?.(e);
            }}
            loading={isPrinting}
            disabled={printDisabled}
          />
        </StyledSubContainer>
      </StyledContainer>
    </UIModal>
  );
};

ModalPrintReturnsBackup.displayName = 'ModalPrintReturnsBackup';

export default ModalPrintReturnsBackup;
