import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UIAction } from '@/components/ui/Action';
import { Typography } from '@/components/ui/Typography';
import { UIButton } from '@/components/ui/Button';

import { NotificationBar } from '@/components/layout/NotificationBar';

// import { cancelLastProductRemoved } from '@/features/sales/salesProductsSlice';
// import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';
import {
  StyledProductItemWrapper,
  ProductItemWrapper,
} from '@/components/layout/ProductItemWrapper';
import { ProductImage } from '@/components/layout/ProductImage';
import { ProductDescription } from '@/components/layout/ProductDescription';
import { ProductAccordion } from '@/components/layout/ProductAccordion';
import { ProductAccordionItem } from '@/components/layout/ProdocutAccordionItem';
import { ProductPrice } from '@/components/layout/ProductPrice';
import { useToggle } from 'react-use';
import { CheckoutItem } from '@/api/receive';
import { UIWarning } from '@/components/ui/Warning';

//#region types
export interface StyledScannedProductProps {
  $found?: boolean;
  $notFound?: boolean;
  $unexpected?: boolean;
  $sale?: boolean;
  $bis?: boolean;
}

export interface StyledScannedProductItemProps
  extends StyledScannedProductProps {
  productItem: CheckoutItem;
  onClick?: React.MouseEventHandler<HTMLElement>;
  handleAddUnexpected?: (
    product: NonNullable<CheckoutItem>,
    epcCode: string
  ) => void;
  handlePrintNotFound?: (
    product: NonNullable<CheckoutItem>,
    epcCode: string
  ) => void;
  handleRemoveSaleEpc?: (upcCode: string, epcCode: string) => void;
  handleRemoveSaleProduct?: (upcCode: string, epcCode: string) => void;
  handleAddBISEpc?: (upcCode: string, epcCode: string) => void;
  disableActions?: boolean;
  $v2?: boolean;
}
//#endregion

export const ScannedProductItemSell: FC<StyledScannedProductItemProps> = ({
  $found,
  $notFound,
  $unexpected,
  $sale,
  productItem,
  handleRemoveSaleEpc,
  handleRemoveSaleProduct,
  handleAddUnexpected,
  handlePrintNotFound,
  onClick,
  handleAddBISEpc,
  disableActions,
  $bis,
  $v2 = true,
}): JSX.Element => {
  const {
    brandCode,
    brandDescription,
    commodity,
    colorCode,
    epcs,
    modelCode,
    price,
    styleName,
    upcCode,
    imageUrl,
    //@ts-ignore
    currency,
  } = productItem;

  const { t } = useTranslation();
  const { store } = useSelector(state => state.currentStore);

  const [isNotificationBarVisible, setNotificationBarVisible] =
    useState<boolean>(false);

  const [$isAccordionExpanded, toggleAccordion] = useToggle(false);

  const onNotificationCancelClick = (): void => {
    setNotificationBarVisible(false);
    // dispatch(cancelLastProductRemoved());
  };

  const onNotificationBarClose = (): void => {
    setNotificationBarVisible(false);
  };

  const onPrintActionClick = (epcCode: string): void => {
    if (!disableActions) {
      handlePrintNotFound?.(productItem as CheckoutItem, epcCode);
    }
  };

  const onUnexpectedActionClick = (epcCode: string): void => {
    if (!disableActions) {
      handleAddUnexpected?.(productItem as CheckoutItem, epcCode);
    }
  };

  const onSaleActionClick = (epcCode: string): void => {
    if (!disableActions && upcCode) {
      // @ts-ignore
      if (epcs?.length === 1) {
        handleRemoveSaleProduct?.(upcCode, epcCode);
      } else {
        handleRemoveSaleEpc?.(upcCode, epcCode);
      }
    }
  };

  const hasAccordion = epcs && epcs.length > 1;

  const renderActions = (epcCode: string): JSX.Element => {
    return (
      <>
        {$unexpected && (
          <>
            <UIAction
              label={t('remove')}
              icon="delete"
              disabled={disableActions}
              onClick={(): void => {}}
            />
            <UIAction
              label={t('add')}
              icon="add"
              disabled={disableActions}
              onClick={(): void => onUnexpectedActionClick(epcCode)}
            />
          </>
        )}
        {$notFound && (
          <UIAction
            label={t('print')}
            icon="print"
            disabled={
              store?.printers === null ||
              store?.printers?.length === 0 ||
              disableActions
            }
            onClick={(): void => onPrintActionClick(epcCode)}
          />
        )}
        {$sale && (
          <UIAction
            label={t('remove')}
            icon="delete"
            disabled={disableActions}
            onClick={(): void => onSaleActionClick(epcCode)}
            isVisible
          />
        )}
        {$bis && (
          <UIAction
            label={t('add')}
            icon="add"
            disabled={disableActions}
            onClick={(): void => handleAddBISEpc?.(upcCode, epcCode)}
            isVisible
          />
        )}
      </>
    );
  };

  return (
    <>
      <StyledProductItemWrapper>
        <ProductItemWrapper
          $found={$found}
          $sale={$sale}
          $sold={epcs && epcs.every(item => item.sold)}
          $notFound={$notFound}
          $unexpected={$unexpected}
          $withAccordion={hasAccordion}
        >
          <ProductImage
            imageUrl={imageUrl}
            onClick={onClick}
            colorCode={colorCode}
            modelCode={modelCode}
            brandCode={brandCode}
            $v2={$v2 && hasAccordion}
            onClickAccordion={toggleAccordion}
            $isAccordionExpanded={$isAccordionExpanded}
          />
          <ProductDescription>
            <Typography size="lg" font="heavy" as="span">
              {brandCode} - {brandDescription} - {styleName}
            </Typography>
            <Typography size="md" font="book" as="p">
              {[upcCode, modelCode, commodity].filter(el => el).join(' - ')}
              {epcs && epcs.length === 1 && (
                <span className="epc-code"> - {epcs[0].epcCode}</span>
              )}
            </Typography>
            {$sale && epcs && epcs.length === 1 && epcs[0].sold && (
              <UIWarning
                label={t('sale.alreadysold', {
                  process: t(epcs[0].lastStatusUpdateProcess || ''),
                })}
                display="inline-flex"
                margin="8px 0 0 0"
                labelAs="span"
                alignItems="end"
              />
            )}
          </ProductDescription>
          <ProductPrice
            price={price ? String(price) : undefined}
            currency={currency}
          >
            {$v2 && hasAccordion && (
              <Typography font="light">
                {t('itemQuantity', {
                  itemQuantity: epcs?.length,
                })}
              </Typography>
            )}
            {epcs && epcs.length === 1 && renderActions(epcs?.[0].epcCode!)}
          </ProductPrice>
        </ProductItemWrapper>
        {hasAccordion && (
          <ProductAccordion
            items={`${epcs!.length} ${t('items')}`}
            $v2={$v2}
            expanded={$isAccordionExpanded}
            toggleAccordion={toggleAccordion}
          >
            {epcs!.map((epcCode, i: number) => (
              <ProductAccordionItem
                key={`${epcCode}-${i}-code`}
                epcCode={epcCode.epcCode!}
                sold={$sale && epcCode.sold}
                lastStatusUpdateProcess={
                  $sale ? epcCode.lastStatusUpdateProcess : undefined
                }
              >
                {renderActions(epcCode.epcCode!)}
              </ProductAccordionItem>
            ))}
          </ProductAccordion>
        )}
      </StyledProductItemWrapper>

      <NotificationBar
        cta={
          <UIButton
            label={t('cancel')}
            outlined
            disabled={false}
            onClick={onNotificationCancelClick}
          />
        }
        message={t('sale.removeArticle')}
        vertical="bottom"
        horizontal="center"
        open={isNotificationBarVisible}
        autoHideDuration={6000}
        onClose={onNotificationBarClose}
      />
    </>
  );
};

export default ScannedProductItemSell;
