import React from 'react';

import {
  unlockDeliveries,
  initDeliveryList,
} from '@/features/deliveries/deliveriesSlice';
import { initDeliveryProducts } from '@/features/products/deliveryProductsSlice';
import { initScannedProducts } from '@/features/products/scannedProductsSlice';
import { initSaleProducts } from '@/features/sales/salesProductsSlice';
import { initConfirmDeliveryProducts } from '@/features/products/confirmDeliveryProductsSlice';
import { initStoreToStoreState } from '@/features/s2s/storeToStoreSlice';
import {
  deleteTags,
  initDevices,
  removeDeviceInUse,
} from '@/features/devices/devicesSlice';
import { initCycleCountState } from '@/features/cycleCount/cycleCountSlice';

import { useAppDispatch } from '@/app/store';
import { initPrints } from '@/features/print/printSlice';
import { ScanDevice } from '@/types/device';
import { initRolesState } from '@/features/profiling/rolesSlice';
import { useSubscribeDeviceStatus } from './mqtt/useSubscribeDeviceStatus';
import { MQTTContext } from '@/context/MQTT';
import { initProfilingUserState } from '@/features/profiling/usersSlice';
import { initProfilingStoresState } from '@/features/profiling/storesSlice';
import { initReprice } from '@/features/repricing/repriceSlice';
import { initProfilingReasonCode } from '@/features/profiling/reasonCodeSlice';
import { initDamagesReturnsState } from '@/features/storeDamages/storeDamagesSlice';
import {
  changeRecallStatus,
  initRecallState,
} from '@/features/recall/recallSlice';
import { useSelector } from './useSelector';
import { initInventoryState } from '@/features/inventory/inventorySlice';
import { initManageDeliveryState } from '@/features/deliveriesManage/deliveriesManageSlice';
import { ReceiveShipmentsService } from '@/api';
import { initPosState } from '@/features/pos/posSlice';
import useHandleProcess from './useHandleProcess';
import {
  initBISState,
  setProcessLocked as setBISProcessLocked,
} from '@/features/bis/backInStockSlice';

type InitApplicationState = () => Promise<void>;

export const useInitApplication = (): InitApplicationState => {
  const dispatch = useAppDispatch();
  const { setDeviceIdOnMqttClient } = React.useContext(MQTTContext);
  const subscribeDevice = useSubscribeDeviceStatus();
  const bisIsLocked = useSelector(state => state.bis.processLocked);
  const salesPosProcessIsLocked = useSelector(state => state.pos.processLocked);
  const manualProcessLocked = useSelector(
    state => state.loader.manualProcessLocked
  );
  const deleteProcessLocked = useSelector(
    state => state.loader.deleteProcessLocked
  );
  const returnPosProcessIsLocked = useSelector(
    state => state.returnPos.processLocked
  );
  const cycleCountLocked = useSelector(
    state => state.cycleCount.cyclecCountLocked
  );
  const { recall } = useSelector(state => state.recall);
  const { deliveryManagedInUse } = useSelector(state => state.manageDeliveries);

  const handleProcess = useHandleProcess();

  const initApplicationState = React.useCallback(async () => {
    const storage = sessionStorage.getItem('deviceInUse');

    const deviceInStorage = storage
      ? (JSON.parse(storage) as Required<ScanDevice>)
      : undefined;

    if (deviceInStorage) {
      sessionStorage.removeItem('deviceInUse');
      // dispatch(addDeviceInUse(deviceInStorage));
      // await dispatch(
      //   updateDeviceStatus({
      //     deviceId: deviceInStorage.deviceId,
      //     nameDevice: deviceInStorage.nameDevice,
      //     statusMode: 'no mode',
      //     storeId: deviceInStorage.storeId,
      //     statusDevice: DeviceStatus.ONLINE,
      //   })
      // );

      await dispatch(deleteTags());
      subscribeDevice.publish(deviceInStorage.deviceId, undefined);
      setDeviceIdOnMqttClient(undefined);
      dispatch(removeDeviceInUse());
    }

    if (recall.status !== 'Completed') {
      await dispatch(changeRecallStatus());
    }

    if (deliveryManagedInUse) {
      await ReceiveShipmentsService.receiveshipChangeDeliveryStatus({
        requestBody: {
          deliveryNumbers: [deliveryManagedInUse],
          status: 'New',
        },
      });
    }

    if (bisIsLocked) {
      await handleProcess({ process: 'BIS', isLocked: false });
      dispatch(setBISProcessLocked(false));
    }

    if (salesPosProcessIsLocked) {
      await handleProcess({ process: 'SLEX', isLocked: false });
    }

    if (returnPosProcessIsLocked) {
      await handleProcess({ process: 'RTPS', isLocked: false });
    }

    if (manualProcessLocked) {
      await handleProcess({ process: 'MSUP', isLocked: false });
    }

    if (deleteProcessLocked) {
      await handleProcess({ process: 'DSUP', isLocked: false });
    }

    if (cycleCountLocked) {
      await handleProcess({ process: 'CYCO', isLocked: false });
    }

    await dispatch(unlockDeliveries());

    dispatch(initRecallState());
    dispatch(initDevices());
    dispatch(initPrints());
    dispatch(initScannedProducts());
    dispatch(initSaleProducts());
    dispatch(initCycleCountState());
    dispatch(initDeliveryProducts());
    dispatch(initConfirmDeliveryProducts());
    dispatch(initDeliveryList());
    dispatch(initStoreToStoreState());
    dispatch(initRolesState());
    dispatch(initReprice());
    dispatch(initProfilingUserState());
    dispatch(initProfilingStoresState());
    dispatch(initProfilingReasonCode());
    dispatch(initProfilingReasonCode());
    dispatch(initDamagesReturnsState());
    dispatch(initInventoryState());
    dispatch(initManageDeliveryState());
    dispatch(initPosState());
    dispatch(initBISState());

    sessionStorage.removeItem('idCheckout');
    sessionStorage.removeItem('soha-status');
  }, [
    cycleCountLocked,
    deleteProcessLocked,
    deliveryManagedInUse,
    dispatch,
    handleProcess,
    manualProcessLocked,
    recall.status,
    returnPosProcessIsLocked,
    salesPosProcessIsLocked,
    setDeviceIdOnMqttClient,
    subscribeDevice,
    bisIsLocked,
  ]);

  return initApplicationState;
};
