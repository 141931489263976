import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';

import { UIBox } from '@/components/ui/Box';
import { UIModal } from '@/components/ui/Modal';
import { UIButton } from '@/components/ui/Button';
import { UISelect } from '@/components/ui/Select';
import { IconButton } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { palette } from '@/theme';
import {
  changeEpcReasons,
  initSelectedItems,
} from '@/features/storeDamages/storeDamagesSlice';
import type { MovementReason } from '@/types/storeDamages';

interface ModalReasonDamagesProps {
  open: boolean;
  onClose: () => void;
}

//#region styled-components
const StyledModalWrapper = styled(UIBox)`
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

const StyledSelectWrapper = styled(UIBox)`
  position: relative;
  width: 100%;
`;

const StyledErrorIcon = styled(ErrorOutlineIcon)`
  position: absolute;
  top: 68px;
  left: 16px;
  color: ${palette.colors.yellow};
`;

const StyledClose = styled(IconButton)`
  height: 1rem;
  width: 1rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 9999;
`;
//#endregion

const ModalReasonDamages: React.FC<ModalReasonDamagesProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { reasons } = useSelector(state => state.storeDamages);
  const [reason, setReason] = useState<MovementReason>({
    movementReason: '',
    movementReasonCode: '',
    movementTypeCode: '',
  });

  useEffect(() => {
    if (!open) {
      setReason({
        movementReason: '',
        movementReasonCode: '',
        movementTypeCode: '',
      });
    }
  }, [open]);

  const defaultValue = useMemo(() => {
    if (reason.movementReason) {
      return `${reason.movementReason}/${reason.movementTypeCode}/${reason.movementReasonCode}`;
    }

    return '';
  }, [
    reason.movementReason,
    reason.movementReasonCode,
    reason.movementTypeCode,
  ]);

  const values = useMemo(
    () =>
      reasons.map(
        ({ movementReason, movementTypeCode, movementReasonCode }) => ({
          label: movementReason,
          value: `${movementReason}/${movementTypeCode}/${movementReasonCode}`,
        })
      ),
    [reasons]
  );

  const confirmClickHandler = (): void => {
    dispatch(changeEpcReasons(reason));
    dispatch(initSelectedItems());
    onClose();
  };

  return (
    <UIModal $minWidth="400px" $minHeight="250px" open={open} onClose={onClose}>
      <StyledModalWrapper>
        <StyledClose onClick={onClose}>
          <HighlightOff color={'primary'} />
        </StyledClose>
        <StyledSelectWrapper>
          <StyledErrorIcon fontSize="large" />
          <UISelect
            title={t('modal.reasonDamages.title')}
            aligned="center"
            variant="outlined"
            defaultValue={defaultValue}
            value={defaultValue}
            values={values}
            onChange={(e: React.ChangeEvent<{ value: unknown }>): void => {
              const movement = (e.target.value as string).split('/');

              setReason({
                movementReason: movement[0].trim(),
                movementTypeCode: movement[1].trim(),
                movementReasonCode: movement[2].trim(),
              });
            }}
          />
        </StyledSelectWrapper>
        <UIButton label="OK" disabled={false} onClick={confirmClickHandler} />
      </StyledModalWrapper>
    </UIModal>
  );
};

export default ModalReasonDamages;
