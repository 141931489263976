/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DownloadScannedEpcsResponse } from '../models/DownloadScannedEpcsResponse';
import type { Inventory } from '../models/Inventory';
import type { InventoryInfo } from '../models/InventoryInfo';
import type { InventoryList } from '../models/InventoryList';
import type { InventoryOwnerInfo } from '../models/InventoryOwnerInfo';
import type { InventoryRequest } from '../models/InventoryRequest';
import type { InventoryStatus } from '../models/InventoryStatus';
import type { ScanEpcList } from '../models/ScanEpcList';
import type { ShowDetailsResponse } from '../models/ShowDetailsResponse';
import type { ViewUpcDetailsRequest } from '../models/ViewUpcDetailsRequest';
import type { ViewUpcDetailsResponse } from '../models/ViewUpcDetailsResponse';
import { request as __request } from '../core/request';

export class InventoryService {
  /**
   * Service to retrieve the list of last inventories
   * Service returns all inventories in state SCHEDULED, ON GOING, VERIFIED and REFUSED by storeCode for Inventory Owner and Operators. Service returns all inventories in state SCHEDULED, ON GOING, WAITING FOR APPLICATION, WAITING FOR VALIDATION and VERIFIED for all stores where given Virtual Buddy is involved. If flag is enabled, service returns all inventories in state SCHEDULED, ON GOING, WAITING FOR APPLICATION, WAITING FOR VALIDATION for all stores and last 20 VERIFIED inventories for the given stores. IF isRetry set in the response to the TRUE value indicates that the Not Found detail page must be shown from the UI
   * @returns InventoryList OK
   * @throws ApiError
   */
  public static async inventoryFindLastInventories({
    storeCode,
    isFiltered,
  }: {
    /** filter for storeCode **/
    storeCode: any;
    /** enable filter for store code **/
    isFiltered?: any;
  }): Promise<InventoryList> {
    const result = await __request({
      method: 'GET',
      path: `/inventory/findLastInventories`,
      query: {
        storeCode: storeCode,
        isFiltered: isFiltered,
      },
      errors: {
        400: `bad input parameter, mandatory fiels is missing`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * showDetails
   * showDetails
   * @returns ShowDetailsResponse OK
   * @throws ApiError
   */
  public static async inventoryShowDetails({
    storeCode,
    idInventory,
  }: {
    /** filter for storeCode **/
    storeCode: any;
    /** filter for idInventory **/
    idInventory: any;
  }): Promise<ShowDetailsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/inventory/showDetails`,
      query: {
        storeCode: storeCode,
        idInventory: idInventory,
      },
      errors: {
        400: `bad input parameter, mandatory fiels is missing`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Service to retrieve the inventory status
   * Service returns inventory status
   * @returns Inventory OK
   * @throws ApiError
   */
  public static async inventoryGetInventoryStatus({
    storeCode,
  }: {
    /** filter for storeCode **/
    storeCode: any;
  }): Promise<Inventory> {
    const result = await __request({
      method: 'GET',
      path: `/inventory/getInventoryStatus`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `bad input parameter, mandatory fiels is missing`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * change inventory status
   * Change inventory status service - If the change is called from the inventory owner page to the CTA on Retry, the status must be set to Ongoing with isRetry set to true. If the change is called from the Virtual Buddy page to the CTA on RETURN TO STORE, the status must be set to Refused with isRetry set to true.
   * @returns any Status Changed
   * @throws ApiError
   */
  public static async inventoryChangeInventoryStatus({
    requestBody,
  }: {
    requestBody: InventoryStatus;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/inventory/changeInventoryStatus`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * start inventory
   * start inventory for Inventory Owner
   * @returns any Inventory Operation Applied
   * @throws ApiError
   */
  public static async inventoryStartInventory({
    requestBody,
  }: {
    requestBody: InventoryRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/inventory/startInventory`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * info about inventory
   * info about inventory
   * @returns InventoryInfo OK
   * @throws ApiError
   */
  public static async inventoryRefreshInventory({
    requestBody,
  }: {
    requestBody: InventoryRequest;
  }): Promise<InventoryInfo> {
    const result = await __request({
      method: 'PUT',
      path: `/inventory/refreshInventory`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * scan epc fo inventory
   * Scan epc fo inventory. The response is only figurative the official one is obtained from the WEBSOCKET
   * @returns InventoryOwnerInfo OK
   * @returns any Inventory Operation Applied
   * @throws ApiError
   */
  public static async inventoryScanEpc({
    requestBody,
  }: {
    requestBody: ScanEpcList;
  }): Promise<InventoryOwnerInfo | any> {
    const result = await __request({
      method: 'POST',
      path: `/inventory/scanEpc`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * quitInventory inventory
   * quitInventory inventory. The response is only figurative the official one is obtained from the WEBSOCKET
   * @returns any Inventory Operation Applied
   * @throws ApiError
   */
  public static async inventoryQuitInventory({
    requestBody,
  }: {
    requestBody: InventoryRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/inventory/quitInventory`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * join inventory
   * join inventory by operators. The response is only figurative the official one is obtained from the WEBSOCKET
   * @returns any Inventory Operation Applied
   * @throws ApiError
   */
  public static async inventoryJoinInventory({
    requestBody,
  }: {
    requestBody: InventoryRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/inventory/joinInventory`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Finish And Exit
   * Press Finish And Exit button by operators
   * @returns any Inventory Operation Applied
   * @throws ApiError
   */
  public static async inventoryFinishAndExit({
    requestBody,
  }: {
    requestBody: InventoryRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/inventory/finishAndExit`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * See Results for Inventory Owner
   * See Results for Inventory Owner
   * @returns InventoryOwnerInfo OK
   * @throws ApiError
   */
  public static async inventoryWaitingForValidationSeeResults({
    requestBody,
  }: {
    requestBody: InventoryRequest;
  }): Promise<InventoryOwnerInfo> {
    const result = await __request({
      method: 'POST',
      path: `/inventory/waitingForValidationSeeResults`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Apply Validation
   * Press Apply Validation button by Inventory Owner
   * @returns any Inventory Operation Applied
   * @throws ApiError
   */
  public static async inventoryApplyValidation({
    requestBody,
  }: {
    requestBody: InventoryRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/inventory/applyValidation`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Close Inventory
   * Press Close Inventory button by Virtual Buddy
   * @returns any Inventory Operation Applied
   * @throws ApiError
   */
  public static async inventoryCloseInventory({
    requestBody,
  }: {
    requestBody: InventoryRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/inventory/closeInventory`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Rejected Inventory
   * Press Rejectedc Inventory button by Virtual Buddy
   * @returns any Inventory Operation Applied
   * @throws ApiError
   */
  public static async inventoryRejectedInventory({
    requestBody,
  }: {
    requestBody: InventoryRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/inventory/rejectedInventory`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * View UPC details
   * View UPC details
   * @returns ViewUpcDetailsResponse OK
   * @throws ApiError
   */
  public static async inventoryViewUpcDetails({
    requestBody,
  }: {
    requestBody: ViewUpcDetailsRequest;
  }): Promise<ViewUpcDetailsResponse> {
    const result = await __request({
      method: 'POST',
      path: `/inventory/viewUpcDetails`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * download scanned epcs
   * download scanned epcs
   * @returns DownloadScannedEpcsResponse OK
   * @throws ApiError
   */
  public static async inventoryDownloadScannedEpcs({
    inventoryId,
  }: {
    inventoryId: string;
  }): Promise<DownloadScannedEpcsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/inventory/downloadScannedEpcs`,
      query: {
        inventoryId: inventoryId,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
