import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useAsync, usePrevious, useUpdateEffect } from 'react-use';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';

import { ScanDevicesService } from '@/api';

import { deleteTags, tagsMQTTDevice } from '@/features/devices/devicesSlice';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { UIList } from '@/components/ui/List';

import { CTAContainer } from '@/components/layout/CTAContainer';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import { ModalProductDetails } from '@/components/layout/ModalProductDetails';

import { Search as SearchIcon } from '@material-ui/icons';

import { EnumMode } from '@/types/enum';
import { useAppDispatch } from '@/app/store';
import { ScanDevice } from '@/types/device';

import { PaginationList } from '@/components/layout/PaginationList';
import {
  ElasticHandlerRestService,
  BackInStockSearchResponse,
} from '@/api/receive';
import { AppRoutes } from '@/app/routers';

import {
  StyledProductsWrapper,
  StyledAutoCompleteWrapper,
  StyledTitle,
  StyledAutoComplete,
  SearchButton,
} from './style';
import { sum } from '@/utils';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import useHandleProcess from '@/hooks/useHandleProcess';
import {
  confirmBisProducts,
  getBisProducts,
  resetProducts,
  setProcessLocked,
} from '@/features/bis/backInStockSlice';
import { ScannedProductItemSell } from '@/components/layout/ScannedProductItemSell';
import { UIButton, UIButtonWithIcon } from '@/components/ui/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ModalDataSavedError } from '@/components/layout/ModalDataSaved';
import { UIBox } from '@/components/ui/Box';

export const PageBISItems: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleProcess = useHandleProcess();

  const history = useHistory();
  const autocompleteInputRef = useRef<HTMLInputElement>(null);

  const { deviceInUse, tags, deleteTagsHasError } = useSelector(
    ({ devices }) => devices
  );

  const { products, error, isLoading } = useSelector(state => state.bis);
  const [openScanModal, setScanModalVisibility] = useState(true);
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const [isScanning, setIsScanning] = useState(false);
  const [openProductModal, setProdcutModalVisibility] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedEpcProduct, setSelectedEpcProduct] = useState('');
  const [epcCode, setEpcCode] = useState<string>('');
  const [epcsList, setEpcList] = useState<string[]>([]);
  const [isScanningAgain, setIsScanningAgain] = useState<boolean>(false);
  const [deviceError, setDeviceError] = useState<boolean>(false);
  const [deviceErrorMsg, setDeviceErrorMsg] = useState<string>();
  const [finishButtonClicked, setFinishButtonClicked] =
    useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [bisConfirmed, setBisConfirmed] = useState<boolean>(false);
  const [bisConfirmedError, setBisConfirmedError] = useState<boolean>(false);
  const [addEpc, setAddEpc] = useState<boolean>(false);

  const productsCount = sum(products?.map(({ epcs }) => epcs?.length));

  const oldProdCount = usePrevious(productsCount);

  useAsync(async () => {
    if (tags && tags.length > 0) {
      await dispatch(getBisProducts({ tags }));

      if (openScanModal) {
        setScanModalVisibility(false);
      }
    }
  }, [dispatch, tags.length]);

  const lockProcess = useCallback(async () => {
    await handleProcess({ process: 'BIS', isLocked: true });
  }, [handleProcess]);

  useEffect(() => {
    lockProcess();
    dispatch(setProcessLocked(true));
  }, [dispatch, lockProcess]);

  useEffect(() => {
    if (deleteTagsHasError) {
      setDeviceError(true);
      setDeviceErrorMsg(deleteTagsHasError.body.message);
    }
  }, [deleteTagsHasError]);

  useEffect(() => {
    if (error) {
      setIsErrorVisible(true);
    }
  }, [error]);

  useEffect(() => {
    if (epcCode.length === 0) {
      setEpcList([]);
    }
  }, [epcCode]);

  const getEpcs = async (): Promise<void> => {
    if (epcCode.length >= 4) {
      try {
        const { epcList } =
          await ElasticHandlerRestService.elastichandlerSearchEpcAutocomplete({
            epcCode,
          });

        if (epcList) {
          if (epcList?.length > 0) {
            const list = epcList.map(({ epcCode }) => (epcCode ? epcCode : ''));
            return setEpcList(list);
          }
        }

        return setEpcList([]);
      } catch (e) {
        console.log('error', e);
      }
    }
  };

  const onAutoCompleteOptionSelect = (epc: string | null): void => {
    setAddEpc(true);
    if (epc) {
      dispatch(tagsMQTTDevice([{ epc }]));
    }
  };

  useUpdateEffect(() => {
    if (addEpc && productsCount === oldProdCount) {
      setOpenSnackbar(false);
    }
  }, [products]);

  const searchInputHandler: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    setEpcCode(value);
  };

  const onFinishScanClick = async (): Promise<void> => {
    try {
      const storage = sessionStorage.getItem('deviceInUse');

      if (!storage) return;

      const deviceInStorage = JSON.parse(storage) as Required<ScanDevice>;
      const devId = deviceInUse?.deviceId! || deviceInStorage.deviceId;

      setFinishButtonClicked(true);

      const { tags, mode } = await ScanDevicesService.getTags({ devId });

      if (mode === 'no mode') {
        setFinishButtonClicked(false);
        setDeviceErrorMsg(t('error.deviceCommunication'));
        return setDeviceError(true);
      }

      if (tags && tags.length > 0) {
        dispatch(tagsMQTTDevice(tags));
        sessionStorage.setItem(
          'saleTags',
          JSON.stringify(tags.map(({ epc }) => epc))
        );
      }

      setTimeout(async () => {
        const { code, message } = await ScanDevicesService.activateScanning({
          requestBody: { dev_id: devId, enable: 'False' },
        });

        if (code !== 'OK') {
          setDeviceErrorMsg(message);
          setFinishButtonClicked(false);
          return setDeviceError(true);
        }
      }, 1000);

      setTimeout(async () => {
        await dispatch(deleteTags());
        setIsScanning(false);
        setFinishButtonClicked(false);
      }, 2000);
    } catch (err) {
      setFinishButtonClicked(false);
      setDeviceError(true);
    }
  };

  const onScanClick = (): void => {
    setIsScanningAgain(true);
    setScanModalVisibility(true);
  };

  const onQuitClick = async (): Promise<void> => {
    history.push(AppRoutes.INTRO);
  };

  const searchClick = (): void => {
    autocompleteInputRef.current?.focus();
  };

  const onProductClick = (e: React.MouseEvent, upcCode: string): void => {
    e.preventDefault();

    const target = e.target as HTMLElement;

    if (
      typeof target.className === 'object' ||
      target.nextElementSibling instanceof SVGElement ||
      target.className.includes('Accordion')
    )
      return;

    setSelectedProduct(upcCode);
    setProdcutModalVisibility(true);
  };

  const handleAddBISEpc = (upcCode: string, epcCode: string): void => {
    setSelectedProduct(upcCode);
    setSelectedEpcProduct(epcCode);
    setProdcutModalVisibility(true);
  };

  const confirmBackInStock = async (): Promise<void> => {
    try {
      await dispatch(confirmBisProducts({ tags: [selectedEpcProduct] }));
      dispatch(resetProducts());
      setBisConfirmed(true);
    } catch (err) {
      setBisConfirmedError(true);
    }
    await dispatch(getBisProducts({ tags }));
  };

  return (
    <>
      <ModalScanDetailsV2
        open={openScanModal}
        onClose={(): void => setScanModalVisibility(false)}
        defaultMode={EnumMode.INVENTORY}
        disabledModes={[EnumMode.FIND]}
        setIsScanCompleted={setIsScanning}
        isScanningAgain={isScanningAgain}
      />

      <ModalProductDetails
        open={openProductModal}
        onClose={(): void => {
          setProdcutModalVisibility(false);
          setSelectedEpcProduct('');
        }}
        productCode={selectedProduct}
        productEPCCode={selectedEpcProduct}
        $bis
        onConfirm={confirmBackInStock}
      />

      <StyledAutoCompleteWrapper>
        <StyledTitle>{t('searchEpcManually')}</StyledTitle>

        <StyledAutoComplete
          disableClearable
          rounded
          width={350}
          inputRef={autocompleteInputRef}
          minSearchTermLength={4}
          label={t('searchByEpc')}
          searchTerm={epcCode}
          options={epcsList}
          getOptions={getEpcs}
          onChange={searchInputHandler}
          loadingText={t('searching')}
          noOptionsText={t('error.no_results_available')}
          onOptionSelect={(_e, epc): void => onAutoCompleteOptionSelect(epc)}
          inputProps={{ maxLength: 24, minLength: 24 }}
        />

        <SearchButton
          onClick={searchClick}
          label={t('search')}
          startIcon={<SearchIcon />}
        />
      </StyledAutoCompleteWrapper>

      <StyledProductsWrapper>
        <UIList
          title=""
          itemsCount={
            <Trans
              i18nKey="itemsFoundBackToStock"
              values={{ itemsFound: productsCount }}
            />
          }
          itemsWarning={
            <UIButtonWithIcon
              label={t('refresh')}
              loading={isLoading}
              startIcon={<RefreshIcon />}
              onClick={async (): Promise<void> => {
                await dispatch(getBisProducts({ tags }));
              }}
            />
          }
          rounded
          backgrounded
          shadowed
        >
          <PaginationList
            data={products}
            pageSize={10}
            renderItem={(
              props: NonNullable<BackInStockSearchResponse>,
              index: number
            ): JSX.Element => (
              <ScannedProductItemSell
                $bis
                productItem={props}
                key={`${props.brandCode}-${index}-productItem`}
                handleAddBISEpc={handleAddBISEpc}
                onClick={(e: React.MouseEvent): void =>
                  onProductClick(e, props.upcCode!)
                }
                disableActions={isScanning}
              />
            )}
          />
        </UIList>
      </StyledProductsWrapper>

      <ErrorSnackbar
        open={isErrorVisible}
        setIsOpen={setIsErrorVisible}
        errorMessage={
          error && error.body && typeof error.body === 'string'
            ? error.body
            : t('error.sale_products')
        }
      />

      <ErrorSnackbar
        open={deviceError}
        setIsOpen={setDeviceError}
        errorMessage={deviceErrorMsg}
      />

      {isScanning ? (
        <CTAContainer
          type="FINISH SCAN"
          disabled={false}
          onClick={onFinishScanClick}
          loading={finishButtonClicked}
        />
      ) : (
        <CTAContainer
          type="TWO_BUTTONS"
          onBackClick={onQuitClick}
          onClick={onScanClick}
          backButtonLabel={t('quit')}
          mainButtonLabel={t('scanagain')}
        />
      )}

      <AlertSnackbar
        open={openSnackbar}
        message={t('cycleCount.otherStore.message', { type: 'EPC' })}
        setIsOpen={setOpenSnackbar}
      />

      <AlertSnackbar
        severity="success"
        open={bisConfirmed}
        setIsOpen={setBisConfirmed}
        message={t('sale.bis.success')}
      />

      <ModalDataSavedError
        iconType="WARNING"
        $minWidth="705px"
        $minHeight="260px"
        title={t('error.something_went_wrong')}
        message={t('modal.datanotsaved')}
        open={bisConfirmedError}
        onClose={(): void => {
          setBisConfirmedError(false);
        }}
      >
        <UIBox mt={4} alignItems="center" width="50%">
          <UIButton
            outlined
            label={t('ok')}
            onClick={(): void => setBisConfirmedError(false)}
          />
        </UIBox>
      </ModalDataSavedError>
    </>
  );
};

export default PageBISItems;
