import { FC, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync, useMount } from 'react-use';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';
import { ProfilingService, UserProfile } from '@/api/receive';

import { UISelect } from '@/components/ui/Select';

import { CTAContainer } from '@/components/layout/CTAContainer';

import { PageLoader } from '@/components/ui/PageLoader';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';

import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import ProfilingUsersList from '@/components/layout/ProfilingUsersList/ProfilingUsersList';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';

import {
  clearStoresAndDetails,
  fetchUsers,
} from '@/features/profiling/usersSlice';
import { useHistory } from 'react-router';
import { AppRoutes } from '@/app/routers';
import PublishIcon from '@material-ui/icons/Publish';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { SelectOption } from '@/types/selectOption';
import { UsersFilters, Roles } from '@/types/filters';
import { isAdmin } from '@/utils/user';

import {
  StyledButtons,
  StyledButtonsWrapper,
  StyledButtonWithIcon,
  StyledFilterWrapper,
  StyledUpdateUserWrapper,
} from './style';
import { ModalRole } from '@/components/layout/ModalRole';
import { useRoleModalContext } from '@/context/roleModalContext';
import { findAllRoles, initRolesState } from '@/features/profiling/rolesSlice';
import { Typography } from '@/components/ui/Typography';

const PageControlUsers: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    list: usersList,
    fetchUsersIsLoading,
    fetchUsersHasError,
    removeUserHasError,
  } = useSelector(state => state.profiling.user);

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const user = useSelector(state => state.user);

  const { roleModal, setRoleModalState } = useRoleModalContext();

  useMount(async (): Promise<void> => {
    await dispatch(initRolesState());
    await dispatch(findAllRoles());
  });

  const { roles: generalRoles } = useSelector(
    ({ profiling }) => profiling?.rolesManagement
  );

  const [alertSnackbarIsVisible, setAlertSnackbarVisibility] =
    useState<boolean>(false);

  const [users, setUsers] = useState<UserProfile[]>(usersList);
  const [filters, setFilters] = useState<UsersFilters>({
    userId: '',
    lastName: '',
    storeCode: '',
    role: Roles.ALL,
    salesOrg: '',
    jobTitle: '',
  });

  useAsync(async () => await dispatch(fetchUsers()), [dispatch]);

  useEffect(() => {
    if (fetchUsersHasError) {
      setAlertSnackbarVisibility(true);
    }
  }, [fetchUsersHasError]);

  useEffect(() => {
    const { userId, lastName, storeCode, role, salesOrg, jobTitle } = filters;

    if (
      userId === '' &&
      lastName === '' &&
      storeCode === '' &&
      role === Roles.ALL &&
      salesOrg === '' &&
      jobTitle === ''
    ) {
      setUsers(usersList);
    } else {
      setUsers(
        usersList.filter(
          user =>
            user.userId?.toLowerCase().includes(userId.toLowerCase()) &&
            user.lastName?.toLowerCase()?.includes(lastName.toLowerCase()) &&
            user.storeCode?.toLowerCase()?.includes(storeCode.toLowerCase()) &&
            (role === Roles.ALL || user.roles?.includes(role)) &&
            user.salesOrganization?.some(so =>
              so.toLowerCase()?.includes(salesOrg!.toLowerCase())
            ) &&
            (jobTitle === '' ||
              user.jobTitle
                ?.toLowerCase()
                ?.includes(jobTitle!.toLocaleLowerCase()))
        )
      );
    }
  }, [filters, usersList]);

  useEffect(() => {
    dispatch(clearStoresAndDetails());
  }, [dispatch]);

  useBreadcrumbs([{ title: t('page.controlPanel') }]);

  const roles: SelectOption[] = useMemo(() => {
    const userRoles = [
      ...new Set(
        usersList.flatMap(({ roles }) => {
          if (roles) {
            return roles.map(role => role);
          }

          return [];
        })
      ),
    ].map(role => ({
      label: t(`${role}`),
      value: role,
    }));

    userRoles.unshift({ label: t('all'), value: 'all' });

    return userRoles;
  }, [usersList, t]);

  const onFilterChange = (key: keyof UsersFilters, value: string): void => {
    setFilters(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onBackClick = (): void => {
    history.push(AppRoutes.ADMIN);
  };

  const onAddUser = (): void => {
    history.push(AppRoutes.MANAGE_USERS_CREATE);
  };

  if (fetchUsersIsLoading) {
    return <PageLoader />;
  }

  const externalFnAdd = async (roles: string[]): Promise<void> => {
    try {
      const requestBody = { users: selectedUsers, roles };

      await ProfilingService.userAssignRolesEncoded({
        requestBody: { base64: btoa(JSON.stringify(requestBody)) },
      });
      await dispatch(fetchUsers());
      setSelectedUsers([]);
    } catch (e) {
      console.error(e);
    }
  };

  const externalFnRemove = async (roles: string[]): Promise<void> => {
    try {
      await ProfilingService.userRemoveRoles({
        requestBody: { users: selectedUsers, roles },
      });
      await dispatch(fetchUsers());
      setSelectedUsers([]);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <StyledFilterWrapper>
        <SearchBarLight
          label=""
          value={filters.userId}
          placeholder={t('profiling.searchById')}
          disabled={false}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onFilterChange('userId', e.target.value)
          }
          loading={false}
          hideButton={true}
        />

        <SearchBarLight
          label=""
          value={filters.lastName}
          placeholder={t('profiling.searchBySurname')}
          disabled={false}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onFilterChange('lastName', e.target.value)
          }
          loading={false}
          hideButton={true}
        />

        <SearchBarLight
          label=""
          value={filters.storeCode}
          placeholder={t('profiling.filterByStoreCode')}
          disabled={false}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onFilterChange('storeCode', e.target.value)
          }
          loading={false}
          hideButton={true}
        />

        <UISelect
          selectwidth="100%"
          title=""
          variant="outlined"
          onChange={(e: React.ChangeEvent<{ value: unknown }>): void =>
            onFilterChange('role', e.target.value as string)
          }
          value={filters.role}
          values={roles}
        />

        <SearchBarLight
          label=""
          value={filters.salesOrg}
          placeholder={t('profiling.filterBySalesOrganizations')}
          disabled={false}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onFilterChange('salesOrg', e.target.value)
          }
          loading={false}
          hideButton={true}
        />

        <SearchBarLight
          label=""
          value={filters.jobTitle}
          placeholder={t('profiling.filterByJobTitle')}
          disabled={false}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onFilterChange('jobTitle', e.target.value)
          }
          loading={false}
          hideButton={true}
        />

        <StyledButtonsWrapper>
          <StyledButtons>
            <StyledButtonWithIcon
              label={t('addRoles')}
              disabled={selectedUsers.length === 0}
              endIcon={<AddCircleOutlineIcon />}
              onClick={(): void =>
                setRoleModalState({
                  open: true,
                  type: 'EXTERNAL',
                  roles: generalRoles,
                  externalFn: externalFnAdd,
                  externalTitle: t('controlUsers.multipleRole.add.title'),
                })
              }
            />

            <StyledButtonWithIcon
              label={t('deleteRoles')}
              disabled={selectedUsers.length === 0}
              endIcon={<RemoveCircleOutlineIcon />}
              onClick={(): void =>
                setRoleModalState({
                  open: true,
                  type: 'EXTERNAL',
                  roles: generalRoles,
                  externalFn: externalFnRemove,
                  externalTitle: t('controlUsers.multipleRole.remove.title'),
                })
              }
            />
          </StyledButtons>
          {isAdmin(user) && (
            <StyledUpdateUserWrapper
              onClick={(): void => {
                history.push(AppRoutes.MANAGE_USERS_UPLOAD);
              }}
            >
              <Typography color="primary">
                {t('controlUsers.upload.label')}
              </Typography>
              <PublishIcon />
            </StyledUpdateUserWrapper>
          )}
        </StyledButtonsWrapper>
      </StyledFilterWrapper>

      <ProfilingUsersList
        users={users}
        setAlertSnackbarVisibility={setAlertSnackbarVisibility}
        exportUsersSelected={setSelectedUsers}
      />

      <CTAContainer
        type="ADD USER"
        onClick={(): void => onAddUser()}
        onBackClick={(): void => onBackClick()}
      />

      <ModalRole
        type={roleModal.type}
        open={roleModal.open}
        roles={roleModal.roles}
        externalFn={roleModal.externalFn}
        externalTitle={roleModal.externalTitle}
        selectedStores={[]}
        setSelectedStores={(): void => {}}
        onClose={(): void => {
          setRoleModalState(prevState => ({
            ...prevState,
            rolesChecked: [],
            open: false,
          }));
        }}
      />

      <AlertSnackbar
        open={alertSnackbarIsVisible && !!removeUserHasError}
        setIsOpen={setAlertSnackbarVisibility}
        message={removeUserHasError?.body}
      />

      <AlertSnackbar
        open={alertSnackbarIsVisible && !!fetchUsersHasError}
        setIsOpen={setAlertSnackbarVisibility}
        message={fetchUsersHasError?.body}
      />
    </>
  );
};

export default PageControlUsers;
