/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StoreCodeList } from '../models/StoreCodeList';
import type { StoreDetails } from '../models/StoreDetails';
import type { UpdateStore } from '../models/UpdateStore';
import { request as __request } from '../core/request';

export class StoreService {
  /**
   * updates store data
   * update store data
   * @returns any Store updated.
   * @throws ApiError
   */
  public static async storeserviceUpdateStore({
    requestBody,
  }: {
    requestBody: UpdateStore;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/storeservice/updateStore`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Internal server errror.`,
      },
    });
    return result.body;
  }

  /**
   * search StoreList By StoreCode
   * Find store by storeCode. At least 3 digits must be entered to activate the search
   * @returns StoreDetails OK
   * @throws ApiError
   */
  public static async storeserviceSearchStore({
    storeCode,
  }: {
    storeCode: any;
  }): Promise<Array<StoreDetails>> {
    const result = await __request({
      method: 'GET',
      path: `/storeservice/searchStore`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Get current date and time of a store by store code
   * Get current date and time of a store considering timezone, in an optional custom format
   * @returns any OK
   * @throws ApiError
   */
  public static async storeserviceGetCurrentStoreDateTimeByStoreCode({
    storeCode,
    pattern,
  }: {
    storeCode: any;
    pattern?: any;
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/storeservice/getCurrentStoreDateTimeByStore`,
      query: {
        storeCode: storeCode,
        pattern: pattern,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Get current date and time of a store by user Id
   * Get current date and time of a store by user id retieved by jwt token in correct  timezone, using an optional custom format.
   * @returns any OK
   * @throws ApiError
   */
  public static async storeserviceGetCurrentStoreDateTimeByUser({
    pattern,
  }: {
    pattern?: any;
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/storeservice/getCurrentStoreDateTimeByUser`,
      query: {
        pattern: pattern,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Get whole Store Codes list
   * Get whole store codes list. If isActive flag is enabled, get just active store store codes
   * @returns StoreCodeList OK
   * @throws ApiError
   */
  public static async storeserviceGetWholeStoreCodes({
    isActive,
  }: {
    isActive?: any;
  }): Promise<StoreCodeList> {
    const result = await __request({
      method: 'GET',
      path: `/storeservice/getWholeStoreCodes`,
      query: {
        isActive: isActive,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
