import { FC } from 'react';
import styled from 'styled-components';
import { palette } from '@/theme';

export const StyledProductDescription = styled.div`
  margin-left: 16px;
  flex: 1;

  .epc-code {
    color: ${palette.colors.grey};

    ${({ theme }): string =>
      `${theme.breakpoints.down('sm')} {
            display: block;
            margin-top: 8px;
        }`}
  }

  p {
    margin: 0;

    &:last-child {
      margin-top: 8px;
      letter-spacing: 0.8px;
    }
  }
`;

export const ProductDescription: FC = ({ children }) => {
  return <StyledProductDescription>{children}</StyledProductDescription>;
};

export default ProductDescription;
