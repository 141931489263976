import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationList } from '../PaginationList';
import { Typography } from '@/components/ui/Typography';
import ProfilingStoreRow from './ProfilingStoreRow';

import { StyledTablesHeader } from './styles';
import { Store } from '@/api/receive';
import { useSelector } from '@/hooks/useSelector';
import { PageLoader } from '@/components/ui/PageLoader';

interface ProfilingStoreListProps {
  paginationStores?: Store[];
  listCurrentPage: number;
  setListCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const ProfilingStoreList: React.FC<ProfilingStoreListProps> = ({
  paginationStores,
  listCurrentPage,
  setListCurrentPage,
}) => {
  const { t } = useTranslation();

  const { fetchStoresIsLoading } = useSelector(state => state.profiling.stores);

  return (
    <>
      <StyledTablesHeader>
        <Typography font="heavy">{t('salesOrganization')}</Typography>
        <Typography font="heavy">{t('storeCode')}</Typography>
        <Typography font="heavy">{t('storeAddress')}</Typography>
        <Typography font="heavy">{t('status')}</Typography>
      </StyledTablesHeader>
      {fetchStoresIsLoading ? (
        <PageLoader />
      ) : (
        paginationStores && (
          <PaginationList
            setCurrPage={setListCurrentPage}
            currPage={listCurrentPage}
            data={paginationStores}
            pageSize={25}
            renderItem={(store: Store): JSX.Element => (
              <ProfilingStoreRow {...store} key={store.storeCode} />
            )}
          />
        )
      )}
    </>
  );
};

export default ProfilingStoreList;
