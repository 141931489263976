import styled from 'styled-components';
import { theme } from '@/theme';
import { UIBox } from '@/components/ui/Box';
import { UIButton } from '@/components/ui/Button';
import { Typography } from '@/components/ui/Typography';
import UIAutocomplete from '@/components/ui/AutoComplete/AutoComplete';

export const StyledProductsWrapper = styled(UIBox)`
  margin: 0 0 100px;
  width: 100%;
  flex-direction: column;
`;

export const StyledAutoCompleteWrapper = styled(UIBox)`
  align-items: flex-start;
  margin: 25px 0 37px auto;
  padding: 0 24px;
`;

export const StyledTitle = styled(Typography)`
  margin-left: auto;
  margin-top: 18px;
`;

export const StyledAutoComplete = styled(UIAutocomplete)`
  margin: 0 8px 0 24px;
`;

export const SearchButton = styled(UIButton)`
  && {
    width: 150px;
    padding: 26px 0;
  }
`;

export const StyledNotificationButton = styled(UIButton)`
  font-size: 18px;
  width: auto;
  color: ${theme.palette.primary.contrastText} !important;
  border-color: ${theme.palette.primary.contrastText} !important;

  ${({ theme }): string =>
    `${theme.breakpoints.down('xs')} {
				margin-left: 0;

				button {
						width: 305px;
				}
		}`}
`;

export const StyledExceptionUpcList = styled(UIBox)`
  padding: 8px 12px;
  flex-direction: column;
  width: 100%;
  max-height: 133px;
  overflow-y: auto;
  margin: 24px 0 38px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: rgba(251, 251, 251, 1);
  border-radius: 4px;

  & > * {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    }
  }
`;

export const StyledExceptionUpc = styled(UIBox)`
  padding: 4px;
`;
