import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { UIAccordion } from '@/components/ui/Accordion';
import { Typography } from '@/components/ui/Typography';
import {
  ListSubheader,
  ListSubheaderProps as MuiListSubheaderListProps,
} from '@material-ui/core';

//#region - Styled Components
const StyledSubHeader = styled(ListSubheader)`
  background: #ededed;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0px 1px 4px 0px #00000040;
`;

const StyledAccordion = styled(UIAccordion)`
  && {
    height: 32px !important;
    margin: 0 !important;
  }
`;

const StyledActionWrapper = styled(UIBox)`
  margin-left: 16px;

  & > * {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;
//#endregion

type Accordion = {
  expanded: boolean;
  toggleAccordionState: React.Dispatch<React.SetStateAction<boolean>>;
};

interface ListSubHeaderProps extends MuiListSubheaderListProps {
  accordion?: Accordion;
  itemsCount?: number;
  title: string;
  actions?: JSX.Element;
}

const SubHeader: React.FC<ListSubHeaderProps> = ({
  accordion,
  title,
  actions,
  itemsCount,
}) => {
  const { t } = useTranslation();

  return (
    <StyledSubHeader>
      <UIBox padding={1} justifyContent="space-between">
        <UIBox alignItems="center">
          {accordion && (
            <StyledAccordion
              expanded={accordion.expanded}
              onClick={(): void =>
                accordion.toggleAccordionState(prevState => !prevState)
              }
            />
          )}
          {title && (
            <Typography size="lg" font="heavy">
              {title}
            </Typography>
          )}
        </UIBox>
        <UIBox alignItems="center">
          {itemsCount && (
            <Typography size="lg" font="heavy">
              {t('itemsFound', { itemsFound: itemsCount })}
            </Typography>
          )}
          {actions && (
            <StyledActionWrapper marginLeft={2}>{actions}</StyledActionWrapper>
          )}
        </UIBox>
      </UIBox>
    </StyledSubHeader>
  );
};

export default SubHeader;
