import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';

import { Typography } from '@/components/ui/Typography';
import { UIAction } from '@/components/ui/Action';
import { UIBox } from '@/components/ui/Box';
import { ProductImage } from '@/components/layout/ProductImage';

import {
  setEpcSelected,
  setUpcSelected,
} from '@/features/products/scannedProductsSlice';

import {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionItemContent,
  StyledActionWrapper,
  StyledCheckbox,
  StyledContentInfo,
  StyledCount,
  StyledCountWrapper,
  StyledDescription,
  StyledEpcCode,
  StyledProductContent,
  StyledProductItem,
} from './style';
import type { EpcSelected, ScannedProduct } from '@/types/product';
import { Product, ProductValues } from '@/types/enum';
import { ProductPrice } from '@/components/layout/ProductPrice';
import { StyledTinyListItemStatusBadge } from '@/components/layout/DeliveryListItem';
import { ReactComponent as DeliveryIcon } from '@/assets/icons/delivery.svg';
import { StyledBadge } from '@/components/layout/ModalPrintTemp/style';

export interface ProductListItemProps {
  type: ProductValues;
  onPrintClick?: (product: ScannedProduct) => void;
  onProductImageClick: (e: React.MouseEvent, upcCode: string) => void;
  disableActions?: boolean;
  showDeliveryNumber: boolean;
  setActionError: React.Dispatch<React.SetStateAction<boolean>>;
  openAddConfirm: (epcCodes: EpcSelected[]) => void;
  onRemoveClick: (selected?: EpcSelected[] | undefined) => Promise<void>;
}

export const ProductListItem: FC<ProductListItemProps & ScannedProduct> = ({
  onPrintClick,
  onProductImageClick,
  disableActions,
  type,
  setActionError,
  openAddConfirm,
  showDeliveryNumber,
  onRemoveClick,
  ...product
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { unexpectedEpcs } = useSelector(state => state.scannedProducts);
  const { selectedDeliveries } = useSelector(state => state.deliveries);
  const selectedDeliveriesCodes = selectedDeliveries.map(
    item => item.codeDelivery
  );

  const printers = useSelector(
    state => state.currentStore.store?.printers || []
  );

  const [accordionExpended, setAccordionExpanded] = useState<boolean>(false);
  const accordionClickHandler = (): void => setAccordionExpanded(p => !p);

  const hasSingleEpc = product.epcCodes && product.epcCodes.length === 1;
  const hasCheckbox = type === Product.UNEXPECTED;

  const upcSelected = useMemo(() => {
    const upc = unexpectedEpcs.find(
      ({ upcCode }) => upcCode === product.upcCode
    );

    return upc ? upc.epcCodes.length === product.epcCodes?.length : false;
  }, [product.epcCodes?.length, product.upcCode, unexpectedEpcs]);

  const epcSelected = useCallback(
    (epcCode: string) => {
      const upc = unexpectedEpcs.find(
        ({ upcCode }) => upcCode === product.upcCode
      );

      return upc
        ? upc.epcCodes.map(({ epcCode }) => epcCode).includes(epcCode)
        : false;
    },
    [product.upcCode, unexpectedEpcs]
  );

  const upcCheckboxHandler = (): void => {
    dispatch(
      setUpcSelected({
        upcCode: product.upcCode!,
        epcCodes: product.epcCodes!,
      })
    );
  };

  const epcCheckboxHandler = (epcCode: string, isDecoded: boolean): void => {
    dispatch(
      setEpcSelected({
        upcCode: product.upcCode!,
        epcCode,
        isDecoded,
      })
    );
  };

  return (
    <StyledProductItem>
      <StyledProductContent $type={type}>
        <StyledContentInfo alignItems="flex-start">
          {type !== Product.NOT_FOUND && !hasSingleEpc && (
            <StyledAccordion
              expanded={accordionExpended}
              onClick={accordionClickHandler}
            />
          )}
          {hasCheckbox && (
            <StyledCheckbox
              checked={upcSelected}
              onClick={upcCheckboxHandler}
            />
          )}
          <ProductImage
            imageUrl={product.imageUrl}
            colorCode={product.colorCode}
            modelCode={product.modelCode}
            brandCode={product.brandCode}
            onClick={(e): void => onProductImageClick(e, product.upcCode!)}
          />
          <UIBox flexDirection="column">
            <StyledDescription>
              <Typography size="lg" font="heavy" as="span">
                {product.brandCode} - {product.brandDescription} -{' '}
                {product.styleName}
              </Typography>
              <Typography margin="8px 0 0 0" size="md" font="book" as="span">
                {product.upcCode} - {product.modelCode} - {product.commodity}
                {hasSingleEpc && (
                  <>
                    <StyledEpcCode>
                      {' '}
                      - {product.epcCodes?.[0].epcCode}{' '}
                      {product.epcCodes?.[0].isDecoded && '*'}
                    </StyledEpcCode>
                    <UIBox display="inline-flex" ml="20px">
                      <UIBox gridGap={5}>
                        {showDeliveryNumber &&
                          hasCheckbox &&
                          !selectedDeliveriesCodes.includes(
                            product.epcCodes?.[0].deliveryNumber ?? ''
                          ) && (
                            <>
                              <UIBox gridGap="10px" alignItems="center">
                                <DeliveryIcon width="12px" />
                                <Typography size="xxs">
                                  {product.epcCodes?.[0].deliveryNumber}
                                </Typography>
                              </UIBox>
                              <UIBox
                                gridGap="0px"
                                ml="10px"
                                alignItems="center"
                              >
                                <StyledTinyListItemStatusBadge
                                  inUse={
                                    product.epcCodes?.[0].deliveryStatus !==
                                    'New'
                                  }
                                />
                                <Typography size="xxs">
                                  {product.epcCodes?.[0].deliveryStatus}
                                </Typography>
                              </UIBox>
                            </>
                          )}
                        {type === Product.FOUND &&
                          product.epcCodes?.[0].printDate && (
                            <StyledBadge
                              width="100px"
                              textAlign="center"
                              display="inline-block"
                            >
                              <Typography size="xs">
                                {t('receiving.reprinted')}
                              </Typography>
                            </StyledBadge>
                          )}
                      </UIBox>
                    </UIBox>
                  </>
                )}
              </Typography>
            </StyledDescription>
          </UIBox>
        </StyledContentInfo>
        {type !== Product.NOT_FOUND && (
          <UIBox flexDirection="column" alignItems="end">
            <ProductPrice
              currency={product.currency}
              price={product.price ? String(product.price) : undefined}
            />
            {type === Product.UNEXPECTED && hasSingleEpc ? (
              <UIBox alignItems="center" gridGap="10px">
                <UIAction
                  label=""
                  icon="delete"
                  onClick={(): Promise<void> =>
                    onRemoveClick(
                      product.epcCodes
                        ? [
                            {
                              upcCode: product.upcCode || '',
                              epcCodes: product.epcCodes,
                            },
                          ]
                        : []
                    )
                  }
                  disabled={disableActions}
                />
                <UIAction
                  label=""
                  icon="add"
                  onClick={(): void =>
                    openAddConfirm(
                      product.epcCodes
                        ? [
                            {
                              upcCode: product.upcCode || '',
                              epcCodes: product.epcCodes,
                            },
                          ]
                        : []
                    )
                  }
                  disabled={
                    (product.epcCodes?.[0].deliveryStatus !== 'New' &&
                      !selectedDeliveriesCodes.includes(
                        product.epcCodes?.[0].deliveryNumber ?? ''
                      )) ||
                    disableActions
                  }
                />
              </UIBox>
            ) : (
              <Typography font="light">
                {t('itemQuantity', {
                  itemQuantity: product.epcCodes?.length,
                })}
              </Typography>
            )}
          </UIBox>
        )}
        {type === Product.NOT_FOUND && (
          <StyledContentInfo justifyContent="space-between">
            <UIBox>
              <StyledCountWrapper mr={2}>
                <StyledCount mb={1}>
                  <Typography color="grey" size="xs" font="heavy">
                    {t('receiving.shipqty')}
                  </Typography>
                </StyledCount>
                <StyledCount>
                  <Typography size="xl" font="heavy">
                    {product.expectedQty}
                  </Typography>
                </StyledCount>
              </StyledCountWrapper>
              <StyledCountWrapper>
                <StyledCount mb={1}>
                  <Typography color="grey" size="xs" font="heavy">
                    {t('receiving.countqty')}
                  </Typography>
                </StyledCount>
                <StyledCount>
                  <Typography size="xl" font="heavy">
                    {product.foundQty}
                  </Typography>
                </StyledCount>
              </StyledCountWrapper>
            </UIBox>
            <StyledActionWrapper>
              <UIAction
                label={t('print')}
                icon="print"
                onClick={(): void => onPrintClick?.(product)}
                disabled={disableActions || printers.length === 0}
              />
            </StyledActionWrapper>
          </StyledContentInfo>
        )}
      </StyledProductContent>
      {!hasSingleEpc && accordionExpended && (
        <StyledAccordionContent ml={hasCheckbox ? '146px' : '92px'}>
          {product.epcCodes?.map(
            ({
              epcCode,
              isDecoded,
              deliveryNumber,
              deliveryStatus,
              printDate,
            }) => (
              <StyledAccordionItemContent
                key={epcCode}
                justifyContent="space-between"
              >
                <UIBox alignItems="center">
                  {hasCheckbox && (
                    <StyledCheckbox
                      onClick={(): void =>
                        epcCheckboxHandler(epcCode!, isDecoded!)
                      }
                      checked={epcSelected(epcCode!)}
                    />
                  )}
                  <Typography
                    margin="0 0 0 12px"
                    color="grey"
                    as="div"
                    style={{ display: 'inline-flex' }}
                  >
                    {epcCode} {isDecoded && '*'}
                    {Product.FOUND && printDate && (
                      <StyledBadge
                        width="100px"
                        textAlign="center"
                        display="inline-block"
                      >
                        <Typography size="xs">
                          {t('receiving.reprinted')}
                        </Typography>
                      </StyledBadge>
                    )}
                    {showDeliveryNumber &&
                      hasCheckbox &&
                      !selectedDeliveriesCodes.includes(
                        deliveryNumber ?? ''
                      ) && (
                        <>
                          <UIBox gridGap="10px" alignItems="center" ml="10px">
                            <DeliveryIcon width="12px" />
                            <Typography size="xxs">{deliveryNumber}</Typography>
                          </UIBox>
                          <UIBox gridGap="0px" ml="10px" alignItems="center">
                            <StyledTinyListItemStatusBadge
                              inUse={
                                product.epcCodes?.[0].deliveryStatus !== 'New'
                              }
                            />
                            <Typography size="xxs">{deliveryStatus}</Typography>
                          </UIBox>
                        </>
                      )}
                  </Typography>
                </UIBox>
                {type === Product.UNEXPECTED && (
                  <StyledActionWrapper>
                    <UIBox alignItems="center" gridGap="20px">
                      <UIAction
                        label={t('')}
                        icon="delete"
                        disabled={disableActions}
                        onClick={(): Promise<void> => {
                          return onRemoveClick(
                            product.epcCodes
                              ? [
                                  {
                                    upcCode: product.upcCode || '',
                                    epcCodes:
                                      product.epcCodes?.filter(
                                        item => item.epcCode === epcCode
                                      ) || [],
                                  },
                                ]
                              : []
                          );
                        }}
                      />
                      <UIAction
                        label={t('')}
                        icon="add"
                        onClick={(): void =>
                          openAddConfirm([
                            {
                              upcCode: product.upcCode!,
                              epcCodes:
                                product.epcCodes?.filter(
                                  item => item.epcCode === epcCode
                                ) || [],
                            },
                          ])
                        }
                        disabled={
                          (deliveryStatus !== 'New' &&
                            !selectedDeliveriesCodes.includes(
                              deliveryNumber ?? ''
                            )) ||
                          disableActions
                        }
                      />
                    </UIBox>
                  </StyledActionWrapper>
                )}
              </StyledAccordionItemContent>
            )
          )}
        </StyledAccordionContent>
      )}
    </StyledProductItem>
  );
};

export default ProductListItem;
