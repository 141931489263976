import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { useAsync } from 'react-use';
import { useHistory } from 'react-router';
import styled, {
  css,
  CSSProperties,
  FlattenSimpleInterpolation,
} from 'styled-components';

import UIAutocomplete from '@/components/ui/AutoComplete/AutoComplete';
import { UIBox } from '@/components/ui/Box';
import { UIAction } from '@/components/ui/Action';
import { Typography } from '@/components/ui/Typography';
import { DamageList } from '@/components/layout/DamageList';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import { ModalReasonDamages } from '@/components/layout/ModalReasonDamages';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';

import {
  cleanBackup,
  confirmDamagedItems,
  fetchDamageItems,
  fetchDamageReasons,
  removeEpcs,
  undoRemovedEpcs,
} from '@/features/storeDamages/storeDamagesSlice';
import {
  deleteTags,
  removeTags,
  tagsMQTTDevice,
} from '@/features/devices/devicesSlice';
import { ScanDevicesService } from '@/api';
import { EnumMode } from '@/types/enum';
import {
  ConfimCreateDamages,
  ElasticHandlerRestService,
  SearchItemsForCreateDamagesResponse,
  UpcDamagesAndReturns,
} from '@/api/receive';
import { AppRoutes } from '@/app/routers';
import { AlertUndoSnackbar } from '@/components/ui/AlertSnackbar';
import { UIButtonWithIcon } from '@/components/ui/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import { UILoader } from '@/components/ui/Loader';

const StyledPageWrapper = styled('div')`
  position: relative;
  padding: 24px;
  width: 100%;
`;

const StyledSpan = styled(Typography)`
  margin-top: 8px;
`;

const StyledAction = styled(UIAction)<{ margin?: CSSProperties['margin'] }>`
  ${({ margin }): false | FlattenSimpleInterpolation | undefined => css`
    margin: ${margin};
  `};
`;

const PageStoreDamagesCreate: React.FC = () => {
  const { t } = useTranslation();
  const { tags } = useSelector(state => state.devices);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const autocompleteInputRef = useRef<HTMLInputElement>(null);

  const [scanCompleted, setScanCompleted] = useState<boolean>(false);
  const [finishScanVisible, setFinishScanVisible] = useState<boolean>(false);
  const [finishScanIsLoading, setFinishScanLoadingStatus] =
    useState<boolean>(false);
  const [scanModalIsOpen, setScanModalVisibility] = useState<boolean>(true);
  const [listCurrentPage, setListCurrentPage] = useState<number>(1);
  const [searchedEpc, setSearchedEpc] = useState<string>('');
  const [searchedEpcs, setSearchedEpcs] = useState<string[]>([]);
  const [deviceError, setDeviceError] = useState<boolean>(false);
  const [deviceErrorMsg, setDeviceErrorMsg] = useState<string>();
  const [undoSnackbarIsVisible, setUndoSnackbarVisibility] =
    useState<boolean>(false);
  const [reasonModalIsOpen, setReasonModalVisibility] =
    useState<boolean>(false);
  const [damageItemsHasError, setDamageItemsHasError] =
    useState<boolean>(false);
  const [confirmDamageHasError, setConfirmDamageHasError] =
    useState<boolean>(false);
  const [paginationItems, setPaginationItems] = useState<
    SearchItemsForCreateDamagesResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { deviceInUse, deleteTagsHasError } = useSelector(
    state => state.devices
  );
  const { store } = useSelector(state => state.currentStore);
  const {
    damageItems,
    selectedEpcs,
    confirmDamagedItemsHasError,
    confirmDamagedItemsIsLoading,
    fetchDamageItemsHasError,
  } = useSelector(state => state.storeDamages);

  /**
   * MQTT: Passare l'array di tags presente in redux anziché lo stato locale
   */
  useAsync(async () => {
    if (tags.length > 0) {
      await dispatch(
        fetchDamageItems({
          epcCodes: tags,
        })
      );
      scanModalIsOpen && setFinishScanVisible(true);
      scanModalIsOpen && setScanModalVisibility(false);
    }
  }, [tags.length]);

  useAsync(async () => {
    dispatch(fetchDamageReasons());
  }, []);

  useEffect(() => {
    if (deleteTagsHasError) {
      setDeviceError(true);
      setDeviceErrorMsg(deleteTagsHasError.body.message);
    }
  }, [deleteTagsHasError]);

  useEffect(() => {
    if (fetchDamageItemsHasError) {
      setDamageItemsHasError(true);
    }
  }, [fetchDamageItemsHasError]);

  useEffect(() => {
    if (confirmDamagedItemsHasError) {
      setConfirmDamageHasError(true);
    }
  }, [confirmDamagedItemsHasError]);

  useEffect(() => {
    if (damageItems) {
      setPaginationItems(damageItems);
    }
  }, [damageItems]);

  const refreshClick = async (): Promise<void> => {
    setIsLoading(true);
    const { tags: deviceTags } = await ScanDevicesService.getTags({
      devId: deviceInUse?.deviceId!,
    });

    if (deviceTags && deviceTags.length > 0) {
      dispatch(tagsMQTTDevice(deviceTags));
    }

    setIsLoading(false);
  };

  const damageItemsCount = damageItems?.flatMap(
    ({ epcCodes }) => epcCodes
  ).length;

  const noReasonForItems = useMemo(() => {
    const reasons = damageItems
      ?.flatMap(({ epcCodes }) => epcCodes)
      .map(({ movementReason }) => movementReason);

    return reasons?.some(reason => reason === null);
  }, [damageItems]);

  const getAutocompleteOptions = async (): Promise<void> => {
    if (searchedEpc.length >= 4) {
      try {
        const { epcList } =
          await ElasticHandlerRestService.elastichandlerSearchEpcAutocomplete({
            epcCode: searchedEpc,
          });

        if (epcList) {
          if (epcList?.length > 0) {
            const list = epcList.map(({ epcCode }) => (epcCode ? epcCode : ''));

            return setSearchedEpcs(list);
          }
        }

        return setSearchedEpcs([]);
      } catch (e) {
        console.log('error', e);
      }
    }

    return setSearchedEpcs([]);
  };

  const onAutoCompleteFocus = (): void => {
    if (searchedEpc.length === 0) {
      setSearchedEpcs([]);
    }
  };

  const onAutoCompleteOptionSelect = (epcCode: string | null): void => {
    if (epcCode) {
      dispatch(tagsMQTTDevice([{ epc: epcCode }]));
    }
  };

  const searchEpcHandler: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    setSearchedEpc(value);
  };

  const confirmClickHandler = async (): Promise<void> => {
    if (store?.storeCode && damageItems) {
      try {
        const storeCode = store.storeCode;

        const upcCodes: UpcDamagesAndReturns[] = damageItems.map(
          ({ upcCode, epcCodes }) => ({
            upcCode,
            epcs: epcCodes.map(
              ({ epcCode, movementTypeCode, movementReasonCode }) => ({
                epcCode,
                movementTypeCode,
                movementReasonCode,
              })
            ),
          })
        );

        const requestBody: ConfimCreateDamages = { storeCode, upcCodes };
        await dispatch(confirmDamagedItems(requestBody)).unwrap();
        history.push(AppRoutes.PDF_DOWNLOAD_CREATE_DAMAGE);
      } catch (err) {}
    }
  };

  const removeClickHandler = (): void => {
    dispatch(removeTags(selectedEpcs));
    dispatch(removeEpcs());
    setUndoSnackbarVisibility(true);
  };

  const undoSnackbarClickHandler = (): void => {
    dispatch(undoRemovedEpcs());
    setUndoSnackbarVisibility(false);
    dispatch(cleanBackup());
  };

  const undoSnackbarCloseHandler = (): void => {
    dispatch(cleanBackup());
  };

  const scanClickHandler = (): void => {
    setScanModalVisibility(true);
    // setFinishScanVisible(true);
  };

  const finishScanClickHandler = async (): Promise<void> => {
    try {
      setFinishScanLoadingStatus(true);

      const { tags: deviceTags, mode } = await ScanDevicesService.getTags({
        devId: deviceInUse?.deviceId!,
      });

      if (mode === 'no mode') {
        setFinishScanLoadingStatus(false);
        setDeviceErrorMsg(t('error.deviceCommunication'));
        return setDeviceError(true);
      }

      if (deviceTags && deviceTags.length > 0) {
        dispatch(tagsMQTTDevice(deviceTags));
      }

      setTimeout(async () => {
        const { code, message } = await ScanDevicesService.activateScanning({
          requestBody: {
            dev_id: deviceInUse?.deviceId!,
            enable: 'False',
          },
        });

        if (code !== 'OK') {
          setDeviceErrorMsg(message);
          setFinishScanLoadingStatus(false);
          return setDeviceError(true);
        }
      }, 1000);

      setTimeout(async () => {
        await dispatch(deleteTags());

        setFinishScanVisible(false);
        setFinishScanLoadingStatus(false);
        setScanCompleted(true);
      }, 2000);
    } catch {
      setFinishScanLoadingStatus(false);
      setDeviceError(true);
    }
  };

  return (
    <StyledPageWrapper>
      <ModalScanDetailsV2
        resetDeviceInUse={!scanCompleted}
        open={scanModalIsOpen}
        isScanningAgain={scanCompleted}
        setIsScanCompleted={setScanCompleted}
        onClose={(): void => setScanModalVisibility(false)}
        disabledModes={[EnumMode.FIND]}
        hideModes={[EnumMode.FIND]}
        startGettingTags={setFinishScanVisible}
      />
      <ModalReasonDamages
        open={reasonModalIsOpen}
        onClose={(): void => setReasonModalVisibility(false)}
      />
      <UIBox justifyContent="space-between">
        <UIBox flexDirection="column">
          <Typography font="heavy" size="lg">
            {t('damagedItemsList')}
          </Typography>
          <StyledSpan color="grey" size="md">
            {t('insertReasonForItems')}
          </StyledSpan>
        </UIBox>

        <UIAutocomplete
          disableClearable
          width={350}
          minSearchTermLength={4}
          label={t('searchByEpc')}
          searchTerm={searchedEpc}
          options={searchedEpcs}
          getOptions={getAutocompleteOptions}
          inputRef={autocompleteInputRef}
          onChange={searchEpcHandler}
          onFocus={onAutoCompleteFocus}
          loadingText={t('searching')}
          noOptionsText={t('error.no_results_available')}
          onOptionSelect={(_e, epcCode): void =>
            onAutoCompleteOptionSelect(epcCode)
          }
          helperText={'Search manually in case the device doesn’t work'}
          inputProps={{ maxLength: 24, minLength: 24 }}
        />
      </UIBox>
      <UIBox marginTop={3} flexDirection="row" justifyContent="space-between">
        <Typography>
          {t('items')}: <Typography font="black">{damageItemsCount}</Typography>
        </Typography>
        {isLoading ? (
          <UILoader size={20} />
        ) : (
          <UIButtonWithIcon
            label={t('refresh')}
            startIcon={<RefreshIcon />}
            onClick={(): Promise<void> => refreshClick()}
            disabled={!deviceInUse}
          />
        )}
      </UIBox>
      <DamageList
        paginationItems={paginationItems}
        listCurrentPage={listCurrentPage}
        setListCurrentPage={setListCurrentPage}
        actions={
          <UIBox marginLeft="auto">
            <StyledAction
              label={t('reason')}
              disabled={selectedEpcs.length === 0 || finishScanVisible}
              icon="edit"
              onClick={(): void => setReasonModalVisibility(true)}
              margin="0 24px"
            />
            <StyledAction
              label={t('remove')}
              onClick={removeClickHandler}
              icon="delete"
              disabled={selectedEpcs.length === 0 || finishScanVisible}
            />
          </UIBox>
        }
      />
      {finishScanVisible ? (
        <CTAContainer
          type="FINISH SCAN"
          loading={finishScanIsLoading}
          onClick={finishScanClickHandler}
        />
      ) : (
        <CTAContainer
          type="DEFAULT"
          onQuitClick={(): void => history.goBack()}
          onScanClick={scanClickHandler}
          disabled={confirmDamagedItemsIsLoading}
          disabledConfirm={noReasonForItems || damageItems?.length === 0}
          loadingConfirm={confirmDamagedItemsIsLoading}
          onConfirmClick={confirmClickHandler}
        />
      )}
      <AlertUndoSnackbar
        open={undoSnackbarIsVisible}
        setIsOpen={setUndoSnackbarVisibility}
        onClose={undoSnackbarCloseHandler}
        onUndoClick={undoSnackbarClickHandler}
        message={t('notification.itemRemoved')}
      />
      <ErrorSnackbar
        open={damageItemsHasError}
        setIsOpen={setDamageItemsHasError}
        errorMessage={fetchDamageItemsHasError?.body}
      />
      <ErrorSnackbar
        open={confirmDamageHasError}
        setIsOpen={setConfirmDamageHasError}
        errorMessage={
          confirmDamagedItemsHasError?.body.errorMessage ||
          confirmDamagedItemsHasError?.body
        }
      />
      <ErrorSnackbar
        open={deviceError}
        setIsOpen={setDeviceError}
        errorMessage={deviceErrorMsg}
      />
    </StyledPageWrapper>
  );
};

export default PageStoreDamagesCreate;
