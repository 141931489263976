import styled from 'styled-components';

import { IconButton, Stepper } from '@material-ui/core';
import { UIButton } from '@/components/ui/Button';
import Radio from '@material-ui/core/Radio';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import { colors, palette } from '@/theme';
import { UIBox } from '@/components/ui/Box';

export const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

export const StyledSubContainer = styled.div`
  padding: 3rem;
  padding-top: 2rem;
  align-items: stretch;
  justify-content: center;
  text-align: center;
  column-gap: 58px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const StyledClose = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const StyledColumn = styled(UIBox)`
  width: 100%;
  height: 100%;
  padding: 24px;
  flex-direction: column;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0 -1px 21px rgba(0, 0, 0, 0.1);

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                    &:last-child {
                      display: block;
                    }
                }`}
`;

export const StyledTitle = styled(UIBox)`
  align-items: center;
  justify-content: center;
  background: #fbfbfb;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: 1px solid #ededed;
`;

export const StyledContent = styled(UIBox)`
  height: 100%;
  flex-direction: column;
  justify-content: center;

  button {
    width: auto !important;
  }

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                  justify-content: flex-start;
                }`}
`;

export const StyledUILoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: -12px;
`;

export const StyledErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 12px 0;

  svg {
    margin: 0 auto !important;
  }
`;

export const StyledDeviceInfoWrapper = styled(UIBox)`
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const StyledRadio = styled(Radio)`
  margin-right: 16px;
`;

export const StyledPrinterInfo = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StyledStepper = styled(Stepper)`
  grid-column: 1/-1;

  .MuiStepIcon-root:not(.MuiStepIcon-active) {
    color: darkgray;
  }
`;

export const StyledStepContainer = styled.div<{ isActive: boolean }>`
  filter: grayscale(${({ isActive }): number => (isActive ? 0 : 1)});
  pointer-events: ${({ isActive }): string => (isActive ? 'initial' : 'none')};
  min-height: 425px;
`;

export const StyledPrintButton = styled(UIButton)`
  &&& {
    width: 200px !important;
    margin: 0 auto;
  }
`;

export const StyledStartUseReaderButton = styled(UIButton)`
  &&& {
    width: 200px !important;
    margin: auto auto 0 auto;
  }
`;

export const StyledMessageWrapper = styled(UIBox)`
  margin: -8px auto 12px auto;
  max-width: 240px;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const StyledMessageIconWrapper = styled(UIBox)<{ color?: string }>`
  margin-bottom: 16px;
  width: 40px;
  height: 40px;
  background: ${({ color }): string => (color ? color : '#417505')};
  align-items: center;
  justify-content: center;
  border-radius: 100px;
`;

export const StyledDoneIcon = styled(DoneIcon)`
  fill: ${palette.colors.white};
  margin: 24px auto;
  width: 26px;
  height: 26px;
`;

export const StyledPrintedEpc = styled(UIBox)`
  max-width: 240px;
  text-align: center;
  margin: 32px auto;
`;

export const StyledErrorOutlineIcon = styled(ErrorIcon)`
  fill: #f6bb3b;
  margin: 24px auto;
  width: 33px;
  height: 33px;
`;

export const StyledErrorIcon = styled(ErrorIcon)`
  fill: ${palette.colors.white};
`;

export const StyledBadge = styled(UIBox)`
  background-color: ${colors.primary};
  padding: 4px 8px;
  text-transform: uppercase;
  border-radius: 100px;
  margin-left: 8px;

  &&& > * {
    color: ${palette.colors.white};
  }
`;
