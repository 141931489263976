import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EnumMode } from '@/types/enum';

import { Row } from '@/components/layout/Row';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import { StyledColumn, StyledTitle, StyledContent, StyledText } from './styles';
import { Typography } from '@/components/ui/Typography';
import { StyledRadio } from './styles';

export interface ModeProps {
  mode: EnumMode;
  handleMode: () => void;
  disabledModes?: EnumMode[];
  hideModes?: EnumMode[];
  onStartReaderClick?: () => void;
}

export const ColumnMode: FC<ModeProps> = ({
  mode,
  disabledModes = [],
  hideModes = [],
  handleMode,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledColumn>
      <StyledTitle>
        <Typography size="lg" font="heavy" as="h1">
          {t('scanwizard.2.mode')}
        </Typography>
      </StyledTitle>
      <StyledContent>
        {!hideModes.includes(EnumMode.INVENTORY) && (
          <Row mt="0">
            <StyledRadio
              icon={<CircleUnchecked />}
              checkedIcon={<CircleChecked />}
              checked={mode === EnumMode.INVENTORY}
              disabled={
                mode === EnumMode.INVENTORY ||
                disabledModes?.includes(EnumMode.INVENTORY)
              }
              onChange={handleMode}
            />
            <StyledText>
              <Typography size="sm" font="heavy" as="h1">
                {t('scanwizard.standard')}
              </Typography>
              <Typography size="xs" as="p">
                {t('scanwizard.standard_desc')}
              </Typography>
            </StyledText>
          </Row>
        )}
        {!hideModes.includes(EnumMode.FIND) && (
          <Row mt="56">
            <StyledRadio
              icon={<CircleUnchecked />}
              checkedIcon={<CircleChecked />}
              checked={mode === EnumMode.FIND}
              disabled={
                mode === EnumMode.FIND || disabledModes.includes(EnumMode.FIND)
              }
              onChange={handleMode}
            />
            <StyledText>
              <Typography size="sm" font="heavy" as="h1">
                {t('scanwizard.find')}
              </Typography>
              <Typography size="xs" as="p">
                {t('scanwizard.find_desc')}
              </Typography>
            </StyledText>
          </Row>
        )}
      </StyledContent>
    </StyledColumn>
  );
};

export default ColumnMode;
