import { Product, ProductValues } from '@/types/enum';

type Generic = {
  brandCode?: string;
  modelCode?: string;
};

const displayProductHeader = <T extends Generic>(
  data: T[],
  type: ProductValues,
  index: number
): boolean => {
  if (type !== Product.UNEXPECTED) {
    const currProduct = data[index];
    const prevProduct = data[index - 1];

    if (!prevProduct) return true;
    if (!currProduct?.modelCode) return true;

    if (
      currProduct?.brandCode === prevProduct?.brandCode &&
      currProduct?.modelCode !== prevProduct?.modelCode
    )
      return true;

    if (
      currProduct?.brandCode !== prevProduct?.brandCode &&
      currProduct?.modelCode !== prevProduct?.modelCode
    )
      return true;

    return false;
  }

  return false;
};

export default displayProductHeader;
