/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListPrintDeviceResponse } from '../models/ListPrintDeviceResponse';
import type { PrintDevice } from '../models/PrintDevice';
import { request as __request } from '../core/request';

export class PrintDeviceService {
  /**
   * create print device
   * create print device (the isRfid tag in the request body must be set to TRUE if it is an rfid printer or to FALSE for a no rfid printer)
   * @returns any PrintDevice created.
   * @throws ApiError
   */
  public static async printCreatePrintDevice({
    requestBody,
  }: {
    requestBody: PrintDevice;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/print/createPrintDevice`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
      },
    });
    return result.body;
  }

  /**
   * update print device
   * update print device (the isRfid tag in the request body must be set to TRUE if it is an rfid printer or to FALSE for a no rfid printer)
   * @returns any PrintDevice updated.
   * @throws ApiError
   */
  public static async printUpdatePrintDevice({
    requestBody,
  }: {
    requestBody: PrintDevice;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/print/updatePrintDevice`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
      },
    });
    return result.body;
  }

  /**
   * delete print device
   * delete print device
   * @returns any Print device successfully deleted.
   * @throws ApiError
   */
  public static async printDeletePrintDevice({
    printerCode,
    storeCode,
  }: {
    /** pass printerCode **/
    printerCode: string;
    /** store code **/
    storeCode: string;
  }): Promise<any> {
    const result = await __request({
      method: 'DELETE',
      path: `/print/deletePrintDevice`,
      query: {
        printerCode: printerCode,
        storeCode: storeCode,
      },
      errors: {
        400: `Bad request. printerCode and storeCode are mandatory.`,
      },
    });
    return result.body;
  }

  /**
   * List Print Devices by store code
   * List Print Devices by store code
   * @returns ListPrintDeviceResponse OK
   * @returns any No content
   * @throws ApiError
   */
  public static async printListPrintDevices({
    storeCode,
  }: {
    /** filter by storeCode **/
    storeCode: any;
  }): Promise<ListPrintDeviceResponse | any> {
    const result = await __request({
      method: 'GET',
      path: `/print/listPrintDevices`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        404: `Not found`,
        500: `Generic error.`,
      },
    });
    return result.body;
  }
}
