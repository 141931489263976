/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckoutItem } from '../models/CheckoutItem';
import type { CheckoutLightRequest } from '../models/CheckoutLightRequest';
import type { ConfirmCheckoutResponse } from '../models/ConfirmCheckoutResponse';
import { request as __request } from '../core/request';

export class CheckoutLightService {
  /**
   * SA have to see all the information about items for checkout
   * By passing in the appropriate options, you can search for items and we return a list of information
   * @returns CheckoutItem OK
   * @throws ApiError
   */
  public static async receiveshipSearchProductCheckoutItems({
    requestBody,
  }: {
    requestBody: CheckoutLightRequest;
  }): Promise<Array<CheckoutItem>> {
    const result = await __request({
      method: 'POST',
      path: `/checkoutlight/search`,
      body: requestBody,
      errors: {
        400: `bad input parameter, List of epcs is mandatory`,
      },
    });
    return result.body;
  }

  /**
   * SA wants to CONFIRM a checkout
   * Confirm confirmCheckout after scan
   * @returns ConfirmCheckoutResponse OK
   * @throws ApiError
   */
  public static async receiveshipConfirmCheckout({
    requestBody,
  }: {
    requestBody: CheckoutLightRequest;
  }): Promise<ConfirmCheckoutResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/checkoutlight/confirm`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        406: `Not accettable.`,
        500: `Internal server error.`,
      },
    });
    return result.body;
  }
}
