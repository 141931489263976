import styled from 'styled-components';

import DoneIcon from '@material-ui/icons/Done';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { IconButton, Radio, Step, Stepper } from '@material-ui/core';
import { UIButton } from '@/components/ui/Button';
import { UIModal } from '@/components/ui/Modal';
import { theme } from '@/theme';

// ***** MODAL STYLES ***** //

export const StyledModal = styled(UIModal)`
  max-width: 60vw;
  margin: 0 auto;

  ${({ theme }): string =>
    `${theme.breakpoints.down('md')} {
				max-width: 80vw;
  	}`}

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
				max-width: 95vw;
  	}`}
`;

export const StyledWrapper = styled(UIBox)`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

export const StyledContainer = styled(UIBox)`
  padding: 3rem;
  padding-top: 2rem;
  align-items: stretch;
  justify-content: center;
  text-align: center;
  column-gap: 58px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const StyledCloseIcon = styled(IconButton)`
  align-self: flex-end;
  position: absolute !important;

  svg {
    fill: #005192;
  }

  ${theme.breakpoints.down('md')} {
    top: 0;
    right: 0.5rem;
  }
`;

export const StyledModalTitle = styled(Typography)`
  margin: 1.5rem 0 1rem;
  text-align: center;
`;

export const StyledStepper = styled(Stepper)`
  grid-column: 1/-1;
  .MuiStepIcon-root:not(.MuiStepIcon-active) {
    color: darkgray;
  }
`;

export const StyledStepContainer = styled.div<{ isActive: boolean }>`
  filter: grayscale(${({ isActive }): number => (isActive ? 0 : 1)});
  pointer-events: ${({ isActive }): string => (isActive ? 'initial' : 'none')};
  min-height: 425px;
`;

export const StyledStep = styled(Step)`
  padding: 0 10%;
  cursor: pointer;
`;

// ***** COLUMN STYLES ***** //

export const StyledColumn = styled(UIBox)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0 -1px 21px rgba(0, 0, 0, 0.1);

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                    &:last-child {
                      display: block;
                    }
                }`}
`;

export const StyledColumnDevice = styled(StyledColumn)`
  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                    min-height: 250px;
                }`}
`;

export const StyledContent = styled(UIBox)`
  height: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  button {
    width: auto !important;
  }

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                  justify-content: flex-start;
                }`}
`;

export const StyledContentDevice = styled(StyledContent)`
  overflow-y: auto;
`;

// ***** COLUMN DEVICE STYLES ***** //

export const StyledUILoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledDeviceInfoWrapper = styled(UIBox)`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

// ***** COLUMN MODE STYLES ***** //

export const StyledRadio = styled(Radio)`
  margin-right: 16px;
`;

export const StyledText = styled(UIBox)`
  flex-direction: column;
  margin-bottom: 16px;

  h1 {
    margin: 4px 0;
  }

  p {
    margin: 0;
  }
`;

export const StyledButton = styled(UIButton)`
  margin: 24px 0;
`;

export const StyledLoaderWrapper = styled(UIBox)`
  margin-top: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    margin-bottom: 16px;
  }
`;

export const StyledDoneIcon = styled(DoneIcon)`
  fill: #417505;
  margin: 24px auto;
  width: 26px;
  height: 26px;
`;

export const StyledTypography = styled(Typography)`
  text-align: center;
  line-height: 19px;
`;

export const StyledReleaseButtonWrapper = styled(UIBox)`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

// ***** USED IN OTHER COMPONENTS ***** //

export const StyledTitle = styled(UIBox)`
  align-items: center;
  justify-content: center;
  background: #fbfbfb;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: 1px solid #ededed;
`;
