import styled from 'styled-components';

import { Typography } from '@/components/ui/Typography';

export const StyledRightSideDatas = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const StyledTimestampEpc = styled(Typography)`
  display: flex;
  gap: 8px;
  align-items: center;
`;
