import { UIBox } from '@/components/ui/Box';
import { StyledAccordionContent } from '@/pages/CycleCount/listItemStyle';
import styled from 'styled-components';

export const StyledProductItem = styled(UIBox)`
  flex-direction: column;
  padding: 20px;
  background: #fbfbfb;
  align-content: start;

  &:not(:last-of-type) {
    border-bottom: 1px solid #ededed;
  }
`;

export const CustomStyledAccordionContent = styled(StyledAccordionContent)`
  padding: 18px 0 0 0px;
`;
