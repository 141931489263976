import styled from 'styled-components';
import { UIAccordion } from '@/components/ui/Accordion';
import { UIBox } from '@/components/ui/Box';

export const Row = styled(UIBox)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  .file-name {
    flex: 1;
  }
`;

export const Accordion = styled(UIAccordion)`
  height: 32px;
  margin-right: 12px !important;
`;
