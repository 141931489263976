export const getSessionItem = (
  key: string,
  type: string = 'string',
  defaultValue: string = ''
): string | number | boolean => {
  const item = sessionStorage.getItem(key || '') || `${defaultValue}`;
  switch (type) {
    case 'boolean':
      return item === 'true';
    case 'int':
      return parseInt(item);
    case 'float':
      return parseFloat(item);
    default:
      return item;
  }
};
export const getSessionObject = (
  key: string | null,
  defaultValue: object = {}
): object => {
  const val = sessionStorage.getItem(key || '');
  if (val) {
    return JSON.parse(val);
  }
  return defaultValue;
};
export const setSessionItem = (
  key: string,
  value: string | number | boolean
): void => sessionStorage.setItem(key, `${value}`);
export const setSessionObject = (
  key: string,
  value: object | Array<string | number | boolean>
): void => sessionStorage.setItem(key, JSON.stringify(value));
