import { CustomReturnPosItem } from '@/types/returnPos';

export enum Type {
  RETURN = 'RETURN',
  VOID = 'VOID',
  EXCEPTIONS = 'EXCEPTIONS',
}

export type ProductListProps = {
  products: CustomReturnPosItem[];
  type: Type;
  onPrintClick?: (
    type: Type,
    product: CustomReturnPosItem
  ) => Promise<void> | void;
  onRemoveClick?: (product: CustomReturnPosItem) => Promise<void> | void;
};
