import { RootState } from '@/app/rootReducer';
import { InitialBisState } from '@/types/bis';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ReceiveApiError as ApiError } from '@/api';
import { getUniqueListBy } from '@/utils';
import { BackInStockSearchResponse, BackInStockService } from '@/api/receive';

const initialState: InitialBisState = {
  processLocked: false,
  isLoading: false,
  products: [],
  avoidAlertInHome: false,
};

export const getBisProducts = createAsyncThunk<
  BackInStockSearchResponse[] | undefined,
  { tags: string[] },
  {
    state: RootState;
    rejectValue: ApiError;
  }
>('bis/getSalesProducts', async ({ tags }, { getState, rejectWithValue }) => {
  const { currentStore } = getState();

  if (currentStore.store) {
    const { storeCode } = currentStore.store;

    if (storeCode) {
      if (tags.length > 0) {
        try {
          return await BackInStockService.backinstockSearch({
            requestBody: {
              epcCodes: tags,
              storeCode: storeCode,
            },
          });
        } catch (error) {
          return rejectWithValue(error as ApiError);
        }
      }
    }
  }
});

export const confirmBisProducts = createAsyncThunk<
  BackInStockSearchResponse[] | undefined,
  { tags: string[] },
  {
    state: RootState;
    rejectValue: ApiError;
  }
>('bis/addBisProducts', async ({ tags }, { getState, rejectWithValue }) => {
  const { currentStore } = getState();

  if (currentStore.store) {
    const { storeCode } = currentStore.store;

    if (storeCode) {
      if (tags.length > 0) {
        try {
          return await BackInStockService.backinstockAdd({
            requestBody: {
              epcCodes: tags,
              storeCode: storeCode,
            },
          });
        } catch (error) {
          return rejectWithValue(error as ApiError);
        }
      }
    }
  }
});

export const bisSlice = createSlice({
  name: 'bisSlice',
  initialState,
  reducers: {
    setProcessLocked: (state, { payload }: PayloadAction<boolean>) => {
      state.processLocked = payload;
    },
    setAvoidAlertInHomePage: (state, { payload }: PayloadAction<boolean>) => {
      state.avoidAlertInHome = payload;
    },
    resetProducts: state => {
      state.products = [];
    },
    initBISState: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getBisProducts.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getBisProducts.fulfilled, (state, { payload }) => {
        if (payload) {
          const itemInState = state.products.map(item => ({ ...item }));
          const updatedProducts = itemInState.concat(payload);
          state.products = getUniqueListBy(updatedProducts, 'upcCode');
        }
        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(getBisProducts.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload as ApiError;
      })
      .addCase(confirmBisProducts.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(confirmBisProducts.fulfilled, state => {
        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(confirmBisProducts.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload as ApiError;
      });
  },
});

export const {
  setProcessLocked,
  setAvoidAlertInHomePage,
  initBISState,
  resetProducts,
} = bisSlice.actions;
export default bisSlice.reducer;
