import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../useSelector';
import { useAppDispatch } from '@/app/store';
import { ScanDevicesService } from '@/api';
import { deleteTags, tagsMQTTDevice } from '@/features/devices/devicesSlice';

type ReturnType = {
  execute: () => Promise<string[]>;
  setIsScanCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  isCompleted: boolean;
  isLoading: boolean;
  error: boolean;
  errorMessage?: string;
};

const useFinishScan = (): ReturnType => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const deviceInUse = useSelector(state => state.devices.deviceInUse);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [isScanCompleted, setIsScanCompleted] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const onFinishScanClick = React.useCallback(async () => {
    try {
      setIsLoading(true);

      const { tags: deviceTags, mode } = await ScanDevicesService.getTags({
        devId: deviceInUse?.deviceId!,
      });

      if (mode === 'no mode') {
        setIsLoading(false);
        setErrorMessage(t('error.deviceCommunication'));
        setError(true);
      }

      if (deviceTags && deviceTags.length > 0) {
        dispatch(tagsMQTTDevice(deviceTags));
      }

      setTimeout(async () => {
        const { code, message } = await ScanDevicesService.activateScanning({
          requestBody: {
            dev_id: deviceInUse?.deviceId!,
            enable: 'False',
          },
        });

        if (code !== 'OK') {
          setErrorMessage(message);
          setIsLoading(false);
          setError(true);
        }
      }, 1000);

      setTimeout(async () => {
        await dispatch(deleteTags());

        setIsLoading(false);
        setIsScanCompleted(true);
      }, 2000);

      if (deviceTags) {
        return deviceTags?.map(({ epc }) => epc || '');
      }
    } catch {
      setIsLoading(false);
      setError(true);
    }

    return [];
  }, [deviceInUse?.deviceId, dispatch, t]);

  return {
    execute: onFinishScanClick,
    isCompleted: isScanCompleted,
    setIsScanCompleted,
    isLoading,
    error,
    errorMessage,
  };
};

export default useFinishScan;
