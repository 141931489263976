import React, { useState } from 'react';
import { UserProfile } from '@/api/receive';
import { useHistory, useParams } from 'react-router';
import { UIAction } from '@/components/ui/Action';
import { UIAccordion } from '@/components/ui/Accordion';
import { AppRoutes } from '@/app/routers';
import { Typography } from '@/components/ui/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { UrlUpdateStore } from '@/pages/ControlPanel/PageStoreDetails/types';
import { isUsersManager } from '@/utils/user';
import {
  StyledAccordionItem,
  StyledActionsWrapper,
  StyledGridRow,
  StyledGridRowContent,
  StyledRole,
  StyledRowWrapper,
  StyledSpan,
  StyledStatus,
  StyledStatusWrapper,
  StyledUserId,
} from './style';
import { ProfilingUsersRowProps, TUserSelect } from './types';
import { UICheckbox } from '@/components/ui/Checkbox';
import { useUpdateEffect } from 'react-use';

const ProfilingUsersRow: React.FC<UserProfile & ProfilingUsersRowProps> = ({
  userId,
  firstName,
  storeCode,
  lastName,
  roles,
  salesOrganization,
  streetAndHouseNumber,
  statusUser,
  setUserId,
  setAttentionModalVisibility,
  $showPartial,
  jobTitle,
  exportUserSelected,
  checked,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const { store } = useParams<UrlUpdateStore>();

  const [isAccordionExpanded, toggleAccordion] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<TUserSelect | undefined>();

  useUpdateEffect(() => {
    if (userSelected) {
      exportUserSelected?.(userSelected);
    }
  }, [userSelected, userSelected?.checked]);

  const onRemoveClick = (userId: string): void => {
    setUserId(userId);
    setAttentionModalVisibility(true);
  };

  const onEditClick = (userId: string): void => {
    history.push(`${AppRoutes.MANAGE_USERS_EDIT}?userId=${userId}`, {
      fromStore: $showPartial,
      storeToRedirect: store,
    });
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUserSelected({ user: userId!, checked: event.target.checked });
  };

  return (
    <StyledRowWrapper>
      <StyledGridRow key={userId}>
        <StyledGridRowContent $showPartial={$showPartial}>
          <StyledUserId>
            <UICheckbox
              color="primary"
              onChange={(event): void => changeHandler(event)}
              checked={checked}
            />
            {roles!.length > 1 && (
              <UIAccordion
                expanded={isAccordionExpanded}
                onClick={(): void => toggleAccordion(prevState => !prevState)}
              />
            )}
            <StyledSpan font="medium">{userId}</StyledSpan>
          </StyledUserId>
          <StyledSpan font="medium">
            {firstName} {lastName}
          </StyledSpan>
          <Typography font="medium">
            {roles?.[0]}{' '}
            {roles!.length > 1 && (
              <StyledRole>+ ({roles!.length - 1})</StyledRole>
            )}
          </Typography>
          {!$showPartial ? (
            <>
              <StyledSpan font="medium">{`${salesOrganization?.join(
                ', '
              )}`}</StyledSpan>
              <StyledSpan font="medium">
                {storeCode} - {streetAndHouseNumber}
              </StyledSpan>
            </>
          ) : (
            <StyledSpan font="medium">
              {jobTitle ? t(`${jobTitle}`) : ''}
            </StyledSpan>
          )}
          <StyledStatusWrapper>
            <StyledStatus status={statusUser} />
            <StyledSpan font="medium">
              {t(`${statusUser?.toLowerCase()}`)}
            </StyledSpan>
          </StyledStatusWrapper>
          {isUsersManager(user) && (
            <StyledActionsWrapper>
              {!$showPartial && (
                <UIAction
                  icon="delete"
                  label=""
                  disabled={
                    roles?.includes('Admin') && !user.roles?.includes('Admin')
                  }
                  onClick={(): void => onRemoveClick(userId!)}
                />
              )}
              <UIAction
                icon="edit"
                label={$showPartial ? t('edit') : ''}
                disabled={
                  roles?.includes('Admin') && !user.roles?.includes('Admin')
                }
                onClick={(): void => onEditClick(userId!)}
              />
            </StyledActionsWrapper>
          )}
        </StyledGridRowContent>
      </StyledGridRow>
      {isAccordionExpanded && (
        <StyledAccordionItem>
          {roles?.slice(1).map(role => (
            <Typography key={role} font="medium">
              {role}
            </Typography>
          ))}
        </StyledAccordionItem>
      )}
    </StyledRowWrapper>
  );
};

export default ProfilingUsersRow;
