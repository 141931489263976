import { FC } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { UIModal } from '@/components/ui/Modal';
import { Typography } from '@/components/ui/Typography';
import { UIBox } from '@/components/ui/Box';
import { UIButton } from '@/components/ui/Button';
import { IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { palette, theme } from '@/theme';
import { useTranslation } from 'react-i18next';

export interface ModalAttentionProps {
  open: boolean;
  title?: string;
  message: string;
  messageMaxWidth?: CSSProperties['maxWidth'];
  modalMaxWidth?: CSSProperties['maxWidth'];
  onConfirmClick: () => Promise<void> | void;
  onUnconfirmClick?: () => Promise<void> | void;
  onClose: () => void;
  disableConfirm?: boolean;
  loadingConfirm?: boolean;
}

//#region - Styled Components
const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const StyledCloseIcon = styled(IconButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  align-self: baseline;

  svg {
    fill: ${palette.colors.primary};
  }
`;

const StyledContent = styled(UIBox)`
  flex-direction: column;
  width: 100%;
`;

const StyledMessageWrapper = styled(UIBox)<{
  maxWidth?: CSSProperties['maxWidth'];
}>`
  max-width: ${({ maxWidth }): string => (maxWidth ? maxWidth : '50%')};
  margin: 18px 0 32px;

  ${theme.breakpoints.down('sm')} {
    max-width: 75%;
  }

  &&& > p {
    margin: 0;
    line-height: 22px;
  }
`;

const SyledButtonContainer = styled(UIBox)`
  align-items: center;
  gap: 43px;
  width: 60%;
  align-self: flex-end;
`;
//#endregion

const ModalAttention: FC<ModalAttentionProps> = ({
  open,
  title,
  message,
  onClose,
  onConfirmClick,
  onUnconfirmClick,
  disableConfirm,
  loadingConfirm,
  messageMaxWidth = '50%',
  modalMaxWidth,
}) => {
  const { t } = useTranslation();

  return (
    <UIModal
      $minWidth="500px"
      $minHeight="225px"
      $maxWidth={modalMaxWidth}
      open={open}
      onClose={onClose}
    >
      <StyledWrapper>
        <StyledContent>
          <Typography font="heavy">{title || t('payAttention')}</Typography>
          <StyledMessageWrapper maxWidth={messageMaxWidth}>
            <Typography as="p">{message}</Typography>
          </StyledMessageWrapper>
          <SyledButtonContainer>
            <UIButton
              label={t('no')}
              outlined
              onClick={onUnconfirmClick || onClose}
            />
            <UIButton
              label={t('yes')}
              outlined
              loading={loadingConfirm}
              onClick={onConfirmClick}
              disabled={disableConfirm}
            />
          </SyledButtonContainer>
        </StyledContent>
        <StyledCloseIcon onClick={onClose}>
          <HighlightOffIcon />
        </StyledCloseIcon>
      </StyledWrapper>
    </UIModal>
  );
};

export default ModalAttention;
