import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { StockSummarySlice } from '@/types/stockSummarySlice';
import {
  ReceiveApiError as ApiError,
  StockItemDetailList,
  StockItemList,
  StockSummaryService,
} from '@/api';
import { RootState } from '@/app/rootReducer';

const initialState: StockSummarySlice = {
  brands: {
    stockItems: [],
    totalStock: 0,
    totalTransit: 0,
  },
  stockBrandDetail: {
    stockItemDetail: [],
    totalTransit: 0,
    totalStock: 0,
  },
  stockItemsIsLoading: false,
  stockItemsHasError: undefined,
};

export const findStockItems = createAsyncThunk<
  (StockItemList & StockItemDetailList) | undefined,
  | Partial<{
      brand: string;
      upcCode: string;
      commodity: string;
      epcCode: string;
      modelCode: string;
    }>
  | undefined,
  { state: RootState; rejectValue: ApiError }
>('findStockItems', async (params, { getState, rejectWithValue }) => {
  const { store } = getState().currentStore;

  try {
    if (store?.storeCode) {
      if (params) {
        const { brand, upcCode, epcCode, modelCode } = params;

        const response = await StockSummaryService.stockFindStockItemDetails({
          storeId: store.storeCode,
          brand,
          upcCode,
          epcCode,
          modelCode,
        });

        if (response)
          return {
            ...(await StockSummaryService.stockFindStockItems({
              storeId: store.storeCode,
            })),
            ...response,
          };

        return {
          stockItems: [],
          stockItemDetail: [],
          totalStock: 0,
          totalTransit: 0,
        };
        // if (brand && upcCode) {
        // } else if (brand) {
        //   return await StockSummaryService.stockFindStockItemDetails({
        //     storeId: store.storeCode,
        //     brand,
        //   });
        // } else if (upcCode) {
        //   return await StockSummaryService.stockFindStockItemDetails({
        //     storeId: store.storeCode,
        //     upcCode,
        //   });
        // } else if (epcCode) {
        //   return await StockSummaryService.stockFindStockItemDetails({
        //     storeId: store.storeCode,
        //     epcCode,
        //   });
        // } else if (modelCode) {
        //   return await StockSummaryService.stockFindStockItemDetails({
        //     storeId: store.storeCode,
        //     modelCode,
        //   });
        // }
      }

      return await StockSummaryService.stockFindStockItems({
        storeId: store.storeCode,
      });
    }
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

const stockSummarySlice = createSlice({
  name: 'stockSummary',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(findStockItems.pending, state => {
        state.stockItemsIsLoading = true;
      })
      .addCase(findStockItems.fulfilled, (state, { payload }) => {
        state.stockItemsIsLoading = false;

        if (payload) {
          if (payload.stockItems) {
            state.brands.stockItems = payload.stockItems;
            state.brands.totalStock = payload.totalStock;
            state.brands.totalTransit = payload.totalTransit;
          }

          if (payload.stockItemDetail) {
            state.stockBrandDetail.stockItemDetail = payload.stockItemDetail;
            state.stockBrandDetail.totalStock = payload.totalStock;
            state.stockBrandDetail.totalTransit = payload.totalTransit;
          }
        }
      })
      .addCase(findStockItems.rejected, (state, { payload }) => {
        state.stockItemsHasError = payload;
      });
  },
});

export default stockSummarySlice.reducer;
