import { FC } from 'react';
import styled from 'styled-components';

import { Accordion } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { UIBox } from '@/components/ui/Box';
import { ProductImage } from '@/components/ui/ProductImage';
import { Typography } from '@/components/ui/Typography';

import { palette } from '@/theme';
import { ProductPrice } from '@/components/layout/ProductPrice';
import { ProductDetailsItem } from '@/api/receive';

interface DeliveryProductItemProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
}

//#region styled-components
export const StyledDeliveryProductItem = styled(UIBox)<{
  onClick?: React.MouseEventHandler<HTMLElement>;
  $withAccordion?: boolean;
}>`
  width: 100%;
  padding: ${({ $withAccordion }): string =>
    $withAccordion ? '24px 24px 5px 24px' : '24px'};
  flex-direction: column;
  justify-content: space-between;
  cursor: ${({ onClick }): string => (onClick ? 'pointer' : 'auto')};

  &:not(:last-of-type) {
    border-bottom: 1px solid #ededed;
  }
`;

export const StyledDeliveryProductInfo = styled(UIBox)`
  position: relative;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    left: -24px;
    height: 100%;
    width: 8px;
    background: #ededed;
    border-radius: 50px;
  }
`;

export const StyledDeliveryProductImage = styled(UIBox)`
  max-width: 80px;
  max-height: 60px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ededed;
  cursor: pointer;
`;

export const StyledDeliveryProductDescription = styled.div`
  margin-left: 16px;

  .epc-code {
    color: ${palette.colors.grey};

    ${({ theme }): string =>
      `${theme.breakpoints.down('sm')} {
            display: block;
            margin-top: 8px;
        }`}
  }

  p {
    margin: 0;

    &:last-child {
      margin-top: 8px;
      letter-spacing: 0.8px;
    }
  }
`;

export const StyledAccordion = styled(Accordion)`
  && {
    color: #fff;
    margin-top: 16px;
    display: flex;
    flex-direction: column-reverse;
    border: none;
    box-shadow: none;
    background: transparent;
  }

  &::before {
    display: none;
  }

  svg {
    height: 20px;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)<{
  expanded?: boolean;
}>`
  && {
    min-height: 16px !important;
    max-width: 110px;
    padding: 2px 16px;
    background: #005192;
    border-radius: 50px;
    align-self: center;
  }

  div {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    color: #929496;
    padding: 24px 116px;
    flex-direction: column;
  }

  & > * {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;
//#endregion

export const DeliveryProductItem: FC<
  ProductDetailsItem & DeliveryProductItemProps
> = ({
  brandDescription,
  brandCode,
  colorCode,
  commodity,
  modelCode,
  price,
  currency,
  sizeCode,
  styleName,
  upcCode,
  onClick,
  imageUrl,
}): JSX.Element => {
  return (
    <StyledDeliveryProductItem>
      <StyledDeliveryProductInfo>
        <StyledDeliveryProductImage onClick={onClick}>
          <ProductImage
            imageUrl={imageUrl}
            colorCode={colorCode}
            modelCode={modelCode}
            brandCode={brandCode}
            width={80}
            height={60}
          />
        </StyledDeliveryProductImage>
        <StyledDeliveryProductDescription>
          <Typography size="lg" font="heavy" as="h1">
            {brandDescription} - {sizeCode} {commodity} {colorCode} {styleName}
          </Typography>
          <Typography size="md" font="default" as="h1">
            {upcCode} - {modelCode} - {brandCode}
          </Typography>
        </StyledDeliveryProductDescription>
        <ProductPrice
          price={price ? String(price) : undefined}
          currency={currency}
        />
      </StyledDeliveryProductInfo>
    </StyledDeliveryProductItem>
  );
};

DeliveryProductItem.displayName = 'DeliveryProductItem';

export default DeliveryProductItem;
