import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';

import { changeAllStoreStatus } from '@/features/profiling/reasonCodeSlice';

import { Typography } from '@/components/ui/Typography';
import { PaginationList } from '../PaginationList';
import { PageLoader } from '@/components/ui/PageLoader';
import { UISwitch } from '@/components/ui/Switch';
import ProfilingReasonCodeStoresRow from './ProfilingReasonCodeStoresRow';

import { StyledTablesHeader } from './styles';
import type { ReasonCodeStoreDetails } from '@/api/receive';

interface ProfilingStoreListProps {
  paginationStores?: ReasonCodeStoreDetails[];
  listCurrentPage: number;
  enableAllStores: boolean;
  setListCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setAllStoresEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfilingReasonCodeStoresList: React.FC<ProfilingStoreListProps> = ({
  paginationStores,
  enableAllStores,
  setAllStoresEnabled,
  listCurrentPage,
  setListCurrentPage,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { fetchStoreBySalesIsLoading } = useSelector(
    state => state.profiling.reasonCode
  );

  const switchChangeHandler = (): void => {
    setAllStoresEnabled(prevState => !prevState);
    dispatch(changeAllStoreStatus(!enableAllStores));
  };

  return (
    <>
      <StyledTablesHeader>
        <Typography font="heavy">{t('salesOrganization')}</Typography>
        <Typography font="heavy">{t('storeCode')}</Typography>
        <Typography font="heavy">{t('storeAddress')}</Typography>
        <UISwitch
          checkedLabel={t('enableAll')}
          unCheckedLabel={t('disableAll')}
          checked={enableAllStores}
          onChange={(): void => switchChangeHandler()}
        />
      </StyledTablesHeader>
      {fetchStoreBySalesIsLoading ? (
        <PageLoader />
      ) : (
        paginationStores && (
          <PaginationList
            setCurrPage={setListCurrentPage}
            currPage={listCurrentPage}
            data={paginationStores}
            pageSize={25}
            renderItem={(store: ReasonCodeStoreDetails): JSX.Element => (
              <ProfilingReasonCodeStoresRow key={store.storeCode} {...store} />
            )}
          />
        )
      )}
    </>
  );
};

export default ProfilingReasonCodeStoresList;
