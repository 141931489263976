import { FC, memo } from 'react';

import {
  Button,
  ButtonProps,
  CircularProgress,
  createStyles,
  makeStyles,
} from '@material-ui/core';

export type UIButtonProps = {
  loading?: boolean;
  outlined?: boolean;
  label: string;
  icon?: JSX.Element;
} & ButtonProps;

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export const UIButton: FC<UIButtonProps> = memo(
  ({
    label,
    icon,
    outlined,
    disabled = false,
    onClick,
    loading,
    ...props
  }): JSX.Element => {
    const classes = useStyles();

    return (
      <Button
        {...props}
        variant={outlined ? 'outlined' : 'contained'}
        disabled={disabled}
        color="primary"
        onClick={onClick}
      >
        {icon}
        {label}
        {loading && (
          <CircularProgress
            size={24}
            // @ts-ignore
            className={classes.buttonProgress}
          />
        )}
      </Button>
    );
  }
);

UIButton.displayName = 'UIButton';

export default UIButton;
