import styled from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';

export const StyledPageContainer = styled(UIBox)`
  width: 100%;
  flex-direction: column;

  & > * {
    padding: 0 24px;
  }
`;

export const StyledTitle = styled(Typography)`
  margin: 24px 0 24px;
  text-align: center;
`;

export const LIWrapper = styled.div`
  margin-top: 12px;
`;
