import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useAsync } from 'react-use';
import {
  ReportBrand,
  ReportUpcs,
  StockOnHandAlignmentService,
} from '@/api/receive';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import ProductListItem from './ProductListItem';
import { UIList } from '@/components/ui/List';
import { PageLoader } from '@/components/ui/PageLoader';
import ProductList from './ProductList';
import qs from 'query-string';

import * as S from './style';
import { calc } from './utils/calc';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { AppRoutes } from '@/app/routers';
import { isAppRecapDownloader, isSapRecapDownloader } from '@/utils/user';
import { useSelector } from '@/hooks/useSelector';

const downloadFile = (file: string, name: string): void => {
  const linkSource = `data:application/csv;base64,${file}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = name;
  downloadLink.click();
};

const PageSohReport: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector(state => state.user);

  const [upcs, setUpcs] = React.useState<ReportUpcs[]>([]);
  const [movementTypes, setMovementTypes] = React.useState<{
    [key: string]: ReportBrand[];
  }>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [downloadError, setDownloadError] = React.useState<boolean>(false);
  const [fetchReportError, setFetchReportError] =
    React.useState<boolean>(false);

  const query = React.useMemo(
    () => qs.parse(location.search),
    [location.search]
  );

  useAsync(async () => {
    try {
      setLoading(true);

      const response =
        await StockOnHandAlignmentService.stockonhandReportDetails({
          stockOnHandId: query.id,
          isSapReport: query.report === 'SAP',
        });

      if (response) {
        if (response.movementsType) {
          let state: { [key: string]: ReportBrand[] } = {
            '551': [],
            '552': [],
            NO_MOVEMENT: [],
          };

          response.movementsType.forEach(({ movementType, brands }) => {
            state = {
              ...state,
              [movementType || '']: brands || [],
            };
          });

          setMovementTypes(state);
          // setMovementTypes(response.movementsType);
        }

        if (response.upcsNotPresentInSap) {
          setUpcs(response.upcsNotPresentInSap);
        }
      }

      setLoading(false);
    } catch {
      setLoading(false);
      setFetchReportError(true);
    }
  }, []);

  const totalStore = React.useMemo(() => {
    // const brands = movementTypes.flatMap(({brands}) => brands || []);
    const brands = Object.values(movementTypes).flatMap(brands => brands || []);
    const upcCodes = brands.flatMap(({ upcs }) => upcs || []);

    if (query.report === 'SAP') {
      return {
        pre: calc(upcCodes, 'preQuantitySap'),
        post: calc(upcCodes, 'postQuantitySap'),
        adj: calc(upcCodes, 'adjustment'),
      };
    } else {
      return {
        pre: calc(upcCodes, 'preQuantityApp'),
        post: calc(upcCodes, 'postQuantityApp'),
      };
    }
  }, [movementTypes, query.report]);

  const listTotal = React.useCallback((brands: ReportBrand[]) => {
    return {
      pre: calc(brands, 'preQuantitySap'),
      post: calc(brands, 'postQuantitySap'),
      adj: calc(brands, 'adjustment'),
    };
  }, []);

  const handleDownloadClick = async (): Promise<void> => {
    try {
      const response = await StockOnHandAlignmentService.stockonhandDownloadCsv(
        {
          requestBody: {
            id: query.id as string,
            type: query.report === 'SAP' ? 'SAP_SOH' : 'APP_SOH',
          },
        }
      );

      if (response) {
        downloadFile(response.base64, response.fileName);
      }
    } catch {
      setDownloadError(true);
    }
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <UIBox
            width="100%"
            p={3}
            mb="100px"
            flexDirection="column"
            alignItems="center"
          >
            <Typography font="heavy">
              {t('recapSohActivities', { report: query.report })}
            </Typography>
            <S.Grid report={query.report as string}>
              {query.report === 'APP' ? (
                <>
                  <Typography>{t('upcsCounted')}</Typography>
                  <Typography size="xs">{t('inStockBeforeCount')}</Typography>
                  <Typography size="xs">
                    {t('inStockConfirmedAndSent')}
                  </Typography>
                </>
              ) : (
                <>
                  <span></span>
                  <Typography size="xs">{t('inSapBeforeCount')}</Typography>
                  <Typography size="xs">{t('inSapAfterCount')}</Typography>
                  <Typography size="xs" color="yellow">
                    {t('adjustment')}
                  </Typography>
                </>
              )}
            </S.Grid>
            {query.report === 'SAP' && (
              <UIBox width="100%" flexDirection="column">
                <Typography size="lg">{t('upcNotPresentInSap')}</Typography>
                <UIList
                  rounded
                  bordered
                  backgrounded
                  padding={[0]}
                  margin={[24, 0, 24]}
                >
                  {/* {upcs.map(product => (
                    <ProductListItem
                      {...product}
                      ""Values
                      report={query.report as string}
                    />
                  ))} */}
                  {upcs.length > 0 ? (
                    <>
                      {upcs.map(product => (
                        <ProductListItem
                          {...product}
                          nullValues
                          report={query.report as string}
                        />
                      ))}
                    </>
                  ) : (
                    <ProductListItem
                      nullValues
                      report={query.report as string}
                    />
                  )}
                </UIList>
              </UIBox>
            )}
            {query.report === 'SAP' && (
              <UIBox mt={3} alignSelf="start">
                <Typography size="lg">{t('detailsPostedInSap')}</Typography>
              </UIBox>
            )}
            <S.Grid report={query.report as string}>
              <Typography size="xl" font="heavy">
                {t('totStore')}
              </Typography>
              <Typography size="xl" font="heavy">
                {totalStore.pre}
              </Typography>
              <Typography size="xl" font="heavy" color="green">
                {totalStore.post}
              </Typography>
              {query.report === 'SAP' && (
                <Typography size="xl" font="heavy" color="yellow">
                  {totalStore.adj || 0}
                </Typography>
              )}
            </S.Grid>
            {Object.entries(movementTypes).map(([key, brands]) => (
              <>
                {query.report === 'SAP' && (
                  <>
                    <S.Grid marginBottom="24px" report={query.report as string}>
                      <Typography size="lg">
                        {t('totStoreForMovementType', {
                          mt: t(key),
                        })}
                      </Typography>
                      <Typography size="lg">
                        {brands.length > 0 ? listTotal(brands).pre : '-'}
                      </Typography>
                      <Typography size="lg" color="green">
                        {brands.length > 0 ? listTotal(brands).post : '-'}
                      </Typography>
                      <Typography size="lg" color="yellow">
                        {brands.length > 0 ? listTotal(brands).adj : '-'}
                      </Typography>
                    </S.Grid>
                  </>
                )}
                {brands.length > 0 && (
                  <>
                    {brands.map((item, index, array) => (
                      <ProductList
                        {...item}
                        key={item.brand}
                        report={query.report as string}
                        margin={
                          array.length - 1 === index ? [0, 0, 48] : [0, 0, 24]
                        }
                      />
                    ))}
                  </>
                )}
              </>
            ))}
            {/* {movementTypes.map(mt => (
              <>
                <UIBox width="100%" mb={2}>
                  {query.report === 'SAP' && (
                    <S.Grid report={query.report as string}>
                      <Typography size="lg">
                        {t('totStoreForMovementType', {
                          mt:
                            mt.movementType === 'NO_MOVEMENT'
                              ? t(mt.movementType)
                              : mt.movementType || 'ND',
                        })}
                      </Typography>
                      <Typography size="lg">
                        {listTotal(mt.brands || []).pre}
                      </Typography>
                      <Typography size="lg" color="green">
                        {listTotal(mt.brands || []).post}
                      </Typography>
                      <Typography size="lg" color="yellow">
                        {listTotal(mt.brands || []).adj}
                      </Typography>
                    </S.Grid>
                  )}
                </UIBox>
                {mt.brands &&
                  mt.brands.map((item, index, array) => (
                    <ProductList
                      {...item}
                      key={item.brand}
                      report={query.report as string}
                      margin={
                        array.length - 1 === index ? [0, 0, 48] : [0, 0, 24]
                      }
                    />
                  ))}
              </>
            ))} */}
          </UIBox>
          <CTAContainer
            type="TWO_BUTTONS"
            onClick={handleDownloadClick}
            onBackClick={(): void => history.push(AppRoutes.SOH_ALIGNMENT)}
            disabledMainAction={
              query.report === 'SAP'
                ? !isSapRecapDownloader(user)
                : !isAppRecapDownloader(user)
            }
            mainButtonLabel={t('downloadAppRecap')}
          />
          <ErrorSnackbar
            open={downloadError}
            setIsOpen={setDownloadError}
            errorMessage={t('error.downloadReport')}
          />
          <ErrorSnackbar
            open={fetchReportError}
            setIsOpen={setFetchReportError}
            errorMessage={t('error.fetchSohReport')}
          />
        </>
      )}
    </>
  );
};

export default PageSohReport;
