import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HighlightOff } from '@material-ui/icons';
import { UIModal, UIModalProps } from '@/components/ui/Modal';
import { Typography } from '@/components/ui/Typography';

import { ProductItemToReprint } from '@/api';

import { PrintMode } from '@/types/enum';
import { UIBox } from '@/components/ui/Box';
import { ProductPrice } from '../ProductPrice';
import {
  StyledContainer,
  StyledSubContainer,
  StyledClose,
  StyledProductImage,
  StyledPrintButton,
} from './style';

//#region types
export interface ModalPrintProps extends Omit<UIModalProps, 'children'> {
  product: ProductItemToReprint;
  buttonDisabled?: boolean;
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  mode?: PrintMode;
}
//#endregion

export const ModalPrint: FC<ModalPrintProps> = ({
  open,
  onClose,
  product,
  mode,
  onButtonClick,
  buttonDisabled,
  ...rest
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <UIModal open={open} onClose={onClose} {...rest}>
      <StyledContainer>
        <StyledClose onClick={(): void => onClose?.({}, 'backdropClick')}>
          <HighlightOff color={'primary'} />
        </StyledClose>
        <StyledSubContainer>
          <StyledProductImage
            imageUrl={product.imageUrl}
            colorCode={product.colorCode}
            modelCode={product.modelCode}
            brandCode={product.brandDescription}
            width={240}
            height={180}
          />

          <Typography size="lg" font="heavy" as="h1">
            {[product?.brandDescription, product?.styleNameDescription]
              .filter(info => info)
              .join(' - ')}
          </Typography>
          <Typography size="md" font="book" as="h1">
            {[
              product?.upcCode,
              product?.modelCode,
              product?.commodityDescription,
            ]
              .filter(info => info)
              .join(' - ')}
          </Typography>

          <UIBox alignItems="center" flexDirection="column">
            {product.retailPrice && (
              <UIBox alignItems="center" mt={1}>
                <Typography font="medium" color="grey">
                  {t('retailPrice')}:
                </Typography>
                <UIBox ml={1}>
                  <ProductPrice
                    price={product.retailPrice}
                    currency={product.currency}
                  />
                </UIBox>
              </UIBox>
            )}
            {product.airportPrice && (
              <UIBox alignItems="center" mt={1}>
                <Typography font="medium" color="grey">
                  {t('airportPrice')}:
                </Typography>
                <UIBox ml={1}>
                  <ProductPrice
                    price={product.airportPrice}
                    currency={product.currency}
                  />
                </UIBox>
              </UIBox>
            )}
            {product.outletPrice && (
              <UIBox alignItems="center" mt={1}>
                <Typography font="medium" color="grey">
                  {t('outletPrice')}:
                </Typography>
                <UIBox ml={1}>
                  <ProductPrice
                    price={product.outletPrice}
                    currency={product.currency}
                  />
                </UIBox>
              </UIBox>
            )}
            {product.percentage && (
              <UIBox alignItems="center" mt={4} mb={2}>
                <Typography font="medium" size="xs" color="grey">
                  {t('discount')}:
                </Typography>
                <UIBox ml={1}>
                  <Typography font="medium" size="xs">
                    {product.percentage}%{' '}
                    {product.description ? `(${product.description})` : null}
                  </Typography>
                </UIBox>
              </UIBox>
            )}
          </UIBox>

          <StyledPrintButton
            label={
              mode === PrintMode.Update ? t('updateTag') : t('printNewTag')
            }
            disabled={buttonDisabled}
            onClick={onButtonClick}
          />
        </StyledSubContainer>
      </StyledContainer>
    </UIModal>
  );
};

ModalPrint.displayName = 'ModalPrint';

export default ModalPrint;
