import { ProfilingService } from '@/api/receive/services/ProfilingService';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/app/rootReducer';

import type { BFFApiError as ApiError } from '@/api';
import { RolesInitialState } from '@/types/profiling';
import { PermissionObject, PermissionsFullList, RoleList } from '@/api/receive';

const initialState: RolesInitialState = {
  roles: [],
  currentRole: '',
  rolesAreLoading: false,
  rolesError: undefined,
  permissions: [],
  permissionsAreLoading: false,
  permissionsError: undefined,
  isUpdatingRole: false,
  isUpdatedRole: false,
  updateRoleError: undefined,
};

export const findAllRoles = createAsyncThunk<
  RoleList,
  void,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>('user/findAllRoles', async (_, { rejectWithValue }) => {
  try {
    return await ProfilingService.userFindAllRoles();
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const createRole = createAsyncThunk<
  void,
  Parameters<typeof ProfilingService.userCreateRole>[number]['requestBody'],
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('user/createRole', async (requestBody, { rejectWithValue }) => {
  try {
    return await ProfilingService.userCreateRole({
      requestBody,
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const updateRole = createAsyncThunk<
  void,
  Parameters<typeof ProfilingService.userUpdateRole>[number]['requestBody'],
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('user/updateRole', async (requestBody, { rejectWithValue }) => {
  try {
    return await ProfilingService.userUpdateRole({
      requestBody,
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const findAllPermissions = createAsyncThunk<
  PermissionsFullList | undefined,
  Parameters<
    typeof ProfilingService.userFindAllPermissions
  >[number]['roleName'],
  {
    state: RootState;
    rejectValue: ApiError;
  }
>('user/findAllPermissions', async (roleName, { rejectWithValue }) => {
  try {
    return await ProfilingService.userFindAllPermissions({ roleName });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    update(state, action: PayloadAction<RolesInitialState>) {
      return { ...state, ...action.payload };
    },
    enableAllMainPermissions: state => {
      state.permissions.forEach(privilege => {
        if (privilege.mainPermission) {
          privilege.enabled = true;
        }
      });
    },
    disableAllMainPermissions: state => {
      state.permissions.forEach(privilege => {
        if (privilege.mainPermission) {
          privilege.enabled = false;
        }
      });
    },
    disableAllPermissions: state => {
      state.permissions.forEach(privilege => {
        privilege.enabled = false;
      });
    },
    setRole: (state, { payload }: PayloadAction<string>) => {
      state.currentRole = payload;
    },
    setPermissionValue: (state, { payload }: PayloadAction<string>) => {
      const currentPermission = state.permissions.find(
        (permission: PermissionObject) => permission.permissionName === payload
      );
      if (currentPermission) {
        currentPermission.enabled = !currentPermission.enabled;
      }
    },
    initRolesState: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(findAllRoles.pending, state => {
        state.rolesAreLoading = true;
        state.rolesError = undefined;
      })
      .addCase(findAllRoles.fulfilled, (state, { payload }) => {
        state.rolesAreLoading = false;

        if (payload.roles) {
          state.roles = payload.roles;
        }
      })
      .addCase(findAllRoles.rejected, (state, { payload }) => {
        state.rolesAreLoading = false;
        state.rolesError = payload;
      })
      .addCase(findAllPermissions.pending, state => {
        state.permissionsAreLoading = true;
        state.permissionsError = undefined;
      })
      .addCase(findAllPermissions.fulfilled, (state, { payload }) => {
        state.permissionsAreLoading = false;

        if (payload?.permission) {
          state.permissions = payload.permission;
        }
      })
      .addCase(findAllPermissions.rejected, (state, { payload }) => {
        state.permissionsAreLoading = false;
        state.permissionsError = payload;
      })
      .addCase(createRole.pending, state => {
        state.isUpdatingRole = true;
        state.isUpdatedRole = false;
        state.updateRoleError = undefined;
      })
      .addCase(createRole.fulfilled, state => {
        state.isUpdatingRole = false;
        state.isUpdatedRole = true;
        state.updateRoleError = undefined;
      })
      .addCase(createRole.rejected, (state, { payload }) => {
        state.isUpdatingRole = false;
        state.isUpdatedRole = false;
        state.updateRoleError = payload as ApiError;
      })
      .addCase(updateRole.pending, state => {
        state.isUpdatingRole = true;
        state.isUpdatedRole = false;
        state.updateRoleError = undefined;
      })
      .addCase(updateRole.fulfilled, state => {
        state.isUpdatingRole = false;
        state.isUpdatedRole = true;
        state.updateRoleError = undefined;
      })
      .addCase(updateRole.rejected, (state, { payload }) => {
        state.isUpdatingRole = false;
        state.isUpdatedRole = false;
        state.updateRoleError = payload as ApiError;
      });
  },
});

export const {
  initRolesState,
  enableAllMainPermissions,
  disableAllMainPermissions,
  disableAllPermissions,
  setPermissionValue,
  setRole,
} = roleSlice.actions;
export default roleSlice.reducer;
