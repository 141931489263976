/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfirmStoreToStoreEncoded } from '../models/ConfirmStoreToStoreEncoded';
import type { ConfirmStoreToStoreResponse } from '../models/ConfirmStoreToStoreResponse';
import type { ConfirmTransfer } from '../models/ConfirmTransfer';
import type { LastStoreTransferList } from '../models/LastStoreTransferList';
import type { PdfFile } from '../models/PdfFile';
import type { ProductTransferList } from '../models/ProductTransferList';
import type { SearchStoreList } from '../models/SearchStoreList';
import type { StoreToTrasnsferList } from '../models/StoreToTrasnsferList';
import type { TransferEpc } from '../models/TransferEpc';
import { request as __request } from '../core/request';

export class StoreToStoreService {
  /**
   * search StoreList By Filter (only for settings user)
   * Find only store without relationship between distribution channel and sale organization. Use query param filtered set to TRUE to found only Active Stores for User logged
   * @returns SearchStoreList OK
   * @throws ApiError
   */
  public static async storeSearchStoreListByFilter({
    storeFilter,
    filtered,
  }: {
    storeFilter: any;
    filtered?: any;
  }): Promise<SearchStoreList> {
    const result = await __request({
      method: 'GET',
      path: `/store/searchStoreListByFilter`,
      query: {
        storeFilter: storeFilter,
        filtered: filtered,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Load data of stores
   * Service used to obtain the list of the last store tradfers made by a store. If the status of a transfer store is Ready to transfer, the report download button must not be clickable. While, if the status is Transfer completed, the button must be clickable for that specific orderId call the /store/createStoreToStoreReport service
   * @returns LastStoreTransferList OK
   * @throws ApiError
   */
  public static async storeFindLastStoreTransfer({
    storeCode,
  }: {
    /** filter for itemCode **/
    storeCode: any;
  }): Promise<LastStoreTransferList> {
    const result = await __request({
      method: 'GET',
      path: `/store/findLastStoreTransfer`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * find Store To Transfer
   * find stores to trasfer
   * @returns StoreToTrasnsferList OK
   * @throws ApiError
   */
  public static async storeFindStoreToTransfer({
    storeCode,
  }: {
    /** filter for itemCode **/
    storeCode: any;
  }): Promise<StoreToTrasnsferList> {
    const result = await __request({
      method: 'GET',
      path: `/store/findStoreToTransfer`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * search item to transfer
   * search item to transfer by date (yyyymmdd), store and epcs
   * @returns ProductTransferList OK
   * @throws ApiError
   */
  public static async storeSearchItemToTransfer({
    requestBody,
  }: {
    requestBody: TransferEpc;
  }): Promise<ProductTransferList> {
    const result = await __request({
      method: 'POST',
      path: `/store/searchItemToTransfer`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * SA wants to CONFIRM a TOC
   * Confirm TOC
   * @returns ConfirmStoreToStoreResponse Transfer Order Confirmed
   * @throws ApiError
   */
  public static async storeConfirmStoreToStore({
    requestBody,
  }: {
    requestBody: ConfirmTransfer;
  }): Promise<ConfirmStoreToStoreResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/store/confirmStoreToStore`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * SA wants to CONFIRM a TOC
   * Confirm TOC
   * @returns ConfirmStoreToStoreResponse Transfer Order Confirmed
   * @throws ApiError
   */
  public static async storeConfirmStoreToStoreEncoded({
    requestBody,
  }: {
    requestBody: ConfirmStoreToStoreEncoded;
  }): Promise<ConfirmStoreToStoreResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/store/confirmStoreToStoreEncoded`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Store to store service for create report
   * Create pdf report
   * @returns PdfFile OK
   * @throws ApiError
   */
  public static async storeCreateStoreToStoreReport({
    orderId,
  }: {
    /** order id of store to store operation **/
    orderId: any;
  }): Promise<PdfFile> {
    const result = await __request({
      method: 'GET',
      path: `/store/createStoreToStoreReport`,
      query: {
        orderId: orderId,
      },
      errors: {
        400: `Bad request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
