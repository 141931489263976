import type { CycleDetailsItems as Product } from '@/api';
import type { NotFoundCycleCount } from '@/api/receive';
import { EpcStatusValues, EPC_STATUS } from '@/types/enum';

/**
 * Sort products by model code & brand code.
 *
 * @param list
 * @returns
 */
export const sortProductList = (list: Product[]): Product[] => {
  return list.sort(
    (a, b) =>
      a.brandCode?.localeCompare(b.brandCode!) ||
      a.modelCode?.localeCompare(b.modelCode!)
  );
};

/**
 * Update the cycle count state with the results of the filters selected by the user.
 *
 * @param products - The results of the filters.
 * @param status
 * @returns
 */
export const filterProductsByStatus = (
  products: Product[],
  status: string
): Product[] =>
  products
    .map(product => ({
      ...product,
      epcCodes: product.epcCodes.filter(
        ({ epcStatus }) => epcStatus === status
      ),
    }))
    .filter(({ epcCodes }) => epcCodes.length > 0);

/**
 *	Update the products in the cycle count state with new epc codes scanned by the user.
 *
 * @param products - Current products in the cycle count state.
 * @param tags - Epc codes scanned by the user.
 * @returns
 */
export const addScannedEpcs = (
  products: Product[],
  tags: string[]
): Product[] =>
  products
    .map(product => ({
      ...product,
      epcCodes: product.epcCodes.filter(({ epcCode }) =>
        tags.includes(epcCode)
      ),
    }))
    .filter(({ epcCodes }) => epcCodes.length > 0);

/**
 * Remove from the cycle count state the epc codes scanned by the user.
 *
 * @param products - Current products it the cycle count state.
 * @param tags - Epc codes scanned by the user.
 * @param status - The current status of the epc codes.
 * @returns
 */
export const removeScannedEpcs = <T extends Product | NotFoundCycleCount>(
  products: T[],
  tags: string[],
  status: EpcStatusValues | 'Reasoned'
): T[] => {
  const removeEpcs = (product: Product): Product => ({
    ...product,
    epcCodes: product.epcCodes.filter(({ epcCode }) => !tags.includes(epcCode)),
  });

  const removeReasonedEpcs = (
    product: NotFoundCycleCount
  ): NotFoundCycleCount => ({
    ...product,
    epcNotFound: product.epcNotFound.filter(
      ({ epcNotFoundCode }) => !tags.includes(epcNotFoundCode)
    ),
  });

  switch (status) {
    case EPC_STATUS.IN_STOCK:
    case EPC_STATUS.MISSING:
    case EPC_STATUS.RECALLED:
    case EPC_STATUS.DAMAGED:
    case EPC_STATUS.IN_TRANSIT:
    case EPC_STATUS.QUALITY_INSPECTION:
    case EPC_STATUS.CHECKOUT_ERROR:
      return products
        .map(product => removeEpcs(product as Product))
        .filter(({ epcCodes }) => epcCodes.length > 0) as T[];

    case 'Reasoned':
      return products
        .map(product => removeReasonedEpcs(product as NotFoundCycleCount))
        .filter(({ epcNotFound }) => epcNotFound.length > 0) as T[];

    default:
      return [];
  }
};

/**
 * Update the current cycle count state with new epc codes.
 *
 * @param products - Updated products with new scanned epc codes.
 * @param state - The current state of the cycle count.
 */
export const updateState = (products: Product[], state: Product[]): void => {
  products.forEach(product => {
    const curIndex = state.findIndex(
      ({ upcCode }) => upcCode === product.upcCode
    );

    if (curIndex > -1) {
      state[curIndex].epcCodes = state[curIndex].epcCodes.concat(
        product.epcCodes
      );
    } else {
      state.push(product);
    }
  });
};
