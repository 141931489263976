import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';
import styled from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { ModalProductDetails } from '@/components/layout/ModalProductDetails';
import { ProductPrice } from '@/components/layout/ProductPrice';
import { ProductImage } from '@/components/layout/ProductImage';
import { ProductAccordion } from '@/components/layout/ProductAccordion';
import { Typography } from '@/components/ui/Typography';
import { UICheckbox } from '@/components/ui/Checkbox';
import { ProductAccordionItem } from '@/components/layout/ProdocutAccordionItem';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import {
  selectAllEpcByUpc,
  deselectAllEpcByUpc,
  selectEpc,
  deselectEpc,
} from '@/features/storeDamages/storeDamagesSlice';
import { colors } from '@/theme';
import type { SearchItemsForCreateDamagesResponse } from '@/api/receive';
import { Chip } from '@material-ui/core';

interface DamageListItemProps extends SearchItemsForCreateDamagesResponse {}

//#region - Styled Components
const StyledProductItemWrapper = styled(UIBox)`
  flex-direction: column;
  padding: 20px 18px;
  width: 100%;
  background: #fbfbfb;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.lightGrey};
  }
`;

const StyledProductGrid = styled(UIBox)`
  display: grid;
  grid-template-columns: max-content auto max-content max-content;
  align-items: center;
  gap: 16px;
`;

const StyledUserActions = styled(UIBox)`
  align-items: center;
`;

const StyledContentWrapper = styled(UIBox)`
  flex-direction: column;

  && > * {
    line-height: 19px;
  }
`;

const StyledPriceWrapper = styled(UIBox)`
  flex-direction: column;
  align-items: flex-end;
  justify-self: flex-end;
`;

const ProcessChip = styled(Chip)<{ $process?: string }>`
  border-color: ${({ $process }): string =>
    $process === 'CRDA' ? '#E20404' : '#005192'};
  color: #707173;
`;

const StyledPrice = styled(UIBox)`
  flex-direction: column;

  & > * {
    align-self: flex-end;
  }
`;

const StyledAccortionItemContent = styled(UIBox)`
  align-items: center;
  justify-content: space-between;
`;

const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)`
  margin-right: 8px;
`;
//#endregion

const DamageListitem: React.FC<DamageListItemProps> = ({
  upcCode,
  epcCodes,
  brandDescription,
  commodity,
  colorCode,
  styleName,
  modelCode,
  price,
  currency,
  imageUrl,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [$isAccordionExpanded, toggleAccordion] = useToggle(false);
  const [isProductModalVisible, setProductModalVisibility] =
    useState<boolean>(false);

  const { selectedEpcs } = useSelector(state => state.storeDamages);

  const epcs = epcCodes.map(({ epcCode }) => epcCode);

  const selectedEpcsInGroup = useMemo(
    () => selectedEpcs.filter(epc => epcs.includes(epc)),
    [epcs, selectedEpcs]
  );

  const upcClickHandler = (): void => {
    if (selectedEpcsInGroup.length === epcs.length) {
      dispatch(deselectAllEpcByUpc(epcs));
    } else {
      dispatch(selectAllEpcByUpc(epcs));
    }
  };

  const epcClickHandler = (epcCode: string): void => {
    if (selectedEpcs.includes(epcCode)) {
      dispatch(deselectEpc(epcCode));
    } else {
      dispatch(selectEpc(epcCode));
    }
  };

  return (
    <>
      <ModalProductDetails
        productCode={upcCode}
        open={isProductModalVisible}
        onClose={(): void => setProductModalVisibility(false)}
      />
      <StyledProductItemWrapper>
        <StyledProductGrid>
          <StyledUserActions>
            <ProductImage
              checkboxIsVisible
              $v2={epcCodes.length > 1}
              imageUrl={imageUrl}
              upc={upcCode}
              checked={selectedEpcsInGroup.length === epcs.length}
              brandName={brandDescription}
              onClick={(): void => setProductModalVisibility(true)}
              onCheckboxClick={upcClickHandler}
              $isAccordionExpanded={$isAccordionExpanded}
              onClickAccordion={toggleAccordion}
            />
          </StyledUserActions>
          <StyledContentWrapper>
            <Typography font="heavy" margin="0 0 4px">
              {brandDescription} - {commodity} {colorCode} {styleName}
            </Typography>
            <Typography font="light">
              {upcCode} - {modelCode} - {commodity}{' '}
              {epcCodes.length === 1 && (
                <Typography color="grey">- {epcCodes[0].epcCode}</Typography>
              )}
            </Typography>
          </StyledContentWrapper>
          <StyledPriceWrapper>
            <StyledPrice>
              <ProductPrice
                currency={currency}
                price={price ? String(price) : undefined}
              />
              <Typography size="xs" color="grey">
                {t('reprice.items', { count: epcCodes.length })}
              </Typography>
              <UIBox justifyContent="space-between" width={300}>
                {epcCodes.length === 1 && epcCodes[0].process && (
                  <ProcessChip
                    size="small"
                    label={t(epcCodes[0].process)}
                    variant="outlined"
                    $process={epcCodes[0].process}
                  />
                )}
                {epcCodes.length === 1 && epcCodes[0].movementReason && (
                  <UIBox alignItems="center">
                    <StyledCheckCircleOutlineIcon htmlColor="#417505" />
                    <Typography size="xs" color="grey">
                      {epcCodes[0].movementReason}
                    </Typography>
                  </UIBox>
                )}
              </UIBox>
            </StyledPrice>
          </StyledPriceWrapper>
        </StyledProductGrid>
        {epcCodes.length > 1 && (
          <ProductAccordion
            $v2
            items=""
            padding="11px 11px 0 51px"
            expanded={$isAccordionExpanded}
            toggleAccordion={toggleAccordion}
          >
            {epcCodes.map(({ epcCode, movementReason, process }) => (
              <StyledAccortionItemContent key={epcCode}>
                <UIBox alignItems="center">
                  <UICheckbox
                    checked={selectedEpcs.includes(epcCode)}
                    onClick={(): void => epcClickHandler(epcCode)}
                  />
                  <ProductAccordionItem epcCode={epcCode} />
                </UIBox>
                <UIBox
                  alignItems="center"
                  justifyContent="space-between"
                  width={300}
                >
                  {process && (
                    <ProcessChip
                      size="small"
                      label={t(process)}
                      variant="outlined"
                      $process={process}
                    />
                  )}
                  {movementReason && (
                    <UIBox alignItems="center">
                      <StyledCheckCircleOutlineIcon htmlColor="#417505" />
                      <Typography size="xs" color="grey">
                        {movementReason}
                      </Typography>
                    </UIBox>
                  )}
                </UIBox>
              </StyledAccortionItemContent>
            ))}
          </ProductAccordion>
        )}
      </StyledProductItemWrapper>
    </>
  );
};

export default DamageListitem;
