import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCycleCountContext } from '@/context/cycleCount';

import { UIBox } from '@/components/ui/Box';
import { UICheckbox } from '@/components/ui/Checkbox';
import { PaginationList } from '@/components/layout/PaginationList';
import { UIList } from '@/components/ui/List';
import { Typography } from '@/components/ui/Typography';
import MissingProductListItem from '../MissingProductListItem';

import type { CycleDetailsItems } from '@/api';
import type { MissingProductListProps } from './type';
import displayProductHeader from '@/utils/displayProductHeader';
import { Product } from '@/types/enum';

const MissingProductList: React.FC<MissingProductListProps> = ({
  products,
  selectedEpcs,
  setSelectedEpcs,
}) => {
  const { t } = useTranslation();
  const { setMissingItemsBrands } = useCycleCountContext();

  const epcCodes = useMemo(
    () => products.flatMap(({ epcCodes }) => epcCodes),
    [products]
  );

  const brandCodes = useMemo(
    () => products.map(({ brandDescription }) => brandDescription),
    [products]
  );

  const isListChecked = useMemo(
    () => epcCodes.every(({ epcCode }) => selectedEpcs.includes(epcCode)),
    [epcCodes, selectedEpcs]
  );

  const handleCheckboxClick = (): void => {
    const epcs = epcCodes.map(({ epcCode }) => epcCode);

    if (isListChecked) {
      setSelectedEpcs(e => e.filter(e => !epcs.includes(e)));
      setMissingItemsBrands(b => b.filter(e => !brandCodes.includes(e)));
    } else {
      setSelectedEpcs(e => [...e, ...epcs]);
      setMissingItemsBrands(b => [...new Set([...b, ...brandCodes])]);
    }
  };

  return (
    <UIBox width="100%" mt={3} flexDirection="column">
      <UIBox ml={-1.5} justifyContent="space-between">
        <UIBox alignItems="center">
          <UICheckbox checked={isListChecked} onClick={handleCheckboxClick} />
          <Typography size="lg" font="heavy" as="h1">
            {products[0].brandDescription}
          </Typography>
        </UIBox>
        <UIBox alignItems="center">
          <Typography size="lg">{t('page.missingitems')}:</Typography>
          <Typography size="lg" margin="0 0 0 4px" font="heavy">
            {epcCodes.length}
          </Typography>
        </UIBox>
      </UIBox>
      <UIBox width="100%">
        <UIList padding={[12, 0]} rounded backgrounded shadowed>
          <PaginationList
            data={products}
            pageSize={25}
            renderItem={(
              productItem: NonNullable<CycleDetailsItems>,
              index: number
            ): JSX.Element => (
              <React.Fragment key={`${index}-${productItem.upcCode}`}>
                {displayProductHeader(products, Product.NOT_FOUND, index) && (
                  <UIBox width="100%">
                    <UIBox mt={2} alignItems="center">
                      <Typography font="heavy">{t('modelCode')}:</Typography>
                      <Typography margin="0 0 0 8px">
                        {productItem.modelCode}
                      </Typography>
                    </UIBox>
                  </UIBox>
                )}
                <MissingProductListItem {...productItem} key={index} />
              </React.Fragment>
            )}
          />
        </UIList>
      </UIBox>
    </UIBox>
  );
};

export default MissingProductList;
