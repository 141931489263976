import { UIBox } from '@/components/ui/Box';
import styled from 'styled-components';

export const UIListWrapper = styled(UIBox)`
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  z-index: 0;
`;

export const Actions = styled(UIBox)`
  margin-left: auto;
  align-items: center;

  & > * {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`;
