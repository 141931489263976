import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from '@/components/layout/Tabs';
import { UIBox } from '@/components/ui/Box';
import { useAsync } from 'react-use';
import {
  ApiError,
  PosException,
  PosService,
  SearchPosExceptionResponse,
} from '@/api/receive';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { PageLoader } from '@/components/ui/PageLoader';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';
import { UIButton, UIButtonWithIcon } from '@/components/ui/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import { UISelectAll } from '@/components/ui/SelectAll';
import { UIAction } from '@/components/ui/Action';
import ExceptionsList from './ExceptionsList';
import { Exceptions, SaveRequestBody, Tabs as TabsType } from './types';
import EditExceptionModal from './EditExceptionModal';
import { FormInputs } from '@/types/hookFormInput';
import { FieldNames, FormData } from './EditExceptionModal/types';
import { ModalDataSavedError as Modal } from '@/components/layout/ModalDataSaved';
import { Typography } from '@/components/ui/Typography';
import { UIAutoCompleteStores } from '@/components/ui/AutoComplete';
import { StoreWithPrinters } from '@/types/store';
import { ChipList } from '@/components/layout/ChipList';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { Search as SearchIcon } from '@material-ui/icons';
import styled from 'styled-components';
import { Chip } from '@/hooks/useChips';

const StyledUIBox = styled(UIBox)`
  .MuiAutocomplete-popupIndicatorOpen {
    transform: unset;
  }
  .MuiAutocomplete-popupIndicator {
    color: #005192;
  }
`;

const fields: FieldNames = [
  'storeCode',
  'upcCode',
  'tagCode',
  'transactionID',
  'dateOfTransaction',
];

const requiredFields: FieldNames = [
  'storeCode',
  'upcCode',
  'transactionID',
  'dateOfTransaction',
];

const createExceptionsData = (
  tabs: TabsType,
  response?: SearchPosExceptionResponse
): Exceptions => {
  let exception: Exceptions = {};

  tabs.forEach(({ key }, index) => {
    if (key !== 'salesExceptionsExtraction') {
      exception = {
        ...exception,
        [index]: {
          selectedIds: [],
          data: response ? response[key] || [] : [],
        },
      };
    }
  });

  return exception;
};

const PagePosExceptions: React.FC = () => {
  const { t } = useTranslation();

  const tabs: TabsType = React.useMemo(
    () => [
      {
        label: t('salesExceptions'),
        key: 'salesException',
      },
      {
        label: t('returnsExceptions'),
        key: 'returnsException',
      },
      {
        label: t('voidSalesExceptions'),
        key: 'voidSalesException',
      },
      {
        label: t('salesExceptionsExtraction'),
        key: 'salesExceptionsExtraction',
      },
    ],
    [t]
  );

  const [loading, setLoading] = React.useState<boolean>(false);
  const [removeError, setRemoveError] = React.useState<boolean>(false);
  const [saveError, setSaveError] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [storeCodeFilter, setStoreCodeFilter] = React.useState<string>();
  const [saveRequestBody, setSaveRequestBody] =
    React.useState<SaveRequestBody>();
  const [formDefaultValues, setFormDefaultValues] =
    React.useState<Partial<FormInputs>>();

  const [editModalIsVisible, setEditModalVisibility] =
    React.useState<boolean>(false);
  const [saveErrorModalIsVisible, setSaveErrorModalVisibility] =
    React.useState<boolean>(false);
  const [getExceptionsError, setGetExceptionsError] =
    React.useState<boolean>(false);

  const [exceptions, setExceptions] = React.useState<Exceptions>({});
  const [filteredExceptions, setFilteredExceptions] =
    React.useState<Exceptions>({});
  const [selectedStores, setSelectedStores] = React.useState<
    StoreWithPrinters[]
  >([]);
  const [errorDownloadCSV, setErrorDownloadCSV] =
    React.useState<boolean>(false);
  const [downoloadLoader, setDownoloadLoader] = React.useState<boolean>(false);

  const currentExceptions = filteredExceptions[selectedTab] || {
    data: [],
    selectedIds: [],
  };

  const fetchPosExceptions = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await PosService.posSearchPosException();
      setExceptions(createExceptionsData(tabs, response || []));
      setFilteredExceptions(createExceptionsData(tabs, response || []));
      setLoading(false);
    } catch {
      setLoading(false);
      setGetExceptionsError(true);
    }
  };

  useAsync(async () => {
    await fetchPosExceptions();
  }, []);

  React.useEffect(() => {
    setExceptions(createExceptionsData(tabs));
    setFilteredExceptions(createExceptionsData(tabs));
  }, [tabs]);

  React.useEffect(() => {
    if (storeCodeFilter) {
      setFilteredExceptions(prevState => ({
        ...prevState,
        [selectedTab]: {
          ...prevState[selectedTab],
          data: exceptions[selectedTab].data.filter(({ storeCode }) =>
            storeCode?.toLowerCase().includes(storeCodeFilter.toLowerCase())
          ),
        },
      }));
    } else {
      setFilteredExceptions(exceptions);
    }
  }, [exceptions, selectedTab, storeCodeFilter]);

  const allIdsSelected = React.useMemo(() => {
    if (currentExceptions?.data.length === 0) return false;

    return (
      currentExceptions?.data.length === currentExceptions?.selectedIds.length
    );
  }, [currentExceptions?.data.length, currentExceptions?.selectedIds.length]);

  const handleCheckboxClick = (id: string): void => {
    const currentExceptionsIds = currentExceptions.selectedIds;

    if (currentExceptionsIds.includes(id)) {
      const deselectException = (state: Exceptions): Exceptions => ({
        ...state,
        [selectedTab]: {
          ...state[selectedTab],
          selectedIds: state[selectedTab].selectedIds.filter(
            selectedId => selectedId !== id
          ),
        },
      });

      setExceptions(deselectException);
      setFilteredExceptions(deselectException);
    } else {
      const selectException = (state: Exceptions): Exceptions => ({
        ...state,
        [selectedTab]: {
          ...state[selectedTab],
          selectedIds: [...state[selectedTab].selectedIds, id],
        },
      });

      setExceptions(selectException);
      setFilteredExceptions(selectException);
    }
  };

  const handleSelectAll = (): void => {
    const selectExceptions = (state: Exceptions): Exceptions => ({
      ...state,
      [selectedTab]: {
        ...state[selectedTab],
        selectedIds: currentExceptions.data.map(({ id }) => id || ''),
      },
    });

    setExceptions(selectExceptions);
    setFilteredExceptions(selectExceptions);
  };

  const handleDeselectAll = (): void => {
    const deselectExceptions = (state: Exceptions): Exceptions => ({
      ...state,
      [selectedTab]: {
        ...state[selectedTab],
        selectedIds: [],
      },
    });

    setExceptions(deselectExceptions);
    setFilteredExceptions(deselectExceptions);
  };

  const handleRemoveClick = async (): Promise<void> => {
    try {
      const ids = currentExceptions.selectedIds;

      await PosService.posRemovePosSyntacticException({
        requestBody: { ids },
      });

      await fetchPosExceptions();
    } catch (e) {
      const error = e as ApiError;

      if (error.status === 406) {
        setSaveErrorModalVisibility(true);
      } else {
        setRemoveError(true);
      }
    }
  };

  const handleEditItemClick = (exception: PosException): void => {
    setFormDefaultValues({
      storeCode: exception.storeCode || '',
      upcCode: exception.upcCode || '',
      tagCode: exception.tagCode || '',
      transactionID: exception.transactionId || '',
      dateOfTransaction: exception.dateOfTransaction || '',
      reasonCode: exception.reasonCode,
    });

    setSaveRequestBody({
      id: exception.id || '',
      reasonCode:
        selectedTab === 2 ? undefined : exception.reasonCode || undefined,
      isVoidSales: selectedTab === 2 ? true : exception.isVoidSales || false,
    });

    setEditModalVisibility(true);
  };

  const handleSaveClick = async (formData: FormData): Promise<void> => {
    try {
      await PosService.posSavePosException({
        requestBody: [
          {
            ...saveRequestBody,
            storeCode: formData.storeCode || '',
            upcCode: formData.upcCode || '',
            tagCode: formData.tagCode || undefined,
            transactionId: formData.transactionID || '',
            dateOfTransaction: formData.dateOfTransaction || '',
            reasonCode: formData.reasonCode,
          },
        ],
      });

      setEditModalVisibility(false);
      setSaveRequestBody(undefined);
      setFormDefaultValues(undefined);

      await fetchPosExceptions();
    } catch (e) {
      const error = e as ApiError;

      if (error.status === 406) {
        setSaveErrorModalVisibility(true);
      } else {
        setSaveError(true);
      }
    }
  };

  const handleEditModalClose = (): void => {
    setEditModalVisibility(false);
    setSaveRequestBody(undefined);
    setFormDefaultValues(undefined);
  };

  const handleSaveErrorModalClose = async (): Promise<void> => {
    setSaveErrorModalVisibility(false);
    setEditModalVisibility(false);
    setSaveRequestBody(undefined);
    setFormDefaultValues(undefined);
    await fetchPosExceptions();
  };

  const selectStoreCodeFilter = (store: StoreWithPrinters): void => {
    if (
      selectedStores.find(({ storeCode }) => store.storeCode === storeCode) ===
      undefined
    ) {
      setSelectedStores(prev => [...prev, store]);
    }
  };

  const onDeleteSelectedStore = (chip: Chip): void => {
    setSelectedStores(
      selectedStores.filter(({ storeCode }) => storeCode !== chip.key)
    );
  };

  const downloadCsvHandler = async (): Promise<void> => {
    setDownoloadLoader(true);
    try {
      const { base64, fileName } = await PosService.posRetrieveExceptionOnFile({
        requestBody: {
          typeFormat: 'csv',
          storeCodes: selectedStores.map(({ storeCode }) => storeCode || ''),
        },
      });

      const linkSource = `data:application/csv;base64,${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch {
      setErrorDownloadCSV(true);
    }
    setDownoloadLoader(false);
  };

  const setRequiredFields = (tab: number): FieldNames => {
    switch (tab) {
      case 0:
        return [...requiredFields, 'tagCode'];
      case 1:
        return [...requiredFields, 'reasonCode'];
    }
    return requiredFields;
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Modal
            iconType="ERROR"
            $minWidth="500px"
            $minHeight="250px"
            title={t('payAttention')}
            message={t('exceptionManagedByAnotherUser')}
            open={saveErrorModalIsVisible}
            onClose={handleSaveErrorModalClose}
          >
            <UIBox mt={4} alignItems="center" width="100%" alignSelf="center">
              <UIButton
                outlined
                label={t('ok')}
                onClick={handleSaveErrorModalClose}
              />
            </UIBox>
          </Modal>
          <EditExceptionModal
            open={editModalIsVisible}
            onClose={handleEditModalClose}
            handleFormSubmit={handleSaveClick}
            defaultValues={formDefaultValues}
            fields={selectedTab === 0 ? fields : [...fields, 'reasonCode']}
            requiredFields={setRequiredFields(selectedTab)}
          />
          <UIBox p={3} width="100%" flexDirection="column">
            <UIBox width="100%">
              <Tabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                values={tabs.map(({ label }) => label)}
              />
            </UIBox>
            {selectedTab < 3 ? (
              <>
                <UIBox
                  width="100%"
                  justifyContent="space-between"
                  mt={1}
                  mb={3}
                >
                  <SearchBarLight
                    label=""
                    value={storeCodeFilter}
                    placeholder={t('profiling.filterByStoreCode')}
                    disabled={false}
                    onSearch={(): void => {}}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      setStoreCodeFilter(e.target.value)
                    }
                    loading={false}
                    hideButton
                    hideSearchIcon
                  />
                  <UIButtonWithIcon
                    label={t('refresh')}
                    startIcon={<RefreshIcon />}
                    onClick={fetchPosExceptions}
                  />
                </UIBox>
                <UIBox
                  width="100%"
                  justifyContent="space-between"
                  mt={3}
                  mb={2}
                >
                  <UISelectAll
                    selected={currentExceptions?.selectedIds.length}
                    selectedAll={allIdsSelected}
                    actions={{
                      selectAll: handleSelectAll,
                      deselectAll: handleDeselectAll,
                    }}
                  />
                  <UIAction
                    label={t('remove')}
                    icon="delete"
                    onClick={handleRemoveClick}
                  />
                </UIBox>

                {currentExceptions.data && (
                  <ExceptionsList
                    withReason={selectedTab === 1 || selectedTab === 2}
                    onCheckboxClick={handleCheckboxClick}
                    onEditClick={handleEditItemClick}
                    exceptions={currentExceptions.data}
                    selectedIds={currentExceptions.selectedIds}
                  />
                )}
              </>
            ) : (
              <StyledUIBox
                width="100%"
                height="100%"
                mx="auto"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <UIBox
                  alignItems="center"
                  marginBottom={2}
                  marginTop={20}
                  flexDirection="column"
                  gridGap="3em"
                  minWidth="500px"
                  maxWidth="100%"
                >
                  <Typography size="lg">
                    {t('salesExceptionsExtractionDescription')}
                  </Typography>
                  <UIBox
                    minWidth="500px"
                    maxWidth="500px"
                    flexDirection="column"
                    gridGap="2em"
                    alignItems="center"
                  >
                    <UIAutoCompleteStores
                      inputPlaceholder={t('filterByStoreCode')}
                      selectStoreCodeFilter={selectStoreCodeFilter}
                      clearOnBlur={true}
                      blurOnSelect={true}
                      showHelpText={false}
                      popupIcon={<SearchIcon />}
                    />
                    <ChipList
                      chips={selectedStores.map(item => ({
                        key: item.storeCode || '',
                        label: item.storeCode || '',
                      }))}
                      onChipDelete={onDeleteSelectedStore}
                    />
                  </UIBox>
                  <CTAContainer
                    type="ONE_BUTTON"
                    loading={downoloadLoader}
                    label={t('downloadCsv')}
                    onClick={downloadCsvHandler}
                  />
                </UIBox>
              </StyledUIBox>
            )}

            <ErrorSnackbar
              open={getExceptionsError}
              setIsOpen={setGetExceptionsError}
              errorMessage={t('error.getReturnPosExceptions')}
            />
            <ErrorSnackbar
              open={removeError}
              setIsOpen={setRemoveError}
              errorMessage={t('error.removePosItem')}
            />
            <ErrorSnackbar
              open={saveError}
              setIsOpen={setSaveError}
              errorMessage={t('error.saveError')}
            />
            <ErrorSnackbar
              open={errorDownloadCSV}
              setIsOpen={setErrorDownloadCSV}
              errorMessage={t('error.downloadFile')}
            />
          </UIBox>
        </>
      )}
    </>
  );
};

export default PagePosExceptions;
