import {
  ReceiveApiError as ApiError,
  CycleCountItemsList,
  CycleCountService,
  CycleDetailsItems,
  ProductCycleCountList,
  Brand,
} from '@/api';
import {
  IProductsByStore,
  NotFoundPrinted,
  NotFoundWithReason,
  UnexpectedAdded,
} from '@/types/cycleCount';
import {
  CustomBrand,
  CustomMarketingTheme,
  CustomMarketingStory,
  CycleCountSlice,
} from '@/types/cycleCount';
import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  current,
} from '@reduxjs/toolkit';

import { RootState } from '@/app/rootReducer';
import Fuse from 'fuse.js';
import getCurrentDate from '@/utils/getCurrentDate';
import { EPC_STATUS } from '@/types/enum';
import {
  addScannedEpcs,
  updateState,
  filterProductsByStatus,
  removeScannedEpcs,
  sortProductList,
} from './utils';
import {
  ConfirmCycleCount as ConfirmCycleCountType,
  NotFoundCycleCount,
} from '@/api/receive';
import { Process } from '@/hooks/useHandleProcess';

const initialState: CycleCountSlice = {
  cycleCountItems: undefined,
  cyclecCountLocked: false,
  sohLocked: false,
  // ---------------------------------------
  brandList: {
    brand: [],
    commodityList: [],
  },
  filterSelected: -1,
  selectedBrands: [],
  brandsMarketingThemes: [],
  brandsMarketingStories: [],
  selectedMarketingThemes: [],
  selectedMarketingStories: [],
  selectedEpc: [],
  selectedUpc: [],
  selectedModel: [],
  commodityState: {
    sunglasses: true,
    frames: true,
  },
  // ---------------------------------------
  cycleCountItemsIsLoading: undefined,
  brandsMarketingThemesIsLoading: undefined,
  brandsMarketingStoriesIsLoading: undefined,
  cycleCountItemsHasError: undefined,
  // ---------------------------------------
  brandsHasError: undefined,
  products: {
    found: [],
    foundToUndo: [],
    notFound: [],
    unexpected: [],
    unexpectedToUndo: [],
    missing: [],
    recalled: [],
    recalledList: [],
    damaged: [],
    damagedList: [],
    qualityInspection: [],
    qualityInspectionList: [],
    checkoutError: [],
    checkoutErrorList: [],
    inTransit: [],
    inTransitList: [],
    expectedItems: 0,
  },
  productsIsLoading: undefined,
  productsHasError: undefined,
  cycleCountTags: [],
  unexpectedAdded: [],
  notFoundWithReason: [],
  notFoundToUndo: [],

  notFoundPrinted: [],
  filterByEpc: [],
  filterByUpc: [],
  filterByModel: [],
  missingEpcs: [],
  isMissingItemsCycleCount: false,
};

export const getCycleCountList = createAsyncThunk<
  CycleCountItemsList,
  Parameters<typeof CycleCountService.cyclecountGetCycleCountList>[number],
  {
    rejectValue: ApiError;
  }
>('getCycleCountList', async (body, { rejectWithValue }) => {
  try {
    const response = await CycleCountService.cyclecountGetCycleCountList(body);
    response.cycleCountItemsList = response.cycleCountItemsList
      .filter(({ date }) => date)
      .map(({ date, ...item }) => ({
        ...item,
        date: new Date(date!).toISOString(),
      }));
    return response;
  } catch (error) {
    return rejectWithValue(error as ApiError);
  }
});

export const getBrandsByStore = createAsyncThunk<
  IProductsByStore | undefined,
  | (Omit<
      Parameters<typeof CycleCountService.cyclecountFindProductByStore>[number],
      'storeId'
    > & { brand: string })
  | undefined,
  {
    state: RootState;
  }
>('getBrands', async (body, { getState }) => {
  const { currentStore } = getState();

  if (currentStore?.store) {
    const { storeCode } = currentStore.store;

    if (storeCode) {
      const brand = body?.brand;
      const searchOptions = {
        keys: ['brandDescription'],
        minMatchCharLength: 3,
      };
      const response = await CycleCountService.cyclecountFindProductByStore({
        storeId: storeCode,
      });
      if (response.brandList?.brand) {
        const fuse = new Fuse<Brand>(response.brandList?.brand, searchOptions);

        if (brand) {
          const filteredBrands = fuse.search(brand).map(({ item }) => item);

          return {
            ...response,
            brandList: {
              ...response.brandList.brand,
              brand: filteredBrands,
            },
          };
        }
      }
      return response;
    }
  }
});

export const getProductsByFilters = createAsyncThunk<
  ProductCycleCountList,
  NonNullable<{
    requestBody: Omit<
      Parameters<
        typeof CycleCountService.cyclecountSearchProductByFilter
      >[number]['requestBody'],
      'date'
    >;
  }>,
  {
    rejectValue: ApiError;
  }
>('searchProductByFilter', async ({ requestBody }, { rejectWithValue }) => {
  const date = getCurrentDate();

  try {
    return await CycleCountService.cyclecountSearchProductByFilter({
      requestBody: {
        ...requestBody,
        date,
      },
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const confirmCycleCount = createAsyncThunk<
  void,
  ConfirmCycleCountType,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>('confirmCycleCount', async (requestBody, { rejectWithValue }) => {
  try {
    await CycleCountService.cyclecountConfirmCycleCountEncoded({
      requestBody: {
        confirmCycleCount: btoa(JSON.stringify(requestBody)),
      },
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

const cycleCountSlice = createSlice({
  name: 'cycleCount',
  initialState,
  reducers: {
    addCycleCountTags: (
      state,
      { payload }: PayloadAction<{ epc: string }[]>
    ) => {
      const flatTags = payload.map(({ epc }): string => epc.toUpperCase());
      const concatTags = state.cycleCountTags.concat(flatTags);

      state.cycleCountTags = [...new Set(concatTags)];
    },
    // -------------------------------------------------------------------------------------------- //
    setProcessLocked: (
      state,
      { payload }: PayloadAction<{ process: Process; isLocked: boolean }>
    ) => {
      if (payload.process === 'CYCO') {
        state.cyclecCountLocked = payload.isLocked;
      } else if (payload.process === 'SOHA') {
        state.sohLocked = payload.isLocked;
      }
    },
    // -------------------------------------------------------------------------------------------- //
    resetBrandsSettings: (state): void => {
      state.brandsMarketingThemes = [];
      state.brandsMarketingStories = [];
      state.selectedMarketingStories = [];
      state.selectedMarketingThemes = [];
      state.brandsMarketingStoriesIsLoading = undefined;
      state.brandsMarketingThemesIsLoading = undefined;
      state.filterByEpc = [];
      state.filterByUpc = [];
      state.filterByModel = [];
    },
    resetFindItems: (state): void => {
      state.products = {
        found: [],
        foundToUndo: [],
        notFound: [],
        unexpected: [],
        unexpectedToUndo: [],
        missing: [],
        recalled: [],
        recalledList: [],
        damaged: [],
        damagedList: [],
        qualityInspection: [],
        qualityInspectionList: [],
        checkoutError: [],
        checkoutErrorList: [],
        inTransit: [],
        inTransitList: [],
        expectedItems: 0,
      };
    },
    getBrandsMarketing: (state): void => {
      state.brandsMarketingThemesIsLoading = true;
      state.brandsMarketingStoriesIsLoading = true;

      const marketingThemes = state.selectedBrands
        .map(({ marketingTheme }) => marketingTheme)
        .flat() as CustomMarketingTheme[];

      const marketingStories = state.selectedBrands
        .map(({ marketingStory }) => marketingStory)
        .flat() as CustomMarketingStory[];

      if (marketingThemes.length) {
        if (state.selectedBrands.length > 1) {
          const marketingThemeCodes = marketingThemes.map(
            ({ marketingThemeCode }) => marketingThemeCode
          );

          const marketingThemesInCommon = marketingThemeCodes.filter(
            (code, index, array) => array.indexOf(code) !== index
          );

          state.brandsMarketingThemes = marketingThemes
            .filter(({ marketingThemeCode }) =>
              marketingThemesInCommon.includes(marketingThemeCode!)
            )
            .map(marketingTheme => ({
              ...marketingTheme,
              selected: false,
            }));
        } else {
          state.brandsMarketingThemes = marketingThemes.map(marketingTheme => ({
            ...marketingTheme,
            selected: false,
          }));
        }
      }

      if (marketingStories.length) {
        if (state.selectedBrands.length > 1) {
          const marketingStoryCodes = marketingStories.map(
            ({ marketingStoryCode }) => marketingStoryCode
          );

          const marketingStoriesInCommon = marketingStoryCodes.filter(
            (code, index, array) => array.indexOf(code) !== index
          );

          state.brandsMarketingStories = marketingStories
            .filter(({ marketingStoryCode }) =>
              marketingStoriesInCommon.includes(marketingStoryCode!)
            )
            .map(marketingStory => ({
              ...marketingStory,
              selected: false,
            }));
        } else {
          state.brandsMarketingStories = marketingStories.map(
            marketingStory => ({
              ...marketingStory,
              selected: false,
            })
          );
        }
      }

      state.brandsMarketingThemesIsLoading = false;
      state.brandsMarketingStoriesIsLoading = false;
    },
    // -------------------------------------------------------------------------------------------- //
    selectBrand: (state, { payload }: PayloadAction<CustomBrand>): void => {
      state.selectedBrands.push({ ...payload, selected: true });

      if (state.brandList?.brand) {
        const brandSelectedIndex = state.brandList.brand.findIndex(
          ({ brandCode }) => brandCode === payload.brandCode
        );

        if (brandSelectedIndex !== -1) {
          state.brandList.brand[brandSelectedIndex].selected = true;
        }
      }
    },
    deselectBrand: (state, { payload }: PayloadAction<CustomBrand>): void => {
      if (state.brandList?.brand) {
        const brandListIndex = state.brandList.brand.findIndex(
          ({ brandCode }) => payload.brandCode === brandCode
        );
        const brandSelectedIndex = state.selectedBrands.findIndex(
          ({ brandCode }) => payload.brandCode === brandCode
        );

        if (brandListIndex !== -1) {
          state.brandList.brand[brandListIndex].selected = false;
          state.selectedBrands.splice(brandSelectedIndex, 1);
        }
      }
    },
    selectAllBrand: (
      state,
      { payload }: PayloadAction<CustomBrand[]>
    ): void => {
      if (state.brandList?.brand) {
        const selectedBrand = payload.map(({ brandCode }) => brandCode);

        const brandsMatch = current(state)
          .brandList?.brand?.filter(({ brandCode }) =>
            selectedBrand.includes(brandCode)
          )
          .map(item => ({
            ...item,
            selected: true,
          }));

        state.brandList.brand = current(state).brandList?.brand?.map(
          item =>
            brandsMatch?.find(
              matchItem => item.brandCode === matchItem.brandCode
            ) || item
        );

        state.selectedBrands = state.brandList?.brand!.filter(
          item => item.selected
        );
      }
    },
    deselectAllBrand: (state, { payload }: PayloadAction<CustomBrand[]>) => {
      if (state.brandList?.brand) {
        const selectedBrand = payload.map(({ brandCode }) => brandCode);

        const brandsMatch = current(state)
          .brandList?.brand?.filter(({ brandCode }) =>
            selectedBrand.includes(brandCode)
          )
          .map(item => ({
            ...item,
            selected: false,
          }));

        state.brandList.brand = current(state).brandList?.brand?.map(
          item =>
            brandsMatch?.find(
              matchItem => item.brandCode === matchItem.brandCode
            ) || item
        );

        state.selectedBrands = state.selectedBrands.filter(
          item =>
            !payload.find(
              itemToRemove => item.brandCode === itemToRemove.brandCode
            )
        );
      }
    },
    // -------------------------------------------------------------------------------------------- //
    selectMarketingTheme: (
      state,
      { payload }: PayloadAction<CustomMarketingTheme>
    ): void => {
      state.selectedMarketingThemes.push({ ...payload, selected: true });

      const selectedMarketingThemeIndex = state.brandsMarketingThemes.findIndex(
        ({ marketingThemeCode }) =>
          marketingThemeCode === payload.marketingThemeCode
      );

      if (selectedMarketingThemeIndex !== -1) {
        state.brandsMarketingThemes[selectedMarketingThemeIndex].selected =
          true;
      }
    },
    deselectMarketingTheme: (
      state,
      { payload }: PayloadAction<CustomMarketingTheme>
    ): void => {
      const brandsMarketingThemeIndex = state.brandsMarketingThemes.findIndex(
        ({ marketingThemeCode }) =>
          marketingThemeCode === payload.marketingThemeCode
      );

      const selectedMarketingThemeIndex =
        state.selectedMarketingThemes.findIndex(
          ({ marketingThemeCode }) =>
            marketingThemeCode === payload.marketingThemeCode
        );

      state.brandsMarketingThemes[brandsMarketingThemeIndex].selected = false;
      state.selectedMarketingThemes.splice(selectedMarketingThemeIndex, 1);
    },

    selectAllMarketingTheme: (
      state,
      { payload }: PayloadAction<CustomMarketingTheme[]>
    ) => {
      const selectedMarketingThemeCodes = payload.map(
        ({ marketingThemeCode }) => marketingThemeCode
      );

      const marketingThemesMatch = current(state)
        .brandsMarketingThemes.filter(({ marketingThemeCode }) =>
          selectedMarketingThemeCodes.includes(marketingThemeCode)
        )
        .map(item => ({
          ...item,
          selected: true,
        }));

      state.brandsMarketingThemes = current(state).brandsMarketingThemes.map(
        item =>
          marketingThemesMatch.find(
            matchItem =>
              item.marketingThemeCode === matchItem.marketingThemeCode
          ) || item
      );

      state.selectedMarketingThemes = state.brandsMarketingThemes.filter(
        item => item.selected
      );
    },

    deselectAllMarketingTheme: (
      state,
      { payload }: PayloadAction<CustomMarketingTheme[]>
    ) => {
      const selectedMarketingThemeCodes = payload.map(
        ({ marketingThemeCode }) => marketingThemeCode
      );

      const marketingThemesMatch = current(state)
        .brandsMarketingThemes.filter(({ marketingThemeCode }) =>
          selectedMarketingThemeCodes.includes(marketingThemeCode)
        )
        .map(item => ({
          ...item,
          selected: false,
        }));

      state.brandsMarketingThemes = current(state).brandsMarketingThemes.map(
        item =>
          marketingThemesMatch.find(
            matchItem =>
              item.marketingThemeCode === matchItem.marketingThemeCode
          ) || item
      );

      state.selectedMarketingThemes = state.selectedMarketingThemes.filter(
        item =>
          !payload.find(
            itemToRemove =>
              item.marketingThemeCode === itemToRemove.marketingThemeCode
          )
      );
    },

    selectAllMarketingStory: (
      state,
      { payload }: PayloadAction<CustomMarketingStory[]>
    ) => {
      const selectedMarketingStoriesCodes = payload.map(
        ({ marketingStoryCode }) => marketingStoryCode
      );

      const marketingStoriesMatch = current(state)
        .brandsMarketingStories.filter(({ marketingStoryCode }) =>
          selectedMarketingStoriesCodes.includes(marketingStoryCode)
        )
        .map(item => ({
          ...item,
          selected: true,
        }));

      state.brandsMarketingStories = current(state).brandsMarketingStories.map(
        item =>
          marketingStoriesMatch.find(
            matchItem =>
              item.marketingStoryCode === matchItem.marketingStoryCode
          ) || item
      );

      state.selectedMarketingStories = state.brandsMarketingStories.filter(
        item => item.selected
      );
    },
    deselectAllMarketingStory: (
      state,
      { payload }: PayloadAction<CustomMarketingStory[]>
    ) => {
      const selectedMarketingStoryCodes = payload.map(
        ({ marketingStoryCode }) => marketingStoryCode
      );

      const marketingStoriesMatch = current(state)
        .brandsMarketingStories.filter(({ marketingStoryCode }) =>
          selectedMarketingStoryCodes.includes(marketingStoryCode)
        )
        .map(item => ({
          ...item,
          selected: false,
        }));

      state.brandsMarketingStories = current(state).brandsMarketingStories.map(
        item =>
          marketingStoriesMatch.find(
            matchItem =>
              item.marketingStoryCode === matchItem.marketingStoryCode
          ) || item
      );

      state.selectedMarketingStories = state.selectedMarketingStories.filter(
        item =>
          !payload.find(
            itemToRemove =>
              item.marketingStoryCode === itemToRemove.marketingStoryCode
          )
      );
    },
    selectMarketingStory: (
      state,
      { payload }: PayloadAction<CustomMarketingStory>
    ): void => {
      state.selectedMarketingStories.push({ ...payload, selected: true });

      const selectedMarketingStoryIndex =
        state.brandsMarketingStories.findIndex(
          ({ marketingStoryCode }) =>
            marketingStoryCode === payload.marketingStoryCode
        );

      if (selectedMarketingStoryIndex !== -1) {
        state.brandsMarketingStories[selectedMarketingStoryIndex].selected =
          true;
      }
    },
    deselectMarketingStory: (
      state,
      { payload }: PayloadAction<CustomMarketingStory>
    ): void => {
      const brandsMarketingStoryIndex = state.brandsMarketingStories.findIndex(
        ({ marketingStoryCode }) =>
          marketingStoryCode === payload.marketingStoryCode
      );

      const selectedMarketingStoryIndex =
        state.selectedMarketingStories.findIndex(
          ({ marketingStoryCode }) =>
            marketingStoryCode === payload.marketingStoryCode
        );

      state.brandsMarketingStories[brandsMarketingStoryIndex].selected = false;
      state.selectedMarketingStories.splice(selectedMarketingStoryIndex, 1);
    },
    // -------------------------------------------------------------------------------------------- //
    changeCommodityState: (
      state,
      { payload }: PayloadAction<keyof CycleCountSlice['commodityState']>
    ): void => {
      state.commodityState[payload] = !state.commodityState[payload];
    },
    // -------------------------------------------------------------------------------------------- //
    addUnexpectedToFound: (
      state,
      {
        payload,
      }: PayloadAction<{
        unexpectedProduct: UnexpectedAdded;
        product: NonNullable<CycleDetailsItems>;
        epc: string;
      }>
    ) => {
      // * ADD EPC TO UNEXPECTED ADDED STATE
      const upcAlreadyInStateIndex = state.unexpectedAdded.findIndex(
        ({ upcCodeFound }) =>
          upcCodeFound === payload.unexpectedProduct.upcCodeFound
      );

      if (upcAlreadyInStateIndex === -1) {
        state.unexpectedAdded.push(payload.unexpectedProduct);
      } else {
        state.unexpectedAdded[upcAlreadyInStateIndex].epcFound = [
          ...state.unexpectedAdded[upcAlreadyInStateIndex].epcFound,
          ...payload.unexpectedProduct.epcFound,
        ];
        state.unexpectedAdded[upcAlreadyInStateIndex].epcFoundQuantity += 1;
      }

      // * REMOVE EPC FROM UNEXPECTED PRODUCTS STATE
      const epcToRemoveIndex = state.products.unexpected.findIndex(
        ({ epcCodes }) =>
          epcCodes.findIndex(({ epcCode }) => epcCode === payload.epc) !== -1
      );

      state.products.unexpected[epcToRemoveIndex].epcCodes =
        state.products.unexpected[epcToRemoveIndex].epcCodes.filter(
          ({ epcCode }) => epcCode !== payload.epc
        );

      if (state.products.unexpected[epcToRemoveIndex].epcCodes.length === 0) {
        state.products.unexpected.splice(epcToRemoveIndex, 1);
      }

      // * ADD EPC FROM UNEXPECTED PRODUCTS TO FOUND PRODUCTS
      const isArleadyFoundIndex = state.products.found.findIndex(
        ({ upcCode }) => upcCode === payload.unexpectedProduct.upcCodeFound
      );

      if (isArleadyFoundIndex !== -1) {
        state.products.found[isArleadyFoundIndex].epcCodes.push({
          epcCode: payload.epc,
          epcStatus: EPC_STATUS.MISSING,
        });
      } else {
        state.products.found.push({
          ...payload.product,
          epcCodes: payload.product.epcCodes.filter(
            ({ epcCode }) => epcCode === payload.epc
          ),
        });
      }
    },
    addNotFoundReason: (
      state,
      {
        payload,
      }: PayloadAction<{
        notFoundProduct: NotFoundWithReason;
        product: NonNullable<CycleDetailsItems>;
        epc: string;
      }>
    ) => {
      // * ADD EPC TO NOT FOUND ADDED STATE
      const upcAlreadyInStateIndex = state.notFoundWithReason.findIndex(
        ({ upcCodeNotFound }) =>
          upcCodeNotFound === payload.notFoundProduct.upcCodeNotFound
      );

      if (upcAlreadyInStateIndex === -1) {
        state.notFoundWithReason.push(payload.notFoundProduct);
      } else {
        const epcAlreadyReasoned = state.notFoundWithReason[
          upcAlreadyInStateIndex
        ].epcNotFound.find(
          ({ epcNotFoundCode }) => epcNotFoundCode === payload.epc
        );

        if (epcAlreadyReasoned) {
          epcAlreadyReasoned.reasonEpcNotFound =
            payload.notFoundProduct.epcNotFound[0].reasonEpcNotFound;
        } else {
          state.notFoundWithReason[upcAlreadyInStateIndex].epcNotFound = [
            ...state.notFoundWithReason[upcAlreadyInStateIndex].epcNotFound,
            ...payload.notFoundProduct.epcNotFound,
          ];
          state.notFoundWithReason[
            upcAlreadyInStateIndex
          ].epcNotFoundQuantity += 1;
        }
      }
    },
    removeNotFoundReason: (
      state,
      { payload }: PayloadAction<{ upc: string; epc: string }>
    ) => {
      // * ADD EPC TO NOT FOUND ADDED STATE
      const { notFoundWithReason } = state;
      const upcAlreadyInStateIndex = notFoundWithReason.findIndex(
        ({ upcCodeNotFound }) => upcCodeNotFound === payload.upc
      );

      const epcAlreadyInStateIndex = notFoundWithReason?.[
        upcAlreadyInStateIndex
      ]?.epcNotFound?.findIndex(myEpc => myEpc.epcNotFoundCode === payload.epc);

      if (upcAlreadyInStateIndex !== -1) {
        notFoundWithReason[upcAlreadyInStateIndex]?.epcNotFound.splice(
          epcAlreadyInStateIndex,
          1
        );

        if (
          notFoundWithReason[upcAlreadyInStateIndex]?.epcNotFound.length === 0
        ) {
          notFoundWithReason.splice(upcAlreadyInStateIndex, 1);
        }
      }
    },
    // -------------------------------------------------------------------------------------------- //
    addNotFoundPrintedToFound: (
      state,
      {
        payload,
      }: PayloadAction<{
        upc: string;
        oldEpc: string;
        newEpc: string;
      }>
    ) => {
      const foundProductIndex = state.products.found.findIndex(
        product => product.upcCode === payload.upc
      );

      if (foundProductIndex !== -1) {
        state.products.found[foundProductIndex]?.epcCodes?.push({
          epcCode: payload.newEpc.toUpperCase(),
          epcStatus: 'In Stock',
        });
      } else {
        const productNotFoundToPush = (
          state.isMissingItemsCycleCount
            ? state.products.missing
            : state.products.notFound
        ).find(product => product.upcCode === payload.upc);

        state.products.found.push({
          ...productNotFoundToPush!,
          epcCodes: [
            {
              epcCode: payload.newEpc.toUpperCase(),
              epcStatus: 'In Stock',
            },
          ],
        });
      }
    },
    removeEpcPrintedFromNotFound: (
      state,
      {
        payload,
      }: PayloadAction<{
        upc: string;
        oldEpc: string;
      }>
    ) => {
      const products = state.isMissingItemsCycleCount
        ? state.products.missing
        : state.products.notFound;

      const productNotFoundIndex = products.findIndex(
        ({ upcCode }) => upcCode === payload.upc
      );

      if (productNotFoundIndex !== -1) {
        const epcDaRimuovere = products[
          productNotFoundIndex
        ].epcCodes.findIndex(({ epcCode }) => epcCode === payload.oldEpc);

        products[productNotFoundIndex].epcCodes.splice(epcDaRimuovere, 1);

        if (products[productNotFoundIndex].epcCodes.length === 0) {
          products.splice(productNotFoundIndex, 1);
        }
      }
    },
    addNotFoundPrinted: (
      state,
      {
        payload,
      }: PayloadAction<{
        notFoundProduct: NotFoundPrinted;
        epc: string;
        upc: string;
      }>
    ) => {
      // * ADD EPC TO PRINTED ADDED STATE
      const upcAlreadyInStateIndex = state.notFoundPrinted.findIndex(
        ({ upcCodeFound }) =>
          upcCodeFound === payload.notFoundProduct.upcCodeFound
      );

      if (upcAlreadyInStateIndex === -1) {
        state.notFoundPrinted.push(payload.notFoundProduct);
      } else {
        state.notFoundPrinted[upcAlreadyInStateIndex].epcFound = [
          ...state.notFoundPrinted[upcAlreadyInStateIndex].epcFound,
          ...payload.notFoundProduct.epcFound,
        ];
        state.notFoundPrinted[upcAlreadyInStateIndex].epcFoundQuantity += 1;
      }
    },
    // -------------------------------------------------------------------------------------------- //
    findEpcThroughTags: (state, { payload }: PayloadAction<string[]>) => {
      const foundList = addScannedEpcs(
        sortProductList(state.products.notFound),
        payload
      );

      const unexpectedList = addScannedEpcs(state.products.missing, payload);
      const recalledList = addScannedEpcs(state.products.recalledList, payload);
      const damagedList = addScannedEpcs(state.products.damagedList, payload);
      const inTransitList = addScannedEpcs(
        state.products.inTransitList,
        payload
      );
      const qualityInspectionList = addScannedEpcs(
        state.products.qualityInspectionList,
        payload
      );
      const checkoutErrorList = addScannedEpcs(
        state.products.checkoutErrorList,
        payload
      );

      // Update the state which is responsible to show products in the app
      updateState(foundList, state.products.found);
      updateState(unexpectedList, state.products.unexpected);
      updateState(recalledList, state.products.recalled);
      updateState(damagedList, state.products.damaged);
      updateState(inTransitList, state.products.inTransit);
      updateState(qualityInspectionList, state.products.qualityInspection);
      updateState(checkoutErrorList, state.products.checkoutError);
      // -----------------------------------------------------------------

      state.products.notFound = removeScannedEpcs<CycleDetailsItems>(
        sortProductList(state.products.notFound),
        payload,
        EPC_STATUS.IN_STOCK
      );

      state.notFoundWithReason = removeScannedEpcs<NotFoundCycleCount>(
        state.notFoundWithReason,
        payload,
        'Reasoned'
      );

      state.products.missing = removeScannedEpcs<CycleDetailsItems>(
        state.products.missing,
        payload,
        EPC_STATUS.MISSING
      );

      state.products.recalledList = removeScannedEpcs<CycleDetailsItems>(
        state.products.recalledList,
        payload,
        EPC_STATUS.RECALLED
      );

      state.products.damagedList = removeScannedEpcs<CycleDetailsItems>(
        state.products.damagedList,
        payload,
        EPC_STATUS.DAMAGED
      );

      state.products.inTransitList = removeScannedEpcs<CycleDetailsItems>(
        state.products.inTransitList,
        payload,
        EPC_STATUS.IN_TRANSIT
      );

      state.products.qualityInspectionList =
        removeScannedEpcs<CycleDetailsItems>(
          state.products.qualityInspectionList,
          payload,
          EPC_STATUS.QUALITY_INSPECTION
        );

      state.products.checkoutErrorList = removeScannedEpcs<CycleDetailsItems>(
        state.products.checkoutErrorList,
        payload,
        EPC_STATUS.CHECKOUT_ERROR
      );

      // const foundList = sortProductList(state.products.notFound)
      //   .map(product => ({
      //     ...product,
      //     epcCodes: product.epcCodes.filter(({ epcCode }) =>
      //       payload.includes(epcCode)
      //     ),
      //   }))
      //   .filter(({ epcCodes }) => epcCodes.length > 0);

      // foundList.forEach(nf => {
      // 	const curIndex = state.products.found.findIndex(
      // 		({ upcCode }) => upcCode === nf.upcCode
      // 	);
      // 	if (curIndex > -1) {
      // 		state.products.found[curIndex].epcCodes = state.products.found[
      // 			curIndex
      // 		].epcCodes.concat(nf.epcCodes);
      // 	} else {
      // 		state.products.found.push(nf);
      // 	}
      // });

      // const unexpectedList = state.products.missing
      //   .map(product => ({
      //     ...product,
      //     epcCodes: product.epcCodes.filter(({ epcCode }) =>
      //       payload.includes(epcCode)
      //     ),
      //   }))
      //   .filter(({ epcCodes }) => epcCodes.length > 0);

      // unexpectedList.forEach(nu => {
      // 	const curIndex = state.products.unexpected.findIndex(
      // 		({ upcCode }) => upcCode === nu.upcCode
      // 	);
      // 	if (curIndex > -1) {
      // 		state.products.unexpected[curIndex].epcCodes =
      // 			state.products.unexpected[curIndex].epcCodes.concat(nu.epcCodes);
      // 	} else {
      // 		state.products.unexpected.push(nu);
      // 	}
      // });

      // state.products.notFound = sortProductList(state.products.notFound)
      // 	.map(product => ({
      // 		...product,
      // 		epcCodes: product.epcCodes.filter(
      // 			({ epcCode }) => !payload.includes(epcCode)
      // 		),
      // 	}))
      // 	.filter(({ epcCodes }) => epcCodes.length > 0);

      // state.notFoundWithReason = state.notFoundWithReason
      // 	.map(item => ({
      // 		...item,
      // 		epcNotFound: item.epcNotFound.filter(
      // 			({ epcNotFoundCode }) => !payload.includes(epcNotFoundCode)
      // 		),
      // 	}))
      // 	.filter(({ epcNotFound }) => epcNotFound.length > 0);

      // state.products.missing = state.products.missing
      // 	.map(product => ({
      // 		...product,
      // 		epcCodes: product.epcCodes.filter(
      // 			({ epcCode }) => !payload.includes(epcCode)
      // 		),
      // 	}))
      // 	.filter(({ epcCodes }) => epcCodes.length > 0);
    },
    addCycleCountFilteredByEPC: (
      state,
      { payload }: PayloadAction<CycleDetailsItems>
    ) => {
      state.filterByEpc = [...state.filterByEpc!, payload];
    },
    addCycleCountFilteredByUPC: (
      state,
      { payload }: PayloadAction<CycleDetailsItems[]>
    ) => {
      state.filterByUpc = payload;
    },
    addCycleCountFilteredByModel: (
      state,
      { payload }: PayloadAction<CycleDetailsItems[]>
    ) => {
      state.filterByModel = payload;
    },
    setCycleCountFilteredByUpc: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.selectedUpc = payload;
    },
    setCycleCountFilteredByEpc: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.selectedEpc = payload;
    },
    setCycleCountFilteredByModel: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.selectedModel = payload;
    },
    setFilterSelected: (state, { payload }) => {
      state.filterSelected = payload;
    },
    setSohProducts: (state, { payload }) => {
      state.products = payload;
    },
    // -------------------------------------------------------------------------------------------- //
    setMissingEpcs: (state, { payload }: PayloadAction<string[]>) => {
      state.missingEpcs = payload;
    },
    undoNotFoundProducts: state => {
      state.notFoundWithReason = state.notFoundToUndo;
    },
    undoUnexpectedProducts: state => {
      state.products.unexpected = state.products.unexpectedToUndo;
      state.products.found = state.products.foundToUndo;
    },
    setNotFoundProductsToUndo: (
      state,
      { payload }: PayloadAction<NotFoundWithReason[]>
    ) => {
      state.notFoundToUndo = payload;
    },
    setUnexpectedProductsToUndo: (
      state,
      {
        payload,
      }: PayloadAction<{
        unexpected: CycleDetailsItems[];
        found: CycleDetailsItems[];
      }>
    ) => {
      state.products.unexpectedToUndo = payload.unexpected;
      state.products.foundToUndo = payload.found;
    },
    setMissingItemsCycleCount: (state, { payload }: PayloadAction<boolean>) => {
      state.isMissingItemsCycleCount = payload;
    },
    filterMissingProductsByMissingEpcs: state => {
      state.products.missing = state.products.missing
        .map(product => ({
          ...product,
          epcCodes: product.epcCodes.filter(({ epcCode }) =>
            state.missingEpcs.includes(epcCode)
          ),
        }))
        .filter(({ epcCodes }) => epcCodes.length > 0);
    },
    initCycleCountState: state => {
      return {
        ...initialState,
        cyclecCountLocked: state.cyclecCountLocked,
        sohLocked: false,
      };
    },
    initCycleCountProducts: state => {
      state.missingEpcs = [];
      state.notFoundPrinted = [];
      state.notFoundWithReason = [];
      state.notFoundToUndo = [];
      state.products = initialState.products;
      state.unexpectedAdded = [];
      state.selectedUpc = [];
      state.selectedEpc = [];
      state.selectedBrands = [];
      state.selectedModel = [];
      state.filterByEpc = [];
      state.filterByUpc = [];
      state.filterByModel = [];
      state.cycleCountTags = [];
      state.brandsMarketingThemes = [];
      state.brandsMarketingStories = [];
      state.selectedMarketingThemes = [];
      state.selectedMarketingStories = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCycleCountList.pending, state => {
        state.cycleCountItemsIsLoading = true;
      })
      .addCase(getCycleCountList.fulfilled, (state, { payload }) => {
        state.cycleCountItemsIsLoading = false;
        state.cycleCountItems = payload.cycleCountItemsList;
      })
      .addCase(getCycleCountList.rejected, (state, { payload }) => {
        state.cycleCountItemsHasError = payload as ApiError;
      })
      .addCase(getBrandsByStore.pending, state => {
        state.brandIsLoading = true;
      })
      .addCase(getBrandsByStore.fulfilled, (state, { payload }) => {
        state.brandIsLoading = false;

        if (payload?.brandList) {
          state.brandList = payload.brandList || {
            brand: [],
            commodityList: [],
          };
          state.brandList.brand = state.brandList.brand?.map(brand => ({
            ...brand,
            selected: false,
          }));

          if (payload.commodityList && payload.commodityList.commodity) {
            const commodities = payload.commodityList.commodity.filter(
              ({ commodityCode, commodityDescription }) =>
                commodityCode !== '' && !!commodityDescription
            );

            state.brandList.commodityList = state.sohLocked
              ? commodities.concat({
                  commodityCode: 'ALL',
                  commodityDescription: 'all',
                })
              : commodities;
          }
        }
      })
      .addCase(getBrandsByStore.rejected, (state, { payload }) => {
        state.brandsHasError = payload as ApiError;
      })
      .addCase(getProductsByFilters.pending, state => {
        state.productsIsLoading = true;
      })
      .addCase(getProductsByFilters.fulfilled, (state, { payload }) => {
        state.productsIsLoading = false;

        if (payload.productDetailsItems) {
          if (state.isMissingItemsCycleCount) {
            state.products.missing = filterProductsByStatus(
              payload.productDetailsItems,
              EPC_STATUS.MISSING
            );
          } else {
            // Unexpected
            state.products.missing = filterProductsByStatus(
              payload.productDetailsItems,
              EPC_STATUS.MISSING
            );

            // Not Found
            state.products.notFound = filterProductsByStatus(
              sortProductList(payload.productDetailsItems),
              EPC_STATUS.IN_STOCK
            );
          }

          // Recalled
          state.products.recalledList = filterProductsByStatus(
            sortProductList(payload.productDetailsItems),
            EPC_STATUS.RECALLED
          );

          // Damaged
          state.products.damagedList = filterProductsByStatus(
            sortProductList(payload.productDetailsItems),
            EPC_STATUS.DAMAGED
          );

          // Quality Inspection
          state.products.qualityInspectionList = filterProductsByStatus(
            sortProductList(payload.productDetailsItems),
            EPC_STATUS.QUALITY_INSPECTION
          );

          // Checkout Error
          state.products.checkoutErrorList = filterProductsByStatus(
            sortProductList(payload.productDetailsItems),
            EPC_STATUS.CHECKOUT_ERROR
          );

          // In transit
          state.products.inTransitList = filterProductsByStatus(
            sortProductList(payload.productDetailsItems),
            EPC_STATUS.IN_TRANSIT
          );

          state.products.expectedItems = state.products.notFound.reduce(
            (acc: number, b) => acc + Number(b.epcCodes.length),
            0
          );
        }
      })
      .addCase(getProductsByFilters.rejected, (state, { payload }) => {
        state.productsHasError = payload;
      })
      .addCase(confirmCycleCount.pending, state => {
        state.confirmIsLoading = true;
        state.confirmHasError = undefined;
      })
      .addCase(confirmCycleCount.fulfilled, state => {
        state.confirmIsLoading = false;
      })
      .addCase(confirmCycleCount.rejected, (state, { payload }) => {
        state.confirmIsLoading = false;
        state.confirmHasError = payload;
      });
  },
});

export const {
  addCycleCountTags,
  setProcessLocked,
  resetBrandsSettings,
  resetFindItems,
  getBrandsMarketing,
  setFilterSelected,
  selectBrand,
  selectAllBrand,
  deselectAllBrand,
  deselectBrand,
  selectMarketingTheme,
  deselectMarketingTheme,
  selectAllMarketingTheme,
  deselectAllMarketingTheme,
  selectAllMarketingStory,
  deselectAllMarketingStory,
  changeCommodityState,
  addUnexpectedToFound,
  addNotFoundReason,
  addNotFoundPrintedToFound,
  removeEpcPrintedFromNotFound,
  addNotFoundPrinted,
  selectMarketingStory,
  deselectMarketingStory,
  findEpcThroughTags,
  initCycleCountProducts,
  initCycleCountState,
  removeNotFoundReason,
  addCycleCountFilteredByEPC,
  addCycleCountFilteredByUPC,
  addCycleCountFilteredByModel,
  setCycleCountFilteredByUpc,
  setCycleCountFilteredByEpc,
  setCycleCountFilteredByModel,
  setSohProducts,
  setMissingEpcs,
  undoNotFoundProducts,
  undoUnexpectedProducts,
  setNotFoundProductsToUndo,
  setUnexpectedProductsToUndo,
  filterMissingProductsByMissingEpcs,
  setMissingItemsCycleCount,
} = cycleCountSlice.actions;
export default cycleCountSlice.reducer;
