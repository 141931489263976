import { useSelector } from '@/hooks/useSelector';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { DateTime, Interval } from 'luxon';
import useSROTrackEvent from '@/hooks/useSROTrackEvent';
import { useCycleCountContext } from '@/context/cycleCount';

type TrackConfirmCycleCount = {
  User: string;
  StoreCode: string;
  TimeNeeded: string;
  IdCounts: string;
  Filter: string;
  ExpectedItems: number;
  FoundItems: number;
  MissingItems: number;
  ExcessItems: number;
  ScanNeeded: number;
  StockOnHand: number;
};

type ReturnHookType = (startTimestamp: DateTime) => Promise<void>;

const useTrackConfirm = (scanAgainCounter: number): ReturnHookType => {
  const appInsights = useAppInsightsContext();
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const { missingItemsBrands } = useCycleCountContext();
  const { username } = useSelector(state => state.user);
  const { unexpectedAdded } = useSelector(state => state.cycleCount);
  const { selectedBrands, isMissingItemsCycleCount } = useSelector(
    state => state.cycleCount
  );
  const { found, notFound } = useSelector(state => state.cycleCount.products);
  const { totalStock: initialStock } = useSelector(
    state => state.stockSummary.brands
  );

  const foundItemsCount = found.reduce(
    (acc: number, b) => acc + Number(b.epcCodes.length),
    0
  );

  const notFoundItemsCount = notFound.reduce(
    (acc: number, b) => acc + Number(b.epcCodes.length),
    0
  );

  const data: TrackConfirmCycleCount = {
    User: username || '',
    StoreCode: storeCode || '',
    TimeNeeded: '',
    IdCounts: `${username}-${sessionStorage.getItem('linkedEventId')}`,
    Filter: isMissingItemsCycleCount
      ? missingItemsBrands.join(', ')
      : selectedBrands
          .map(({ brandDescription }) => brandDescription)
          .join(', '),
    ExpectedItems: foundItemsCount + notFoundItemsCount,
    FoundItems: foundItemsCount,
    MissingItems: notFoundItemsCount,
    ExcessItems: unexpectedAdded.map(({ epcFound }) => epcFound).flat().length,
    ScanNeeded: scanAgainCounter,
    StockOnHand: initialStock || 0,
  };

  const trackEvent = useSROTrackEvent<TrackConfirmCycleCount>(
    appInsights,
    'Confirm Cycle Count',
    data
  );

  const trackConfirm = async (startTimestamp: DateTime): Promise<void> => {
    if (startTimestamp) {
      const confirmTimestamp = DateTime.local();
      const interval = Interval.fromDateTimes(startTimestamp, confirmTimestamp)
        .toDuration(['minutes', 'seconds'])
        .toObject();

      const seconds =
        interval.seconds! <= 9
          ? `0${interval.seconds?.toFixed()}`
          : interval.seconds?.toFixed();

      await trackEvent({
        ...data,
        TimeNeeded: `${interval.minutes?.toFixed()}:${seconds}`,
      });
    }
  };

  return trackConfirm;
};

export default useTrackConfirm;
