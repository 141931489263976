import { FC, useContext } from 'react';
import { useAsync } from 'react-use';
// import { useInterval } from 'react-use';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { AppRoutes } from '@/app/routers';
import { getAllDevicesStatus } from '@/features/devices/devicesSlice';

import { AppBar, Tooltip } from '@material-ui/core';
import { UILink } from '@/components/ui/Link';
import { UIBox } from '@/components/ui/Box';
import { UIBreadcrumbs } from '@/components/ui/Breadcrumbs';
import { UILoader } from '@/components/ui/Loader';
import { UserInfo } from '@/components/ui/UserInfo';
import { Typography } from '@/components/ui/Typography';
import { DeviceInfo } from '@/components/layout/DeviceInfo';
import TodayIcon from '@material-ui/icons/Today';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

import { ReactComponent as LogoLux } from '@/assets/images/logo_luxottica.svg';

import { colors, TColorName } from '@/theme';
import { useAppDispatch } from '@/app/store';
import { useSubscribeDeviceStatus } from '@/hooks/mqtt/useSubscribeDeviceStatus';
import { useHistory, useLocation } from 'react-router';
import { useNotificationContext } from '@/context/notificationContext';
import { NotificationBanner } from '@/components/layout/NotificationBanner';
import { isVirtualBuddy } from '@/utils/user';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import { MQTTContext } from '@/context/MQTT';

export interface HeaderProps {
  color: 'default' | 'inherit' | 'primary' | 'secondary' | 'transparent';
  position: 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky';
}

//#region - Styled Components
const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }): TColorName => theme.palette.colors.white};
`;

const StyledLogo = styled(LogoLux)`
  overflow: visible;
  margin-right: 24px;
`;

const StyledTopContainerBox = styled(UIBox)`
  padding: 24px;
  height: 88px;
`;

const StyledTextContainerBox = styled(UIBox)`
  height: 43px;
  justify-content: space-between;
`;

const StyledBottomContainerBox = styled(UIBox)`
  padding: 0 24px;
  border-top: 1px solid
    ${({ theme }): TColorName => theme.palette.colors.lightGrey};
  border-bottom: 1px solid
    ${({ theme }): TColorName => theme.palette.colors.lightGrey};
  background-color: ${({ theme }): TColorName => theme.palette.colors.midGrey};
  height: 48px;
  line-height: 48px;
  align-items: center;
`;

const StyledDevicesStatusContainer = styled('div')`
  display: flex;
`;

const StyledDeviceInfo = styled(DeviceInfo)`
  margin-left: 8px;
`;

const StyledCalendarIconBox = styled(UIBox)<{ $active?: boolean }>`
  position: relative;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s ease;

  ${({ $active }): false | FlattenSimpleInterpolation | undefined =>
    $active &&
    css`
      border: 1px solid ${colors.primary};
      box-shadow: 0px 0px 10px 0px #00000033;
    `};

  &:hover {
    border: 1px solid ${colors.primary};
    box-shadow: 0px 0px 10px 0px #00000033;
  }
`;

const StyledIconWrapper = styled(UIBox)`
  margin-left: 8px;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: ${colors.primary};
  margin-left: 8px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledNotification = styled('div')`
  position: absolute;
  top: 8px;
  right: 8px;
  background: red;
  width: 9px;
  height: 9px;
  border-radius: 100px;
  border: 1px solid white;
`;

const StyledAntennaIconWrapper = styled('div')<{ color: string }>`
  margin-right: 18px;

  svg {
    fill: ${({ color }): string => color};
  }
`;
//#endregion

export const Header: FC<HeaderProps> = (props: HeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const history = useHistory();
  const { pathname } = useLocation();
  const { mqttConnectionStatus } = useContext(MQTTContext);

  const {
    isNotificationBannerVisible,
    isNotificationAvailable,
    setNotificationBannerVisibility,
  } = useNotificationContext();
  const breadcrumbsItems = useSelector(state => state.breadcrumbs.items);

  const loggedUser = useSelector(state => state.user);
  const { store } = useSelector(state => state.currentStore);
  const inventoryStatus = useSelector(
    state => state.inventory?.inventoryDetails?.inventoryStatus
  );

  const { deviceList, isLoadingDeviceStatus } = useSelector(
    state => state.devices
  );

  useAsync(async () => {
    await dispatch(getAllDevicesStatus());
  }, [dispatch]);

  useSubscribeDeviceStatus();

  const calendarClickHandler = (): void => {
    if (isNotificationAvailable) {
      setNotificationBannerVisibility(prev => !prev);
    } else {
      history.push(AppRoutes.NOTIFICATION);
    }
  };

  return (
    <StyledAppBar {...props} elevation={0}>
      <StyledTopContainerBox justifyContent="space-between">
        <UIBox alignItems="flex-start">
          <StyledLogo />
          <StyledTextContainerBox flexDirection="column">
            <Typography size="xl">RFeyeD</Typography>
            {store && (
              <UIBox flexWrap="wrap">
                <Typography size="sm" font="heavy">
                  {store.storeDescription}
                </Typography>
                <Typography size="sm">
                  &nbsp;|&nbsp;{store.storeAddress}
                </Typography>
              </UIBox>
            )}
          </StyledTextContainerBox>
        </UIBox>
        <UIBox alignItems="center">
          <Tooltip
            title={t('connectionOffline') || ''}
            open={mqttConnectionStatus === 'DISCONNECTED'}
          >
            <StyledAntennaIconWrapper
              color={
                mqttConnectionStatus === 'CONNECTED' ? colors.green : colors.red
              }
            >
              <SettingsInputAntennaIcon />
            </StyledAntennaIconWrapper>
          </Tooltip>
          {(pathname === AppRoutes.INTRO ||
            pathname === AppRoutes.NOTIFICATION) && (
            <StyledCalendarIconBox
              onClick={calendarClickHandler}
              $active={pathname === AppRoutes.NOTIFICATION}
              marginRight={3}
            >
              {isNotificationAvailable && <StyledNotification />}
              <TodayIcon htmlColor={`${colors.primary}`} />
            </StyledCalendarIconBox>
          )}
          {loggedUser?.username ? (
            <UserInfo user={loggedUser} />
          ) : (
            <UILink to={AppRoutes.LOGIN}>{t('login')}</UILink>
          )}
        </UIBox>
      </StyledTopContainerBox>
      <StyledBottomContainerBox justifyContent="space-between">
        <UIBreadcrumbs items={breadcrumbsItems} />
        {isVirtualBuddy(loggedUser) &&
        pathname.indexOf(`${AppRoutes.INVENTORY_VIRTUAL_BUDDY}/`) > -1 ? (
          <UIBox alignItems="center">
            {inventoryStatus && inventoryStatus === 'Ongoing' ? (
              <UIBox alignItems="center">
                <Typography>{t('ongoing')}</Typography>
                <StyledIconWrapper>
                  <MoreHorizIcon htmlColor="#fff" />
                </StyledIconWrapper>
              </UIBox>
            ) : (
              <UIBox alignItems="center">
                <Typography margin="0 8px 0 0">
                  {t('pendingValidation')}
                </Typography>
                <WatchLaterIcon htmlColor={colors.primary} />
              </UIBox>
            )}
          </UIBox>
        ) : (
          <StyledDevicesStatusContainer>
            {isLoadingDeviceStatus ? (
              <UILoader linear size={20} width={150} />
            ) : (
              deviceList.map(
                ({ deviceId, status, userName, battery, nameDevice }) => (
                  <StyledDeviceInfo
                    easteregg
                    key={deviceId}
                    deviceId={deviceId}
                    deviceName={nameDevice || deviceId}
                    status={status}
                    userName={userName}
                    battery={battery}
                  />
                )
              )
            )}
          </StyledDevicesStatusContainer>
        )}
      </StyledBottomContainerBox>
      {isNotificationBannerVisible && <NotificationBanner />}
    </StyledAppBar>
  );
};

Header.displayName = 'Header';

export default Header;
