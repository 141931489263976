/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddEpcEncodedRequest } from '../models/AddEpcEncodedRequest';
import type { AddEpcRequest } from '../models/AddEpcRequest';
import type { BackToStockRequest } from '../models/BackToStockRequest';
import type { CloseDeliveryEncodedRequest } from '../models/CloseDeliveryEncodedRequest';
import type { CloseDeliveryRequest } from '../models/CloseDeliveryRequest';
import type { ConfirmDelivery } from '../models/ConfirmDelivery';
import type { ConfirmDeliveryEncode } from '../models/ConfirmDeliveryEncode';
import type { DeliveriesReport } from '../models/DeliveriesReport';
import type { DeliveryList } from '../models/DeliveryList';
import type { DeliveryStatus } from '../models/DeliveryStatus';
import type { GetDeliveriesRecapRequest } from '../models/GetDeliveriesRecapRequest';
import type { GetDeliveriesRecapResponse } from '../models/GetDeliveriesRecapResponse';
import type { GetDeliveryDetailsResponse } from '../models/GetDeliveryDetailsResponse';
import type { PrintTag } from '../models/PrintTag';
import type { ProductItem } from '../models/ProductItem';
import type { RecapConfirm } from '../models/RecapConfirm';
import type { RecapConfirmEncode } from '../models/RecapConfirmEncode';
import type { RecapConfirmList } from '../models/RecapConfirmList';
import type { ReceivingDownloadPdfResponse } from '../models/ReceivingDownloadPdfResponse';
import type { RemoveEpcEncodedRequest } from '../models/RemoveEpcEncodedRequest';
import type { RemoveEpcRequest } from '../models/RemoveEpcRequest';
import type { SearchDeliveryListScannedResponse } from '../models/SearchDeliveryListScannedResponse';
import type { SearchProductDeliveryItems } from '../models/SearchProductDeliveryItems';
import type { SearchProductDeliveryItemsForScan } from '../models/SearchProductDeliveryItemsForScan';
import type { SearchProductDeliveryItemsForScanEncoded } from '../models/SearchProductDeliveryItemsForScanEncoded';
import type { SearchProductProductDeliveryItemsResponse } from '../models/SearchProductProductDeliveryItemsResponse';
import type { StoreList } from '../models/StoreList';
import type { StoreWithPrinters } from '../models/StoreWithPrinters';
import { request as __request } from '../core/request';

export class ReceiveShipmentService {
  /**
   * Return list of store
   * Return list of store
   * @returns StoreList OK
   * @throws ApiError
   */
  public static async receiveshipGetStoreList(): Promise<StoreList> {
    const result = await __request({
      method: 'GET',
      path: `/receiveship/getStoreList`,
      errors: {
        400: `bad request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * SA will to find a Store StoreCode
   * By passing a itemCode, you can view the detail of the ProductItem
   * @returns StoreWithPrinters OK
   * @throws ApiError
   */
  public static async receiveshipReadStoreByStoreCode({
    storeCode,
  }: {
    /** filter for storeCode **/
    storeCode: any;
  }): Promise<StoreWithPrinters> {
    const result = await __request({
      method: 'GET',
      path: `/receiveship/readStoreByStoreCode`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `bad input parameter, itemCode is mandatory`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * SA must be able to select one or more deliveries from the list.
   * By passing in the appropriate options, you can search for delivery list, and we return a list of DeliveryItems sorted by date
   * @returns DeliveryList OK
   * @throws ApiError
   */
  public static async receiveshipGetDeliveryList({
    storeId,
    fromDate,
    status,
  }: {
    /** filter for storeId **/
    storeId: any;
    /** validity of delivery **/
    fromDate?: any;
    /** delivery status **/
    status?: 'New' | 'In Use' | 'Confirmed';
  }): Promise<DeliveryList> {
    const result = await __request({
      method: 'GET',
      path: `/receiveship/getDeliveryList`,
      query: {
        storeId: storeId,
        fromDate: fromDate,
        status: status,
      },
      errors: {
        400: `Bad request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * SA have to see all the information about items in the delivery selected
   * By passing in the appropriate options, you can search for delivery items and we return a list of ProductDeliveryItems. Note date format is yyyymmdd
   * @returns SearchProductProductDeliveryItemsResponse OK
   * @throws ApiError
   */
  public static async receiveshipSearchProductDeliveryItems({
    requestBody,
  }: {
    requestBody: SearchProductDeliveryItems;
  }): Promise<SearchProductProductDeliveryItemsResponse> {
    const result = await __request({
      method: 'POST',
      path: `/receiveship/searchProductDeliveryItems`,
      body: requestBody,
      errors: {
        400: `bad input parameter, List of deliveryCodes is mandatory`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * SA will get a card of glass with all information
   * By passing a itemCode, you can view the detail of the ProductItem
   * @returns ProductItem OK
   * @throws ApiError
   */
  public static async receiveshipGetProductItemDetails({
    itemCode,
  }: {
    /** filter for itemCode **/
    itemCode: any;
  }): Promise<ProductItem> {
    const result = await __request({
      method: 'GET',
      path: `/receiveship/getProductItemDetails`,
      query: {
        itemCode: itemCode,
      },
      errors: {
        400: `bad input parameter, itemCode is mandatory`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * 7. SA see the results of items scanned
   * searches deliveries for store by scanner
   * @returns SearchDeliveryListScannedResponse OK
   * @throws ApiError
   */
  public static async receiveshipSearchDeliveryListScanned({
    requestBody,
  }: {
    requestBody: SearchProductDeliveryItemsForScan;
  }): Promise<SearchDeliveryListScannedResponse> {
    const result = await __request({
      method: 'POST',
      path: `/receiveship/searchDeliveryListScanned`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * 7. SA see the results of items scanned
   * searches deliveries for store by scanner
   * @returns SearchDeliveryListScannedResponse OK
   * @throws ApiError
   */
  public static async receiveshipSearchDeliveryListScannedEncoded({
    requestBody,
  }: {
    requestBody: SearchProductDeliveryItemsForScanEncoded;
  }): Promise<SearchDeliveryListScannedResponse> {
    const result = await __request({
      method: 'POST',
      path: `/receiveship/searchDeliveryListScannedEncoded`,
      body: requestBody,
      errors: {
        400: `bad input parameter, codeScan is mandatory`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * SA wants to CONFIRM a delivery(s)
   * Confirm delivery after scan
   * @returns any Receive confirmed
   * @throws ApiError
   */
  public static async receiveshipConfirmDelivery({
    requestBody,
  }: {
    requestBody: ConfirmDelivery;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/receiveship/confirmDelivery`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * SA wants to CONFIRM a delivery(s)
   * Confirm delivery after scan
   * @returns any Receive confirmed
   * @throws ApiError
   */
  public static async receiveshipConfirmDeliveryEncoded({
    requestBody,
  }: {
    requestBody: ConfirmDeliveryEncode;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/receiveship/confirmDeliveryEncoded`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
      },
    });
    return result.body;
  }

  /**
   * SA wants to see reacp CONFIRM a delivery(s)
   * Recap Confirm delivery after scan
   * @returns RecapConfirmList OK
   * @throws ApiError
   */
  public static async receiveshipRecapConfirm({
    requestBody,
  }: {
    requestBody: RecapConfirm;
  }): Promise<RecapConfirmList> {
    const result = await __request({
      method: 'POST',
      path: `/receiveship/recapConfirm`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * SA wants to see reacp CONFIRM a delivery(s)
   * Recap Confirm delivery after scan
   * @returns RecapConfirmList OK
   * @throws ApiError
   */
  public static async receiveshipRecapConfirmEncoded({
    requestBody,
  }: {
    requestBody: RecapConfirmEncode;
  }): Promise<RecapConfirmList> {
    const result = await __request({
      method: 'POST',
      path: `/receiveship/recapConfirmEncoded`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * downLoadReport delivery management
   * downLoadReport delivery management
   * @returns DeliveriesReport OK
   * @throws ApiError
   */
  public static async receiveshipDownLoadReport(): Promise<DeliveriesReport> {
    const result = await __request({
      method: 'GET',
      path: `/receiveship/downLoadReport`,
      errors: {
        400: `Bad request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * downLoadReport receiving
   * downLoadReport receiving
   * @returns ReceivingDownloadPdfResponse OK
   * @throws ApiError
   */
  public static async receiveshipDownLoadReportReceiving({
    requestBody,
  }: {
    requestBody: RecapConfirm;
  }): Promise<ReceivingDownloadPdfResponse> {
    const result = await __request({
      method: 'POST',
      path: `/receiveship/downLoadReportReceiving`,
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Change delivery status
   * Change delivery status
   * @returns any Status Changed
   * @throws ApiError
   */
  public static async receiveshipChangeDeliveryStatus({
    requestBody,
  }: {
    requestBody: DeliveryStatus;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/receiveship/changeDeliveryStatus`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * service to obtain the delivery list for a specific store or a specific deliveryNr
   * service to obtain the delivery list for a specific store or a specific deliveryNr
   * @returns GetDeliveriesRecapResponse OK
   * @throws ApiError
   */
  public static async receiveshipGetDeliveriesRecap({
    requestBody,
  }: {
    requestBody: GetDeliveriesRecapRequest;
  }): Promise<GetDeliveriesRecapResponse> {
    const result = await __request({
      method: 'POST',
      path: `/receiveship/getDeliveriesRecap`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * service used to bring a stolen delivery back to store, therefore in a New state
   * service used to bring a stolen delivery back to store, therefore in a New state
   * @returns any Back To Store Operation Applied
   * @throws ApiError
   */
  public static async receiveshipBackToStore({
    requestBody,
  }: {
    requestBody: BackToStockRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/receiveship/backToStore`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * service that allows you to return the details of a delivery with the status of the epc
   * service that allows you to return the details of a delivery with the status of the epc
   * @returns GetDeliveryDetailsResponse OK
   * @throws ApiError
   */
  public static async receiveshipDeliveryDetails({
    storeCode,
    deliveryNumber,
  }: {
    /** filter for storeCode **/
    storeCode: any;
    /** filter for deliveryNumber **/
    deliveryNumber: any;
  }): Promise<GetDeliveryDetailsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/receiveship/deliveryDetails`,
      query: {
        storeCode: storeCode,
        deliveryNumber: deliveryNumber,
      },
      errors: {
        400: `bad input parameter, storeCode o deliveryNumber is mandatory`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * service used to close a delivery
   * service used to close a delivery
   * @returns any Close Delivery Operation Applied
   * @throws ApiError
   */
  public static async receiveshipCloseDelivery({
    requestBody,
  }: {
    requestBody: CloseDeliveryRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/receiveship/closeDelivery`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * service used to close a delivery
   * service used to close a delivery
   * @returns any Close Delivery Operation Applied
   * @throws ApiError
   */
  public static async receiveshipCloseDeliveryEncoded({
    requestBody,
  }: {
    requestBody: CloseDeliveryEncodedRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/receiveship/closeDeliveryEncoded`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * print Tag
   * print Tag
   * @returns any Print Saved, New EPC has been saved
   * @throws ApiError
   */
  public static async receiveshipPrintTag({
    requestBody,
  }: {
    requestBody: PrintTag;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/receiveship/printTag`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * add epc
   * add epc
   * @returns any Info Saved, New EPC has been saved
   * @throws ApiError
   */
  public static async receiveshipAddEpc({
    requestBody,
  }: {
    requestBody: AddEpcRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/receiveship/addEpc`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * add epc
   * add epc
   * @returns any Info Saved, New EPC has been saved
   * @throws ApiError
   */
  public static async receiveshipAddEpcEncoded({
    requestBody,
  }: {
    requestBody: AddEpcEncodedRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/receiveship/addEpcEncoded`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * add epc
   * add epc
   * @returns any Info Saved, New EPC has been saved
   * @throws ApiError
   */
  public static async receiveshipAddEpcEncodedTests({
    requestBody,
  }: {
    requestBody: AddEpcEncodedRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/receiveship/addEpcEncodedTests`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * remove Epc
   * remove epc
   * @returns any Info Saved, New EPC has been saved
   * @throws ApiError
   */
  public static async receiveshipRemoveEpc({
    requestBody,
  }: {
    requestBody: RemoveEpcRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/receiveship/removeEpc`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * remove Epc
   * remove epc
   * @returns any Info Saved, New EPC has been saved
   * @throws ApiError
   */
  public static async receiveshipRemoveEpcEncoded({
    requestBody,
  }: {
    requestBody: RemoveEpcEncodedRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/receiveship/removeEpcEncoded`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
