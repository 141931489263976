import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import ListItem from '@material-ui/core/ListItem';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { InventoyStatusValues } from '@/types/enum';
import { colors } from '@/theme';

export const StyledInventoryItem = styled(ListItem)`
  width: 100%;
  padding: 16px;
  background: #fbfbfb;
  border-radius: 4px;
  border: 1px solid #ededed;
  box-shadow: 0px 0px 10px 0px #0000001a;
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: space-between;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
    background: #ccc;
    border-radius: 100px;
  }
`;

export const StyledCalendarIcon = styled(CalendarTodayIcon)<{
  $inventoryStatus: InventoyStatusValues;
}>`
  margin-right: 8px;
  fill: ${({ $inventoryStatus }): string =>
    $inventoryStatus !== 'Validated' ? colors.primary : 'grey'};
`;

export const StyledStoreInfoWrapper = styled(UIBox)`
  margin-right: auto;
  margin-left: 64px;

  & > * {
    display: block;
    margin-right: 64px;
  }
`;

export const StyledActionWrapper = styled(UIBox)`
  margin-left: auto;
`;

export const StyledIconWrapper = styled(UIBox)`
  margin-left: 8px;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: ${colors.primary};
  margin-left: 8px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const StyledCircle = styled('div')<{ background?: string }>`
  margin-right: 8px;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  border: 1px solid #ededed;
  background: ${({ background }): string =>
    background ? background : colors.red};
`;

export const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  margin-left: 16px;
`;
