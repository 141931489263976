import React from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';

import { isVirtualBuddy } from '@/utils/user';
import { StyledCircle, StyledInventoryItem } from './style';
import { colors } from '@/theme';
import type { InventoryBrand } from '@/api/receive';

type InvetoryItemScanProps = {
  onClick?: (brand: string) => Promise<void> | void;
};

const InvetoryItemScan: React.FC<InventoryBrand & InvetoryItemScanProps> = ({
  brandName,
  notFound = 0,
  unexpected = 0,
  scannedTags,
  expected,
  onClick,
}) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const userCanClick = notFound > 0 || unexpected > 0;

  return (
    <StyledInventoryItem
      // the followed ignore fix the error in list item declaration
      // @ts-ignore
      button={userCanClick}
      onClick={(): Promise<void> | void =>
        userCanClick ? onClick?.(brandName || '') : undefined
      }
    >
      <UIBox width="100%" justifyContent="space-between">
        <Typography size="lg" font="heavy">
          {brandName}
        </Typography>
        {isVirtualBuddy(user) && (
          <UIBox alignItems="center" ml="auto" mr={8}>
            {notFound > 0 && (
              <UIBox alignItems="center">
                <StyledCircle />
                <Typography color="grey" margin="0 4px 0 0">
                  {t('notFound')}:
                </Typography>
                <Typography font="heavy">{notFound}</Typography>
              </UIBox>
            )}
            {unexpected > 0 && (
              <UIBox alignItems="center" ml={4}>
                <StyledCircle background={colors.yellow} />
                <Typography color="grey" margin="0 4px 0 0">
                  {t('unexpected')}:
                </Typography>
                <Typography font="heavy">{unexpected}</Typography>
              </UIBox>
            )}
          </UIBox>
        )}
        {isVirtualBuddy(user) ? (
          <Typography size="lg" font="heavy">
            {scannedTags} / {expected}
          </Typography>
        ) : (
          <Typography size="lg" font="heavy">
            {scannedTags}
          </Typography>
        )}
      </UIBox>
    </StyledInventoryItem>
  );
};

export default InvetoryItemScan;
