import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import ActionGroup from '../ActionGroup';

import { tagsMQTTDevice } from '@/features/devices/devicesSlice';
import { ScanDevicesService } from '@/api';

import {
  StyledHeader,
  StyledHeaderContent,
  StyledScanStatusWrapper,
  StyledSpan,
  StyledTab,
  StyledTabBadge,
  StyledTabWrapper,
  StyledActionWrapper,
} from './style';

import { colors } from '@/theme';
import { Product, ProductValues } from '@/types/enum';
import { useMediaQuery } from '@material-ui/core';

type StatusBarProps = {
  tabsDisabled?: boolean;
  inPause: boolean;
  setPause: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTab: ProductValues | undefined;
  setSelectedTab: React.Dispatch<
    React.SetStateAction<ProductValues | undefined>
  >;
};

const StatusBar = ({
  inPause,
  setPause,
  selectedTab,
  setSelectedTab,
  tabsDisabled,
}: StatusBarProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isIPad = useMediaQuery('(max-width:1110px)');

  const { selectedDeliveries } = useSelector(state => state.deliveries);
  const { deviceInUse } = useSelector(state => state.devices);

  const { found, missing, sold, unexpected } = useSelector(
    state => state.scannedProducts.products
  );

  const selectedDeliveriesCodes = selectedDeliveries.map(
    item => item.codeDelivery
  );

  const shipmentQty = selectedDeliveries.reduce((a, b) => a + b.numberItems, 0);
  const totalFound = (found?.qty || 0) + (sold?.qty || 0);

  const tabs_missing = [
    {
      tab: Product.NOT_FOUND,
      label: t('notFound'),
      count: missing.qty,
      badgeColor: colors.red,
    },
    {
      tab: Product.UNEXPECTED,
      label: t('unexpected'),
      count: unexpected.qty,
      badgeColor: colors.yellow,
    },
  ];

  const tabs_found = [
    {
      tab: Product.FOUND,
      label: t('found'),
      count: found.qty,
      badgeColor: colors.green,
    },
    {
      tab: Product.SOLD,
      label: t('sold'),
      count: sold.qty,
      badgeColor: colors.grey,
    },
  ];

  const onUpdateClick = async (): Promise<void> => {
    const { tags: deviceTags } = await ScanDevicesService.getTags({
      devId: deviceInUse?.deviceId!,
    });

    dispatch(tagsMQTTDevice(deviceTags || []));
  };

  return (
    <StyledHeader>
      <StyledHeaderContent>
        <UIBox alignItems="center">
          <Typography size="xs" font="heavy">
            {selectedDeliveries.length > 1
              ? t('list.multipleDeliveries') + `: #${selectedDeliveriesCodes}`
              : t('list.delivery') + `: #${selectedDeliveriesCodes}`}
          </Typography>
        </UIBox>
        <StyledScanStatusWrapper>
          <UIBox>
            <UIBox
              alignItems="center"
              justifyContent="center"
              paddingTop={isIPad ? '0' : '20px'}
            >
              <Typography color="grey" font="heavy">
                {t('totalFound')} /&nbsp;
              </Typography>
              <Typography color="primary" font="heavy">
                {t('shipmentQty')}
              </Typography>
              <Typography size="xl" font="heavy" margin="0 0 0 8px">
                {totalFound} / {shipmentQty}
              </Typography>
            </UIBox>

            {/* <StyledActionWrapper className="ipad-visible">
              <ActionGroup
                inPause={inPause}
                setPause={setPause}
                onUpdateClick={onUpdateClick}
              />
            </StyledActionWrapper> */}
          </UIBox>
          <StyledTabWrapper>
            <UIBox gridGap="40px">
              <UIBox
                alignItems="center"
                padding="4px 12px"
                flexDirection="column"
              >
                <UIBox>
                  <StyledSpan color="grey" size="xs" margin="4px 12px 4px 0">
                    &nbsp;
                  </StyledSpan>
                </UIBox>
                <UIBox>
                  {tabs_missing.map(
                    ({ label, tab, count, badgeColor }, index) => (
                      <StyledTab
                        key={index}
                        $disabled={tabsDisabled}
                        $selected={tab === selectedTab}
                        onClick={(): void => setSelectedTab(tab)}
                      >
                        <Typography color="grey" font="heavy" size="xs">
                          {label}
                        </Typography>
                        <Typography size="xl" font="heavy" margin="8px 0">
                          {count}
                        </Typography>
                        <StyledTabBadge color={badgeColor} />
                      </StyledTab>
                    )
                  )}
                </UIBox>
              </UIBox>
              <UIBox
                alignItems="center"
                border="1px solid #E9EDF4"
                borderRadius="4px"
                padding="4px 12px"
                flexDirection="column"
              >
                <UIBox>
                  <StyledSpan color="grey" size="xs" margin="4px 12px 4px 0">
                    {t('totalFound')}
                  </StyledSpan>
                </UIBox>
                <UIBox>
                  {tabs_found.map(
                    ({ label, tab, count, badgeColor }, index) => (
                      <StyledTab
                        key={index}
                        $disabled={tabsDisabled}
                        $selected={tab === selectedTab}
                        onClick={(): void => setSelectedTab(tab)}
                      >
                        <Typography color="grey" font="heavy" size="xs">
                          {label}
                        </Typography>
                        <Typography size="xl" font="heavy" margin="8px 0">
                          {count}
                        </Typography>
                        <StyledTabBadge color={badgeColor} />
                      </StyledTab>
                    )
                  )}
                </UIBox>
              </UIBox>
            </UIBox>

            <StyledActionWrapper
              className={isIPad ? 'ipad-visible' : 'desktop-visible'}
            >
              <ActionGroup
                inPause={inPause}
                setPause={setPause}
                onUpdateClick={onUpdateClick}
              />
            </StyledActionWrapper>
          </StyledTabWrapper>
        </StyledScanStatusWrapper>
      </StyledHeaderContent>
    </StyledHeader>
  );
};

export default StatusBar;
