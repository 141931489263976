import styled from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { ProductAccordion } from '@/components/layout/ProductAccordion';
import { Typography } from '@/components/ui/Typography';

const paddingLeft = '173px';

export const StyledProductItemWrapper = styled(UIBox)`
  flex-direction: column;

  &:not(:last-of-type) {
    border-bottom: 1px solid #ededed;
  }
`;

export const StyledProductAccordion = styled(ProductAccordion)`
  padding: 0 0 12px ${paddingLeft};

  & > * {
    &:first-child {
      // Needed since the StyledProductAccordion v2 prop is ineffective
      display: none;
    }
  }
`;

export const StyledAccortionItemContent = styled(UIBox)`
  align-items: center;
  justify-content: space-between;
`;

export const StyledMessageWrapper = styled(UIBox)`
  padding-left: ${paddingLeft};
  padding-right: 24px;
  align-items: center;
  justify-content: space-between;
`;

export const StyledMessage = styled(Typography)`
  && {
    margin-right: 24px;
    margin-bottom: 16px;
  }
`;
