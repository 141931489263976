import React from 'react';
import { useSelector } from '@/hooks/useSelector';
import { ConfirmCycleCount, ConfirmStockOnHand } from '@/api/receive';

type NN<T> = NonNullable<T>;
type FoundCycleCount = NN<ConfirmCycleCount['epcFoundCycleCountList']>;
type FoundStockOnHand = NN<ConfirmStockOnHand['epcFound']>;
type Found = FoundCycleCount | FoundStockOnHand;

const useFoundRequestBody = (): Found => {
  const { found } = useSelector(state => state.cycleCount.products);
  const { unexpectedAdded, notFoundPrinted } = useSelector(
    state => state.cycleCount
  );

  const body = React.useMemo(() => {
    return found.map(product => ({
      upcCodeFound: product.upcCode || '',
      epcFoundQuantity: product.epcCodes.length || 0,
      epcFound:
        product.epcCodes.map(({ epcCode }) => {
          const isInUnexpected = unexpectedAdded.find(
            ({ upcCodeFound }) => upcCodeFound === product.upcCode
          );

          const isInNotFoundPrinted = notFoundPrinted.find(
            ({ upcCodeFound }) => upcCodeFound === product.upcCode
          );

          if (isInNotFoundPrinted) {
            const epc = isInNotFoundPrinted.epcFound.find(
              obj => obj.epcFoundCodeNew === epcCode
            );

            if (epc) {
              return epc;
            }
          }

          if (isInUnexpected) {
            const epc = isInUnexpected.epcFound.find(
              obj => obj.epcFoundCodeOld === epcCode
            );

            if (epc) {
              return epc;
            }
          }

          return {
            epcFoundCodeOld: epcCode,
          };
        }) || [],
    }));
  }, [found, notFoundPrinted, unexpectedAdded]);

  return body;
};

export default useFoundRequestBody;
