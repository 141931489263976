import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDebounce } from 'react-use';

import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  AutocompleteInputChangeReason,
  UseAutocompleteProps,
} from '@material-ui/lab/useAutocomplete';
interface UIAutocompleteProps {
  options: string[];
  searchTerm: string;
  getOptions: () => Promise<void>;
  resetValue?: () => void;
  width?: number;
  onOptionSelect?: (
    e: React.ChangeEvent<{}> | React.MouseEvent,
    value: string | null
  ) => void | Promise<void>;
  noOptionsText?: string;
  loadingStatus?: boolean;
  loadingText?: string;
  minSearchTermLength?: number;
  disableClearable?: boolean;
  rounded?: boolean;
  clearOnBlur?: boolean;
  prevValue?: string;
  onInputChange?: (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  onFocus?: React.FocusEventHandler<HTMLDivElement> | undefined;
}

const StyledAutocompleteWrapper = styled('div')<{ $rounded: boolean }>`
  && {
    fieldset {
      border-radius: ${({ $rounded }): string => ($rounded ? '100px' : '4px')};
    }
  }
`;

const UIAutocomplete: React.FC<UIAutocompleteProps & TextFieldProps> = ({
  options,
  getOptions,
  searchTerm,
  onChange,
  resetValue,
  error,
  label,
  noOptionsText,
  helperText,
  loadingText,
  onOptionSelect,
  minSearchTermLength,
  width,
  onFocus,
  className,
  loadingStatus = false,
  rounded = false,
  disableClearable = false,
  clearOnBlur = false,
  inputRef,
  prevValue,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>(prevValue ?? '');
  const [loading, setLoading] = useState<boolean>(loadingStatus);

  useDebounce(
    async () => {
      if (open && searchTerm.length >= 1) {
        setLoading(true);
        await getOptions();
        setLoading(false);
      }

      return (): void => setLoading(false);
    },
    1250,
    [searchTerm]
  );

  useEffect(() => {
    if (minSearchTermLength) {
      if (searchTerm.length >= minSearchTermLength) {
        setOpen(true);
      }
    }
  }, [minSearchTermLength, searchTerm]);

  useEffect(() => {
    if (error) {
      setOpen(false);
      setLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (!open) {
      setLoading(false);
    }
  }, [open]);

  const onAutoCompleteChange: UseAutocompleteProps<
    string,
    false,
    false,
    false
  >['onChange'] = (e, value) => {
    if (value) {
      onOptionSelect?.(e, value);
      setOpen(false);
      setValue(value);
    }

    setValue('');
  };

  return (
    <StyledAutocompleteWrapper $rounded={rounded}>
      <Autocomplete
        className={className}
        id="asynchronous-autocomplete"
        value={value}
        loading={loading}
        options={[value, ...options]}
        filterOptions={(): string[] => options}
        onFocus={onFocus}
        onInputChange={(_e, reason): void => {
          if (reason === 'clear') {
            setValue('');
            resetValue?.();
            return;
          }
        }}
        open={open}
        onOpen={minSearchTermLength ? undefined : (): void => setOpen(true)}
        onClose={(): void => setOpen(false)}
        onChange={onAutoCompleteChange}
        getOptionSelected={(option, value): boolean => option === value}
        getOptionLabel={(option): string => (option ? option : '')}
        noOptionsText={noOptionsText}
        loadingText={loadingText}
        disableClearable={disableClearable}
        clearOnBlur={clearOnBlur}
        style={{ width: width || 300 }}
        renderInput={(params): JSX.Element => (
          <>
            <TextField
              {...params}
              label={label}
              variant="outlined"
              onChange={onChange}
              error={error}
              helperText={helperText}
              onFocus={(): void => setOpen(true)}
              inputRef={inputRef}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          </>
        )}
      />
    </StyledAutocompleteWrapper>
  );
};

export default UIAutocomplete;
