import { FC, useState, useEffect } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { DeviceInfoCheckbox } from '@/components/layout/DeviceinfoCheckbox';
import { ScanDevice } from '@/types/device';

import { UILoader } from '@/components/ui/Loader';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import {
  StyledColumnDevice,
  StyledContent,
  StyledDeviceInfoWrapper,
  StyledReleaseButtonWrapper,
  StyledUILoaderWrapper,
} from './styles';
import { useSubscribeDeviceStatus } from '@/hooks/mqtt/useSubscribeDeviceStatus';
import { useSubscribeEpcs } from '@/hooks/mqtt/useSubscribeEpcs';
import { useAppDispatch } from '@/app/store';
import { removeDeviceInUse } from '@/features/devices/devicesSlice';
import { useTranslation } from 'react-i18next';
import { UIButton } from '@/components/ui/Button';

export interface SelectDeviceProps {
  selectedDevice?: ScanDevice;
  setDevice: React.Dispatch<React.SetStateAction<ScanDevice | undefined>>;
  scanContinue?: boolean;
  title?: string;
  $v2?: boolean;
  hiddenDevices?: boolean;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}
const ColumnDevice: FC<SelectDeviceProps> = ({
  children,
  selectedDevice,
  setDevice,
  scanContinue,
  hiddenDevices,
  // setActiveStep,
  $v2 = true,
}): JSX.Element => {
  const [isSelected, setIsSelected] = useState(false);
  const [forceUnlock, setForceUnlock] = useState(false);

  const { deviceInUse } = useSelector(state => state.devices);

  const { deviceList, isLoadingDeviceStatus } = useSelector(
    state => state.devices
  );

  const subscribeDevice = useSubscribeDeviceStatus();
  const subscribeEPCS = useSubscribeEpcs(deviceInUse?.deviceId!);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const removeDeviceClickHandler = (): void => {
    setForceUnlock(true);
    subscribeDevice.publish(deviceInUse?.deviceId!, undefined);
    subscribeEPCS.unsubscribe();
    dispatch(removeDeviceInUse());
  };

  const onDeviceChange = (dev: ScanDevice): void => {
    setDevice(dev);
  };

  useEffect(() => {
    if (deviceList && !isSelected) {
      const clickableRadio: HTMLElement | null = document.querySelector(
        '*[data-status="online"] input'
      );

      if (clickableRadio) {
        clickableRadio?.click();
        setIsSelected(true);
      }
    }
  }, [deviceInUse, deviceList, isSelected]);

  return (
    <StyledColumnDevice>
      <StyledContent>
        {isLoadingDeviceStatus ? (
          <StyledUILoaderWrapper>
            <UILoader />
          </StyledUILoaderWrapper>
        ) : (
          <>
            {scanContinue && (
              <StyledReleaseButtonWrapper>
                <UIButton
                  color="primary"
                  onClick={removeDeviceClickHandler}
                  label={t('device.release')}
                />
              </StyledReleaseButtonWrapper>
            )}
            {!hiddenDevices &&
              deviceList.map(
                (
                  {
                    status,
                    battery,
                    deviceId,
                    userName,
                    nameDevice,
                    mode,
                    num_tag,
                    storeId,
                  },
                  i
                ) => (
                  <StyledDeviceInfoWrapper key={`${deviceId}-${i}`}>
                    <DeviceInfoCheckbox
                      status={status}
                      battery={battery}
                      deviceName={nameDevice || deviceId}
                      userName={userName}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleChecked />}
                      checked={
                        status === 'offline'
                          ? false
                          : deviceInUse
                          ? deviceInUse.deviceId === deviceId
                          : selectedDevice?.deviceId === deviceId
                      }
                      disabled={
                        !(forceUnlock && status === 'online') &&
                        ((scanContinue &&
                          selectedDevice?.deviceId !== '' &&
                          selectedDevice?.deviceId !== deviceId) ||
                          status !== 'online')
                      }
                      // onClick={(): void => setActiveStep(1)}
                      onChange={(): void =>
                        onDeviceChange({
                          status,
                          battery,
                          deviceId,
                          userName,
                          nameDevice,
                          mode,
                          num_tag,
                          storeId,
                        })
                      }
                      data-status={status}
                      $v2={$v2}
                    />
                  </StyledDeviceInfoWrapper>
                )
              )}
          </>
        )}
        {children}
      </StyledContent>
    </StyledColumnDevice>
  );
};

export default ColumnDevice;
