import { FC, memo } from 'react';
import { useSelector } from '@/hooks/useSelector';
import {
  StyledMultiProgressBar,
  StyledMultiProgressBarInfo,
  StyledMultiProgressBarWrapper,
  StyledMultiProgressBarInnerWrapper,
  StyledScannedStickyBar,
  StyledNotFoundBar,
  SyledFoundBar,
  StyledUnexpectedBar,
} from './styles';
import { Typography } from '@/components/ui/Typography';
import { useTranslation } from 'react-i18next';
export interface MultiProgressBarProps {
  foundItemsCount: number;
  notFoundItemsCount: number;
  unexpectedItemsCount: number;
  sticky?: boolean;
}

export const MultiProgressBar: FC<MultiProgressBarProps> = memo(
  ({
    sticky,
    foundItemsCount,
    notFoundItemsCount,
    unexpectedItemsCount,
  }): JSX.Element => {
    const { t } = useTranslation();
    const totalItems = foundItemsCount + unexpectedItemsCount;

    const { selectedDeliveries } = useSelector(state => state.deliveries);

    const selectedDeliveriesItemsCount = selectedDeliveries.reduce(
      (a, b) => Number(a) + Number(b.numberItems),
      0
    );

    const selectedDeliveriesEpcQuantity = selectedDeliveries.reduce(
      (prev, curr) => prev + Number(curr.numberSoldItems),
      0
    );

    return (
      <StyledMultiProgressBar>
        {!sticky && (
          <StyledMultiProgressBarInfo>
            <Typography as="p" size="sm" font="heavy">
              <span>
                {t('shipmentQty', {
                  count: Number(selectedDeliveriesItemsCount),
                })}
              </span>
            </Typography>
            <Typography as="p" size="sm" font="heavy">
              <span>
                {t('expectedQty', {
                  count: Number(selectedDeliveriesEpcQuantity),
                })}
              </span>
            </Typography>
            <Typography as="p" size="sm" font="heavy">
              <span>
                {t('receivedQty', { count: Number(totalItems) })}/
                {selectedDeliveriesItemsCount}
              </span>
            </Typography>
          </StyledMultiProgressBarInfo>
        )}
        {sticky ? (
          <StyledScannedStickyBar>
            <Typography as="p" size="sm" font="heavy">
              <span>
                {t('shipmentQty', {
                  count: Number(selectedDeliveriesItemsCount),
                })}
              </span>
            </Typography>
            <Typography as="p" size="sm" font="heavy">
              <span>
                {t('expectedQty', {
                  count: Number(selectedDeliveriesEpcQuantity),
                })}
              </span>
            </Typography>
            <StyledMultiProgressBarInnerWrapper $sticky={sticky}>
              <SyledFoundBar
                found={foundItemsCount}
                notFound={notFoundItemsCount}
                unexpected={unexpectedItemsCount}
              />
              <StyledNotFoundBar />
              <StyledUnexpectedBar
                found={foundItemsCount}
                notFound={notFoundItemsCount}
                unexpected={unexpectedItemsCount}
              />
            </StyledMultiProgressBarInnerWrapper>
            <Typography as="p" size="sm" font="heavy">
              {t('receivedQty')} {totalItems}/{selectedDeliveriesItemsCount}
            </Typography>
          </StyledScannedStickyBar>
        ) : (
          <StyledMultiProgressBarWrapper>
            <StyledMultiProgressBarInnerWrapper flex="1">
              <SyledFoundBar
                found={foundItemsCount}
                notFound={notFoundItemsCount}
                unexpected={unexpectedItemsCount}
              />
              <StyledNotFoundBar />
              <StyledUnexpectedBar
                found={foundItemsCount}
                notFound={notFoundItemsCount}
                unexpected={unexpectedItemsCount}
              />
            </StyledMultiProgressBarInnerWrapper>
            <Typography as="p" size="sm" font="heavy">
              <span>
                {t('scannedQty', {
                  count: Number(totalItems),
                })}
                /{selectedDeliveriesEpcQuantity}
              </span>
            </Typography>
          </StyledMultiProgressBarWrapper>
        )}
      </StyledMultiProgressBar>
    );
  }
);

MultiProgressBar.displayName = 'MultiProgressBar';

export default MultiProgressBar;
