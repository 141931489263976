import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';
import { StoreFilters } from '@/types/filters';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { AppRoutes } from '@/app/routers';
import { useHistory } from 'react-router';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';
import { fetchStores } from '@/features/profiling/storesSlice';
import { useAsync } from 'react-use';
import ProfilingStoreList from '@/components/layout/ProfilingStoreList/ProfilingStoreList';
import { Store } from '@/api';
import { StyledFilterWrapper, StyledWrapper } from './style';

const PageControlStores: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [alertSnackbarIsVisible, setAlertSnackbarVisibility] =
    useState<boolean>(false);
  const [filters, setFilters] = useState<StoreFilters>({
    sales: '',
    storeCode: '',
  });
  const [listCurrentPage, setListCurrentPage] = useState<number>(1);
  const [paginationStores, setPaginationStores] = useState<Store[]>([]);

  const { stores, fetchStoresHasError } = useSelector(
    state => state.profiling.stores
  );

  useAsync(async () => {
    await dispatch(fetchStores());
  }, [dispatch]);

  useEffect(() => {
    const { sales, storeCode } = filters;

    if (sales === '' && storeCode === '') {
      setPaginationStores(stores);
    } else {
      setPaginationStores(() => {
        return stores.filter(store => {
          if (sales !== '' && storeCode !== '')
            return (
              store.storeCode
                ?.toLowerCase()
                .includes(storeCode.toLowerCase()) &&
              store.salesOrganization
                ?.toLowerCase()
                .includes(sales.toLowerCase())
            );
          else if (sales !== '')
            return store.salesOrganization
              ?.toLowerCase()
              .includes(sales.toLowerCase());

          return store.storeCode
            ?.toLowerCase()
            .includes(storeCode.toLowerCase());
        });
      });
    }
  }, [filters, stores]);

  const onFilterChange = (
    key: keyof StoreFilters,
    value: string | 'all'
  ): void => {
    setFilters(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <>
      <StyledFilterWrapper>
        <SearchBarLight
          label=""
          value={filters.sales}
          placeholder={t('profiling.filterBySalesOrganizations')}
          disabled={false}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onFilterChange('sales', e.target.value)
          }
          loading={false}
          hideButton={true}
        />
        <SearchBarLight
          label=""
          value={filters.storeCode}
          placeholder={t('profiling.filterByStoreCode')}
          disabled={false}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onFilterChange('storeCode', e.target.value)
          }
          loading={false}
          hideButton={true}
        />
      </StyledFilterWrapper>

      <StyledWrapper>
        <ProfilingStoreList
          paginationStores={paginationStores}
          listCurrentPage={listCurrentPage}
          setListCurrentPage={setListCurrentPage}
        />
      </StyledWrapper>

      <CTAContainer
        type="BACK"
        onClick={(): void => {
          history.push(AppRoutes.ADMIN);
        }}
      />

      <AlertSnackbar
        open={alertSnackbarIsVisible && !!fetchStoresHasError}
        setIsOpen={setAlertSnackbarVisibility}
        message={fetchStoresHasError?.body}
      />
    </>
  );
};

export default PageControlStores;
