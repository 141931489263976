import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';
import styled from 'styled-components';

import { Typography } from '@/components/ui/Typography';
import { UIBox } from '@/components/ui/Box';
import { theme } from '@/theme';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { ModalScanDetails } from '@/components/layout/ModalScanDetails';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { ModalDataSaved } from '@/components/layout/ModalDataSaved';
import { UIButton } from '@/components/ui/Button';

import { MigrationService, ScanDevicesService } from '@/api';
import { deleteTags, tagsMQTTDevice } from '@/features/devices/devicesSlice';
import { AppRoutes } from '@/app/routers';
import { EnumMode } from '@/types/enum';
import { useTranslation } from 'react-i18next';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';

const SyledContainer = styled(UIBox)`
  max-width: ${theme.breakpoints.values.lg}px;
  margin: auto auto 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    &:first-of-type {
      margin: 4vh 0 3vh;
    }

    &:last-of-type {
      font-size: 36px;
    }
  }
`;

const SyledDataMigrationContainer = styled(UIBox)`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-bottom: 28px;
  }
`;

const PagePocMigration: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  // ** Scan details modal
  const [openScanModal, setScanModalVisibility] = useState(false);

  // ** Modal Data Saved
  const [isConfirmModalVisible, setConfirmModalVisibility] =
    useState<boolean>(false);

  const [finishButtonClicked, setFinishButtonClicked] =
    useState<boolean>(false);
  const [deviceError, setDeviceError] = useState<boolean>(false);
  const [deviceErrorMsg, setDeviceErrorMsg] = useState<string>();

  const [isScanCompleted, setIsScanCompleted] = useState<boolean>(false);
  const [isScanningAgain, setIsScanningAgain] = useState<boolean>(false);
  const { store } = useSelector(state => state.currentStore);
  const { tags } = useSelector(state => state.devices);
  const { deviceInUse, deleteTagsHasError } = useSelector(
    state => state.devices
  );

  useBreadcrumbs([
    {
      title: t('page.poc'),
    },
  ]);

  useEffect(() => {
    if (deleteTagsHasError) {
      setDeviceError(true);
      setDeviceErrorMsg(deleteTagsHasError.body.message);
    }
  }, [deleteTagsHasError]);

  const onCheckTagClick = async (): Promise<void> => {
    if (store?.storeCode) {
      const { csvFile, nameFile } = await MigrationService.migrationCheckTags({
        requestBody: {
          storeCode: store.storeCode,
          epcCodes: tags.map(epc => epc),
        },
      });

      const linkSource = `data:application/csv;base64,${csvFile}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = nameFile;
      downloadLink.click();
    }
  };

  const onConfirmClick = async (): Promise<void> => {
    if (store?.storeCode) {
      await MigrationService.migrationMigrationStock({
        requestBody: {
          storeCode: store.storeCode,
          epcCodes: tags.map(epc => epc),
        },
      });
      setConfirmModalVisibility(true);
    }
  };

  const onFinishScanClick = async (): Promise<void> => {
    try {
      setFinishButtonClicked(true);

      const { tags: deviceTags, mode } = await ScanDevicesService.getTags({
        devId: deviceInUse?.deviceId!,
      });

      if (mode === 'no mode') {
        setFinishButtonClicked(false);
        setDeviceErrorMsg(t('error.deviceCommunication'));
        return setDeviceError(true);
      }

      if (deviceTags && deviceTags.length > 0) {
        await dispatch(tagsMQTTDevice(deviceTags));
      }

      setTimeout(async () => {
        const { code, message } = await ScanDevicesService.activateScanning({
          requestBody: {
            dev_id: deviceInUse?.deviceId!,
            enable: 'False',
          },
        });

        if (code !== 'OK') {
          setDeviceErrorMsg(message);
          setFinishButtonClicked(false);
          return setDeviceError(true);
        }
      }, 1000);

      setTimeout(async () => {
        await dispatch(deleteTags());

        setIsScanCompleted(true);
        setFinishButtonClicked(false);
      }, 2000);
    } catch {
      setFinishButtonClicked(false);
      setDeviceError(true);
    }
  };

  const onScanClick = async (): Promise<void> => {
    setIsScanningAgain(true);
    setScanModalVisibility(true);
  };

  const onCloseModalDataSavedClick = (): void => {
    setConfirmModalVisibility(false);
    history.push(AppRoutes.INTRO);
  };

  return (
    <>
      <ModalDataSaved
        open={isConfirmModalVisible}
        onClose={onCloseModalDataSavedClick}
        message={t('modal.datasaved')}
      />
      <ModalScanDetails
        open={openScanModal}
        hideModes={[EnumMode.FIND]}
        isScanningAgain={isScanningAgain}
        setIsScanCompleted={setIsScanCompleted}
        onClose={(): void => setScanModalVisibility(false)}
      />
      <SyledContainer>
        <Typography size="lg" as="h1">
          {t('page.poc')}
        </Typography>
        <Typography size="lg" as="h1">
          {t('poc.total.tags.read')}
        </Typography>
        <Typography size="lg" as="h1">
          {tags.length}
        </Typography>
        {isScanCompleted && (
          <SyledDataMigrationContainer>
            <Typography size="lg" font="light" as="h1">
              {t('poc.download.file')}
            </Typography>
            <UIButton label={t('poc.checkTag')} onClick={onCheckTagClick} />
          </SyledDataMigrationContainer>
        )}
      </SyledContainer>
      {!isScanCompleted ? (
        <CTAContainer
          type="FINISH SCAN"
          onClick={onFinishScanClick}
          loading={finishButtonClicked}
        />
      ) : (
        <CTAContainer
          type="DEFAULT"
          onQuitClick={(): void => history.push(AppRoutes.INTRO)}
          onScanClick={onScanClick}
          onConfirmClick={onConfirmClick}
        />
      )}
      <ErrorSnackbar
        open={deviceError}
        setIsOpen={setDeviceError}
        errorMessage={deviceErrorMsg}
      />
    </>
  );
};

export default PagePocMigration;
