import { FC } from 'react';
import styled from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useTranslation } from 'react-i18next';
import { UIWarning } from '@/components/ui/Warning';

interface ProductAccordionItemProps {
  className?: string;
  epcCode: string;
  timestamp?: string;
  sold?: boolean;
  lastStatusUpdateProcess?: string;
}

const StyledItemWrapper = styled(UIBox)`
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 200px auto auto;
  gap: 40px;
`;

const StyledActionWrapper = styled(UIBox)`
  display: inline-flex;
  align-items: center;
  gap: 20px;
  margin-right: -95px;
  justify-self: end;
`;

const TimestampWrapper = styled(Typography)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ProductAccordionItem: FC<ProductAccordionItemProps> = ({
  epcCode,
  className,
  children,
  timestamp,
  sold,
  lastStatusUpdateProcess,
}) => {
  const { t } = useTranslation();

  return (
    <StyledItemWrapper className={className}>
      {epcCode}
      {timestamp && (
        <TimestampWrapper as="div">
          <AccessTimeIcon />
          <span>{timestamp}</span>
        </TimestampWrapper>
      )}
      {sold && (
        <UIWarning
          label={t('sale.alreadysold', {
            process: t(lastStatusUpdateProcess || ''),
          })}
        />
      )}
      {!timestamp && !sold && <div>&nbsp;</div>}
      <StyledActionWrapper>{children}</StyledActionWrapper>
    </StyledItemWrapper>
  );
};

export default ProductAccordionItem;
