import { FC } from 'react';
import styled, { CSSProperties } from 'styled-components';
interface StyledFormProps {
  margin?: CSSProperties['margin'];
}

//#region - Styled Components
const SyledForm = styled('form')<StyledFormProps>`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin: ${({ margin }): CSSProperties['margin'] =>
    margin ? margin : '0 0 100px 0'};
`;
//#endregion

const Form: FC<StyledFormProps> = ({ children, margin }) => {
  return <SyledForm margin={margin}>{children}</SyledForm>;
};

export default Form;
