import React, { FC } from 'react';
import { Tab, Tabs as MuiTabs } from '@material-ui/core';
import styled, { CSSProperties } from 'styled-components';
interface TabsProps {
  values: string[];
  selectedTab?: number;
  setSelectedTab?: React.Dispatch<React.SetStateAction<number>>;
  onTabChange?: (index: number) => void;
  disabledTabs?: number[];
}

interface StyledTabsProps {
  mt?: CSSProperties['marginTop'];
}

const StyledTabsContainer = styled(MuiTabs)<StyledTabsProps>`
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  margin-top: ${({ mt }): CSSProperties['marginTop'] => (mt ? mt : '0')};
`;
const StyledLabelWrapper = styled(Tab)<{ selected?: boolean }>`
  padding: 14px 21px;
  color: ${({ selected }): string => (selected ? '#005192' : '#929496')};
  text-transform: capitalize;
  border-bottom: ${({ selected }): string =>
    selected ? '1px solid #005192' : 'none'};
`;
const Tabs: FC<TabsProps & StyledTabsProps> = ({
  values,
  selectedTab,
  setSelectedTab,
  disabledTabs,
  onTabChange,
  ...styledProps
}) => {
  const onValueClick = (index: number): void => {
    if (onTabChange) {
      return onTabChange(index);
    }

    setSelectedTab?.(index);
  };
  return (
    <StyledTabsContainer
      value={selectedTab}
      {...styledProps}
      scrollButtons="auto"
    >
      {values.map((value, index) => (
        <StyledLabelWrapper
          key={`${value}-${index}`}
          label={value}
          value={index}
          onClick={(): void => onValueClick(index)}
          selected={selectedTab === index}
          disabled={disabledTabs?.includes(index)}
        />
      ))}
    </StyledTabsContainer>
  );
};

export default Tabs;
