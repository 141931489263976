import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { UIAction } from '@/components/ui/Action';
import { ProductItemWrapper } from '@/components/layout/ProductItemWrapper';
import { ProductImage } from '@/components/layout/ProductImage';
import { ProductDescription } from '@/components/layout/ProductDescription';
import { ProductAccordion } from '@/components/layout/ProductAccordion';
import { ProductAccordionItem } from '@/components/layout/ProdocutAccordionItem';
import { Typography } from '@/components/ui/Typography';

import { StyledProductItemWrapper } from './style';
import type { InventoryOwnerInfoUpc } from '@/api/receive';
import { UIBox } from '@/components/ui/Box';

interface InventoryReportListItemProps extends InventoryOwnerInfoUpc {
  brandName?: string;
  actionClickHandler?: (epc: string) => void;
  actionDisabled?: boolean;
}

const InventoryReportListItem: React.FC<InventoryReportListItemProps> = ({
  actionDisabled,
  actionClickHandler,
  imageUrl,
  epcs,
  brandName,
  styleName,
  upcCode,
  modelCode,
  commodity,
  numberItems,
}) => {
  const { t } = useTranslation();
  const [$isAccordionExpanded, toggleAccordion] = useToggle(false);

  return (
    <StyledProductItemWrapper>
      <ProductItemWrapper $notFound>
        <ProductImage
          $v2={epcs && epcs.length > 1}
          imageUrl={imageUrl}
          $isAccordionExpanded={$isAccordionExpanded}
          onClickAccordion={toggleAccordion}
        />
        <ProductDescription>
          <Typography size="lg" font="heavy" as="span">
            {brandName} - {styleName}
          </Typography>
          <Typography size="md" font="book" as="p">
            {upcCode} - {modelCode} - {commodity}
            {epcs && epcs.length === 1 && (
              <span className="epc-code"> - {epcs[0]}</span>
            )}
          </Typography>
        </ProductDescription>
        {epcs && epcs.length === 1 && (
          <UIBox position="absolute" right="0" top="6px">
            <UIAction
              label={t('add')}
              icon="add"
              disabled={actionDisabled}
              onClick={(): void => actionClickHandler?.(epcs[0])}
            />
          </UIBox>
        )}
        <UIBox alignSelf="end" justifySelf="end">
          <Typography size="sm" color="grey">
            {t('items')}: {numberItems}
          </Typography>
        </UIBox>
      </ProductItemWrapper>
      {epcs && epcs.length > 1 && (
        <ProductAccordion
          $v2
          padding="0 116px 12px 80px"
          items={`${epcs.length} ${t('items')}`}
          expanded={$isAccordionExpanded}
        >
          {epcs.map((epcCode: string, i: number) => (
            <ProductAccordionItem
              key={`${epcCode}-${i}-code`}
              epcCode={epcCode}
            >
              <UIAction
                label={t('add')}
                icon="add"
                disabled={actionDisabled}
                onClick={(): void => actionClickHandler?.(epcCode)}
              />
            </ProductAccordionItem>
          ))}
        </ProductAccordion>
      )}
    </StyledProductItemWrapper>
  );
};

export default InventoryReportListItem;
