import { FC } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/ui/Typography';
import { UILoader } from '@/components/ui/Loader';
import { UIButton } from '@/components/ui/Button';

import { ScanDevice } from '@/types/device';
import {
  StyledColumn,
  StyledTitle,
  StyledContent,
  StyledText,
  StyledLoaderWrapper,
  StyledDoneIcon,
  StyledTypography,
} from './styles';

export interface InstructionsProps {
  onStartReaderClick: () => void;
  device?: ScanDevice;
  isScanAgain?: boolean;
}

//#region - Styled Components

//#endregion

export const ColumnInstructions: FC<InstructionsProps> = ({
  onStartReaderClick,
  device,
  isScanAgain = false,
}): JSX.Element => {
  const { t } = useTranslation();

  const {
    deviceList,
    startReaderIsLoading,
    deviceInUse,
    isLoadingDeviceStatus,
  } = useSelector(state => state.devices);

  const devicesOffline = deviceList.every(
    device => device.status === 'offline'
  );

  const isStartButtonDisabled =
    isLoadingDeviceStatus ||
    startReaderIsLoading ||
    devicesOffline ||
    device?.deviceId === '';

  return (
    <StyledColumn>
      <StyledTitle>
        <Typography size="lg" font="heavy" as="h1">
          {t('scanwizard.3.instruction')}
        </Typography>
      </StyledTitle>
      <StyledContent>
        {(isScanAgain || !deviceInUse) && (
          <>
            <StyledText>
              <Typography size="sm" font="heavy" as="h1">
                {t('scanwizard.wait')}
              </Typography>
            </StyledText>
            <UIButton
              onClick={onStartReaderClick}
              label={t('scanwizard.start')}
              outlined={false}
              disabled={isStartButtonDisabled}
            />
          </>
        )}
        {startReaderIsLoading && (
          <StyledLoaderWrapper>
            <span>{t('scanwizard.loading')}</span>
            <UILoader />
          </StyledLoaderWrapper>
        )}
        {!isScanAgain && deviceInUse && (
          <>
            <StyledDoneIcon />
            <StyledTypography size="sm" font="medium">
              {t('scanwizard.ready')}
            </StyledTypography>
          </>
        )}
      </StyledContent>
    </StyledColumn>
  );
};

export default ColumnInstructions;
