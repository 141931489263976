import { FC, useEffect, useState } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';

import { useTranslation } from 'react-i18next';

import {
  addCycleCountFilteredByEPC,
  getBrandsByStore,
  initCycleCountState,
  setCycleCountFilteredByEpc,
  setFilterSelected,
} from '@/features/cycleCount/cycleCountSlice';

import TabCycleCount from '@/components/CycleCount/TabCycleCount/TabCycleCount';
import { CycleCountService, ElasticHandlerRestService } from '@/api/receive';
import GenericFilter from '@/components/CycleCount/GenericFilter/GenericFilter';
import { StyledPageContainer, StyledTitle } from './style';
import ListCCSelect from '@/components/CycleCount/ListCCSelect/ListCCSelect';
import EpcLI from '@/components/CycleCount/EpcLI/EpcLI';
import { Iepc } from '@/components/CycleCount/EpcLI/types';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { AppRoutes } from '@/app/routers';
import { useHistory } from 'react-router';
import { useMount } from 'react-use';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';

const PageCycleCountFilterEPC: FC = () => {
  const [searchedEpc, setSearchedEpc] = useState<string>('');
  const [listEpcs, setListEpcs] = useState<Array<string>>([]);
  const [searcheEpcCodes, setSearchedEpcCodes] = useState<Array<string>>([]);
  const [checkedElements, setCheckedElements] = useState<{
    [k: string]: boolean;
  }>({});

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const filterNr = 2;

  const history = useHistory();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useMount(() => {
    dispatch(setFilterSelected(filterNr));
  });

  const { filterByEpc, selectedEpc } = useSelector(state => state?.cycleCount);
  const { store } = useSelector(state => state.currentStore);

  const debounceFn = async (): Promise<void> => {
    try {
      const { epcList } =
        await ElasticHandlerRestService.elastichandlerSearchEpcAutocomplete({
          epcCode: searchedEpc,
        });

      setListEpcs(epcList?.map(({ epcCode }) => epcCode || '') || []);
    } catch (e) {
      console.error(e);
    }
  };

  const { brandIsLoading, selectedBrands } = useSelector(
    ({ cycleCount }) => cycleCount
  );

  useEffect(() => {
    if (brandIsLoading === undefined && selectedBrands?.length === 0) {
      dispatch(getBrandsByStore());
    }
  }, [dispatch, brandIsLoading, selectedBrands?.length]);

  const curDate = new Date();

  const curY = curDate.getFullYear();
  const curM = (curDate.getMonth() + 1).toString().padStart(2, '0');
  const curD = curDate.getDate().toString().padStart(2, '0');

  const onAutoCompleteOptionSelect = async (epc: string): Promise<void> => {
    if (epc !== '' && !searcheEpcCodes.includes(epc)) {
      const epcCodes =
        filterByEpc
          ?.flatMap(({ epcCodes }) => epcCodes)
          .map(({ epcCode }) => epcCode) || [];

      if (epcCodes.includes(epc)) return;

      if (selectedEpc.length > 0) {
        setSearchedEpcCodes(p => [...new Set([...p, ...epcCodes, epc])]);
      } else {
        setSearchedEpcCodes(p => [...new Set([...p, epc])]);
      }

      try {
        const { productDetailsItems } =
          await CycleCountService.cyclecountSearchProductByFilter({
            requestBody: {
              date: `${curY}${curM}${curD}`,
              storeCode: store!.storeCode!,
              epcList: [epc],
            },
          });

        const selectedEpc = productDetailsItems?.[0];

        if (!productDetailsItems || productDetailsItems?.length === 0) {
          setOpenSnackbar(true);
        }

        if (productDetailsItems && selectedEpc) {
          dispatch(addCycleCountFilteredByEPC(selectedEpc));
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const checkEpcs = (): string[] => {
    const epcCodes = Object.entries(checkedElements);
    const checkedEpcCodes: string[] = [];

    for (const [epcCode, isChecked] of epcCodes) {
      if (isChecked) {
        checkedEpcCodes.push(epcCode);
      }
    }

    return checkedEpcCodes;
  };

  const applyClickHandler = (): void => {
    const checkedEpcCodes = checkEpcs();
    dispatch(setCycleCountFilteredByEpc(checkedEpcCodes));
    history.push(AppRoutes.CYCLE_COUNT_FILTER_SUMMARY);
  };

  return (
    <>
      <StyledPageContainer>
        <StyledTitle size="lg">{t('cycleCount.filtertitle')}</StyledTitle>

        <TabCycleCount index={filterNr} />

        <GenericFilter
          debounceFn={debounceFn}
          list={listEpcs}
          onAutoCompleteOptionSelect={onAutoCompleteOptionSelect}
          setChangeElement={setSearchedEpc}
          filterTitle={t('cycleCount.filter.EPC.label')}
          filterPlaceholder={t('searchByEpc')}
        />

        <ListCCSelect
          data={filterByEpc as Iepc[]}
          keyName="epcCode"
          checkedElements={checkedElements}
          exportCheckedElements={setCheckedElements}
          component={(el, key, isChecked): JSX.Element => (
            <EpcLI
              el={el}
              keyName={key}
              setChecked={setCheckedElements}
              forceChecked={isChecked}
            />
          )}
        />

        <CTAContainer
          type="APPLY"
          disabledMainAction={checkEpcs().length === 0}
          onClick={(): void => applyClickHandler()}
          onBackClick={(): void => {
            dispatch(initCycleCountState());
            history.push(AppRoutes.CYCLE_COUNT);
          }}
        />
        <AlertSnackbar
          open={openSnackbar}
          message={t('cycleCount.otherStore.message', { type: 'EPC' })}
          setIsOpen={setOpenSnackbar}
        />
      </StyledPageContainer>
    </>
  );
};

PageCycleCountFilterEPC.displayName = 'PageCycleCountFilterEPC';

export default PageCycleCountFilterEPC;
