import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@/app/rootReducer';

import {
  ReceiveApiError as ApiError,
  RecapConfirmList,
  ReceiveShipmentsService,
} from '@/api';

export interface ConfirmDeliveryProductsSlice {
  confirmDeliveryProducts?: RecapConfirmList['productDetailsItems'];
  isLoading: boolean;
  error?: ApiError;
}

const initialState: ConfirmDeliveryProductsSlice = {
  isLoading: false,
  error: undefined,
  confirmDeliveryProducts: undefined,
};

export const getConfirmRecapDeliveryProducts = createAsyncThunk<
  RecapConfirmList | undefined,
  void,
  { state: RootState; rejectValue: ApiError }
>(
  'deliveryProducts/getConfirmRecapDeliveryProducts',
  async (_, { getState, rejectWithValue }) => {
    const {
      currentStore: { store },
    } = getState();

    if (store) {
      const { storeCode } = store;

      if (storeCode) {
        const {
          products: { found },
        } = getState().scannedProducts;

        try {
          const requestBody = {
            storeCode,
            upcs: found.list?.map(({ upcCode, epcCodes }) => ({
              quantityConfirm: epcCodes?.length.toString() ?? '0',
              upcCode,
            })),
          };

          return await ReceiveShipmentsService.receiveshipRecapConfirmEncoded({
            requestBody: {
              recapConfirm: btoa(JSON.stringify(requestBody)),
            },
          });
        } catch (error) {
          return rejectWithValue(error as ApiError);
        }
      }
    }
  }
);

const deliveryProductsSlice = createSlice({
  name: 'deliveryProducts',
  initialState,
  reducers: {
    initConfirmDeliveryProducts: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getConfirmRecapDeliveryProducts.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(
        getConfirmRecapDeliveryProducts.fulfilled,
        (state, { payload }) => {
          if (payload?.productDetailsItems) {
            state.confirmDeliveryProducts = payload.productDetailsItems;
          }

          state.isLoading = false;
          state.error = undefined;
        }
      )
      .addCase(
        getConfirmRecapDeliveryProducts.rejected,
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

export const { initConfirmDeliveryProducts } = deliveryProductsSlice.actions;
export default deliveryProductsSlice.reducer;
