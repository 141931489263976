import { ListItem as MuiListItem, List as MuiList } from '@material-ui/core';
import styled from 'styled-components';
import { CalendarTodayRounded } from '@material-ui/icons';
import { colors, palette } from '@/theme';
import { UIBox } from '@/components/ui/Box';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Search as SearchIcon } from '@material-ui/icons';

export const List = styled(MuiList)<{ mb?: string }>`
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({ mb }): string => mb || '110px'};

  & > * {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

export const ListItem = styled(MuiListItem)`
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  background: ${palette.colors.midGrey};
  border: 1px solid #dedede;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-direction: column;
`;

export const CalendarIcon = styled(CalendarTodayRounded)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

export const Actions = styled(UIBox)`
  align-items: center;
  gap: 24px;
  margin-left: auto;
`;

export const Sarch = styled(SearchIcon)`
  width: 24px;
  height: 24px;
  margin-left: 8px;
`;

export const ErrorIcon = styled(ErrorOutlineIcon)`
  margin-right: 8px;
  height: 16px;
  width: 16px;
`;

export const IconWrapper = styled(UIBox)`
  margin-left: 8px;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: ${colors.primary};
  margin-left: 8px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
