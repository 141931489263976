import styled, { css } from 'styled-components';
import { TablesHeader, TablesRow } from '@/components/layout/Tables';
import { UIBox } from '@/components/ui/Box';
import { theme } from '@/theme';
import { UISelect } from '@/components/ui/Select';

export const gridStyles = css`
  display: grid;
  gap: 24px;
  grid-template-columns: 50px 150px 300px 150px auto;

  ${theme.breakpoints.down('md')} {
    gap: 16px;
  }
`;

export const StyledStickyHeader = styled(UIBox)`
  flex-direction: column;
  background-color: #fff;
  position: sticky;
  top: 136px;
  padding-top: 24px;
  z-index: 9;
`;

export const StyledTableWrapper = styled(UIBox)`
  margin-top: 16px;
  margin-bottom: 100px;
  flex-direction: column;
`;

export const StyledTablesHeader = styled(TablesHeader)`
  ${gridStyles};
  padding: 8px 24px;

  & > * {
    &:last-child {
      justify-self: end;
    }
  }
`;

export const StyledTablesRow = styled(TablesRow)`
  ${gridStyles};
  padding: 0 !important;
  align-items: center;

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      justify-self: end;
    }
  }
`;

export const StyledSelect = styled(UISelect)`
  &&& {
    width: 65%;

    div {
      width: 100%;
    }
  }
`;
