import styled, { css } from 'styled-components';
import { TablesHeader, TablesRow } from '@/components/layout/Tables';
import { theme } from '@/theme';

export const gridStyles = css`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 220px) 300px auto;

  ${theme.breakpoints.down('md')} {
    gap: 16px;
  }
`;

export const StyledTablesHeader = styled(TablesHeader)`
  ${gridStyles};
  padding: 8px 48px;

  & > * {
    &:last-child {
      justify-self: end;
    }
  }
`;

export const StyledTablesRow = styled(TablesRow)`
  ${gridStyles};
  padding: 0 !important;
  align-items: center;

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      justify-self: end;
    }
  }
`;
