import { FC, useState, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import styled from 'styled-components';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { useTranslation } from 'react-i18next';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { Typography } from '@/components/ui/Typography';

import { Checkbox, Container, FormControlLabel } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { UIButtonWithIcon } from '@/components/ui/Button';
import { UIBox } from '@/components/ui/Box';
import { UIListHeader } from '@/components/ui/ListHeader';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';

import { Tabs } from '@/components/layout/Tabs';

import { PageLoader } from '@/components/ui/PageLoader';
import { ModalRepriceItems } from '@/components/layout/ModalRepriceItems';
import { ModalLoading } from '@/components/layout/ModalLoading';
import {
  getRepriceBrands,
  printRepriceProducts,
  setCurrentDateSlice,
} from '@/features/repricing/repriceSlice';
import { RepriceBrand, RepriceUpc } from '@/api/reprice';
import { ModalSelectPrinters } from '@/components/layout/ModalSelectPrinters';
import { useHistory } from 'react-router';
import { RepriceBrandList } from '@/components/layout/RepriceBrandList';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { palette } from '@/theme';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@/components/ui/TextField';

//#region - Styled Components

const StyledFixedBox = styled(UIBox)`
  position: fixed;
  top: 136px;
  width: 100%;
  max-width: 1440px;
  padding: 0 24px;
  margin: 0 -24px;
  background: #fff;
  z-index: 4;
  display: block;
  .MuiAutocomplete-inputRoot {
    padding: 0 0 0 10px !important;
  }
  .MuiInputBase-root {
    height: 40px;
    > input {
      padding: 10px;
    }
  }
`;

const StyledContainer = styled(Container)`
  margin-bottom: 150px;
`;

const StyledPaginationWrapper = styled('div')`
  width: 100%;
  margin-top: 275px;
  padding: 0;

  & > div {
    padding: 0;
    margin-bottom: 100px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 8px;
`;

export const StyledHeaderList = styled(UIListHeader)`
  &&& {
    padding: 0;
  }
`;

export const StyledSearchBar = styled(SearchBarLight)`
  &&& {
    padding: 0;
  }
`;

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  margin-right: 16px;
  height: 20px;
  width: 20px;
`;
//#endregion

const PageRepricing: FC = () => {
  const totalUpcs = 25;
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data, currentDate, dataIsLoading, printIsLoading, printHasError } =
    useSelector(state => state.reprice);

  const { store } = useSelector(state => state.currentStore);
  const printers = useSelector(
    state => state.currentStore.store?.printers || []
  );

  const [checkedUpcs, setCheckedUpcs] = useState<string[]>([]);
  const [selectedPrinter, setSelectedPrinter] = useState<string>();
  const [searchedUpcCode, setSearchedUpcCode] = useState<string>();
  const [isPrintStarted, setPrintStatus] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [noPrinterAvailable, setNoPrinterAvailable] = useState<boolean>(false);
  const [filteredList, setFilteredList] = useState<RepriceBrand[]>([]);
  const [upcPrintQuantity, setUpcPrintQuantity] = useState<Map<string, number>>(
    new Map()
  );
  const [repriceBrand, setRepriceBrand] = useState<RepriceBrand>({
    brandName: '',
    upcs: [],
  });

  const [alertSnackbarIsVisible, setAlertSnackbarVisibility] =
    useState<boolean>(false);
  const [isBrandModalVisible, setBrandModalVisibility] =
    useState<boolean>(false);
  const [isSelectPrintersModalVisible, setSelectPrintersModalVisibility] =
    useState<boolean>(false);
  const [filterEpcWithoutUpc, setFilterEpcWithoutUpc] = useState<boolean>(true);
  const [filterDonePrinted, setFilterDonePrinted] = useState<boolean>(false);
  const [commodityFilter, setCommodityFilter] = useState<string>(
    t('stock.all')
  );
  const [brandsSelected, setBrandsSelected] = useState<string[]>([]);

  useBreadcrumbs([
    {
      title: t('page.repricing'),
    },
    {
      title: t('page.repricing_list'),
    },
  ]);

  const dates = useMemo(
    () =>
      data.dateRepriceList &&
      data.dateRepriceList.map(({ date }) =>
        String(
          DateTime.fromFormat(String(date), 'yyyyMMdd').toFormat('yyyy/MM/dd')
        )
      ),
    [data.dateRepriceList]
  );

  useEffect(() => {
    if (!printHasError && !printIsLoading) {
      setBrandsSelected([]);
    }
  }, [printHasError, printIsLoading, setCheckedUpcs]);

  useEffect(() => {
    const curDate = currentDate;
    setSelectedTab(
      dates && dates.findIndex(d => d === curDate) > 0
        ? dates?.findIndex(d => d === curDate)
        : selectedTab
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, data, currentDate]);

  useEffect(() => {
    if (printers.length === 0) {
      setNoPrinterAvailable(true);
    }
  }, [printers.length]);

  const brandsList = useMemo(() => {
    const brands = data.dateRepriceList;

    if (brands) {
      const brandIndex = brands.findIndex((_, index) => selectedTab === index);

      if (brandIndex !== -1) {
        if (filterEpcWithoutUpc) {
          return brands[brandIndex].brandList
            ?.map(item => ({
              ...item,
              upcs: item.upcs.filter(({ statusPrint, numberItems }) => {
                if (filterDonePrinted) {
                  return numberItems > 0 && statusPrint !== 'done';
                }
                return numberItems > 0;
              }),
            }))
            .filter(({ upcs }) => {
              return upcs.length > 0;
            });
        }

        if (filterDonePrinted) {
          return brands[brandIndex].brandList
            ?.map(item => ({
              ...item,
              upcs: item.upcs.filter(({ statusPrint }) => {
                return statusPrint !== 'done';
              }),
            }))
            .filter(({ upcs }) => {
              return upcs.length > 0;
            });
        }

        return brands[brandIndex].brandList;
      }
    }

    return [];
  }, [
    data.dateRepriceList,
    selectedTab,
    filterEpcWithoutUpc,
    filterDonePrinted,
  ]);

  useEffect(() => {
    const brands =
      commodityFilter !== t('stock.all')
        ? brandsList.map(brand => ({
            ...brand,
            upcs: brand.upcs.filter(({ commodity }) =>
              commodityFilter === 'Sunglasses'
                ? commodity === '10'
                : commodity === '01'
            ),
          }))
        : brandsList;

    if (searchedUpcCode && searchedUpcCode.length > 3) {
      const filtered = brands.map(brand => ({
        ...brand,
        upcs: [...brand.upcs]
          .filter(({ upcCode }) =>
            upcCode.toLowerCase().includes(searchedUpcCode.toLowerCase())
          )
          .sort((a, b) => {
            if (a.modelCode > b.modelCode) {
              return 1;
            }
            if (a.modelCode < b.modelCode) {
              return -1;
            }
            return 0;
          }),
      }));

      setFilteredList(filtered.filter(({ upcs }) => upcs.length > 0));
    } else {
      setFilteredList(
        brands
          .filter(({ upcs }) => upcs.length > 0)
          .map(brand => ({
            ...brand,
            upcs: [...brand.upcs].sort((a, b) => {
              if (a.modelCode > b.modelCode) {
                return 1;
              }
              if (a.modelCode < b.modelCode) {
                return -1;
              }
              return 0;
            }),
          }))
      );
    }
  }, [brandsList, searchedUpcCode, commodityFilter, t]);

  useEffect(() => {
    dispatch(getRepriceBrands());
  }, [dispatch]);

  useEffect(() => {
    if (printHasError) {
      setAlertSnackbarVisibility(true);
    }
  }, [printHasError]);

  useEffect(() => {
    if (isPrintStarted) {
      const products =
        brandsList &&
        brandsList
          .flatMap(({ upcs }) => upcs)
          .filter(({ upcCode }) => checkedUpcs.includes(upcCode));

      if (products) {
        (async (): Promise<void> => {
          try {
            await dispatch(
              printRepriceProducts({
                products,
                upcPrintQuantity,
                selectedPrinter,
              })
            ).unwrap();

            setPrintStatus(false);
            setCheckedUpcs([]);
            setSelectedPrinter(undefined);
          } catch (err) {
            setAlertSnackbarVisibility(true);
          }
        })();
      }
    }
    // ? Disabled for possibile unwanted side effects 😦
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrintStarted, printRepriceProducts, setPrintStatus]);

  const onPrintClick = (): void => {
    if (store?.storeCode) {
      if (store.printers) {
        if (store.printers.length > 1) {
          setSelectPrintersModalVisibility(true);
        } else {
          setPrintStatus(true);
        }
      } else {
        setPrintStatus(true);
      }
    }
  };

  const onUpdateClick = async (): Promise<void> => {
    setCheckedUpcs([]);
    setSearchedUpcCode('');
    setCommodityFilter(t('stock.all'));
    setFilterEpcWithoutUpc(true);
    setFilterDonePrinted(false);
    await dispatch(getRepriceBrands());
  };

  const onSeeItemsClick = (brandName: string, upcs: RepriceUpc[]): void => {
    setBrandModalVisibility(true);
    setRepriceBrand({
      brandName,
      upcs,
    });
  };

  const onTabChange = (index: number): void => {
    setSelectedTab(index);
    setSearchedUpcCode('');
    setCommodityFilter(t('stock.all'));
    setFilterEpcWithoutUpc(true);
    setFilterDonePrinted(false);
    setBrandsSelected([]);
    setCheckedUpcs([]);

    if (currentDate) {
      dispatch(setCurrentDateSlice(dates?.[index] || ''));
    }
  };

  if (dataIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalLoading open={!!printIsLoading} />
      <ModalSelectPrinters
        open={isSelectPrintersModalVisible}
        onClose={(): void => setSelectPrintersModalVisibility(false)}
        setPrintStatus={setPrintStatus}
        selectedPrinter={selectedPrinter}
        setSelectedPrinter={setSelectedPrinter}
      />
      <StyledContainer>
        <StyledFixedBox>
          <UIBox justifyContent="space-between">
            <Typography font="heavy" as="h1" margin="30px 0 23px">
              {t('reprice.click_on_date')}
            </Typography>
            <UIButtonWithIcon
              label={t('refreshPriceList')}
              startIcon={<RefreshIcon />}
              onClick={onUpdateClick}
            />
          </UIBox>
          <Tabs
            values={dates!}
            selectedTab={selectedTab}
            onTabChange={onTabChange}
          />
          <UIBox mt={2} mb={0} gridGap="100px">
            <Autocomplete
              fullWidth
              key="commodity"
              value={{
                label: commodityFilter,
                value: commodityFilter,
              }}
              onChange={(_, selected): void => {
                setCommodityFilter(selected?.value || t('stock.all'));
              }}
              options={[
                { value: 'Frames', label: 'Frames' },
                { value: 'Sunglasses', label: 'Sunglasses' },
                { value: t('stock.all'), label: t('stock.all') },
              ]}
              getOptionLabel={(option): string => option.label}
              getOptionSelected={(option): boolean =>
                option.label === commodityFilter
              }
              renderInput={(params): JSX.Element => (
                <TextField
                  {...params}
                  label={t('stock.select_commodity')}
                  variant="outlined"
                />
              )}
            />
            <StyledSearchBar
              fullWidth
              label=""
              hideButton
              disabled={false}
              loading={false}
              placeholder={t('cycleCount.filter.UPC')}
              value={searchedUpcCode}
              onSearch={(): void => {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                setSearchedUpcCode(e.target.value)
              }
            />
          </UIBox>
          <UIBox mt={0} mb={2} ml="-20px">
            <StyledFormControlLabel
              label={t('reprice.show_upc.check')}
              control={
                <Checkbox
                  checked={filterEpcWithoutUpc}
                  onChange={(): void =>
                    setFilterEpcWithoutUpc(!filterEpcWithoutUpc)
                  }
                />
              }
            />
            <StyledFormControlLabel
              label={t('reprice.show_done.check')}
              control={
                <Checkbox
                  checked={filterDonePrinted}
                  onChange={(): void =>
                    setFilterDonePrinted(!filterDonePrinted)
                  }
                />
              }
            />
          </UIBox>
          <StyledHeaderList
            padding="0 21px"
            text={t('item_selected', {
              selected: checkedUpcs.length,
            })}
            extraText={t('reprice.item_to_print', {
              itemsFound:
                brandsList
                  ?.map(({ upcs }) => upcs)
                  .flat()
                  .filter(({ statusPrint }) => statusPrint !== 'done')
                  .reduce((prev, curr) => {
                    if (curr) {
                      return prev + curr.numberItems;
                    }
                    return prev;
                  }, 0) || 0,
            })}
          />
          <UIBox
            mt={-1}
            alignItems="center"
            visibility={checkedUpcs.length > 49 ? 'visible' : 'hidden'}
          >
            <StyledErrorOutlineIcon htmlColor={palette.colors.yellow} />
            <Typography size="sm">
              {t('reprice.printingIssuesMessage')}
            </Typography>
          </UIBox>
        </StyledFixedBox>
        <StyledPaginationWrapper>
          {filteredList && filteredList.length > 0 ? (
            <>
              {filteredList.map((brand, index) => (
                <RepriceBrandList
                  key={index}
                  totalUpcs={totalUpcs}
                  checkedUpcs={checkedUpcs}
                  onSeeItemsClick={onSeeItemsClick}
                  setCheckedUpcs={setCheckedUpcs}
                  setUpcPrintQuantity={setUpcPrintQuantity}
                  brand={brand}
                  selectedTab={selectedTab}
                  brandsSelected={brandsSelected}
                  setBrandsSelected={setBrandsSelected}
                />
              ))}
            </>
          ) : (
            <>
              <UIBox
                mt={5}
                width="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Typography font="heavy">{t('noResultsFound')}</Typography>
              </UIBox>
            </>
          )}
        </StyledPaginationWrapper>
        <CTAContainer
          type="PRINT"
          label={t('item_selected', {
            selected: checkedUpcs.length,
          })}
          onClick={onPrintClick}
          onBackClick={(): void => history.goBack()}
          disabledMainAction={checkedUpcs.length === 0 || printers.length === 0}
        />
      </StyledContainer>

      <ModalRepriceItems
        brandName={repriceBrand.brandName}
        open={isBrandModalVisible}
        onClose={(): void => setBrandModalVisibility(false)}
        repriceUpcs={repriceBrand.upcs.reduce(
          (arr: RepriceUpc[], el: RepriceUpc): RepriceUpc[] => {
            if (
              (el.firstPrice?.price && Number(el.firstPrice.price) === 0) ||
              (el.secondPrice?.price && Number(el.secondPrice.price) === 0)
            ) {
              return [
                ...arr,
                {
                  ...el,
                  firstPrice:
                    el.firstPrice && Number(el.firstPrice.price) === 0
                      ? el.firstPrice
                      : undefined,
                  secondPrice:
                    el.secondPrice?.price && Number(el.secondPrice.price) === 0
                      ? el.secondPrice
                      : undefined,
                },
              ];
            }

            return arr;
          },
          [] as RepriceUpc[]
        )}
      />

      <AlertSnackbar
        open={alertSnackbarIsVisible}
        setIsOpen={setAlertSnackbarVisibility}
        autoHideDuration={5000}
        message={printHasError?.body?.message}
      />

      <AlertSnackbar
        open={noPrinterAvailable}
        setIsOpen={setNoPrinterAvailable}
        message={t('error.noPrinterAvailable')}
      />
    </>
  );
};

export default PageRepricing;
