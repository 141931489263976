import React, { memo, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CTAContainer } from '@/components/layout/CTAContainer';

import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { useHistory } from 'react-router';
import { AppRoutes } from '@/app/routers';
import { UIList } from '@/components/ui/List';
import { DeliveryRecapProductItem } from '@/components/layout/DeliveryRecapProductItem';

import { useSelector } from '@/hooks/useSelector';
import { getConfirmRecapDeliveryProducts } from '@/features/products/confirmDeliveryProductsSlice';
import { useAppDispatch } from '@/app/store';
import { useAsync } from 'react-use';
import { Typography } from '@/components/ui/Typography';
import { PageLoader } from '@/components/ui/PageLoader';
import { PaginationList } from '@/components/layout/PaginationList';
import { RecapConfirmList } from '@/api';
import { useSubscribeDeviceStatus } from '@/hooks/mqtt/useSubscribeDeviceStatus';
import { MQTTContext } from '@/context/MQTT';
import { RecapConfirm, ReceiveShipmentService } from '@/api/receive';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { UIBox } from '@/components/ui/Box';
import { DeviceStatus } from '@/types/enum';
import { updateDeviceStatus } from '@/features/devices/devicesSlice';

const PageReceivingRecapConfirm: FC = memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { deviceInUse } = useSelector(state => state.devices);
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const found = useSelector(
    state => state.scannedProducts.products.found.list || []
  );

  const hasFoundItems = found.length > 0;

  const { confirmDeliveryProducts, isLoading: isLoadingConfirmDelivery } =
    useSelector(state => state.confirmDeliveryProductsSlice);

  const newConfirmDeliveryProducts =
    confirmDeliveryProducts?.filter(
      ({ showBeforeReceipt }) => showBeforeReceipt
    ) || [];

  const oldConfirmDeleveryProducts =
    confirmDeliveryProducts?.filter(
      ({ showBeforeReceipt }) => !showBeforeReceipt
    ) || [];

  const downloadReceivingReport: React.MouseEventHandler =
    async (): Promise<void> => {
      if (storeCode) {
        try {
          setLoading(true);
          const requestBody: RecapConfirm = {
            storeCode,
            upcs: found?.map(({ upcCode, epcCodes }) => ({
              quantityConfirm: epcCodes?.length.toString() ?? '0',
              upcCode: upcCode!,
            })),
          };

          const { pdfFile, namePdfFile } =
            await ReceiveShipmentService.receiveshipDownLoadReportReceiving({
              requestBody,
            });

          const linkSource = `data:application/pdf;base64,${pdfFile}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = namePdfFile;
          downloadLink.click();
          setLoading(false);

          history.push(AppRoutes.INTRO);
        } catch {
          setLoading(false);
          setDownloadError(true);
        }
      }
    };

  const redirectToHome: React.MouseEventHandler = (): void => {
    history.push(AppRoutes.INTRO);
  };

  const subscribeDevice = useSubscribeDeviceStatus();
  const { setDeviceIdOnMqttClient } = React.useContext(MQTTContext);

  useBreadcrumbs([
    {
      title: t('page.confirmRecap'),
    },
  ]);

  useAsync(async () => {
    await dispatch(getConfirmRecapDeliveryProducts());
  }, [dispatch]);

  useAsync(async () => {
    await dispatch(
      updateDeviceStatus({
        deviceId: deviceInUse?.deviceId!,
        statusDevice: DeviceStatus.ONLINE,
        storeId: deviceInUse?.storeId!,
        nameDevice: deviceInUse?.nameDevice!,
        statusMode: deviceInUse?.mode,
      })
    );
    subscribeDevice.publish(deviceInUse?.deviceId!, undefined);
    setDeviceIdOnMqttClient(undefined);
    sessionStorage.removeItem('deviceInUse');
  }, [dispatch]);

  if (isLoadingConfirmDelivery) {
    return <PageLoader />;
  }

  return (
    <>
      {hasFoundItems ? (
        <UIList
          title={t('page.confimDeliveryRecap')}
          itemsCount={t('page.confirmDeliveryUpcQty')}
          rightHeader={
            <>
              <Typography size="md" font="heavy" as="h1">
                {t('list.delivery')}
              </Typography>
              <Typography size="md" font="heavy" as="h1">
                {t('totalInStock')}
              </Typography>
            </>
          }
          rounded
          backgrounded
          shadowed
          margin={[0, 0, 100]}
        >
          <PaginationList
            data={newConfirmDeliveryProducts}
            pageSize={50}
            renderItem={(
              props: NonNullable<RecapConfirmList['productDetailsItems']>[0],
              index: number
            ): JSX.Element => (
              <DeliveryRecapProductItem
                {...props}
                key={`${props.brandCode}-${index}-productItem`}
              />
            )}
          />
          <PaginationList
            data={oldConfirmDeleveryProducts}
            pageSize={50}
            renderItem={(
              props: NonNullable<RecapConfirmList['productDetailsItems']>[0],
              index: number
            ): JSX.Element => (
              <DeliveryRecapProductItem
                {...props}
                key={`${props.brandCode}-${index}-productItem`}
              />
            )}
          />
        </UIList>
      ) : (
        <UIBox width="100%" textAlign="center" mt={16}>
          <Typography margin="0 auto" font="heavy" size="lg">
            {t('emptyRecapPageMessage')}
          </Typography>
        </UIBox>
      )}
      <CTAContainer
        type="ONE_BUTTON"
        label={hasFoundItems ? t('downloadReport') : t('gotIt')}
        loading={loading}
        onClick={hasFoundItems ? downloadReceivingReport : redirectToHome}
      />
      <ErrorSnackbar
        open={downloadError}
        setIsOpen={setDownloadError}
        errorMessage={t('error.downloadReport')}
      />
    </>
  );
});

PageReceivingRecapConfirm.displayName = 'PageReceivingRecapConfirm';

export default PageReceivingRecapConfirm;
