import React from 'react';
import styled from 'styled-components';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { TColorName } from '@/theme';
import { UIBox } from '../Box';
import { Typography } from '../Typography';

interface UIWarningProps {
  label: string;
  display?: string;
  margin?: string;
  labelAs?: string;
  alignItems?: string;
}

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  fill: ${({ theme }): TColorName => theme.palette.colors.yellow};
  margin-right: 3px;
  width: 18px;
  height: 18px;
`;

const UIWarning: React.FC<UIWarningProps> = ({
  label,
  display = 'flex',
  margin,
  labelAs = 'p',
  alignItems = 'center',
}) => {
  return (
    <UIBox display={display} alignItems={alignItems} margin={margin}>
      <StyledErrorOutlineIcon />
      <Typography font="medium" as={labelAs}>
        {label}
      </Typography>
    </UIBox>
  );
};

export default UIWarning;
