import { Printer, Device } from '@/api/receive';
import { TableDevice } from '@/types/tableDevice';

export const mergeReadersAndPrinters = (
  readers: Array<Device>,
  printers: Array<(Omit<Printer, 'isRfid'> & { isRfid?: boolean }) | null>
): Array<TableDevice> => {
  const readersElaborated = readers.map(reader => ({
    type: 'reader',
    name: reader.nameDevice,
    id: reader.deviceId,
  }));
  const printersElaborated = printers.map(printer => ({
    type: 'printer',
    name: printer?.printerName || '',
    id: printer?.printerCode || '',
    isRfid: printer?.isRfid,
  }));
  return [...readersElaborated, ...printersElaborated];
};
