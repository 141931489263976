import { createSlice, createAsyncThunk, Dispatch } from '@reduxjs/toolkit';

import {
  FindLastPrintResponse,
  PrintService,
  ProductItemToReprint,
  TypeOfStore,
} from '@/api/receive';
import { PrintService as BFFPrintService } from '@/api/bff';

import {
  RfidPrintOut,
  BFFApiError as ApiError,
  ProductItemForPrint,
  Print,
  RfidPrintIn,
} from '@/api';

import {
  addNotFoundPrinted,
  addNotFoundPrintedToFound,
  removeEpcPrintedFromNotFound,
} from '@/features/cycleCount/cycleCountSlice';

import { RootState } from '@/app/rootReducer';
import getCurrentDate from '@/utils/getCurrentDate';
import { handlePrintBody } from '@/utils/handlePrintLayout';
import { PRINT_NEW_TAG_DAYS_BEFORE } from '@/configs/settings';
export interface PrintState {
  isLoadingLastPrints: boolean;
  lastPrintsError?: ApiError;
  lastPrints: FindLastPrintResponse;
  printedEpc: string[];
  cycleCountPrintedEpc: string[];
  isLoadingProductDetails: boolean;
  productDetails?: ProductItemForPrint | ProductItemToReprint;
  isPrinting: boolean;
  cycleCountIsPrinting: boolean;
  printError?: ApiError;
  cycleCountPrintError?: ApiError;
}

const initialState: PrintState = {
  isLoadingLastPrints: false,
  isLoadingProductDetails: false,
  lastPrints: {
    productDetailsItems: [],
  },
  printedEpc: [],
  cycleCountPrintedEpc: [],
  isPrinting: false,
  cycleCountIsPrinting: false,
};

export const getLastPrints = createAsyncThunk<
  FindLastPrintResponse | undefined,
  void,
  { state: RootState; rejectValue: ApiError }
>('print/getLastPrints', async (_, { getState, rejectWithValue }) => {
  const {
    currentStore: { store },
  } = getState();

  if (store?.storeCode) {
    try {
      return await PrintService.receiveshipFindLastPrint({
        storeCode: store.storeCode,
        days: PRINT_NEW_TAG_DAYS_BEFORE,
      });
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  }
});

export const printLabel = createAsyncThunk<
  RfidPrintOut,
  Partial<Print & { oldEpc?: string; epc?: string }>,
  {
    dispatch: Dispatch;
    state: RootState;
    rejectValue: ApiError;
  }
>('print/printLabel', async (body, { rejectWithValue, getState }) => {
  const { oldEpc, confirmPrint = false, ...printPayload } = body;
  const date = getCurrentDate();
  const { store } = getState().currentStore;
  const { typeOfStore } = store!;

  try {
    const productToPrint =
      await PrintService.receiveshipFindProductItemPrintDetails({
        upcCode: printPayload.upcCode,
        storeCode: printPayload.storeCode,
        date,
      });

    const additionalBody: RfidPrintIn = {
      layout: printPayload.layout!,
      upcCode: printPayload.upcCode!,
      serialNumberPrinter: printPayload.serialNumberPrinter || '',
      epc: printPayload.epc,
    };

    const printRequestBody = handlePrintBody(
      typeOfStore || TypeOfStore.AIRPORT_US,
      productToPrint,
      additionalBody
    );

    const zebraResponse = await BFFPrintService.print({
      requestBody: {
        storeCode: printPayload.storeCode || '',
        ...printRequestBody,
      },
    });

    if (confirmPrint) {
      await PrintService.receiveshipConfirmPrintDetails({
        requestBody: {
          ...body,
          storeId: body.storeCode || '',
          epcCode: zebraResponse.epc?.toUpperCase(),
          upcCode: body?.upcCode! ?? productToPrint.upcCode,
          reason: body.reasonCode || 'Printed',
        },
      });
    }

    return zebraResponse;
  } catch (error) {
    return rejectWithValue(error as ApiError);
  }
});

export const printCycleCountLabel = createAsyncThunk<
  RfidPrintOut,
  Print & { oldEpc?: string },
  {
    dispatch: Dispatch;
    rejectValue: ApiError;
  }
>('print/printCycleCountLabel', async (body, { dispatch, rejectWithValue }) => {
  const { oldEpc, confirmPrint = false, ...printPayload } = body;
  const date = getCurrentDate();

  try {
    const productToPrint =
      await PrintService.receiveshipFindProductItemPrintDetails({
        upcCode: printPayload.upcCode,
        storeCode: printPayload.storeCode,
        date,
      });

    const zebraResponse = await BFFPrintService.print({
      requestBody: {
        storeCode: printPayload.storeCode,
        serialNumberPrinter: printPayload.serialNumberPrinter,
        layout: printPayload.layout,
        currency: productToPrint.currency,
        van: productToPrint.van,
        brandDescription: productToPrint.brandDescription,
        styleNameDescription: productToPrint.styleNameDescription,
        marketingThemeDescription: productToPrint.marketingThemeDescription,
        marketingStoryDescription: productToPrint.marketingStoryDescription,
        frameColor: productToPrint.frameColor,
        lensColor: productToPrint.lensColor,
        upcCode: printPayload.upcCode,
        epc: printPayload.epc,
        price: productToPrint.retailPrice,
        polarCode: productToPrint.polarCode,
      },
    });

    if (confirmPrint) {
      await PrintService.receiveshipConfirmPrintDetails({
        requestBody: {
          ...body,
          storeId: body.storeCode,
          epcCode: zebraResponse.epc?.toUpperCase(),
          upcCode: body?.upcCode! ?? productToPrint.upcCode,
          reason: body.reasonCode ?? 'Printed',
        },
      });
    }

    console.log('oldEpc in slice:', oldEpc);

    // * ON CYCLECOUNT
    if (body.reasonCode !== 'Printed' && oldEpc) {
      dispatch(
        addNotFoundPrintedToFound({
          upc: body.upcCode,
          oldEpc,
          newEpc: zebraResponse.epc!,
        })
      );

      dispatch(
        removeEpcPrintedFromNotFound({
          oldEpc,
          upc: body.upcCode,
        })
      );

      dispatch(
        addNotFoundPrinted({
          notFoundProduct: {
            epcFound: [
              {
                epcFoundCodeNew: zebraResponse.epc!?.toUpperCase(),
                epcFoundCodeOld: oldEpc,
                reasonEpcFound:
                  body.reasonCode! === 'Damaged'
                    ? 'Tag Damaged'
                    : body.reasonCode!,
              },
            ],
            upcCodeFound: body.upcCode,
            epcFoundQuantity: 1,
          },
          epc: oldEpc,
          upc: body.upcCode,
        })
      );
    }

    return zebraResponse;
  } catch (error) {
    return rejectWithValue(error as ApiError);
  }
});

export const findUpc = createAsyncThunk<
  ProductItemForPrint,
  Omit<
    Parameters<
      typeof PrintService.receiveshipFindProductItemPrintDetails
    >[number],
    'date'
  >,
  {
    rejectValue: ApiError;
  }
>('print/findUpc', async (body, { rejectWithValue }) => {
  const date = getCurrentDate();

  try {
    return await PrintService.receiveshipFindProductItemPrintDetails({
      ...body,
      date,
    });
  } catch (error) {
    return rejectWithValue(error as ApiError);
  }
});

export const findEpc = createAsyncThunk<
  ProductItemToReprint,
  Omit<
    Parameters<typeof PrintService.receiveshipFindEpcToReprint>[number],
    'date'
  >,
  {
    rejectValue: ApiError;
  }
>('print/findEpc', async (body, { rejectWithValue }) => {
  const date = getCurrentDate();

  try {
    return await PrintService.receiveshipFindEpcToReprint({ ...body, date });
  } catch (error) {
    return rejectWithValue(error as ApiError);
  }
});

const printSlice = createSlice({
  name: 'print',
  initialState,
  reducers: {
    initLastPrints: () => initialState,
    clearProductDetails: state => {
      state.productDetails = undefined;
    },
    resetUpc: state => {
      state.productDetails = initialState.productDetails;
      state.isLoadingProductDetails = initialState.isLoadingProductDetails;
      state.lastPrintsError = initialState.lastPrintsError;
      state.cycleCountPrintError = initialState.cycleCountPrintError;
      state.printError = initialState.printError;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLastPrints.pending, state => {
        state.isLoadingLastPrints = true;
        state.lastPrintsError = undefined;
      })
      .addCase(getLastPrints.fulfilled, (state, { payload }) => {
        if (payload) {
          state.lastPrints = payload;
        }

        state.isLoadingLastPrints = false;
        state.lastPrintsError = undefined;
        state.cycleCountPrintError = undefined;
        state.printError = undefined;
      })
      .addCase(getLastPrints.rejected, (state, { payload }) => {
        state.isLoadingLastPrints = false;
        state.lastPrintsError = payload;
      })
      .addCase(printLabel.pending, state => {
        state.isPrinting = true;
        state.lastPrintsError = undefined;
      })
      .addCase(printLabel.fulfilled, (state, { payload }) => {
        state.printedEpc.push(payload.epc!.toUpperCase());
        state.isPrinting = false;
        state.lastPrintsError = undefined;
        state.cycleCountPrintError = undefined;
        state.printError = undefined;
      })
      .addCase(printLabel.rejected, (state, { payload }) => {
        state.isPrinting = false;
        state.printError = payload;
      })
      .addCase(printCycleCountLabel.pending, state => {
        state.isPrinting = true;
        state.lastPrintsError = undefined;
      })
      .addCase(printCycleCountLabel.fulfilled, (state, { payload }) => {
        state.cycleCountPrintedEpc.push(payload.epc!.toUpperCase());
        state.isPrinting = false;
        state.lastPrintsError = undefined;
        state.printError = undefined;
        state.printError = undefined;
      })
      .addCase(printCycleCountLabel.rejected, (state, { payload }) => {
        state.isPrinting = false;
        state.printError = payload;
      })
      .addCase(findUpc.pending, state => {
        state.isLoadingProductDetails = true;
        state.lastPrintsError = undefined;
      })
      .addCase(findUpc.fulfilled, (state, { payload }) => {
        state.productDetails = payload;
        state.isLoadingProductDetails = false;
        state.lastPrintsError = undefined;
        state.cycleCountPrintError = undefined;
        state.printError = undefined;
      })
      .addCase(findUpc.rejected, (state, { payload }) => {
        state.isLoadingProductDetails = false;
        state.lastPrintsError = payload;
      })
      .addCase(findEpc.pending, state => {
        state.isLoadingProductDetails = true;
        state.lastPrintsError = undefined;
      })
      .addCase(findEpc.fulfilled, (state, { payload }) => {
        state.productDetails = payload;
        state.isLoadingProductDetails = false;
        state.lastPrintsError = undefined;
        state.cycleCountPrintError = undefined;
        state.printError = undefined;
      })
      .addCase(findEpc.rejected, (state, { payload }) => {
        state.isLoadingProductDetails = false;
        state.lastPrintsError = payload;
      });
  },
});

export const {
  initLastPrints: initPrints,
  clearProductDetails,
  resetUpc,
} = printSlice.actions;
export default printSlice.reducer;
